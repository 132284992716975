import {
  AlternateViewportContent,
  OrganizationPageLayout
} from "../../../components/Layout";
import Page from "../../../components/Page";
import OrganizationHeader from "../OrganizationHeader";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import NavigationMenu from "../../../components/NavigationMenu";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from "react-router-dom";
import { Forbidden, NotFound } from "../../ErrorPage";
import UpdateConfiguration from "./UpdateConfiguration";
import { compose } from "redux";
import styled from "styled-components";
import { hasPermission, selectCookie } from "../../../store/reducers";
import Permissions from "../../../utils/permissions";
import { connect } from "react-redux";
import CookieNames from "../../../utils/cookie-names";
import DiscoveryUnsupportedResolutionPage from "../../Discovery/DiscoveryUnsupportedResolutionPage";
import { Space } from "antd";
import { UpdateDropdown } from "./components";
import CurrentConfiguration from "./CurrentConfiguration";
import CuvamaRoute from "../../../routes/CuvamaRoute";
import GuidesDropdown from "./components/GuidesDropdown";
import ConfigurationLogs from "./ConfigurationLogs";
import FileDownloadMonitor from "./FileDownloadMonitor";

const Pages = {
  UPDATE_CONFIGURATION: "update-configuration",
  CURRENT_CONFIGURATION: "current-configuration",
  CONFIGURATION_LOG: "configuration-log"
};
Pages.ALL = Object.values(Pages);

const getSelectedPage = (pathname) => {
  const matches = pathname.match(/^.*?([a-z\-]+)$/);

  if (matches) {
    for (const page of Pages.ALL) {
      if (page === matches[1]) {
        return page;
      }
    }
  }

  return Pages.UPDATE_CONFIGURATION;
};

const ManageConfiguration = ({
  history,
  canSeeUpdateConfiguration,
  showDiscoveryOnUnsupportedResolution
}) => {
  const header = s(
    "organization.page.manageConfiguration.header",
    "Manage Configuration"
  );
  const configurationLog = s(
    "organization.page.manageConfiguration.menu.configurationLog",
    "Changes Log"
  );
  const currentConfigurationText = s(
    "organization.page.manageConfiguration.menu.currentConfiguration",
    "Current Configuration"
  );
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(Pages.MANAGE_CONFIGURATION);

  const navigationMenuItems = [
    {
      key: Pages.CURRENT_CONFIGURATION,
      label: currentConfigurationText
    },
    {
      key: Pages.CONFIGURATION_LOG,
      label: configurationLog
    }
  ];

  useEffect(() => {
    setSelectedPage(getSelectedPage(location.pathname));
  }, [location.pathname]);

  const onChangePage = (e) => {
    history.push(`/organizations/manage-configuration/${e.key}`);
  };

  if (
    selectedPage === Pages.UPDATE_CONFIGURATION &&
    !canSeeUpdateConfiguration
  ) {
    return <Forbidden />;
  }

  return (
    <AlternateViewportContent
      alwaysShowContent={showDiscoveryOnUnsupportedResolution}
      alternate={<DiscoveryUnsupportedResolutionPage />}
    >
      <Page header={<OrganizationHeader />}>
        <OrganizationPageLayout>
          <HeaderAndButtons>
            <Heading level={"h3"}>{header}</Heading>
            <Space size={24} direction={"horizontal"}>
              <GuidesDropdown />
              <UpdateDropdown />
            </Space>
          </HeaderAndButtons>
          <NavigationMenu
            mode={"horizontal"}
            selectedKeys={[selectedPage]}
            onClick={onChangePage}
            items={navigationMenuItems}
          />
          <Container>
            <Switch>
              <Route
                path="/organizations/manage-configuration/update-configuration"
                exact
                component={UpdateConfiguration}
              />
              <Route
                path="/organizations/manage-configuration/configuration-log"
                component={ConfigurationLogs}
              />
              <CuvamaRoute
                path="/organizations/manage-configuration/current-configuration"
                exact
                component={CurrentConfiguration}
              />
              <Redirect
                from={""}
                to={"/organizations/manage-configuration/current-configuration"}
                exact
              />
              <Route component={NotFound} />
            </Switch>
          </Container>
        </OrganizationPageLayout>
        <FileDownloadMonitor />
      </Page>
    </AlternateViewportContent>
  );
};

const HeaderAndButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  & h3 {
    flex-grow: 1;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => ({
  canSeeUpdateConfiguration: hasPermission(
    state,
    Permissions.UPDATE_CONFIGURATION
  ),
  showDiscoveryOnUnsupportedResolution: selectCookie(
    state,
    CookieNames.SHOW_DISCOVERY_ON_UNSUPPORTED_RESOLUTION
  )
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ManageConfiguration);
