import styled from "styled-components";
import { Typography } from "antd";
import { themeProp } from "../../theme";

const { Paragraph: ANTParagraph } = Typography;

const Paragraph = styled(ANTParagraph)`
  font-size: ${({ variant }) =>
    themeProp(`typography.${variant}.fontSize`)} !important;
  font-family: ${({ variant }) =>
    themeProp(`typography.${variant}.fontFamily`)} !important;
  font-weight: ${({ variant }) =>
    themeProp(`typography.${variant}.fontWeight`)} !important;
  line-height: ${({ variant }) =>
    themeProp(`typography.${variant}.lineHeight`)} !important;
  ${(props) =>
    props.color
      ? "color: " + themeProp(`palette.${props.color}`)(props) + " !important;"
      : ""}

  &.ant-typography {
    margin-bottom: 0;
  }
`;

export default Paragraph;
