import React from "react";
import SignUpForm from "./SignUpForm";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTheme } from "../../components/ThemeProvider";
import { SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import useLoadingState from "../../utils/use-loading-state";
import { registerUser } from "../../store/actions/authentication";
import { selectRequestState } from "../../store/reducers";
import actionTypes from "../../store/actionTypes";

const SignUp = ({ history, loadingState, registerUser }) => {
  useLoadingState(loadingState, () => {
    history.push("/verification-code");
  });

  const onFinish = ({ email, password }) => {
    registerUser({ email, password });
  };

  return (
    <Page>
      <SplitPageLayout image={useTheme("assets.signup_cover")}>
        <SignUpForm onFinish={onFinish} />
      </SplitPageLayout>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingState: selectRequestState(state, actionTypes.REGISTER_USER_REQUEST)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ registerUser }, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SignUp);
