import types from "../actionTypes";

export const setFullscreen =
  ({ fullscreen }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_FULLSCREEN,
      payload: fullscreen
    });
  };
