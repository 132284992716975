const hasMissingProperties = (obj, requiredProperties) => {
  if (typeof obj !== "object" || obj === null) {
    return true;
  }

  if (!Array.isArray(requiredProperties)) {
    throw new Error("requiredProperties must be an array");
  }

  return requiredProperties.some((prop) => {
    return (
      !(prop in obj) ||
      obj[prop] === undefined ||
      obj[prop] === null ||
      obj[prop] === ""
    );
  });
};

export default hasMissingProperties;
