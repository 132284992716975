import Page from "../../components/Page";
import { ErrorPageLayout } from "../../components/Layout";
import { useString as s } from "../../components/StringProvider";
import { Space } from "antd";
import WebFont from "webfontloader";
import { useEffect, useState } from "react";

const ThemeError = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const headerText = s(
    "theme_error_page.header",
    "Oops, our site is currently down"
  );
  const errorText = s(
    "theme_error_page.text",
    "Please try to load this page in a few minutes"
  );
  const buttonText = s("theme_error_page.reload_page", "Reload Page");

  const reloadPage = () => {
    location.reload();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log("Font loading timeout");
      setFontsLoaded(true);
    }, 10000);

    const fonts = ["Overpass:600,700", "Open Sans:400"];

    WebFont.load({
      google: {
        families: fonts
      },
      active: () => {
        clearTimeout(timeout);
        setFontsLoaded(true);
      },
      inactive: () => {
        clearTimeout(timeout);
        setFontsLoaded(true);
      },
      fontinactive: (familyName) => {
        console.log(`Failed to load font '${familyName}'`);
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  });

  const pageStyle = {
    background: "#DCDEE2"
  };

  const layoutStyle = {
    background: "#DCDEE2"
  };

  const headerStyle = {
    fontFamily: "Overpass, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "34px",
    color: "rgb(91,102,122)"
  };

  const messageStyle = {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "rgb(91,102,122)"
  };

  const buttonStyle = {
    fontFamily: "Overpass, sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "20px",
    color: "#65CCCE",
    borderRadius: "6px",
    border: "1px solid #65CCCE",
    padding: "12px 24px",
    background: "transparent"
  };

  return fontsLoaded ? (
    <Page style={pageStyle}>
      <ErrorPageLayout style={layoutStyle}>
        <Space direction={"vertical"} size={"large"}>
          <div style={headerStyle}>{headerText}</div>
          <div style={messageStyle}>{errorText}</div>
          <button style={buttonStyle} onClick={reloadPage}>
            {buttonText}
          </button>
        </Space>
      </ErrorPageLayout>
    </Page>
  ) : null;
};

export default ThemeError;
