import loginStateService from "../services/login-state.service";
import { signInWithRedirect } from "aws-amplify/auth";

const redirectToOAuthLogin = ({ identityProvider, stateData }) => {
  const state = loginStateService.saveState(stateData);

  signInWithRedirect({
    provider: { custom: identityProvider },
    customState: state
  });
};

export default redirectToOAuthLogin;
