import { useEffect } from "react";
import PropTypes from "prop-types";

const usePreventScroll = (isScrollable) => {
  useEffect(() => {
    const handleScroll = (event) => {
      if (isScrollable) {
        event.preventDefault();
      }
    };

    if (isScrollable) {
      document.body.style.overflow = "hidden";
      document.addEventListener("scroll", handleScroll, { passive: false });
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollable]);
};

usePreventScroll.propTypes = {
  isScrollable: PropTypes.bool.isRequired
};

export default usePreventScroll;
