import types from "../actionTypes";

const INITIAL_STATE = {
  kpiCode: null
};

const deleteKPIsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.START_DELETING_KPI:
      return {
        ...state,
        kpiCode: action.payload.kpiCode
      };

    case types.STOP_DELETING_KPI:
      return {
        ...state,
        kpiCode: null
      };

    default:
      return state;
  }
};

export default deleteKPIsReducer;

export const selectDeleteKPICode = (data) => data.kpiCode;
