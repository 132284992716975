import { ReactComponent as ArrowRight } from "../../../assets/ArrowRight.svg";
import React from "react";
import styled from "styled-components";

const ArrowRightIcon = () => (
  <Container alt="->">
    <ArrowRight />
  </Container>
);

const Container = styled.div`
  margin-left: 10px;
  line-height: inherit;
  display: inline-block;

  svg {
    vertical-align: middle;
    path {
      fill: white;
    }
  }
`;

export default ArrowRightIcon;
