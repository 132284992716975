import { notification } from "antd";
import { POPUP_CONTAINER } from "../FullscreenProvider";
import {
  selectCloseNotificationKey,
  selectOpenNotificationData
} from "../../store/reducers";
import { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { openNotificationMessage } from "../../store/actions/notification-message";

const NotificationMessage = ({
  openNotificationData,
  closeNotificationKey,
  openNotificationMessage
}) => {
  const [notificationAPI, contextHolder] = notification.useNotification({
    getContainer: () => document.getElementById(POPUP_CONTAINER),
    stack: { threshold: 1 }
  });

  useEffect(() => {
    if (openNotificationData) {
      notificationAPI.open(openNotificationData);
      openNotificationMessage(null);
    }
  }, [openNotificationData]);

  useEffect(() => {
    if (closeNotificationKey) {
      notificationAPI.destroy(closeNotificationKey);
    }
  }, [closeNotificationKey]);

  return contextHolder;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openNotificationMessage
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  openNotificationData: selectOpenNotificationData(state),
  closeNotificationKey: selectCloseNotificationKey(state)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotificationMessage
);
