import React from "react";
import PropType from "prop-types";
import Heading from "../../Heading";
import { useString as s } from "../../StringProvider";
import styled from "styled-components";
import { useMobileMediaQuery } from "../../Responsive";
import Tooltip from "../../Tooltip";

const UnquantifiedKPIData = ({ kpi, disabled }) => {
  const isMobile = useMobileMediaQuery();

  const notQuantifiedText = s(
    "discovery.kpi.card.impact.notQuantified",
    "Not quantified"
  );

  return (
    <Container type={kpi.type}>
      <Heading level={isMobile ? "h5Small" : "h5"} color={"gray3"}>
        {s("discovery.kpi.card.impact.title", "Impact for You")}
      </Heading>
      <Tooltip title={kpi?.definition?.impactPercentageFormulaDescription}>
        <Heading level={"h4"} color={disabled ? "gray3" : undefined}>
          {kpi.definition.noFormulaReason
            ? kpi.definition.noFormulaReason
            : notQuantifiedText}
        </Heading>
      </Tooltip>
    </Container>
  );
};

const Container = styled.div``;

UnquantifiedKPIData.propTypes = {
  disabled: PropType.bool
};

export default UnquantifiedKPIData;
