import actionTypes from "../actionTypes";
import _ from "lodash";
import ValidationErrorLevels from "../../utils/validation-error-levels";

export const FileStates = {
  READY: "ready",
  UPLOADING: "uploading",
  UPLOAD_ERROR: "upload_error",
  UPLOAD_COMPLETE: "upload_complete",
  VALIDATING: "validating",
  VALIDATION_ERROR: "validation_error",
  VALIDATION_COMPLETE: "validation_complete",
  VALIDATION_COMPLETE_WITH_ERRORS: "validation_complete_with_errors",
  CANCELLING: "cancelling"
};

const INITIAL_STATE = {
  files: [],
  fileData: {}
};

const updateFileData = ({ fileData, query, changes }) => {
  const file = _.find(Object.values(fileData), query);
  const newFileData = { ...fileData };

  if (file) {
    newFileData[file.uid] = {
      ...file,
      ...changes
    };
  } else {
    newFileData[query.uid] = {
      ...changes,
      uid: query.uid
    };
  }

  return newFileData;
};

const fileList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_FILE_LIST:
      return {
        ...state,
        files: [],
        fileData: {}
      };

    case actionTypes.ADD_FILE_TO_LIST:
      return {
        ...state,
        files: [...state.files, action.payload]
      };

    case actionTypes.REMOVE_FILE_FROM_LIST:
      return {
        ...state,
        files: state.files.filter((file) => file.uid !== action.payload.uid)
      };

    case actionTypes.CREATE_FILE_SUCCESS:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { uid: action.parameters.description },
          changes: { fileId: action.payload.file._id }
        })
      };

    case actionTypes.CREATE_FILE_FAILURE:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { uid: action.parameters.description },
          changes: {
            state: FileStates.UPLOAD_ERROR
          }
        })
      };

    case actionTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { uid: action.parameters.file.uid },
          changes: { state: FileStates.UPLOADING }
        })
      };

    case actionTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { uid: action.parameters.file.uid },
          changes: { state: FileStates.UPLOAD_ERROR }
        })
      };

    case actionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { uid: action.parameters.file.uid },
          changes: { state: FileStates.UPLOAD_COMPLETE, uploadPercentage: 100 }
        })
      };

    case actionTypes.UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { uid: action.parameters.file.uid },
          changes: { uploadPercentage: action.payload.uploadPercentage }
        })
      };

    case actionTypes.CREATE_VALIDATION_REQUEST:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { fileId: action.parameters.fileId },
          changes: { state: FileStates.VALIDATING }
        })
      };

    case actionTypes.CREATE_VALIDATION_FAILURE:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { fileId: action.parameters.fileId },
          changes: { state: FileStates.VALIDATION_ERROR }
        })
      };

    case actionTypes.CREATE_VALIDATION_SUCCESS:
      return {
        ...state,
        fileData: updateFileData({
          fileData: state.fileData,
          query: { fileId: action.parameters.fileId },
          changes: {
            state: action.payload.validationErrors.length
              ? FileStates.VALIDATION_COMPLETE_WITH_ERRORS
              : FileStates.VALIDATION_COMPLETE,
            validationId: action.payload._id,
            validationErrors: action.payload.validationErrors,
            additionalData: action.payload.additionalData
          }
        })
      };
  }

  return state;
};

export const selectAllFiles = (fileList) => {
  return fileList.files;
};

export const selectFileState = (fileList, fileUID) => {
  return fileList.fileData[fileUID]?.state || FileStates.READY;
};

export const selectFileFileId = (fileList, fileUID) => {
  return fileList.fileData[fileUID]?.fileId;
};

export const selectFileUploadPercentage = (fileList, fileUID) => {
  return fileList.fileData[fileUID]?.uploadPercentage;
};

export const selectFileValidationId = (fileList, fileUID) => {
  return fileList.fileData[fileUID]?.validationId;
};

export const isFileListErrorFree = (fileList) => {
  const fileWithErrors = fileList.files.filter((f) => {
    const state = selectFileState(fileList, f.uid);

    if (
      state !== FileStates.VALIDATION_COMPLETE &&
      state !== FileStates.VALIDATION_COMPLETE_WITH_ERRORS
    ) {
      return true;
    }

    const validationErrors = selectFileValidationErrors(fileList, f.uid);

    return validationErrors.find(
      (e) => e.level === ValidationErrorLevels.ERROR
    );
  });

  return !!fileList.files.length && !fileWithErrors.length;
};

export const selectFileListValidationIds = (fileList) =>
  fileList.files
    .map((f) => fileList.fileData[f.uid]?.validationId)
    .filter((id) => id);

export const selectFileValidationErrors = (fileList, fileUID) =>
  fileList.fileData[fileUID]?.validationErrors || [];

export const selectFileAdditionalData = (fileList, fileUID) =>
  fileList.fileData[fileUID]?.additionalData || {};

export default fileList;
