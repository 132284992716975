export const sortDiscoveryKPIsByOrder = (a, b) => {
  const order = a.order - b.order;

  return order !== 0
    ? order
    : a.definition.name.localeCompare(b.definition.name);
};

export const sortDiscoveryKPIsByImportance = (a, b) => {
  const importance = b.importance - a.importance;

  if (importance !== 0) {
    return importance;
  }

  return sortDiscoveryKPIsByOrder(a, b);
};

export const sortDiscoveryChallengesByOrder = (a, b) => {
  const order = a.order - b.order;

  return order !== 0
    ? order
    : a.definition.description.localeCompare(b.definition.description);
};

export const sortDiscoveryChallengesByImportance = (a, b) => {
  const importance = b.importance - a.importance;

  if (importance !== 0) {
    return importance;
  }

  return sortDiscoveryChallengesByOrder(a, b);
};
