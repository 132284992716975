export const requestsStoreReset =
  ({ actionType }) =>
  (dispatch) => {
    if (actionType.slice(-5) !== "RESET") {
      throw new Error(`${actionType} is not a RESET action type `);
    }
    dispatch({
      type: actionType
    });
  };
