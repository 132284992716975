import Text from "./Text";
import styled from "styled-components";
import { useString as s } from "../components/StringProvider";

const PoweredByCuvama = () => {
  const today = new Date();
  const year = today.getFullYear();
  const message = s("page.footer.copyright", "Powered by © {year} Cuvama", {
    year
  });

  return (
    <Container>
      <Text variant={"small"} color={"gray4"}>
        {message}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 50px;
  width: 100%;
  background-color: transparent;
`;

export default PoweredByCuvama;
