import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import PoweredByCuvama from "../PoweredByCuvama";
import { Desktop, useMobileMediaQuery } from "../Responsive";

const SplitPageLayout = ({ children, image, ...props }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <Container {...props} isMobile={isMobile}>
      <ContentContainer>
        {children}
        <PoweredByCuvama />
      </ContentContainer>
      <Desktop>
        <SideBanner image={image} />
      </Desktop>
    </Container>
  );
};
const Container = styled.div`
  min-height: ${(props) =>
    props.hasHeader
      ? props.isMobile
        ? "calc(100vh - 64px)"
        : "calc(100vh - 80px)"
      : "100vh"};
  width: 100vw;
  max-width: 100%;
  min-width: fit-content;
  display: flex;
`;

const ContentContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > div:nth-child(1) {
    flex-grow: 1;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const SideBanner = styled.div`
  width: 50%;
  ${({ image }) => (image ? `background-image: url(${image});` : null)}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    min-width: 100%;
    min-height: 100%;
  }
`;

SplitPageLayout.propTypes = {
  image: PropTypes.string,
  hasHeader: PropTypes.bool
};

export default SplitPageLayout;
