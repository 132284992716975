import React from "react";
import { RichTextEditor } from "../../Input";
import { FormItem } from "../../Form";
import { useString as s } from "../../StringProvider";

const AboutThisFormItem = () => {
  const detailTextPlaceholder = s(
    "createKPI.popup.detailText.placeholder",
    "Add description (optional)"
  );

  return (
    <FormItem name="detailText">
      <RichTextEditor
        autoFocus={true}
        placeholder={detailTextPlaceholder}
        style={{ minHeight: 200 }}
        useMarkdown={true}
        size={"small"}
        paragraphMargin={true}
      />
    </FormItem>
  );
};

export default AboutThisFormItem;
