import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import ChallengeSummaryCards from "../../ChallengeSummary/ChallengeSummaryCards";
import PropType from "prop-types";

const PrintReportChallenges = ({ discovery }) => {
  const header = s(
    "report.challenges.header",
    "Challenges to be solved for {discoveryName}",
    { discoveryName: discovery.name }
  );

  return (
    <Container>
      <Heading level={"h1"} align={"center"}>
        {header}
      </Heading>
      <Heading level="h4" align="center">
        {s(
          "report.challenges.subheader",
          "The business challenges we will solve together to achieve your Success Outcomes"
        )}
      </Heading>
      <MarginContainer>
        <ChallengeSummaryCards reportId={discovery.reportId} printMode={true} />
      </MarginContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${themeProp("palette.surface")};
  display: flex;
  flex-direction: column;
`;

const MarginContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

PrintReportChallenges.propTypes = {
  discovery: PropType.object.isRequired
};

export default PrintReportChallenges;
