import types from "../actionTypes";

export const startDeletingKPI =
  ({ kpiCode }) =>
  (dispatch) => {
    dispatch({
      type: types.START_DELETING_KPI,
      payload: { kpiCode }
    });
  };

export const stopDeletingKPI = () => (dispatch) => {
  dispatch({
    type: types.STOP_DELETING_KPI
  });
};
