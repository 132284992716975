import { selectRequestData } from "../reducers";
import actionTypes from "../actionTypes";

export const selectLatestVersionNumber = (state) => {
  const latestVersion = selectRequestData(
    state,
    actionTypes.GET_LATEST_VERSION_REQUEST
  );

  return latestVersion ? latestVersion.version : null;
};
