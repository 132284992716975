import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import _ from "lodash";
import { getMenuItems, isInView, scrollToReportSection } from "./ScrollUtils";
import Progress from "../../../../components/Progress";
import PropTypes from "prop-types";
import { Phases } from "../../../../utils/phases";
import FooterMenuItem from "./FooterMenuItem";
import Icon from "../../../../components/Icon";

const MobileReportFooter = ({ template, discovery }) => {
  const [activeSection, setActiveSection] = useState();
  const [isVisible, setVisible] = useState(false);
  const [canScrollLeft, setScrollLeft] = useState(false);
  const [canScrollRight, setScrollRight] = useState(false);
  const menuItems = getMenuItems({ discovery, template });

  useEffect(() => {
    if (isVisible) {
      handleHorizontalScroll();
    }
  }, [isVisible]);

  useEffect(() => {
    if (discovery) {
      handleScroll();
    }
  }, [discovery]);

  const onClick = ({ sectionId }) => {
    scrollToReportSection({ sectionId });
  };

  const handleScroll = _.throttle(() => {
    let lastVisibleSection = false;
    let previousVisibleSection = false;

    for (let i = 0; i < menuItems.length; i++) {
      const item = menuItems[i];
      const element = document.getElementById(item.sectionId);

      if (element && isInView(element, 60, 124)) {
        previousVisibleSection = lastVisibleSection;
        lastVisibleSection = item.sectionId;
      }
    }

    setVisible(
      !isInView(document.getElementById("report-intro"), 60, 124) ||
        lastVisibleSection
    );
    setActiveSection(previousVisibleSection || lastVisibleSection);
  }, 100);

  const handleHorizontalScroll = _.throttle(() => {
    const element = document.getElementById("discoveries-navigation-container");

    setScrollLeft(element.scrollLeft > 0);
    setScrollRight(
      element.scrollLeft + element.clientWidth < element.scrollWidth
    );
  }, 100);

  useEffect(() => {
    if (discovery) {
      const element = document.getElementById(
        "discoveries-navigation-container"
      );

      window.addEventListener("scroll", handleScroll);
      element.addEventListener("scroll", handleHorizontalScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
        element.removeEventListener("scroll", handleHorizontalScroll);
      };
    }
  }, [discovery, handleScroll]);

  const onScrollLeft = () => {
    const element = document.getElementById("discoveries-navigation-container");
    element.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  const onScrollRight = () => {
    const element = document.getElementById("discoveries-navigation-container");
    element.scroll({
      top: 0,
      left: 10000,
      behavior: "smooth"
    });
  };

  return (
    <Container style={{ display: isVisible ? undefined : "none" }}>
      <ButtonContainer onClick={onScrollLeft}>
        {canScrollLeft ? <Icon name={"left"} colour={"secondary"} /> : null}
      </ButtonContainer>
      <NavigationContainer id={"discoveries-navigation-container"}>
        {menuItems.map((item, index) => (
          <FooterMenuItem key={index}>
            <Progress
              color={"secondary"}
              percent={100}
              showInfo={false}
              style={{
                visibility:
                  activeSection === item.sectionId ? "visible" : "hidden"
              }}
            />
            <MenuButton
              color={"secondary"}
              active={activeSection === item.sectionId}
              onClick={() => onClick({ sectionId: item.sectionId })}
              data-cy={`menu-button-${item.sectionId}`}
            >
              {item.name}
            </MenuButton>
          </FooterMenuItem>
        ))}
      </NavigationContainer>
      <ButtonContainer onClick={onScrollRight}>
        {canScrollRight ? <Icon name={"right"} colour={"secondary"} /> : null}
      </ButtonContainer>
    </Container>
  );
};

const MenuButton = styled.div`
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  color: ${(props) =>
    props.active
      ? themeProp("components.button." + props.color + ".base")
      : "inherit"};
`;

MenuButton.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary"])
};

MenuButton.defaultProps = {
  active: false,
  color: "primary"
};

const NavigationContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  margin: 0px 17px;
`;

const ButtonContainer = styled.div`
  width: 40px;
  max-width: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  background: ${themeProp("palette.surface")};
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 100%;
  -webkit-backface-visibility: hidden;
  z-index: 10;
  min-height: 64px;
  max-height: 64px;

  box-shadow: 0px 0px 4px rgba(5, 37, 67, 0.15);
  display: flex;
  flex-direction: row;
`;

MobileReportFooter.propTypes = {
  discovery: PropTypes.object,
  template: PropTypes.oneOf(Phases.ALL)
};

export default MobileReportFooter;
