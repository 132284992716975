import React, { useState } from "react";
import { compose } from "redux";
import { useString as s } from "../StringProvider";
import NewButton from "../NewButton";
import {
  hasPermission,
  isDiscoveryROIComplete,
  selectDiscoveryId,
  selectDiscoveryUsername
} from "../../store/reducers";
import Permissions from "../../utils/permissions";
import { connect } from "react-redux";
import RequestEvaluationModal from "../../Pages/ValueHypothesis/components/RequestEvaluationModal";

const RequestEvaluationButton = ({
  discoveryId,
  discoveryUsername,
  isROIComplete,
  canUpdateROIValues,
  hasLeadGenerationPermission
}) => {
  const [showRequestEvaluationModal, setShowRequestEvaluationModal] =
    useState(false);
  const evaluateROI = s("discovery.opportunity.page.button1", "Evaluate ROI");

  if (hasLeadGenerationPermission) {
    return null;
  }

  const onClick = () => {
    setShowRequestEvaluationModal(true);
  };

  return !isROIComplete && !canUpdateROIValues ? (
    <>
      <NewButton
        type={"primary"}
        data-cy={"evaluate-roi-button"}
        onClick={onClick}
      >
        {evaluateROI}
      </NewButton>
      {showRequestEvaluationModal ? (
        <RequestEvaluationModal
          discoveryId={discoveryId}
          discoveryUsername={discoveryUsername}
          onClose={() => setShowRequestEvaluationModal(false)}
        />
      ) : null}
    </>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    canUpdateROIValues: hasPermission(state, Permissions.UPDATE_ROI_VALUES),
    isROIComplete: isDiscoveryROIComplete(state),
    discoveryId: selectDiscoveryId(state),
    discoveryUsername: selectDiscoveryUsername(state),
    hasLeadGenerationPermission: hasPermission(
      state,
      Permissions.SEE_LEAD_GENERATION
    )
  };
};

export default compose(connect(mapStateToProps))(RequestEvaluationButton);
