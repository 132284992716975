import { useString as s } from "../StringProvider";
import NewButton from "../NewButton";
import React from "react";
import {
  hasPermission,
  selectDiscoveryId,
  selectDiscoveryMetricsCount
} from "../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Permissions from "../../utils/permissions";
import OnlyIfUserCanAccessDiscoveryPath from "../../Pages/Discovery/components/OnlyIfUserCanAccessDiscoveryPath";

const GoToBusinessMetricsButton = ({ discoveryId, metricsCount, history }) => {
  const showMeTheMoneyText = s(
    "discovery.kpi.summary.page.button1",
    "Explore value further"
  );

  const onShowMeTheMoney = () => {
    if (metricsCount) {
      history.push({
        pathname: `/discoveries/${discoveryId}/business-metrics`
      });
    } else {
      history.push({
        pathname: `/discoveries/${discoveryId}/value-hypothesis`
      });
    }
  };

  return (
    <OnlyIfUserCanAccessDiscoveryPath path={"business-metrics"}>
      <NewButton
        type={"primary"}
        onClick={onShowMeTheMoney}
        data-cy={"show-me-the-money-button"}
        className={"up-explore-value-further"}
      >
        {showMeTheMoneyText}
      </NewButton>
    </OnlyIfUserCanAccessDiscoveryPath>
  );
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    metricsCount: selectDiscoveryMetricsCount(
      state,
      hasPermission(state, Permissions.SEE_ALL_METRICS)
    )
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(GoToBusinessMetricsButton);
