import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import ROIChart from "../../ROI/components/ROIChart";

const PrintReportROIChart = () => {
  return (
    <Container>
      <ROIChart />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${themeProp("palette.background")};
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  & div {
    margin-top: 0;
  }

  & .card {
    display: flex;
    flex-direction: column;
  }

  & .card-body {
    flex-grow: 1;
  }
`;

export default PrintReportROIChart;
