import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import {
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryKPI
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CommentsButton } from "../../../../components/Discovery/Comments";
import { CardIconBar } from "../../../../components/Discovery/SelectableCard";
import DefinitionTypes from "../../../../utils/definition-types";
import StandardDetails from "./StandardDetails";
import MobileDetails from "./MobileDetails";
import CategoryBadges from "../../../../components/Discovery/CategoryBadges";
import { useMobileMediaQuery } from "../../../../components/Responsive";
import SettingsButton from "./SettingsButton";
import { startEditingKPI } from "../../../../store/actions/edit-kpis";
import {
  sendUserPilotEvent,
  UserPilotEventNames
} from "../../../../utils/user-pilot-util";

const FinancialCard = ({
  discoveryId,
  reportId,
  kpiCode,
  discoveryKPI,
  showAllChallenges,
  currency,
  startEditingKPI,
  printMode
}) => {
  const isMobile = useMobileMediaQuery();

  const openEditModal = (eventName) => {
    startEditingKPI({ kpiCode: discoveryKPI.kpiCode });
    sendUserPilotEvent({
      eventName,
      data: { discoveryId, code: kpiCode }
    });
  };

  const openEditModalSettingsButton = () => {
    openEditModal(UserPilotEventNames.VH_OPEN_SETTINGS);
  };

  const openEditModalValueSection = () => {
    openEditModal(UserPilotEventNames.VH_OPEN_VALUE_SECTION);
  };

  return (
    <Wrapper
      className={"financial-card" + (isMobile ? " mobile" : "")}
      checked={
        !reportId &&
        discoveryKPI.definition &&
        discoveryKPI.definition.status === DefinitionTypes.NEW &&
        !discoveryKPI.viewed
      }
      data-cy={`financial-card-${kpiCode}`}
    >
      <Content>
        <Title>
          <CategoryBadges
            definition={discoveryKPI.definition}
            showStatus={!reportId}
          />
          <CardIconBar>
            {!reportId && (
              <SettingsButton openEditModal={openEditModalSettingsButton} />
            )}
            <CommentsButton
              reportId={reportId}
              topic={`/discoveries/${discoveryId}/kpis/${discoveryKPI.kpiCode}`}
              eventName={UserPilotEventNames.ADD_NEW_COMMENT_VALUE_HYPOTHESIS}
            />
          </CardIconBar>
        </Title>
        {isMobile ? (
          <MobileDetails
            discoveryKPI={discoveryKPI}
            reportId={reportId}
            currency={currency}
          />
        ) : (
          <StandardDetails
            discoveryKPI={discoveryKPI}
            reportId={reportId}
            currency={currency}
            showAllChallenges={showAllChallenges}
            openEditModal={openEditModalValueSection}
            printMode={printMode}
          />
        )}
      </Content>
    </Wrapper>
  );
};

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Wrapper = styled.div`
  justify-content: center;
  width: 100%;
  display: inline-flex;
  padding: 24px 16px 24px 24px;
  background: ${themeProp("palette.surface")};
  border: 2px solid
    ${(props) =>
      props.checked ? themeProp("palette.primary")(props) : "transparent"};
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  border-radius: 12px;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  margin-top: 2px;
  margin-bottom: 2px;

  &:hover .card-icon-bar {
    visibility: visible;
  }

  &.mobile {
    padding: 16px 16px;
    border-radius: 8px;
  }
`;

FinancialCard.propTypes = {
  kpiCode: PropTypes.string.isRequired,
  reportId: PropTypes.string,
  printMode: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  return {
    discoveryId: selectDiscoveryId(state),
    currency: selectDiscoveryCurrency(state),
    discoveryKPI: selectDiscoveryKPI(state, props.kpiCode)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startEditingKPI
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FinancialCard
);
