import types from "../actionTypes";

export const clearFileList = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_FILE_LIST
  });
};

export const addFileToList =
  ({ file }) =>
  (dispatch) => {
    dispatch({
      type: types.ADD_FILE_TO_LIST,
      payload: file
    });
  };

export const removeFileFromList =
  ({ file }) =>
  (dispatch) => {
    dispatch({
      type: types.REMOVE_FILE_FROM_LIST,
      payload: file
    });
  };
