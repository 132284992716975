import Menu, { MenuItem } from "../../../../components/Menu";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown";
import NewButton from "../../../../components/NewButton";
import { useString as s } from "../../../../components/StringProvider";
import UpdateNotesTemplateModal from "../UpdateNotesTemplateModal";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { hasSandbox } from "../../../../store/selectors/config";
import UpdatePowerpointTemplatesModal from "../UpdatePowerpointTemplatesModal";
import UpdateMasterDataModal from "../UpdateMasterDataModal";
import styled from "styled-components";
import { ConfigurationModals } from "../../../../store/reducers/update-configuration-modals";
import {
  closeConfigurationModal,
  openConfigurationModal
} from "../../../../store/actions/update-configation-modals";
import { selectCurrentConfigurationModal } from "../../../../store/reducers";

const UpdateDropdown = ({
  hasSandbox,
  openConfigurationModal,
  closeConfigurationModal,
  modal
}) => {
  const updateText = s(
    "organization.page.manageConfiguration.update",
    "Update"
  );
  const notesTemplateText = s(
    "organization.page.manageConfiguration.notesTemplate",
    "Notes Template"
  );
  const powerpointTemplatesText = s(
    "organization.page.manageConfiguration.powerpointTemplates",
    "Powerpoint Template"
  );
  const masterDataText = s(
    "organization.page.manageConfiguration.masterData",
    "Master Data"
  );

  const onClick = (e) => {
    openConfigurationModal({ modal: e.key });
  };

  const renderMenu = () => (
    <StyledMenu onClick={onClick}>
      <MenuItem
        key={ConfigurationModals.POWERPOINT_TEMPLATES}
        icon={<Icon name={"powerpoint"} />}
      >
        {powerpointTemplatesText}
      </MenuItem>
      <MenuItem
        key={ConfigurationModals.MASTER_DATA}
        icon={<Icon name={"organization"} />}
      >
        {masterDataText}
      </MenuItem>
      <MenuItem
        key={ConfigurationModals.NOTES_TEMPLATE}
        icon={<Icon name={"paperEdit"} />}
      >
        {notesTemplateText}
      </MenuItem>
    </StyledMenu>
  );

  const onClose = () => {
    closeConfigurationModal();
  };

  const onCloseAndOpenMasterData = () => {
    openConfigurationModal({ modal: ConfigurationModals.MASTER_DATA });
  };

  const renderModal = () => {
    switch (modal) {
      case ConfigurationModals.NOTES_TEMPLATE:
        return <UpdateNotesTemplateModal onClose={onClose} />;
      case ConfigurationModals.POWERPOINT_TEMPLATES:
        return (
          <UpdatePowerpointTemplatesModal
            onClose={onClose}
            onCloseAndOpenMasterData={onCloseAndOpenMasterData}
          />
        );
      case ConfigurationModals.MASTER_DATA:
        return <UpdateMasterDataModal onClose={onClose} />;
    }

    return null;
  };

  return !hasSandbox ? (
    <>
      <Dropdown
        overlay={renderMenu()}
        placement={"bottomRight"}
        trigger={["click"]}
      >
        <NewButton type={"primaryDropdown"} data-cy={"update-dropdown"}>
          {updateText}
          <Icon name={"angleDown"} />
        </NewButton>
      </Dropdown>
      {renderModal()}
    </>
  ) : null;
};

const StyledMenu = styled(Menu)`
  min-width: 230px;
  max-width: 230px;
`;

const mapStateToProps = (state) => ({
  hasSandbox: hasSandbox(state),
  modal: selectCurrentConfigurationModal(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openConfigurationModal,
      closeConfigurationModal
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UpdateDropdown
);
