import React from "react";
import styled from "styled-components";
import { useString as s } from "../../../../components/StringProvider";
import Currency from "../../../../components/Currency";
import { themeProp } from "../../../../theme";
import Heading from "../../../../components/Heading";
import Range from "../../../../components/Range";
import { useMobileMediaQuery } from "../../../../components/Responsive";
import OneTimeBenefits from "./OneTimeBenefits";
import KPIName from "./KPIName";
import WhiteDivider from "./WhiteDivider";
import CurrencyRange from "./CurrencyRange";
import ToText from "./ToText";
import {
  impactPercentageKPIsFilter,
  impactValueKPIsFilter,
  sortKPIsByImportance,
  strategicKPIsFilter
} from "../../../../utils/filters/kpi-filters";

const Statistics = ({ kpis, currency }) => {
  const isMobile = useMobileMediaQuery();
  const strategicBenefitsText = s(
    "discovery.kpi.card.impact.strategicBenefits",
    "Strategic Benefits"
  );

  const impactValueKPIs = impactValueKPIsFilter(kpis);
  const impactPercentageKPIs = impactPercentageKPIsFilter(kpis);
  const strategicKPIs = strategicKPIsFilter(kpis);

  const totalLowImpactValue = impactValueKPIs.reduce(
    (sum, kpi) => sum + kpi.lowImpactValue,
    0
  );
  const totalHighImpactValue = impactValueKPIs.reduce(
    (sum, kpi) => sum + kpi.potentialImpactValue,
    0
  );

  sortKPIsByImportance({ kpis: impactValueKPIs });
  sortKPIsByImportance({ kpis: impactPercentageKPIs });
  sortKPIsByImportance({ kpis: strategicKPIs });

  return (
    <Wrapper data-cy={"value-hypothesis-statistics"}>
      <Title>
        {s("discovery.opportunity.page.stats.header", "VALUE HYPOTHESIS")}
      </Title>
      <CurrencyRange>
        <Currency
          format="short"
          currency={currency}
          data-cy={"sidebar-element"}
        >
          {totalLowImpactValue}
        </Currency>
        {totalLowImpactValue !== totalHighImpactValue ? (
          <>
            <ToText />
            <Currency
              format="short"
              currency={currency}
              data-cy={"sidebar-element"}
            >
              {totalHighImpactValue}
            </Currency>
          </>
        ) : null}
      </CurrencyRange>
      {impactValueKPIs.map((kpi, index) => (
        <KPIName
          key={index}
          className={isMobile ? "mobile" : undefined}
          variant={isMobile ? "statisticsMobile" : "bMedium"}
          data-cy={"sidebar-element"}
        >
          {kpi.definition.name}
        </KPIName>
      ))}
      <OneTimeBenefits kpis={kpis} currency={currency} />
      {impactPercentageKPIs.length > 0 ? <WhiteDivider /> : null}
      {impactPercentageKPIs.map((kpi, index) => (
        <div
          key={index}
          style={{ paddingBottom: 16 }}
          data-cy={`sidebar-element-${kpi.kpiCode}`}
        >
          <PercentageRange data-cy={"sidebar-element"}>
            <Range
              first={kpi.lowImpactPercentage}
              second={kpi.highImpactPercentage}
              type={kpi.definition.impactFormat}
              customImpactFormat={kpi.definition.customImpactFormat}
              impactPercentageFormulaDescription={
                kpi.definition.impactPercentageFormulaDescription
              }
            />
          </PercentageRange>
          <KPIName
            key={index}
            className={isMobile ? "mobile" : undefined}
            variant={isMobile ? "statisticsMobile" : "bMedium"}
            data-cy={"sidebar-element"}
          >
            {kpi.definition.name}
          </KPIName>
        </div>
      ))}
      {strategicKPIs.length > 0 ? (
        <>
          <WhiteDivider />
          <Heading level={"h3"} color={"surface"}>
            {strategicBenefitsText}
          </Heading>{" "}
        </>
      ) : null}
      {strategicKPIs.map((kpi, index) => (
        <KPIName
          key={index}
          className={isMobile ? "mobile" : undefined}
          variant={isMobile ? "statisticsMobile" : "bMedium"}
          data-cy={"sidebar-element"}
        >
          {kpi.definition.name}
        </KPIName>
      ))}
    </Wrapper>
  );
};

const Title = styled(Heading).attrs(() => ({
  level: "h5"
}))`
  color: white;
  margin-bottom: 16px;
`;

const PercentageRange = styled(Heading).attrs(() => ({
  level: "h3"
}))`
  color: white;
  margin-bottom: 0;

  & .ant-typography {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  border-radius: 8px;
  padding: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;

  @media print {
    box-shadow: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  background-color: ${themeProp("palette.secondary")};
`;

export default Statistics;
