import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ValueMapTextForm from "./ValueMapTextForm";
import { ValueMapCardContainer } from "./ValueMapCardUI";
import { Icon } from "../../components";
import ValueMapCardTickButton from "./ValueMapCardTickButton";
import { AutoComplete } from "antd";
import styled from "styled-components";
import ValueMapAutocompleteItem from "./ValueMapAutocompleteItem";
import { themeProp } from "../../theme";
import classNames from "classnames";
import { POPUP_CONTAINER } from "../../components/FullscreenProvider";

const CreateCard = ({
  onCreate,
  onCancel,
  searchLibrary,
  searchLibraryData,
  isCreateKeyObjective,
  placeholder
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const onCreateInternal = ({ text, code, fromLibrary }) => {
    if (text && text.trim()) {
      onCreate({ text, code, fromLibrary });
      return;
    }
    onCancel();
  };

  const onSelect = (value) => {
    const [text, code] = value.split("*/*");
    const data = { text, code, fromLibrary: true };

    onCreateInternal(data);
    onCancel();
  };

  const getCategory = (categories, categoryCode) =>
    categories.find(({ code }) => code === categoryCode)?.name ?? null;

  const onSearch = (value) => {
    setSearchTerm(value);

    if (value.length <= 1) {
      setOptions([]);
      return;
    }

    searchLibrary(value);
    setSelectedOption(null);
  };

  useEffect(() => {
    if (searchLibraryData?.items && searchTerm) {
      const mappedSearchLibraryData = searchLibraryData.items.map((item) => ({
        value: item.name + "*/*" + item.code,
        label: (
          <ValueMapAutocompleteItem
            key={item.code}
            category={getCategory(
              searchLibraryData.categories,
              item.categoryCode
            )}
            itemCode={item.code}
            searchTerm={searchTerm}
          >
            {item.name}
          </ValueMapAutocompleteItem>
        )
      }));

      setOptions(mappedSearchLibraryData);
    }
  }, [searchLibraryData, searchTerm]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      const index = options.findIndex((opt) => opt.value === selectedOption);

      const increment = e.key === "ArrowUp" ? -1 : 1;

      const newIndex = (index + increment + options.length) % options.length;

      setSelectedOption(options[newIndex]?.value);
    }

    if (e.key === "Enter") {
      if (selectedOption) {
        onSelect(selectedOption);
      } else {
        onCreateInternal({ text: e.target.value });
      }

      e.preventDefault();
    }
  };

  const dropdownCustomStyle = {
    borderRadius: "0px 0px 6px 6px",
    position: "absolute"
  };

  return (
    <StyledAutoComplete
      options={options}
      onSelect={onSelect}
      onSearch={onSearch}
      dropdownStyle={dropdownCustomStyle}
    >
      <ValueMapCardContainer
        className={classNames("selected", "editing", "creating", {
          "autocomplete-dropdown-visible": options.length,
          "autocomplete-dropdown-not-visible": !options.length,
          "creating-key-objective": isCreateKeyObjective
        })}
        data-cy={"value-map-create-card"}
      >
        <ValueMapCardTickButton data-cy={`create-card-tick`}>
          <Icon name={"tick"} size={"large"} />
        </ValueMapCardTickButton>
        <ValueMapTextForm
          text={""}
          onCancel={onCancel}
          onSubmit={onCreateInternal}
          customKeydown={handleKeyDown}
          placeholder={placeholder}
        />
      </ValueMapCardContainer>
    </StyledAutoComplete>
  );
};

const StyledAutoComplete = styled(AutoComplete).attrs(() => ({
  getPopupContainer: () => document.getElementById(POPUP_CONTAINER)
}))`
  height: fit-content;
  width: calc(100% - 6px);
  transform: translateX(3px);
  textarea {
    font-size: ${themeProp("typography.body.fontSize")};
    color: ${themeProp("palette.black")};
  }
`;

CreateCard.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchLibrary: PropTypes.func.isRequired,
  searchLibraryData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        categoryCode: PropTypes.string.isRequired
      })
    ).isRequired,
    categories: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
      start: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  isCreateKeyObjective: PropTypes.bool,
  placeholder: PropTypes.string
};

CreateCard.defaultProps = {
  isCreateKeyObjective: false
};

export default CreateCard;
