import Heading from "../../Heading";
import Markdown from "../Markdown";
import ChallengeLink from "./ChallengeLink";
import AboutThisCard from "../AboutThisCard";
import React from "react";
import { useString as s } from "../../StringProvider";
import PropType from "prop-types";
import ChallengeTable from "./ChallengeTable";
import { Space } from "antd";
import formatMarkdownText from "../../../utils/format-markdown-text";
import AboutThisFormItem from "./AboutThisFormItem";

const AboutThisChallenge = ({
  discoveryChallenge,
  reportMode,
  editMode,
  canSeeInternalLinksAndComments,
  printMode
}) => {
  const aboutThisChallengeText = s(
    "discovery.challenges.card.aboutThisKPI",
    "About this Challenge"
  );
  const sourcesText = s(
    "discovery.challenges.card.slider.more.section2.title",
    "Sources"
  );

  const links =
    discoveryChallenge?.definition?.links.filter(
      (link) =>
        (!reportMode && canSeeInternalLinksAndComments) ||
        link.internalOnly === false ||
        link.internalOnly === undefined
    ) || [];

  return (
    <AboutThisCard
      text={aboutThisChallengeText}
      defaultExpandedState={editMode}
      printMode={printMode}
    >
      <Space direction={"vertical"} size={8}>
        {!editMode && (
          <Markdown size={"small"} paragraphMargin={true}>
            {formatMarkdownText(discoveryChallenge?.definition?.detailText)}
          </Markdown>
        )}
        {editMode && <AboutThisFormItem />}
        {!editMode && (
          <div>
            <ChallengeTable
              discoveryChallenge={discoveryChallenge}
              editable={!editMode}
            />
          </div>
        )}
        {links.length && !editMode ? (
          <>
            <Heading level={"h5"} color={"gray3"}>
              {sourcesText}
            </Heading>
            {links.map((link, index) => (
              <ChallengeLink link={link} key={index} />
            ))}
          </>
        ) : null}
      </Space>
    </AboutThisCard>
  );
};

AboutThisChallenge.propTypes = {
  discoveryChallenge: PropType.object.isRequired,
  editMode: PropType.bool,
  reportMode: PropType.bool,
  printMode: PropType.bool
};

export default AboutThisChallenge;
