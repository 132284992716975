import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useString as s } from "../../../../components/StringProvider";
import Table from "../../../../components/Table";
import { getAllConversations } from "../../../../store/actions/ai";
import useLoadingState from "../../../../utils/use-loading-state";
import { message } from "antd";
import ButtonBar from "../ButtonBar";
import Link from "../../../../components/Link";
import DescriptionList from "../../../../components/DescriptionList";

const AIConversations = ({
  loadingState,
  conversations,
  pagination,
  getAllConversations
}) => {
  const errorText = s(
    "aiConversations.page.messages.getConversationsFailed",
    "Failed to load conversations"
  );
  const [start, setStart] = useState(0);
  const [sort, setSort] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [count, setCount] = useState(10);

  const data =
    (conversations &&
      conversations.map((conversation) => ({
        ...conversation,
        key: conversation._id,
        updatedAt: new Date(conversation.updatedAt).toDateString(),
        createdAt: new Date(conversation.createdAt).toDateString(),
        options: conversation.options.join(", ")
      }))) ||
    [];

  useEffect(() => {
    getAllConversations({ start, count, sort, order });
  }, [start, sort, order, count]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      message.error(errorText);
    }
  );

  const columns = [
    {
      title: s(
        "aiConversations.page.table.conversation.header",
        "Conversation Id"
      ),
      dataIndex: "_id",
      key: "_id",
      sorter: false,
      render: (text, record) => (
        <Link to={`/organizations/cuvama-ai/conversations/${record._id}`}>
          {text}
        </Link>
      )
    },
    {
      title: s("aiConversations.page.table.accountName.header", "Discovery"),
      dataIndex: "accountName",
      key: "accountName",
      sorter: false,
      render: (text, record) => (
        <DescriptionList>
          <dt>
            {s("aiConversations.page.table.accountName.label", "Account Name")}
          </dt>
          <dd>{record.accountName}</dd>
          <dt>
            {s(
              "aiConversations.page.table.opportunityName.label",
              "Opportunity Name"
            )}
          </dt>
          <dd>{record.opportunityName}</dd>
          <dt>{s("aiConversations.page.table.version.label", "Version")}</dt>
          <dd>{record.version}</dd>
        </DescriptionList>
      )
    },
    {
      title: s("aiConversations.page.table.username.header", "Username"),
      dataIndex: "username",
      key: "username",
      sorter: false
    },
    {
      title: s("aiConversations.page.table.options.header", "Options"),
      dataIndex: "options",
      key: "options",
      sorter: false
    },
    {
      title: s("aiConversations.page.table.prompt.header", "Prompt"),
      dataIndex: "prompt",
      key: "prompt",
      sorter: false,
      ellipsis: { showTitle: true }
    },
    {
      title: s("aiConversations.page.table.createdAt.header", "Created At"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: false
    }
  ];

  const currentPagination = pagination
    ? {
        current: Math.floor(pagination.start / count) + 1,
        total: pagination.total,
        pageSize: count
      }
    : undefined;

  const handleTableChange = (pagination, filters, sorter) => {
    const mapOrder = (order) => {
      switch (order) {
        case "ascend":
          return "asc";
        case "descend":
          return "desc";
        default:
          return;
      }
    };

    if (pagination.current) {
      setStart((pagination.current - 1) * pagination.pageSize);
    }

    setCount(pagination.pageSize);

    if (sorter.order) {
      setSort(sorter.columnKey);
      setOrder(mapOrder(sorter.order));
    } else {
      setSort(undefined);
      setOrder(undefined);
    }
  };

  return (
    <Container>
      <ButtonBar />
      <Table
        columns={columns}
        dataSource={data}
        variant={"smallBody"}
        pagination={currentPagination}
        onChange={handleTableChange}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 24px;
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_ALL_CONVERSATIONS_REQUEST
  ),
  conversations: selectRequestData(
    state,
    actionTypes.GET_ALL_CONVERSATIONS_REQUEST
  )?.items,
  pagination: selectRequestData(
    state,
    actionTypes.GET_ALL_CONVERSATIONS_REQUEST
  )?.pagination
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllConversations
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AIConversations
);
