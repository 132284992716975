import styled from "styled-components";
import { themeProp } from "../theme";

const DescriptionList = styled.dl`
  margin: 0;

  & dd {
    font-size: ${themeProp(`typography.small.fontSize`)};
    font-family: ${themeProp(`typography.small.fontFamily`)};
    font-weight: ${themeProp(`typography.small.fontWeight`)};
    line-height: ${themeProp(`typography.small.lineHeight`)};
    color: ${themeProp(`palette.body`)};
  }

  & dt {
    font-size: ${themeProp(`typography.small.fontSize`)};
    font-family: ${themeProp(`typography.small.fontFamily`)};
    font-weight: ${themeProp(`typography.small.fontWeight`)};
    line-height: ${themeProp(`typography.small.lineHeight`)};
    color: ${themeProp(`palette.gray4`)};
    margin-right: 8px;
  }

  & dt:before {
    content: "";
    display: block;
  }

  & dt,
  & dd {
    display: inline;
  }
`;

export default DescriptionList;
