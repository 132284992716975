import Heading from "../../../../components/Heading";
import Text from "../../../../components/Text";
import { useString as s } from "../../../../components/StringProvider";
import styled from "styled-components";
import PropTypes from "prop-types";
import ConfigurationCardTypes from "./ConfigurationCardTypes";
import DescriptionList from "../../../../components/DescriptionList";
import Badge from "../../../../components/Discovery/Badge";
import { themeProp } from "../../../../theme";
import { compose } from "redux";
import { connect } from "react-redux";
import { hasPermission } from "../../../../store/reducers";
import Permissions from "../../../../utils/permissions";
import { hasSandbox } from "../../../../store/selectors/config";
import DownloadMasterDataButton from "../ConfigurationLogs/DownloadMasterDataButton";

const ConfigurationMasterData = ({
  masterData,
  cardType,
  canUpdate,
  hasSandbox
}) => {
  const header = s(
    "organization.page.currentConfiguration.masterData.header",
    "MD {version}",
    { version: masterData?.version }
  );
  const sandbox = s(
    "organization.page.currentConfiguration.masterData.sandbox",
    "Sandbox"
  );
  const production = s(
    "organization.page.currentConfiguration.masterData.production",
    "Production"
  );
  const noTemplateCanUpdate = s(
    "organization.page.currentConfiguration.masterData.noTemplateCanUpdate",
    'Nothing here yet. To add master data, click the "Update" button above.'
  );
  const noTemplate = s(
    "organization.page.currentConfiguration.masterData.noTemplate",
    "Nothing here yet."
  );
  const lastUpdated = s(
    "organization.page.currentConfiguration.masterData.lastUpdated",
    "Last Updated"
  );
  const updatedBy = s(
    "organization.page.currentConfiguration.masterData.updatedBy",
    "Updated By"
  );
  const mdModel = s(
    "organization.page.currentConfiguration.masterData.mdModel",
    "MD Model"
  );
  const createdByDefault = s(
    "accessManagement.page.roles.createdBy.default",
    "Cuvama"
  );

  return (
    <Container>
      <TitleBar>
        <Heading level={"h4"}>
          {header}
          {cardType === ConfigurationCardTypes.SANDBOX && !hasSandbox ? (
            <Badge bg={themeProp("palette.focused")} color={"surface"}>
              {sandbox}
            </Badge>
          ) : (
            <Badge bg={themeProp("palette.success")} color={"surface"}>
              {production}
            </Badge>
          )}
        </Heading>
        {masterData?.fileId && (
          <DownloadMasterDataButton fileId={masterData?.fileId} />
        )}
      </TitleBar>
      {!!masterData ? (
        <>
          <DescriptionList>
            <dt>{lastUpdated}</dt>
            <dd>{new Date(masterData.deployedAt).toDateString()}</dd>
            <dt>{updatedBy}</dt>
            <dd>{masterData.username || createdByDefault}</dd>
            <dt>{mdModel}</dt>
            <dd>{masterData.templateVersion}</dd>
          </DescriptionList>
          <Text>{masterData.description}</Text>
        </>
      ) : (
        <Text variant={"small"} color={"gray4"}>
          {canUpdate && cardType === ConfigurationCardTypes.SANDBOX
            ? noTemplateCanUpdate
            : noTemplate}
        </Text>
      )}
    </Container>
  );
};

const Container = styled.div`
  & h4 {
    vertical-align: baseline;
  }

  & h4 span {
    margin-left: 8px;
  }
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
`;

ConfigurationMasterData.propTypes = {
  masterData: PropTypes.object,
  cardType: PropTypes.oneOf(ConfigurationCardTypes.ALL)
};

const mapStateToProps = (state) => ({
  canUpdate:
    hasPermission(state, Permissions.UPDATE_CONFIGURATION) &&
    !hasSandbox(state),
  hasSandbox: hasSandbox(state)
});

export default compose(connect(mapStateToProps))(ConfigurationMasterData);
