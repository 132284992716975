import { useEffect, useState } from "react";
import { message, Space } from "antd";
import NewButton from "../NewButton";
import Icon from "../Icon";
import styled from "styled-components";
import { themeProp } from "../../theme";
import {
  hasPermission,
  selectDiscoveryId,
  selectRequestData,
  selectRequestState,
  selectChallengeLibraryOpen,
  selectRequestParameters
} from "../../store/reducers";
import Permissions from "../../utils/permissions";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import {
  setChallengeLibraryOpen,
  setScrollToChallengeCode
} from "../../store/actions/challenge-library";
import { startCreatingChallenge } from "../../store/actions/create-challenges";
import {
  addDiscoveryChallengeFromLibrary,
  getDiscoveryChallengeLibrary
} from "../../store/actions/discoveries";
import ChallengeLibraryGroup from "./ChallengeLibraryGroup";
import { useMobileMediaQuery } from "../Responsive";
import Heading from "../Heading";
import SearchBar from "../SearchBar";
import { useString as s } from "../StringProvider";
import Text from "../Text";
import { stopDeletingChallenge } from "../../store/actions/delete-challenges";
import { Tooltip } from "../index";
import LibraryDrawer from "./LibraryDrawer";
import { ReactComponent as InfoIcon } from "../../assets/InfoCircleOutlined.svg";

export const CHALLENGE_LIBRARY_COOKIE_NAME = "CHALLENGE_LIBRARY_COOKIE_NAME";

const ChallengeLibrary = ({
  canSeeChallengeLibrary,
  setChallengeLibraryOpen,
  challengeLibraryOpen,
  getDiscoveryChallengeLibrary,
  challengeLibrary,
  loadingState,
  addChallengeLoadingState,
  removeChallengeLoadingState,
  deleteChallengeLoadingState,
  discoveryId,
  startCreatingChallenge,
  stopDeletingChallenge,
  addDiscoveryChallengeFromLibrary,
  setScrollToChallengeCode,
  newChallengeCode,
  deleteParameters,
  removeParameters
}) => {
  const isMobile = useMobileMediaQuery();
  const start = 0;
  const pageSize = 100;
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState("asc");

  const hideText = s("discovery.challengeLibrary.hide.text", "Hide");
  const sortText = s("discovery.challengeLibrary.sort.text", "Sort A - Z");
  const toolTipTitle = s(
    "discovery.challengeLibrary.tooltip.title",
    "Challenges Library"
  );
  const challengeLibraryTitle = s(
    "discovery.challengeLibrary.title",
    "Challenges Library"
  );
  const emptyLibraryText = s(
    "discovery.challengeLibrary.empty",
    "All pre-configured challenges have been added to the discovery."
  );

  const noSearchResultsText = s(
    "discovery.challengeLibrary.searchResults.empty",
    "We didn't find any matching challenges. Please try again or add a custom challenge."
  );

  const errorText = s(
    "discovery.challengeLibrary.messages.getFailed",
    "The Challenges Library could not be loaded"
  );
  const addSucceeded = s(
    "discovery.challengeLibrary.messages.added",
    "Challenge added to the discovery!"
  );
  const addFailed = s(
    "discovery.challengeLibrary.messages.addFailed",
    "Failed to add challenge from library"
  );
  const removeSucceeded = s(
    "discovery.challenge.card.removeFromList.succeeded",
    "Challenge removed from the discovery!"
  );
  const removeCBISucceeded = s(
    "discovery.challenge.card.removeFromList.cbi.succeeded",
    "CBI removed from the discovery!"
  );
  const removeFailed = s(
    "discovery.challenge.card.removeFromList.failed",
    "Failed to remove challenge from the discovery!"
  );
  const deleteSucceeded = s(
    "discovery.challenge.card.delete.succeeded",
    "Challenge deleted from the discovery!"
  );
  const deleteCBISucceeded = s(
    "discovery.challenge.card.delete.cbi.succeeded",
    "CBI deleted from the discovery!"
  );
  const deleteFailed = s(
    "discovery.challenge.card.delete.failed",
    "Failed to delete the challenge from the discovery"
  );
  const searchPlaceholderText = s(
    "discovery.challengeLibrary.search.placeholder",
    "Search by challenge description"
  );
  const addCustomChallengeText = s(
    "discovery.challengeLibrary.addCustomChallenge.text",
    "Add Custom Challenge"
  );

  const doQuery = () => {
    const query = {
      discoveryId,
      start,
      count: pageSize,
      order
    };

    if (searchTerm) {
      query.search = searchTerm;
    }

    getDiscoveryChallengeLibrary(query);
  };

  useEffect(() => {
    if (challengeLibraryOpen && discoveryId) {
      doQuery();
    }
  }, [discoveryId, searchTerm, order, challengeLibraryOpen]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      message.error(errorText);
    }
  );

  useLoadingState(
    addChallengeLoadingState,
    () => {
      message.success(addSucceeded);
      doQuery();
      setScrollToChallengeCode({ challengeCode: newChallengeCode });
    },
    () => {
      message.error(addFailed);
    }
  );

  useLoadingState(
    removeChallengeLoadingState,
    () => {
      message.success({
        content: removeParameters.isCBI ? removeCBISucceeded : removeSucceeded,
        icon: <InfoIcon />
      });
      doQuery();
    },
    () => {
      message.error(removeFailed);
    }
  );

  useLoadingState(
    deleteChallengeLoadingState,
    () => {
      message.success({
        content: deleteParameters.isCBI ? deleteCBISucceeded : deleteSucceeded,
        icon: <InfoIcon />
      });
      stopDeletingChallenge();
      doQuery();
    },
    () => {
      message.error(deleteFailed);
      stopDeletingChallenge();
    }
  );

  const onClickAddCustomChallenge = () => {
    startCreatingChallenge();
  };

  const onClick = () => {
    setChallengeLibraryOpen({ open: true });
  };

  const onClose = () => {
    setChallengeLibraryOpen({ open: false });
  };

  const onAddChallenge = (challengeCode) => {
    addDiscoveryChallengeFromLibrary({ discoveryId, challengeCode });
  };

  if (!canSeeChallengeLibrary) {
    return null;
  }

  const challenges = (challengeLibrary && challengeLibrary.items) || [];

  const categoryCodes = (
    (challengeLibrary && challengeLibrary.categories) ||
    []
  ).map((category) => category.code);

  const onSearch = (e) => {
    setSearchTerm(e);
  };

  const doOrder = () => {
    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  };

  const angleUp = <Icon name={"angleUp"} colour={"primary"} size={"base"} />;
  const angleDown = (
    <Icon name={"angleDown"} colour={"primary"} size={"base"} />
  );

  const renderTitle = () => (
    <div>
      <HideContainer>
        <Heading
          level={isMobile ? "h4" : "h3"}
          data-cy={"challenge-library-title"}
        >
          <b>{challengeLibraryTitle}</b>
        </Heading>
        <NewButton
          type={"text"}
          onClick={onClose}
          data-cy={"close-challenge-library"}
          className={"up-close-challenge-library"}
        >
          {hideText}
          <Icon name={"right"} colour={"primary"} size={"medium"} />
        </NewButton>
      </HideContainer>
      <SearchBar
        placeholder={searchPlaceholderText}
        onSearch={onSearch}
        data-cy={"search-challenge-library"}
      />
      <SortAndToggleRow>
        <Sort onClick={doOrder}>
          <NewButton type={"text"}>
            {sortText}
            {order === "asc" ? angleDown : angleUp}
          </NewButton>
        </Sort>
      </SortAndToggleRow>
    </div>
  );
  const renderFooter = () => (
    <div>
      <KPILibraryItemContainer onClick={onClickAddCustomChallenge}>
        <Space direction={"horizontal"} size={16}>
          <NewButton
            type={"iconPrimary"}
            onClick={onClickAddCustomChallenge}
            data-cy={"add-custom-challenge"}
          >
            <Icon
              data-cy="challenge-library-add-custom-kpi-icon"
              name={"plusCircle"}
              size={"large"}
              colour={"primary"}
            />
          </NewButton>
          <Text variant={"label"} color={"primary"}>
            <b>{addCustomChallengeText}</b>
          </Text>
        </Space>
      </KPILibraryItemContainer>
    </div>
  );

  return (
    <Container data-cy="challenge-library" isMobile={isMobile}>
      <NewButton
        data-cy="open-challenge-library"
        type={"iconPrimary"}
        onClick={onClick}
        className={"up-open-challenges-library"}
      >
        <Tooltip title={toolTipTitle} placement={"left"}>
          <Icon name={"left"} size={"large"} />
        </Tooltip>
      </NewButton>
      <SideBar isMobile={isMobile} />
      <LibraryDrawer
        open={challengeLibraryOpen}
        onClose={onClose}
        title={renderTitle()}
        footer={renderFooter()}
        className={"up-challenges-library-header"}
      >
        <Contents data-cy="challenge-library-contents">
          <Space direction={"vertical"} size={24} style={{ width: "100%" }}>
            {categoryCodes.map((categoryCode) => (
              <ChallengeLibraryGroup
                key={categoryCode}
                categoryCode={categoryCode}
                onAdd={onAddChallenge}
                searchTerm={searchTerm}
              />
            ))}
          </Space>
          {!challenges.length && (
            <EmptyLibraryContainer data-cy={"empty-library-container"}>
              <Text color={"primary"} alignment={"center"}>
                {searchTerm ? noSearchResultsText : emptyLibraryText}
              </Text>
            </EmptyLibraryContainer>
          )}
        </Contents>
      </LibraryDrawer>
    </Container>
  );
};

const Sort = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const SortAndToggleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 8px; //margin-bottom of SearchBar is 16px
  margin-bottom: 24px;
`;

const KPILibraryItemContainer = styled.div`
  padding: 16px 24px 16px 0;
  border-color: ${themeProp("palette.gray1")};
  border-width: 0;
  border-radius: 8px;

  &:hover {
    background-color: ${themeProp(`components.newButton.text.backgroundHover`)};
    cursor: pointer;
  }
`;

const HideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 17px;
  margin-top: 7px;
`;

const Container = styled.div`
  min-height: calc(100vh - 140px);
  max-width: 40px;
  background-color: ${themeProp("palette.surface")};
  position: fixed;
  right: 0;
  top: ${({ isMobile }) => (isMobile ? "100px" : "140px")};
  z-index: 1;
  padding-top: 24px;
`;

const SideBar = styled.div`
  min-height: calc(100vh - 140px);
  max-width: 5px;
  background-color: ${themeProp("palette.surface")};
  position: fixed;
  right: 0;
  top: ${({ isMobile }) => (isMobile ? "100px" : "140px")};
  z-index: 1;
  padding-top: 24px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EmptyLibraryContainer = styled.div`
  max-width: 250px;
  align-self: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const mapStateToProps = (state) => {
  return {
    canSeeChallengeLibrary: hasPermission(
      state,
      Permissions.SEE_CHALLENGE_LIBRARY
    ),
    challengeLibraryOpen: selectChallengeLibraryOpen(state),
    challengeLibrary: selectRequestData(
      state,
      actionTypes.GET_CHALLENGE_LIBRARY_REQUEST
    ),
    loadingState: selectRequestState(
      state,
      actionTypes.GET_CHALLENGE_LIBRARY_REQUEST
    ),
    addChallengeLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_REQUEST
    ),
    removeChallengeLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_REQUEST
    ),
    deleteChallengeLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_DELETE_CHALLENGE_REQUEST
    ),
    discoveryId: selectDiscoveryId(state),
    newChallengeCode: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_REQUEST
    )?.challengeCode,
    deleteParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_DELETE_CHALLENGE_REQUEST
    ),
    removeParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setChallengeLibraryOpen,
      getDiscoveryChallengeLibrary,
      addDiscoveryChallengeFromLibrary,
      startCreatingChallenge,
      stopDeletingChallenge,
      setScrollToChallengeCode
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChallengeLibrary
);
