import DefinitionTypes from "../../../../utils/definition-types";
import KPITypes from "../../../../utils/kpi-types";
import NewKPIImpactPercentage from "./NewKPIImpactPercentage";
import Range from "../../../../components/Range";
import PropType from "prop-types";
import Text from "../../../../components/Text";
import { useMobileMediaQuery } from "../../../../components/Responsive";

//TODO rename to DiscoveryKPIImpactUnits?
const DiscoveryKPIPercentage = ({ discoveryKPI, reportId }) => {
  const isMobile = useMobileMediaQuery();

  return discoveryKPI.definition.status === DefinitionTypes.NEW ||
    discoveryKPI.definition.type === KPITypes.UNQUANTIFIED ? (
    <NewKPIImpactPercentage
      discoveryKPI={discoveryKPI}
      reportMode={!!reportId}
    />
  ) : (
    <Text variant={isMobile ? "bMedium" : "h3"}>
      <Range
        first={discoveryKPI.lowImpactPercentage}
        second={discoveryKPI.highImpactPercentage}
        type={discoveryKPI.definition.impactFormat}
        customImpactFormat={discoveryKPI.definition.customImpactFormat}
      />
    </Text>
  );
};

DiscoveryKPIPercentage.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  reportId: PropType.string
};

export default DiscoveryKPIPercentage;
