import React from "react";
import { ConfigProvider, Slider as ANTSlider } from "antd";
import { selectRequestData } from "../store/reducers";
import actionTypes from "../store/actionTypes";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { themeProp } from "../theme";
import PropTypes from "prop-types";
import { useMobileMediaQuery } from "./Responsive";
import { getTooltipDefaultAttributes } from "./Tooltip";

const Slider = ({ theme, color, ...props }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            dotSize: 8,
            dotBorderColor: theme.palette.gray2,
            dotActiveBorderColor: theme.palette[color],
            railBg: theme.palette.gray2,
            railHoverBg: theme.palette.gray2,
            trackBg: theme.palette[color],
            trackHoverBg: theme.palette[color],
            trackBgDisabled: theme.palette[color],
            handleActiveColor: theme.palette[color],
            handleColor: theme.palette[color],
            handleLineWidth: 4,
            handleLineWidthHover: 4,
            handleSize: isMobile ? 10 : props.range ? 4 : 8,
            handleSizeHover: isMobile ? 10 : props.range ? 4 : 8
          }
        }
      }}
    >
      <StyledSlider
        included={false}
        {...props}
        tooltip={{
          ...props.tooltip,
          placement: "top",
          ...getTooltipDefaultAttributes({ theme })
        }}
      />
    </ConfigProvider>
  );
};

const StyledSlider = styled(ANTSlider)`
  font-family: ${themeProp(`typography.body.fontFamily`)} !important;
  margin: 0;

  & .ant-slider-dot {
    border-width: 2px;
  }
`;

Slider.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"])
};

Slider.defaultProps = {
  color: "primary"
};

const mapStateToProps = (state) => {
  return {
    theme: selectRequestData(state, actionTypes.GET_THEME_REQUEST) || {}
  };
};

export default compose(withRouter, connect(mapStateToProps))(Slider);
