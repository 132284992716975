import { Phases } from "../utils/phases";
import { useString as s } from "./StringProvider";

const PhaseName = ({ phase }) => {
  const questions = s("phases.questions", "Segmentation");
  const challengesSolved = s("phases.challengesSolved", "Challenges");
  const kpisImpacted = s("phases.kpisImpacted", "Outcomes");
  const metrics = s("phases.metrics", "Inputs");
  const valueHypothesis = s("phases.valueHypothesis", "Value Hypothesis");
  const returnOnInvestment = s("phases.returnOnInvestment", "ROI");

  switch (phase) {
    case Phases.QUESTIONS:
      return questions;
    case Phases.CHALLENGES_SOLVED:
      return challengesSolved;
    case Phases.KPIS_IMPACTED:
      return kpisImpacted;
    case Phases.METRICS:
      return metrics;
    case Phases.VALUE_HYPOTHESIS:
      return valueHypothesis;
    case Phases.ROI:
      return returnOnInvestment;
  }
};

export default PhaseName;
