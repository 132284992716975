import OrganizationHeader from "../OrganizationHeader";
import { OrganizationPageLayout } from "../../../components/Layout";
import Heading from "../../../components/Heading";
import NavigationMenu from "../../../components/NavigationMenu";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from "react-router-dom";
import CuvamaRoute from "../../../routes/CuvamaRoute";
import { NotFound } from "../../ErrorPage";
import Page from "../../../components/Page";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../components/StringProvider";
import AIPromptTemplates from "./AIPromptTemplates";
import AIConversations from "./AIConversations";
import styled from "styled-components";
import { compose } from "redux";
import AIConversationDetails from "./AIConversationDetails";
import { selectIsCuvamaUser } from "../../../store/reducers";
import { connect } from "react-redux";

const Pages = {
  TEMPLATES: "templates",
  CONVERSATIONS: "conversations"
};
Pages.ALL = Object.values(Pages);

const getSelectedPage = (pathname) => {
  for (const page of Pages.ALL) {
    if (pathname.includes(page)) {
      return page;
    }
  }

  return Pages.TEMPLATES;
};

const CuvamaAI = ({ history, isCuvamaUser }) => {
  const header = s("cuvamaAI.page.header", "Cuvama AI");
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(Pages.ACCESS_MANAGEMENT);
  const templates = s("cuvamaAI.page.menu.templates", "Prompt Templates");
  const conversations = s("cuvamaAI.page.menu.conversations", "Conversations");
  const navigationMenuItems = [];

  if (isCuvamaUser) {
    navigationMenuItems.push({
      key: Pages.TEMPLATES,
      label: templates
    });

    navigationMenuItems.push({
      key: Pages.CONVERSATIONS,
      label: conversations
    });
  }

  useEffect(() => {
    setSelectedPage(getSelectedPage(location.pathname));
  }, [location.pathname]);

  const onChangePage = (e) => {
    history.push(`/organizations/cuvama-ai/${e.key}`);
  };

  return (
    <Page header={<OrganizationHeader />}>
      <OrganizationPageLayout>
        {navigationMenuItems.length > 1 ? (
          <>
            <Heading level={"h3"}>{header}</Heading>
            <NavigationMenu
              mode={"horizontal"}
              selectedKeys={[selectedPage]}
              onClick={onChangePage}
              items={navigationMenuItems}
            />
          </>
        ) : (
          <Heading level={"h3"}>
            {selectedPage === Pages.TEMPLATES
              ? navigationMenuItems[0].label
              : navigationMenuItems[1].label}
          </Heading>
        )}

        <Container>
          <Switch>
            <CuvamaRoute
              path="/organizations/cuvama-ai/templates"
              exact
              component={AIPromptTemplates}
              requiresCuvamaUser={true}
            />
            <CuvamaRoute
              path="/organizations/cuvama-ai/conversations"
              exact
              component={AIConversations}
              requiresCuvamaUser={true}
            />
            <CuvamaRoute
              path="/organizations/cuvama-ai/conversations/:conversationId"
              exact
              component={AIConversationDetails}
              requiresCuvamaUser={true}
            />
            <Redirect from="" to="/organizations/cuvama-ai/templates" exact />
            <Route component={NotFound} />
          </Switch>
        </Container>
      </OrganizationPageLayout>
    </Page>
  );
};

const Container = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => ({
  isCuvamaUser: selectIsCuvamaUser(state)
});

export default compose(withRouter, connect(mapStateToProps))(CuvamaAI);
