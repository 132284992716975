import SolutionsPanel from "./SolutionsPanel";
import { useFeature } from "../../FeaturesProvider";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import PropTypes from "prop-types";

const SolutionsModal = ({ editable, onClose }) => {
  const rocketShipEnabled = useFeature("rocketShip", true);

  return rocketShipEnabled ? (
    <ModalContainer>
      <SolutionsPanel editable={editable} onClose={onClose} />
    </ModalContainer>
  ) : null;
};

SolutionsModal.propTypes = {
  editable: PropTypes.bool
};

const ModalContainer = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  margin-right: 40px;
  z-index: 200;

  body.scrollbar-gap & {
    margin-right: ${(props) =>
      themeProp("constants.scrollBarWidth")(props) + 40}px;
  }
`;

export default SolutionsModal;
