import types from "../actionTypes";
import notesService from "../../services/notes.service";

export const getDiscoveryNote =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_NOTE_REQUEST
    });

    return notesService
      .getDiscoveryNote({ discoveryId })
      .then((payload) => {
        dispatch({
          type: types.GET_NOTE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_NOTE_FAILURE,
          error
        });
      });
  };

export const updateDiscoveryNote =
  ({ discoveryId, text }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_NOTE_REQUEST
    });

    return notesService
      .updateDiscoveryNote({ discoveryId, text })
      .then((payload) => {
        dispatch({
          type: types.UPDATE_NOTE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_NOTE_FAILURE,
          error
        });
      });
  };

export const stopDiscoveryNoteSession =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.STOP_NOTE_SESSION_REQUEST
    });

    return notesService
      .stopDiscoveryNoteSession({ discoveryId })
      .then((payload) => {
        dispatch({
          type: types.STOP_NOTE_SESSION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.STOP_NOTE_SESSION_FAILURE,
          error
        });
      });
  };

export const startDiscoveryNoteSession =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.START_NOTE_SESSION_REQUEST
    });

    return notesService
      .startDiscoveryNoteSession({ discoveryId })
      .then((payload) => {
        dispatch({
          type: types.START_NOTE_SESSION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.START_NOTE_SESSION_FAILURE,
          error
        });
      });
  };

export const keepDiscoveryNoteSessionAlive =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.KEEP_NOTE_SESSION_ALIVE_REQUEST
    });

    return notesService
      .keepDiscoveryNoteSessionAlive({ discoveryId })
      .then((payload) => {
        dispatch({
          type: types.KEEP_NOTE_SESSION_ALIVE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.KEEP_NOTE_SESSION_ALIVE_FAILURE,
          error
        });
      });
  };
