import TooltipIcon from "../../../../components/TooltipIcon";
import ConfigurationLogStatus from "./ConfigurationLogStatus";
import { useString as s } from "../../../../components/StringProvider";
import styled from "styled-components";

const ConfigurationLogStatusWithTooltipIcon = ({ status }) => {
  const promotionFailedTooltip = s(
    "organization.page.configurationLogs.promotionFailedTooltip",
    "Please contact Cuvama for more details at support@cuvama.com"
  );

  return (
    <Container>
      {status === "promotionFailed" && (
        <TooltipIcon title={promotionFailedTooltip} />
      )}
      <ConfigurationLogStatus status={status} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`;

export default ConfigurationLogStatusWithTooltipIcon;
