import EstimationScenarioTypes from "../../../utils/estimation-scenario-types";
import Currency from "../../Currency";
import React, { useState } from "react";
import PropType from "prop-types";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import FormulaPanel from "./FormulaPanel";
import Popover from "../../Popover";
import { useMobileMediaQuery } from "../../Responsive";

const FormulaValue = ({
  children,
  currency,
  reportId,
  estimationScenario,
  discoveryKPI,
  disableFormulaPanel,
  format,
  showTooltip,
  cursor
}) => {
  const isMobile = useMobileMediaQuery();
  const [popoverVisible, setPopoverVisible] = useState(false);

  return discoveryKPI.hasFormulaDescription ? (
    <Popover
      placement={isMobile ? "bottomRight" : "bottomLeft"}
      trigger={""}
      content={
        <FormulaPanel
          estimationScenario={estimationScenario}
          discoveryKPI={discoveryKPI}
          visible={popoverVisible}
          reportId={reportId}
          onClose={() => setPopoverVisible(false)}
        />
      }
      open={popoverVisible && !disableFormulaPanel}
      onOpenChange={setPopoverVisible}
    >
      <FormulaLink onClick={() => setPopoverVisible(true)}>
        <StyledCurrency
          format={format}
          currency={currency}
          showTooltip={showTooltip}
          $cursor={cursor}
        >
          {children}
        </StyledCurrency>
      </FormulaLink>
    </Popover>
  ) : (
    <StyledCurrency
      format={format}
      currency={currency}
      showTooltip={showTooltip}
      $cursor={cursor}
    >
      {children}
    </StyledCurrency>
  );
};

const FormulaLink = styled.a`
  & {
    color: ${themeProp("palette.text")};
  }

  &:hover {
    color: ${themeProp("palette.text")};
  }
`;

const StyledCurrency = styled(Currency)`
  color: ${themeProp("palette.text")};
  cursor: ${(props) => props.$cursor};
`;

FormulaValue.propTypes = {
  currency: PropType.string.isRequired,
  estimationScenario: PropType.oneOf(EstimationScenarioTypes.ALL),
  discoveryKPI: PropType.object.isRequired,
  reportId: PropType.string,
  disableFormulaPanel: PropType.bool,
  format: PropType.oneOf(["default", "short", "long"]),
  showTooltip: PropType.bool,
  cursor: PropType.oneOf(["auto", "pointer"])
};

FormulaValue.defaultProps = {
  disableFormulaPanel: false,
  format: "short",
  cursor: "auto"
};

export default FormulaValue;
