import PropTypes from "prop-types";
import styled from "styled-components";
import { useString as s } from "../../StringProvider";
import { selectDiscovery } from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { themeProp } from "../../../theme";
import { useMobileMediaQuery } from "../../Responsive";

const KPIMappingsTable = ({ kpiMappings, discovery }) => {
  const isMobile = useMobileMediaQuery();
  const noKPIsLinkedText = s(
    "discovery.challenges.card.kpi.column.noKPIs",
    "No KPIs linked"
  );

  const names = [];

  for (const mapping of kpiMappings) {
    const discoveryKPI = discovery.kpis.find((kpi) => kpi.kpiCode === mapping);

    if (discoveryKPI?.inScope) {
      const name = discoveryKPI.definition.name;

      if (!names.includes(name)) {
        names.push(name);
      }
    }
  }

  names.sort((a, b) => a.localeCompare(b));

  return names.length ? (
    <MappingsTable className={isMobile ? "mobile" : undefined}>
      {names.map((name, index) => (
        <li key={index} data-cy={"linked-kpi"}>
          {name}
        </li>
      ))}
    </MappingsTable>
  ) : (
    <MappingsTable className={isMobile ? "mobile" : undefined}>
      {noKPIsLinkedText}
    </MappingsTable>
  );
};

const MappingsTable = styled.ul`
  width: 100%;
  list-style-type: none;
  margin-left: 10px;

  & li {
    position: relative;
  }

  & li:before {
    content: "•";
    padding: 0 10px 0 0;
    position: absolute;
    top: 0;
    left: -10px;
  }

  &.mobile {
    font-size: ${themeProp("typography.small.fontSize")};
    font-family: ${themeProp("typography.small.fontFamily")};
    font-weight: ${themeProp("typography.small.fontWeight")};
    line-height: ${themeProp("typography.small.lineHeight")};
  }
`;

KPIMappingsTable.propTypes = {
  kpiMappings: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state)
});

export default compose(connect(mapStateToProps))(KPIMappingsTable);
