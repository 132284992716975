import { DropDirection as DragDirection } from "./ValueMapCard";

const getOffset = ({ items, destinationIndex }) => {
  let counter = 0;
  const itemsAbove = items.slice(0, destinationIndex);
  for (let i = itemsAbove.length - 1; i > -1; i--) {
    if (itemsAbove[i].selected) {
      return counter;
    } else {
      counter++;
    }
  }

  return counter;
};

export const reorderItems = ({
  code,
  destinationCode,
  direction,
  items,
  showSelectedOnly = true
}) => {
  console.log(
    "code",
    code,
    "destinationCode",
    destinationCode,
    "direction",
    direction,
    "items",
    items.map((i) => i.code).join(",")
  );

  const item = items.find((item) => item.code === code);
  const newItems = items.filter((item) => item.code !== code);
  const destinationIndex = newItems.findIndex(
    (item) => item.code === destinationCode
  );

  if (code === destinationCode) {
    return items;
  }

  let counter = 0;
  if (
    showSelectedOnly &&
    (direction === DragDirection.TOP ||
      direction === DragDirection.KEY_OBJECTIVE)
  ) {
    //find how many unselected items are above the destinationCode
    counter = getOffset({ items, destinationIndex });
  }

  const newIndex =
    direction === DragDirection.TOP || direction === DragDirection.KEY_OBJECTIVE
      ? destinationIndex - counter
      : destinationIndex + 1;

  if (newIndex < 0) {
    newItems.unshift(item);
  } else {
    newItems.splice(newIndex, 0, item);
  }

  console.log(
    "destinationIndex",
    destinationIndex,
    "counter",
    counter,
    "newIndex",
    newIndex,
    "newItems",
    newItems.map((i) => i.code).join(",")
  );
  return newItems;
};

export const getValueMapPath = (discoveryId) => `/valuemaps/${discoveryId}`;
