import styled from "styled-components";
import { Space } from "antd";
import { Text } from "../../../components";
import React from "react";
import Heading from "../../../components/Heading";
import PropType from "prop-types";

const MobileReportTitle = ({ header, subheader, headerLevel, ...props }) => {
  return (
    <Container {...props}>
      <Heading level={headerLevel}>{header}</Heading>
      {subheader ? <Text variant={"bodyMobile"}>{subheader}</Text> : null}
    </Container>
  );
};

const Container = styled(Space).attrs(() => ({
  size: 8,
  direction: "vertical"
}))`
  margin-bottom: 16px;
  text-align: center;

  h3 {
    text-align: center;
    margin-bottom: 0px;
  }

  h2 {
    text-align: center;
    margin-bottom: 0px;
  }

  h1 {
    text-align: center;
    margin-bottom: 0px;
  }
`;

MobileReportTitle.propTypes = {
  header: PropType.string.isRequired,
  subheader: PropType.string
};

MobileReportTitle.defaultProps = {
  headerLevel: "h1"
};

export default MobileReportTitle;
