import Icon from "../../../../components/Icon";
import { Text } from "../../../../components";
import Upload from "../../../../components/Upload";
import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";
import styled from "styled-components";
import { formatBytes } from "../../../../utils/formatting";

const { Dragger } = Upload;

const FileDragger = ({
  onStartUpload,
  acceptTypes,
  multiple,
  description,
  maxFileSize,
  header
}) => {
  const maxFileSizeDescription = maxFileSize
    ? formatBytes(maxFileSize)
    : "empty";

  const _header = header
    ? header
    : s(
        "manageConfiguration.page.fileDragger.header",
        "Please drag & drop your file here or click this area to upload"
      );
  const subheader = s(
    "manageConfiguration.page.fileDragger.subheader",
    "Supported format(s): {acceptTypes}{maxFileSizeDescription, select, empty {} other {. Max file size: {maxFileSizeDescription}}}",
    { acceptTypes, maxFileSizeDescription }
  );

  const onBeforeUpload = (file, fileList) => {
    if (onStartUpload) {
      return onStartUpload(file, fileList);
    }

    return file;
  };

  return (
    <Dragger
      name={"file"}
      multiple={multiple}
      beforeUpload={onBeforeUpload}
      accept={acceptTypes}
      fileList={[]}
    >
      <Container>
        <Icon name={"drop"} size={"large"} colour={"gray3"} />
        <Text variant={"body"} color={"gray4"}>
          {_header}
        </Text>
        <Text variant={"small"} color={"gray4"}>
          {subheader}
        </Text>
        {description && (
          <Text variant={"small"} color={"gray4"}>
            {description}
          </Text>
        )}
      </Container>
    </Dragger>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px;
`;

FileDragger.propTypes = {
  onStartUpload: PropTypes.func.isRequired,
  acceptTypes: PropTypes.string,
  description: PropTypes.string,
  multiple: PropTypes.bool
};

FileDragger.defaultProps = {
  acceptTypes: ".xlsx,.xlsm"
};

export default FileDragger;
