const ConfigurationLogTypes = {
  NOTES_TEMPLATE: "notesTemplate",
  POWERPOINT_TEMPLATE: "powerpointTemplate",
  MASTER_DATA: "masterData",
  MULTIPLE: "multiple"
};

ConfigurationLogTypes.ALL = Object.values(ConfigurationLogTypes);

export default ConfigurationLogTypes;
