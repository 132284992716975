import PropTypes from "prop-types";
import Form, { FormItem } from "../../../../components/Form";
import { useString as s } from "../../../../components/StringProvider";
import NewButton from "../../../../components/NewButton";
import ButtonGroup from "../../../../components/ButtonGroup";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  getNotesTemplate,
  updateNotesTemplate
} from "../../../../store/actions/templates";
import { useEffect, useState } from "react";
import useLoadingState from "../../../../utils/use-loading-state";
import { message } from "antd";
import Icon from "../../../../components/Icon";
import { RichTextEditor } from "../../../../components/Input";
import { Modal, Tooltip } from "../../../../components";

const UpdateNotesTemplateModal = ({
  notesTemplate,
  loadingState,
  updateLoadingState,
  onClose,
  getNotesTemplate,
  updateNotesTemplate
}) => {
  const [form] = Form.useForm();
  const okText = s("update_notes_template.popup.okButton", "Publish Template");
  const cancelText = s(
    "update_notes_template.popup.cancelButton",
    "Don't Publish"
  );
  const titleText = s("update_notes_template.popup.header", "Notes Template");
  const tooltipText = s(
    "update_notes_template.popup.tooltip",
    "Publishing this template will make it available to each unedited note in this environment."
  );
  const getNotesTemplateFailed = s(
    "update_notes_template.popup.messages.getNotesTemplateFailed",
    "Failed to load notes template."
  );
  const updateNotesTemplateFailed = s(
    "update_notes_template.popup.messages.updateNotesTemplateFailed",
    "Failed to save notes template."
  );
  const updateNotesTemplateSucceeded = s(
    "update_notes_template.popup.messages.updateNotesTemplateSucceeded",
    "Saved notes template."
  );
  const [ready, setReady] = useState(false);

  useEffect(() => {
    getNotesTemplate();
  }, []);

  useLoadingState(
    loadingState,
    () => {
      setReady(true);
    },
    () => {
      message.error(getNotesTemplateFailed);
    }
  );

  useLoadingState(
    updateLoadingState,
    () => {
      message.success(updateNotesTemplateSucceeded);
      onClose();
    },
    () => {
      message.error(updateNotesTemplateFailed);
    }
  );

  const onFinish = (values) => {
    updateNotesTemplate(values);
  };

  return (
    ready && (
      <Modal
        title={titleText}
        open={true}
        onCancel={onClose}
        closable={false}
        width={520}
      >
        <Form
          form={form}
          initialValues={notesTemplate}
          layout="vertical"
          onFinish={onFinish}
        >
          <FormItem name={"content"}>
            <RichTextEditor autoFocus={true} style={{ minHeight: 300 }} />
          </FormItem>
          <FormItem>
            <ButtonGroup style={{ alignItems: "center" }}>
              <NewButton type={"submit"}>{okText}</NewButton>
              <NewButton type={"secondary"} onClick={onClose}>
                {cancelText}
              </NewButton>
              <Tooltip title={tooltipText}>
                <Icon name={"infoCircle"} colour={"gray3"} size={"large"} />
              </Tooltip>
            </ButtonGroup>
          </FormItem>
        </Form>
      </Modal>
    )
  );
};

UpdateNotesTemplateModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  notesTemplate: selectRequestData(
    state,
    actionTypes.GET_NOTES_TEMPLATE_REQUEST
  ),
  loadingState: selectRequestState(
    state,
    actionTypes.GET_NOTES_TEMPLATE_REQUEST
  ),
  updateLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_NOTES_TEMPLATE_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotesTemplate,
      updateNotesTemplate
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UpdateNotesTemplateModal
);
