import { Alert as ANTAlert } from "antd";
import styled from "styled-components";
import { themeProp } from "../theme";
import PropTypes from "prop-types";

const Alert = styled(ANTAlert)`
  &.ant-alert {
    border-radius: 8px;
  }

  & .ant-alert-content {
    text-align: left;
    font-size: ${({ variant }) =>
      themeProp(`typography.${variant}.fontSize`)} !important;
    font-family: ${({ variant }) =>
      themeProp(`typography.${variant}.fontFamily`)} !important;
    font-weight: ${({ variant }) =>
      themeProp(`typography.${variant}.fontWeight`)} !important;
    line-height: ${({ variant }) =>
      themeProp(`typography.${variant}.lineHeight`)} !important;
  }
`;

Alert.propTypes = {
  variant: PropTypes.oneOf(["body", "smallBody"])
};

Alert.defaultProps = {
  variant: "body"
};

export default Alert;
