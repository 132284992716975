import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import styled from "styled-components";
import ReadOnlyPlugin from "./plugins/ReadOnlyPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import PropTypes from "prop-types";
import AutoFocusPlugin from "./plugins/AutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { themeProp } from "../../../theme";
import { useEffect, useState } from "react";
import getInitialEditorState from "./get-initial-editor-state";
import OnUpdateValuePlugin from "./plugins/OnUpdateValuePlugin";

const Placeholder = ({ text }) =>
  !!text && <div className="editor-placeholder">{text}</div>;

const editorConfig = {
  theme: ExampleTheme,
  onError(error) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};

const RichTextEditor = ({
  placeholder,
  value,
  onChange,
  disabled,
  autoFocus,
  useMarkdown = false,
  removeBorder = false,
  canUseUpdateValuePlugin = false,
  setOnChangeValue,
  ...props
}) => {
  const [editorStateString, setEditorStateString] = useState();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (onChange && initialized) {
      onChange(editorStateString);
    }
    setInitialized(true);

    if (setOnChangeValue) setOnChangeValue(editorStateString);
  }, [editorStateString]);

  const onChangeInternal = (editorState) => {
    editorState.read(() => {
      if (useMarkdown) {
        setEditorStateString($convertToMarkdownString(TRANSFORMERS));
      } else {
        setEditorStateString(JSON.stringify(editorState));
      }
    });
  };

  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        disabled,
        editorState: useMarkdown
          ? () => getInitialEditorState({ useMarkdown, value })
          : getInitialEditorState({ useMarkdown, value })
      }}
    >
      <EditorContainer
        className={"editor-container" + (useMarkdown ? "markdown" : "")}
        removeBorder={removeBorder}
        disabled={disabled}
        {...props}
      >
        {!disabled && (
          <ToolbarPlugin
            tools={
              useMarkdown
                ? [
                    "dropdown",
                    "divider",
                    "bold",
                    "underlineMarkdown",
                    "divider",
                    "orderedList",
                    "unorderedList"
                  ]
                : undefined
            }
          />
        )}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="editor-input"
                data-cy={"rich-text-editor-text-area"}
              />
            }
            placeholder={<Placeholder text={placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <ReadOnlyPlugin isDisabled={disabled} />
          <OnChangePlugin onChange={onChangeInternal} />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          {autoFocus && <AutoFocusPlugin />}
          {canUseUpdateValuePlugin && (
            <OnUpdateValuePlugin value={value} initialized={initialized} />
          )}
        </div>
      </EditorContainer>
    </LexicalComposer>
  );
};

const EditorContainer = styled.div`
  border: ${(props) =>
    props.removeBorder
      ? "none"
      : `1px solid
    ${themeProp("palette.gray1")}`};
  font-size: ${themeProp(`typography.body.fontSize`)};
  font-family: ${themeProp(`typography.body.fontFamily`)};
  font-weight: ${themeProp(`typography.body.fontWeight`)};
  line-height: ${themeProp(`typography.body.lineHeight`)};
  display: flex;
  flex-direction: column;
  color: ${(props) =>
    props.disabled ? themeProp(`palette.gray4`) : themeProp(`palette.text`)};

  .other h2 {
    font-size: 18px;
    color: #444;
    margin-bottom: 7px;
  }

  .other a {
    color: #777;
    text-decoration: underline;
    font-size: 14px;
  }

  .other ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  h1 {
    font-size: 24px;
    color: #333;
  }

  .ltr {
    text-align: left;
  }

  .rtl {
    text-align: right;
  }

  .editor-inner {
    background: ${themeProp("palette.surface")};
    flex-grow: 1;
    flex-basis: 0;
    overflow: auto;
    position: relative;
  }

  .editor-input {
    min-height: 150px;
    resize: none;
    font-size: 15px;
    tab-size: 1;
    outline: 0;
    padding: ${(props) => (props.disabled ? "4px" : "15px 10px")};
    caret-color: #444;
  }

  .editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-text-strikethrough {
    text-decoration: line-through;
  }

  .editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
  }

  .editor-text-code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }

  .editor-link {
    color: ${themeProp(`components.link.primary.base`)};
    transition: color 0.1s ease-out;

    &:hover {
      color: ${themeProp(`components.link.primary.hover`)};
    }
    &:focus {
      color: ${themeProp(`components.link.primary.focus`)};
    }
    &[disabled] {
      color: ${themeProp(`components.link.primary.disabled`)};
    }

    text-decoration: none;
  }

  .tree-view-output {
    display: block;
    background: #222;
    color: ${themeProp("palette.surface")};
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
  }

  .editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    overflow-x: auto;
    position: relative;
  }

  .editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
  }
  .editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
  }

  .editor-tokenComment {
    color: slategray;
  }

  .editor-tokenPunctuation {
    color: #999;
  }

  .editor-tokenProperty {
    color: #905;
  }

  .editor-tokenSelector {
    color: #690;
  }

  .editor-tokenOperator {
    color: #9a6e3a;
  }

  .editor-tokenAttr {
    color: #07a;
  }

  .editor-tokenVariable {
    color: #e90;
  }

  .editor-tokenFunction {
    color: #dd4a68;
  }

  .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-heading-h1 {
    margin: 0;
    margin-bottom: 12px;
    padding: 0;

    font-size: ${themeProp(`typography.h1.fontSize`)};
    font-family: ${themeProp(`typography.h1.fontFamily`)};
    font-weight: ${themeProp(`typography.h1.fontWeight`)};
    line-height: ${themeProp(`typography.h1.lineHeight`)};
  }

  .editor-heading-h2 {
    margin: 0;
    margin-top: 10px;
    padding: 0;

    font-size: ${themeProp(`typography.h2.fontSize`)};
    font-family: ${themeProp(`typography.h2.fontFamily`)};
    font-weight: ${themeProp(`typography.h2.fontWeight`)};
    line-height: ${themeProp(`typography.h2.lineHeight`)};
  }

  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }

  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-listitem {
    margin: 8px;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  pre::-webkit-scrollbar-thumb {
    background: #999;
  }

  &.markdown {
    h1 {
      font-size: ${(props) => themeProp("typography.bMedium.fontSize")(props)};
      line-height: ${(props) =>
        themeProp("typography.bMedium.lineHeight")(props)};
      font-weight: ${(props) =>
        themeProp("typography.bMedium.fontWeight")(props)};
      font-family: ${(props) =>
        themeProp("typography.bMedium.fontFamily")(props)};
    }

    h2 {
      font-size: ${(props) =>
        themeProp(`typography.${props.size}.fontSize`)(props)};
      line-height: ${(props) =>
        themeProp(`typography.${props.size}.lineHeight`)(props)};
      font-weight: ${(props) =>
        themeProp(`typography.${props.size}.fontWeight`)(props)};
      font-family: ${(props) =>
        themeProp(`typography.${props.size}.fontFamily`)(props)};
    }

    h3,
    h4,
    h5,
    h6 {
      font-size: ${(props) => themeProp("typography.small.fontSize")(props)};
      line-height: ${(props) =>
        themeProp("typography.small.lineHeight")(props)};
      font-weight: ${(props) =>
        themeProp("typography.small.fontWeight")(props)};
      font-family: ${(props) =>
        themeProp("typography.small.fontFamily")(props)};
    }

    p {
      font-size: ${(props) =>
        themeProp(`typography.${props.size}.fontSize`)(props)};
      line-height: ${(props) =>
        themeProp(`typography.${props.size}.lineHeight`)(props)};
      font-weight: ${(props) =>
        themeProp(`typography.${props.size}.fontWeight`)(props)};
      font-family: ${(props) =>
        themeProp(`typography.${props.size}.fontFamily`)(props)};
      margin-bottom: ${(props) =>
        props.paragraphMargin
          ? themeProp(`typography.${props.size}.fontSize`)(props)
          : "0px"};
    }

    p:last-child {
      margin-bottom: 0;
    }

    li {
      font-size: ${(props) =>
        themeProp(`typography.${props.size}.fontSize`)(props)};
      line-height: ${(props) =>
        themeProp(`typography.${props.size}.lineHeight`)(props)};
      font-weight: ${(props) =>
        themeProp(`typography.${props.size}.fontWeight`)(props)};
      font-family: ${(props) =>
        themeProp(`typography.${props.size}.fontFamily`)(props)};
      list-style-position: inside;
    }

    a {
      color: ${(props) => themeProp("palette.primary")(props)};
    }

    del {
      text-decoration: line-through;
    }

    .editor-text-italic,
    em {
      text-decoration: underline;
      font-style: normal;
    }
  }
`;

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  removeBorder: PropTypes.bool,
  canUseUpdateValuePlugin: PropTypes.bool,
  setOnChangeValue: PropTypes.func
};

export default RichTextEditor;
