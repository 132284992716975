import types from "../actionTypes";
import masterDataService from "../../services/master-data.service";

export const getMasterData =
  ({ version }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_MASTER_DATA_REQUEST
    });

    return masterDataService
      .getMasterData({ version })
      .then((data) => {
        dispatch({
          type: types.GET_MASTER_DATA_SUCCESS,
          payload: { data }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_MASTER_DATA_FAILURE,
          payload: { error }
        });
      });
  };

export const getMasterDataForReportId =
  ({ reportId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_MASTER_DATA_REQUEST
    });

    return masterDataService
      .getMasterDataForReportId({ reportId })
      .then((data) => {
        dispatch({
          type: types.GET_MASTER_DATA_SUCCESS,
          payload: { data }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_MASTER_DATA_FAILURE,
          payload: { error }
        });
      });
  };

const masterData = {
  getMasterData
};

export default masterData;
