import types from "../actionTypes";
import reportsService from "../../services/reports.service";

export const getReportSummary =
  ({ reportId }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SUMMARY_REQUEST
    });

    return reportsService
      .getReportSummary({ reportId })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_SUMMARY_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SUMMARY_FAILURE,
          error
        });
      });
  };

export const previewReportCashflow =
  ({ reportId, scenario, benefits }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_PREVIEW_CASHFLOW_REQUEST
    });

    return reportsService
      .previewCashflow({ reportId, scenario, benefits })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_PREVIEW_CASHFLOW_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_PREVIEW_CASHFLOW_FAILURE,
          error
        });
      });
  };

export const setReportTemplate =
  ({ template }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SET_REPORT_TEMPLATE_SUCCESS,
      payload: template
    });
  };
