import React, { useState } from "react";
import Header, {
  BrandBadge,
  HeaderRightSection
} from "../../components/Header";
import { useString as s } from "../../components/StringProvider";
import NewButton from "../../components/NewButton";
import NotificationsButton from "../../components/Notifications/NotificationsButton";
import NewDiscoveryModal from "../../components/DiscoveryOperations/NewDiscoveryModal";
import { Space } from "antd";
import Icon from "../../components/Icon";
import { hasPermission } from "../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Permissions from "../../utils/permissions";
import AccountMenuDropdown from "../../components/AccountMenu/AccountMenuDropdown";
import NewDiscoveryButton from "../../components/DiscoveryOperations/NewDiscoveryButton";

const MyDiscoveriesHeader = ({ history, canSeeOrgAdmin }) => {
  const [showModal, setShowModal] = useState(false);
  const onNewDiscovery = () => {
    setShowModal(true);
  };
  const onOrganizationAdmin = () => {
    history.push({ pathname: "/organizations/manage-configuration" });
  };
  const manageOrganization = s(
    "discoveries.dashboard.manageOrganization.button",
    "Manage organization"
  );

  return (
    <>
      <Header data-cy={"my-discoveries-header"}>
        <BrandBadge linkTo="/MyDiscoveries" />
        <HeaderRightSection>
          <Space size={20}>
            <NotificationsButton />
            {canSeeOrgAdmin ? (
              <NewButton
                type={"iconSecondary"}
                onClick={onOrganizationAdmin}
                tooltip={manageOrganization}
              >
                <Icon name={"organization"} size={"large"} />
              </NewButton>
            ) : null}
            <AccountMenuDropdown />

            <NewDiscoveryButton onNewDiscovery={onNewDiscovery} />
          </Space>
        </HeaderRightSection>
      </Header>
      {showModal ? (
        <NewDiscoveryModal onClose={() => setShowModal(false)} />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    canSeeOrgAdmin: hasPermission(state, Permissions.SEE_ORG_ADMIN)
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(MyDiscoveriesHeader);
