import styled from "styled-components";
import PropTypes from "prop-types";
import { TruncatedTitle } from "../../../components/Typography";

const ChartColumn = ({ index, title, color, borderColor, value, dataCy }) => {
  return (
    <>
      <TitleContainer index={index} className={"title"}>
        <TruncatedTitle fullText={title} level={5} color={"gray4"}>
          {title}
        </TruncatedTitle>
      </TitleContainer>
      <ColorContainer
        index={index}
        className={"color"}
        color={color}
        borderColor={borderColor}
        data-cy={dataCy}
      ></ColorContainer>
      <AmountContainer index={index} className={"amount"}>
        <TruncatedTitle
          fullText={value}
          level={4}
          rows={2}
          placement={"bottom"}
        >
          {value}
        </TruncatedTitle>
      </AmountContainer>
    </>
  );
};

ChartColumn.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  value: PropTypes.string.isRequired,
  dataCy: PropTypes.string.isRequired
};

const TitleContainer = styled.div`
  grid-row-start: 1;
  grid-row-end: span ${({ index }) => index + 1};
  grid-column-start: ${({ index }) => index + 1};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  & h5 {
    text-align: center;
    font-size: 11px;
  }

  & h5 .ant-typography {
    margin-bottom: 0.5em;
  }
`;

const ColorContainer = styled.div`
  grid-row-start: ${({ index }) => index + 2};
  grid-column-start: ${({ index }) => index + 1};
  background-color: ${(props) => props.color};
  border-radius: 4px;
  border: 1px dashed
    ${(props) => (props.borderColor ? props.borderColor : "transparent")};
`;

const AmountContainer = styled.div`
  grid-row-start: ${({ index }) => index + 3};
  grid-row-end: -1;
  grid-column-start: ${({ index }) => index + 1};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & .ant-typography,
  & h4 {
    text-align: center;
    margin-bottom: 0;
  }
`;

export default ChartColumn;
