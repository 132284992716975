import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../theme";
import Icon from "./Icon";
import Text from "./Text";

const renderTitle = (title) =>
  title ? <AlertBoxTitle>{title}</AlertBoxTitle> : null;

const AlertBox = ({ title, children, className, ...props }) => {
  return (
    <AlertBoxContainer className={className} {...props}>
      <AlertBoxIcon>
        <Icon name="alert" size="large" />
      </AlertBoxIcon>
      <AlertBoxWrapper>
        {renderTitle(title)}
        <AlertBoxMessage variant="small">{children}</AlertBoxMessage>
      </AlertBoxWrapper>
    </AlertBoxContainer>
  );
};
AlertBox.propTypes = {
  title: PropTypes.node
};
AlertBox.defaultProps = {
  title: null
};

export default AlertBox;

const AlertBoxContainer = styled.div`
  background: ${themeProp("palette.highlight")};
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
`;
const AlertBoxIcon = styled.div`
  margin-right: 24px;
  color: ${themeProp("palette.text")};
  line-height: 20px;
  flex-grow: 0 !important;
`;
const AlertBoxWrapper = styled.div`
  flex: 1;
`;
const AlertBoxTitle = styled(Text)`
  display: block;
`;
const AlertBoxMessage = styled(Text)``;
