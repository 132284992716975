import httpService from "./http.service";

const getInvitation = ({ invitationCode }) =>
  httpService
    .get(`/invitations/${invitationCode}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const createInvitation = ({ discoveryId, emails }) =>
  httpService
    .post(`/invitations/`, { discoveryId, emails })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const checkEmailStatus = ({ invitationCode, email }) =>
  httpService
    .post(`/invitations/${invitationCode}/checkEmailStatus`, { email })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateMyDiscoveries = () =>
  httpService
    .post(`/invitations/updateMyInvitations`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getInvitation,
  createInvitation,
  checkEmailStatus,
  updateMyDiscoveries
};

export default methods;
