import types from "../actionTypes";
import templatesService from "../../services/templates.service";

export const getNotesTemplate = () => (dispatch) => {
  dispatch({
    type: types.GET_NOTES_TEMPLATE_REQUEST
  });

  return templatesService
    .getNotesTemplate()
    .then((payload) => {
      dispatch({
        type: types.GET_NOTES_TEMPLATE_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_NOTES_TEMPLATE_FAILURE,
        error
      });
    });
};

export const updateNotesTemplate =
  ({ content }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_NOTES_TEMPLATE_REQUEST
    });

    return templatesService
      .updateNotesTemplate({
        content
      })
      .then((payload) => {
        dispatch({
          type: types.UPDATE_NOTES_TEMPLATE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_NOTES_TEMPLATE_FAILURE,
          error
        });
      });
  };

export const deployPowerpointTemplates =
  ({ validationIds }) =>
  (dispatch) => {
    dispatch({
      type: types.DEPLOY_POWERPOINT_TEMPLATE_REQUEST,
      parameters: { validationIds }
    });

    return templatesService
      .deployPowerpointTemplates({
        validationIds
      })
      .then((payload) => {
        dispatch({
          type: types.DEPLOY_POWERPOINT_TEMPLATE_SUCCESS,
          payload,
          parameters: { validationIds }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DEPLOY_POWERPOINT_TEMPLATE_FAILURE,
          error,
          parameters: { validationIds }
        });
      });
  };
