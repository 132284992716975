import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { CostOfStatusQuoChart } from "../../ValueHypothesis/components";
import { Text } from "../../../components";
import PropType from "prop-types";

const PrintReportValueHypothesisTwo = ({ kpis, currency }) => {
  return (
    <ContainerContainer>
      <Container>
        <Heading level={"h1"} align={"center"}>
          {s("discovery.opportunity.page.header2", "Cost of Status Quo")}
        </Heading>
        <Text alignment={"center"} style={{ marginBottom: 20 }}>
          {s(
            "discovery.opportunity.page.subheader2",
            "The cost to you if you choose not to use the {companyName} platform"
          )}
        </Text>
        <CostOfStatusQuoChart
          kpis={kpis}
          currency={currency}
          reportMode={true}
          printMode={true}
        />
      </Container>
    </ContainerContainer>
  );
};

const ContainerContainer = styled.div`
  background-color: ${themeProp("palette.surface")};
`;

const Container = styled.div`
  background-color: ${themeProp("palette.background")};
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  padding: 0 40px 80px 40px;
  margin-top: 80px;
`;

PrintReportValueHypothesisTwo.propTypes = {
  currency: PropType.string.isRequired,
  kpis: PropType.array.isRequired
};

export default PrintReportValueHypothesisTwo;
