import types from "../actionTypes";

export const startDiscoveryOperation =
  ({ operationId, operationDiscoveryId, operationReportId, parameters }) =>
  (dispatch) => {
    dispatch({
      type: types.START_DISCOVERY_OPERATION,
      payload: {
        operationId,
        operationDiscoveryId,
        operationReportId,
        parameters
      }
    });

    return Promise.resolve();
  };

export const stopDiscoveryOperation = () => (dispatch) => {
  dispatch({
    type: types.STOP_DISCOVERY_OPERATION
  });

  return Promise.resolve();
};

export const confirmDiscoveryOperation = () => (dispatch) => {
  dispatch({
    type: types.CONFIRM_DISCOVERY_OPERATION
  });

  return Promise.resolve();
};

export const alertDiscoveryOperation = () => (dispatch) => {
  dispatch({
    type: types.ALERT_DISCOVERY_OPERATION
  });

  return Promise.resolve();
};
