import PropType from "prop-types";
import JobTypes from "../../../utils/job-types";
import { useString as s } from "../../../components/StringProvider";

const DeploymentMessage = ({ type }) => {
  const messages = {};

  messages[JobTypes.MD_CONFIGURATION_DEPLOYMENT] = s(
    `deploymentJob.page.deploymentMessage.mdConfigurationDeployment`,
    "Updating configuration..."
  );

  messages[JobTypes.CRM_CONFIGURATION_DEPLOYMENT] = s(
    `deploymentJob.page.deploymentMessage.crmConfigurationDeployment`,
    "Updating CRM Data..."
  );

  return messages[type];
};

DeploymentMessage.propTypes = {
  type: PropType.oneOf(JobTypes.ALL)
};

export default DeploymentMessage;
