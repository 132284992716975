import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Input as ANTInput } from "antd";
import { themeProp } from "../../theme";
import { useMobileMediaQuery } from "../Responsive";
import React from "react";

const Input = React.forwardRef(({ ...props }, ref) => {
  const isMobile = useMobileMediaQuery();

  return (
    <InputComponent
      className={isMobile ? "mobile" : undefined}
      ref={ref}
      {...props}
    />
  );
});

const InputComponent = styled(ANTInput)
  .withConfig({
    shouldForwardProp: (prop) => !["block"].includes(prop)
  })
  .attrs(() => ({
    bordered: false
  }))`
  width: ${(props) => (props.block ? "100%" : "initial")};
  background: transparent;
  color: ${themeProp("palette.text")};
  border-bottom: 1px solid ${themeProp("palette.secondary")};
  font-size: ${themeProp("typography.body.fontSize")};
  font-family: ${themeProp("typography.body.fontFamily")};
  transition: border-color 0.2s ease-out;
  border-radius: 0;

  &:hover {
    background-color: ${themeProp("palette.surface")};
  }

  ${({ size }) =>
    size === "large"
      ? css`
          height: 54px;
          padding: 0 12px;
        `
      : css`
          height: 40px;
          padding: 0 8px;
        `}


  &::placeholder {
    color: ${themeProp("palette.gray3")}; 
    font-size: ${themeProp("typography.body.fontSize")};
    line-height: ${themeProp("typography.body.lineHeight")};
    font-weight: ${themeProp("typography.body.fontWeight")};
  }
  
  &.ant-input-disabled {
    border-bottom: 1px solid transparent;
    padding-left: 0;
    padding-right: 0;
    color: ${themeProp("palette.gray4")};
    background-color: ${themeProp("palette.disabled")}; 
  }
  
   &.ant-input-disabled:hover {
    border-bottom: 1px solid transparent;
    background-color: ${themeProp("palette.disabled")}; 
  }

  &:hover {
    border-bottom: 1px solid ${themeProp("palette.secondary")};
  }

  &:focus {
    border-bottom: 1px solid ${themeProp("palette.secondary")};
    background-color: ${themeProp("palette.surface")};
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  &.ant-input-disabled {
    padding: 0px 12px;
    background-color: ${themeProp("palette.disabled")};
    color: ${themeProp("palette.gray4")};
    border-bottom-color: ${themeProp("palette.gray2")};
  }
`;

Input.propTypes = {
  block: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "large"])
};

Input.defaultProps = {
  block: true,
  size: "large"
};

export default Input;
