import Page from "../../components/Page";
import { ErrorPageLayout } from "../../components/Layout";
import { useString as s } from "../../components/StringProvider";
import Heading from "../../components/Heading";
import { Text } from "../../components";
import { isAuthenticated } from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { Space } from "antd";
import { ReactComponent as Icon404 } from "../../assets/404.svg";
import { withRouter } from "react-router-dom";
import NewButton from "../../components/NewButton";

const NotFound = ({ isAuthenticated, history }) => {
  const headerText = s("not_found_page.header", "Oops!");
  const errorText = s(
    "not_found_page.text",
    "It seems this page no longer exists or has been removed. You may return to the homepage."
  );
  const buttonText = s(
    "not_found_page.go_to_my_discoveries",
    "Go to My Discoveries"
  );

  const goToMyDiscoveries = () => {
    history.push({ pathname: "/" });
  };

  return (
    <Page>
      <ErrorPageLayout>
        <Space direction={"vertical"} size={"large"} data-cy={"not-found-page"}>
          <Icon404 alt={"404 error"} aria-hidden={true} />
          <Heading level={"h3"} color={"gray4"}>
            {headerText}
          </Heading>
          <Text color={"gray4"}>{errorText}</Text>
          {isAuthenticated ? (
            <NewButton type={"alternate"} onClick={goToMyDiscoveries}>
              {buttonText}
            </NewButton>
          ) : null}
        </Space>
      </ErrorPageLayout>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state)
});

export default compose(withRouter, connect(mapStateToProps))(NotFound);
