import types from "../actionTypes";

const INITIAL_STATE = {
  roles: [],
  role: null,
  error: null
};

const deleteRole = ({ roles, roleId }) => {
  return roles.filter((role) => role._id !== roleId);
};

const roles = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload
      };
    case types.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: replaceRoleInRoles({
          roles: state.roles,
          role: action.payload
        })
      };
    case types.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roles: deleteRole({ roles: state.roles, roleId: action.payload })
      };
    case types.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.items,
        pagination: action.payload.pagination
      };

    default:
      return state;
  }
};

const replaceRoleInRoles = ({ roles, role }) => {
  return roles.reduce(
    (list, item) => (item._id === role._id ? [...list, role] : [...list, item]),
    []
  );
};

export const selectRoles = (rolesData) => rolesData.roles;
export const selectRole = (rolesData) => rolesData.role;

export default roles;
export const selectRolesPagination = (rolesData) => rolesData.pagination;
