import React from "react";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import Card from "../../../components/Card";
import Metric from "../../../components/Metric";
import Currency from "../../../components/Currency";
import { useMobileMediaQuery } from "../../../components/Responsive";
import {
  selectDiscoveryCashflow,
  selectDiscoveryCurrency,
  selectDiscoveryROI
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import * as formatting from "../../../utils/formatting";
import { useSetting } from "../../../components/SettingsProvider";

const ROIStatistics = ({ cashflow, roi, currency, useMaxWidth = true }) => {
  const isMobile = useMobileMediaQuery();
  const {
    totalInvestment,
    totalBenefit,
    totalNetBenefit,
    annualNetBenefit,
    returnOnInvestmentPercentage,
    paybackPeriod,
    paybackPeriodMonths,
    isMaxPaybackPeriod,
    npv,
    irr
  } = cashflow;
  const locale = useSetting("locale", "en-GB");
  const { evaluationPeriod } = roi;
  const moreThanText = s(
    "discovery.roi.summary.statistics.paybackPeriod.moreThan",
    "More than {paybackPeriod, select, 1 {{paybackPeriod} Year} other {{paybackPeriod} Years}}",
    { paybackPeriod }
  );
  const lessThanText = s(
    "discovery.roi.summary.statistics.paybackPeriod.lessThan",
    "Approx. {paybackPeriodMonths, select, 1 {{paybackPeriodMonths} Month} other {{paybackPeriodMonths} Months}}",
    { paybackPeriodMonths, paybackPeriod }
  );

  const headerLabel = s(
    "discovery.roi.summary.statistics.header",
    "Year Return On Investment"
  );
  const headerTooltipLabel = s(
    "discovery.roi.summary.statistics.heading.tooltip",
    "The ROI numbers below show the return that the customer will get during the evaluation period selected. For example, if the evaluation period selected is 3 years and the total investment over the 3 years is $1M, then with a total benefit of $2.5M and a total net benefit of $1.5M, this would equate to a 150% ROI."
  );
  const investmentLabel = s(
    "discovery.roi.summary.statistics.metric1.label",
    "Investment"
  );
  const investmentTooltipLabel = s(
    "discovery.roi.summary.statistics.metric1.tooltip",
    "This is the total investment (annual plus one-off) during the evaluation period."
  );
  const benefitLabel = s(
    "discovery.roi.summary.statistics.metric2.label",
    "Benefit"
  );
  const benefitTooltipLabel = s(
    "discovery.roi.summary.statistics.metric2.tooltip",
    "This is the total financial benefit the customer will receive during the evaluation period that factors in the time to implement, the time to ramp up to full adoption and any other adjustment factors."
  );
  const netBenefitLabel = s(
    "discovery.roi.summary.statistics.metric3.label",
    "Net Benefit"
  );
  const netBenefitTooltipLabel = s(
    "discovery.roi.summary.statistics.metric3.tooltip",
    "Net Benefit = Benefit – Investment"
  );
  const roiLabel = s("discovery.roi.summary.statistics.roi.label", "ROI %");
  const roiTooltipLabel = s(
    "discovery.roi.summary.statistics.roi.tooltip",
    "ROI = (Benefit - Investment) / Investment "
  );
  const paybackLabel = s(
    "discovery.roi.summary.statistics.metric5.label",
    "Payback"
  );
  const paybackTooltipLabel = s(
    "discovery.roi.summary.statistics.payback.tooltip",
    "The estimated month where the cumulative net benefit turns positive i.e. the cumulative benefit is greater than the cumulative investment and 'payback' has been achieved."
  );
  const annualNetBenefitLabel = s(
    "discovery.roi.summary.statistics.metric6.label",
    "Annual Net Benefit"
  );
  const annualNetBenefitTooltip = s(
    "discovery.roi.summary.statistics.annualNetBenefit.tooltip",
    "Net Benefit per year once the solution is in steady state i.e. fully implemented, fully adopted and the benefits are being fully realized. If you are using different investment and benefit adjustment factors per year then this use the final year numbers."
  );
  const irrLabel = s("discovery.roi.summary.statistics.metric7.label", "IRR");
  const irrTooltipLabel = s(
    "discovery.roi.summary.statistics.irr.tooltip",
    "IRR, or internal rate of return, is a discount rate that makes the net present value (NPV) of all cash flows equal to zero in a discounted cash flow analysis."
  );
  const npvLabel = s("discovery.roi.summary.statistics.metric8.label", "NPV");
  const npvTooltipLabel = s(
    "discovery.roi.summary.statistics.npv.tooltip",
    "Net present value (NPV) is the difference between the present value of cash inflows and the present value of cash outflows over a period of time."
  );

  return (
    <Container
      useMaxWidth={useMaxWidth}
      className={isMobile ? "mobile" : undefined}
    >
      <CardHeader>
        <Metric
          label={`${evaluationPeriod} ${headerLabel}`}
          help={headerTooltipLabel}
          tooltipColor={"surface"}
          active
          size={"bMediumMobile"}
        />
      </CardHeader>
      <CardBody>
        <Body>
          <Column>
            <Metric
              label={investmentLabel}
              help={investmentTooltipLabel}
              tooltipColor={"surface"}
              active
            >
              <Currency
                format="short"
                currency={currency}
                data-cy={"investment-amount"}
              >
                {totalInvestment}
              </Currency>
            </Metric>
            <Metric
              label={benefitLabel}
              help={benefitTooltipLabel}
              tooltipColor={"surface"}
              active
            >
              <Currency
                format="short"
                currency={currency}
                data-cy={"benefit-amount"}
              >
                {totalBenefit}
              </Currency>
            </Metric>
            <Separator />
            <Metric
              label={netBenefitLabel}
              help={netBenefitTooltipLabel}
              tooltipColor={"surface"}
              size={"h2"}
              active
            >
              <Currency
                format="short"
                currency={currency}
                data-cy={"net-benefit-amount"}
              >
                {totalNetBenefit}
              </Currency>
            </Metric>
            {roi.npvMode && roi.annualAdjustmentMode && (
              <>
                <Separator />
                <Metric
                  label={npvLabel}
                  help={npvTooltipLabel}
                  tooltipColor={"surface"}
                  size={"h2"}
                  active
                >
                  <Currency
                    format="short"
                    currency={currency}
                    data-cy={"roi-statistics-npv"}
                  >
                    {npv}
                  </Currency>
                </Metric>
              </>
            )}
          </Column>
          <Column>
            <Metric
              label={roiLabel}
              help={roiTooltipLabel}
              tooltipColor={"surface"}
              size={"h2"}
              active
              data-cy={"roi-percentage"}
            >
              {formatting.formatROIPercentage({
                value: returnOnInvestmentPercentage,
                options: { locale }
              })}
            </Metric>
            <Separator />
            <Metric
              label={paybackLabel}
              help={paybackTooltipLabel}
              tooltipColor={"surface"}
              data-cy={"payback"}
            >
              {isMaxPaybackPeriod ? moreThanText : lessThanText}
            </Metric>
            <Metric
              label={annualNetBenefitLabel}
              help={annualNetBenefitTooltip}
              tooltipColor={"surface"}
            >
              <Currency
                format="short"
                currency={currency}
                data-cy={"annual-net-benefit"}
              >
                {annualNetBenefit}
              </Currency>
            </Metric>
            {roi.npvMode && roi.annualAdjustmentMode && (
              <>
                <Separator />
                <Metric
                  label={irrLabel}
                  help={irrTooltipLabel}
                  tooltipColor={"surface"}
                  size={"h2"}
                  active
                  data-cy={"roi-statistics-irr"}
                >
                  {formatting.formatPercentageWithDP({
                    value: irr,
                    options: {
                      locale,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }
                  })}
                </Metric>
              </>
            )}
          </Column>
        </Body>
      </CardBody>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: selectDiscoveryCurrency(state),
  roi: selectDiscoveryROI(state),
  cashflow: selectDiscoveryCashflow(state)
});

export default compose(connect(mapStateToProps))(ROIStatistics);

const Container = styled(Card)`
  &:not(.mobile) {
    max-width: ${({ useMaxWidth }) => (useMaxWidth ? "450px" : null)};
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background: ${themeProp("palette.secondary")};
  color: ${themeProp("palette.surface")};
  padding: 24px;
`;

const CardHeader = styled.div`
  span {
    font-weight: 700;
  }
`;

const CardBody = styled.div`
  width: 100%;
`;

const Body = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  h2 {
    margin: 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 150px;
  margin-right: 16px;
  gap: 20px;

  &:last-child {
    margin-right: 0;
  }
`;
const Separator = styled.div`
  height: 1px;
  background: ${themeProp("palette.gray2")};
  width: 32px;
`;
