import React from "react";
import { useString as s } from "../../StringProvider";
import Range from "../../Range";
import Heading from "../../Heading";
import PropTypes from "prop-types";
import ImpactTBD from "./ImpactTBD";
import PropType from "prop-types";

const MetricDrivenKPIData = ({ kpi, currency, disabled }) => {
  return (
    <div>
      <Heading level={"h5"} color={"gray3"} style={{ marginTop: 6 }}>
        {s("discovery.kpi.card.slider.title", "Typical range")}
        &nbsp;
        <Range
          first={kpi.definition.rangeLowMed}
          second={kpi.definition.rangeMedHigh}
          type={kpi.definition.rangeMetricType}
          currency={currency}
          customImpactFormat={kpi.definition.customImpactFormat}
          impactPercentageFormulaDescription={
            kpi.definition.impactPercentageFormulaDescription
          }
        />
      </Heading>
      <ImpactTBD kpi={kpi} disabled={disabled} />
    </div>
  );
};

MetricDrivenKPIData.propTypes = {
  currency: PropTypes.string.isRequired,
  kpi: PropTypes.object.isRequired,
  disabled: PropType.bool
};

export default MetricDrivenKPIData;
