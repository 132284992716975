import httpService from "./http.service";

const createAutoconfiguration = ({
  companyName,
  companyWebsite,
  productName,
  model
}) =>
  httpService
    .post(`/autoconfigurations/`, {
      companyName,
      companyWebsite,
      productName,
      model
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getAutoconfiguration = ({ autoconfigurationId }) =>
  httpService
    .get(`/autoconfigurations/${autoconfigurationId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const generateAutoconfiguration = ({ autoconfigurationId, section }) =>
  httpService
    .post(`/autoconfigurations/${autoconfigurationId}/generate`, { section })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const validateAutoconfiguration = ({ autoconfigurationId }) =>
  httpService
    .post(`/autoconfigurations/${autoconfigurationId}/validate`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  createAutoconfiguration,
  getAutoconfiguration,
  generateAutoconfiguration,
  validateAutoconfiguration
};

export default methods;
