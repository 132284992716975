import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const useComponentDimensions = (elementRef) => {
  const [dimensions, setDimensions] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    scrollWidth: 0,
    clientWidth: 0
  });

  const resizeObserverRef = useRef();

  useEffect(() => {
    if (!elementRef || !elementRef.current) {
      return;
    }

    const handleResize = (entries) => {
      if (entries && entries.length > 0) {
        const { top, left, width, height } =
          entries[0].target.getBoundingClientRect();
        const { scrollWidth, clientWidth } = entries[0].target;
        setDimensions({ top, left, width, height, scrollWidth, clientWidth });
      }
    };

    resizeObserverRef.current = new ResizeObserver(handleResize);
    resizeObserverRef.current.observe(elementRef.current);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [elementRef]);

  return dimensions;
};

useComponentDimensions.propTypes = {
  elementRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

export default useComponentDimensions;
