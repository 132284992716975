import Form, { FormItem } from "../../Form";
import { useString as s } from "../../StringProvider";
import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { Space } from "antd";
import Heading from "../../Heading";
import Text from "../../Text";
import SelectCategory from "../SelectCategory";
import KPINameTextArea from "./KPINameTextArea";
import BenefitTextArea from "./BenefitTextArea";
import PropType from "prop-types";
import KPINumberInput from "./KPINumberInput";
import NewButton from "../../NewButton";
import {
  getMetricParser,
  getPercentageFormatter
} from "../../../utils/formatting";
import SelectLinkedChallenges from "../SelectLinkedChallenges";
import ButtonGroup from "../../ButtonGroup";
import { DesktopOrTablet, Mobile } from "../../Responsive";
import ToText from "../../../Pages/ValueHypothesis/components/Statistics/ToText";
import { SettingsContext } from "../../SettingsProvider";
import MetricTypes from "../../../utils/metric-types";
import AboutThisKPI from "./AboutThisKPI";

const NewKPIForm = ({ form, isEdit, error, onFinish, onCancel, currency }) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const okText = s("createKPI.popup.save", "Save card");
  const changeCancelText = s("createKPI.popup.cancelEdit", "Don't change");
  const addCancelText = s("createKPI.popup.cancelAdd", "Don't add");
  const nameValidationText = s(
    "createKPI.popup.name.validationText",
    "Please add an outcome name to save"
  );
  const duplicateErrorText = s(
    "createKPI.popup.name.duplicateError",
    "Outcome name already exists"
  );

  const namePlaceholder = s(
    "createKPI.popup.name.placeholder",
    "Type outcome name"
  );
  const benefitPlaceholder = s(
    "createKPI.popup.benefitValueDriverDescription.placeholder",
    "Add description"
  );
  const categoryPlaceholder = s(
    "createKPI.popup.categoryCode.placeholder",
    "Select category"
  );
  const textRef = useRef();
  const formatPercentage = getPercentageFormatter({});
  const parsePercentage = getMetricParser({
    type: MetricTypes.PERCENTAGE,
    locale,
    currency
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (textRef.current) {
        textRef.current.focus({
          cursor: isEdit ? "end" : "start",
          preventScroll: true
        });
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [textRef]);

  useEffect(() => {
    if (error && error.status === 409) {
      form.setFields([
        {
          name: "name",
          errors: [duplicateErrorText]
        }
      ]);
      setTimeout(() => {
        textRef.current.focus({
          cursor: "end"
        });
      }, 1);
    }
  }, [error, textRef]);

  return (
    <Form form={form} layout={"vertical"} onFinish={onFinish}>
      <Mobile>
        <FormItem
          data-cy={"form-item-kpi-name"}
          name="name"
          rules={[
            {
              required: true,
              message: nameValidationText,
              whitespace: true
            }
          ]}
        >
          <KPINameTextArea
            autoSize={true}
            ref={textRef}
            placeholder={namePlaceholder}
          />
        </FormItem>
        <FormItem
          data-cy={"form-item-kpi-benefitValueDriverDescription"}
          name="benefitValueDriverDescription"
          rules={[
            {
              whitespace: true
            }
          ]}
        >
          <BenefitTextArea autoSize={true} placeholder={benefitPlaceholder} />
        </FormItem>
        <Heading level={"h5Small"} color={"gray3"}>
          {s("discovery.kpi.card.section.title", "Challenges Solved")}
        </Heading>
        <FormItem name="challengeMappings">
          <SelectLinkedChallenges />
        </FormItem>
        <Heading level={"h5Small"} color={"gray3"}>
          {s("discovery.kpi.card.impact.title", "Impact for You")}
        </Heading>
        <ImpactContainer>
          <FormItem name="lowImpactPercentage">
            <KPINumberInput
              formatter={formatPercentage}
              parser={parsePercentage}
              placeholder={"0%"}
            />
          </FormItem>
          <Text variant={"body"}>
            <ToText />
          </Text>
          <FormItem name="highImpactPercentage">
            <KPINumberInput
              formatter={formatPercentage}
              parser={parsePercentage}
              placeholder={"0%"}
            />
          </FormItem>
        </ImpactContainer>
        <FormItem data-cy={"form-item-kpi-categoryCode"} name="categoryCode">
          <SelectCategory
            placeholder={categoryPlaceholder}
            data-cy={"select-category"}
          />
        </FormItem>
        <AboutThisKPI editMode={true} style={{ marginBottom: "24px" }} />
        <FormItem>
          <ButtonGroup>
            <NewButton type={"submit"}>{okText}</NewButton>
            <NewButton
              type={"secondary"}
              onClick={onCancel}
              data-cy={"cancel-button"}
            >
              {isEdit ? changeCancelText : addCancelText}
            </NewButton>
          </ButtonGroup>
        </FormItem>
      </Mobile>
      <DesktopOrTablet>
        <DesktopContainer>
          <Contents>
            <LeftColumn>
              <FormItem
                data-cy={"form-item-kpi-name"}
                name="name"
                rules={[
                  {
                    required: true,
                    message: nameValidationText,
                    whitespace: true
                  }
                ]}
              >
                <KPINameTextArea
                  autoSize={true}
                  ref={textRef}
                  placeholder={namePlaceholder}
                />
              </FormItem>
              <FormItem
                data-cy={"form-item-kpi-benefitValueDriverDescription"}
                name="benefitValueDriverDescription"
                rules={[
                  {
                    required: false,
                    whitespace: true
                  }
                ]}
              >
                <BenefitTextArea
                  autoSize={true}
                  placeholder={benefitPlaceholder}
                />
              </FormItem>
              <div style={{ marginBottom: 16 }}>
                <Heading level={"h5"} color={"gray3"}>
                  {s("discovery.kpi.card.section.title", "Challenges Solved")}
                </Heading>
                <FormItem name="challengeMappings">
                  <SelectLinkedChallenges />
                </FormItem>
              </div>
            </LeftColumn>
            <RightColumn>
              <Heading level={"h5"} color={"gray3"}>
                {s("discovery.kpi.card.impact.title", "Impact for You")}
              </Heading>
              <Space direction={"horizontal"} align={"baseline"}>
                <FormItem name="lowImpactPercentage">
                  <KPINumberInput
                    formatter={formatPercentage}
                    placeholder={"0%"}
                  />
                </FormItem>
                <Text variant={"h4"}>
                  <ToText />
                </Text>
                <FormItem name="highImpactPercentage">
                  <KPINumberInput
                    formatter={formatPercentage}
                    placeholder={"0%"}
                  />
                </FormItem>
              </Space>
              <FormItem
                data-cy={"form-item-kpi-categoryCode"}
                name="categoryCode"
              >
                <SelectCategory
                  placeholder={categoryPlaceholder}
                  data-cy={"select-category"}
                />
              </FormItem>
            </RightColumn>
          </Contents>
          <AboutThisKPI editMode={true} />
          <ButtonGroup>
            <NewButton type={"submit"}>{okText}</NewButton>
            <NewButton
              type={"secondary"}
              onClick={onCancel}
              data-cy={"cancel-button"}
            >
              {isEdit ? changeCancelText : addCancelText}
            </NewButton>
          </ButtonGroup>
        </DesktopContainer>
      </DesktopOrTablet>
    </Form>
  );
};

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftColumn = styled.div`
  flex-grow: 1;
  padding-right: 20px;
`;

const RightColumn = styled.div`
  max-width: 250px;
  min-width: 250px;
`;

const ImpactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  & > *:first-child,
  & > *:last-child {
    flex: 1;
  }

  & .ant-form-item {
    margin-bottom: 0;
  }

  & .ant-input-number input {
    width: 100%;
    max-width: unset;
  }
`;

NewKPIForm.propTypes = {
  form: PropType.object.isRequired,
  onFinish: PropType.func.isRequired,
  onCancel: PropType.func.isRequired,
  currency: PropType.string.isRequired,
  isEdit: PropType.bool,
  error: PropType.object
};

export default NewKPIForm;
