import PropTypes from "prop-types";
import DescriptionList from "../../../../components/DescriptionList";
import { useContext } from "react";
import { SettingsContext } from "../../../../components/SettingsProvider";
import { useString as s } from "../../../../components/StringProvider";
import { formatConfigurationDetailDate } from "../../../../utils/formatting";

const NotesTemplateDetails = ({ log }) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const lastUpdated = s(
    "organization.page.configurationLogs.details.lastUpdated",
    "Last Updated"
  );
  const updatedBy = s(
    "organization.page.configurationLogs.details.updatedBy",
    "Updated By"
  );
  const createdByDefault = s(
    "accessManagement.page.roles.createdBy.default",
    "Cuvama"
  );

  return (
    <div>
      <DescriptionList>
        <dt>{lastUpdated}</dt>
        <dd>
          {formatConfigurationDetailDate({ time: log.createdAt, locale })}
        </dd>
        <dt>{updatedBy}</dt>
        <dd>{log.username || createdByDefault}</dd>
      </DescriptionList>
    </div>
  );
};

NotesTemplateDetails.propTypes = {
  log: PropTypes.object.isRequired
};

export default NotesTemplateDetails;
