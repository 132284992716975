import { NewButton } from "../../components";
import styled from "styled-components";
import Color from "color";
import { themeProp } from "../../theme";

const ValueMapCardTickButton = (props) => {
  return <StyledButton type={"iconCanvas"} {...props} />;
};

const StyledButton = styled(NewButton)`
  color: ${(props) => {
    const rgbArray = Color(themeProp("palette.gray2")(props)).rgb().array();
    return `rgba(${rgbArray.join(",")}, 0.7) !important;`;
  }};

  .selected & {
    color: ${themeProp("palette.secondary")} !important;
  }

  &:hover:not(:active):not(:focus):not(:disabled) {
    color: ${themeProp("palette.gray3")} !important;
  }

  .selected &:hover:not(:active):not(:focus):not(:disabled) {
    color: ${themeProp("palette.secondary")} !important;
  }

  .selected.editing:not(.keyObjective) & {
    color: ${(props) => {
      const rgbArray = Color(themeProp("palette.secondary")(props))
        .rgb()
        .array();
      return `rgba(${rgbArray.join(",")}, 0.5) !important;`;
    }};
  }

  .selected.keyObjective & {
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      color: white !important;
    }
  }
`;

export default ValueMapCardTickButton;
