import React from "react";
import { useString as s } from "../StringProvider";
import Form, { FormItem } from "../Form";
import PropTypes from "prop-types";
import { assignDiscovery } from "../../store/actions/discoveries";
import SelectUser from "./SelectUser";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { hasPermission } from "../../store/reducers";
import Text from "../Text";
import styled from "styled-components";
import NewButton from "../NewButton";
import Permissions from "../../utils/permissions";
import ButtonGroup from "../ButtonGroup";
import Modal from "../Modal";

const AssignDiscoveryModal = ({
  discoveryId,
  currentUserId,
  canSeeAllDiscoveries,
  assignDiscovery,
  onClose
}) => {
  const [form] = Form.useForm();
  const okText = s("assign_discovery.popup.okButton", "Assign");
  const cancelText = s("assign_discovery.popup.cancelButton", "Cancel");
  const titleText = s("assign_discovery.popup.header", "Assign to");
  const emailText = s("assign_discovery.popup.email.header", "User email");
  const emailValidationText = s(
    "assign_discovery.popup.email.validationText",
    "Please select a user"
  );
  const emailPlaceholderText = s(
    "assign_discovery.popup.email.placeholder",
    "Type user email to select from the list"
  );
  const warningText = s(
    "assign_discovery.popup.warning",
    "By continuing, you will change ownership of this discovery to the selected user, and the discovery will be removed from your list."
  );

  const onFinish = async (values) => {
    console.log("userId", values);
    assignDiscovery({
      discoveryId,
      userId: values.userId,
      canSeeAllDiscoveries
    }).then(() => {
      onClose();
    });
  };

  return (
    <Modal title={titleText} open={true} onCancel={onClose}>
      <WarningText>
        <Text>{warningText}</Text>
      </WarningText>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          label={emailText}
          name="userId"
          rules={[
            {
              required: true,
              message: emailValidationText
            }
          ]}
        >
          <SelectUser
            excludeUsers={[currentUserId]}
            placeholder={emailPlaceholderText}
          />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <NewButton type={"submit"}>{okText}</NewButton>
            <NewButton type={"secondary"} onClick={onClose}>
              {cancelText}
            </NewButton>
          </ButtonGroup>
        </FormItem>
      </Form>
    </Modal>
  );
};

const WarningText = styled.div`
  margin-bottom: 16px;
`;

AssignDiscoveryModal.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  canSeeAllDiscoveries: hasPermission(state, Permissions.SEE_ALL_DISCOVERIES)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      assignDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AssignDiscoveryModal
);
