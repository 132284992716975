import { useEffect, useState } from "react";
import { useString as s } from "../../components/StringProvider";
import styled, { css } from "styled-components";
import Heading from "../../components/Heading";
import { useDrop } from "react-dnd";
import PropTypes from "prop-types";
import ItemTypes from "../../components/ItemTypes";
import KeyObjectiveCard from "./KeyObjectiveCard";
import { themeProp } from "../../theme";
import CreateCard from "./CreateCard";
import {
  sendUserPilotEvent,
  UserPilotEventNames
} from "../../utils/user-pilot-util";

const KeyObjective = ({
  keyObjective,
  onChange,
  onSaveItem,
  onCreateKeyObjective,
  onDeleteKeyObjective,
  discoveryId,
  onReorder
}) => {
  const header1 = s(
    "valueMap.page.keyObjective.header1",
    "What happens if you don't solve these challenges?"
  );
  const header2 = s(
    "valueMap.page.keyObjective.header2",
    "Click to add Critical Business Issue"
  );
  const placeholder = s(
    "valueMap.page.keyObjective.placeholder",
    "Add Critical Business Issue"
  );

  const [keyObjectiveCardHeight, setKeyObjectiveCardHeight] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const onDrop = (item) => {
    onChange({ item });
  };

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: ItemTypes.VALUE_MAP_CHALLENGE,
    drop: onDrop,
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver()
    })
  }));

  const isHovering = isHovered && !canDrop && !keyObjective && !isCreating;

  const onClick = (event) => {
    event.stopPropagation();

    if (!keyObjective && !isCreating) {
      setIsCreating(true);

      sendUserPilotEvent({
        eventName: UserPilotEventNames.CLICK_TO_ADD_CBI,
        data: { discoveryId }
      });
    }
  };

  useEffect(() => {
    if (keyObjective) {
      setIsCreating(false);
    } else {
      setIsHovered(false);
    }
  }, [keyObjective]);

  return (
    <Container
      ref={dropRef}
      className={
        (canDrop && isOver ? "active " : "") + (keyObjective ? "filled" : "")
      }
      cardHeight={keyObjectiveCardHeight}
      isHovered={isHovering}
      isCreating={isCreating}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Heading
        className="up-canvas-drag-key-objective"
        name={"canvas-drag-key-objective"}
        level={"h5"}
        color={isHovering ? "surface" : "gray3"}
        onClick={onClick}
        style={{
          marginBottom: isCreating ? 0 : ""
        }}
      >
        {isHovering ? header2 : header1}
      </Heading>
      {!!keyObjective && !isCreating && (
        <KeyObjectiveCard
          item={keyObjective}
          onSave={({ text }) => {
            onSaveItem({ item: keyObjective, data: { text } });
          }}
          setCardHeight={setKeyObjectiveCardHeight}
          cardHeight={keyObjectiveCardHeight}
          onDelete={onDeleteKeyObjective}
          onReorder={onReorder}
        />
      )}
      {isCreating && (
        <CreateCard
          onCreate={onCreateKeyObjective}
          onCancel={() => setIsCreating(false)}
          searchLibrary={undefined}
          searchLibraryData={undefined}
          isCreateKeyObjective={true}
          placeholder={placeholder}
        />
      )}
      {isCreating && <KeyObjectiveOverlay />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${themeProp("palette.surface")};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  padding: 0 10px;
  margin-bottom: 4px;
  border-top: 1px dashed transparent;
  border-bottom: 1px dashed transparent;
  transition: min-height 0.5s ease-in-out;
  position: relative;

  ${({ isCreating }) =>
    !isCreating &&
    css`
      min-height: 27px;
    `}

  & > * {
    margin-bottom: 10px;
  }

  & h5 {
    text-align: center;
    margin: 0;
    margin: 4px;
    transition: 0.5s ease-in-out;
  }

  &.active {
    border-color: #376fff;
    background: #cee5fb;
    min-height: 130px;
    transition: min-height 0.5s ease-in-out;
  }

  &.filled {
    transition: min-height 0.1s ease-in-out;
    min-height: ${(props) =>
      props.cardHeight !== 0
        ? `calc(36px + ${props.cardHeight}px)`
        : "fit-content"};
  }

  &.filled:not(.active) {
    border-color: transparent;
  }

  &.active h5 {
    transition: min-height 0.5s ease-in-out;
    color: #376fff;
    opacity: 1;
  }

  &:hover {
    ${({ isHovered }) =>
      isHovered &&
      css`
        transition: 0.5s ease-in-out;
        background: ${themeProp("palette.secondary")};
        cursor: pointer;
      `}
  }
`;

const KeyObjectiveOverlay = styled.div`
  width: 100%;
  height: 27px;
  top: 0;
  position: absolute;
  transform: translateX(-10px);
  z-index: 100;
`;

KeyObjective.propTypes = {
  keyObjective: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSaveItem: PropTypes.func.isRequired,
  onCreateKeyObjective: PropTypes.func.isRequired,
  onDeleteKeyObjective: PropTypes.func.isRequired,
  discoveryId: PropTypes.string.isRequired,
  onReorder: PropTypes.func.isRequired
};

export default KeyObjective;
