import styled from "styled-components";
import { connect } from "react-redux";
import Heading from "../../components/Heading";
import NewButton from "../../components/NewButton";
import { useString as s } from "../../components/StringProvider";

const InitializationError = ({ authentication }) => {
  console.error("initializationError", authentication.initializationError);
  const header = s(
    "initialization_error.page.header",
    "500: Something went wrong"
  );
  const button = s(
    "initialization_error.page.button",
    "Click here to try again"
  );

  return (
    <Container>
      <ContainerHeader>
        <Heading level="h2">{header}</Heading>
      </ContainerHeader>
      <NewButton type={"primary"} onClick={() => window.location.reload()}>
        {button}
      </NewButton>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 80px;
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const ContainerHeader = styled.div`
  margin-bottom: 24px;
`;

const mapStateToProps = (state) => ({
  authentication: state.authentication
});

export default connect(mapStateToProps)(InitializationError);
