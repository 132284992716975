const QuestionVisualizations = {
  INTEGER_VALUE: "simpleNumberEntryBox",
  ONE_COLUMN_CHECK_BOXES: "oneColumnCheckBoxes",
  TWO_COLUMN_CHECK_BOXES: "twoColumnCheckBoxes",
  ONE_COLUMN_RADIO_BUTTONS: "oneColumnRadioButtons",
  TWO_COLUMN_RADIO_BUTTONS: "twoColumnRadioButtons"
};

QuestionVisualizations.ALL = Object.values(QuestionVisualizations);

export default QuestionVisualizations;
