import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import NewButton from "../NewButton";

const TIMEOUT_INTERVAL = 1000;

const ModalAnimationButton = ({ children, image, onComplete }) => {
  const [isLoading, setLoading] = useState(false);
  let timeout = null;

  const onClick = () => {
    setLoading(true);
    setTimeout(() => {
      onComplete();
    }, TIMEOUT_INTERVAL);
  };

  useEffect(() => () => {
    clearTimeout(timeout);
  });

  return isLoading ? (
    <AnimationWrapper className={"modalAnimationButton"}>
      <img src={image} alt={"Processing..."} />
    </AnimationWrapper>
  ) : (
    <NewButton
      type={"primary"}
      data-cy={"modal-animation-button"}
      onClick={onClick}
    >
      {children}
    </NewButton>
  );
};
ModalAnimationButton.propTypes = {
  image: PropTypes.string,
  onComplete: PropTypes.func
};
ModalAnimationButton.defaultProps = {
  image: "",
  onComplete: () => {}
};

const AnimationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  animation: fadeIn ease 0.4s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
  background-color: #fafafa;
  text-align: center;

  video {
    width: 100%;
    height: 100%;
  }
`;

export default ModalAnimationButton;
