import httpService from "../../../../services/http.service";
import EnvironmentNames from "../../../../utils/constants/EnvironmentNames";
import IntegrationNames from "../../../../utils/constants/integration-names";

const getOAuthRedirectUri = ({ name, salesforceIntegrationV2 }) => {
  if (!salesforceIntegrationV2 && name === IntegrationNames.SALESFORCE) {
    return `${window.location.origin}/organizations/general/salesforce-connection-callback`;
  }

  switch (httpService.getEnvironmentId()) {
    case EnvironmentNames.LIVE:
      return `https://identity.cuvama.com/integrations/${name}/callback`;

    case EnvironmentNames.DEV:
      return `https://identity.dev.cuvama.com/integrations/${name}/callback`;
  }

  return `${window.location.origin}/integrations/${name}/callback`;
};

export default getOAuthRedirectUri;
