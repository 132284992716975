import React from "react";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { themeProp } from "../../../theme";
import { useTheme } from "../../../components/ThemeProvider";
import { selectDiscoveryName } from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";

const PrintReportCoverOne = ({ discoveryName }) => {
  return (
    <ReportHeaderContainer>
      <ReportHeaderContent>
        <ReportHeaderContentWrapper>
          <Heading level="h1">
            {s("report.page.header", "Opportunity for {discoveryName}", {
              discoveryName
            })}
          </Heading>
          <Heading level={"h4"}>{s("report.page.subheader2")}</Heading>
        </ReportHeaderContentWrapper>
      </ReportHeaderContent>
      <ReportHeaderImage src={useTheme("assets.report_header_cover")} />
    </ReportHeaderContainer>
  );
};

const ReportHeaderContainer = styled.header`
  background-color: ${themeProp("palette.background")};
  display: flex;
  min-height: 100vh;
`;
const ReportHeaderImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const ReportHeaderContent = styled.div`
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const ReportHeaderContentWrapper = styled.div`
  width: 480px;
`;

const mapStateToProps = (state) => ({
  discoveryName: selectDiscoveryName(state)
});

export default compose(connect(mapStateToProps))(PrintReportCoverOne);
