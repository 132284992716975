import React from "react";
import styled from "styled-components";

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  width: 120px;
  max-width: 120px;
  padding: 4px 4px;
  border: 1px solid #bfc9d9;
  border-radius: 4px;

  input[type="color"] {
    margin-right: 2px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 25px;
      height: 25px;
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      border-radius: 4px;
      padding: 0;
    }
  }

  input[type="text"] {
    border: none;
    width: 100%;
    font-size: 14px;
  }
`;

const ColorPicker = ({ value, onChange }) => {
  return (
    <Container>
      <input type="color" value={value} onChange={onChange} />
      <Value>{value}</Value>
    </Container>
  );
};

const Value = styled.div`
  margin-left: 10px;
`;

export default ColorPicker;
