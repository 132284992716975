import types from "../actionTypes";
import stringService from "../../services/string.service";

export const getStrings = () => (dispatch) => {
  dispatch({
    type: types.GET_STRINGS_REQUEST
  });

  return stringService
    .getStrings()
    .then((payload) => {
      dispatch({
        type: types.GET_STRINGS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_STRINGS_FAILURE,
        error
      });
    });
};

export const downloadStrings = () => (dispatch) => {
  dispatch({
    type: types.DOWNLOAD_STRINGS_REQUEST
  });

  return stringService
    .downloadStrings()
    .then((payload) => {
      dispatch({
        type: types.DOWNLOAD_STRINGS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.DOWNLOAD_STRINGS_FAILURE,
        error
      });
    });
};
