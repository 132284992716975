import SolutionBadge from "./SolutionBadge";
import Text from "../../Text";
import PropType from "prop-types";

const SolutionBadges = ({ editable, solutions, emptyText, onClick }) => {
  return solutions.length > 0 ? (
    solutions.map((solution, index) => (
      <SolutionBadge
        key={index}
        solution={solution}
        editable={editable}
        onClick={onClick}
      />
    ))
  ) : (
    <Text variant={"small"} color={"surface"}>
      {emptyText}
    </Text>
  );
};

SolutionBadges.propTypes = {
  editable: PropType.bool,
  emptyText: PropType.string,
  solutions: PropType.array,
  onClick: PropType.func.isRequired
};

export default SolutionBadges;
