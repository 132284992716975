import { useString as s } from "../../../../../components/StringProvider";
import { ReactComponent as Icon } from "../../../../../assets/editor/text-left.svg";
import ToolbarButton from "./ToolbarButton";

const LeftAlignButton = ({ onClick, active }) => {
  return (
    <ToolbarButton
      active={active}
      onClick={onClick}
      label={s("editor.buttons.left.label", "Left Align")}
    >
      <Icon />
    </ToolbarButton>
  );
};

export default LeftAlignButton;
