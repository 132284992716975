const openInNewWindow = ({
  url,
  width = 500,
  height = 650,
  left = 200,
  top = 200
}) => {
  const features = [
    `width=${width}`,
    `height=${height}`,
    `left=${left}`,
    `top=${top}`
  ];

  const newWin = window.open(url, "", features.join(","));

  if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
    return false;
  }

  return true;
};

export default openInNewWindow;
