import React from "react";
import PropTypes from "prop-types";
import { ValueMapCardContainer, TickContainer } from "./ValueMapCardUI";
import ValueMapCardTickButton from "./ValueMapCardTickButton";
import ItemTypes from "../../components/ItemTypes";
import { Icon, NewButton } from "../../components";
import ValueMapCardText from "./ValueMapCardText";
import classNames from "classnames";
import { CardMenu } from "./ValueMapCard";
import { CommentsButton } from "../../components/Discovery/Comments";
import { UserPilotEventNames } from "../../utils/user-pilot-util";

const ValueMapCardPreview = ({ text, type, code, selected, discoveryId }) => {
  return (
    <ValueMapCardContainer
      className={classNames({
        selected
      })}
      data-cy={`${type}-${code}`}
    >
      <TickContainer>
        <ValueMapCardTickButton
          data-cy={`${type}-${code}-tick`}
          className={
            type === ItemTypes.VALUE_MAP_CHALLENGE
              ? "up-canvas-tick-challenges"
              : "up-canvas-tick-outcomes"
          }
        >
          <Icon name={"tick"} size={"large"} />
        </ValueMapCardTickButton>
      </TickContainer>
      <ValueMapCardText text={text} type={type} code={code} />
      <CardMenu>
        <CommentsButton
          topic={`/discoveries/${discoveryId}/${type}s/${code}`}
          commentPanelMaxHeight={220}
          eventName={
            type === ItemTypes.VALUE_MAP_CHALLENGE
              ? UserPilotEventNames.ADD_NEW_COMMENT_CANVAS_CHALLENGE
              : UserPilotEventNames.ADD_NEW_COMMENT_CANVAS_OUTCOME
          }
        />
        <NewButton
          type={"iconSecondary"}
          data-cy={`valuemap-card-dropdown-menu`}
        >
          <Icon name={"dropdown"} />
        </NewButton>
      </CardMenu>
    </ValueMapCardContainer>
  );
};

ValueMapCardPreview.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    ItemTypes.VALUE_MAP_CHALLENGE,
    ItemTypes.VALUE_MAP_KPI
  ]).isRequired,
  code: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  discoveryId: PropTypes.string.isRequired
};

export default ValueMapCardPreview;
