import { selectCategory } from "../../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import React, { useContext } from "react";
import { SettingsContext } from "../../../../components/SettingsProvider";
import { getValue } from "../../value-hypothesis-util";
import { formatRangeNumber } from "../../../../utils/formatting";
import KPIImpactFormats from "../../../../utils/kpi-impact-formats";
import PropTypes from "prop-types";
import MetricTypes from "../../../../utils/metric-types";
import ChartColumn from "../ChartColumn";

const getText = ({ kpi, scenario, delayTimeline, currency }) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const value = getValue({ kpi, scenario, delayTimeline });

  return kpi.hasImpactValue
    ? formatRangeNumber({
        value,
        type: MetricTypes.CURRENCY,
        options: { locale, currency, notation: "compact" }
      })
    : formatRangeNumber({
        value:
          kpi.definition.impactFormat === KPIImpactFormats.MULTIPLIER ||
          kpi.definition.impactFormat === KPIImpactFormats.PERCENTAGE
            ? Math.round(value)
            : value,
        type:
          kpi.definition.impactFormat === KPIImpactFormats.MULTIPLIER
            ? KPIImpactFormats.PERCENTAGE
            : kpi.definition.impactFormat,
        options: {
          locale,
          currency,
          showPlusSign: true,
          showUnits: true,
          customImpactFormat: kpi.definition.customImpactFormat,
          notation: "compact"
        }
      });
};

const KPIColumn = ({
  discoveryKPI,
  index,
  category,
  currency,
  scenario,
  delayTimeline
}) => {
  const color =
    category &&
    (discoveryKPI.hasImpactValue ? category.color : category.chartColor);
  const borderColor =
    category &&
    (discoveryKPI.hasImpactValue ? undefined : category.chartBorderColor);
  const text = getText({
    kpi: discoveryKPI,
    scenario,
    delayTimeline,
    currency
  });

  return (
    <ChartColumn
      index={index}
      title={discoveryKPI.definition.name}
      color={color}
      borderColor={borderColor}
      value={text}
      dataCy={"outcome-chart-column-bar"}
    />
  );
};

KPIColumn.propTypes = {
  discoveryKPI: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  scenario: PropTypes.number.isRequired,
  delayTimeline: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    category: selectCategory(state, props.discoveryKPI.definition.categoryCode)
  };
};

export default compose(connect(mapStateToProps))(KPIColumn);
