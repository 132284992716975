import styled from "styled-components";
import { Dropdown as ANTDropdown } from "antd";
import { POPUP_CONTAINER } from "./FullscreenProvider";
import { themeProp } from "../theme";

const Dropdown = styled(ANTDropdown).attrs((props) => ({
  getPopupContainer: () => document.getElementById(POPUP_CONTAINER),
  trigger: props.trigger ? props.trigger : ["click"]
}))``;

export const DropdownMenu = ({ dataCy, className, children, ...props }) => {
  return (
    <DropdownMenuDiv data-cy={dataCy} className={className} {...props}>
      {children}
    </DropdownMenuDiv>
  );
};

export const DropdownMenuDiv = styled.div`
  line-height: ${(props) => themeProp(`typography.body.lineHeight`)(props)};

  span {
    font-size: ${(props) => themeProp(`typography.body.fontSize`)(props)};
    font-family: ${(props) => themeProp(`typography.body.fontFamily`)(props)};
    font-weight: ${(props) => themeProp(`typography.body.fontWeight`)(props)};
    line-height: inherit;
    color: ${(props) => themeProp(`palette.black`)(props)};
    vertical-align: middle;
  }

  & .ant-dropdown-menu-item-active span {
    color: ${(props) => themeProp(`palette.secondary`)(props)};
    font-weight: ${(props) =>
      props.useFontWeightForHover
        ? themeProp(`typography.bMedium.fontWeight`)(props)
        : undefined};
  }

  & .ant-dropdown-menu-item span {
    display: flex;
    align-items: center;
  }

  & .ant-dropdown-menu-item li {
    align-items: center;
  }

  & .ant-dropdown-menu-item svg {
    line-height: normal;
    margin-right: 8px;
  }

  & .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-active) svg {
    color: ${(props) => themeProp(`palette.gray3`)(props)};
  }

  & .ant-dropdown-menu-item-active svg {
    color: ${(props) => themeProp(`palette.black`)(props)};
  }
`;
export default Dropdown;
