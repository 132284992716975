import Heading from "../../../../components/Heading";
import Icon from "../../../../components/Icon";
import { Text } from "../../../../components";
import RelatedChallengeDescription from "../../../../components/Discovery/KPICard/RelatedChallengeDescription";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import PropType from "prop-types";
import { Space } from "antd";
import DiscoveryKPIPercentage from "./DiscoveryKPIPercentage";
import DiscoveryKPIImpactValue from "./DiscoveryKPIImpactValue";
import AboutThisCard from "../../../../components/Discovery/AboutThisCard";
import { useString as s } from "../../../../components/StringProvider";

const MobileDetails = ({ discoveryKPI, reportId, currency }) => {
  const viewDetailsButton = s(
    "discovery.opportunity.page.viewDetails",
    "View details"
  );
  const challengesSolved = s(
    "discovery.opportunity.page.challengesSolved",
    "Challenges Solved"
  );
  const noChallengesText = s(
    "discovery.kpi.card.challengesSolved.noChallenges.text",
    "No challenges related"
  );

  return (
    <Space direction={"vertical"} size={8}>
      <Text variant={"bMediumMobile"}>{discoveryKPI.definition.name}</Text>
      <PercentageAndImpact>
        <DiscoveryKPIPercentage
          discoveryKPI={discoveryKPI}
          reportId={reportId}
        />

        <DiscoveryKPIImpactValue
          discoveryKPI={discoveryKPI}
          currency={currency}
          reportId={reportId}
        />
      </PercentageAndImpact>
      <AboutThisCard text={viewDetailsButton}>
        <div>
          <Text variant={"small"}>
            {discoveryKPI.definition.benefitValueDriverDescription}
          </Text>
          <Heading level={"h5"} color={"gray3"} style={{ marginTop: "8px" }}>
            {challengesSolved}
          </Heading>
          {discoveryKPI.relatedChallengeCodes.map((challengeCode, index) => (
            <DescriptionItem key={index}>
              <Icon name="check" colour="secondary" />
              <Text variant={"small"}>
                <RelatedChallengeDescription challengeCode={challengeCode} />
              </Text>
            </DescriptionItem>
          ))}
          {discoveryKPI.relatedChallengeCodes.length === 0 ? (
            <Text variant={"small"} color={"gray3"}>
              {noChallengesText}
            </Text>
          ) : null}
        </div>
      </AboutThisCard>
    </Space>
  );
};

const PercentageAndImpact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const DescriptionItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;

  svg {
    margin-top: 6px;
    display: block;
    margin-right: 10px;
    color: ${themeProp("palette.success")};
  }
`;

MobileDetails.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  reportId: PropType.string
};

export default MobileDetails;
