import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";

const INITIAL_STATE = {
  notificationCount: 0,
  notificationCountLoadingState: LoadingState.UNINITIALIZED,
  notificationCountError: null,
  notificationsLoadingState: LoadingState.UNINITIALIZED,
  notificationsError: null,
  notificationIds: [],
  notificationsMap: {},
  notificationsReadLoadingState: LoadingState.UNINITIALIZED,
  notificationsReadError: null
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COUNT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationCountLoadingState: LoadingState.IN_PROGRESS,
        notificationCountError: null
      };
    case types.COUNT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationCountLoadingState: LoadingState.COMPLETED,
        notificationCount: action.payload.count
      };
    case types.COUNT_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationCountLoadingState: LoadingState.FAILED,
        notificationCountError: action.payload.error
      };

    case types.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationsLoadingState: LoadingState.IN_PROGRESS,
        notificationsError: null
      };
    case types.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsLoadingState: LoadingState.FAILED,
        notificationsError: action.payload.error
      };
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsLoadingState: LoadingState.COMPLETED,
        notificationIds: action.payload.notifications.map(
          (notification) => notification._id
        ),
        notificationsMap: action.payload.notifications.reduce(
          (map, notification) => {
            map[notification._id] = notification;
            return map;
          },
          {}
        )
      };
    case types.ADD_NOTIFICATION:
      const newNotification = action.payload.notification;
      const newNotificationIds = [
        newNotification._id,
        ...state.notificationIds
      ];
      const newNotificationsMap = { ...state.notificationsMap };

      newNotificationsMap[newNotification._id] = newNotification;

      return {
        ...state,
        notificationCount: state.notificationCount + 1,
        notificationIds: newNotificationIds,
        notificationsMap: newNotificationsMap
      };

    case types.READ_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationsReadLoadingState: LoadingState.IN_PROGRESS,
        notificationsReadError: null
      };
    case types.READ_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsReadLoadingState: LoadingState.FAILED,
        notificationsReadError: action.payload.error
      };
    case types.READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsReadLoadingState: LoadingState.COMPLETED
      };

    default:
      return state;
  }
};

export default notificationsReducer;

export const selectNotificationCount = (notificationsData) =>
  notificationsData.notificationCount;
export const selectNotificationCountLoadingState = (notificationsData) =>
  notificationsData.notificationCountLoadingState;
export const selectNotificationsLoadingState = (notificationsData) =>
  notificationsData.notificationsLoadingState;
export const selectNotificationIds = (notificationsData) =>
  notificationsData.notificationIds;
export const selectNotification = (notificationsData, notificationId) =>
  notificationsData.notificationsMap[notificationId];
export const selectNotificationsReadLoadingState = (notificationsData) =>
  notificationsData.notificationsReadLoadingState;
