import Features from "../../../../utils/constants/Features";

const getFeatureGroups = ({ s }) => [
  {
    groupId: 1,
    name: s("manageFeatures.form.group1.title", "My Discoveries Page"),
    items: [
      {
        name: Features.DISCOVERY_TYPES_ENABLED,
        label: s(
          "manageFeatures.features.DISCOVERY_TYPES_ENABLED.name",
          "Discovery Types"
        ),
        description: s(
          "manageFeatures.features.DISCOVERY_TYPES_ENABLED.description",
          'Whether or not to show the "Discovery Types" column in My Discoveries. (Managed by the application.)'
        ),
        disabled: true
      },
      {
        name: Features.DISCOVERY_VERSION,
        label: s(
          "manageFeatures.features.DISCOVERY_VERSION.name",
          "Discovery Version"
        ),
        description: s(
          "manageFeatures.features.DISCOVERY_VERSION.description",
          "Whether or not to show the discovery MD version in My Discoveries"
        )
      }
    ]
  },
  {
    groupId: 2,
    name: s("manageFeatures.form.group2.title", "Integrations"),
    items: [
      {
        name: Features.SALESFORCE_INTEGRATION,
        label: s(
          "manageFeatures.features.SALESFORCE_INTEGRATION.name",
          "Salesforce Integration (V1)"
        ),
        description: s(
          "manageFeatures.features.SALESFORCE_INTEGRATION.description",
          "Whether or to show screens related to the Salesforce managed package"
        )
      },
      {
        name: Features.SALESFORCE_INTEGRATION_V2,
        label: s(
          "manageFeatures.features.SALESFORCE_INTEGRATION_V2.name",
          "Salesforce Integration (V2)"
        ),
        description: s(
          "manageFeatures.features.SALESFORCE_INTEGRATION_V2.description",
          "Whether or not the Salesforce integration V2 is enabled. (Requires Salesforce Integration V1 to be enabled)"
        ),
        dependsOn: Features.SALESFORCE_INTEGRATION
      },
      {
        name: Features.HUBSPOT_INTEGRATION,
        label: s(
          "manageFeatures.features.HUBSPOT_INTEGRATION.name",
          "Hubspot Integration"
        ),
        description: s(
          "manageFeatures.features.HUBSPOT_INTEGRATION.description",
          "Whether or to show screens related to HubSpot integration"
        )
      },
      {
        name: Features.CRM_ACCOUNTS_INTEGRATION,
        label: s(
          "manageFeatures.features.CRM_ACCOUNTS_INTEGRATION.name",
          "CRM Accounts Integration"
        ),
        description: s(
          "manageFeatures.features.CRM_ACCOUNTS_INTEGRATION.description",
          "Whether or not accounts and opportunities are managed by an external CRM that an admin can upload via Cuvama admin"
        )
      },
      {
        name: Features.EXTERNAL_DISCOVERY_MANAGEMENT,
        label: s(
          "manageFeatures.features.EXTERNAL_DISCOVERY_MANAGEMENT.name",
          "External Discovery Management"
        ),
        description: s(
          "manageFeatures.features.EXTERNAL_DISCOVERY_MANAGEMENT.description",
          "Whether or not discoveries are managed by an external CRM. When this is true, the user cannot edit the account or opportunity via Cuvama"
        )
      }
    ]
  },
  {
    groupId: 3,
    name: s(
      "manageFeatures.form.group3.title",
      "Salesforce Integration Features (Requires Salesforce Integration V1 to be enabled)"
    ),
    items: [
      {
        name: Features.SALESFORCE_LEAD_CREATION,
        label: s(
          "manageFeatures.features.SALESFORCE_LEAD_CREATION.name",
          "Salesforce Lead Creation"
        ),
        description: s(
          "manageFeatures.features.SALESFORCE_LEAD_CREATION.description",
          "Whether or not Salesforce integration should create Leads for prospect-led discoveries"
        ),
        dependsOn: Features.SALESFORCE_INTEGRATION
      },
      {
        name: Features.SALESFORCE_USERS_SYNC,
        label: s(
          "manageFeatures.features.SALESFORCE_USERS_SYNC.name",
          "Salesforce Users Synchronization"
        ),
        description: s(
          "manageFeatures.features.SALESFORCE_USERS_SYNC.description",
          "Whether or not Salesforce Users sync is enabled"
        ),
        dependsOn: Features.SALESFORCE_INTEGRATION
      },
      {
        name: Features.SALESFORCE_OPPORTUNITIES_SYNC,
        label: s(
          "manageFeatures.features.SALESFORCE_OPPORTUNITIES_SYNC.name",
          "Salesforce Opportunities Synchronization"
        ),
        description: s(
          "manageFeatures.features.SALESFORCE_OPPORTUNITIES_SYNC.description",
          "Whether or not Salesforce Opportunities sync is enabled"
        ),
        dependsOn: Features.SALESFORCE_INTEGRATION
      },
      {
        name: Features.SALESFORCE_MULTI_CURRENCY,
        label: s(
          "manageFeatures.features.SALESFORCE_MULTI_CURRENCY.name",
          "Salesforce Multi Currency"
        ),
        description: s(
          "manageFeatures.features.SALESFORCE_MULTI_CURRENCY.description",
          "Whether or not the connected Salesforce instance supports multi-currency. (Managed by the application.)"
        ),
        disabled: true
      },
      {
        name: Features.SALESFORCE_SANDBOX,
        label: s(
          "manageFeatures.features.SALESFORCE_SANDBOX.name",
          "Salesforce Sandbox"
        ),
        description: s(
          "manageFeatures.features.SALESFORCE_SANDBOX.description",
          "Whether or not the connected Salesforce instance is a sandbox or a scratch organization."
        ),
        dependsOn: Features.SALESFORCE_INTEGRATION
      }
    ]
  },
  {
    groupId: 4,
    name: s("manageFeatures.form.group4.title", "Other"),
    items: [
      {
        name: Features.CRM_BCC_ENABLED,
        label: s(
          "manageFeatures.features.CRM_BCC_ENABLED.name",
          "CRM BCC Email Address"
        ),
        description: s(
          "manageFeatures.features.CRM_BCC_ENABLED.description",
          "Whether or not to show the BCC option when sending email to CRM"
        )
      },
      {
        name: Features.ROCKET_SHIP,
        label: s(
          "manageFeatures.features.ROCKET_SHIP.name",
          "Manage Solutions (Rocket Ship)"
        ),
        description: s(
          "manageFeatures.features.ROCKET_SHIP.description",
          "Whether or not to show the Rocket Ship solutions button"
        )
      }
    ]
  }
];

export default getFeatureGroups;
