import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { useString as s } from "../StringProvider";
import NewButton from "../NewButton";
import Permissions from "../../utils/permissions";
import { hasPermission } from "../../store/reducers";

const NewDiscoveryButton = ({ canSeeNewDiscoveryButton, onNewDiscovery }) => {
  const newDiscovery = s(
    "discoveries.dashboard.newdiscovery.button",
    "New discovery"
  );

  return canSeeNewDiscoveryButton ? (
    <NewButton
      type={"primary"}
      onClick={onNewDiscovery}
      data-cy={"new-discovery-button"}
      className={"up-new-discovery-button"}
    >
      {newDiscovery}
    </NewButton>
  ) : null;
};

NewDiscoveryButton.propTypes = {
  onNewDiscovery: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  canSeeNewDiscoveryButton: hasPermission(
    state,
    Permissions.SEE_NEW_DISCOVERY_BUTTON
  )
});

export default compose(connect(mapStateToProps))(NewDiscoveryButton);
