import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { useString as s } from "../../components/StringProvider";
import Permissions from "../../utils/permissions";
import { hasPermission } from "../../store/reducers";
import NewDiscoveryButton from "../../components/DiscoveryOperations/NewDiscoveryButton";
import styled from "styled-components";
import {
  useMobileMediaQuery,
  useWindowDimensions
} from "../../components/Responsive";
import { Empty, Space } from "antd";
import Text from "../../components/Text";

const CreateFirstDiscovery = ({ canSeeNewDiscoveryButton, onNewDiscovery }) => {
  const { height } = useWindowDimensions();
  const isMobile = useMobileMediaQuery();
  const title = s(
    "discoveries.dashboard.table.empty.title",
    "Create your first discovery"
  );
  const subTitle = s(
    "discoveries.dashboard.table.empty.subtitle",
    "Start discovering customer value!"
  );
  if (!canSeeNewDiscoveryButton) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Container
      height={height}
      isMobile={isMobile}
      data-cy={"create-first-discovery"}
    >
      <Title>
        <Space direction={"horizontal"} size={6}>
          <Text variant={"h4"}>
            <b>{title}</b>
          </Text>
          <Emoji>🎉</Emoji>
        </Space>
      </Title>
      <SubTitle>
        <Text variant={"body"}>{subTitle}</Text>
      </SubTitle>
      <NewDiscoveryButton onNewDiscovery={onNewDiscovery} />
    </Container>
  );
};

const SubTitle = styled.div`
  margin-bottom: 15px;
`;

const Emoji = styled.div`
  padding-bottom: 7px;
  font-size: 1.2rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.isMobile ? "20px 20px" : "20px 50px")};
  gap: 8px;
`;

CreateFirstDiscovery.propTypes = {
  onNewDiscovery: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  canSeeNewDiscoveryButton: hasPermission(
    state,
    Permissions.SEE_NEW_DISCOVERY_BUTTON
  )
});

export default compose(connect(mapStateToProps))(CreateFirstDiscovery);
