import { Paragraph } from "../../components/Typography";
import { NewButton } from "../../components";
import styled from "styled-components";
import { useString as s } from "../../components/StringProvider";
import { ReactComponent as InfoIcon } from "../../assets/InfoCircleOutlined.svg";
import Icon from "../../components/Icon";

const CanvasNotificationPanel = ({ description, onClick, close }) => {
  const undo = s("valueMap.page.deleteNotification.undo.label", "Undo");

  return (
    <Panel>
      <Left>
        <InfoIcon />
      </Left>
      <Right>
        <div>
          <Paragraph color={"gray4"} variant={"body"}>
            {description}
          </Paragraph>
          <NewButton onClick={close} type={"closeIcon"}>
            <Icon name={"close"} colour={"inherit"} size={"base"} />
          </NewButton>
        </div>
        <div>
          <NewButton type={"text"} style={{ left: -8 }} onClick={onClick}>
            {undo}
          </NewButton>
        </div>
      </Right>
    </Panel>
  );
};

const Panel = styled.div`
  min-width: 240px;
  display: flex;
  flex-direction: row;
  gap: 18px;

  .ant-notification-notice-message {
    display: none !important;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Right = styled.div`
  flex-grow: 1;

  & > * {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
`;

export default CanvasNotificationPanel;
