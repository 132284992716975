import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { FinancialCards, Statistics } from "../../ValueHypothesis/components";
import PropType from "prop-types";

const PrintReportValueHypothesisOne = ({ name, reportId, kpis, currency }) => {
  return (
    <Container>
      <Heading level={"h1"} align={"center"}>
        {s(
          "discovery.opportunity.page.header",
          "Value Hypothesis for {discoveryName}",
          { discoveryName: name }
        )}
      </Heading>
      <Body>
        <Left>
          <FinancialCards reportId={reportId} printMode={true} />
        </Left>
        <Right>
          <Statistics kpis={kpis} currency={currency} />
        </Right>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${themeProp("palette.surface")};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;

  & div:first-child {
    margin-right: 2px;
  }

  & div:nth-child(2) {
    margin-left: 2px;
  }
`;

const Left = styled.div`
  margin-right: 32px;
  flex: 1;
`;

const Right = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 295px;
`;

PrintReportValueHypothesisOne.propTypes = {
  name: PropType.string.isRequired,
  reportId: PropType.string.isRequired,
  currency: PropType.string.isRequired,
  kpis: PropType.array.isRequired
};

export default PrintReportValueHypothesisOne;
