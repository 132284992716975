import React, { useCallback, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { bindActionCreators, compose } from "redux";
import {
  resetDiscoveryMetric,
  saveDiscoveryMetric
} from "../../../store/actions/discoveries";
import { connect } from "react-redux";
import styled from "styled-components";
import { SettingsContext } from "../../../components/SettingsProvider";
import { InputGroup, SliderInput } from "../../../components/Input";
import { selectDiscoveryMetric } from "../../../store/reducers";
import * as PropTypes from "prop-types";
import { themeProp } from "../../../theme";
import { ReactComponent as LeftTriangleIcon } from "../../../assets/LeftTriangle.svg";
import { ReactComponent as RightTriangleIcon } from "../../../assets/RightTriangle.svg";
import { ReactComponent as ResetIcon } from "../../../assets/Reset.svg";
import { useString as s } from "../../../components/StringProvider";
import ResetButton from "./ResetButton";
import {
  getMetricFormatter,
  getMetricMarkFormatter,
  getMetricParser,
  getMetricTooltipFormatter
} from "../../../utils/formatting";
import {
  DesktopOrTablet,
  useMobileMediaQuery
} from "../../../components/Responsive";

const SLIDER_DEBOUNCE_TIMEOUT = 1000;

const icons = {
  leftTriangle: <LeftTriangleIcon />,
  rightTriangle: <RightTriangleIcon />,
  reset: <ResetIcon />
};

const RangeIcon = ({ name, visible }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <RangeIconContainer
      alt={name}
      name={name}
      visible={visible}
      className={isMobile ? "mobile" : undefined}
    >
      {icons[name]}
    </RangeIconContainer>
  );
};

const MetricWidget = ({
  discoveryId,
  currency,
  metric,
  resetDiscoveryMetric,
  saveDiscoveryMetric
}) => {
  const isMobile = useMobileMediaQuery();
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const [modified, setModified] = useState(metric.modified);
  const [metricValue, setMetricValue] = useState(metric.value);
  const [isUpdating, setUpdating] = useState(false);

  const debounceSaveDiscoveryMetric = useCallback(
    _.debounce(saveDiscoveryMetric, SLIDER_DEBOUNCE_TIMEOUT, {
      trailing: true
    }),
    []
  );

  useEffect(() => {
    if (!isUpdating || String(metric.value) === String(metricValue)) {
      setMetricValue(metric.value);
    }
  }, [metric.value]);

  useEffect(() => {
    setModified(metric.modified);
  }, [metric.modified]);

  useEffect(() => {
    setUpdating(false);
  }, [metric]);

  const isNumber = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  };

  const formatter = getMetricFormatter({ type: metric.type, locale, currency });
  const inputFormatter = getMetricFormatter({
    type: metric.type,
    locale,
    currency,
    options: { notation: "standard" }
  });

  const parser = getMetricParser({ type: metric.type, locale, currency });
  const tooltipFormatter = getMetricTooltipFormatter({
    type: metric.type,
    locale,
    currency
  });
  const markFormatter = getMetricMarkFormatter({
    type: metric.type,
    locale,
    currency
  });

  const marks = Array.from(Array(11).keys()).reduce((map, n) => {
    const value =
      ((metric.maximumUserValue - metric.minimumUserValue) * n) / 10 +
      metric.minimumUserValue;

    map[value] =
      n === 0 || n === 5 || n === 10
        ? (n === 0 && value > 0 ? "<" : "") +
          markFormatter(value) +
          (n === 10 && (value !== 100 || metric.type !== "Percentage")
            ? ">"
            : "")
        : true; // TO DO: Need to format;
    return map;
  }, {});

  const step = (metric.maximumUserValue - metric.minimumUserValue) / 20;
  const tooltipText = s(
    "discovery.metrics.page.reset.tooltip",
    "Reset to default {defaultValue}",
    { defaultValue: tooltipFormatter(metric.defaultValue) }
  );

  const onChange = (v) => {
    if (isNumber(v)) {
      if (metric.type === "Percentage" && v > 100) {
        setMetricValue(100);
      } else {
        setMetricValue(v);
      }
    }
  };

  const onSaveChange = (v) => {
    if (isNumber(v)) {
      const newValue = v < 0 ? metric.minimumUserValue : v;
      setMetricValue(newValue);

      const changes = { value: newValue };
      setUpdating(true);

      debounceSaveDiscoveryMetric({
        discoveryId,
        metricCode: metric.metricCode,
        changes
      });
    }
  };

  const onResetMetric = () => {
    setModified(false);
    setMetricValue(metric.defaultValue);
    resetDiscoveryMetric({ discoveryId, metricCode: metric.metricCode });
  };

  const resetButton = (
    <ResetButton
      visible={modified && !isUpdating}
      onReset={onResetMetric}
      tooltipText={tooltipText}
    />
  );

  return (
    <InputGroup
      order={metric.order}
      title={metric.name}
      help={metric.description}
      data-cy={"metric-widget"}
    >
      <SliderWrapper className={isMobile ? "mobile" : undefined}>
        <RangeWrapper>
          <RangeIcon
            name={"leftTriangle"}
            visible={metricValue < metric.minimumUserValue}
          />
          <SliderInput
            code={metric.metricCode}
            value={Number(metricValue)}
            min={metric.minimumUserValue}
            max={metric.maximumUserValue}
            tipFormatter={tooltipFormatter}
            inputFormatter={inputFormatter}
            formatter={formatter}
            inputParser={parser}
            step={step}
            isInputLimited={false}
            marks={marks}
            onChange={onChange}
            onAfterChange={onSaveChange}
            resetButton={isMobile ? resetButton : undefined}
            color={"secondary"}
          />
          <RangeIcon
            name={"rightTriangle"}
            visible={metricValue > metric.maximumUserValue}
          />
        </RangeWrapper>
        <DesktopOrTablet>{resetButton}</DesktopOrTablet>
      </SliderWrapper>
    </InputGroup>
  );
};

const RangeIconContainer = styled.div`
  position: absolute;
  ${(props) => (props.visible ? undefined : "display: none;")}
  top: 7px;
  ${(props) =>
    props.name === "rightTriangle" ? "right: 210px;" : "left: -15px;"}
  color: ${(props) => themeProp(`palette.secondary`)(props)};

  svg {
    height: 12px;
    path {
      fill: currentColor;
    }
  }

  &.mobile {
    top: 4px;

    ${(props) =>
      props.name === "rightTriangle" ? "right: -15px;" : "left: -15px;"}
  }
`;

const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & .ant-input-number {
    margin-left: 50px;
  }

  &.mobile {
    flex-direction: column;
    align-items: stretch;
  }

  &.mobile .ant-input-number {
    margin-left: 0;
  }

  .ant-input-number {
    width: 160px !important;
  }
`;

MetricWidget.propTypes = {
  metricCode: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => ({
  metric: selectDiscoveryMetric(state, props.metricCode)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryMetric,
      resetDiscoveryMetric
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MetricWidget
);
