import PropTypes from "prop-types";
import { selectCategory } from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";

const CategoryName = ({ category }) => {
  return category ? category.name : "";
};

CategoryName.propTypes = {
  categoryCode: PropTypes.string.isRequired
};

function mapStateToProps(state, props) {
  return {
    category: selectCategory(state, props.categoryCode)
  };
}

export default compose(connect(mapStateToProps))(CategoryName);
