import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";
import getDomain from "../../utils/get-domain";

const INITIAL_STATE = {
  tokenRefreshLoadingState: LoadingState.UNINITIALIZED,
  refreshCall: null,
  initializationError: null,
  isInitialLoginComplete: false,
  isAuthenticated: false,
  isLoginWithUnconfirmedUser: false,
  isSignupWithExistingUser: false,
  token: null,
  userInfoLoadingState: LoadingState.UNINITIALIZED,
  user: {},
  userInfoError: null,
  isUserRegistered: false,
  registrationLoadingState: LoadingState.UNINITIALIZED,
  registeredUsername: null,
  registrationError: null,
  isConfirmationResent: false,
  confirmationError: null,
  forgotPasswordEmail: null,
  forgotPasswordLoadingState: LoadingState.UNINITIALIZED,
  forgotPasswordError: null
};

const authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.INITIALIZATION_FAILURE:
      return {
        ...state,
        initializationError: action.payload.error
      };

    case types.USER_INFO_REQUEST:
      return {
        ...state,
        userInfoLoadingState: LoadingState.IN_PROGRESS,
        userInfoError: null
      };
    case types.USER_INFO_SUCCESS:
      return {
        ...state,
        userInfoLoadingState: LoadingState.COMPLETED,
        user: action.payload.user
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user:
          state?.user?.userId === action.payload.userId
            ? { ...state.user, ...action.payload }
            : state.user
      };
    case types.USER_INFO_FAILURE:
      return {
        ...state,
        userInfoLoadingState: LoadingState.FAILED,
        user: {},
        userInfoError: action.payload.error
      };

    case types.TOKEN_REFRESH_REQUEST:
      return {
        ...state,
        tokenRefreshLoadingState: LoadingState.IN_PROGRESS
      };
    case types.TOKEN_REFRESH_SUCCESS:
      return {
        ...state,
        tokenRefreshLoadingState: LoadingState.COMPLETED,
        token: action.payload?.token
      };
    case types.TOKEN_REFRESH_FAILURE:
      return {
        ...state,
        tokenRefreshLoadingState: LoadingState.FAILED,
        token: undefined,
        isAuthenticated: false
      };

    case types.REGISTER_USER_REQUEST:
      return {
        ...state,
        registeredUsername: null,
        isSignupWithExistingUser: false
      };
    case types.REGISTER_USER_SUCCESS:
      return {
        ...state,
        registeredUsername: action.payload.username
      };
    case types.REGISTER_USER_FAILURE:
      return {
        ...state
      };

    case types.RESEND_CONFIRMATION_REQUEST:
      return {
        ...state,
        isConfirmationResent: false,
        confirmationError: null
      };
    case types.RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isConfirmationResent: true
      };
    case types.RESEND_CONFIRMATION_FAILURE:
      return {
        ...state,
        confirmationError: action.payload.error
      };

    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordLoadingState: LoadingState.IN_PROGRESS,
        forgotPasswordError: null
      };

    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordLoadingState: LoadingState.FAILED,
        forgotPasswordError: action.payload.error
      };

    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoadingState: LoadingState.COMPLETED,
        forgotPasswordError: null,
        forgotPasswordEmail: action.payload.email
      };

    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state
      };

    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state
      };

    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoadingState: LoadingState.COMPLETED,
        resetPasswordError: null
      };

    case types.AMPLIFY_INITIALIZED:
      console.log(action.type, !!action.payload?.token);
      return {
        ...state,
        isAuthenticated: !!action.payload?.token,
        token: action.payload?.token,
        isInitialLoginComplete: true
      };

    case types.AMPLIFY_SIGNED_IN:
      console.log(action.type, !!action.payload?.token);
      return {
        ...state,
        isAuthenticated: !!action.payload?.token,
        token: action.payload?.token
      };

    case types.AMPLIFY_SIGNED_OUT:
      console.log(action.type);
      return {
        ...state,
        isAuthenticated: false,
        userSettings: {},
        user: null,
        token: null,
        roles: []
      };

    default:
      return state;
  }
};

export default authentication;

export const selectInitializationError = (authentication) =>
  authentication.initializationError;
export const selectRegistrationError = (authentication) =>
  authentication.registrationError;
export const isAuthenticated = (authentication) =>
  authentication.isAuthenticated;
export const selectUser = (authentication) => authentication.user;
export const selectIsCuvamaUser = (authentication) => {
  const emailDomain = getDomain({ email: authentication?.user?.email });
  return emailDomain?.includes("cuvama.com");
};
export const selectUserId = (authentication) =>
  authentication.user ? authentication.user.userId : undefined;
export const selectAllPermissions = (authentication) =>
  authentication?.user?.permissions;
export const hasPermission = (authentication, permission) =>
  authentication?.user?.permissions
    ? authentication.user.permissions.includes(permission)
    : false;
export const selectUserInfoLoadingState = (authentication) =>
  authentication.userInfoLoadingState;
export const selectUserInfoError = (authentication) =>
  authentication.userInfoError;
export const selectForgotPasswordLoadingState = (authentication) =>
  authentication.forgotPasswordLoadingState;
export const selectForgotPasswordError = (authentication) =>
  authentication.forgotPasswordError;
export const selectForgotPasswordEmail = (authentication) =>
  authentication.forgotPasswordEmail;
export const isLoginWithUnconfirmedUser = (authentication) =>
  authentication.isLoginWithUnconfirmedUser;
export const isSignupWithExistingUser = (authentication) =>
  authentication.isSignupWithExistingUser;
export const selectConfirmationError = (authentication) =>
  authentication.confirmationError;
export const selectRegisteredUsername = (authentication) =>
  authentication.registeredUsername;
export const selectRegistrationLoadingState = (authentication) =>
  authentication.registrationLoadingState;
export const isInitialLoginComplete = (authentication) =>
  authentication.isInitialLoginComplete;
