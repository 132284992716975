import React, { useState } from "react";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { PageSection } from "../../../components/Page";
import {
  selectDiscoveryName,
  selectDiscoverySelectedChallenges
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ChallengeSummaryCards from "../../ChallengeSummary/ChallengeSummaryCards";
import MobileReportSection from "./MobileReportSection";
import NewButton from "../../../components/NewButton";
import MobileReportTitle from "./MobileReportTitle";
import { useMobileMediaQuery } from "../../../components/Responsive";

const ReportChallenges = ({ discoveryName, discoveryChallenges, reportId }) => {
  const isMobile = useMobileMediaQuery();

  const header = s(
    "report.challenges.header",
    "Challenges to be solved for {discoveryName}",
    { discoveryName }
  );
  const subheader = s(
    "report.challenges.subheader",
    "The business challenges we will solve together to achieve your Success Outcomes"
  );
  const showAllChallengesButton = s(
    "report.challenges.showAllChallenges",
    "Show all challenges"
  );
  const hideChallengesButton = s(
    "report.challenges.hideChallenges",
    "Hide challenges"
  );
  const [showAllChallenges, setShowAllChallenges] = useState(false);

  const onToggleShowAllChallenges = () => {
    setShowAllChallenges(!showAllChallenges);
  };

  return isMobile ? (
    <MobileReportSection id={"report-challenges-solved"}>
      <MobileReportTitle header={header} subheader={subheader} />
      <ChallengeSummaryCards
        reportId={reportId}
        showAllChallenges={showAllChallenges}
      />
      {discoveryChallenges.length > 4 ? (
        <ButtonContainer>
          <NewButton
            data-cy={"show-all-challenges"}
            type={"text"}
            onClick={onToggleShowAllChallenges}
          >
            {showAllChallenges ? hideChallengesButton : showAllChallengesButton}
          </NewButton>
        </ButtonContainer>
      ) : null}
    </MobileReportSection>
  ) : (
    <ReportChallengesContainer id={"report-challenges-solved"}>
      <ReportChallengesTitle level="h1" align="center">
        {header}
      </ReportChallengesTitle>
      <Heading level="h4" align="center" style={{ marginBottom: 24 }}>
        {subheader}
      </Heading>
      <ChallengeSummaryCards reportId={reportId} />
    </ReportChallengesContainer>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const ReportChallengesContainer = styled(PageSection)`
  padding: 120px 24px;
  @media print {
    padding: 0px;
  }
`;
const ReportChallengesTitle = styled(Heading)`
  margin-bottom: 16px;
`;

ReportChallenges.propTypes = {
  reportId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  discoveryName: selectDiscoveryName(state),
  discoveryChallenges: selectDiscoverySelectedChallenges(state)
});

export default compose(connect(mapStateToProps))(ReportChallenges);
