import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  selectDiscoveryId,
  selectDiscoveryQuestion,
  selectQuestion
} from "../../../store/reducers";
import React, { useEffect, useState } from "react";
import ArrowRightIcon from "./ArrowRightIcon";
import { useString as s } from "../../../components/StringProvider";
import Heading from "../../../components/Heading";
import styled from "styled-components";
import { Space } from "antd";
import { Text } from "../../../components";
import { CommentsButton } from "../../../components/Discovery/Comments";
import NewButton from "../../../components/NewButton";
import QuestionVisualizations from "../../../utils/question-visualizations";
import CheckBoxes from "./CheckBoxes";
import RadioButtons from "./RadioButtons";
import IntegerValueSimpleNumberEntryBox from "./IntegerValueSimpleNumberEntryBox";
import QuestionTypes from "../../../utils/question-types";
import {
  Desktop,
  useMobileOrTabletMediaQuery
} from "../../../components/Responsive";
import ButtonGroup, { ButtonGroupModes } from "../../../components/ButtonGroup";
import Form, { FormItem } from "../../../components/Form";
import { saveDiscoveryQuestionNextDisabled } from "../../../store/actions/discoveries";

export const QuestionIconBar = ({ children }) => {
  return (
    <IconBarContainer className={"question-icon-bar"}>
      <Space direction={"horizontal"}>{children}</Space>
    </IconBarContainer>
  );
};

const IconBarContainer = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  justify-content: flex-end;
  visibility: hidden;

  & .open-comments-button-open,
  & .open-comments-button-has-comments {
    visibility: visible;
  }
`;

const getMinAndMax = (question) => {
  return question.options
    .map(({ label }) => label.split(/\s*-\s*/))
    .map((pair) => [parseInt(pair[0]), parseInt(pair[1])])
    .reduce(
      (r, i) => [r[0] < i[0] ? r[0] : i[0], r[1] < i[1] ? r[1] : i[1]],
      []
    );
};

const QuestionForm = ({
  discoveryQuestion,
  question,
  discoveryId,
  questionNumber,
  editable,
  onNext,
  saveDiscoveryQuestionNextDisabled,
  form,
  quickCanvasButton
}) => {
  const isMobileOrTablet = useMobileOrTabletMediaQuery();
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const nextText = s("discovery.question.button.text", "Next");
  const hitEnterText = s(
    "discovery.question.button.subtext",
    "or hit 'Enter' to continue"
  );

  useEffect(() => {
    //TODO: is there any reason to move resetFields() to where form.submit() is called? i.e. when nextButtonClicked is triggered
    form.resetFields();
    form.validateFields().catch(() => {});
  }, []);

  useEffect(() => {
    // Store isSubmitDisabled in redux so that QuestionNavigator can use it
    saveDiscoveryQuestionNextDisabled({ disabled: isSubmitDisabled });
  }, [isSubmitDisabled]);

  useEffect(() => {
    /*
      set the initialValues here instead of at the Form level as this was causing
      a bug because the value from the previous question was being passed to the next
      Question component (before it changed to the next answer) and causing an Error:

      TypeError: checkboxGroup.value.includes is not a function.
     */

    form.setFieldsValue({ answer: discoveryQuestion.answer });
  }, [discoveryQuestion]);

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    /*
      Only set the submit button state once validation has completed.
      Not doing this was causing a flicker of the Canvas button from
      active to disabled.
     */

    if (!form.isFieldValidating("answer")) {
      setSubmitDisabled(!!hasErrors);
    }
  };

  const renderOptions = () => {
    switch (question.questionVisualization) {
      case QuestionVisualizations.INTEGER_VALUE:
        const [min, max] = getMinAndMax(question);

        return (
          <IntegerValueSimpleNumberEntryBox
            min={min}
            max={max}
            editable={editable}
          />
        );

      case QuestionVisualizations.ONE_COLUMN_RADIO_BUTTONS:
      case QuestionVisualizations.TWO_COLUMN_RADIO_BUTTONS:
        return (
          <RadioButtons
            questionNumber={questionNumber}
            editable={editable}
            width={question?.visualizationOptions?.width}
          />
        );

      case QuestionVisualizations.ONE_COLUMN_CHECK_BOXES:
      case QuestionVisualizations.TWO_COLUMN_CHECK_BOXES:
        return (
          <CheckBoxes
            questionNumber={questionNumber}
            editable={editable}
            width={question?.visualizationOptions?.width}
          />
        );

      default:
        return question.questionType;
    }
  };

  const isValidAnswer = (answer) => {
    switch (question.questionType) {
      case QuestionTypes.MULTIPLE_TEXT_VALUE:
        return (
          (answer && Array.isArray(answer) && answer.length > 0) ||
          !question?.visualizationOptions?.compulsorySelection
        );

      case QuestionTypes.INTEGER_VALUE:
        const [min, max] = getMinAndMax(question);

        return (
          (answer && parseInt(answer) <= max && parseInt(answer) >= min) ||
          (!answer && !question?.visualizationOptions?.compulsorySelection)
        );

      default:
        return answer || !question?.visualizationOptions?.compulsorySelection;
    }
  };

  return question && questionNumber !== undefined ? (
    <Form form={form} onFieldsChange={onFieldsChange} onFinish={onNext}>
      <SurveyWrapper
        isMobileOrTablet={isMobileOrTablet}
        className={isMobileOrTablet ? "mobileOrTablet" : undefined}
      >
        <HoverContainer className={"hover-container"}>
          <Header className={isMobileOrTablet ? "mobileOrTablet" : undefined}>
            <TitleBar
              className={isMobileOrTablet ? "mobileOrTablet" : undefined}
            >
              <Heading level={isMobileOrTablet ? "h3" : "h2"}>
                {question.question}
                {isMobileOrTablet ? (
                  <MobileCommentContainer>
                    <CommentsButton
                      topic={`/discoveries/${discoveryId}/questions/${questionNumber}`}
                    />
                  </MobileCommentContainer>
                ) : null}
              </Heading>
              <Desktop>
                <QuestionIconBar>
                  <CommentsButton
                    topic={`/discoveries/${discoveryId}/questions/${questionNumber}`}
                  />
                </QuestionIconBar>
              </Desktop>
            </TitleBar>
            <Text variant={isMobileOrTablet ? "bodyMobile" : "body"}>
              {question.prompt}
            </Text>
          </Header>
          <FormItem
            className={questionNumber === 0 ? "up-question-area" : ""}
            name={"answer"}
            rules={[
              () => ({
                validator(_, value) {
                  if (!isValidAnswer(value)) {
                    return Promise.reject(new Error(""));
                  }

                  return Promise.resolve();
                }
              })
            ]}
          >
            {renderOptions()}
          </FormItem>
        </HoverContainer>
        <MarginContainer
          isMobileOrTablet={isMobileOrTablet}
          className={isSubmitDisabled ? "hidden" : undefined}
        >
          <ButtonGroup mode={ButtonGroupModes.MOBILE_OR_TABLET}>
            <NewButton data-cy={"next-question-button"} type={"submit"}>
              {nextText}
              <ArrowRightIcon />
            </NewButton>
            {quickCanvasButton}
            {question.type === "integerValue" ? (
              <Text color={"gray3"}>{hitEnterText}</Text>
            ) : null}
          </ButtonGroup>
        </MarginContainer>
      </SurveyWrapper>
    </Form>
  ) : null;
};

const MobileCommentContainer = styled.div`
  margin-left: 8px;
  display: inline-block;

  & > * {
    vertical-align: middle;
  }
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  &.mobileOrTablet {
    justify-content: center;
  }
`;

const MarginContainer = styled.div`
  margin-bottom: ${({ isMobileOrTablet }) =>
    isMobileOrTablet ? "64px" : "0px"};
  text-align: ${({ isMobileOrTablet }) => (isMobileOrTablet ? "center" : "")};

  &.hidden {
    visibility: hidden;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  &.mobileOrTablet {
    text-align: center;
  }

  &.mobileOrTablet h3 {
    text-align: center;
    margin-bottom: 8px;
  }
`;

const SurveyWrapper = styled.div`
  width: 700px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;

  &.mobileOrTablet {
    width: 100%;
  }

  &.mobileOrTablet .ant-checkbox-group,
  &.mobileOrTablet .ant-radio-group {
    width: 100%;
  }
`;

const HoverContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:hover .question-icon-bar {
    visibility: visible;
  }
`;

QuestionForm.propTypes = {
  form: PropTypes.object.isRequired,
  questionNumber: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  const discoveryQuestion = selectDiscoveryQuestion(
    state,
    parseInt(props.questionNumber)
  );

  return {
    discoveryId: selectDiscoveryId(state),
    question: selectQuestion(state, discoveryQuestion.questionCode),
    discoveryQuestion
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryQuestionNextDisabled
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  QuestionForm
);
