import PropTypes from "prop-types";
import { hasPermission } from "../../../store/reducers";
import Permissions from "../../../utils/permissions";
import { compose } from "redux";
import { connect } from "react-redux";
import Heading from "../../Heading";
import ChallengeLink from "../ChallengeCard/ChallengeLink";
import { useString as s } from "../../StringProvider";

const KPILinks = ({
  discoveryKPI,
  reportId,
  canSeeInternalLinksAndComments
}) => {
  const sourcesText = s("discovery.kpis.card.more.links.title", "Sources");

  const links = discoveryKPI.definition.links
    ? discoveryKPI.definition.links.filter(
        (link) =>
          (!reportId && canSeeInternalLinksAndComments) ||
          link.internalOnly === false ||
          link.internalOnly === undefined
      )
    : [];

  return links.length ? (
    <div>
      <Heading level={"h5"} color={"gray3"}>
        {sourcesText}
      </Heading>
      {links.map((link, index) => (
        <ChallengeLink link={link} key={index} />
      ))}
    </div>
  ) : null;
};

KPILinks.propTypes = {
  reportId: PropTypes.string,
  discoveryKPI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    canSeeInternalLinksAndComments: hasPermission(
      state,
      Permissions.SEE_INTERNAL_LINKS_AND_COMMENTS
    )
  };
};

export default compose(connect(mapStateToProps))(KPILinks);
