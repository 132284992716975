import Page from "../../components/Page";
import { ErrorPageLayout } from "../../components/Layout";
import { useString as s } from "../../components/StringProvider";
import Heading from "../../components/Heading";
import { Text } from "../../components";
import { Space } from "antd";
import { ReactComponent as Icon403 } from "../../assets/403.svg";
import NewButton from "../../components/NewButton";

const Forbidden = () => {
  const headerText = s("forbidden_page.header", "Sorry");
  const errorText = s(
    "forbidden_page.text",
    "Not your fault, but you don’t have access to this page. Please try to reload the page later or request to grant you access."
  );
  const buttonText = s("forbidden_page.reload_page", "Reload Page");

  const reloadPage = () => {
    location.reload();
  };

  return (
    <Page>
      <ErrorPageLayout>
        <Space direction={"vertical"} size={"large"}>
          <Icon403 alt={"403 error"} aria-hidden={true} />
          <Heading level={"h3"} color={"gray4"}>
            {headerText}
          </Heading>
          <Text color={"gray4"}>{errorText}</Text>
          <NewButton type={"alternate"} onClick={reloadPage}>
            {buttonText}
          </NewButton>
        </Space>
      </ErrorPageLayout>
    </Page>
  );
};

export default Forbidden;
