import {
  selectDiscoveryChallenges,
  selectDiscoveryId,
  selectDiscoverySetting,
  selectIsCreatingChallenge,
  selectMasterDataMap,
  selectScrollToChallengeCode
} from "../../store/reducers";
import { selectDiscoveryChallengeGroups } from "../../store/selectors/groups-of-challenges";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import GroupOfChallenges from "./GroupOfChallenges";
import { Space } from "antd";
import React, { useEffect } from "react";
import { CreateChallengeModal } from "../../components/Discovery/ChallengeModal";
import { isDiscoveryGroupCollapsed } from "../../store/selectors/discovery";
import { scrollToSection } from "../Report/components/Header/ScrollUtils";
import { updateDiscoverySettingsGroup } from "../../store/actions/discoveries";
import { clearScrollToChallengeCode } from "../../store/actions/challenge-library";
import ExpandedOrCollapsed from "../../utils/ExpandedOrCollapsed";
import { isTypicalChallenge } from "../../utils/filters/challenge-filters";
import DiscoverySettings from "../../utils/discoverySettings";
import ShowCardOptions from "../../utils/show-card-options";

const ViewChallengesByGroups = ({
  groupIds,
  isCreatingChallenge,
  isScrollToGroupCollapsed,
  scrollToChallengeCode,
  scrollToCategoryCode,
  clearScrollToChallengeCode,
  updateDiscoverySettingsGroup,
  discoveryId
}) => {
  const toggleGroupExpandedState = ({ groupCode }) => {
    updateDiscoverySettingsGroup({
      discoveryId,
      groupId: groupCode,
      status: ExpandedOrCollapsed.EXPANDED
    });
  };

  useEffect(() => {
    if (scrollToCategoryCode && isScrollToGroupCollapsed !== false) {
      toggleGroupExpandedState({ groupCode: scrollToCategoryCode });
    } else if (scrollToChallengeCode && !isScrollToGroupCollapsed) {
      scrollToSection({ sectionId: `challenge-card-${scrollToChallengeCode}` });
      clearScrollToChallengeCode();
    }
  }, [scrollToChallengeCode, scrollToCategoryCode, isScrollToGroupCollapsed]);

  return (
    <Space direction={"vertical"} size={16}>
      {isCreatingChallenge && <CreateChallengeModal />}
      {groupIds
        ? groupIds
            .split(",")
            .map((groupId) => (
              <GroupOfChallenges
                key={groupId}
                groupId={groupId}
                collapsedByDefault={true}
              />
            ))
        : null}
    </Space>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettingsGroup,
      clearScrollToChallengeCode
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const showChallenges = selectDiscoverySetting(
    state,
    DiscoverySettings.SHOW_CHALLENGES,
    ShowCardOptions.ALL_CARDS
  );
  const discoveryChallenges =
    selectDiscoveryChallenges(state)?.filter(isTypicalChallenge);
  const categoryMap = selectMasterDataMap(state, "categories");
  const scrollToChallengeCode = selectScrollToChallengeCode(state);
  const scrollToCategoryCode =
    scrollToChallengeCode &&
    discoveryChallenges.find(
      (challenge) => challenge.challengeCode === scrollToChallengeCode
    )?.definition?.categoryCode;
  const isScrollToGroupCollapsed =
    scrollToCategoryCode &&
    isDiscoveryGroupCollapsed(
      selectDiscoverySetting(state, "groups"),
      scrollToCategoryCode
    );

  return {
    groupIds: selectDiscoveryChallengeGroups(
      discoveryChallenges,
      showChallenges,
      categoryMap
    ),
    isCreatingChallenge: selectIsCreatingChallenge(state),
    isScrollToGroupCollapsed,
    scrollToChallengeCode,
    scrollToCategoryCode,
    discoveryId: selectDiscoveryId(state)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ViewChallengesByGroups
);
