import styled from "styled-components";
import PropTypes from "prop-types";
import React from "react";

const AlternateViewportContent = ({
  children,
  alternate,
  alwaysShowContent
}) => {
  return (
    <Container alwaysShowContent={alwaysShowContent}>
      <div className={"alternate-display"}>{alternate}</div>
      <div className={"full-display"}>{children}</div>
    </Container>
  );
};

const Container = styled.div`
  .alternate-display {
    display: none;
  }
  .full-display {
    display: block;
  }

  @media only screen and (max-width: 1024px) {
    .alternate-display {
      display: ${({ alwaysShowContent }) =>
        alwaysShowContent ? "none" : "block"};
    }
    .full-display {
      display: ${({ alwaysShowContent }) =>
        alwaysShowContent ? "block" : "none"};
    }
  }
`;

AlternateViewportContent.propTypes = {
  alternate: PropTypes.object.isRequired,
  alwaysShowContent: PropTypes.bool.isRequired
};

AlternateViewportContent.defaultProps = {
  alwaysShowContent: false
};

export default AlternateViewportContent;
