import { bindActionCreators, compose } from "redux";
import _ from "lodash";
import { updateUser } from "../../../../store/actions/users";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import Form from "../../../../components/Form";
import { message } from "antd";
import DuplicateUserAlert from "./DuplicateUserAlert";
import PropTypes from "prop-types";
import {
  selectAccessManagementUserById,
  selectRequestError,
  selectRequestState
} from "../../../../store/reducers";
import NewButton from "../../../../components/NewButton";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import UserForm from "./UserForm";
import { Modal } from "../../../../components";

const EditUserModal = ({
  userId,
  user,
  visible,
  loadingState,
  error,
  onClose,
  updateUser,
  userType
}) => {
  const [form] = Form.useForm();
  const cancelText = s("accessManagement.page.users.edit.cancel", "Cancel");
  const errorText = s(
    "accessManagement.page.users.edit.error",
    "Failed to update User"
  );
  const successText = s(
    "accessManagement.page.users.edit.success",
    "User was successfully updated"
  );
  const saveButtonText = {
    default: s("accessManagement.page.userForm.save", "Save"),
    loading: s("accessManagement.page.userForm.saving", "Saving...")
  };

  const [isDuplicateError, setDuplicateError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const isSalesforceData = user?.salesforce;

  useEffect(() => {
    if (user) {
      const { email, roles, firstName, lastName, jobTitle, country } = user;

      form.setFieldsValue({
        email: email,
        roleIds: roles?.map((role) => role._id) || [],
        firstName: firstName,
        lastName: lastName,
        jobTitle: jobTitle,
        country: country
      });
    }
  }, [user]);

  useLoadingState(
    loadingState,
    () => {
      onClose();
      message.success(successText);
      setIsLoading(false);
    },
    () => {
      setIsLoading(false);

      if (error) {
        if (error.status === 409) {
          setDuplicateError(true);
        } else {
          setDuplicateError(false);
          message.error(errorText);
        }
      } else {
        setDuplicateError(false);
        message.error(errorText);
      }
    },
    () => {
      setIsLoading(true);
    }
  );

  const mandatoryFields = ["email", "roleIds"];

  const onValuesChange = () => {
    const someErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length);

    const isFieldsFilled = mandatoryFields.every((field) =>
      form.getFieldValue(field)
    );

    setDisabled(!isFieldsFilled || someErrors);
  };

  const onFinish = async (values) => {
    const roleIds = values.roleIds;

    const { email, firstName, lastName, jobTitle, country } = values;

    if (
      email !== user.email ||
      firstName !== user.firstName ||
      lastName !== user.lastName ||
      jobTitle !== user.jobTitle ||
      country !== user.country ||
      !_.isEqual(roleIds, user.roleIds)
    ) {
      updateUser({
        userId,
        roleIds,
        newEmail: email,
        firstName,
        lastName,
        jobTitle,
        country
      });
    }
  };

  return (
    <Modal
      title={s("accessManagement.popup.editUser.header", "Edit User")}
      visible={visible}
      onCancel={onClose}
    >
      {isDuplicateError ? <DuplicateUserAlert /> : null}
      <UserForm
        form={form}
        onFinish={onFinish}
        submitButtonText={saveButtonText}
        userType={userType}
        disabled={disabled}
        onValuesChange={onValuesChange}
        isSalesforceData={isSalesforceData}
        isLoading={isLoading}
      >
        <NewButton type={"secondary"} onClick={onClose} data-cy={"cancel-edit"}>
          {cancelText}
        </NewButton>
      </UserForm>
    </Modal>
  );
};

EditUserModal.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: selectAccessManagementUserById(state, ownProps.userId),
  loadingState: selectRequestState(state, actionTypes.UPDATE_USER_REQUEST),
  error: selectRequestError(state, actionTypes.UPDATE_USER_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditUserModal
);
