import { message } from "antd";
import {
  FileDragger,
  FileUploadStatus,
  ValidationErrors
} from "../../ManageConfiguration/components";
import NewButton from "../../../../components/NewButton";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import {
  selectAllFiles,
  selectFileAdditionalData,
  selectFileFileId,
  selectFileState,
  selectFileValidationId,
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import {
  addFileToList,
  clearFileList
} from "../../../../store/actions/file-list";
import {
  createValidation,
  deployValidation
} from "../../../../store/actions/validations";
import { connect } from "react-redux";
import { FileStates } from "../../../../store/reducers/file-list";
import JobMonitor from "../../../../components/JobMonitor";
import styled from "styled-components";
import ValidationWorkflowTypes from "../../../../utils/validation-workflow-types";
import ButtonGroup from "../../../../components/ButtonGroup";
import useLoadingState from "../../../../utils/use-loading-state";
import { Alert, Modal } from "../../../../components";
import { getConversationPrompts } from "../../../../store/actions/ai";

const UploadAIPromptTemplatesModal = ({
  onClose,
  fileId,
  files,
  fileState,
  validation,
  validationId,
  validationLoadingState,
  deploymentLoadingState,
  addFileToList,
  clearFileList,
  createValidation,
  deployValidation,
  deploymentRequestData,
  getConversationPrompts,
  additionalData
}) => {
  const title = s(
    "organization.page.uploadAIPromptTemplates.title",
    "Upload AI Prompt Templates"
  );
  const startValidation = s(
    "organization.page.uploadAIPromptTemplates.startValidationButton.label",
    "Start Validation"
  );
  const cancel = s(
    "organization.page.uploadAIPromptTemplates.cancelButton.label",
    "Cancel"
  );
  const updatePrompts = s(
    "organization.page.uploadAIPromptTemplates.updatePromptsButton.label",
    "Update Prompts"
  );
  const deploymentSuccess = s(
    "organization.page.uploadAIPromptTemplates.messages.deploymentSuccess",
    "Prompt templates successfully updated"
  );
  const deploymentFailure = s(
    "organization.page.uploadAIPromptTemplates.messages.deploymentFailure",
    "Failed to update prompt templates"
  );
  const numberOfPrompts = s(
    "organization.page.uploadAIPromptTemplates.messages.createdUsersCount",
    "{count} {count, plural, one {prompt} other {prompts}} found in file",
    { count: additionalData?.numberOfPrompts }
  );
  const [jobId, setJobId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    clearFileList();
  }, []);

  useLoadingState(
    deploymentLoadingState,
    () => {
      setJobId(deploymentRequestData._id);
      setLoading(true);
    },
    () => {}
  );

  useLoadingState(
    validationLoadingState,
    () => {
      setLoading(false);
    },
    () => {
      setLoading(false);
    },
    () => {
      setLoading(true);
    }
  );

  const onStartUpload = (file) => {
    addFileToList({ file });
  };

  const onStartValidation = () => {
    createValidation({
      fileId,
      workflowType: ValidationWorkflowTypes.AI_PROMPT_TEMPLATES
    });
  };

  const onDeploy = () => {
    deployValidation({ validationId });
  };

  const onJobFinish = (successful) => {
    if (successful) {
      message.success(deploymentSuccess);
      getConversationPrompts();
      setLoading(false);
      onClose();
    } else {
      message.error(deploymentFailure);
      setJobId("");
      setLoading(false);
    }
  };

  const renderButtons = () => {
    switch (fileState) {
      case FileStates.UPLOAD_COMPLETE:
      case FileStates.VALIDATING:
        return (
          <ButtonGroup>
            <NewButton
              type={"primary"}
              onClick={onStartValidation}
              loading={loading}
              data-cy={"start-validation-button"}
            >
              {startValidation}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onClose}
              data-cy={"cancel-button"}
            >
              {cancel}
            </NewButton>
          </ButtonGroup>
        );
      case FileStates.VALIDATION_COMPLETE:
        return (
          <ButtonGroup>
            <NewButton
              type={"primary"}
              onClick={onDeploy}
              loading={loading}
              data-cy={"update-prompts-button"}
            >
              {updatePrompts}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onClose}
              data-cy={"cancel-button"}
            >
              {cancel}
            </NewButton>
          </ButtonGroup>
        );
    }
  };

  return (
    <Modal
      title={title}
      open={true}
      onCancel={onClose}
      width={580}
      data-cy={"upload-ai-prompt-template-modal"}
    >
      <MainContainer>
        {files.length === 0 ? (
          <FileDragger
            onStartUpload={onStartUpload}
            acceptTypes={".yaml,.yml"}
          />
        ) : (
          <div>
            {files.map((file) => (
              <FileUploadStatus key={file.uid} file={file} icon={"xls"} />
            ))}
          </div>
        )}
        {!!additionalData?.numberOfPrompts ? (
          <Alert
            message={numberOfPrompts}
            type={"success"}
            variant={"smallBody"}
            showIcon
            data-cy={"number-of-prompts-alert"}
          />
        ) : null}
        {renderButtons()}
        {fileState === FileStates.VALIDATION_COMPLETE_WITH_ERRORS && (
          <ValidationErrors validation={validation} />
        )}
        {jobId && <JobMonitor jobId={jobId} onFinish={onJobFinish} />}
      </MainContainer>
    </Modal>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 24px;
  max-width: 600px;
`;

const mapStateToProps = (state) => {
  const files = selectAllFiles(state);

  return {
    files,
    fileState: selectFileState(state, files[0]?.uid),
    fileId: selectFileFileId(state, files[0]?.uid),
    validationId: selectFileValidationId(state, files[0]?.uid),
    additionalData: selectFileAdditionalData(state, files[0]?.uid),
    validation: selectRequestData(state, actionTypes.CREATE_VALIDATION_REQUEST),
    validationLoadingState: selectRequestState(
      state,
      actionTypes.CREATE_VALIDATION_REQUEST
    ),
    deploymentLoadingState: selectRequestState(
      state,
      actionTypes.DEPLOY_VALIDATION_REQUEST
    ),
    deploymentRequestData: selectRequestData(
      state,
      actionTypes.DEPLOY_VALIDATION_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearFileList,
      addFileToList,
      createValidation,
      deployValidation,
      getConversationPrompts
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadAIPromptTemplatesModal
);
