import types from "../actionTypes";
import settingsService from "../../services/settings.service";

export const getUserSettings = () => (dispatch) => {
  dispatch({
    type: types.GET_USER_SETTINGS_REQUEST
  });

  return settingsService
    .getUserSettings()
    .then((payload) => {
      dispatch({
        type: types.GET_USER_SETTINGS_SUCCESS,
        payload
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.GET_USER_SETTINGS_FAILURE,
        error
      });
    });
};

export const setUserSettings = (data) => (dispatch) => {
  dispatch({
    type: types.SET_USER_SETTINGS_REQUEST
  });

  return settingsService
    .setUserSettings(data)
    .then((payload) => {
      dispatch({
        type: types.SET_USER_SETTINGS_SUCCESS,
        payload
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.SET_USER_SETTINGS_FAILURE,
        error
      });
    });
};

const userSettings = {
  getUserSettings,
  setUserSettings
};

export default userSettings;
