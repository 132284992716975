import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Group from "../../../../components/Discovery/Group";
import {
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryMetricCodeStringByGroup
} from "../../../../store/reducers";
import ReportMetric from "./ReportMetric";
import YourSituationColumns from "./YourSituationColumns";

const ReportMetricGroup = ({ groupCode, metricCodeString }) => {
  return (
    <Group
      groupCode={groupCode}
      showGroupDescription={false}
      initiallyCollapsed={true}
    >
      <YourSituationColumns>
        {metricCodeString.split(",").map((metricCode, index) => (
          <ReportMetric key={index} metricCode={metricCode} />
        ))}
      </YourSituationColumns>
    </Group>
  );
};

const mapStateToProps = (state, props) => ({
  discoveryId: selectDiscoveryId(state),
  currency: selectDiscoveryCurrency(state),
  metricCodeString: selectDiscoveryMetricCodeStringByGroup(
    state,
    props.groupCode
  )
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReportMetricGroup
);
