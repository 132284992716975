import { bindActionCreators, compose } from "redux";
import { exportDiscovery } from "../../store/actions/discoveries";
import { connect } from "react-redux";
import { selectRequestData, selectRequestState } from "../../store/reducers";
import openDownload from "../../utils/open-download";
import { notification, Spin } from "antd";
import { useString as s } from "../../components/StringProvider";
import {
  LoadingOutlined,
  CheckCircleFilled,
  WarningOutlined
} from "@ant-design/icons";
import { useTheme } from "../ThemeProvider";
import { useMobileMediaQuery } from "../Responsive";
import useLoadingState from "../../utils/use-loading-state";
import actionTypes from "../../store/actionTypes";
import PropTypes from "prop-types";
import { useState } from "react";
import JobMonitor from "../JobMonitor";
import JobStatuses from "../../utils/job-statuses";
import { POPUP_CONTAINER } from "../FullscreenProvider";

const ExportDiscoveryMonitor = ({ loadingState, exportData }) => {
  const isMobile = useMobileMediaQuery();
  const inProgressTitle = s(
    "export_discovery.popup.inProgress.title",
    "Generating report"
  );
  const inProgressDescription = s(
    "export_discovery.popup.inProgress.description",
    "Until the report is downloaded, please don’t close this tab"
  );
  const completeTitle = s(
    "export_discovery.popup.complete.title",
    "Report is ready!"
  );
  const completeDescription = s(
    "export_discovery.popup.complete.description",
    "Download has started"
  );
  const errorTitle = s("export_discovery.popup.error.title", "Oops!");
  const errorDescription = s(
    "export_discovery.popup.error.description",
    "We can't generate this report. Please try again later."
  );
  const successColor = useTheme("palette.success");
  const errorColor = useTheme("palette.error");
  const [notificationAPI, contextHolder] = notification.useNotification({
    getContainer: () => document.getElementById(POPUP_CONTAINER)
  });

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: successColor }} spin />
  );
  const successIcon = (
    <CheckCircleFilled style={{ fontSize: 24, color: successColor }} />
  );
  const errorIcon = (
    <WarningOutlined style={{ fontSize: 24, color: errorColor }} />
  );
  const [jobIds, setJobIds] = useState([]);

  const completeDownload = (url) => {
    openDownload(url);
    notificationAPI.open({
      message: completeTitle,
      description: completeDescription,
      icon: successIcon,
      placement: "topRight",
      top: isMobile ? 120 : 98,
      closeIcon: "",
      style: { width: "400px" }
    });
  };

  const showError = () => {
    notificationAPI.open({
      message: errorTitle,
      description: errorDescription,
      icon: errorIcon,
      placement: "topRight",
      duration: 0,
      top: isMobile ? 120 : 98,
      style: { width: "400px" }
    });
  };

  useLoadingState(
    loadingState,
    () => {
      notificationAPI.open({
        message: inProgressTitle,
        description: inProgressDescription,
        icon: <Spin indicator={antIcon} />,
        placement: "topRight",
        duration: 0,
        top: isMobile ? 120 : 98,
        key: exportData._id,
        closeIcon: "",
        style: { width: "400px" }
      });

      setJobIds((ids) => [...ids, exportData._id]);
    },
    () => {
      showError();
    }
  );

  const onJobChange = (job) => {
    if (job.status === JobStatuses.FAILED) {
      showError();
      notificationAPI.destroy(job._id);
      setJobIds((ids) => ids.filter((id) => id !== job._id));
    } else if (job.status === JobStatuses.COMPLETED) {
      notificationAPI.destroy(job._id);
      completeDownload(job.results.url);
      setJobIds((ids) => ids.filter((id) => id !== job._id));
    }
  };

  return (
    <>
      {contextHolder}
      {jobIds.map((jobId) => (
        <JobMonitor key={jobId} jobId={jobId} onChange={onJobChange} />
      ))}
    </>
  );
};

ExportDiscoveryMonitor.propTypes = {
  actionType: PropTypes.oneOf(Object.keys(actionTypes))
};

const mapStateToProps = (state, props) => ({
  loadingState: selectRequestState(state, props.actionType),
  exportData: selectRequestData(state, props.actionType)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExportDiscoveryMonitor
);
