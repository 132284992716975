import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";

const INITIAL_STATE = {
  loadingStates: {},
  jobs: {}
};

const updateJobs = ({ jobs, jobId, newJob }) => {
  const newJobs = {
    ...jobs
  };

  newJobs[jobId] = newJob;
  return newJobs;
};

const updateLoadingStates = ({ loadingStates, jobId, newLoadingState }) => {
  const newLoadingStates = {
    ...loadingStates
  };

  newLoadingStates[jobId] = newLoadingState;
  return newLoadingStates;
};

const jobsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_JOB_REQUEST:
      return {
        ...state,
        loadingStates: updateLoadingStates({
          loadingStates: state.loadingStates,
          jobId: action.parameters.jobId,
          newLoadingState: LoadingState.IN_PROGRESS
        })
      };

    case types.GET_JOB_SUCCESS:
      return {
        ...state,
        loadingStates: updateLoadingStates({
          loadingStates: state.loadingStates,
          jobId: action.parameters.jobId,
          newLoadingState: LoadingState.COMPLETED
        }),
        jobs: updateJobs({
          jobs: state.jobs,
          jobId: action.parameters.jobId,
          newJob: action.payload
        })
      };

    case types.GET_JOB_FAILURE:
      return {
        ...state,
        loadingStates: updateLoadingStates({
          loadingStates: state.loadingStates,
          jobId: action.parameters.jobId,
          newLoadingState: LoadingState.FAILED
        })
      };

    default:
      return state;
  }
};

export default jobsReducer;

export const selectJob = (jobsData, jobId) => jobsData.jobs[jobId];
export const selectJobLoadingState = (jobsData, jobId) =>
  jobsData.loadingStates[jobId] || LoadingState.UNINITIALIZED;
