import KPICard from "./KPICard";
import PropType from "prop-types";
import {
  selectDiscoveryCurrency,
  selectEditKPICode
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { EditKPIModal } from "../KPIModal";
import styled from "styled-components";

const KPICardAndModals = ({ discoveryKPI, editKPICode, currency }) => {
  return (
    <Container id={`kpi-card-${discoveryKPI.kpiCode}`}>
      {editKPICode === discoveryKPI.kpiCode ? (
        <EditKPIModal kpiCode={discoveryKPI.kpiCode} />
      ) : (
        <KPICard
          discoveryKPI={discoveryKPI}
          editable={true}
          currency={currency}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

KPICardAndModals.propTypes = {
  discoveryKPI: PropType.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    editKPICode: selectEditKPICode(state),
    currency: selectDiscoveryCurrency(state)
  };
};
export default compose(connect(mapStateToProps))(KPICardAndModals);
