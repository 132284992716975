import { useState } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { getTooltipDefaultAttributes } from "../Tooltip";
import classNames from "classnames";
import Paragraph from "./Paragraph";

const TruncatedParagraph = ({
  fullText,
  rows,
  placement,
  children,
  theme,
  hasTooltip,
  ...props
}) => {
  const [truncatedInternal, setTruncated] = useState(false);

  const onEllipsisInternal = () => {
    setTruncated(hasTooltip && true);
  };

  return (
    <Container className={classNames({ hasTooltip: truncatedInternal })}>
      <Paragraph
        ellipsis={{
          rows,
          expandable: true,
          symbol: <></>,
          tooltip: hasTooltip
            ? {
                title: fullText,
                placement,
                ...getTooltipDefaultAttributes({ theme })
              }
            : undefined,
          onEllipsis: onEllipsisInternal
        }}
        {...props}
      >
        {children}
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;

  &.hasToolip {
    cursor: help;
  }

  & .ant-typography.ant-typography-single-line {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

TruncatedParagraph.propTypes = {
  fullText: PropTypes.any,
  placement: PropTypes.string,
  hasTooltip: PropTypes.bool,
  rows: PropTypes.number,
  variant: PropTypes.oneOf([
    "body",
    "label",
    "small",
    "micro",
    "bMedium",
    "bodyMobile",
    "bMediumMobile",
    "statisticsMobile"
  ])
};

TruncatedParagraph.defaultProps = {
  placement: "top",
  rows: 2,
  variant: "body",
  hasTooltip: true
};

export default withTheme(TruncatedParagraph);
