import styled from "styled-components";
import PropTypes from "prop-types";

const DiscoveryContentWrapper = styled.div`
  max-width: ${({ format }) => (format === "narrow" ? "830px" : "100%")};
  padding: ${({ isMobile, kpiLibraryOpen, kpiLibraryVisible }) => {
    if (isMobile && kpiLibraryVisible) {
      return "0px 52px 0px 20px";
    } else if (isMobile && !kpiLibraryVisible) {
      return "0px 20px 0px 20px";
    } else if (!isMobile && kpiLibraryOpen) {
      return "0px 32px 0px 32px";
    } else if (!isMobile && !kpiLibraryOpen) {
      return "0px 32px 0px 32px";
    }
  }};
  margin: ${({ format }) => {
    if (format === "narrow") {
      return "32px auto 0px auto";
    } else {
      return "32px 0px 0px 0px";
    }
  }};
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: flex-start;

  h2,
  h3 {
    margin-bottom: 8px;
  }
`;

DiscoveryContentWrapper.propTypes = {
  format: PropTypes.oneOf(["narrow", "wide"]),
  isMobile: PropTypes.bool.isRequired,
  kpiLibraryOpen: PropTypes.bool,
  kpiLibraryVisible: PropTypes.bool
};

DiscoveryContentWrapper.defaultProps = {
  format: "narrow",
  isMobile: false,
  kpiLibraryOpen: false,
  kpiLibraryVisible: false
};

export default DiscoveryContentWrapper;
