import styled from "styled-components";
import { themeProp } from "../theme";

const ScrollZone = styled.div`
  overflow-y: auto;
  max-height: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${themeProp("palette.gray4")};
  }
`;

export default ScrollZone;
