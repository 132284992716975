import FixedPercentKPIData from "./FixedPercentKPIData";
import MetricDrivenKPIData from "./MetricDrivenKPIData";
import RangeDrivenKPIData from "./RangeDrivenKPIData";
import React from "react";
import PropType from "prop-types";
import KPITypes from "../../../utils/kpi-types";
import UnquantifiedKPIData from "./UnquantifiedKPIData";
import { Space } from "antd";
import { useString as s } from "../../StringProvider";
import RateImportance from "../../RateImportance";

const KPIData = ({
  discoveryKPI,
  currency,
  isEditable,
  disabled,
  onChange,
  onChangeImportance
}) => {
  const importanceForYou = s(
    "discovery.kpi.card.importanceForYou",
    "Importance for you"
  );

  const renderData = () => {
    switch (discoveryKPI.definition.type) {
      case KPITypes.UNQUANTIFIED:
        return <UnquantifiedKPIData kpi={discoveryKPI} disabled={disabled} />;
      case KPITypes.FIXED_PERCENT:
        return <FixedPercentKPIData kpi={discoveryKPI} disabled={disabled} />;
      case KPITypes.METRIC_DRIVEN:
        return (
          <MetricDrivenKPIData
            kpi={discoveryKPI}
            currency={currency}
            disabled={disabled}
          />
        );
      default:
        return (
          <RangeDrivenKPIData
            kpi={discoveryKPI}
            currency={currency}
            isEditable={isEditable}
            onChange={onChange}
            disabled={disabled}
          />
        );
    }
  };

  return (
    <Space
      size={16}
      direction={"vertical"}
      style={{ width: "100%" }}
      className={"up-kpi-data"}
    >
      <RateImportance
        editable={isEditable}
        label={importanceForYou}
        importance={discoveryKPI.importance}
        onChangeImportance={onChangeImportance}
      />
      {renderData()}
    </Space>
  );
};

KPIData.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  isEditable: PropType.bool,
  disabled: PropType.bool,
  onChange: PropType.func,
  onChangeImportance: PropType.func
};

export default KPIData;
