import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 46px;
  max-height: 46px;

  ${({ alignment }) =>
    alignment === "left"
      ? css`
          justify-content: flex-start;
        `
      : css`
          justify-content: flex-end;
        `}
`;

ButtonBar.propTypes = {
  alignment: PropTypes.oneOf(["left", "right"])
};

ButtonBar.defaultProps = {
  alignment: "right"
};

export default ButtonBar;
