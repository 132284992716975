import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { Forbidden, IFrameNotSupported, NotFound } from "../Pages/ErrorPage";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  hasPermission,
  isAuthenticated,
  selectIsCuvamaUser
} from "../store/reducers";
import isInIFrame from "../utils/is-in-iframe";
import { useFeature } from "../components/FeaturesProvider";

function CuvamaRoute({
  component: Component,
  supportsIFrame,
  requiresAuthentication,
  requiresFeature,
  requiresCuvamaUser,
  isCuvamaUser,
  hasPermission,
  path,
  isAuthenticated,
  ...rest
}) {
  const hasFeature = requiresFeature && useFeature(requiresFeature);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!supportsIFrame && isInIFrame()) {
          return <IFrameNotSupported />;
        }

        if (
          (!requiresFeature || hasFeature) &&
          (!requiresAuthentication || (isAuthenticated && hasPermission)) &&
          (!requiresCuvamaUser || isCuvamaUser)
        ) {
          return <Component {...props} />;
        } else if (requiresFeature && !hasFeature) {
          return <NotFound />;
        } else if (requiresCuvamaUser && !isCuvamaUser) {
          return <NotFound />;
        } else if (isAuthenticated && !hasPermission) {
          return <Forbidden />;
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
                error: "You must login to see this page."
              }
            }}
          />
        );
      }}
    />
  );
}

CuvamaRoute.propTypes = {
  component: PropTypes.any.isRequired,
  requiresAuthentication: PropTypes.bool,
  requiresPermission: PropTypes.string,
  requiresFeature: PropTypes.string,
  requiresCuvamaUser: PropTypes.bool,
  supportsIFrame: PropTypes.bool
};

const mapStateToProps = (state, props) => ({
  isAuthenticated: isAuthenticated(state),
  isCuvamaUser: selectIsCuvamaUser(state),
  hasPermission:
    !props.requiresPermission || hasPermission(state, props.requiresPermission)
});

export default compose(connect(mapStateToProps))(CuvamaRoute);
