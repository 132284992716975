import React from "react";
import { useString as s } from "../StringProvider";
import { useWindowDimensions } from "../Responsive";
import { Drawer } from "antd";
import PropTypes from "prop-types";
import NotesPanel from "./NotesPanel";
import styled from "styled-components";

const DiscoveryNotesDrawer = ({ visible, onClose }) => {
  const { width } = useWindowDimensions();
  const notesTitle = s("notes.title", "Notes");

  return (
    <StyledDrawer
      title={notesTitle}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={width}
    >
      <NotesPanel closeDrawer={onClose} />
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
  }
`;

DiscoveryNotesDrawer.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DiscoveryNotesDrawer;
