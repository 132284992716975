import styled from "styled-components";
import { Typography } from "antd";
import { themeProp } from "../../theme";

const { Title: ANTTitle } = Typography;

const Title = styled(ANTTitle)`
  font-size: ${({ level }) =>
    themeProp(`typography.h${level}.fontSize`)} !important;
  font-family: ${({ level }) =>
    themeProp(`typography.h${level}.fontFamily`)} !important;
  font-weight: ${({ level }) =>
    themeProp(`typography.h${level}.fontWeight`)} !important;
  line-height: ${({ level }) =>
    themeProp(`typography.h${level}.lineHeight`)} !important;
  ${(props) =>
    props.color
      ? "color: " + themeProp(`palette.${props.color}`)(props) + " !important;"
      : ""}
  ${({ level }) => (level === 5 ? `text-transform: uppercase !important;` : "")}
`;

export default Title;
