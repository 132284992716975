class NumberParser {
  constructor(locale, currency) {
    const parts = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      notation: "standard",
      currencyDisplay: "narrowSymbol"
    }).formatToParts(12345.6);
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210
      )
    ].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(
      `[${parts.find((d) => d.type === "group").value}]`,
      "g"
    );
    this._decimal = new RegExp(
      `[${parts.find((d) => d.type === "decimal").value}]`
    );
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d) => index.get(d);
    this._currency = new RegExp(
      `[${parts.find((d) => d.type === "currency").value}]`,
      "g"
    );
  }
  parse(string) {
    return (string = string
      .trim()
      .replace(this._group, "")
      .replace(this._currency, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index)).trim()
      ? +string
      : NaN;
  }
}

export default NumberParser;
