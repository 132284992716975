import React from "react";
import PropType from "prop-types";
import { useString as s } from "../../../../components/StringProvider";
import Heading from "../../../../components/Heading";
import styled from "styled-components";
import ImpactValue from "./ImpactValue";

const NewKPIImpactValue = ({ discoveryKPI, currency, reportId }) => {
  const notQuantifiedText = s(
    "discovery.kpi.card.impact.notQuantified",
    "Not quantified"
  );

  if (!discoveryKPI.hasImpactValue && !discoveryKPI.hasImpactPercentage) {
    return null;
  }

  return (
    <Container color={"text"}>
      {discoveryKPI.hasImpactValue ? (
        <ImpactValue
          currency={currency}
          lowImpactValue={discoveryKPI.lowImpactValue}
          potentialImpactValue={discoveryKPI.potentialImpactValue}
          reportId={reportId}
          discoveryKPI={discoveryKPI}
        />
      ) : (
        <Heading level={"h4"} color={"text"}>
          {discoveryKPI.definition.noFormulaReason || notQuantifiedText}
        </Heading>
      )}
    </Container>
  );
};

const Container = styled.div`
  text-align: right;

  & h4 {
    margin-bottom: 0;
    text-align: right;
  }
`;

NewKPIImpactValue.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  reportId: PropType.string
};

export default NewKPIImpactValue;
