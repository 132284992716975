import styled from "styled-components";
import SolutionStatus from "../../../utils/solution-status";
import { themeProp } from "../../../theme";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectSolution } from "../../../store/reducers";
import { useDrag } from "react-dnd";
import ItemTypes from "../../ItemTypes";
import PropType from "prop-types";
import { useString as s } from "../../StringProvider";
import Tooltip from "../../Tooltip";

const SolutionBadge = ({ editable, solution, solutionData, onClick }) => {
  const tooltipText1 = s("solutions.badge.tooltip1", "Hold to drag");
  const tooltipText2 = s("solutions.badge.tooltip2", "Drag to move");

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type:
        solution.status === SolutionStatus.OUT_OF_SCOPE
          ? ItemTypes.OUT_OF_SCOPE_SOLUTION
          : ItemTypes.SOLUTION,
      item: solutionData,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    [solution]
  );

  return editable ? (
    <Tooltip title={isDragging ? tooltipText2 : tooltipText1}>
      <Container
        status={solution.status}
        ref={editable ? dragRef : undefined}
        isDragging={isDragging}
        editable={editable}
      >
        {solution.status === SolutionStatus.LINKED ||
        solution.status === SolutionStatus.NOT_LINKED ? (
          <SolutionLink onClick={() => onClick(solutionData.code)}>
            {solutionData.name}
          </SolutionLink>
        ) : (
          <span>{solutionData.name}</span>
        )}
      </Container>
    </Tooltip>
  ) : (
    <Container status={solution.status}>
      {solution.status === SolutionStatus.LINKED ||
      solution.status === SolutionStatus.NOT_LINKED ? (
        <SolutionLink onClick={() => onClick(solutionData.code)}>
          {solutionData.name}
        </SolutionLink>
      ) : (
        <span>{solutionData.name}</span>
      )}
    </Container>
  );
};

SolutionBadge.propTypes = {
  editable: PropType.bool.isRequired,
  solution: PropType.object.isRequired,
  onClick: PropType.func.isRequired
};

const SolutionLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const Container = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-top: 8px;
  background-color: ${({ status, isDragging }) =>
    status === SolutionStatus.LINKED
      ? themeProp("palette.surface")
      : isDragging
      ? themeProp("palette.gray4")
      : themeProp("palette.gray3")};
  height: 32px;
  border-radius: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 7px;
  cursor: ${({ isDragging, editable }) =>
    isDragging ? "grabbing" : editable ? "grab" : "inherit"};
  transform: translate(0, 0);

  & span {
    font-size: ${themeProp(`typography.small.fontSize`)};
    font-family: ${themeProp(`typography.small.fontFamily`)};
    font-weight: ${themeProp(`typography.bMedium.fontWeight`)};
    line-height: ${themeProp(`typography.small.lineHeight`)};
    color: ${themeProp("palette.black")};
  }
`;

function mapStateToProps(state, props) {
  return {
    solutionData: selectSolution(state, props.solution.code)
  };
}

export default compose(connect(mapStateToProps))(SolutionBadge);
