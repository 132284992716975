import styled from "styled-components";
import { selectDiscoverySelectedChallenges } from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { ChallengeCard } from "../../components/Discovery";
import PropType from "prop-types";
import Masonry from "../../components/Masonry";
import { useMobileMediaQuery } from "../../components/Responsive";
import { sortDiscoveryChallengesByImportance } from "../../utils/sorting";

const ChallengeSummaryCards = ({
  selectedChallenges,
  reportId,
  showAllChallenges,
  printMode
}) => {
  const isMobile = useMobileMediaQuery();
  const sortedDiscoveryChallenges = [...selectedChallenges];
  sortedDiscoveryChallenges.sort(sortDiscoveryChallengesByImportance);

  return isMobile ? (
    (showAllChallenges
      ? sortedDiscoveryChallenges
      : sortedDiscoveryChallenges.slice(0, 4)
    ).map((challenge) => (
      <MarginContainer key={challenge.challengeCode} className={"mobile"}>
        <ChallengeCard
          challengeCode={challenge.challengeCode}
          reportId={reportId}
          editable={false}
        />
      </MarginContainer>
    ))
  ) : (
    <Masonry
      breakpointCols={{
        default: 2,
        1300: 1
      }}
    >
      {sortedDiscoveryChallenges.map((challenge, index) => (
        <MarginContainer key={index}>
          <ChallengeCard
            challengeCode={challenge.challengeCode}
            reportId={reportId}
            editable={false}
            printMode={printMode}
          />
        </MarginContainer>
      ))}
    </Masonry>
  );
};

const MarginContainer = styled.div`
  margin-bottom: 16px;

  &.mobile {
    margin-bottom: 16px;
  }
  &.mobile:last-child {
    margin-bottom: 0;
  }
`;

ChallengeSummaryCards.propType = {
  reportId: PropType.string,
  showAllChallenges: PropType.bool,
  printMode: PropType.bool
};

ChallengeSummaryCards.defaultProps = {
  showAllChallenges: true
};

const mapStateToProps = (state) => ({
  selectedChallenges: selectDiscoverySelectedChallenges(state)
});

export default compose(connect(mapStateToProps))(ChallengeSummaryCards);
