import PropType from "prop-types";
import Markdown from "../Discovery/Markdown";
import getNotificationText, {
  NotificationCategory
} from "./get-notification-text";
import { IntlMessageFormat } from "intl-messageformat";
import { getNotificationContext } from "./get-notification-context";
import { stopDiscoveryOperation } from "../../store/actions/discovery-operations";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

const EngagementNotificationCard = ({
  notification,
  stopDiscoveryOperation
}) => {
  const message = new IntlMessageFormat(
    getNotificationText({
      type: notification.type,
      category: NotificationCategory.ENGAGEMENT_NOTIFICATIONS
    }),
    "en-US"
  ).format(getNotificationContext(notification));

  return (
    <Markdown onClick={stopDiscoveryOperation} className={"notification"}>
      {message}
    </Markdown>
  );
};

EngagementNotificationCard.propTypes = {
  notification: PropType.object.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      stopDiscoveryOperation
    },
    dispatch
  );

export default compose(connect(null, mapDispatchToProps))(
  EngagementNotificationCard
);
