import PropTypes from "prop-types";
import { useEffect } from "react";
import {
  isAuthenticated,
  selectDiscoveryId,
  selectDiscoveryScenario,
  selectDiscoveryShowAdditionalBenefits,
  selectDiscoveryShowOutcomesByCategory
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import {
  setDiscoveryShowAdditionalBenefits,
  updateDiscoverySettings
} from "../../../store/actions/discoveries";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useString as s } from "../../../components/StringProvider";
import actionTypes from "../../../store/actionTypes";
import ChartToggle from "./ChartToggle";

const ShowAdditionalBenefits = ({
  isAuthenticated,
  discoveryId,
  reportMode,
  printMode,
  updateDiscoverySettings,
  setDiscoveryShowAdditionalBenefits,
  showAdditionalBenefits,
  showOutcomesByCategory
}) => {
  const showAdditionalBenefitsText = s(
    "discovery.showAdditionalBenefits.text",
    "Show additional benefits"
  );
  const toolTipText = s(
    "discovery.showAdditionalBenefits.toolTip",
    "Display additional benefits that are quantified, but not financial."
  );

  const onChange = (value) => {
    if (reportMode && !isAuthenticated) {
      setDiscoveryShowAdditionalBenefits({ showAdditionalBenefits: value });
    } else {
      updateDiscoverySettings({
        discoveryId,
        changes: {
          showAdditionalBenefits: value
        }
      });
    }
  };

  useEffect(() => {
    if (showOutcomesByCategory) {
      onChange(false);
    }
  }, [showOutcomesByCategory]);

  if (printMode) {
    return null;
  }

  return (
    <ChartToggle
      dataCy={"cosq-additional-benefits-toggle"}
      userpilotTag={"up-cosq-additionalbenefitstoggle"}
      checked={showAdditionalBenefits}
      onChange={onChange}
      toggleText={showAdditionalBenefitsText}
      toggleTooltip={toolTipText}
      disabled={showOutcomesByCategory}
    />
  );
};

ShowAdditionalBenefits.propTypes = {
  reportMode: PropTypes.bool.isRequired,
  printMode: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => ({
  isAuthenticated: isAuthenticated(state),
  discoveryId: selectDiscoveryId(state),
  scenario: selectDiscoveryScenario(state),
  showAdditionalBenefits: selectDiscoveryShowAdditionalBenefits({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_REQUEST
  }),
  showOutcomesByCategory: selectDiscoveryShowOutcomesByCategory({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_SHOW_OUTCOMES_BY_CATEGORY_REQUEST
  })
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettings,
      setDiscoveryShowAdditionalBenefits
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ShowAdditionalBenefits);
