const Features = {
  CRM_BCC_ENABLED: "crmBccEnabled",
  DISCOVERY_TYPES_ENABLED: "discoveryTypesEnabled",
  DISCOVERY_VERSION: "discoveryVersion",
  ROCKET_SHIP: "rocketShip",
  EXTERNAL_DISCOVERY_MANAGEMENT: "externalDiscoveryManagement",
  CRM_ACCOUNTS_INTEGRATION: "crmAccountsIntegration",
  AUTO_CONFIGURATION: "autoConfiguration",
  SALESFORCE_INTEGRATION: "salesforceIntegration",
  SALESFORCE_INTEGRATION_V2: "salesforceIntegrationV2",
  SALESFORCE_LEAD_CREATION: "salesforceLeadCreation",
  SALESFORCE_USERS_SYNC: "salesforceUsersSync",
  SALESFORCE_OPPORTUNITIES_SYNC: "salesforceOpportunitiesSync",
  SALESFORCE_MULTI_CURRENCY: "salesforceMultiCurrency",
  SALESFORCE_SANDBOX: "salesforceSandbox",
  HUBSPOT_INTEGRATION: "hubspotIntegration"
};

export default Features;
