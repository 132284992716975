import { Modal as ANTModal } from "antd";
import styled from "styled-components";
import { themeProp } from "../theme";
import PropTypes from "prop-types";
import { POPUP_CONTAINER } from "./FullscreenProvider";

const Modal = styled(ANTModal).attrs(() => ({
  maskClosable: false,
  footer: null,
  getContainer: () => document.getElementById(POPUP_CONTAINER)
}))`
  & .ant-modal-title {
    font-size: ${({ titleSize }) =>
      themeProp(`typography.${titleSize}.fontSize`)};
    font-family: ${({ titleSize }) =>
      themeProp(`typography.${titleSize}.fontFamily`)};
    font-weight: ${({ titleSize }) =>
      themeProp(`typography.${titleSize}.fontWeight`)};
    line-height: ${({ titleSize }) =>
      themeProp(`typography.${titleSize}.lineHeight`)};
  }

  & .ant-modal-close .ant-modal-close-x .anticon-close {
    font-size: 24px;
    color: ${themeProp("palette.text")};
  }

  & .ant-modal-close {
    display: ${(props) => (props.showCloseIcon ? "flex" : "none")};
  }

  & .ant-modal-title {
    width: 90%;
  }

  & .ant-modal-content {
    padding: 24px;
  }
`;

Modal.propTypes = {
  titleSize: PropTypes.oneOf(["h3", "h4"]),
  showCloseIcon: PropTypes.bool
};

Modal.defaultProps = {
  titleSize: "h3",
  showCloseIcon: true
};

export default Modal;
