import { Forbidden, NotFound } from "../ErrorPage";
import SelectChallenges from "../SelectChallenges/SelectChallenges";
import StartDiscovery from "../StartDiscovery/StartDiscovery";
import ChallengeSummary from "../ChallengeSummary/ChallengeSummary";
import SelectKPIs from "../SelectKPIs/SelectKPIs";
import KPISummary from "../KPISummary/KPISummary";
import BusinessMetrics from "../BusinessMetrics/BusinessMetrics";
import ValueHypothesis from "../ValueHypothesis/ValueHypothesis";
import ROI from "../ROI/ROI";
import Question from "../Question";
import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import PropType from "prop-types";
import ShareDiscovery from "../ShareDiscovery";
import {
  canUserAccessDiscoveryPath,
  getLastValidCheckpoint
} from "./components/OnlyIfUserCanAccessDiscoveryPath";

const DiscoveryRoute = ({
  component: Component,
  path,
  maximumPhase,
  permissions,
  validPathname,
  ...props
}) => {
  const search = props.search;

  return (
    <Route
      {...props}
      render={(props) => {
        if (canUserAccessDiscoveryPath({ path, maximumPhase, permissions })) {
          const allProps = { ...props, search };
          return <Component {...allProps} />;
        } else if (validPathname) {
          return <Redirect from={""} to={validPathname} exact />;
        }

        return <Forbidden />;
      }}
    />
  );
};

DiscoveryRoute.propTypes = {
  component: PropType.any.isRequired,
  path: PropType.string.isRequired,
  maximumPhase: PropType.string,
  validPathname: PropType.string,
  permissions: PropType.array.isRequired
};

const DiscoveryPageContents = ({
  discoveryId,
  lastViewedPage,
  maximumPhase,
  permissions
}) => {
  const validCheckpoint = getLastValidCheckpoint({
    checkpoint: lastViewedPage,
    maximumPhase,
    permissions
  });

  const validPathname = validCheckpoint
    ? `/discoveries/${discoveryId}${validCheckpoint}`
    : undefined;

  return (
    <Switch>
      <DiscoveryRoute
        path="/discoveries/:discoveryId/start-discovery"
        exact
        component={StartDiscovery}
        maximumPhase={maximumPhase}
        permissions={permissions}
        validPathname={validPathname}
      />
      <Route
        path="/discoveries/:discoveryId/questions/:questionNumber"
        exact
        component={Question}
      />
      <DiscoveryRoute
        path="/discoveries/:discoveryId/select-challenges"
        exact
        component={SelectChallenges}
        maximumPhase={maximumPhase}
        permissions={permissions}
        validPathname={validPathname}
      />
      <DiscoveryRoute
        path="/discoveries/:discoveryId/challenge-summary"
        exact
        component={ChallengeSummary}
        maximumPhase={maximumPhase}
        permissions={permissions}
        validPathname={validPathname}
      />
      <DiscoveryRoute
        path="/discoveries/:discoveryId/select-kpis"
        exact
        component={SelectKPIs}
        maximumPhase={maximumPhase}
        permissions={permissions}
        validPathname={validPathname}
      />
      <DiscoveryRoute
        path="/discoveries/:discoveryId/kpi-summary"
        exact
        component={KPISummary}
        maximumPhase={maximumPhase}
        permissions={permissions}
        validPathname={validPathname}
      />
      <DiscoveryRoute
        path="/discoveries/:discoveryId/business-metrics"
        exact
        component={BusinessMetrics}
        maximumPhase={maximumPhase}
        permissions={permissions}
        validPathname={validPathname}
      />
      <DiscoveryRoute
        path="/discoveries/:discoveryId/value-hypothesis"
        exact
        component={ValueHypothesis}
        maximumPhase={maximumPhase}
        permissions={permissions}
        discoveryId={discoveryId}
        validPathname={validPathname}
      />
      <DiscoveryRoute
        path="/discoveries/:discoveryId/roi"
        exact
        component={ROI}
        maximumPhase={maximumPhase}
        permissions={permissions}
        discoveryId={discoveryId}
        validPathname={validPathname}
        search={validCheckpoint?.split("?")?.[1]}
      />
      <Route
        path="/discoveries/:discoveryId/share"
        exact
        component={ShareDiscovery}
      />
      {validPathname ? <Redirect from={""} to={validPathname} exact /> : null}
      <Route component={NotFound} />
    </Switch>
  );
};

DiscoveryPageContents.propTypes = {
  discoveryId: PropType.string.isRequired,
  checkpoint: PropType.string.isRequired,
  maximumPhase: PropType.string,
  permissions: PropType.array.isRequired
};

export default DiscoveryPageContents;
