import types from "../actionTypes";
import versionsService from "../../services/versions.service";

export const getLatestVersion = () => (dispatch) => {
  dispatch({
    type: types.GET_LATEST_VERSION_REQUEST
  });

  return versionsService
    .getLatestVersion()
    .then((payload) => {
      dispatch({
        type: types.GET_LATEST_VERSION_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_LATEST_VERSION_FAILURE,
        error
      });
    });
};
