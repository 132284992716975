import httpService from "./http.service";
import { createTheme } from "../theme";

const getTheme = () =>
  httpService
    .get(`/themes`)
    .then(({ data }) => createTheme(data))
    .catch((err) => Promise.reject(err.response));

const methods = {
  getTheme
};
export default methods;
