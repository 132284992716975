import { selectRequestData } from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { generateAutoconfiguration } from "../../../../store/actions/autoconfigurations";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AutoconfigurationLayout from "./AutoconfigurationLayout";
import { useEffect, useState } from "react";
import Table from "../../../../components/Table";
import { useString as s } from "../../../../components/StringProvider";

const AutoconfigurationQuestions = ({
  autoconfiguration,
  generateAutoconfiguration
}) => {
  const [ready, setReady] = useState(false);
  const [data, setData] = useState([]);
  const columns = [];

  useEffect(() => {
    if (autoconfiguration) {
      setData(
        (autoconfiguration?.questions || []).map((o) => ({
          ...o,
          key: o.code
        }))
      );
    }
  }, [autoconfiguration]);

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.questions.code",
      "Code"
    ),
    dataIndex: "code",
    key: "code"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.questions.questionOrder",
      "Order"
    ),
    dataIndex: "questionOrder",
    key: "questionOrder"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.questions.question",
      "Question"
    ),
    dataIndex: "question",
    key: "question"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.questions.questionType",
      "Type"
    ),
    dataIndex: "questionType",
    key: "questionType"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.questions.questionVisualization",
      "Visualization"
    ),
    dataIndex: "questionVisualization",
    key: "questionVisualization"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.questions.options",
      "Options"
    ),
    dataIndex: "options",
    key: "options",
    render: (values) => (
      <ul>
        {values.map((o) => (
          <li key={o.code}>{o.label}</li>
        ))}
      </ul>
    )
  });

  useEffect(() => {
    if (!autoconfiguration?.questions?.length) {
      generateAutoconfiguration({
        autoconfigurationId: autoconfiguration._id,
        section: "questions"
      });
    } else if (autoconfiguration) {
      setReady(true);
    }
  }, [autoconfiguration]);

  return (
    <AutoconfigurationLayout>
      {ready && (
        <Table dataSource={data} columns={columns} pagination={false} />
      )}
    </AutoconfigurationLayout>
  );
};

const mapStateToProps = (state) => ({
  autoconfiguration: selectRequestData(
    state,
    actionTypes.GET_AUTOCONFIGURATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generateAutoconfiguration
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AutoconfigurationQuestions);
