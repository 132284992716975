import { themeProp } from "../../theme";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Space } from "antd";
import React from "react";
import { DesktopOrTablet, Mobile, useMobileMediaQuery } from "../Responsive";

export const CardIconBar = ({ children }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <IconBarContainer isMobile={isMobile} className={"card-icon-bar"}>
      {children}
    </IconBarContainer>
  );
};

const IconBarContainer = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  justify-content: flex-end;
  visibility: ${({ isMobile }) => (isMobile ? "visible" : "hidden")};

  & .open-comments-button-open,
  & .open-comments-button-has-comments,
  & .ant-dropdown-open {
    visibility: visible;
  }
`;

const SelectableCard = React.forwardRef(
  ({ editable, selected, order, toggle, toolbar, children, ...props }, ref) => {
    const isMobile = useMobileMediaQuery();

    return (
      <Container
        order={order}
        selected={selected}
        editable={editable}
        className={"selectable-card" + (isMobile ? " mobile" : "")}
        ref={ref}
        {...props}
      >
        <Mobile>
          <Space direction={"vertical"} size={12} style={{ width: "100%" }}>
            {editable ? (
              <ToggleBar>
                {editable ? toggle : null}
                {toolbar}
              </ToggleBar>
            ) : null}

            <ChildContainer>{children}</ChildContainer>
          </Space>
        </Mobile>
        <DesktopOrTablet>
          {toggle && editable ? (
            <ToggleContainer>{toggle}</ToggleContainer>
          ) : null}
          <ChildContainer>{children}</ChildContainer>
        </DesktopOrTablet>
      </Container>
    );
  }
);

export const SelectableCardRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  & .selectable-card-title {
    flex-grow: 1;
  }
`;

export const SelectableCardLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 20px;
  flex-grow: 1;
`;

export const SelectableCardRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "250px")};
  min-width: ${({ width }) => (width ? width : "250px")};
  align-items: flex-end;
  justify-content: flex-start;
`;

const ToggleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ToggleContainer = styled.div`
  margin-right: 16px;
`;

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  display: inline-flex;
  padding: 21px 24px 16px 24px;
  background: ${themeProp("palette.surface")};
  border: 2px solid
    ${(props) =>
      props.selected && props.editable
        ? themeProp("palette.secondary")(props)
        : "transparent"};
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  border-radius: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
  ${(props) => (props.order ? `order: ${props.order};` : null)};
  width: 100%;
  height: 100%;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;

  &:hover .card-icon-bar {
    visibility: visible;
  }

  &.mobile {
    border-radius: 8px;
    padding: 13px 16px 8px 16px;
    margin-bottom: 0;
  }

  & .ant-typography {
    margin-bottom: 0;
  }
`;

SelectableCard.propTypes = {
  selected: PropTypes.bool,
  editable: PropTypes.bool,
  toggle: PropTypes.object,
  toolbar: PropTypes.object,
  order: PropTypes.number
};

export default SelectableCard;
