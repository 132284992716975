import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { Forbidden, IFrameNotSupported } from "../Pages/ErrorPage";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectAuthConfiguration } from "../store/selectors/config";
import { selectRequestData } from "../store/reducers";
import actionType from "../store/actionTypes";
import isInIFrame from "../utils/is-in-iframe";

function ProtectedRoute({
  component: Component,
  isAuthenticated,
  hasPermission,
  path,
  authConfiguration,
  identityProviderRecord,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isInIFrame()) {
          return <IFrameNotSupported />;
        }

        if (isAuthenticated && hasPermission) {
          return <Component {...props} />;
        } else if (isAuthenticated && !hasPermission) {
          return <Forbidden />;
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
                error: "You must login to see this page."
              }
            }}
          />
        );
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  hasPermission: PropTypes.bool
};

ProtectedRoute.defaultProps = {
  hasPermission: true
};

const mapStateToProps = (state) => ({
  authConfiguration: selectAuthConfiguration(state),
  identityProviderRecord: selectRequestData(
    state,
    actionType.IDENTITY_PROVIDER_SUCCESS
  )
});

export default compose(connect(mapStateToProps))(ProtectedRoute);
