import {
  selectDiscoveryId,
  selectRequestParameters,
  selectRequestState
} from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import {
  addDiscoveryKPIFromLibrary,
  restoreDiscoveryKPI
} from "../../store/actions/discoveries";
import { connect } from "react-redux";
import useLoadingState from "../../utils/use-loading-state";
import { useString as s } from "../../components/StringProvider";
import CanvasNotificationPanel from "./CanvasNotificationPanel";
import {
  closeNotificationMessage,
  openNotificationMessage
} from "../../store/actions/notification-message";

const DeleteOrRemoveOutcomeNotification = ({
  discoveryId,
  removeLoadingState,
  deleteLoadingState,
  removeParameters,
  deleteParameters,
  addDiscoveryKPIFromLibrary,
  restoreDiscoveryKPI,
  restoreLoadingState,
  restoreParameters,
  addLoadingState,
  addParameters,
  openNotificationMessage,
  closeNotificationMessage
}) => {
  const removedSucceeded = s(
    "discovery.kpi.card.removeFromList.succeeded",
    "Outcome removed from the discovery!"
  );
  const deletedSucceeded = s(
    "discovery.kpi.card.delete.succeeded",
    "Outcome deleted from the discovery!"
  );
  const notificationPlacement = "bottomLeft";

  useLoadingState(
    removeLoadingState,
    () => {
      const kpiCode = removeParameters.kpiCode;

      openNotificationMessage({
        key: kpiCode,
        description: (
          <CanvasNotificationPanel
            description={removedSucceeded}
            onClick={() => {
              addDiscoveryKPIFromLibrary({
                discoveryId,
                kpiCode: kpiCode
              });
            }}
            close={() => closeNotificationMessage({ key: kpiCode })}
          />
        ),
        closeIcon: null,
        placement: notificationPlacement
      });
    },
    () => {}
  );

  useLoadingState(
    deleteLoadingState,
    () => {
      const kpiCode = deleteParameters.kpiCode;

      openNotificationMessage({
        key: kpiCode,
        description: (
          <CanvasNotificationPanel
            description={deletedSucceeded}
            onClick={() => {
              restoreDiscoveryKPI({
                discoveryId,
                kpiCode: kpiCode
              });
            }}
            close={() => closeNotificationMessage({ key: kpiCode })}
          />
        ),
        closeIcon: null,
        placement: notificationPlacement
      });
    },
    () => {}
  );

  useLoadingState(restoreLoadingState, () => {
    closeNotificationMessage({ key: restoreParameters.kpiCode });
  });

  useLoadingState(addLoadingState, () => {
    closeNotificationMessage({ key: addParameters.kpiCode });
  });

  return "";
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    deleteLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_DELETE_KPI_REQUEST
    ),
    deleteParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_DELETE_KPI_REQUEST
    ),
    removeLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_REQUEST
    ),
    removeParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_REQUEST
    ),
    restoreLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_RESTORE_KPI_REQUEST
    ),
    restoreParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_RESTORE_KPI_REQUEST
    ),
    addLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST
    ),
    addParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDiscoveryKPIFromLibrary,
      restoreDiscoveryKPI,
      openNotificationMessage,
      closeNotificationMessage
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeleteOrRemoveOutcomeNotification
);
