import Form, { FormItem } from "../../../components/Form";
import { useString as s } from "../../../components/StringProvider";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Input, { TextArea } from "../../../components/Input";
import NewButton from "../../../components/NewButton";
import { compose } from "redux";
import { useMobileMediaQuery } from "../../../components/Responsive";
import getStringsFormConfig from "./strings-form-config";
import { Space } from "antd";
import styled from "styled-components";
import ButtonGroup from "../../../components/ButtonGroup";
import Text from "../../../components/Text";
import { GroupCollapse } from "../../../components";

const UpdateBrandingStringsForm = ({
  form,
  initialValues,
  onFinish,
  submitButtonText
}) => {
  const isMobile = useMobileMediaQuery();
  const stringsFormConfigGroups = getStringsFormConfig(s);
  const initialGroupCollapsedState = stringsFormConfigGroups.reduce(
    (map, group, idx) => {
      map[group.groupId] = idx !== 0;
      return map;
    },
    {}
  );

  const [groupCollapsedState, setGroupCollapsedState] = useState(
    initialGroupCollapsedState
  );

  const maxWidth = "673px";

  const onToggleCollapsed = (groupId) => {
    setGroupCollapsedState({
      ...groupCollapsedState,
      [groupId]: !groupCollapsedState[groupId]
    });
  };

  const label = ({ value }) => {
    return (
      <Label>
        <Text variant={"bMedium"} color={"gray4"}>
          {value.label}
        </Text>
        <Text variant={"small"} color={"gray4"}>
          {value.name}
        </Text>
      </Label>
    );
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={onFinish}
      name={"updateBrandingStringsForm"}
    >
      {stringsFormConfigGroups.map((group) => {
        return (
          <GroupContainer
            isMobile={isMobile}
            inputWidth={maxWidth}
            key={group.groupId}
          >
            <GroupCollapse
              title={group.groupName}
              onCollapse={() => onToggleCollapsed(group.groupId)}
              collapsed={groupCollapsedState[group.groupId]}
            >
              <Space direction={"vertical"}>
                {group.values.map((value, index) => (
                  <FormItem
                    key={index}
                    name={value.name}
                    label={label({ value })}
                    style={{ maxWidth }}
                    rules={[
                      {
                        required: !group.optional
                      }
                    ]}
                  >
                    {value.name.match(/\.body$/) ? <TextArea /> : <Input />}
                  </FormItem>
                ))}
              </Space>
            </GroupCollapse>
          </GroupContainer>
        );
      })}
      <FormItem>
        <ButtonGroup mobile={isMobile}>
          <NewButton
            type={"submit"}
            data-cy={"update-branding-strings-form-save"}
          >
            {submitButtonText}
          </NewButton>
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

const Label = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupContainer = styled.div`
  margin-bottom: 30px;
  width: ${({ isMobile, isTablet, inputWidth }) =>
    isTablet ? "420px" : isMobile ? undefined : inputWidth};
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & .ant-form-item-label {
    max-height: unset;
  }

  & textarea {
    min-height: 300px;
  }
`;

UpdateBrandingStringsForm.propTypes = {
  form: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired
};

export default compose(UpdateBrandingStringsForm);
