import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import PropType from "prop-types";
import QuestionAndAnswer from "../../Report/components/ReportYourSituation/QuestionAndAnswer";
import { isPhaseLessThanOrEqualTo, Phases } from "../../../utils/phases";
import ReportMetric from "../../Report/components/ReportYourSituation/ReportMetric";
import {
  selectDiscoveryMetricGroupCodeString,
  selectDiscoveryUngroupedMetricCodeString
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import ThreeColumns from "./ThreeColumns";
import PrintReportMetricGroup from "./PrintReportMetricGroup";

const PrintReportYourSituation = ({
  discovery,
  template,
  metricCodeString,
  groupCodeString
}) => {
  return (
    <Container>
      <Heading level={"h1"}>
        {s("report.your_situation.header", "Your Situation", {
          discoveryName: discovery.name
        })}
      </Heading>
      <Heading level={"h3"}>
        {s(
          "report.your_situation.subheader",
          "Segmentation Questions & Answers"
        )}
      </Heading>
      <ThreeColumns style={{ marginBottom: 20 }}>
        {discovery.questions
          .filter((question) => question.active)
          .map((discoveryQuestion, index) => (
            <QuestionAndAnswer
              discoveryQuestion={discoveryQuestion}
              discoveryId={discovery._id}
              reportId={discovery.reportId}
              key={index}
              questionNumber={index}
            />
          ))}
      </ThreeColumns>
      {isPhaseLessThanOrEqualTo(Phases.VALUE_HYPOTHESIS, template) &&
      (metricCodeString || groupCodeString) ? (
        <>
          <Heading level={"h3"}>
            {s(
              "report.your_situation.subheader2",
              "Current Inputs Used to Calculate Value"
            )}
          </Heading>
          {metricCodeString ? (
            <ThreeColumns>
              {metricCodeString.split(",").map((metricCode, index) => (
                <ReportMetric metricCode={metricCode} key={index} />
              ))}
            </ThreeColumns>
          ) : null}
          {groupCodeString
            ? groupCodeString
                .split(",")
                .map((groupCode, index) => (
                  <PrintReportMetricGroup key={index} groupCode={groupCode} />
                ))
            : null}
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${themeProp("palette.surface")};
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
`;

PrintReportYourSituation.propTypes = {
  discovery: PropType.object.isRequired,
  template: PropType.string.isRequired
};

const mapStateToProps = (state) => ({
  metricCodeString: selectDiscoveryUngroupedMetricCodeString(state),
  groupCodeString: selectDiscoveryMetricGroupCodeString(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PrintReportYourSituation
);
