import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import MyDiscoveries from "../Pages/MyDiscoveries";
import Login from "../Pages/Login";
import SignUp from "../Pages/SignUp";
import ConfirmationCode from "../Pages/ConfirmationCode";
import ResetPassword from "../Pages/ResetPassword";
import Discovery from "../Pages/Discovery";
import ValueMap from "../Pages/ValueMap";
import { NotFound } from "../Pages/ErrorPage";
import Report from "../Pages/Report";
import PrintReport from "../Pages/PrintReport";
import Logout from "../Pages/Logout";
import Callback from "../Pages/Callback";
import { hasPermission, isAuthenticated } from "../store/reducers";
import Invitation from "../Pages/Invitation";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import CreateDiscovery from "../Pages/CreateDiscovery";
import LeadGeneration from "../Pages/LeadGeneration";
import Organization from "../Pages/Organization";
import Permissions from "../utils/permissions";
import GrantDiscoveryAccess from "../Pages/GrantDiscoveryAccess";
import ProtectedRoute from "./ProtectedRoute";
import CuvamaRoute from "./CuvamaRoute";
import NotesPanel from "../components/Notes/NotesPanel";
import AutoConfiguration from "../Pages/AutoConfiguration";
import LoadingPage from "../Pages/AutoConfiguration/LoadingPage";
import { IntegrationCallback } from "../Pages/Organization/General/Integration";

const Routes = ({ isAuthenticated, canSeeMyDiscoveries, canSeeOrgAdmin }) => {
  return (
    <Switch>
      <ProtectedRoute
        isAuthenticated={isAuthenticated}
        hasPermission={canSeeMyDiscoveries}
        exact
        path="/MyDiscoveries"
        component={MyDiscoveries}
      />
      <ProtectedRoute
        isAuthenticated={isAuthenticated}
        path="/discoveries/create"
        component={CreateDiscovery}
      />
      <CuvamaRoute
        requiresAuthentication={true}
        requiresPermission={Permissions.SEE_DISCOVERY_NOTES}
        path="/discoveries/:discoveryId/notes"
        component={NotesPanel}
      />
      <CuvamaRoute
        supportsIFrame
        path={"/discoveries/lead-generation"}
        component={LeadGeneration}
        exact
      />
      <CuvamaRoute
        supportsIFrame
        requiresAuthentication={true}
        path="/discoveries/:discoveryId"
        component={Discovery}
      />
      <CuvamaRoute
        requiresAuthentication={true}
        requiresPermission={Permissions.SEE_CANVAS}
        path="/valuemaps/:discoveryId"
        component={ValueMap}
      />
      <ProtectedRoute
        isAuthenticated={isAuthenticated}
        hasPermission={canSeeOrgAdmin}
        path="/organizations/:path"
        component={Organization}
      />

      <ProtectedRoute
        isAuthenticated={isAuthenticated}
        path="/access/:accessId/grant"
        component={GrantDiscoveryAccess}
      />

      <CuvamaRoute
        exact
        path={"/auto-configuration"}
        component={AutoConfiguration}
        requiresAuthentication={true}
        requiresFeature={"autoConfiguration"}
      />

      <CuvamaRoute
        exact
        path={"/auto-configuration/:jobId"}
        component={LoadingPage}
        requiresAuthentication={true}
        requiresFeature={"autoConfiguration"}
      />

      <CuvamaRoute path="/login" component={Login} />
      <CuvamaRoute path="/sign-up" component={SignUp} />
      <CuvamaRoute path="/verification-code" component={ConfirmationCode} />
      <CuvamaRoute path="/forgot-password" component={ForgotPassword} />
      <CuvamaRoute path="/reset-password" component={ResetPassword} />
      <CuvamaRoute path="/logout" component={Logout} />
      <Route path={"/callback"} component={Callback} exact />

      <CuvamaRoute
        supportsIFrame
        path="/reports/:reportId"
        component={Report}
        exact
      />
      <CuvamaRoute
        supportsIFrame
        path="/reports/:reportId/:section"
        component={PrintReport}
      />

      <CuvamaRoute path="/invitations/:invitationCode" component={Invitation} />

      <CuvamaRoute
        path="/integrations/:name/callback"
        component={IntegrationCallback}
      />

      <Redirect from="/" to="/MyDiscoveries" exact />
      <Route component={NotFound} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  canSeeMyDiscoveries: hasPermission(state, Permissions.SEE_MY_DISCOVERIES),
  canSeeOrgAdmin: hasPermission(state, Permissions.SEE_ORG_ADMIN)
});

export default connect(mapStateToProps)(Routes);
