import styled from "styled-components";
import { Text } from "../../../../components";

const KPIName = styled(Text)`
  color: white !important;
  opacity: 0.8;
  padding-bottom: 16px;

  &.mobile {
    padding-bottom: 0px;
  }
`;

export default KPIName;
