import Text from "../../Text";
import styled from "styled-components";
import PropType from "prop-types";
import Dropdown from "../../Dropdown";
import Icon from "../../Icon";
import React, { useState } from "react";
import Menu, { MenuItem } from "../../Menu";
import { useString as s } from "../../StringProvider";
import EditCommentForm from "./EditCommentForm";
import { startDiscoveryOperation } from "../../../store/actions/discovery-operations";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  startEditingComment,
  stopEditingComment
} from "../../../store/actions/comments";
import { isEditingComment, selectComment } from "../../../store/reducers";
import NewButton from "../../NewButton";
import DeleteCommentModal from "./DeleteCommentModal";
import { dateTimeFormat } from "../../../utils/formatting";

const Keys = {
  EDIT: "Edit",
  DELETE: "Delete"
};

const CommentCard = ({
  commentId,
  comment,
  discoveryId,
  reportId,
  isEditing,
  startDiscoveryOperation,
  startEditingComment,
  stopEditingComment,
  ...props
}) => {
  const editCommentText = s("comments.popup.edit.menu", "Edit comment");
  const deleteCommentText = s("comments.popup.delete.menu", "Delete comment");
  const valueNoteText = s("comments.popup.valueNote", "Value Note");
  const [deleting, setDeleting] = useState(false);
  const moreActionsText = s("comments.popup.moreActions", "More actions");

  const onClick = (e) => {
    switch (e.key) {
      case Keys.EDIT:
        startEditingComment({ commentId: commentId });
        break;

      case Keys.DELETE:
        setDeleting(true);
        break;
    }
  };

  const renderMenu = () => {
    return (
      <Menu onClick={onClick}>
        <MenuItem key={Keys.EDIT} icon={<Icon name={"edit"} />}>
          {editCommentText}
        </MenuItem>
        {!comment.isValueNote ? (
          <MenuItem
            key={Keys.DELETE}
            icon={<Icon name={"delete"} />}
            data-cy={"comment-delete-menu-item"}
          >
            {deleteCommentText}
          </MenuItem>
        ) : null}
      </Menu>
    );
  };

  const onStopEditing = () => {
    stopEditingComment();
  };

  return isEditing ? (
    <EditCommentForm
      comment={comment}
      reportId={reportId}
      onClose={onStopEditing}
    />
  ) : (
    <Card className={"comment-card"} {...props}>
      <Headline>
        <Subject>
          <Text
            variant={"small"}
            color={"gray4"}
            className={"comment-card-username"}
            block
          >
            {comment.username}
          </Text>
          &nbsp;
          <Text
            variant={"small"}
            color={"gray3"}
            className={"comment-card-date"}
            block
          >
            {dateTimeFormat(comment.createdAt)}
          </Text>
          {comment.isValueNote ? (
            <Text
              variant={"small"}
              color={"gray3"}
              data-cy={"comment-value-note"}
              block
            >
              &nbsp;&bull;&nbsp;{valueNoteText}
            </Text>
          ) : null}
        </Subject>
        {comment.canEdit ? (
          <Action>
            <Dropdown
              overlay={renderMenu()}
              placement={"bottomLeft"}
              trigger={["click"]}
            >
              <NewButton type={"iconSecondary"} tooltip={moreActionsText}>
                <Icon name={"dropdown"} />
              </NewButton>
            </Dropdown>
          </Action>
        ) : null}
      </Headline>
      <Content>
        {comment.text.split("\n").map((text, index) => (
          <Text key={index} variant={"body"} className={"comment-card-text"}>
            {text}
          </Text>
        ))}
      </Content>
      {deleting ? (
        <DeleteCommentModal
          topic={comment.topic}
          commentId={comment._id}
          reportId={reportId}
          onClose={() => setDeleting(false)}
        />
      ) : null}
    </Card>
  );
};

CommentCard.propTypes = {
  comment: PropType.object.isRequired,
  discoveryId: PropType.string.isRequired,
  reportID: PropType.string
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Headline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Subject = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  span:not(:last-child) {
    padding-bottom: 10px;
  }
`;

const mapStateToProps = (state, props) => ({
  isEditing: isEditingComment(state, props.commentId),
  comment: selectComment(state, props.commentId)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startDiscoveryOperation,
      startEditingComment,
      stopEditingComment
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CommentCard);
