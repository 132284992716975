import NewButton from "../NewButton";
import PropTypes from "prop-types";
import { useString as s } from "../StringProvider";

const RegisterInterestButton = ({ onClick }) => {
  const registerInterestText = s("discovery.registerInterestButton", "Next");

  return (
    <NewButton
      type={"primary"}
      onClick={onClick}
      data-cy={"register-interest-button"}
    >
      {registerInterestText}
    </NewButton>
  );
};

RegisterInterestButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default RegisterInterestButton;
