import PropTypes from "prop-types";
import styled from "styled-components";
import TextBody from "../../../../components/TextBody";
import Progress from "../../../../components/Progress";
import { useEffect, useState } from "react";
import { Text } from "../../../../components";
import { useString as s } from "../../../../components/StringProvider";
import * as formatting from "../../../../utils/formatting";
import {
  selectRequestData,
  selectRequestParameters,
  selectRequestState
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { createFile } from "../../../../store/actions/files";
import actionTypes from "../../../../store/actionTypes";
import { themeProp } from "../../../../theme";
import { ReactComponent as XLS } from "../../../../assets/XLS.svg";
import useLoadingState from "../../../../utils/use-loading-state";
import ValidationStatuses from "../../../../utils/validation-statuses";
import httpService from "../../../../services/http.service";
import NewButton from "../../../../components/NewButton";
import Icon from "../../../../components/Icon";
import getFileExtension from "../../../../utils/get-file-extension";
import { formatBytes } from "../../../../utils/formatting";

const States = {
  READY: "ready",
  UPLOADING: "uploading",
  UPLOAD_ERROR: "upload_error",
  INVALID_FILE_TYPE: "invalid_file_type",
  INVALID_FILE_SIZE: "invalid_file_size",
  UPLOAD_COMPLETE: "upload_complete",
  VALIDATING: "validating",
  VALIDATION_ERROR: "validation_error",
  VALIDATION_COMPLETE: "validation_complete",
  VALIDATION_COMPLETE_WITH_ERRORS: "validation_complete_with_errors",
  CANCELLING: "cancelling"
};
const ERROR_STATES = [
  States.UPLOAD_ERROR,
  States.INVALID_FILE_SIZE,
  States.INVALID_FILE_TYPE,
  States.UPLOAD_ERROR,
  States.VALIDATION_ERROR,
  States.VALIDATION_COMPLETE_WITH_ERRORS
];

const FileUpload = ({
  localTag,
  file,
  temporary,
  onDelete,
  onUploadComplete,
  createFileLoadingState,
  createFileData,
  createValidationLoadingState,
  createFileParameters,
  validation,
  createFile,
  showFileIcon = true,
  maxFileSize,
  acceptTypes
}) => {
  const [percent, setPercent] = useState(0);
  const [state, setState] = useState(States.READY);
  const [isCancelled, setCancelled] = useState(false);
  const messages = {};
  const controller = new AbortController();
  const deleteTooltip = s(
    "manageConfiguration.page.fileUpload.delete",
    "Discard file"
  );
  const cancelTooltip = s(
    "manageConfiguration.page.fileUpload.cancel",
    "Cancel"
  );
  const maxFileSizeDescription = maxFileSize ? formatBytes(maxFileSize) : "";

  messages[States.READY] = { text: "" };
  messages[States.UPLOADING] = {
    text: s("manageConfiguration.page.fileUpload.uploading", "Uploading...")
  };
  messages[States.UPLOAD_ERROR] = {
    color: "error",
    text: s("manageConfiguration.page.fileUpload.uploadError", "Upload error")
  };
  messages[States.INVALID_FILE_SIZE] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUpload.invalidFileSize",
      "File is too large. Upload a file up to {maxFileSizeDescription}",
      { maxFileSizeDescription }
    )
  };
  messages[States.INVALID_FILE_TYPE] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUpload.invalidFileType",
      "Wrong file format, please try {acceptTypes}",
      { acceptTypes }
    )
  };

  messages[States.UPLOAD_COMPLETE] = {
    text: s(
      "manageConfiguration.page.fileUpload.uploadComplete",
      "Upload complete"
    )
  };
  messages[States.VALIDATING] = {
    text: s(
      "manageConfiguration.page.fileUpload.validating",
      "Validating your template..."
    )
  };
  messages[States.VALIDATION_ERROR] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUpload.validationError",
      "Validation error"
    )
  };
  messages[States.VALIDATION_COMPLETE] = {
    text: s(
      "manageConfiguration.page.fileUpload.validationComplete",
      "Validation complete"
    )
  };
  messages[States.VALIDATION_COMPLETE_WITH_ERRORS] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUpload.validationCompleteWithErrors",
      "Error detected"
    )
  };
  messages[States.CANCELLING] = {
    color: "error",
    text: s("manageConfiguration.page.fileUpload.cancelling", "Cancelling...")
  };

  const onUploadProgress = (progressEvent) => {
    if (localTag && createFileParameters?.localTag !== localTag) {
      return;
    }
    const { loaded, total } = progressEvent;
    setPercent(Math.floor((loaded * 100) / total));
  };

  const onStartUpload = (url) => {
    if (localTag && createFileParameters?.localTag !== localTag) {
      return;
    }

    httpService
      .uploadFile(url, file, onUploadProgress, controller.signal)
      .then(() => {
        setState(States.UPLOAD_COMPLETE);
        onUploadComplete();
      })
      .catch((error) => {
        console.log("Error", error.code, error.message);
        setState(States.UPLOAD_ERROR);
      });

    setState(States.UPLOADING);
  };

  const onCancel = () => {
    console.log("abort");
    controller.abort();
    setCancelled(true);

    if (state === States.UPLOAD_COMPLETE) {
      onDelete(file);
    } else if (!ERROR_STATES.includes(state)) {
      setState(States.CANCELLING);
    }
  };

  useLoadingState(
    createFileLoadingState,
    () => {
      onStartUpload(createFileData.url);
    },
    () => {
      setState(States.UPLOAD_ERROR);
    }
  );

  useLoadingState(
    createValidationLoadingState,
    () => {
      setState(
        validation.status === ValidationStatuses.VALIDATED
          ? States.VALIDATION_COMPLETE
          : States.VALIDATION_COMPLETE_WITH_ERRORS
      );
    },
    () => {
      setState(States.VALIDATION_ERROR);
    }
  );

  useEffect(() => {
    if (maxFileSize && file.size > maxFileSize) {
      setState(States.INVALID_FILE_SIZE);
      return;
    }

    if (acceptTypes) {
      const extension = getFileExtension(file.name);
      const acceptedExtensions = acceptTypes
        ?.split(",")
        ?.map((s) => s.replace(".", ""));

      if (!acceptedExtensions.includes(extension)) {
        setState(States.INVALID_FILE_TYPE);
        return;
      }
    }

    createFile({
      name: file.name,
      size: file.size,
      type: file.type,
      temporary,
      localTag
    });
  }, []);

  useEffect(() => {
    if (ERROR_STATES.includes(state) && isCancelled) {
      onDelete(file);
    }
  }, [state, isCancelled]);

  switch (state) {
    case States.VALIDATION_COMPLETE:
      return (
        <Container>
          <Row>
            {showFileIcon && <XLS className={"xls"} />}
            <TextBody className={"name"}>{file.name}</TextBody>
            <Text
              className={"percent"}
              variant={"small"}
              color={messages[state].color || "gray4"}
            >
              {messages[state].text}
            </Text>
            <Icon name={"check"} colour={"success"} />
          </Row>
        </Container>
      );

    case States.INVALID_FILE_SIZE:
    case States.INVALID_FILE_TYPE:
    case States.VALIDATION_COMPLETE_WITH_ERRORS:
      return (
        <Container>
          <Row>
            <Icon name={"close"} colour={"error"} />
            <TextBody className={"name"}>{file.name}</TextBody>
            <Text className={"percent"} variant={"small"} color={"error"}>
              {messages[state].text}
            </Text>
            <NewButton
              type={"iconSecondary"}
              onClick={onCancel}
              tooltip={deleteTooltip}
            >
              <Icon name={"delete"} />
            </NewButton>
          </Row>
        </Container>
      );

    default:
      return (
        <Container>
          <Row showFileIcon={showFileIcon}>
            {showFileIcon && <XLS className={"xls"} />}
            <TextBody className={"name"}>{file.name}</TextBody>
            <Text className={"percent"} variant={"small"} color={"gray4"}>
              {formatting.formatPercentage({ value: percent })}
            </Text>
            <NewButton
              type={"iconSecondary"}
              onClick={onCancel}
              tooltip={cancelTooltip}
            >
              <Icon name={"close"} />
            </NewButton>
          </Row>

          <Row>
            <Progress percent={percent} color={"primary"} />
          </Row>
          <Row>
            <Text color={messages[state].color || "gray4"} variant={"small"}>
              {messages[state].text}
            </Text>
          </Row>
        </Container>
      );
  }
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  line-height: ${themeProp(`typography.body.lineHeight`)};

  & .xls {
    height: 20px;
    width: 20px;

    path {
      fill: ${themeProp(`palette.gray3`)};
    }
    line {
      stroke: ${themeProp(`palette.gray3`)};
    }
  }

  & .name {
    flex: 1;
  }

  & .percent {
    text-align: right;
  }

  & .ant-progress-text {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

FileUpload.propTypes = {
  file: PropTypes.object.isRequired,
  temporary: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number,
  acceptTypes: PropTypes.string
};

FileUpload.defaultProps = {
  temporary: false
};

const mapStateToProps = (state) => ({
  createFileLoadingState: selectRequestState(
    state,
    actionTypes.CREATE_FILE_REQUEST
  ),
  createFileData: selectRequestData(state, actionTypes.CREATE_FILE_REQUEST),
  createValidationLoadingState: selectRequestState(
    state,
    actionTypes.CREATE_VALIDATION_REQUEST
  ),
  createFileParameters: selectRequestParameters(
    state,
    actionTypes.CREATE_FILE_REQUEST
  ),
  validation: selectRequestData(state, actionTypes.CREATE_VALIDATION_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFile
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FileUpload
);
