import { Rate } from "./";
import { useEffect, useState } from "react";
import Heading from "./Heading";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useMobileMediaQuery } from "./Responsive";

const RateImportance = ({
  label,
  importance,
  onChangeImportance,
  editable,
  className
}) => {
  const [currentImportance, setCurrentImportance] = useState(importance);
  const isMobile = useMobileMediaQuery();

  useEffect(() => {
    setCurrentImportance(importance);
  }, [importance]);

  return (
    <Container className={className}>
      <Heading level={isMobile ? "h5Small" : "h5"} color={"gray3"}>
        {label}
      </Heading>
      <Rate
        defaultValue={currentImportance}
        onChange={(value) => onChangeImportance({ importance: value })}
        disabled={!editable}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & h5 {
    margin-bottom: 0;
  }
`;

RateImportance.propTypes = {
  label: PropTypes.string.isRequired,
  importance: PropTypes.number.isRequired,
  onChangeImportance: PropTypes.func,
  editable: PropTypes.bool.isRequired
};

export default RateImportance;
