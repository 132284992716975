import { selectNotification } from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import PropType from "prop-types";
import { themeProp } from "../../theme";
import Text from "../Text";
import { timeSince } from "../../utils/formatting";
import Markdown from "../Discovery/Markdown";
import getNotificationText, {
  NotificationCategory
} from "./get-notification-text";
import { IntlMessageFormat } from "intl-messageformat";
import { getNotificationContext } from "./get-notification-context";

const NotificationCard = ({ notificationId, notification }) => {
  const timeText = timeSince(notification.createdAt);

  const message = new IntlMessageFormat(
    getNotificationText({
      type: notification.type,
      category: NotificationCategory.NOTIFICATIONS
    }),
    "en-US"
  ).format(getNotificationContext(notification));

  return (
    <Container
      className={notification.isRead ? undefined : "unread"}
      data-cy={`notification-card`}
      id={`notification-${notificationId}`}
    >
      <div>
        <Text variant={"small"} color={"gray3"} className={"time"}>
          {timeText}
        </Text>
      </div>
      <Markdown className={"notification"}>{message}</Markdown>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 20px 16px;
  margin-bottom: 1px;

  &.unread {
    background: ${themeProp("palette.background")};
  }
`;

NotificationCard.propTypes = {
  notificationId: PropType.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    notification: selectNotification(state, props.notificationId)
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotificationCard
);
