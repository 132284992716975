import { isPhaseLessThanOrEqualTo, Phases } from "../../../../utils/phases";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import _ from "lodash";
import Progress from "../../../../components/Progress";
import Header, {
  BrandBadge,
  HeaderMenuItem,
  HeaderMiddleSection,
  HeaderRightSection
} from "../../../../components/Header";
import { themeProp } from "../../../../theme";
import styled from "styled-components";
import NewButton from "../../../../components/NewButton";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { isInView, scrollToReportSection } from "./ScrollUtils";
import PhaseName from "../../../../components/PhaseName";
import isInIFrame from "../../../../utils/is-in-iframe";
import {
  useCustomMediaQuery,
  useMobileMediaQuery
} from "../../../../components/Responsive";
import ReportDropdown from "./ReportDropdown";
import { exportDiscovery } from "../../../../store/actions/discoveries";
import { useLocation } from "react-router-dom";

const getMenuItems = ({ discovery, template }) => {
  const menuItems = [];

  if (isPhaseLessThanOrEqualTo(Phases.ROI, template) && discovery.roi) {
    menuItems.push({
      name: <PhaseName phase={Phases.ROI} />,
      sectionId: "report-roi"
    });
  }

  if (isPhaseLessThanOrEqualTo(Phases.VALUE_HYPOTHESIS, template)) {
    menuItems.push({
      name: <PhaseName phase={Phases.VALUE_HYPOTHESIS} />,
      sectionId: "report-value-hypothesis"
    });
  }

  if (isPhaseLessThanOrEqualTo(Phases.KPIS_IMPACTED, template)) {
    menuItems.push({
      name: <PhaseName phase={Phases.KPIS_IMPACTED} />,
      sectionId: "report-kpis-impacted"
    });
  }

  menuItems.push({
    name: <PhaseName phase={Phases.CHALLENGES_SOLVED} />,
    sectionId: "report-challenges-solved"
  });

  menuItems.push({
    name: s("report.your_situation.header", "Your Situation"),
    sectionId: "report-your-situation"
  });

  return discovery && template ? menuItems : [];
};

const ReportHeader = ({ template, discovery, exportDiscovery }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const templateFromUrl = params.get("template");

  const [activeSection, setActiveSection] = useState();
  const menuItems = getMenuItems({ discovery, template });

  const isMobile = useMobileMediaQuery();
  const { isBreakPoint: showDesktopMenu } = useCustomMediaQuery({
    type: "min",
    singleBreakPoint: 1000
  });

  useEffect(() => {
    if (discovery) {
      handleScroll();
    }
  }, [discovery]);

  const onClick = ({ sectionId }) => {
    scrollToReportSection({ sectionId });
  };

  const handleScroll = _.throttle(() => {
    let lastVisibleSection = false;
    let previousVisibleSection = false;

    for (const menuItem of menuItems) {
      const element = document.getElementById(menuItem.sectionId);

      if (element && isInView(element)) {
        previousVisibleSection = lastVisibleSection;
        lastVisibleSection = menuItem.sectionId;
      }
    }

    setActiveSection(previousVisibleSection || lastVisibleSection);
  }, 100);

  useEffect(() => {
    if (discovery) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [discovery, handleScroll]);

  const onDownloadPDF = () => {
    exportDiscovery({
      reportId: discovery?.reportId,
      template: templateFromUrl || discovery?.phase,
      format: "pdf"
    });
  };

  return (
    <Header data-cy={"report-header"}>
      <BrandBadge />
      <HeaderMiddleSection>
        {showDesktopMenu ? (
          <>
            {menuItems.map((item, index) => (
              <HeaderMenuItem key={index}>
                <MenuButton
                  color={"secondary"}
                  active={activeSection === item.sectionId}
                  onClick={() => onClick({ sectionId: item.sectionId })}
                  data-cy={`menu-button-${item.sectionId}`}
                >
                  {item.name}
                </MenuButton>
                {activeSection === item.sectionId ? (
                  <Progress
                    color={"secondary"}
                    percent={100}
                    showInfo={false}
                  />
                ) : null}
              </HeaderMenuItem>
            ))}
          </>
        ) : (
          <ReportDropdownContainer>
            <ReportDropdown
              menuItems={menuItems}
              activeSection={activeSection}
            />
          </ReportDropdownContainer>
        )}
      </HeaderMiddleSection>
      {!isMobile && (
        <HeaderRightSection>
          {!isInIFrame() && (
            <NewButton
              type={"primary"}
              onClick={onDownloadPDF}
              data-cy={"print-button"}
            >
              {s("reports.header.download.pdf", "Download PDF")}
            </NewButton>
          )}
        </HeaderRightSection>
      )}
    </Header>
  );
};

ReportHeader.propTypes = {
  discovery: PropTypes.object,
  template: PropTypes.oneOf(Phases.ALL)
};

const ReportDropdownContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const MenuButton = styled.div`
  cursor: pointer;
  color: ${(props) =>
    props.active
      ? themeProp("components.button." + props.color + ".base")
      : "inherit"};

  &:hover {
    color: ${themeProp("palette.secondary")};
  }
`;

MenuButton.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary"])
};

MenuButton.defaultProps = {
  active: false,
  color: "primary"
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReportHeader
);
