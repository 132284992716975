import styled from "styled-components";
import { themeProp } from "../../../../theme";

const FooterMenuItem = styled.div`
  color: ${themeProp("palette.gray3")};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 8px 0 8px;
  font-size: ${themeProp("typography.body.fontSize", "16px")};
  font-family: ${themeProp("typography.label.fontFamily", "sans-serif")};
  font-weight: bold;
  line-height: 22px;

  .active {
    color: ${themeProp("palette.secondary")};
  }
  .completed {
    color: ${themeProp("palette.secondary")};

    .icon {
      color: ${themeProp("palette.secondary")};
    }
  }
  .ondeck {
    color: ${themeProp("palette.secondary")};
  }

  @media print {
    color: ${themeProp("palette.secondary")};
  }
`;

export default FooterMenuItem;
