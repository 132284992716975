import httpService from "./http.service";

const logError = ({ data, category = "error-service", error, stack }) =>
  httpService
    .post(`/errors`, { data, category, level: "ERROR", error, stack })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const logInfo = ({ data, category = "error-service" }) =>
  httpService
    .post(`/errors`, { data, category, level: "INFO" })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  logError,
  logInfo
};

export default methods;
