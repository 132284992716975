import types from "../actionTypes";
import notificationsService from "../../services/notifications.service";

export const getNotificationCount = () => (dispatch) => {
  dispatch({
    type: types.COUNT_NOTIFICATIONS_REQUEST
  });

  return notificationsService
    .getNotificationCount()
    .then((data) => {
      dispatch({
        type: types.COUNT_NOTIFICATIONS_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: types.COUNT_NOTIFICATIONS_FAILURE,
        payload: { error }
      });
    });
};

export const getNotifications =
  ({ isRead, start = 0, count = 50 }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_NOTIFICATIONS_REQUEST
    });

    return notificationsService
      .getNotifications({ isRead, start, count })
      .then(({ items, pagination }) => {
        dispatch({
          type: types.GET_NOTIFICATIONS_SUCCESS,
          payload: { notifications: items, pagination }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_NOTIFICATIONS_FAILURE,
          payload: { error }
        });
      });
  };

export const markNotificationsAsRead =
  ({ notificationIds }) =>
  (dispatch) => {
    dispatch({
      type: types.READ_NOTIFICATIONS_REQUEST
    });

    return notificationsService
      .markNotificationsAsRead({ notificationIds })
      .then(() => {
        dispatch({
          type: types.READ_NOTIFICATIONS_SUCCESS
        });
      })
      .catch((error) => {
        dispatch({
          type: types.READ_NOTIFICATIONS_FAILURE,
          payload: { error }
        });
      });
  };

export const addNotification =
  ({ notification }) =>
  (dispatch) => {
    dispatch({
      type: types.ADD_NOTIFICATION,
      payload: { notification }
    });
  };
