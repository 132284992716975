import CommentCard from "./CommentCard";
import NewCommentForm from "./NewCommentForm";
import styled from "styled-components";
import { useEffect, useRef } from "react";
import LoadingState from "../../../utils/loading-state";
import {
  isEditingAnyComment,
  selectCommentIds,
  selectCommentsLoadingState,
  selectCommentTopic,
  selectDiscoveryId,
  selectDraftCommentForTopic,
  selectPostCommentLoadingState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import {
  closeComments,
  getComments,
  getReportComments,
  postComment,
  postReportComment
} from "../../../store/actions/comments";
import { connect } from "react-redux";
import Form from "../../Form";
import PropType from "prop-types";
import { useMobileMediaQuery } from "../../Responsive";
import { sendUserPilotEvent } from "../../../utils/user-pilot-util";

const CommentsPanel = ({
  topic,
  commentIds,
  draftComment,
  commentsLoadingState,
  postCommentLoadingState,
  discoveryId,
  reportId,
  isEditing,
  postComment,
  postReportComment,
  getComments,
  getReportComments,
  closeComments,
  onClose,
  commentPanelMaxHeight,
  eventName
}) => {
  const isMobile = useMobileMediaQuery();
  const [form] = Form.useForm();
  const commentsRef = useRef();

  useEffect(() => {
    if (reportId) {
      getReportComments({
        reportId,
        topic
      });
    } else {
      getComments({
        topic
      });
    }

    return () => {
      closeComments({
        topic,
        text: form.getFieldValue("text")
      });
    };
  }, []);

  useEffect(() => {
    if (topic) {
      form.setFieldsValue({ text: draftComment });
    }
  }, [topic, form, draftComment]);

  useEffect(() => {
    if (postCommentLoadingState === LoadingState.COMPLETED) {
      form.resetFields();
      if (commentsRef.current) {
        commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
      }
    }
  }, [form, postCommentLoadingState, commentsRef]);

  useEffect(() => {
    if (
      commentsLoadingState === LoadingState.COMPLETED &&
      commentsRef.current
    ) {
      commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
    }
  }, [commentsRef, commentsLoadingState]);

  const trackNewComment = () => {
    sendUserPilotEvent({
      eventName,
      data: { discoveryId }
    });
  };

  const onPost = ({ text }) => {
    if (reportId) {
      postReportComment({ reportId, topic, text });
      trackNewComment();
    } else {
      postComment({ topic, text });
      trackNewComment();
    }
  };

  return (
    <Container
      className={isMobile ? "mobile" : undefined}
      data-cy={"comments-panel"}
    >
      <Comments ref={commentsRef} commentPanelMaxHeight={commentPanelMaxHeight}>
        {commentIds.map((commentId, index) => (
          <CommentCard
            key={index}
            commentId={commentId}
            discoveryId={discoveryId}
            reportId={reportId}
          />
        ))}
      </Comments>
      {!isEditing ? (
        <NewCommentForm form={form} onClose={onClose} onPost={onPost} />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 32px 24px;
  min-width: 450px;
  max-width: 450px;
  border-radius: 8px;

  &.mobile {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
  }
`;

const Comments = styled.div`
  max-height: ${(props) => `${props.commentPanelMaxHeight}px`};
  overflow-y: auto;
`;

CommentsPanel.propTypes = {
  topic: PropType.string.isRequired,
  reportId: PropType.string,
  commentPanelMaxHeight: PropType.number.isRequired,
  eventName: PropType.string.isRequired
};

function mapStateToProps(state) {
  return {
    discoveryId: selectDiscoveryId(state),
    commentIds: selectCommentIds(state),
    commentsLoadingState: selectCommentsLoadingState(state),
    postCommentLoadingState: selectPostCommentLoadingState(state),
    draftComment: selectDraftCommentForTopic(state, selectCommentTopic(state)),
    isEditing: isEditingAnyComment(state)
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postComment,
      postReportComment,
      getComments,
      getReportComments,
      closeComments
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CommentsPanel
);
