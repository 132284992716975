import types from "../actionTypes";

const INITIAL_STATE = {
  modal: null
};

export const ConfigurationModals = {
  NOTES_TEMPLATE: "notesTemplate",
  POWERPOINT_TEMPLATES: "powerpointTemplates",
  MASTER_DATA: "masterData"
};

const updateConfigurationModalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.OPEN_CONFIGURATION_MODAL:
      return {
        ...state,
        modal: action.payload.modal
      };

    case types.CLOSE_CONFIGURATION_MODAL:
      return {
        ...state,
        modal: null
      };

    default:
      return state;
  }
};

export default updateConfigurationModalsReducer;

export const selectCurrentConfigurationModal = (data) => data.modal;
