import React from "react";
import TextBody from "../../../../components/TextBody";
import NewButton from "../../../../components/NewButton";
import { useString as s } from "../../../../components/StringProvider";
import ButtonGroup from "../../../../components/ButtonGroup";
import PropTypes from "prop-types";
import Form, { FormItem } from "../../../../components/Form";
import Input from "../../../../components/Input";
import { Modal } from "../../../../components";

const RejectPromotionModal = ({ onConfirm, onClose }) => {
  const rejectionReasonValidation = s(
    "currentConfiguration.page.rejectPromotion.modal.rejectionReason.validation",
    "You must enter a reason."
  );
  const rejectionReasonPlaceholder = s(
    "currentConfiguration.page.rejectPromotion.modal.rejectionReason.placeholder",
    "Why do you reject this promotion?"
  );

  return (
    <Modal
      title={s(
        "currentConfiguration.page.rejectPromotion.modal.title",
        "Reject Promotion"
      )}
      open={true}
      onCancel={onClose}
    >
      <TextBody>
        {s(
          "currentConfiguration.page.rejectPromotion.modal.text",
          "By continuing, the configuration won’t be promoted. Please share the rejection reason and we’ll notify the user about it."
        )}
      </TextBody>
      <div style={{ paddingTop: 24 }}>
        <Form onFinish={onConfirm}>
          <FormItem
            name={"rejectionReason"}
            rules={[
              {
                required: true,
                message: rejectionReasonValidation
              }
            ]}
          >
            <Input placeholder={rejectionReasonPlaceholder} />
          </FormItem>
          <ButtonGroup>
            <NewButton type={"submit"}>
              {s(
                "currentConfiguration.page.rejectPromotion.modal.ok",
                "Reject Promotion"
              )}
            </NewButton>
            <NewButton type={"secondary"} onClick={onClose}>
              {s(
                "currentConfiguration.page.rejectPromotion.modal.cancel",
                "Don’t Reject"
              )}
            </NewButton>
          </ButtonGroup>
        </Form>
      </div>
    </Modal>
  );
};

RejectPromotionModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RejectPromotionModal;
