import React, { useEffect, useState } from "react";
import Icon from "../../components/Icon";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import {
  hasPermission,
  selectDiscovery,
  selectDiscoveryId,
  selectDiscoveryQuestion,
  selectQuestion,
  selectQuestions,
  selectRequestData
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import actionType from "../../store/actionTypes";
import { useString as s } from "../../components/StringProvider";
import styled from "styled-components";
import { Tooltip } from "../../components";
import allMandatoryQuestionsAnsweredLocal from "./components/all-mandatory-questions-answered-local";
import NewButton from "../../components/NewButton";
import { isLastQuestion } from "../../store/selectors/discovery";
import { setCanvasClicked } from "../../store/actions/discoveries";
import actionTypes from "../../store/actionTypes";
import Permissions from "../../utils/permissions";
import { getValueMapPath } from "../ValueMap/value-map-utils";
import { themeProp } from "../../theme";
import { useMobileMediaQuery } from "../../components/Responsive";

const pathsToHideCanvasButton = ["/start-discovery"];

const CanvasButton = ({
  discoveryId,
  discovery,
  questions,
  currentQuestion,
  isQuestionFormDisabled,
  isQuickCanvas = false,
  isLastQuestion,
  onClick,
  setCanvasClicked,
  headerCanvasButtonClicked,
  canSeeCanvas,
  isCanvasSelected
}) => {
  const buttonDisabledTooltipText = s(
    "canvas.button.disabled.tooltip",
    "Complete questions first"
  );
  const canvasButtonText = s("canvas.button.text", "Canvas");

  const location = useLocation();
  const history = useHistory();

  const isMobile = useMobileMediaQuery();

  const [disabled, setDisabled] = useState(true);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    /*
      When the user is on a non-questions path and the Question.js component
      will not trigger the submit and re-routing to /valuemaps
     */
    if (
      headerCanvasButtonClicked &&
      !location.pathname.includes("/questions/")
    ) {
      setCanvasClicked({ clicked: false });
      history.push(getValueMapPath(discoveryId));
    }
  }, [headerCanvasButtonClicked]);

  useEffect(() => {
    const isPathToHide = pathsToHideCanvasButton.some((path) =>
      location.pathname.includes(path)
    );
    let isAllMandatoryQuestionsAnswered;
    if (isQuickCanvas) {
      isAllMandatoryQuestionsAnswered = allMandatoryQuestionsAnsweredLocal({
        discovery,
        questions,
        currentQuestion,
        isQuestionFormDisabled
      });
    } else {
      isAllMandatoryQuestionsAnswered = discovery.mandatoryQuestionsCompleted;
    }

    let _hide = false;
    if (
      (!isQuickCanvas && isPathToHide) ||
      (isQuickCanvas && !isLastQuestion) ||
      (isQuickCanvas && isLastQuestion && !isAllMandatoryQuestionsAnswered)
    ) {
      _hide = true;
      setHide(true);
    } else {
      setHide(false);
    }

    if (!_hide) {
      if (!isAllMandatoryQuestionsAnswered) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [isQuestionFormDisabled, isLastQuestion, currentQuestion, discovery]);

  if (hide || !canSeeCanvas) {
    return null;
  }

  if (isQuickCanvas) {
    return (
      <LargeButton
        data-cy={"quick-canvas-button"}
        type={"alternate"}
        onClick={onClick}
        className="up-canvas-button-quick"
      >
        {canvasButtonText}
      </LargeButton>
    );
  }

  const onClickCanvas = () => {
    //trigger Question.js nextStep()
    setCanvasClicked({ clicked: true });
  };

  const TheButton = () => (
    <StyledButton
      onClick={onClickCanvas}
      data-cy={"canvas-button"}
      disabled={disabled}
      type={"text"}
      className="up-canvas-button-main"
      $isSelected={isCanvasSelected}
      $isMobile={isMobile}
    >
      {!isMobile && <Icon name="canvas" />}
      {canvasButtonText}
    </StyledButton>
  );

  if (disabled) {
    return (
      <Tooltip title={buttonDisabledTooltipText} placement="bottom">
        {TheButton()}
      </Tooltip>
    );
  }

  return TheButton();
};

//The same height as a NewButton with an icon
const LargeButton = styled(NewButton)`
  height: 54px;
`;

const StyledButton = styled(NewButton)`
  padding: 0 !important;
  margin: 0 !important;
  color: ${(props) =>
    props.$isSelected
      ? themeProp("palette.secondary")
      : themeProp("palette.text")} !important;

  &:hover {
    background-color: transparent !important;
    color: ${themeProp("palette.secondary")} !important;
  }

  svg {
    width: 18px !important;
    height: 19px !important;
    transform: translateY(-1px);
  }

  span {
    font-size: ${(props) =>
      props.$isMobile
        ? themeProp("typography.bodyMobile.fontSize")
        : themeProp("typography.body.fontSize")};
    font-weight: ${(props) =>
      props.$isMobile
        ? themeProp("typography.bodyMobile.fontWeight")
        : themeProp("typography.button.fontWeight")};
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    color: ${themeProp("palette.gray3")} !important;
  }
`;

function mapStateToProps(state, props) {
  const questionNumber = parseInt(props.match.params.questionNumber);
  const discoveryQuestion = selectDiscoveryQuestion(state, questionNumber);

  return {
    discoveryId: selectDiscoveryId(state),
    discovery: selectDiscovery(state),
    questions: selectQuestions(state),
    currentQuestion:
      discoveryQuestion &&
      selectQuestion(state, discoveryQuestion.questionCode),
    isQuestionFormDisabled: selectRequestData(
      state,
      actionType.DISCOVERY_QUESTION_SUBMIT_DISABLED_SUCCESS
    ),
    headerCanvasButtonClicked: selectRequestData(
      state,
      actionTypes.DISCOVERY_CANVAS_CLICKED_SUCCESS
    ),
    isLastQuestion: isLastQuestion(state, questionNumber),
    canSeeCanvas: hasPermission(state, Permissions.SEE_CANVAS)
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCanvasClicked
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CanvasButton);
