import types from "../actionTypes";

const INITIAL_STATE = {
  kpiCode: null,
  outcomeOverrideType: null,
  isEdit: null,
  evaluationPeriod: null,
  benefitAdjustmentPerYear: null,
  roiFormValues: null,
  roiFormSubmitted: true
};

const roiOverridesOperationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERIES_FETCH_REQUEST:
    case types.DISCOVERY_FETCH_REQUEST:
      return INITIAL_STATE;

    case types.SET_ROI_FORM_VALUES:
      return {
        ...state,
        roiFormValues: action.payload
      };

    case types.SET_ROI_FORM_SUBMITTED:
      return {
        ...state,
        roiFormSubmitted: action.payload
      };

    case types.START_ROI_OVERRIDE_OPERATION:
      return {
        ...state,
        kpiCode: action.payload.kpiCode,
        outcomeOverrideType: action.payload.outcomeOverrideType,
        isEdit: action.payload.isEdit
      };

    case types.STOP_ROI_OVERRIDE_OPERATION:
      return {
        ...state,
        kpiCode: null,
        outcomeOverrideType: null,
        iEdit: null,
        roiFormSubmitted: false
      };

    default:
      return state;
  }
};

export default roiOverridesOperationsReducer;

export const selectROIOverrideKpiCode = (data) => data.kpiCode;
export const selectROIOverrideType = (data) => data.outcomeOverrideType;
export const selectROIOverrideIsEdit = (data) => data.isEdit;
export const selectROIFormValues = (data) => data.roiFormValues;
export const selectROIFormSubmitted = (data) => data.roiFormSubmitted;
