import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Badge from "./Discovery/Badge";
import Icon from "./Icon";
import { themeProp } from "../theme";
import { useString as s } from "./StringProvider";
import Text from "./Text";

const Status = ({ status }) => {
  const statusLabel = s("status.label.title", "STATUS");

  const statuses = {
    pending: {
      label: s("status.label.pending", "Pending"),
      icon: "minus",
      color: "palette.gray4"
    },
    started: {
      label: s("status.label.started", "Started"),
      icon: "minus",
      color: "palette.gray4"
    },
    completed: {
      label: s("status.label.completed", "Completed"),
      icon: "check",
      color: "palette.success"
    },
    failed: {
      label: s("status.label.failed", "Failed"),
      icon: "alert",
      color: "palette.error"
    },
    connected: {
      label: s("status.label.connected", "Connected"),
      icon: "check",
      color: "palette.success"
    },
    notConnected: {
      label: s("status.label.not.connected", "Not Connected"),
      icon: "alert",
      color: "palette.gray4"
    }
  };

  const currentStatus = statuses[status];

  return (
    <StatusContainer data-cy={"status"}>
      <div>
        <Text color={"gray4"} variant={"h5"}>
          {statusLabel}
        </Text>
        <StyledBadge
          color={"surface"}
          bg={themeProp(currentStatus.color)}
          variant={"bodyMobile"}
          data-cy={`${status}-badge`}
        >
          <div>
            <Icon name={currentStatus.icon} size={11} />
          </div>
          {currentStatus.label}
        </StyledBadge>
      </div>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: inline-flex;

  & > * {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${themeProp("palette.gray2")};
    gap: 24px;
  }
`;

const StyledBadge = styled(Badge)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px !important;

  svg {
    transform: translateY(-0.5px);
  }
`;

Status.propTypes = {
  status: PropTypes.oneOf([
    "pending",
    "started",
    "completed",
    "failed",
    "connected",
    "notConnected"
  ]).isRequired
};

export default Status;
