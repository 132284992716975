import React, { useContext, useState } from "react";
import { SettingsContext } from "./SettingsProvider";
import PropTypes from "prop-types";
import MetricTypes from "../utils/metric-types";
import KPIImpactFormats from "../utils/kpi-impact-formats";
import { applyStringTemplate, formatRangeNumber } from "../utils/formatting";
import { Typography } from "antd";
import { useStringTemplate } from "./StringProvider";
import styled from "styled-components";
import Tooltip from "./Tooltip";
const { Paragraph } = Typography;

const RangeWrapper = ({ type, fullText, tooltip, children }) => {
  const [truncated, setTruncated] = useState(false);

  const onEllipsis = () => {
    setTruncated(true);
  };

  const renderTooltip = () => {
    if (!truncated || !tooltip) {
      return undefined;
    }

    return fullText ? (
      <>
        {fullText}
        <br />
        <br />
        {tooltip}
      </>
    ) : (
      tooltip
    );
  };

  if (type === KPIImpactFormats.CUSTOM) {
    return (
      <Tooltip2
        className={truncated ? "hasTooltip" : undefined}
        title={renderTooltip()}
      >
        <Paragraph
          style={{ color: "inherit" }}
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: <></>,
            tooltip: truncated && !tooltip ? fullText : undefined,
            onEllipsis
          }}
        >
          {children}
        </Paragraph>
      </Tooltip2>
    );
  }

  return tooltip || truncated ? (
    <Tooltip2 title={tooltip} className={truncated ? "hasTooltip" : undefined}>
      {children}
    </Tooltip2>
  ) : (
    children
  );
};

const Tooltip2 = styled(Tooltip)`
  &.hasTooltip {
    cursor: help;
  }
`;

const Range = ({
  first,
  second,
  type = "Percentage",
  currency,
  customImpactFormat,
  impactPercentageFormulaDescription
}) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const rangeTemplate = useStringTemplate(
    "discovery.kpi.impact.range",
    "{low} to {high}"
  );

  if (first === second) {
    return (
      <>
        {formatRangeNumber({
          value: first,
          type,
          options: { locale, currency, showUnits: true, customImpactFormat }
        })}
      </>
    );
  }

  const low = formatRangeNumber({
    value: first,
    type,
    options: { locale, currency, showUnits: false, customImpactFormat }
  });

  const high = formatRangeNumber({
    value: second,
    type,
    options: { locale, currency, showUnits: true, customImpactFormat }
  });

  const range = applyStringTemplate({
    template: rangeTemplate,
    placeholders: {
      low,
      high
    }
  });

  let fullText = "";
  if (customImpactFormat) {
    fullText = range;
  }

  return (
    <RangeWrapper
      type={type}
      fullText={fullText}
      tooltip={impactPercentageFormulaDescription}
    >
      {range}
    </RangeWrapper>
  );
};

Range.propTypes = {
  first: PropTypes.number.isRequired,
  second: PropTypes.number.isRequired,
  type: PropTypes.oneOf([...MetricTypes.ALL, ...KPIImpactFormats.ALL])
};

export default Range;
