import types from "../actionTypes";

const INITIAL_STATE = {
  openNotificationData: null,
  closeNotificationKey: ""
};

const notificationMessageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.NOTIFICATION_MESSAGE_OPEN:
      return {
        ...state,
        openNotificationData: action.payload,
        closeNotificationKey: ""
      };
    case types.NOTIFICATION_MESSAGE_CLOSE:
      return {
        ...state,
        closeNotificationKey: action.payload?.key
      };

    default:
      return state;
  }
};

export default notificationMessageReducer;

export const selectOpenNotificationData = (notificationMessageData) =>
  notificationMessageData.openNotificationData;
export const selectCloseNotificationKey = (notificationMessageData) =>
  notificationMessageData.closeNotificationKey;
