import ExternalHighlighter from "react-highlight-words";
import styled from "styled-components";
import { themeProp } from "../theme";
import PropTypes from "prop-types";

const Highlight = ({ children, color, fontWeight }) => (
  <Mark color={color} fontWeight={fontWeight}>
    {children}
  </Mark>
);

const Highlighter = ({ color, fontWeight, ...props }) => {
  return (
    <ExternalHighlighter
      highlightTag={(props) => (
        <Highlight color={color} fontWeight={fontWeight} {...props} />
      )}
      autoEscape={true}
      {...props}
    />
  );
};

const Mark = styled.span`
  padding: 0;
  background-color: ${(props) => {
    switch (props.color) {
      case "transparent":
        return "transparent";
      case "highlight":
      default:
        return themeProp("palette.highlight");
    }
  }};
  font-weight: ${(props) => props.fontWeight};
`;

Highlighter.propTypes = {
  color: PropTypes.oneOf(["transparent", "highlight"]),
  fontWeight: PropTypes.oneOf(["normal", "bold"])
};

Highlighter.defaultProps = {
  color: "highlight",
  fontWeight: "normal"
};

export default Highlighter;
