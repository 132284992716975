import React, { useState, useEffect } from "react";
import { Text } from "../../components";
import { compose } from "redux";
import { connect } from "react-redux";
import Input, { PasswordInput } from "../../components/Input";
import Heading from "../../components/Heading";
import Form, { FormItem } from "../../components/Form";
import NewButton from "../../components/NewButton";
import { useString as s } from "../../components/StringProvider";
import { selectRequestError } from "../../store/reducers";
import LoginFormContainer, {
  FormContainer,
  ResendContainer
} from "../Login/LoginFormContainer";
import ButtonGroup from "../../components/ButtonGroup";
import actionTypes from "../../store/actionTypes";
import Markdown from "../../components/Discovery/Markdown";
import Hyperlink from "../../components/Hyperlink";

const ConfirmAccountForm = ({
  onFinish,
  onResendCode,
  form,
  email,
  confirmSignUpError
}) => {
  const header = s("confirm.account.page.header", "Create Account");
  const subheader = s(
    "confirm.account.page.subheader",
    "We sent a code to **{email}**. Please check your inbox, enter the code below, and create a password for your account.",
    { email }
  );
  const resendCode = s("cconfirm.account.resendCode", "Resend");
  const verificationCodeValidation = s(
    "confirm.account.verificationCode.validation",
    "Please input your verification code"
  );
  const verificationCodePlaceholder = s(
    "confirm.account.verificationCode.placeholder",
    "Code from email"
  );
  const passwordValidationError = s(
    "confirm.account.password.validation",
    "Please enter a password of at least 8 characters"
  );
  const passwordPlaceholder = s(
    "confirm.account.password.placeholder",
    "Password"
  );
  const confirmPasswordValidationError = s(
    "confirm.account.confirmPassword.validation",
    "Please confirm your password"
  );
  const confirmPasswordMismatchError = s(
    "confirm.account.confirmPassword.mismatch",
    "Confirm password must match password"
  );
  const confirmPasswordPlaceholder = s(
    "confirm.account.confirmPassword.placeholder",
    "Confirm password"
  );
  const createAccountButton = s("confirm.account.button", "Create account");
  const haventReceived = s(
    "confirm.account.haventReceived",
    "Haven’t received the verification code?"
  );
  const privacyText = s(
    "confirm.account.privacy.text",
    "By signing up, you’re agreeing to our"
  );
  const privacyPolicyText = s(
    "confirm.account.privacy.policy",
    "Privacy Policy"
  );
  const confirmSignUpErrors = {
    "confirm.account.invalidCode": s(
      "confirm.account.invalidCode",
      "Sorry, this code is incorrect."
    ),
    "confirm.account.expiredCode": s(
      "error.registerUser.expiredCode",
      "Sorry, this code has expired."
    )
  };
  const defaultConfirmSignUpError = s(
    "confirm.account.failed",
    "Sorry, confirmation failed."
  );

  const [error, setError] = useState("");

  useEffect(() => {
    if (confirmSignUpError) {
      setError(
        confirmSignUpErrors[confirmSignUpError?.data?.message] ||
          defaultConfirmSignUpError
      );
    }
  }, [confirmSignUpError]);

  return (
    <LoginFormContainer>
      <FormContainer>
        <Heading level={"h2"}>{header}</Heading>
        <div className={"subheader"} data-cy={"page-subheader"}>
          <Markdown remarkPlugins={[]}>{subheader}</Markdown>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          className={"login"}
          layout={"vertical"}
        >
          <FormItem
            name="code"
            rules={[{ required: true, message: verificationCodeValidation }]}
          >
            <Input placeholder={verificationCodePlaceholder} />
          </FormItem>

          <FormItem
            name="password"
            rules={[
              {
                required: true,
                message: passwordValidationError
              },
              {
                min: 8,
                message: passwordValidationError
              }
            ]}
            hasFeedback
          >
            <PasswordInput
              autoComplete="new-password"
              placeholder={passwordPlaceholder}
            />
          </FormItem>

          <FormItem
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: confirmPasswordValidationError
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(confirmPasswordMismatchError)
                  );
                }
              })
            ]}
            hasFeedback
            dependencies={["password"]}
          >
            <PasswordInput
              autoComplete="new-password"
              placeholder={confirmPasswordPlaceholder}
            />
          </FormItem>
          <div>
            <Text variant={"bodyMobile"} color="gray4">
              {privacyText}{" "}
              <Hyperlink
                to={"https://cuvama.com/privacy-policy/"}
                target={"_blank"}
              >
                {privacyPolicyText}
              </Hyperlink>
            </Text>
          </div>
          {!!error && (
            <div className={"error-wrapper"}>
              <Text color={"error"}>{error}</Text>
            </div>
          )}
          <ButtonGroup mobile={true}>
            <NewButton type={"submit"} data-cy={"verify-code-button"}>
              {createAccountButton}
            </NewButton>
          </ButtonGroup>
        </Form>
        <ResendContainer>
          {haventReceived}{" "}
          <span>
            <NewButton
              type={"text"}
              onClick={onResendCode}
              data-cy={"resend-code-button"}
            >
              {resendCode}
            </NewButton>
          </span>
        </ResendContainer>
      </FormContainer>
    </LoginFormContainer>
  );
};

function mapStateToProps(state) {
  return {
    confirmSignUpError: selectRequestError(
      state,
      actionTypes.CONFIRM_USER_REQUEST
    )
  };
}

export default compose(connect(mapStateToProps))(ConfirmAccountForm);
