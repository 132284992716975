import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "../../../../components";
import { TruncatedParagraph } from "../../../../components/Typography";
import Highlighter from "../../../../components/Highlighter";

const UserValue = ({ searchTerm, value }) => {
  return !value ? (
    "-"
  ) : searchTerm ? (
    <Tooltip title={value} placement={"left"}>
      <Highlighter textToHighlight={value} searchWords={[searchTerm]} />
    </Tooltip>
  ) : (
    <TruncatedParagraph fullText={value} rows={1.1} variant={"bodyMobile"}>
      {value}
    </TruncatedParagraph>
  );
};

UserValue.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default UserValue;
