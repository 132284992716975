import { Button } from "antd";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { themeProp } from "../theme";
import React from "react";
import Tooltip from "./Tooltip";
import classNames from "classnames";

const NewButton = React.forwardRef(
  (
    {
      type,
      color,
      tooltip,
      children,
      tooltipPlacement,
      active,
      className,
      ...props
    },
    ref
  ) => {
    const tooltipProps = typeof tooltip === "object" ? tooltip : undefined;
    const title = typeof tooltip === "object" ? tooltip?.title : tooltip;
    const _classNames = classNames(
      {
        "btn-active": active
      },
      className
    );

    return tooltip ? (
      <Tooltip
        title={title}
        trigger={["hover", "focus"]}
        placement={tooltipPlacement}
        {...tooltipProps}
      >
        <StyledButton
          type={type}
          color={color}
          ref={ref}
          htmlType={type === "submit" ? "submit" : undefined}
          className={_classNames}
          aria-label={tooltip}
          {...props}
        >
          {children}
        </StyledButton>
      </Tooltip>
    ) : (
      <StyledButton
        type={type}
        color={color}
        ref={ref}
        htmlType={type === "submit" ? "submit" : undefined}
        className={_classNames}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
);

const StyledButton = styled(Button).withConfig({
  shouldForwardProp: (prop) =>
    !["wrapText", "dispatch", "staticContext"].includes(prop)
})`
  white-space: ${({ wrapText }) => (wrapText ? "normal" : "nowrap")};
  border-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  height: fit-content;
  width: fit-content;

  &.ant-btn span {
    display: inline-flex;
    align-items: center;
  }

  svg path {
    fill: currentColor;
  }

  ${({ disabledColor }) =>
    disabledColor &&
    css`
      color: transparent !important;
      background-color: transparent !important;

      &:hover {
        color: transparent !important;
        background-color: transparent !important;
      }
    `}

  &[disabled] {
    cursor: not-allowed;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    color: ${({ type }) =>
      themeProp(`components.newButton.${type}.disabled`)} !important;
    background-color: ${({ type }) =>
      themeProp(`components.newButton.${type}.backgroundBase`)} !important;
  }

  &.ant-btn-loading {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    background-color: ${({ type }) =>
      themeProp(`components.newButton.${type}.backgroundBase`)} !important;
    opacity: 1 !important;

    span.ant-btn-icon.ant-btn-loading-icon {
      margin-inline-end: 0px;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      color: ${({ type }) =>
        themeProp(`components.newButton.${type}.base`)} !important;
    }
  }

  &.ant-btn-primary,
  &.ant-btn-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 6px;
    padding: 14px 48px;
    color: ${themeProp(`components.newButton.primary.base`)};
    background-color: ${themeProp(
      `components.newButton.primary.backgroundBase`
    )};

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.primary.clicked`)};
      background-color: ${themeProp(
        `components.newButton.primary.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.primary.hover`)};
      background-color: ${themeProp(
        `components.newButton.primary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.primary.hover`)};
      background-color: ${themeProp(
        `components.newButton.primary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-tertiary {
    border: 1px solid transparent;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.body.fontWeight`)};
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 6px;
    padding: 0px 12px;
    height: 40px;
    color: ${themeProp(`components.newButton.tertiary.base`)};
    background-color: ${themeProp(
      `components.newButton.tertiary.backgroundBase`
    )};

    svg {
      color: ${themeProp(`components.newButton.tertiary.icon`)};
      padding-bottom: 1px;
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.tertiary.clicked`)};
      background-color: ${themeProp(
        `components.newButton.tertiary.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.tertiary.hover`)};
      background-color: ${themeProp(
        `components.newButton.tertiary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.tertiary.hover`)};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;

      svg {
        color: ${themeProp(`components.newButton.tertiary.hover`)};
      }
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-primaryDropdown {
    border: 1px solid transparent;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 6px;
    padding: 9px 16px;
    color: ${themeProp(`components.newButton.primary.base`)};
    background-color: ${themeProp(
      `components.newButton.primary.backgroundBase`
    )};
    vertical-align: middle;

    & svg {
      display: block;
      margin: 0 auto;
    }

    & > span:last-child:not(:first-child) {
      margin-left: 8px;
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.primary.clicked`)};
      background-color: ${themeProp(
        `components.newButton.primary.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.primary.hover`)};
      background-color: ${themeProp(
        `components.newButton.primary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.primary.hover`)};
      background-color: ${themeProp(
        `components.newButton.primary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-secondaryDropdown {
    border: 1px solid transparent;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 6px;
    padding: 9px 16px;
    color: ${themeProp(`components.newButton.secondary.base`)};
    background-color: ${themeProp(
      `components.newButton.secondary.backgroundBase`
    )};
    vertical-align: middle;

    & svg {
      display: block;
      margin: 0 auto;
    }

    & > span:last-child:not(:first-child) {
      margin-left: 8px;
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.secondary.clicked`)};
      background-color: ${themeProp(
        `components.newButton.secondary.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.secondary.hover`)};
      background-color: ${themeProp(
        `components.newButton.secondary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.secondary.hover`)};
      background-color: ${themeProp(
        `components.newButton.secondary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-secondary {
    border: 1px solid transparent;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 6px;
    padding: 14px 16px;
    color: ${themeProp(`components.newButton.text.base`)};
    background-color: ${themeProp(`components.newButton.text.backgroundBase`)};
    box-shadow: none;

    & svg {
      display: block;
      margin: 0 auto;
    }

    & > span:last-child:not(:first-child) {
      margin-left: 8px;
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.text.clicked`)};
      background-color: ${themeProp(
        `components.newButton.text.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.text.hover`)};
      background-color: ${themeProp(
        `components.newButton.text.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.text.hover`)};
      background-color: ${themeProp(
        `components.newButton.text.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-secondaryDarkText {
    border: 1px solid transparent;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.body.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 4px;
    padding: 10px 8px;
    color: ${themeProp(`components.newButton.secondaryDarkText.base`)};
    background-color: ${themeProp(
      `components.newButton.secondaryDarkText.backgroundBase`
    )};
    box-shadow: none;

    & svg {
      display: block;
      margin: 0 auto;
      color: ${themeProp(`components.newButton.secondaryDarkText.icon`)};
    }

    & > span:last-child:not(:first-child) {
      margin-left: 8px;
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.secondaryDarkText.clicked`)};
      background-color: ${themeProp(
        `components.newButton.secondaryDarkText.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.secondaryDarkText.hover`)};
      background-color: ${themeProp(
        `components.newButton.secondaryDarkText.backgroundHover`
      )};
      border-color: transparent;

      & svg {
        color: ${themeProp(`components.newButton.secondaryDarkText.hover`)};
      }
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.secondaryDarkText.base`)};
      background-color: ${themeProp(
        `components.newButton.secondaryDarkText.focus`
      )};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-uploadLogo {
    border: 1px dashed ${themeProp("palette.secondary")};
    border-radius: 0;
    height: 40px;
    width: 140px;
    ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    color: ${themeProp(`palette.secondary`)};
    background-color: ${(props) =>
      themeProp(`components.newButton.${props.type}.backgroundBase`)};

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.clicked`)};
      border-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.clicked`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundClicked`)};
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      border-color: ${({ type }) =>
        themeProp(`components.newButton.${type}.disabled`)};
    }
  }

  &.ant-btn-editLogo {
    border: 1px solid transparent;
    border-radius: 0;
    height: 40px;
    width: 140px;
    opacity: 0;
    ${themeProp(`components.newButton.uploadLogo.base`)};
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    color: ${themeProp(`palette.secondary`)};
    background-color: ${themeProp(
      `components.newButton.uploadLogo.backgroundBase`
    )};

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.uploadLogo.clicked`)};
      opacity: 0.99;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.uploadLogo.hover`)};
      opacity: 0.99;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.uploadLogo.hover`)};
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      border-color: ${themeProp(`components.newButton.uploadLogo.disabled`)};
    }
  }

  &.ant-btn-alternate {
    border: 1px solid
      ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 6px;
    padding: 14px 48px;
    color: ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    background-color: ${(props) =>
      themeProp(`components.newButton.${props.type}.backgroundBase`)};

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.clicked`)};
      border-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.clicked`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundClicked`)};
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      border-color: ${({ type }) =>
        themeProp(`components.newButton.${type}.disabled`)};
    }
  }

  &.ant-btn-toggle {
    border: 1px solid
      ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    border-color: ${() => themeProp(`palette.gray1`)};
    color: ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 4px;
    width: ${(props) => (props.width ? props.width : "100%")};
    padding: 14px 48px;

    overflow: hidden;
    white-space: nowrap;

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      background-color: ${() => themeProp(`palette.surface`)} !important;
      border: 1px solid
        ${(props) => themeProp(`components.newButton.${props.type}.base`)};
      color: ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    }
  }

  &.ant-btn-text {
    font-size: ${(props) =>
      props.size === "small"
        ? themeProp(`typography.small.fontSize`)
        : themeProp(`typography.button.fontSize`)};
    line-height: ${(props) =>
      props.size === "small"
        ? themeProp(`typography.small.lineHeight`)
        : themeProp(`typography.button.lineHeight`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    border-radius: 4px;
    color: ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    background-color: ${(props) =>
      themeProp(`components.newButton.${props.type}.backgroundBase`)};
    padding: 8px;

    & svg {
      width: 12px;
    }

    & span:first-child svg {
      margin-right: 8px;
    }

    & span:not(:first-child) svg {
      margin-left: 8px;
    }

    &:active,
    &.sb-active {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.clicked`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundClicked`)};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not(:disabled),
    &.sb-hover {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 2px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-iconPrimary.btn-active,
  &.ant-btn-iconPrimary.btn-active:hover {
    color: ${themeProp(`components.newButton.primary.base`)} !important;
    background-color: ${themeProp(
      `components.newButton.primary.backgroundBase`
    )} !important;
  }

  &.ant-btn-iconPrimary,
  &.ant-btn-iconSecondary,
  &.ant-btn-iconDarkPrimary {
    border-radius: 4px;
    padding: 0 5px 0 5px;
    min-width: 26px;
    min-height: 26px;
    line-height: 14px;
    color: ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    background-color: ${(props) =>
      themeProp(`components.newButton.${props.type}.backgroundBase`)};
    box-shadow: none;

    &.ant-dropdown-open,
    &.ant-popover-open,
    &.open-comments-button-open,
    &:active,
    &.sb-active {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.clicked`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundClicked`)};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not(:disabled),
    &.sb-hover {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open):not(.ant-popover-open) {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 2px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-iconTextPrimary,
  &.ant-btn-iconTextSecondary {
    font-size: ${themeProp("typography.bMedium.fontSize")};
    line-height: ${themeProp("typography.body.lineHeight")};
    background-color: ${themeProp(
      `components.newButton.iconTextPrimary.backgroundBase`
    )};
    transition: none;

    svg {
      margin-right: 8px;
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled) {
      border-color: transparent;
      color: ${themeProp(`components.newButton.iconTextPrimary.hover`)};
      transition: none;

      svg {
        color: ${themeProp(`components.newButton.iconTextPrimary.hover`)};
      }
    }

    .ant-wave {
      display: none !important;
    }
  }

  &.ant-btn-iconTextSecondary {
    color: ${themeProp(`components.newButton.iconTextSecondary.base`)};

    svg {
      color: ${themeProp(`components.newButton.iconTextSecondary.icon`)};
      padding-bottom: 1px;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.iconTextSecondary.hover`)};
      background-color: ${themeProp(
        `components.newButton.iconTextSecondary.backgroundHover`
      )};
      border-color: transparent;

      svg {
        color: ${themeProp(`components.newButton.iconTextSecondary.hover`)};
      }
    }
  }

  &.ant-btn-iconTextTertiary {
    color: ${themeProp(`palette.text`)};
    background-color: ${themeProp(
      `components.newButton.iconTextTertiary.base`
    )};
    font-size: ${themeProp(`typography.bodyMobile.fontSize`)};
    font-weight: ${themeProp(`typography.body.fontWeight`)};
    line-height: ${themeProp(`typography.body.lineHeight`)};
    padding: 4px 12px;
    min-height: 40px;
    white-space: pre-line;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      color: ${themeProp(`components.newButton.iconTextTertiary.icon`)};
    }

    span {
      margin: 0;
      padding: 0;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.iconTextTertiary.hover`)};
      background-color: ${themeProp(
        `components.newButton.iconTextTertiary.backgroundHover`
      )};
      border-color: transparent;

      svg {
        color: ${themeProp(`components.newButton.iconTextTertiary.hover`)};
      }
    }
  }

  &.ant-btn-iconCanvas {
    border-radius: 4px;
    padding: 0;
    min-width: 26px;
    min-height: 26px;
    line-height: 14px;
    color: ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    background-color: ${(props) =>
      themeProp(`components.newButton.${props.type}.backgroundBase`)};
    box-shadow: none;

    &:hover:not(:disabled),
    &.sb-hover {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 2px ${themeProp(`palette.focus`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
    }
  }

  &.ant-btn-link {
    white-space: normal;
    word-wrap: break-word;
    border-radius: 0;
    padding: 0;
    color: ${(props) => themeProp(`components.newButton.${props.type}.base`)};
    background-color: ${(props) =>
      themeProp(`components.newButton.${props.type}.backgroundBase`)};
    box-shadow: none;

    &:hover:not(:disabled),
    &.sb-hover {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 2px ${themeProp(`palette.focus`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
    }
  }

  &.ant-popover-open .ant-tooltip {
    display: none;
  }

  &.ant-btn-darkPrimary {
    border: 1px solid transparent;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 4px;
    padding: 8px 32px;
    color: ${themeProp(`components.newButton.darkPrimary.base`)} !important;
    background-color: ${themeProp(
      `components.newButton.darkPrimary.backgroundBase`
    )};

    color: ${themeProp(`components.newButton.darkPrimary.base`)};
    background-color: ${themeProp(
      `components.newButton.darkPrimary.backgroundBase`
    )};

    &[disabled] {
      background-color: ${themeProp(
        `components.newButton.darkPrimary.backgroundDisabled`
      )};
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.darkPrimary.clicked`)};
      background-color: ${themeProp(
        `components.newButton.darkPrimary.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.darkPrimary.hover`)};
      background-color: ${themeProp(
        `components.newButton.darkPrimary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.darkPrimary.hover`)};
      background-color: ${themeProp(
        `components.newButton.darkPrimary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-darkSecondary {
    border: 1px solid transparent;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    border-radius: 4px;
    padding: 8px;
    color: ${themeProp(`components.newButton.darkSecondary.base`)};
    background-color: ${themeProp(
      `components.newButton.darkSecondary.backgroundBase`
    )};

    color: ${themeProp(`components.newButton.darkSecondary.base`)};
    background-color: ${themeProp(
      `components.newButton.darkSecondary.backgroundBase`
    )};

    & span:first-child svg {
      margin-right: 8px;
    }

    & span:not(:first-child) svg {
      margin-left: 8px;
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.darkSecondary.clicked`)};
      background-color: ${themeProp(
        `components.newButton.darkSecondary.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.darkSecondary.hover`)};
      background-color: ${themeProp(
        `components.newButton.darkSecondary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.darkSecondary.hover`)};
      background-color: ${themeProp(
        `components.newButton.darkSecondary.backgroundHover`
      )};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-feedback {
    border: 1px solid ${themeProp("palette.gray1")};
    border-radius: 8px;
    font-size: ${themeProp(`typography.button.fontSize`)};
    font-family: ${themeProp(`typography.button.fontFamily`)};
    font-weight: ${themeProp(`typography.button.fontWeight`)};
    line-height: ${themeProp(`typography.button.lineHeight`)};
    padding: 8px;
    color: ${themeProp(`components.newButton.feedback.base`)};
    background-color: ${themeProp(
      `components.newButton.feedback.backgroundBase`
    )};
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;

    & svg {
      display: block;
      margin: auto;
      width: 20px;
      height: 20px;
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.feedback.clicked`)};
      background-color: ${themeProp(
        `components.newButton.feedback.backgroundClicked`
      )};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.feedback.hover`)};
      background-color: ${themeProp(
        `components.newButton.feedback.backgroundHover`
      )};
      border-color: ${themeProp("palette.gray1")};
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.feedback.hover`)};
      background-color: ${themeProp(
        `components.newButton.feedback.backgroundHover`
      )};
      border-color: ${themeProp("palette.gray1")};
    }

    &:focus,
    &:hover {
      border-color: ${themeProp("palette.gray1")};
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-closeIcon {
    border-radius: 4px;
    padding: 0 5px 0 5px;
    min-width: 26px;
    min-height: 26px;
    line-height: 14px;
    color: ${themeProp(`components.newButton.body.base`)};
    background-color: transparent;
    box-shadow: none;

    &.ant-dropdown-open,
    &.ant-popover-open,
    &.open-comments-button-open,
    &:active,
    &.sb-active {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.clicked`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundClicked`)};
      border-color: transparent;
    }

    &:hover:not(:active):not(:focus):not(:disabled),
    &.sb-hover {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: transparent;
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open):not(.ant-popover-open) {
      color: ${(props) =>
        themeProp(`components.newButton.${props.type}.hover`)};
      background-color: ${(props) =>
        themeProp(`components.newButton.${props.type}.backgroundHover`)};
      border-color: transparent;
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 2px ${themeProp(`palette.focus`)};
    }
  }

  &.ant-btn-outlined {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid ${themeProp("palette.gray2")};
    font-size: ${themeProp(`typography.bodyMobile.fontSize`)};
    font-weight: ${themeProp(`typography.body.fontWeight`)};
    line-height: ${themeProp(`typography.body.lineHeight`)};
    border-radius: 6px;
    padding: 8px 12px;
    color: ${themeProp(`components.newButton.outlined.base`)};
    background-color: ${themeProp(
      `components.newButton.outlined.backgroundBase`
    )};

    span {
      margin: 0;
      padding: 0;
    }

    &:active:not([disabled]),
    &.sb-active:not([disabled]) {
      color: ${themeProp(`components.newButton.outlined.clicked`)};
      background-color: ${themeProp(
        `components.newButton.outlined.backgroundClicked`
      )};
      border-color: ${themeProp(`components.newButton.outlined.hover`)};
      svg {
        color: ${themeProp(`components.newButton.outlined.hover`)};
      }
    }

    &:hover:not(:active):not(:focus):not([disabled]),
    &.sb-hover {
      color: ${themeProp(`components.newButton.outlined.hover`)};
      background-color: ${themeProp(
        `components.newButton.outlined.backgroundHover`
      )};
      border-color: ${themeProp(`components.newButton.outlined.hover`)};
      svg {
        color: ${themeProp(`components.newButton.outlined.hover`)};
      }
    }

    &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
      color: ${themeProp(`components.newButton.outlined.hover`)};
      background-color: ${themeProp(
        `components.newButton.outlined.backgroundHover`
      )};
      border-color: ${themeProp(`components.newButton.outlined.hover`)};
      svg {
        color: ${themeProp(`components.newButton.outlined.hover`)};
      }
    }

    &:focus,
    &:hover {
      border-color: transparent;
    }

    &:focus-visible,
    &.sb-focus-visible {
      box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
    }
  }
`;

NewButton.propTypes = {
  type: PropTypes.oneOf([
    "primary",
    "primaryDropdown",
    "secondary",
    "secondaryDarkText",
    "secondaryDropdown",
    "tertiary",
    "iconPrimary",
    "iconDarkPrimary",
    "iconTextPrimary",
    "iconTextSecondary",
    "iconTextTertiary",
    "iconSecondary",
    "darkPrimary",
    "darkSecondary",
    "feedback",
    "closeIcon",
    "uploadLogo",
    "editLogo",
    "iconCanvas",
    "toggle",
    "submit",
    "alternate",
    "text",
    "link",
    "outlined"
  ]),
  size: PropTypes.oneOf(["small", "normal"]),
  wrapText: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledColor: PropTypes.bool,
  tooltip: PropTypes.any,
  tooltipPlacement: PropTypes.oneOf([
    "left",
    "right",
    "bottom",
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
    "leftTop",
    "leftBottom",
    "rightTop",
    "rightBottom"
  ])
};

NewButton.defaultProps = {
  type: "primary",
  size: "normal",
  wrapText: false
};

export default NewButton;
