import NewButton from "../NewButton";
import Icon from "../Icon";
import Text from "../Text";
import React from "react";
import styled from "styled-components";
import { themeProp } from "../../theme";
import { Space } from "antd";
import PropTypes from "prop-types";
import Highlighter from "../Highlighter";
import { useString as s } from "../StringProvider";
import { Tooltip } from "../index";

const ChallengeLibraryItem = ({ challenge, onAdd, searchTerm }) => {
  const toolTipText = s(
    "discovery.challengeLibrary.tooltip.addChallenge.text",
    "Add Challenge"
  );

  return (
    <Tooltip title={toolTipText} placement={"top"}>
      <ChallengeLibraryItemContainer
        data-cy={"challenge-library-item"}
        id={`challenge-library-item-${challenge.code}`}
        onClick={onAdd}
      >
        <Space direction={"horizontal"} size={16}>
          <NewButton type={"iconPrimary"}>
            <Icon
              data-cy={`challenge-library-plus-icon-${challenge.code}`}
              name={"plusCircle"}
              size={"large"}
              colour={"primary"}
            />
          </NewButton>
          <Text variant={"h4"} color={"text"}>
            {searchTerm ? (
              <Highlighter
                textToHighlight={challenge.name}
                searchWords={[searchTerm]}
              />
            ) : (
              <b>{challenge.name}</b>
            )}
          </Text>
        </Space>
      </ChallengeLibraryItemContainer>
    </Tooltip>
  );
};

const ChallengeLibraryItemContainer = styled.div`
  padding: 16px 24px 16px 24px;
  margin-bottom: 8px;
  border-style: solid;
  border-color: ${themeProp("palette.gray1")};
  border-width: 1px;
  border-radius: 8px;

  &:hover {
    background-color: ${themeProp(`components.newButton.text.backgroundHover`)};
    cursor: pointer;
  }
`;

ChallengeLibraryItem.propTypes = {
  challenge: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired
};

export default ChallengeLibraryItem;
