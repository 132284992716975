import ButtonGroup from "../../../../components/ButtonGroup";
import { hasSandbox, isSandbox } from "../../../../store/selectors/config";
import { bindActionCreators, compose } from "redux";
import {
  approvePromotion,
  rejectPromotion,
  requestPromotion
} from "../../../../store/actions/promotions";
import { connect } from "react-redux";
import React, { useState } from "react";
import actionTypes from "../../../../store/actionTypes";
import {
  hasPermission,
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import NewButton from "../../../../components/NewButton";
import Permissions from "../../../../utils/permissions";
import { useString as s } from "../../../../components/StringProvider";
import RequestPromotionModal from "./RequestPromotionModal";
import ApprovePromotionModal from "./ApprovePromotionModal";
import RejectPromotionModal from "./RejectPromotionModal";
import useLoadingState from "../../../../utils/use-loading-state";
import { message } from "antd";

const PromotionButtons = ({
  requestPromotion,
  approvePromotion,
  rejectPromotion,
  promotion,
  canPublishPromotion,
  canRequestPromotion,
  requestLoadingState,
  approveLoadingState,
  rejectLoadingState
}) => {
  const request = s(
    "organization.page.currentConfiguration.requestPromotion",
    "Request Promotion"
  );
  const requestSent = s(
    "organization.page.currentConfiguration.requestSent",
    "Request Sent"
  );
  const approve = s(
    "organization.page.currentConfiguration.approve",
    "Approve"
  );
  const requestPromotionFailed = s(
    "organization.page.currentConfiguration.messages.requestPromotionFailed",
    "Failed to request promotion"
  );
  const requestPromotionSucceeded = s(
    "organization.page.currentConfiguration.messages.requestPromotionSucceeded",
    "We sent your request for a configuration promotion. Check the configuration status later."
  );
  const approvePromotionFailed = s(
    "organization.page.currentConfiguration.messages.approvePromotionFailed",
    "Failed to approve promotion."
  );
  const approvePromotionSucceeded = s(
    "organization.page.currentConfiguration.messages.approvePromotionSucceeded",
    "Configuration update is underway."
  );
  const rejectPromotionFailed = s(
    "organization.page.currentConfiguration.messages.rejectPromotionFailed",
    "Failed to reject promotion."
  );
  const reject = s("organization.page.currentConfiguration.reject", "Reject");
  const [showRequestPromotionModal, setShowRequestPromotionModal] =
    useState(false);
  const [showApprovePromotionModal, setShowApprovePromotionModal] =
    useState(false);
  const [showRejectPromotionModal, setShowRejectPromotionModal] =
    useState(false);

  useLoadingState(
    requestLoadingState,
    () => {
      setShowRequestPromotionModal(false);
      message.success(requestPromotionSucceeded);
    },
    () => {
      message.error(requestPromotionFailed);
    }
  );

  useLoadingState(
    approveLoadingState,
    () => {
      setShowApprovePromotionModal(false);
      message.success(approvePromotionSucceeded);
    },
    () => {
      message.error(approvePromotionFailed);
    }
  );

  useLoadingState(
    rejectLoadingState,
    () => {
      setShowRejectPromotionModal(false);
    },
    () => {
      message.error(rejectPromotionFailed);
    }
  );

  const onRequestPromotion = () => {
    setShowRequestPromotionModal(true);
  };

  const onConfirmRequestPromotion = () => {
    requestPromotion();
  };

  const onCancelRequestPromotion = () => {
    setShowRequestPromotionModal(false);
  };

  const onApprovePromotion = () => {
    setShowApprovePromotionModal(true);
  };

  const onConfirmApprovePromotion = () => {
    approvePromotion({ promotionId: promotion._id });
  };

  const onCancelApprovePromotion = () => {
    setShowApprovePromotionModal(false);
  };

  const onRejectPromotion = () => {
    setShowRejectPromotionModal(true);
  };

  const onConfirmRejectPromotion = ({ rejectionReason }) => {
    rejectPromotion({ promotionId: promotion._id, rejectionReason });
  };

  const onCancelRejectPromotion = () => {
    setShowRejectPromotionModal(false);
  };

  return (
    <>
      <ButtonGroup>
        {!promotion && canRequestPromotion && (
          <NewButton type={"primary"} onClick={onRequestPromotion}>
            {request}
          </NewButton>
        )}
        {promotion && canRequestPromotion && !canPublishPromotion && (
          <NewButton type={"primary"} disabled>
            {requestSent}
          </NewButton>
        )}
        {promotion && canPublishPromotion && (
          <>
            <NewButton type={"primary"} onClick={onApprovePromotion}>
              {approve}
            </NewButton>
            <NewButton type={"secondary"} onClick={onRejectPromotion}>
              {reject}
            </NewButton>
          </>
        )}
      </ButtonGroup>
      {showRequestPromotionModal && (
        <RequestPromotionModal
          onConfirm={onConfirmRequestPromotion}
          onClose={onCancelRequestPromotion}
        />
      )}
      {showApprovePromotionModal && (
        <ApprovePromotionModal
          onConfirm={onConfirmApprovePromotion}
          onClose={onCancelApprovePromotion}
        />
      )}
      {showRejectPromotionModal && (
        <RejectPromotionModal
          onConfirm={onConfirmRejectPromotion}
          onClose={onCancelRejectPromotion}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  hasSandbox: hasSandbox(state),
  isSandbox: isSandbox(state),
  promotion: selectRequestData(state, actionTypes.GET_ACTIVE_PROMOTION_REQUEST),
  canPublishPromotion: hasPermission(state, Permissions.PUBLISH_PROMOTION),
  canRequestPromotion: hasPermission(state, Permissions.REQUEST_PROMOTION),
  requestLoadingState: selectRequestState(
    state,
    actionTypes.REQUEST_PROMOTION_REQUEST
  ),
  approveLoadingState: selectRequestState(
    state,
    actionTypes.APPROVE_PROMOTION_REQUEST
  ),
  rejectLoadingState: selectRequestState(
    state,
    actionTypes.REJECT_PROMOTION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestPromotion,
      approvePromotion,
      rejectPromotion
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PromotionButtons
);
