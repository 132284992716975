import Heading from "../../Heading";
import KPIMappingsTable from "./KPIMappingsTable";
import { useString as s } from "../../StringProvider";
import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import PropType from "prop-types";
import { FormItem } from "../../Form";
import SelectLinkedKPIs from "../SelectLinkedKPIs";
import { useMobileMediaQuery } from "../../Responsive";

const ChallengeTable = ({ discoveryChallenge, form }) => {
  const isMobile = useMobileMediaQuery();
  const linkedKPIText = s(
    "discovery.challenges.card.kpi.column.header",
    "Linked Outcomes"
  );

  return (
    <StyledTable removeHeaderStyle={true}>
      <thead>
        <tr>
          <th>
            <Heading level={"h5"}>{linkedKPIText}</Heading>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {form ? (
              <FormItem
                name="kpiMappings"
                style={isMobile ? undefined : { maxWidth: "260px" }}
              >
                <SelectLinkedKPIs />
              </FormItem>
            ) : (
              <KPIMappingsTable
                kpiMappings={
                  discoveryChallenge && discoveryChallenge.definition
                    ? discoveryChallenge.definition.kpiMappings
                    : []
                }
              />
            )}
          </td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;
  flex-grow: 1;

  & th {
    text-transform: uppercase;
    vertical-align: bottom;

    h5 {
      color: ${themeProp("palette.gray3")};
    }
  }

  & th:nth-child(2) {
  }

  & th:nth-child(3) {
    h5 {
      text-align: right;
    }
  }

  & tbody {
    tr {
      td {
        font-family: ${themeProp("typography.small.fontFamily")};
        font-size: ${themeProp("typography.small.fontSize")};
        line-height: ${themeProp("typography.small.lineHeight")};
        font-weight: ${themeProp("typography.small.fontWeight")};
        vertical-align: top;
      }

      td:nth-child(3) {
        text-align: right;
      }
    }
  }
`;

ChallengeTable.propTypes = {
  discoveryChallenge: PropType.object,
  form: PropType.bool
};

ChallengeTable.defaultProps = {
  onChangeImportance: () => {}
};

export default ChallengeTable;
