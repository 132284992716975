import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import PropType from "prop-types";
import Text from "../../Text";
import Heading from "../../Heading";
import { useString as s } from "../../StringProvider";
import Form, { FormItem } from "../../Form";
import NewButton from "../../NewButton";
import ButtonGroup from "../../ButtonGroup";
import KPISlider from "../KPICard/KPISlider";
import FormulaValue from "../FormulaValue";
import EstimationScenarioTypes from "../../../utils/estimation-scenario-types";
import ToText from "../../../Pages/ValueHypothesis/components/Statistics/ToText";
import Icon from "../../Icon";
import { themeProp } from "../../../theme";
import MetricInput from "./MetricInput";
import getKPIsImpactedByMetrics from "../../../utils/get-kpis-impacted-by-metrics";
import useLoadingState from "../../../utils/use-loading-state";
import { message } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  selectDiscovery,
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectRequestState
} from "../../../store/reducers";
import actionTypes from "../../../store/actionTypes";
import {
  commitPreviewDiscoveryKPI,
  previewDiscoveryKPI
} from "../../../store/actions/discoveries";
import KPITypes from "../../../utils/kpi-types";

const kpiTypesToNotDisplay = [
  KPITypes.FIXED_PERCENT,
  KPITypes.METRIC_DRIVEN,
  KPITypes.UNQUANTIFIED
];

const SLIDER_DEBOUNCE_TIMEOUT = 1000;

const EditQuantifiedKPIForm = ({
  discoveryKPI,
  currency,
  onClose,
  formulaDescription,
  commitPreviewDiscoveryKPI,
  commitPreviewDiscoveryKPILoadingState,
  previewDiscoveryKPI,
  discoveryId,
  discovery
}) => {
  const okText = s("editQuantifiedKPIValue.popup.success.button", "Save");
  const cancelText = s("editQuantifiedKPIValue.popup.cancel.button", "Cancel");
  const valueText = s("editQuantifiedKPIValue.popup.value", "Value");
  const formulaText = s("editQuantifiedKPIValue.popup.formula", "Formula");
  const inputToggleText = s(
    "editQuantifiedKPIValue.popup.input.toggle",
    "Edit Inputs"
  );
  const impactToggleText = s(
    "editQuantifiedKPIValue.popup.impact.toggle",
    "Changes to the Inputs will also affect"
  );
  const saveErrorText = s(
    "editQuantifiedKPIValue.popup.save.error",
    "Failed to save the outcome updates"
  );
  const successText = s(
    "editQuantifiedKPIValue.popup.save.success",
    "The outcome was successfully updated"
  );

  const [isInputOpen, setInputOpen] = useState(false);
  const [isImpactOpen, setImpactOpen] = useState(false);
  const [kpiChanges, setKPIChanges] = useState({});
  const [metricChanges, setMetricChanges] = useState([]);

  const hasChanges = () => {
    return !!metricChanges.length || !!Object.keys(kpiChanges).length;
  };

  const debouncePreviewDiscoveryKPI = useCallback(
    _.debounce(previewDiscoveryKPI, SLIDER_DEBOUNCE_TIMEOUT, {
      trailing: true
    }),
    []
  );

  const onCommit = () => {
    previewDiscoveryKPI({
      discoveryId: discoveryId,
      kpiCode: discoveryKPI.kpiCode,
      kpiChanges: kpiChanges,
      metricChanges: metricChanges
    });
  };

  const onValuesChange = () => {
    debouncePreviewDiscoveryKPI({
      discoveryId: discoveryId,
      kpiCode: discoveryKPI.kpiCode,
      kpiChanges: kpiChanges,
      metricChanges: metricChanges
    });
  };

  useEffect(() => {
    if (Object.keys(kpiChanges).length || metricChanges.length) {
      onValuesChange();
    }
  }, [kpiChanges, metricChanges]);

  useLoadingState(
    commitPreviewDiscoveryKPILoadingState,
    () => {
      onClose();
      message.success(successText);
    },
    () => {
      message.error(saveErrorText);
    }
  );

  const onToggleInput = () => {
    setInputOpen(!isInputOpen);
    setImpactOpen(false);
  };

  const onToggleImpact = () => {
    setImpactOpen(!isImpactOpen);
    setInputOpen(false);
  };

  const onKPISliderChange = async ({ changes }) => {
    setKPIChanges(changes);
  };

  const onMetricChange = async ({ metricCode, value }) => {
    let metricChange = metricChanges.find((m) => m.metricCode === metricCode);
    if (!metricChange) {
      metricChange = { metricCode, value };
    } else {
      metricChange.value = value;
    }

    const newMetricChanges = metricChanges
      .filter((m) => m.metricCode !== metricCode)
      .concat(metricChange);
    setMetricChanges(newMetricChanges);
  };

  const onSave = () => {
    commitPreviewDiscoveryKPI({
      discoveryId: discoveryId,
      kpiCode: discoveryKPI.kpiCode,
      kpiChanges: kpiChanges,
      metricChanges: metricChanges
    });
  };

  const impactedKPIs = getKPIsImpactedByMetrics({
    discoveryKPI,
    discoveryKPIs: discovery?.kpis
  });

  const valueSection = (
    <>
      {!!discoveryKPI.lowImpactValue && !!discoveryKPI.potentialImpactValue && (
        <FormSection>
          <Heading level={"h5"} color={"gray4"}>
            {valueText}
          </Heading>
          <Text variant={"h4"} data-cy={"editQuantifiedKPIValue-value-section"}>
            {discoveryKPI.lowImpactValue !==
            discoveryKPI.potentialImpactValue ? (
              <>
                <FormulaValue
                  estimationScenario={EstimationScenarioTypes.LOW}
                  discoveryKPI={discoveryKPI}
                  currency={currency}
                  format={"long"}
                  showTooltip={false}
                  disableFormulaPanel
                >
                  {discoveryKPI.lowImpactValue}
                </FormulaValue>
                <ToText />
                <FormulaValue
                  estimationScenario={EstimationScenarioTypes.POTENTIAL}
                  discoveryKPI={discoveryKPI}
                  currency={currency}
                  format={"long"}
                  showTooltip={false}
                  disableFormulaPanel
                >
                  {discoveryKPI.potentialImpactValue}
                </FormulaValue>
              </>
            ) : (
              <FormulaValue
                estimationScenario={EstimationScenarioTypes.LOW}
                discoveryKPI={discoveryKPI}
                currency={currency}
                format={"long"}
                showTooltip={false}
                disableFormulaPanel
              >
                {discoveryKPI.lowImpactValue}
              </FormulaValue>
            )}
          </Text>
        </FormSection>
      )}
    </>
  );

  const formulaSection = (
    <>
      {!!formulaDescription?.length && (
        <FormSection data-cy={"editQuantifiedKPIValue-formula-section"}>
          <Heading level={"h5"} color={"gray4"}>
            {formulaText}
          </Heading>
          {formulaDescription.map((item, index) => (
            <Text
              key={`${discoveryKPI.kpiCode}-${index}`}
              variant={item.type === "formula" ? "bMedium" : "body"}
              style={{ whiteSpace: "pre-line" }}
            >
              {item.text}{" "}
            </Text>
          ))}
        </FormSection>
      )}
    </>
  );

  const sliderSection = kpiTypesToNotDisplay.includes(
    discoveryKPI?.definition?.type
  ) ? null : (
    <FormSection data-cy={"editQuantifiedKPIValue-slider-section"}>
      {discoveryKPI?.definition?.rangeMetric && (
        <Heading level={"h5"} color={"gray4"}>
          {discoveryKPI.definition.rangeMetric}
        </Heading>
      )}
      <KPISliderContainer>
        <KPISlider
          kpi={discoveryKPI}
          currency={currency}
          disabled={false}
          onChange={onKPISliderChange}
        />
      </KPISliderContainer>
    </FormSection>
  );

  const metricMappings = discoveryKPI?.definition?.metricMappings;

  const inputsSection = (
    <>
      {!!metricMappings?.length && (
        <ToggleSection>
          <FormSection>
            <ToggleInput
              onClick={onToggleInput}
              data-cy={"editQuantifiedKPIValue-input-toggle"}
            >
              <Text color={"primary"} variant={"h4"}>
                {inputToggleText}
              </Text>
              <Icon
                name={isInputOpen ? "angleUp" : "angleDown"}
                colour={"primary"}
              />
            </ToggleInput>
          </FormSection>
          {isInputOpen && !!metricMappings.length && (
            <MetricInputSection>
              {metricMappings.map((metricCode, index) => (
                <MetricInput
                  key={index}
                  metricCode={metricCode}
                  currency={currency}
                  discoveryId={discoveryId}
                  onMetricChange={onMetricChange}
                  onCommit={onCommit}
                />
              ))}
            </MetricInputSection>
          )}
        </ToggleSection>
      )}
    </>
  );

  const impactSection = (
    <>
      {!!impactedKPIs?.length && (
        <ToggleSection>
          <FormSection>
            <ToggleInput
              onClick={onToggleImpact}
              data-cy={"editQuantifiedKPIValue-impact-toggle"}
            >
              <Text color={"primary"} variant={"h4"}>
                {impactToggleText}
              </Text>
              <Icon
                name={isImpactOpen ? "angleUp" : "angleDown"}
                colour={"primary"}
              />
            </ToggleInput>
          </FormSection>
          {isImpactOpen && !!impactedKPIs.length && (
            <FormSection
              data-cy={"editQuantifiedKPIValue-affected-kpis-section"}
            >
              <ImpactListContainer>
                {impactedKPIs?.map((kpi) => (
                  <li key={kpi.kpiCode}>{kpi.definition.name}</li>
                ))}
              </ImpactListContainer>
            </FormSection>
          )}
        </ToggleSection>
      )}
    </>
  );

  return (
    <FormContainer onFinish={onSave}>
      {valueSection}
      {formulaSection}
      {sliderSection}
      {inputsSection}
      {impactSection}
      <FormItem>
        <ButtonContainer>
          <ButtonGroup>
            <NewButton
              type={"submit"}
              data-cy={"editQuantifiedKPIValue-submit-button"}
              className={"up-edit-outcome-modal-save-button"}
              disabled={!hasChanges()}
            >
              {okText}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onClose}
              data-cy={"editQuantifiedKPIValue-cancel-button"}
            >
              {cancelText}
            </NewButton>
          </ButtonGroup>
        </ButtonContainer>
      </FormItem>
    </FormContainer>
  );
};

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const FormSection = styled.div``;

const ToggleSection = styled.div``;

const MetricInputSection = styled.div`
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${themeProp("palette.gray4")};
  }

  & > * {
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dotted ${themeProp("palette.gray2")};
    padding: 16px 0px;
    gap: 16px;

    .ant-input-number {
      width: 240px;
    }

    span {
      flex: 1;
      padding-top: 5px;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;

      .ant-input-number {
        width: 100%;
      }
    }
  }
`;

const KPISliderContainer = styled.div`
  margin-top: 15px;
  max-width: 420px;
`;

const ToggleInput = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 8px;
  }
`;

const ImpactListContainer = styled.ul`
  margin-top: 8px;
  max-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;

  li {
    margin-left: 16px;
    line-height: ${themeProp("typography.bodyMobile.lineHeight")};
    font-weight: ${themeProp("typography.bodyMobile.fontWeight")};
    font-size: ${themeProp("typography.bodyMobile.fontSize")};

    &::marker {
      color: ${themeProp("palette.gray3")};
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${themeProp("palette.gray4")};
  }
`;

EditQuantifiedKPIForm.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  formulaDescription: PropType.array,
  onClose: PropType.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    discovery: selectDiscovery(state),
    discoveryId: selectDiscoveryId(state),
    currency: selectDiscoveryCurrency(state),

    commitPreviewDiscoveryKPILoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_COMMIT_PREVIEW_KPI_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      previewDiscoveryKPI,
      commitPreviewDiscoveryKPI
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditQuantifiedKPIForm
);
