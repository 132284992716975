import { Slider, Text, Tooltip } from "../../../components";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  isAuthenticated,
  selectDiscoveryId,
  selectDiscoveryReportId,
  selectDiscoveryScenario,
  selectRequestData
} from "../../../store/reducers";
import {
  saveDiscoveryScenario,
  setDiscoveryScenario
} from "../../../store/actions/discoveries";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  useMobileMediaQuery,
  useWindowDimensions
} from "../../../components/Responsive";
import { previewReportCashflow } from "../../../store/actions/reports";
import { useString as s } from "../../../components/StringProvider";
import Icon from "../../../components/Icon";
import actionTypes from "../../../store/actionTypes";
import getCOSQSliderWidth from "./get-cosq-slider-width";

const EstimationScenario = ({
  isAuthenticated,
  discoveryId,
  reportId,
  scenario,
  saveDiscoveryScenario,
  setDiscoveryScenario,
  previewReportCashflow,
  reportMode,
  fixedWidth
}) => {
  const isMobile = useMobileMediaQuery();
  const { width } = useWindowDimensions();
  const title = s("discovery.estimation_scenario.title", "ESTIMATION SCENARIO");
  const minimum = s("discovery.estimation_scenario.min", "Minimum");
  const expected = s("discovery.estimation_scenario.expected", "Expected");
  const potential = s("discovery.estimation_scenario.potential", "Potential");
  const toolTipText = s(
    "discovery.estimation_scenario.toolTip",
    "This applies the bottom, middle or top of the Value Hypothesis range"
  );

  const onChange = (scenario) => {
    if (reportMode && !isAuthenticated) {
      setDiscoveryScenario({ scenario });
      previewReportCashflow({ reportId, scenario });
    } else {
      saveDiscoveryScenario({ discoveryId, scenario });
      previewReportCashflow({ reportId, scenario });
    }
  };

  return (
    <EstimationScenarioContainer data-cy={"estimation-scenario"}>
      <TitleContainer>
        <Text
          style={{ marginLeft: "7px" }}
          variant={isMobile ? "bodyMobile" : "h5"}
          color={"gray4"}
        >
          {title}
        </Text>
        <Tooltip title={toolTipText} placement={"top"}>
          <Icon
            style={{ marginTop: "-2px", marginLeft: "5px" }}
            name={"infoCircle"}
            colour={"gray4"}
            size={"medium"}
          />
        </Tooltip>
      </TitleContainer>
      <SliderContainer>
        <Slider
          style={{
            width: getCOSQSliderWidth({
              isMobile,
              windowWidth: width,
              fixedWidth
            })
          }}
          value={scenario}
          step={50}
          tooltip={{ formatter: null }}
          marks={{
            0: { style: {}, label: minimum },
            50: { style: {}, label: expected },
            100: { style: {}, label: potential }
          }}
          onChange={onChange}
        />
      </SliderContainer>
    </EstimationScenarioContainer>
  );
};

const SliderContainer = styled.div`
  margin-left: 5px;
`;

const EstimationScenarioContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

EstimationScenario.propTypes = {
  reportMode: PropTypes.bool.isRequired,
  fixedWidth: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    discoveryId: selectDiscoveryId(state),
    reportId: selectDiscoveryReportId(state),
    scenario: selectDiscoveryScenario(state),
    theme: selectRequestData(state, actionTypes.GET_THEME_REQUEST) || {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryScenario,
      setDiscoveryScenario,
      previewReportCashflow
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EstimationScenario);
