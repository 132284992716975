import { useString as s } from "../../../../../components/StringProvider";
import { ReactComponent as Icon } from "../../../../../assets/editor/type-bold.svg";
import ToolbarButton from "./ToolbarButton";

const BoldButton = ({ onClick, active }) => {
  return (
    <ToolbarButton
      active={active}
      onClick={onClick}
      label={s("editor.buttons.bold.label", "Format Bold")}
    >
      <Icon />
    </ToolbarButton>
  );
};

export default BoldButton;
