import { themeProp } from "../../../theme";
import styled from "styled-components";
import { Paragraph } from "../../../components/Typography";
import { useString as s } from "../../../components/StringProvider";

const ImagePreview = ({ imageUrl }) => {
  const previewText = s("canvas.popup.logoPreview.text", "Logo preview");

  return (
    <Container>
      <PreviewText>
        <Paragraph variant={"small"} color={"gray4"}>
          {previewText}
        </Paragraph>
      </PreviewText>
      <Logo imageUrl={imageUrl} />
    </Container>
  );
};

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${themeProp("palette.gray1")};
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 140px;
`;

const PreviewText = styled.div`
  min-height: 40px;
  max-height: 40px;
`;

const Logo = styled.div`
  min-height: 40px;
  max-height: 40px;
  width: 140px;
  align-self: center;
  background-image: url(${(props) => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export default ImagePreview;
