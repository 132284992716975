import Form, { FormItem } from "../../../../components/Form";
import { useString as s } from "../../../../components/StringProvider";
import Input from "../../../../components/Input";
import { Space } from "antd";
import NewButton from "../../../../components/NewButton";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "../../../../components/Icon";
import PasswordInput from "../../../../components/Input/PasswordInput";
import PropTypes from "prop-types";

const IntegrationConnectionForm = ({
  integrationData,
  readOnly,
  onConnect,
  onDisconnect,
  onCancel,
  onUpdate,
  onEdit,
  isConnected,
  formFields,
  integrationName
}) => {
  const baseUrlLabel = s(
    "generalAdmin.page.integrationConnection.baseUrl.label",
    "{integrationName} Base URL",
    { integrationName }
  );
  const baseUrlValidation = s(
    "generalAdmin.page.integrationConnection.baseUrl.validation",
    "Please input {integrationName} Base URL",
    { integrationName }
  );
  const baseUrlPlaceholder = s(
    "generalAdmin.page.integrationConnection.baseUrl.placeholder",
    "Add your URL"
  );
  const clientIdLabel = s(
    "generalAdmin.page.integrationConnection.clientId.label",
    "Client ID"
  );
  const clientIdValidation = s(
    "generalAdmin.page.integrationConnection.clientId.validation",
    "Please input Client ID"
  );
  const clientIdPlaceholder = s(
    "generalAdmin.page.integrationConnection.clientId.placeholder",
    "Add your Client ID"
  );
  const clientSecretLabel = s(
    "generalAdmin.page.integrationConnection.clientSecret.label",
    "Client Secret"
  );
  const clientSecretValidation = s(
    "generalAdmin.page.integrationConnection.clientSecret.validation",
    "Please input Client Secret"
  );
  const clientSecretPlaceholder = s(
    "generalAdmin.page.integrationConnection.clientSecret.placeholder",
    "Add your Client Secret"
  );
  const edit = s("generalAdmin.page.integrationConnection.edit.label", "Edit");
  const okCreate = s(
    "generalAdmin.page.integrationConnection.create.label",
    "Connect {integrationName}",
    { integrationName }
  );
  const okEdit = s(
    "generalAdmin.page.integrationConnection.okButton.label",
    "Update"
  );
  const disconnect = s(
    "generalAdmin.page.integrationConnection.disconnectButton.label",
    "Disconnect"
  );
  const cancel = s(
    "generalAdmin.page.integrationConnection.cancelButton.label",
    "Cancel"
  );

  const [form] = Form.useForm();

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [baseUrlValid, setBaseUrlValid] = useState(false);
  const [clientIdValid, setClientIdValid] = useState(false);
  const [clientSecretValid, setClientSecretValid] = useState(false);
  const [formData, setFormData] = useState({
    baseUrl: "",
    clientId: "",
    clientSecret: ""
  });

  useEffect(() => {
    setSubmitEnabled(baseUrlValid && clientIdValid && clientSecretValid);
  }, [baseUrlValid, clientIdValid, clientSecretValid]);

  useEffect(() => {
    if (integrationData) {
      form.setFieldsValue(integrationData);
      onValuesChange(integrationData);
    } else {
      form.resetFields();
      setBaseUrlValid(false);
      setClientIdValid(false);
      setClientSecretValid(false);
    }
  }, [integrationData]);

  const isFormDataChanged = (formData, integrationData) => {
    for (const key in formData) {
      if (
        formData?.hasOwnProperty(key) &&
        integrationData?.hasOwnProperty(key)
      ) {
        if (formData[key] !== integrationData[key]) {
          return true;
        }
      }
    }
    return false;
  };

  const onValuesChange = (changedValues) => {
    setFormData((prevData) => {
      const newFormData = { ...prevData, ...changedValues };
      setIsFormUpdated(isFormDataChanged(newFormData, integrationData));

      return newFormData;
    });

    if (formFields.includes("baseUrl")) {
      if (changedValues.hasOwnProperty("baseUrl")) {
        setBaseUrlValid(
          changedValues["baseUrl"] && changedValues["baseUrl"].trim()
        );
      } else {
        setBaseUrlValid(true);
      }
    }

    if (changedValues.hasOwnProperty("clientId")) {
      if (changedValues.hasOwnProperty("clientId")) {
        setClientIdValid(
          changedValues["clientId"] && changedValues["clientId"].trim()
        );
      }
    } else {
      setClientIdValid(true);
    }

    if (changedValues.hasOwnProperty("clientId")) {
      if (changedValues.hasOwnProperty("clientSecret")) {
        setClientSecretValid(
          changedValues["clientSecret"] && changedValues["clientSecret"].trim()
        );
      }
    } else {
      setClientSecretValid(true);
    }
  };

  return (
    <Form form={form} layout={"vertical"} onValuesChange={onValuesChange}>
      {formFields.includes("baseUrl") && (
        <FormItem
          label={baseUrlLabel}
          name={"baseUrl"}
          rules={[
            {
              required: true,
              message: baseUrlValidation,
              whitespace: true
            }
          ]}
        >
          <Input
            block
            placeholder={baseUrlPlaceholder}
            disabled={readOnly}
            data-cy={"hubspot-base-url"}
          />
        </FormItem>
      )}
      {formFields.includes("clientId") && (
        <FormItem
          label={clientIdLabel}
          name={"clientId"}
          rules={[
            {
              required: true,
              message: clientIdValidation,
              whitespace: true
            }
          ]}
        >
          <Input
            block
            placeholder={clientIdPlaceholder}
            disabled={readOnly}
            data-cy={"hubspot-client-id"}
          />
        </FormItem>
      )}
      {formFields.includes("clientSecret") && (
        <FormItem
          label={clientSecretLabel}
          name={"clientSecret"}
          rules={[
            {
              required: true,
              message: clientSecretValidation,
              whitespace: true
            }
          ]}
        >
          <PasswordInput
            block
            placeholder={clientSecretPlaceholder}
            disabled={readOnly}
            visibilityToggle={!readOnly}
            data-cy={"hubspot-client-secret"}
          />
        </FormItem>
      )}
      <FormItem>
        {readOnly ? (
          <NewButton type={"text"} onClick={onEdit} data-cy={"edit-button"}>
            <Icon name={"edit"} /> {edit}
          </NewButton>
        ) : isConnected ? (
          <ButtonBar>
            <Space size={16}>
              <NewButton
                disabled={!submitEnabled || !isFormUpdated}
                onClick={() => onUpdate(formData)}
                data-cy={"update-button"}
              >
                {okEdit}
              </NewButton>
              <NewButton
                type={"text"}
                onClick={onCancel}
                data-cy={"cancel-button"}
              >
                {cancel}
              </NewButton>
            </Space>
            <NewButton
              type={"text"}
              onClick={onDisconnect}
              data-cy={"disconnect-button"}
            >
              <Icon name={"close"} /> {disconnect}
            </NewButton>
          </ButtonBar>
        ) : (
          <Space size={16}>
            <NewButton
              disabled={!submitEnabled}
              onClick={() => onConnect(formData)}
              data-cy={"connection-button"}
            >
              {okCreate}
            </NewButton>
          </Space>
        )}
      </FormItem>
    </Form>
  );
};

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

IntegrationConnectionForm.propTypes = {
  integrationData: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  onConnect: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  formFields: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default IntegrationConnectionForm;
