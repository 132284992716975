const NotificationTypeNames = {
  COMMENT_ADDED: "COMMENT_ADDED",
  EDITED_KPI_VALUE: "EDITED_KPI_VALUE",
  CHALLENGE_ADDED: "CHALLENGE_ADDED",
  CHALLENGE_REMOVED: "CHALLENGE_REMOVED",
  CHALLENGE_DELETED: "CHALLENGE_DELETED",
  CHALLENGE_TITLE_UPDATED: "CHALLENGE_TITLE_UPDATED",
  CHALLENGE_SELECTED: "CHALLENGE_SELECTED",
  CHALLENGE_DESELECTED: "CHALLENGE_DESELECTED",
  CHALLENGE_IMPORTANCE_UPDATED: "CHALLENGE_IMPORTANCE_UPDATED",
  CHALLENGE_DETAIL_TEXT_UPDATED: "CHALLENGE_DETAIL_TEXT_UPDATED",
  METRIC_UPDATED: "METRIC_UPDATED",
  ROI_REQUESTED: "ROI_REQUESTED",
  KPI_SELECTED: "KPI_SELECTED",
  KPI_DESELECTED: "KPI_DESELECTED",
  KPI_RANGE_UPDATED: "KPI_RANGE_UPDATED",
  KPI_VALUE_UPDATED: "KPI_VALUE_UPDATED",
  KPI_PERCENTAGE_UPDATED: "KPI_PERCENTAGE_UPDATED",
  KPI_ADDED: "KPI_ADDED",
  KPI_REMOVED: "KPI_REMOVED",
  KPI_DELETED: "KPI_DELETED",
  KPI_NAME_UPDATED: "KPI_NAME_UPDATED",
  KPI_DESCRIPTION_UPDATED: "KPI_DESCRIPTION_UPDATED",
  KPI_IMPORTANCE_UPDATED: "KPI_IMPORTANCE_UPDATED",
  KPI_DETAIL_TEXT_UPDATED: "KPI_DETAIL_TEXT_UPDATED",
  ACCESS_REQUESTED: "ACCESS_REQUESTED",
  ACCESS_GRANTED: "ACCESS_GRANTED",
  DISCOVERY_ASSIGNED: "DISCOVERY_ASSIGNED",
  PROMOTION_REQUESTED: "PROMOTION_REQUESTED",
  PROMOTION_REJECTED: "PROMOTION_REJECTED",
  EXTERNAL_USER_SIGNED_IN: "EXTERNAL_USER_SIGNED_IN",
  NEW_GUEST_VIEWED_REPORT: "NEW_GUEST_VIEWED_REPORT"
};

NotificationTypeNames.ALL = Object.values(NotificationTypeNames);

export default NotificationTypeNames;
