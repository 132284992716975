import PropType from "prop-types";
import { selectEditChallengeCode } from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { EditChallengeModal } from "../ChallengeModal";
import ChallengeCard from "./ChallengeCard";
import styled from "styled-components";

const ChallengeCardAndModals = ({ challengeCode, editChallengeCode }) => {
  return (
    <Container id={`challenge-card-${challengeCode}`}>
      {editChallengeCode === challengeCode ? (
        <EditChallengeModal challengeCode={challengeCode} />
      ) : (
        <ChallengeCard challengeCode={challengeCode} editable={true} />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

ChallengeCardAndModals.propTypes = {
  challengeCode: PropType.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    editChallengeCode: selectEditChallengeCode(state)
  };
};
export default compose(connect(mapStateToProps))(ChallengeCardAndModals);
