import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import PropType from "prop-types";
import DefinitionTypes from "../../../utils/definition-types";
import { selectDiscoverySelectedKPIs } from "../../../store/reducers";
import FinancialCard from "./FinancialCard";
import Masonry from "../../../components/Masonry";
import { useMobileMediaQuery } from "../../../components/Responsive";
import { sortDiscoveryKPIsByImportance } from "../../../utils/sorting";

const FinancialCards = ({
  selectedKPIs,
  reportId,
  isShowAllChallenges,
  showAllHypotheses,
  printMode
}) => {
  const isMobile = useMobileMediaQuery();
  const sortedKPIs = [...selectedKPIs];

  sortedKPIs.sort((a, b) => {
    if (!reportId) {
      if (
        a.definition &&
        a.definition.status === DefinitionTypes.NEW &&
        (!b.definition || b.definition.status !== DefinitionTypes.NEW) &&
        !a.viewed
      ) {
        return -1;
      } else if (
        (!a.definition || a.definition.status !== DefinitionTypes.NEW) &&
        b.definition &&
        b.definition.status === DefinitionTypes.NEW &&
        !a.viewed
      ) {
        return 1;
      }
    }

    return sortDiscoveryKPIsByImportance(a, b);
  });

  return (
    <>
      {isMobile ? (
        (showAllHypotheses ? sortedKPIs : sortedKPIs.slice(0, 4)).map(
          (kpi, index) => (
            <MarginContainer key={index} className={"mobile"}>
              <FinancialCard
                kpiCode={kpi.kpiCode}
                reportId={reportId}
                showAllChallenges={isShowAllChallenges}
              />
            </MarginContainer>
          )
        )
      ) : (
        <Masonry
          breakpointCols={{
            default: 2,
            1200: 1
          }}
        >
          {sortedKPIs.map((kpi, index) => (
            <MarginContainer key={index}>
              <FinancialCard
                kpiCode={kpi.kpiCode}
                reportId={reportId}
                showAllChallenges={isShowAllChallenges}
                printMode={printMode}
              />
            </MarginContainer>
          ))}
        </Masonry>
      )}
    </>
  );
};

const MarginContainer = styled.div`
  margin-bottom: 18px;

  &.mobile {
    margin-bottom: 16px;
  }

  &.mobile:last-child {
    margin-bottom: 0;
  }
`;

FinancialCards.propType = {
  reportId: PropType.string,
  isShowAllChallenges: PropType.bool.isRequired,
  showAllHypotheses: PropType.bool.showAllHypotheses,
  printMode: PropType.bool
};

const mapStateToProps = (state) => ({
  selectedKPIs: selectDiscoverySelectedKPIs(state)
});

export default compose(connect(mapStateToProps))(FinancialCards);
