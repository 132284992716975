import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { themeProp } from "../theme";

const Spinner = ({ fontSize, color, ...props }) => {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: fontSize,
            color: color ? color : `${themeProp("palette.secondary")}`
          }}
          spin
        />
      }
      {...props}
    />
  );
};

Spinner.propTypes = {
  fontSize: PropTypes.number,
  color: PropTypes.string
};

export default Spinner;
