import styled from "styled-components";
import { Table as ANTTable } from "antd";
import { themeProp } from "../theme";
import { getTooltipDefaultAttributes } from "./Tooltip";
import PropTypes from "prop-types";

const Table = styled(ANTTable).attrs((props) => ({
  showSorterTooltip: {
    ...getTooltipDefaultAttributes(props)
  }
}))`
  .ant-table {
    margin-right: -1px;
  }

  td.ant-table-cell {
    font-size: ${(props) =>
      `${themeProp(`typography.${props.variant}.fontSize`)(props)} !important`};
  }

  .ant-table-column-title,
  th.ant-table-cell {
    font-size: ${themeProp("typography.bMediumMobile.fontSize")} !important;
    color: ${themeProp("palette.gray4")} !important;
    font-weight: ${themeProp("typography.bMediumMobile.fontWeight")} !important;
  }

  .ant-table-column-sorter {
    z-index: 1;

    &-up,
    &-down {
      color: ${themeProp("palette.gray2")};

      &.active {
        color: ${themeProp("palette.primary")};
      }
    }
  }

  .ant-table-thead .ant-table-cell {
    font-family: ${themeProp("typography.label.fontFamily")};
    font-size: ${themeProp("typography.small.fontSize")};
    font-weight: ${themeProp("typography.small.fontWeight")};
    line-height: ${themeProp("typography.small.lineHeight")};
    color: ${themeProp("palette.gray4")};
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: none;
    background: ${(props) =>
      props.removeHeaderStyle
        ? "transparent"
        : themeProp("palette.gray1")} !important;
  }

  .ant-table-cell {
    background: ${themeProp("palette.white")} !important;
  }

  .ant-table-column-sort > div.ant-table-column-sorters::after {
    background: ${themeProp("palette.gray2")} !important;
  }

  :where(.css-dev-only-do-not-override-1adbn6x).ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${themeProp("palette.gray2")} !important;
  }

  th.ant-table-cell.ant-table-cell-ellipsis.ant-table-column-has-sorters::before {
    background-color: ${themeProp("palette.gray2")} !important;
  }

  th.ant-table-cell::before {
    background-color: ${themeProp("palette.gray2")} !important;
  }

  .ant-table-column-sorter,
  .ant-table-column-sorter-full {
    opacity: 0;
  }

  .ant-table-column-sorters:hover .ant-table-column-sorter,
  .ant-table-column-sorters:hover .ant-table-column-sorter-full {
    opacity: 1;
  }

  .ant-table-column-sort .ant-table-column-sorter {
    opacity: 1;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    color: ${themeProp("palette.gray3")};
  }

  .ant-table-tbody .ant-table-cell {
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")};
    font-weight: ${themeProp("typography.body.fontWeight")};
    line-height: ${themeProp("typography.body.lineHeight")};
    color: ${themeProp("palette.text")};

    &.ant-table-column-sort {
      background: ${themeProp("palette.gray1")};
    }
  }

  & .ant-table-tbody > tr.ant-table-row-selected > td,
  & tr.ant-table-row-selected {
    background-color: ${themeProp("palette.gray1")};
  }

  .ant-table-pagination {
    margin: 24px 0px !important;

    .ant-pagination-item a {
      transform: translateY(1px);
    }

    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${themeProp("palette.primary")};
      border-color: ${themeProp("palette.gray2")};

      a {
        color: inherit;
        line-height: none;
      }

      svg {
        transform: translateY(-1px);
      }

      &-active,
      &:hover {
        border-color: ${themeProp("palette.secondary")};
        color: ${themeProp("palette.secondary")};

        & > a {
          color: inherit;
        }
      }

      &:focus-visible {
        box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
        border-color: transparent;
      }
    }
    .ant-pagination-item-link {
      color: ${themeProp("palette.primary")};
      border-color: ${themeProp("palette.gray2")};

      &:hover {
        border-color: ${themeProp("palette.secondary")};
        color: ${themeProp("palette.secondary")};

        & > a {
          color: inherit;
        }
      }
    }
    .ant-pagination-disabled {
      .ant-pagination-item-link {
        color: ${themeProp("palette.gray2")};
        border-color: ${themeProp("palette.gray2")};

        &:hover {
          border-color: ${themeProp("palette.gray2")};
          color: ${themeProp("palette.gray2")};

          & > a {
            color: inherit;
          }
        }
      }
    }
  }
`;

Table.propTypes = {
  variant: PropTypes.oneOf(["smallBody", "body"]),
  removeHeaderStyle: PropTypes.bool
};

Table.defaultProps = {
  variant: "body",
  removeHeaderStyle: false
};

export default Table;
