import React from "react";
import styled from "styled-components";
import { useString as s } from "../StringProvider";
import NewButton from "../NewButton";
import Icon from "../Icon";
import { Space } from "antd";

const NotesStartPage = ({ onClick }) => {
  const introText = s(
    "notes.text.intro",
    "Capture all the additional notes for the discovery"
  );
  const startText = s("notes.text.start", "Start");

  return (
    <Container>
      <Center>
        <Space direction={"vertical"} size={24} align={"center"}>
          <Icon name={"notes"} size={"large"} />
          <IntroText>{introText}</IntroText>
          <NewButton type={"submit"} onClick={onClick}>
            {startText}
          </NewButton>
        </Space>
      </Center>
    </Container>
  );
};

const IntroText = styled.div`
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Center = styled.div`
  padding: 40px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export default NotesStartPage;
