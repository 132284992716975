import { Phases } from "../../utils/phases";
import PropTypes from "prop-types";
import Select, { Option } from "../Select";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  selectAllPermissions,
  selectDiscoveryMaximumPhase,
  selectDiscoveryROI,
  isDiscoveryROIComplete
} from "../../store/reducers";
import PhaseName from "../PhaseName";
import { canUserAccessDiscoveryPhase } from "../../Pages/Discovery/components/OnlyIfUserCanAccessDiscoveryPath";
import Permissions from "../../utils/permissions";

const canUserAccessDiscoveryPhaseInSelectTemplate = ({
  phase,
  maximumPhase,
  permissions,
  isROIComplete
}) => {
  if (
    phase === Phases.CHALLENGES_SOLVED &&
    !permissions.includes(Permissions.SEE_SELECT_CHALLENGES)
  ) {
    return false;
  }

  if (
    phase === Phases.KPIS_IMPACTED &&
    !permissions.includes(Permissions.SEE_SELECT_KPIS)
  ) {
    return false;
  }

  if (phase === Phases.ROI && !isROIComplete) {
    return false;
  }

  return canUserAccessDiscoveryPhase({
    phase,
    maximumPhase,
    permissions
  });
};

const getTemplateOptions = ({ phase }) => {
  switch (phase) {
    case Phases.QUESTIONS:
      return [Phases.CHALLENGES_SOLVED];
    default:
      return [
        Phases.CHALLENGES_SOLVED,
        Phases.KPIS_IMPACTED,
        Phases.VALUE_HYPOTHESIS,
        Phases.ROI
      ];
  }
};

const SelectTemplate = ({
  phase,
  roi,
  maximumPhase,
  permissions,
  isROIComplete,
  dispatch,
  ...props
}) => {
  if (phase === Phases.ROI && !roi) {
    phase = Phases.VALUE_HYPOTHESIS;
  }

  return (
    <Select {...props}>
      {getTemplateOptions({ phase })
        .filter((phase) =>
          canUserAccessDiscoveryPhaseInSelectTemplate({
            phase,
            maximumPhase,
            permissions,
            isROIComplete
          })
        )
        .map((option) => (
          <Option key={option} value={option}>
            <span>
              <PhaseName phase={option} />
            </span>
          </Option>
        ))}
    </Select>
  );
};

SelectTemplate.propTypes = {
  phase: PropTypes.oneOf(Phases.ALL).isRequired
};

const mapStateToProps = (state) => ({
  roi: selectDiscoveryROI(state),
  maximumPhase: selectDiscoveryMaximumPhase(state),
  permissions: selectAllPermissions(state),
  isROIComplete: isDiscoveryROIComplete(state)
});

export default compose(connect(mapStateToProps))(SelectTemplate);
