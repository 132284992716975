import styled, { css } from "styled-components";
import { Form as ANTForm } from "antd";
import { themeProp } from "../theme";
import TooltipIcon from "./TooltipIcon";

const Form = styled(ANTForm).attrs(() => ({}))`
  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-form-item:last-of-type {
    margin-bottom: 0;
  }

  .ant-form-item-required::before {
    display: none !important;
  }

  & .ant-form-item.checkbox {
    margin-bottom: 0;
  }

  &.login.ant-form-horizontal {
    max-width: 435px;

    .ant-form-item-control {
      max-width: 100%;
      width: 100%;
    }
  }

  & .ant-form-item-label {
    padding: 0;
    margin: 0;
  }

  & .ant-form-item-control-input {
    align-items: flex-start;
  }

  & .ant-form-item-explain {
    padding: 4px 12px;
    font-size: ${themeProp("typography.bodyMobile.fontSize")};
    line-height: ${themeProp("typography.bodyMobile.lineHeight")};
    font-weight: ${themeProp("typography.bodyMobile.fontWeight")};
  }
`;

export default Form;

export const FormItem = styled(ANTForm.Item)`
  &.ant-form-item,
  &.ant-form-item label {
    font-family: ${themeProp("typography.body.fontFamily")};

    ${({ size }) =>
      size === "large"
        ? css`
            font-size: ${themeProp("typography.body.fontSize")};
            line-height: ${themeProp("typography.body.lineHeight")};
            font-weight: ${themeProp("typography.body.fontWeight")};
          `
        : css`
            font-size: ${themeProp("typography.bodyMobile.fontSize")};
            line-height: ${themeProp("typography.bodyMobile.lineHeight")};
            font-weight: ${themeProp("typography.bodyMobile.fontWeight")};
          `}
  }
`;

FormItem.defaultProps = {
  size: "large"
};

export const FormItemLabel = ({ label, tooltip, size, bold }) => {
  return (
    <FormItemLabelContainer size={size} bold={bold}>
      {label}&nbsp;
      <TooltipIcon title={tooltip} />
    </FormItemLabelContainer>
  );
};

const FormItemLabelContainer = styled.div`
  ${({ size }) =>
    size === "large"
      ? css`
          font-size: ${themeProp("typography.body.fontSize")};
          line-height: ${themeProp("typography.body.lineHeight")};
          font-weight: ${(props) =>
            props.bold
              ? themeProp("typography.bMedium.fontWeight")
              : themeProp("typography.body.fontWeight")};
        `
      : css`
          font-size: ${themeProp("typography.bodyMobile.fontSize")};
          line-height: ${themeProp("typography.bodyMobile.lineHeight")};
          font-weight: ${(props) =>
            props.bold
              ? themeProp("typography.bMedium.fontWeight")
              : themeProp("typography.bodyMobile.fontWeight")};
        `}
`;

FormItemLabel.defaultProps = {
  size: "large",
  bold: false
};
