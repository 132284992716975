import styled from "styled-components";
import { themeProp } from "../../../theme";
import Color from "color";

export const isQuestionDisabled = ({
  isCurrentQuestionOrPrevious,
  question,
  isNextDisabled,
  lastMandatoryQuestionAnswered = "n/a",
  lastMandatoryQuestionNumber,
  currentQuestionNumber
}) => {
  const lastMandatoryQuestionAnsweredLocally =
    currentQuestionNumber === lastMandatoryQuestionNumber && !isNextDisabled;

  if (isCurrentQuestionOrPrevious) {
    return false;
  }

  //if the Next button is disabled all menu options should be disabled
  if (isNextDisabled) {
    return true;
  }

  if (
    question.compulsorySelection &&
    !lastMandatoryQuestionAnswered &&
    !lastMandatoryQuestionAnsweredLocally
  ) {
    return true;
  }

  //All non-mandatory questions to be active
  if (!question.compulsorySelection && lastMandatoryQuestionAnsweredLocally) {
    return false;
  }
  //This last section of logic can be simplified but I have left it like this because I find it easier to read
  if (!question.compulsorySelection && !lastMandatoryQuestionAnswered) {
    return true;
  }

  return false;
};

const getLabel = ({ questionText, index, isCurrentQuestion }) => {
  return (
    <Label $isCurrentQuestion={isCurrentQuestion}>
      <LabelNumber className="label-number">{index + 1}</LabelNumber>
      <div>{questionText}</div>
    </Label>
  );
};

const Label = styled.div`
  color: ${(props) =>
    props.$isCurrentQuestion ? themeProp(`palette.secondary`) : undefined};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LabelNumber = styled.div`
  padding: 0px 8px;
  background-color: ${(props) => {
    return Color(themeProp("palette.gray1")(props)).alpha(0.5);
  }};
  border-radius: 4px;
  color: ${themeProp("palette.gray4")};
`;

export const getQuestionNavDropdownMenuItems = ({
  questions,
  isNextDisabled,
  currentQuestionNumber
}) => {
  const menuItems = [];

  let lastMandatoryQuestionAnswered = undefined;
  let lastMandatoryQuestionNumber = undefined;

  questions.forEach((question, questionIndex) => {
    if (question.active) {
      if (
        question.compulsorySelection &&
        question.missingAnswerForCompulsorySelection
      ) {
        lastMandatoryQuestionAnswered = false;
        lastMandatoryQuestionNumber = questionIndex;
      }

      menuItems.push({
        key: questionIndex,
        className:
          questionIndex === currentQuestionNumber
            ? "highlighted-question"
            : undefined,
        value: question.questionCode,
        label: getLabel({
          questionText: question?.question,
          index: menuItems.length,
          isCurrentQuestion: questionIndex === currentQuestionNumber
        }),
        disabled: isQuestionDisabled({
          isCurrentQuestionOrPrevious: questionIndex <= currentQuestionNumber,
          question,
          isNextDisabled,
          lastMandatoryQuestionAnswered,
          lastMandatoryQuestionNumber,
          currentQuestionNumber
        })
      });
    }
  });

  //make the first disabled question active
  const firstDisabledQuestion = menuItems.find((item) => item.disabled);

  if (firstDisabledQuestion && !isNextDisabled) {
    firstDisabledQuestion.disabled = false;
  }

  return menuItems;
};
