import {
  selectNotificationIds,
  selectNotificationsLoadingState
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { getNotifications } from "../../store/actions/notifications";
import { connect } from "react-redux";
import Heading from "../Heading";
import styled from "styled-components";
import { Dropdown, Empty, message, Space } from "antd";
import { useString as s } from "../StringProvider";
import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import NewButton from "../NewButton";
import Menu, { MenuItem } from "../Menu";
import Icon from "../Icon";
import LoadingState from "../../utils/loading-state";
import NotificationsList from "./NotificationsList";

const SHOW_NEW_ONLY = "SHOW_NEW_ONLY";
const SHOW_ALL = "SHOW_ALL";

const NotificationsPanel = ({
  visible,
  notificationIds,
  loadingState,
  getNotifications
}) => {
  const notificationsText = s("notifications.popover.title", "Notifications");
  const noNotificationsText = s(
    "notifications.popover.empty",
    "No notifications"
  );
  const showNewOnlyText = s(
    "notifications.popover.showNewOnly",
    "Show new only"
  );
  const errorText = s(
    "notifications.popover.error.text",
    "Failed to load notifications"
  );
  const showAllText = s("notifications.popover.showAll", "Show all");
  const [showNewOnly, setShowNewOnly] = useState(false);

  useEffect(() => {
    if (visible) {
      const params = {};

      if (showNewOnly) {
        params.isRead = false;
      }

      getNotifications(params);
    }
  }, [getNotifications, visible, showNewOnly]);

  useEffect(() => {
    if (loadingState === LoadingState.FAILED) {
      message.error(errorText);
    }
  }, [loadingState]);

  const onShow = ({ key }) => {
    setShowNewOnly(key === SHOW_NEW_ONLY);
  };

  const showOnlyMenu = (
    <Menu onClick={onShow}>
      <MenuItem key={SHOW_NEW_ONLY}>
        <span>{showNewOnlyText}</span>
      </MenuItem>
      <MenuItem key={SHOW_ALL}>
        <span>{showAllText}</span>
      </MenuItem>
    </Menu>
  );

  return (
    <Container data-cy={"notifications-panel"}>
      <TitleSpace direction={"vertical"} size={0}>
        <Heading level={"h4"} style={{ marginBottom: 0 }}>
          {notificationsText}
        </Heading>
        <Dropdown overlay={showOnlyMenu} trigger="click">
          <NewButton
            type={"text"}
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            style={{
              left: -8
            }}
          >
            {showNewOnly ? showNewOnlyText : showAllText}{" "}
            <Icon name={"angleDown"} />
          </NewButton>
        </Dropdown>
      </TitleSpace>
      {!notificationIds || !notificationIds.length ? (
        <Empty
          description={noNotificationsText}
          style={{ paddingBottom: 20 }}
        />
      ) : (
        <NotificationsList
          notificationIds={notificationIds}
          visible={visible}
        />
      )}
    </Container>
  );
};

const TitleSpace = styled(Space)`
  padding: 16px;
`;

const Container = styled.div`
  min-width: 480px;
  max-width: 480px;
  .ant-empty-img-default-path-3 {
    fill: #f5f5f7;
  }
  .ant-empty-img-default-path-4 {
    fill: #dce0e6;
  }
  .ant-empty-img-default-path-5 {
    fill: #dce0e6;
  }
  .ant-empty-img-default-path-1 {
    fill: #aeb8c2;
  }
  .ant-empty-img-default-g path {
    fill: white;
  }
`;

NotificationsPanel.propTypes = {
  visible: PropType.bool.isRequired
};

const mapStateToProps = (state) => {
  return {
    notificationIds: selectNotificationIds(state),
    loadingState: selectNotificationsLoadingState(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotifications
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotificationsPanel
);
