import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { FormItemLabel } from "../../Form";
import NumberInput from "../../Input/NumberInput";
import Text from "../../Text";
import PropType from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { hasPermission, selectDiscoveryMetric } from "../../../store/reducers";
import { getMetricFormatter, getMetricParser } from "../../../utils/formatting";
import { saveDiscoveryMetric } from "../../../store/actions/discoveries";
import { SettingsContext } from "../../SettingsProvider";
import { themeProp } from "../../../theme";
import Permissions from "../../../utils/permissions";

const MetricInput = ({
  currency,
  metric,
  metricCode,
  onMetricChange,
  canSeeAllMetrics,
  onCommit
}) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;

  const [metricValue, setMetricValue] = useState(metric.value);
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    if (!isUpdating || String(metric.value) === String(metricValue)) {
      setMetricValue(metric.value);
    }
  }, [metric.value]);

  useEffect(() => {
    setUpdating(false);
  }, [metric]);

  const isNumber = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  };

  const formatter = getMetricFormatter({
    type: metric.type,
    locale,
    currency,
    options: { notation: "standard" }
  });

  const parser = getMetricParser({ type: metric.type, locale, currency });

  const onChange = (v) => {
    if (isNumber(v)) {
      if (metric.type === "Percentage" && v > 100) {
        setMetricValue(100);
        onMetricChange({ metricCode, value: 100 });
      } else {
        setMetricValue(v);
        onMetricChange({ metricCode, value: v });
      }
    }
  };

  if (!canSeeAllMetrics && !metric.visible) {
    return null;
  }

  return (
    <InputContainer>
      <Text>
        <FormItemLabel label={metric.name} tooltip={metric.description} />
      </Text>
      <NumberInput
        size={"medium"}
        value={Number(metricValue)}
        formatter={formatter}
        parser={parser}
        onChange={onChange}
        onCommit={onCommit}
        data-cy={`metric-input-${metricCode}`}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    height: fit-content;
    color: ${themeProp("palette.secondary")};
    font-weight: ${themeProp("typography.body.fontWeight")} !important;
  }

  .ant-input-number {
    width: 100%;
    height: fit-content !important;
  }
`;

MetricInput.propTypes = {
  metricCode: PropType.string.isRequired,
  currency: PropType.string.isRequired,
  discoveryId: PropType.string.isRequired
};

const mapStateToProps = (state, props) => ({
  metric: selectDiscoveryMetric(state, props.metricCode),
  canSeeAllMetrics: hasPermission(state, Permissions.SEE_ALL_METRICS)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryMetric
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MetricInput
);
