import types from "../actionTypes";
import commentsService from "../../services/comments.service";

export const getDiscoveryCommentCount =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_COMMENT_COUNT_REQUEST
    });

    return commentsService
      .getDiscoveryCommentCount({
        discoveryId
      })
      .then((topics) => {
        dispatch({
          type: types.GET_COMMENT_COUNT_SUCCESS,
          payload: { topics }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_COMMENT_COUNT_FAILURE,
          payload: { error }
        });
      });
  };

export const getValueNote =
  ({ discoveryId, kpiCode }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_VALUE_NOTE_REQUEST
    });

    return commentsService
      .getValueNote({
        discoveryId,
        kpiCode
      })
      .then(({ items }) => {
        const comment = items.length ? items[0] : undefined;

        dispatch({
          type: types.GET_VALUE_NOTE_SUCCESS,
          payload: { comment }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_VALUE_NOTE_FAILURE,
          payload: { error }
        });
      });
  };

export const getComments =
  ({ topic }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_COMMENTS_REQUEST
    });

    return commentsService
      .getComments({
        topic
      })
      .then(({ items, pagination }) => {
        dispatch({
          type: types.GET_COMMENTS_SUCCESS,
          payload: { comments: items, topic, total: pagination.total }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_COMMENTS_FAILURE,
          payload: { error }
        });
      });
  };

export const postComment =
  ({ topic, text }) =>
  (dispatch) => {
    dispatch({
      type: types.POST_COMMENT_REQUEST
    });

    return commentsService
      .postComment({
        topic,
        text
      })
      .then((data) => {
        dispatch({
          type: types.POST_COMMENT_SUCCESS,
          payload: { comment: data, topic }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_COMMENT_FAILURE,
          payload: { error }
        });
      });
  };

export const updateComment =
  ({ commentId, text }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_COMMENT_REQUEST
    });

    return commentsService
      .updateComment({
        commentId,
        text
      })
      .then((comment) => {
        dispatch({
          type: types.UPDATE_COMMENT_SUCCESS,
          payload: { comment }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_COMMENT_FAILURE,
          payload: { error }
        });
      });
  };

export const deleteComment =
  ({ commentId, topic }) =>
  (dispatch) => {
    dispatch({
      type: types.DELETE_COMMENT_REQUEST
    });

    return commentsService
      .deleteComment({
        commentId
      })
      .then(() => {
        dispatch({
          type: types.DELETE_COMMENT_SUCCESS,
          payload: { commentId, topic }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_COMMENT_FAILURE,
          payload: { error }
        });
      });
  };

export const startEditingComment =
  ({ commentId }) =>
  (dispatch) => {
    dispatch({
      type: types.START_EDITING_COMMENT,
      payload: { commentId }
    });
  };

export const stopEditingComment = () => (dispatch) => {
  dispatch({
    type: types.STOP_EDITING_COMMENT
  });
};

export const closeComments =
  ({ topic, text }) =>
  (dispatch) => {
    dispatch({
      type: types.CLOSE_COMMENTS,
      payload: { topic, text }
    });
  };

export const getReportCommentCount =
  ({ reportId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_COMMENT_COUNT_REQUEST
    });

    return commentsService
      .getReportCommentCount({
        reportId
      })
      .then((topics) => {
        dispatch({
          type: types.GET_COMMENT_COUNT_SUCCESS,
          payload: { topics }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_COMMENT_COUNT_FAILURE,
          payload: { error }
        });
      });
  };

export const getReportComments =
  ({ reportId, topic }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_COMMENTS_REQUEST
    });

    return commentsService
      .getReportComments({
        reportId,
        topic
      })
      .then(({ items, pagination }) => {
        dispatch({
          type: types.GET_COMMENTS_SUCCESS,
          payload: { comments: items, topic, total: pagination.total }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_COMMENTS_FAILURE,
          payload: { error }
        });
      });
  };

export const postReportComment =
  ({ reportId, topic, text }) =>
  (dispatch) => {
    dispatch({
      type: types.POST_COMMENT_REQUEST
    });

    return commentsService
      .postReportComment({
        reportId,
        topic,
        text
      })
      .then((data) => {
        dispatch({
          type: types.POST_COMMENT_SUCCESS,
          payload: { comment: data, topic }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_COMMENT_FAILURE,
          payload: { error }
        });
      });
  };

export const updateReportComment =
  ({ reportId, commentId, text }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_COMMENT_REQUEST
    });

    return commentsService
      .updateReportComment({
        reportId,
        commentId,
        text
      })
      .then((comment) => {
        dispatch({
          type: types.UPDATE_COMMENT_SUCCESS,
          payload: { comment }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_COMMENT_FAILURE,
          payload: { error }
        });
      });
  };

export const deleteReportComment =
  ({ reportId, commentId, topic }) =>
  (dispatch) => {
    dispatch({
      type: types.DELETE_COMMENT_REQUEST
    });

    return commentsService
      .deleteReportComment({
        reportId,
        commentId
      })
      .then(() => {
        dispatch({
          type: types.DELETE_COMMENT_SUCCESS,
          payload: { commentId, topic }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_COMMENT_FAILURE,
          payload: { error }
        });
      });
  };

export const setDeletingComment =
  ({ deletingComment }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_DELETING_COMMENT,
      payload: { deletingComment }
    });
  };
