import React from "react";
import NewButton from "../NewButton";
import { useString as s } from "../../components/StringProvider";
import { useCustomMediaQuery } from "../../components/Responsive";
import Menu, { MenuItem } from "../../components/Menu";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import { Operations } from "../../components/DiscoveryOperations/DiscoveryOperations";
import { startDiscoveryOperation } from "../../store/actions/discovery-operations";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { selectDiscoveryId } from "../../store/reducers";

const ExportToPDFButton = ({ startDiscoveryOperation, discoveryId }) => {
  const downloadPDFText = s("download.pdf.button.text", "Download PDF");

  const { isBreakPoint } = useCustomMediaQuery({
    type: "max",
    singleBreakPoint: 1000
  });

  const handleClick = () => {
    startDiscoveryOperation({
      operationId: Operations.PRINT,
      operationDiscoveryId: discoveryId
    });
  };

  const renderMenu = () => (
    <Menu onClick={handleClick}>
      <MenuItem key={Operations.PRINT} icon={<Icon name={"pdf"} />}>
        {downloadPDFText}
      </MenuItem>
    </Menu>
  );

  return isBreakPoint ? (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomLeft"}
      trigger={["click"]}
    >
      <NewButton
        type={"iconPrimary"}
        className={"ant-dropdown-link"}
        onClick={(e) => e.preventDefault()}
      >
        <Icon name={"dropdown"} size={"medium"} />
      </NewButton>
    </Dropdown>
  ) : (
    <NewButton
      type={"primary"}
      onClick={handleClick}
      data-cy={"export-pdf-button"}
    >
      {downloadPDFText}
    </NewButton>
  );
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startDiscoveryOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExportToPDFButton
);
