import React from "react";
import ImpactForYou from "./ImpactForYou";
import PropType from "prop-types";

const FixedPercentKPIData = ({ kpi, disabled }) => {
  return <ImpactForYou kpi={kpi} disabled={disabled} />;
};

FixedPercentKPIData.propTypes = {
  disabled: PropType.bool
};

export default FixedPercentKPIData;
