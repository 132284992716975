import styled from "styled-components";
import { useMobileMediaQuery, useMobileOrTabletMediaQuery } from "./Responsive";
import PropTypes from "prop-types";

export const ButtonGroupModes = {
  MOBILE: "MOBILE",
  MOBILE_OR_TABLET: "MOBILE_OR_TABLET"
};

const ButtonGroup = ({ mode, mobile, children, ...props }) => {
  const isMobile =
    mode === ButtonGroupModes.MOBILE_OR_TABLET
      ? useMobileOrTabletMediaQuery()
      : useMobileMediaQuery();

  return (
    <Footer
      className={isMobile || mobile ? "mobile button-group" : "button-group"}
      {...props}
    >
      {children}
    </Footer>
  );
};

const Footer = styled.div`
  display: flex;

  & > * {
    margin-right: 16px;
  }

  & > *:last-child {
    margin-right: 0px;
  }

  &.mobile {
    flex-direction: column;
    align-items: stretch;
  }

  &.mobile > * {
    margin-bottom: 16px;
    margin-right: 0px;
    width: unset;
    min-width: fit-content;
  }

  &.mobile > *:last-child {
    margin-bottom: 0px;
  }
`;

ButtonGroup.propTypes = {
  mode: PropTypes.oneOf([
    ButtonGroupModes.MOBILE,
    ButtonGroupModes.MOBILE_OR_TABLET
  ]),
  mobile: PropTypes.bool
};

ButtonGroup.defaultProps = {
  mode: ButtonGroupModes.MOBILE
};

export default ButtonGroup;
