import { useString as s } from "../../../../../components/StringProvider";
import { ReactComponent as Icon } from "../../../../../assets/editor/text-center.svg";
import ToolbarButton from "./ToolbarButton";

const CenterAlignButton = ({ onClick, active }) => {
  return (
    <ToolbarButton
      active={active}
      onClick={onClick}
      label={s("editor.buttons.center.label", "Center Align")}
    >
      <Icon />
    </ToolbarButton>
  );
};

export default CenterAlignButton;
