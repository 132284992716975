import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import isJSON from "../../../utils/is-json";

const getInitialEditorState = ({ value, useMarkdown }) => {
  if (useMarkdown && value) {
    return $convertFromMarkdownString(value, TRANSFORMERS);
  }

  if (isJSON(value)) {
    return value;
  }

  return JSON.stringify({
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: value,
              type: "text",
              version: 1
            }
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1
        }
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1
    }
  });
};

export default getInitialEditorState;
