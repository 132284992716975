import Heading from "../../../components/Heading";
import styled from "styled-components";
import React from "react";
import PropType from "prop-types";
import { themeProp } from "../../../theme";
import { useMobileMediaQuery } from "../../../components/Responsive";

const HeaderMetric = ({ label, children }) => {
  const isMobile = useMobileMediaQuery();
  return (
    <HeaderMetricContainer className={isMobile ? "mobile" : undefined}>
      <Heading level={"h1"}>{children}</Heading>
      <Heading level={"h4"}>{label}</Heading>
    </HeaderMetricContainer>
  );
};

const HeaderMetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 48px;

  h1 {
    margin-bottom: 8px;
  }

  &.mobile h4 {
    font-family: ${themeProp("typography.bMediumMobile.fontFamily")};
    font-size: ${themeProp("typography.bMediumMobile.fontSize")};
    font-weight: ${themeProp("typography.bMediumMobile.fontWeight")};
    line-height: ${themeProp("typography.bMediumMobile.lineHeight")};
    margin-bottom: 0px;
  }
`;

HeaderMetric.propTypes = {
  label: PropType.string.isRequired
};

export default HeaderMetric;
