import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TextBody = (props) => <TextContainer {...props} />;
TextBody.propTypes = {
  alignment: PropTypes.oneOf(["center"])
};

export default TextBody;

const TextContainer = styled.span`
  ${(props) => (props.alignment === "center" ? "text-align: center;" : "")}
  ${(props) => (props.alignment === "center" ? "display: inline-block;" : "")}
`;
