import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { selectCookie } from "../../store/reducers";
import CookieNames from "../../utils/cookie-names";
import { setCookie } from "../../store/actions/cookies";
import { v4 as uuidv4 } from "uuid";
import errorsService from "../../services/errors.service";

const COOKIE_EXPIRATION_IN_DAYS = 365;

const GuestUserTracker = ({ guestId, setCookie }) => {
  const location = useLocation();

  useEffect(() => {
    if (!guestId) {
      setCookie({
        name: CookieNames.GUEST_ID,
        value: "guest-" + uuidv4(),
        expires: COOKIE_EXPIRATION_IN_DAYS
      });
    }
  }, [guestId]);

  useEffect(() => {
    if (guestId) {
      errorsService.logInfo({
        category: "guest-user-tracker",
        data: JSON.stringify(location)
      });
    }
  }, [location, guestId]);

  return null;
};

const mapStateToProps = (state) => ({
  guestId: selectCookie(state, CookieNames.GUEST_ID)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCookie
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GuestUserTracker
);
