import httpService from "./http.service";

const getDiscoveryNote = ({ discoveryId }) =>
  httpService
    .get(`/discoveries/${discoveryId}/notes`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateDiscoveryNote = ({ discoveryId, text }) =>
  httpService
    .put(`/discoveries/${discoveryId}/notes`, {
      text
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const stopDiscoveryNoteSession = ({ discoveryId }) =>
  httpService
    .delete(`/discoveries/${discoveryId}/notes/session`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const startDiscoveryNoteSession = ({ discoveryId }) =>
  httpService
    .post(`/discoveries/${discoveryId}/notes/session`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const keepDiscoveryNoteSessionAlive = ({ discoveryId }) =>
  httpService
    .post(`/discoveries/${discoveryId}/notes/session/keep-alive`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getDiscoveryNote,
  updateDiscoveryNote,
  stopDiscoveryNoteSession,
  startDiscoveryNoteSession,
  keepDiscoveryNoteSessionAlive
};

export default methods;
