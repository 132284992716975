import httpService from "./http.service";

const getNotesTemplate = () =>
  httpService
    .get(`/templates/notes`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateNotesTemplate = ({ content }) =>
  httpService
    .put(`/templates/notes`, { content })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deployPowerpointTemplates = ({ validationIds }) =>
  httpService
    .post(`/templates/powerpoint/deploy`, { validationIds })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getNotesTemplate,
  updateNotesTemplate,
  deployPowerpointTemplates
};

export default methods;
