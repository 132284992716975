import { notification } from "antd";
import { useMobileMediaQuery } from "../Responsive";
import { WarningOutlined } from "@ant-design/icons";
import { useTheme } from "../ThemeProvider";
import { useEffect } from "react";
import { POPUP_CONTAINER } from "../FullscreenProvider";

const ErrorNotificationBox = ({ title, description }) => {
  const isMobile = useMobileMediaQuery();
  const errorColor = useTheme("palette.error");
  const errorIcon = (
    <WarningOutlined style={{ fontSize: 24, color: errorColor }} />
  );

  const [notificationAPI, contextHolder] = notification.useNotification({
    getContainer: () => document.getElementById(POPUP_CONTAINER)
  });

  useEffect(() => {
    notificationAPI.open({
      message: title,
      description: description,
      icon: errorIcon,
      placement: "topRight",
      duration: 0,
      top: isMobile ? 120 : 98,
      style: { width: "400px" }
    });
  }, []);

  return <>{contextHolder}</>;
};

export default ErrorNotificationBox;
