import { createRef } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import PropTypes from "prop-types";
import ValueMapTextForm from "./ValueMapTextForm";
import ItemTypes from "../../components/ItemTypes";
import { ValueMapCardContainer } from "./ValueMapCardUI";
import ValueMapCardText from "./ValueMapCardText";
import { Icon, Tooltip } from "../../components";
import { useString as s } from "../../components/StringProvider";
import useComponentDimensions from "../../utils/use-component-dimensions";
import ValueMapCardDropdown from "./ValueMapCardDropdown";
import { CommentsButton } from "../../components/Discovery/Comments";
import { selectDiscoveryId } from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { DropDirection } from "./ValueMapCard";
import { UserPilotEventNames } from "../../utils/user-pilot-util";

const KeyObjectiveCard = ({
  item,
  onSave,
  setCardHeight,
  cardHeight,
  discoveryId,
  onDelete,
  onReorder
}) => {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState(item.text);
  const [code, setCode] = useState(item.code);
  const type = ItemTypes.VALUE_MAP_CHALLENGE;
  const tooltip = s(
    "valueMap.page.keyObjective.select.disabled",
    "Critical Business Issue"
  );
  const title = s("key.objective", "Key Objective");

  useEffect(() => {
    setText(item.text);
    setCode(item.code);
  }, [item]);

  const clickHandler = (event) => {
    if (event.detail === 2) {
      setEditMode(true);
    }
  };

  const onCancel = () => {
    setEditMode(false);
  };

  const onSubmit = ({ text }) => {
    if (text && text.trim()) {
      setText(text.trim());
      onSave({ text: text.trim() });
    }

    setEditMode(false);
  };

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type,
      item: { code },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [code]
  );

  const cardRef = createRef();
  const dimensions = useComponentDimensions(cardRef);

  useEffect(() => {
    if (cardHeight !== dimensions.height) {
      setCardHeight(dimensions.height);
    }
  }, [dimensions.height]);

  const removeKeyObjective = (item) => {
    onReorder({
      code: item.code,
      destinationCode: item.code,
      direction: DropDirection.REMOVE_KEY_OBJECTIVE
    });
  };

  return (
    <CardContainer ref={cardRef}>
      <ValueMapCardContainer
        className={
          "selected keyObjective " +
          (editMode ? "editing " : "") +
          (isDragging ? "dragging " : "")
        }
        onClick={clickHandler}
        ref={dragRef}
        data-cy={`${type}-${code}`}
      >
        <Tooltip title={tooltip}>
          <StyledIcon name={"flag"} size={"medium"} />
        </Tooltip>
        {editMode ? (
          <ValueMapTextForm
            text={text}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        ) : (
          <ValueMapCardText text={text} type={type} code={code} maxRows={3} />
        )}
        <CardMenu>
          <CommentsButton
            topic={`/discoveries/${discoveryId}/${type}s/${code}`}
            commentPanelMaxHeight={220}
            eventName={UserPilotEventNames.ADD_NEW_COMMENT_CANVAS_CHALLENGE}
          />
          <ValueMapCardDropdown
            onEdit={() => setEditMode(true)}
            onDelete={() => onDelete({ item })}
            onSelect={undefined}
            isSelected={true}
            title={title}
            item={item}
            editMode={editMode}
            isCBI={true}
            isCBIPresent={true}
            type={type}
            unSetCBI={removeKeyObjective}
          />
        </CardMenu>
      </ValueMapCardContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const CardMenu = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  padding: 0px 5px 0px 5px;
  display: flex;
`;

KeyObjectiveCard.propTypes = {
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  setCardHeight: PropTypes.func.isRequired,
  cardHeight: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state)
  };
};

export default compose(connect(mapStateToProps))(KeyObjectiveCard);
