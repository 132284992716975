import { BasicBrandHeader, SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import React, { useEffect, useState } from "react";
import Form from "../../components/Form";
import styled from "styled-components";
import { bindActionCreators, compose } from "redux";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addDiscovery } from "../../store/actions/discoveries";
import { useString as s } from "../../components/StringProvider";
import {
  selectAddDiscoveryError,
  selectAddDiscoveryLoadingState,
  selectAddDiscoveryResult,
  selectMasterDataVersion,
  selectRequestData
} from "../../store/reducers";
import { setUserSettings } from "../../store/actions/user-settings";
import DiscoveryAlert from "../../components/DiscoveryOperations/NewDiscoveryModal/DiscoveryAlert";
import { message } from "antd";
import { getMasterData } from "../../store/actions/master-data";
import actionTypes from "../../store/actionTypes";
import { useTheme } from "../../components/ThemeProvider";
import useLoadingState from "../../utils/use-loading-state";
import NewButton from "../../components/NewButton";
import Heading from "../../components/Heading";
import TextBody from "../../components/TextBody";
import { selectLatestVersionNumber } from "../../store/selectors/versions";
import { getLatestVersion } from "../../store/actions/versions";
import DiscoveryForm from "../../components/Discovery/DiscoveryForm";
import SourceTypes from "../../utils/source-types";

const CreateDiscovery = ({
  history,
  userSettings,
  newDiscovery,
  error,
  masterDataVersion,
  addDiscoveryLoadingState,
  addDiscovery,
  setUserSettings,
  getLatestVersion,
  latestVersionNumber,
  getMasterData
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const accountId = params.get("accountId");
  const accountName = params.get("accountName");
  const accountSource =
    accountId && accountName ? SourceTypes.CRM_MANAGED : undefined;
  const opportunityId = params.get("opportunityId");
  const opportunityName = params.get("opportunityName");
  const opportunitySource =
    opportunityId && opportunityName ? SourceTypes.CRM_MANAGED : undefined;
  const currency = params.get("currency");

  const [form] = Form.useForm();

  const defaultCoverImage = useTheme("assets.report_header_cover");
  const salesforceCoverImage = useTheme(
    "assets.salesforce_create_discovery_cover"
  );

  const okText = s("new_discovery.page.button1", "Start discovery");
  const header = s("new_discovery.page.header", "New Discovery");
  const subheader = s(
    "new_discovery.page.subheader",
    "Create the discovery by filling out the form"
  );
  const viewAllDiscoveries = s(
    "new_discovery.page.viewAllDiscoveries",
    "View all Discoveries"
  );
  const errorText = s(
    "new_discovery.page.error.text",
    "Failed to create discovery"
  );
  const [isDuplicateError, setDuplicateError] = useState(false);

  useEffect(() => {
    getLatestVersion();
  }, []);

  useEffect(() => {
    if (latestVersionNumber && latestVersionNumber !== masterDataVersion) {
      getMasterData({ version: latestVersionNumber });
    }
  }, [latestVersionNumber, masterDataVersion]);

  useEffect(() => {
    form.setFieldsValue({
      accountId,
      accountName,
      accountSource,
      opportunityId,
      opportunityName,
      opportunitySource,
      currency
    });
  }, [
    accountId,
    accountName,
    accountSource,
    opportunityId,
    opportunityName,
    opportunitySource,
    currency
  ]);

  useEffect(() => {
    if (userSettings && userSettings.discoveryTypeCode) {
      form.setFieldsValue({
        discoveryTypeCode: userSettings.discoveryTypeCode
      });
    }
  }, [userSettings]);

  useLoadingState(
    addDiscoveryLoadingState,
    () => {
      setUserSettings({
        discoveryTypeCode: newDiscovery.discoveryTypeCode
      });

      history.push({
        pathname: `/discoveries/${newDiscovery["_id"]}/start-discovery`
      });
    },
    () => {
      if (error.status === 409) {
        setDuplicateError(true);
      } else {
        message.error(errorText);
        setDuplicateError(false);
      }
    }
  );

  useEffect(() => {
    if (userSettings && userSettings.discoveryTypeCode) {
      form.setFieldsValue({
        discoveryTypeCode: userSettings.discoveryTypeCode
      });
    }
  }, [userSettings]);

  const onFinish = (values) => {
    const data = { ...values, accountId, opportunityId };
    addDiscovery(data);
  };
  const onViewAllDiscoveries = () => {
    history.push({ pathname: "/MyDiscoveries" });
  };

  return (
    <Page header={<BasicBrandHeader />}>
      <SplitPageLayout
        image={salesforceCoverImage || defaultCoverImage}
        hasHeader={true}
      >
        {latestVersionNumber && latestVersionNumber === masterDataVersion ? (
          <Container>
            <Heading level={"h2"}>{header}</Heading>
            <SubHeaderContainer>
              <TextBody>{subheader}</TextBody>
            </SubHeaderContainer>

            {isDuplicateError ? <DiscoveryAlert /> : null}
            <DiscoveryForm
              form={form}
              okText={okText}
              isNameEditable={!accountId}
              isOpportunityEditable={!opportunityId}
              isCurrencyEditable={!currency}
              isDiscoveryTypeEditable={true}
              onFinish={onFinish}
            >
              <NewButton type={"secondary"} onClick={onViewAllDiscoveries}>
                {viewAllDiscoveries}
              </NewButton>
            </DiscoveryForm>
          </Container>
        ) : null}
      </SplitPageLayout>
    </Page>
  );
};

const Container = styled.div`
  padding: 40px;
`;

const SubHeaderContainer = styled.div`
  margin-bottom: 30px;
`;

const mapStateToProps = (state) => {
  return {
    userSettings: selectRequestData(
      state,
      actionTypes.GET_USER_SETTINGS_REQUEST
    ),
    newDiscovery: selectAddDiscoveryResult(state),
    error: selectAddDiscoveryError(state),
    masterDataVersion: selectMasterDataVersion(state),
    latestVersionNumber: selectLatestVersionNumber(state),
    addDiscoveryLoadingState: selectAddDiscoveryLoadingState(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDiscovery,
      setUserSettings,
      getMasterData,
      getLatestVersion
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateDiscovery);
