import { selectRequestData } from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { generateAutoconfiguration } from "../../../../store/actions/autoconfigurations";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AutoconfigurationLayout from "./AutoconfigurationLayout";
import { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import Table from "../../../../components/Table";

const AutoconfigurationCategories = ({
  autoconfiguration,
  generateAutoconfiguration
}) => {
  const [ready, setReady] = useState(false);
  const [data, setData] = useState([]);
  const columns = [];

  useEffect(() => {
    if (autoconfiguration) {
      setData(
        (autoconfiguration?.categories || []).map((o) => ({
          ...o,
          key: o.code
        }))
      );
    }
  }, [autoconfiguration]);

  useEffect(() => {
    if (!autoconfiguration?.categories?.length) {
      generateAutoconfiguration({
        autoconfigurationId: autoconfiguration._id,
        section: "categories"
      });
    } else if (autoconfiguration) {
      setReady(true);
    }
  }, [autoconfiguration]);

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.categories.code",
      "Code"
    ),
    dataIndex: "code",
    key: "code"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.categories.name",
      "Name"
    ),
    dataIndex: "name",
    key: "name"
  });

  return (
    <AutoconfigurationLayout>
      {ready && (
        <Table dataSource={data} columns={columns} pagination={false} />
      )}
    </AutoconfigurationLayout>
  );
};

const mapStateToProps = (state) => ({
  autoconfiguration: selectRequestData(
    state,
    actionTypes.GET_AUTOCONFIGURATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generateAutoconfiguration
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AutoconfigurationCategories);
