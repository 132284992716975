const SalesforceInstallationActions = {
  CREATE_REMOTE_SITE: "CREATE_REMOTE_SITE",
  CONFIGURE_CORS: "CONFIGURE_CORS",
  CREATE_AUTH_PROVIDER_METADATA_RECORD: "CREATE_AUTH_PROVIDER_METADATA_RECORD",
  CREATE_AUTH_PROVIDER: "CREATE_AUTH_PROVIDER",
  CHECK_AUTH_PROVIDER_STATUS: "CHECK_AUTH_PROVIDER_STATUS",
  CREATE_NAMED_CREDENTIAL: "CREATE_NAMED_CREDENTIAL",
  CREATE_SP_CONNECTED_APP: "CREATE_SP_CONNECTED_APP",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED"
};

export default SalesforceInstallationActions;
