import Heading from "../../../../components/Heading";
import Text from "../../../../components/Text";
import { useString as s } from "../../../../components/StringProvider";
import styled from "styled-components";
import PropTypes from "prop-types";
import ConfigurationCardTypes from "./ConfigurationCardTypes";
import DescriptionList from "../../../../components/DescriptionList";
import { hasPermission } from "../../../../store/reducers";
import Permissions from "../../../../utils/permissions";
import { compose } from "redux";
import { connect } from "react-redux";
import { hasSandbox } from "../../../../store/selectors/config";

const ConfigurationNotesTemplate = ({ notesTemplate, cardType, canUpdate }) => {
  const header = s(
    "organization.page.currentConfiguration.notesTemplate.header",
    "Notes Template"
  );
  const noTemplateCanUpdate = s(
    "organization.page.currentConfiguration.notesTemplate.noTemplateCanUpdate",
    'Nothing here yet. To add a template, click the "Update" button above.'
  );
  const noTemplate = s(
    "organization.page.currentConfiguration.notesTemplate.noTemplate",
    "Nothing here yet."
  );
  const lastUpdated = s(
    "organization.page.currentConfiguration.notesTemplate.lastUpdated",
    "Last Updated"
  );
  const updatedBy = s(
    "organization.page.currentConfiguration.notesTemplate.updatedBy",
    "Updated By"
  );
  const createdByDefault = s(
    "accessManagement.page.roles.createdBy.default",
    "Cuvama"
  );

  return (
    <Container>
      <Heading level={"h4"}>{header}</Heading>
      {!!notesTemplate ? (
        <DescriptionList>
          <dt>{lastUpdated}</dt>
          <dd>{new Date(notesTemplate.deployedAt).toDateString()}</dd>
          <dt>{updatedBy}</dt>
          <dd>{notesTemplate.username || createdByDefault}</dd>
        </DescriptionList>
      ) : (
        <Text variant={"small"} color={"gray4"}>
          {canUpdate && cardType === ConfigurationCardTypes.SANDBOX
            ? noTemplateCanUpdate
            : noTemplate}
        </Text>
      )}
    </Container>
  );
};

const Container = styled.div``;

ConfigurationNotesTemplate.propTypes = {
  notesTemplate: PropTypes.object,
  cardType: PropTypes.oneOf(ConfigurationCardTypes.ALL)
};

const mapStateToProps = (state) => ({
  canUpdate:
    hasPermission(state, Permissions.UPDATE_CONFIGURATION) && !hasSandbox(state)
});

export default compose(connect(mapStateToProps))(ConfigurationNotesTemplate);
