import { bindActionCreators, compose } from "redux";
import { createUser } from "../../../../store/actions/users";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import Form from "../../../../components/Form";
import { message } from "antd";
import DuplicateUserAlert from "./DuplicateUserAlert";
import PropTypes from "prop-types";
import {
  selectRequestData,
  selectRequestError,
  selectRequestState
} from "../../../../store/reducers";
import NewButton from "../../../../components/NewButton";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import UserForm from "./UserForm";
import { getRoleByCode } from "../../../../store/actions/roles";
import { RoleCodes } from "../../../../utils/role-codes";
import { Modal } from "../../../../components";
import * as userTypes from "../../../../utils/user-types";

const CreateUserModal = ({
  loadingState,
  error,
  onClose,
  doUsersRefresh,
  createUser,
  getRoleByCode,
  userRole
}) => {
  const [form] = Form.useForm();
  const cancelText = s("accessManagement.page.users.create.cancel", "Cancel");
  const errorText = s(
    "accessManagement.page.users.create.error",
    "Failed to create user"
  );
  const successText = s(
    "accessManagement.page.users.create.success",
    "User was successfully created"
  );
  const addButtonText = {
    default: s("accessManagement.page.userForm.add", "Add"),
    loading: s("accessManagement.page.userForm.adding", "Adding...")
  };

  const [isDuplicateError, setDuplicateError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRoleByCode({ roleCode: RoleCodes.USER });
  }, []);

  useEffect(() => {
    if (userRole) {
      form.setFieldsValue({
        roleIds: [userRole._id]
      });
    }
  }, [userRole]);

  useLoadingState(
    loadingState,
    () => {
      onClose();
      doUsersRefresh();
      message.success(successText);
      setIsLoading(false);
    },
    () => {
      setIsLoading(false);

      if (error) {
        if (error.status === 409) {
          setDuplicateError(true);
        } else {
          setDuplicateError(false);
          message.error(errorText);
        }
      } else {
        setDuplicateError(false);
        message.error(errorText);
      }
    },
    () => {
      setIsLoading(true);
    }
  );

  const mandatoryFields = ["firstName", "lastName", "email", "roleIds"];

  const onValuesChange = () => {
    const someErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length);

    const isFieldsFilled = mandatoryFields.every((field) =>
      form.getFieldValue(field)
    );

    setDisabled(!isFieldsFilled || someErrors);
  };

  const onFinish = async (values) => {
    if (values.email && values.firstName && values.lastName) {
      const roleIds = values.roleIds.filter(
        (roleId) => roleId !== userRole._id
      );

      createUser({
        roleIds,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        country: values.country
      });
    }
  };

  if (!userRole) {
    return null;
  }

  return (
    <Modal
      title={s("accessManagement.popup.addUser.header", "Add User")}
      open={true}
      onCancel={onClose}
    >
      {isDuplicateError ? <DuplicateUserAlert /> : null}
      <UserForm
        form={form}
        onFinish={onFinish}
        submitButtonText={addButtonText}
        onValuesChange={onValuesChange}
        disabled={disabled}
        userType={userTypes.INTERNAL}
        createMode
        isLoading={isLoading}
      >
        <NewButton type={"secondary"} onClick={onClose}>
          {cancelText}
        </NewButton>
      </UserForm>
    </Modal>
  );
};

CreateUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  doUsersRefresh: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.CREATE_USER_REQUEST),
  error: selectRequestError(state, actionTypes.CREATE_USER_REQUEST),
  userRole: selectRequestData(state, actionTypes.GET_ROLE_BY_CODE_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createUser,
      getRoleByCode
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateUserModal
);
