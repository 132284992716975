import React, { useEffect, useState } from "react";
import { CognitoError, Text } from "../../components";
import { compose } from "redux";
import { connect } from "react-redux";
import Input from "../../components/Input";
import Heading from "../../components/Heading";
import Form, { FormItem } from "../../components/Form";
import NewButton from "../../components/NewButton";
import { useString as s } from "../../components/StringProvider";
import {
  isLoginWithUnconfirmedUser,
  selectRequestError
} from "../../store/reducers";
import LoginFormContainer from "../Login/LoginFormContainer";
import ButtonGroup from "../../components/ButtonGroup";
import TextBody from "../../components/TextBody";
import actionTypes from "../../store/actionTypes";

const ConfirmationCodeForm = ({
  confirmSignUpError,
  isLoginWithUnconfirmedUser,
  onFinish,
  onResendCode
}) => {
  const header = s("confirmation_code.page.header", "Verification Code");
  const subheader = s(
    "confirmation_code.page.subheader",
    "Please check your inbox for your verification code. The verification code expires in approximately 24 hours."
  );

  const resendCode = s("confirmation_code.page.resendCode", "Resend Code");
  const verifyCode = s("confirmation_code.page.verifyCode", "Verify Code");
  const haventReceived = s(
    "confirmation_code.page.haventReceived",
    "Haven't received your email with the verification code?"
  );
  const unconfirmedLoginError = s(
    "confirmation_code.page.messages.unconfirmedLoginError",
    "It seems this account has not been confirmed yet. Click {resendCode} to receive a new confirmation code via email.",
    { resendCode }
  );
  const verificationCodeValidation = s(
    "confirmation_code.page.verificationCode.validation",
    "Please input your verification code"
  );
  const verificationCodePlaceholder = s(
    "confirmation_code.page.verificationCode.placeholder",
    "Your verification code"
  );

  const confirmSignUpErrors = {
    "error.confirmSignUp.invalidCode": s(
      "error.confirmSignUp.invalidCode",
      "Sorry, this code is incorrect."
    ),
    "error.confirmSignUp.expiredCode": s(
      "error.registerUser.expiredCode",
      "Sorry, this code has expired."
    )
  };
  const defaultConfirmSignUpError = s(
    "error.confirmSignUp.failed",
    "Sorry, confirmation failed."
  );

  const [error, setError] = useState("");

  useEffect(() => {
    if (confirmSignUpError) {
      setError(
        confirmSignUpErrors[confirmSignUpError?.data?.message] ||
          defaultConfirmSignUpError
      );
    }
  }, [confirmSignUpError]);

  return (
    <LoginFormContainer>
      <Heading level={"h2"}>{header}</Heading>
      <Form
        name="basic"
        initialValues={{ verificationCode: "" }}
        onFinish={onFinish}
        className={"login"}
        layout={"vertical"}
      >
        {isLoginWithUnconfirmedUser ? (
          <CognitoError error={unconfirmedLoginError} />
        ) : (
          <TextBody>{subheader}</TextBody>
        )}
        <FormItem
          name="verificationCode"
          rules={[{ required: true, message: verificationCodeValidation }]}
        >
          <Input placeholder={verificationCodePlaceholder} />
        </FormItem>
        {!!error && (
          <div className={"error-wrapper"}>
            <Text color={"error"}>{error}</Text>
          </div>
        )}
        <ButtonGroup mobile={true}>
          <NewButton type={"submit"} data-cy={"verify-code-button"}>
            {verifyCode}
          </NewButton>
        </ButtonGroup>
      </Form>
      <Text>{haventReceived}</Text>
      <ButtonGroup mobile={true}>
        <NewButton
          type={"alternate"}
          onClick={onResendCode}
          data-cy={"resend-code-button"}
        >
          {resendCode}
        </NewButton>
      </ButtonGroup>
    </LoginFormContainer>
  );
};

function mapStateToProps(state) {
  return {
    isLoginWithUnconfirmedUser: isLoginWithUnconfirmedUser(state),
    confirmSignUpError: selectRequestError(
      state,
      actionTypes.CONFIRM_USER_REQUEST
    )
  };
}

export default compose(connect(mapStateToProps))(ConfirmationCodeForm);
