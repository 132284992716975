import styled from "styled-components";
import { CostOfStatusQuoChart } from "../../ValueHypothesis/components";
import PropType from "prop-types";

const PrintReportCostOfStatusQuo = ({ kpis, currency }) => {
  return (
    <Container id={"print-div"}>
      <CostOfStatusQuoChart
        kpis={kpis}
        currency={currency}
        reportMode={true}
        printMode={true}
        showChartControls={false}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  min-height: 100vh;
  min-width: 100vw;

  & .cost-of-status-quo {
    min-width: fit-content;
    min-height: fit-content;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
`;

PrintReportCostOfStatusQuo.propTypes = {
  currency: PropType.string.isRequired,
  kpis: PropType.array.isRequired
};

export default PrintReportCostOfStatusQuo;
