import React, { useState, useEffect } from "react";
import { Modal } from "../../../components";
import { useString as s } from "../../../components/StringProvider";
import SelectOutcomesOverride from "./SelectOutcomesOverride";
import {
  selectROIFormValues,
  selectROIOverrideType
} from "../../../store/reducers";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { stopROIOverrideOperation } from "../../../store/actions/roi-overrides-operations";
import Form from "../../../components/Form";
import OutcomeOverrideForm from "./OutcomeOverrideForm";
import styled from "styled-components";

export const OutcomeOverrideTypes = {
  IMPLEMENTATION_LENGTH: "implementationLength",
  ADOPTION_TIME: "adoptionTime",
  BENEFIT_ADJUSTMENT_PER_YEAR: "benefitAdjustmentPerYear"
};

const getTitle = (outcomeOverrideType) => {
  const titles = {};

  titles[OutcomeOverrideTypes.IMPLEMENTATION_LENGTH] = s(
    "discovery.roi.overrideModal.implementationLength.title",
    "Override Time to Benefits Start"
  );

  titles[OutcomeOverrideTypes.ADOPTION_TIME] = s(
    "discovery.roi.overrideModal.adoptionTime.title",
    "Override Time to Full Adoption"
  );

  titles[OutcomeOverrideTypes.BENEFIT_ADJUSTMENT_PER_YEAR] = s(
    "discovery.roi.overrideModal.benefitAdjustmentPerYear.title",
    "Override Benefits Adjustment"
  );

  return titles[outcomeOverrideType];
};

const getInputLabel = (outcomeOverrideType) => {
  const inputLabels = {};

  inputLabels[OutcomeOverrideTypes.IMPLEMENTATION_LENGTH] = s(
    "discovery.roi.form.implementationLength.label",
    "Time to Benefits Start, months"
  );

  inputLabels[OutcomeOverrideTypes.ADOPTION_TIME] = s(
    "discovery.roi.form.adoptionTime.label",
    "Override Time to Full Adoption"
  );

  inputLabels[OutcomeOverrideTypes.BENEFIT_ADJUSTMENT_PER_YEAR] = s(
    "discovery.roi.form.benefitAdjustmentPerYear.label",
    "Benefits Adjustment"
  );

  return inputLabels[outcomeOverrideType];
};

const OutcomeOverrideModal = ({
  outcomeOverrideType,
  stopROIOverrideOperation,
  outcomeOverrideTypeClicked,
  overrideEvaluationPeriod
}) => {
  const MODAL_WIDTHS = {
    MAX: "fit-content",
    MIN: 632
  };

  const [form] = Form.useForm();
  const [modalWidth, setModalWidth] = useState(MODAL_WIDTHS.MIN);

  const handleCancel = () => {
    stopROIOverrideOperation();
  };

  useEffect(() => {
    const isBenefitAdjustment =
      outcomeOverrideType === OutcomeOverrideTypes.BENEFIT_ADJUSTMENT_PER_YEAR;

    setModalWidth(
      isBenefitAdjustment && overrideEvaluationPeriod > 5
        ? MODAL_WIDTHS.MAX
        : MODAL_WIDTHS.MIN
    );
  }, [outcomeOverrideType, overrideEvaluationPeriod]);

  return (
    <StyledModal
      width={modalWidth}
      title={getTitle(outcomeOverrideType)}
      open={outcomeOverrideTypeClicked}
      onCancel={handleCancel}
      data-cy={"outcome-override-modal"}
    >
      <OutcomeOverrideForm
        form={form}
        outcomeOverrideType={outcomeOverrideType}
        inputLabel={getInputLabel(outcomeOverrideType)}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    background-color: transparent;
    width: fit-content;

    &:hover {
      background-color: transparent;
    }
  }
`;

SelectOutcomesOverride.propTypes = {
  outcomeOverrideType: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  outcomeOverrideTypeClicked: selectROIOverrideType(state),
  overrideEvaluationPeriod: selectROIFormValues(state)?.evaluationPeriod
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      stopROIOverrideOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  OutcomeOverrideModal
);
