import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const AutoFocusPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor) {
      editor.focus(() => {}, { defaultSelection: "rootStart" });
    }
  }, [editor]);

  return null;
};

export default AutoFocusPlugin;
