import types from "../actionTypes";

const INITIAL_STATE = {
  isCreatingChallenge: false
};

const createChallengesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERIES_FETCH_REQUEST:
    case types.DISCOVERY_FETCH_REQUEST:
      return INITIAL_STATE;

    case types.START_CREATING_CHALLENGE:
      return {
        ...state,
        isCreatingChallenge: true
      };

    case types.STOP_CREATING_CHALLENGE:
      return {
        ...state,
        isCreatingChallenge: false
      };

    default:
      return state;
  }
};

export default createChallengesReducer;

export const selectIsCreatingChallenge = (data) => data.isCreatingChallenge;
