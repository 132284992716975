import { useEffect, useState } from "react";
import { CenteredPageLayout } from "../../components/Layout";
import { Space } from "antd";
import Heading from "../../components/Heading";
import { Text } from "../../components";
import NewButton from "../../components/NewButton";
import Page from "../../components/Page";
import Header, { BrandBadge } from "../../components/Header";
import { useString as s } from "../../components/StringProvider";
import styled from "styled-components";
import Icon from "../../components/Icon";
import { selectRequestData, selectRequestState } from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { getAccess, grantAccess } from "../../store/actions/access";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import { withRouter } from "react-router-dom";
import ErrorPage from "../ErrorPage";

const DiscoveryErrorPageHeader = ({}) => {
  return (
    <Header>
      <BrandBadge linkTo={"/"} />
    </Header>
  );
};

const GrantDiscoveryAccess = ({
  history,
  match,
  access,
  getAccessLoadingState,
  getAccess,
  grantAccessLoadingState,
  grantAccess
}) => {
  const accessId = match.params.accessId;
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(false);
  const header = s(
    "grant_discovery_access.page.header",
    "You shared the discovery"
  );
  const subheader = s(
    "grant_discovery_access.page.subheader",
    "You’ve granted edit discovery access to {username}",
    { username: access ? access.username : undefined }
  );
  const openDiscovery = s(
    "grant_discovery_access.page.openDiscovery",
    "Open discovery"
  );

  useEffect(() => {
    getAccess({ accessId });
    setComplete(false);
    setError(false);
  }, [accessId]);

  useLoadingState(
    getAccessLoadingState,
    () => {
      switch (access.status) {
        case "granted":
          setComplete(true);
          break;
        case "denied":
          setError(true);
          break;
        default:
          grantAccess({ accessId });
          break;
      }
    },
    () => {
      setError(true);
    }
  );

  useLoadingState(
    grantAccessLoadingState,
    () => {
      setComplete(true);
    },
    () => {
      setError(true);
    }
  );

  const onOpenDiscovery = () => {
    history.push({
      pathname: access ? `/discoveries/${access.discoveryId}` : "/"
    });
  };

  return complete ? (
    <Page header={<DiscoveryErrorPageHeader />}>
      <CenteredPageLayout>
        <Container>
          <Space direction={"vertical"} size={16}>
            <Icon colour={"success"} size={"xlarge"} name={"check"} />
            <Heading level={"h3"} data-cy={"grant-access-header"}>
              {header}
            </Heading>
            <Text color={"gray4"} data-cy={"grant-access-sub-header"}>
              {subheader}
            </Text>
            <ButtonContainer>
              <NewButton
                type={"primary"}
                onClick={onOpenDiscovery}
                data-cy={"grant-access-button"}
              >
                {openDiscovery}
              </NewButton>
            </ButtonContainer>
          </Space>
        </Container>
      </CenteredPageLayout>
    </Page>
  ) : error ? (
    <ErrorPage />
  ) : null;
};

const ButtonContainer = styled.div`
  margin-top: 16px;
`;

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  text-align: center;

  h3 {
    text-align: center;
    margin-bottom: 0px;
  }
`;

const mapStateToProps = (state) => ({
  getAccessLoadingState: selectRequestState(
    state,
    actionTypes.GET_ACCESS_REQUEST
  ),
  grantAccessLoadingState: selectRequestState(
    state,
    actionTypes.GRANT_ACCESS_REQUEST
  ),
  access: selectRequestData(state, actionTypes.GET_ACCESS_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccess,
      grantAccess
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GrantDiscoveryAccess);
