import Heading from "../../Heading";
import { useString as s } from "../../StringProvider";
import PropType from "prop-types";
import React from "react";
import Markdown from "../Markdown";
import ChallengeLink from "./ChallengeLink";
import AboutThisCard from "../AboutThisCard";
import KPIMappingsTable from "./KPIMappingsTable";
import { Space } from "antd";
import RateImportance from "../../RateImportance";
import formatMarkdownText from "../../../utils/format-markdown-text";

const MobileDetails = ({
  discoveryChallenge,
  onChangeImportance,
  editable,
  reportId,
  canSeeInternalLinksAndComments
}) => {
  const importanceForYou = s(
    "discovery.challenges.card.important.column",
    "Importance for you"
  );
  const aboutThisChallengeText = s(
    "discovery.challenges.card.aboutThisKPI",
    "About this Challenge"
  );
  const sourcesText = s(
    "discovery.challenges.card.slider.more.section2.title",
    "Sources"
  );
  const linkedKPIText = s(
    "discovery.challenges.card.kpi.column.header",
    "Linked Outcomes"
  );

  const links = discoveryChallenge.definition.links.filter(
    (link) =>
      (!reportId && canSeeInternalLinksAndComments) ||
      link.internalOnly === false ||
      link.internalOnly === undefined
  );

  return discoveryChallenge.selected ? (
    <Space direction={"vertical"} size={8}>
      <RateImportance
        label={importanceForYou}
        editable={editable && discoveryChallenge && discoveryChallenge.selected}
        importance={discoveryChallenge.importance}
        onChangeImportance={onChangeImportance}
        className="up-challenge-card-importance"
      />
      <AboutThisCard text={aboutThisChallengeText}>
        <Space direction={"vertical"} size={8}>
          <div>
            <Markdown size={"small"} paragraphMargin={true}>
              {formatMarkdownText(discoveryChallenge.definition.detailText)}
            </Markdown>
          </div>
          <div>
            <Heading level={"h5"} color={"gray3"}>
              {linkedKPIText}
            </Heading>
            <KPIMappingsTable
              kpiMappings={
                discoveryChallenge && discoveryChallenge.definition
                  ? discoveryChallenge.definition.kpiMappings
                  : []
              }
            />
          </div>
          {links.length ? (
            <div>
              <Heading level={"h5"} color={"gray3"}>
                {sourcesText}
              </Heading>
              {links.map((link, index) => (
                <ChallengeLink link={link} key={index} />
              ))}
            </div>
          ) : null}
        </Space>
      </AboutThisCard>
    </Space>
  ) : null;
};

MobileDetails.propTypes = {
  discoveryChallenge: PropType.object.isRequired,
  onChangeImportance: PropType.func.isRequired,
  editable: PropType.bool.isRequired,
  selected: PropType.bool.isRequired,
  reportId: PropType.string,
  canSeeInternalLinksAndComments: PropType.bool.isRequired
};

MobileDetails.defaultProps = {
  onChangeImportance: () => {},
  editable: false
};

export default MobileDetails;
