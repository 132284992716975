import PropTypes from "prop-types";
import { selectQuestion } from "../../../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Text } from "../../../../components";
import removeMd from "remove-markdown";
import React from "react";
import styled from "styled-components";
import Icon from "../../../../components/Icon";
import { CommentsButton } from "../../../../components/Discovery/Comments";
import { QuestionIconBar } from "../../../Question/components/QuestionForm";
import { useMobileMediaQuery } from "../../../../components/Responsive";

const QuestionAndAnswer = ({
  questionNumber,
  discoveryQuestion,
  discoveryId,
  reportId,
  question
}) => {
  const isMobile = useMobileMediaQuery();
  const getValueText = () => {
    const answerText = [];
    const answers = Array.isArray(discoveryQuestion.answer)
      ? discoveryQuestion.answer
      : [discoveryQuestion.answer];

    for (const answer of answers) {
      const matchingOptions = question.options.filter(
        (option) => option.value === answer
      );
      const valueText =
        matchingOptions.length === 1 ? matchingOptions[0].label : answer;

      if (valueText !== undefined && valueText !== null && valueText !== "") {
        answerText.push(removeMd(valueText));
      }
    }

    return answerText;
  };

  return (
    <QuestionAndAnswerContainer className={isMobile ? "mobile" : undefined}>
      <QuestionTitle>
        <Text variant={isMobile ? "bodyMobile" : "body"}>
          {question.question}
        </Text>
        <QuestionIconBar>
          <CommentsButton
            topic={`/discoveries/${discoveryId}/questions/${questionNumber}`}
            reportId={reportId}
          />
        </QuestionIconBar>
      </QuestionTitle>
      {getValueText().map((text, index) => (
        <Answer key={index}>
          <CheckmarkIcon name={"check"} colour={"primary"} />
          <Text variant={isMobile ? "bMediumMobile" : "bMedium"}>{text}</Text>
        </Answer>
      ))}
    </QuestionAndAnswerContainer>
  );
};

const CheckmarkIcon = styled(Icon)`
  padding-right: 10px;
`;

const QuestionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Answer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QuestionAndAnswerContainer = styled.div`
  margin-bottom: 21px;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;

  &.mobile {
    margin-bottom: 16px;
  }

  &:hover .question-icon-bar {
    visibility: visible;
  }
`;

QuestionAndAnswer.propTypes = {
  discoveryQuestion: PropTypes.object.isRequired,
  discoveryId: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired
};

function mapStateToProps(state, props) {
  return {
    question: selectQuestion(state, props.discoveryQuestion.questionCode)
  };
}

export default compose(withRouter, connect(mapStateToProps))(QuestionAndAnswer);
