import { useState } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Title from "./Title";
import { getTooltipDefaultAttributes } from "../Tooltip";

const TruncatedTitle = ({
  fullText,
  rows,
  placement,
  children,
  theme,
  ...props
}) => {
  const [truncatedInternal, setTruncated] = useState(false);

  const onEllipsisInternal = () => {
    setTruncated(true);
  };

  return (
    <Container className={truncatedInternal ? "hasToolip" : undefined}>
      <Title
        ellipsis={{
          rows,
          expandable: true,
          symbol: <></>,
          tooltip: {
            title: fullText,
            placement,
            ...getTooltipDefaultAttributes({ theme })
          },
          onEllipsis: onEllipsisInternal
        }}
        level={5}
        {...props}
      >
        {children}
      </Title>
    </Container>
  );
};

const Container = styled.div`
  &.hasToolip {
    cursor: help;
  }
`;

TruncatedTitle.propTypes = {
  fullText: PropTypes.any,
  placement: PropTypes.string
};

TruncatedTitle.defaultProps = {
  placement: "top",
  rows: 2
};

export default withTheme(TruncatedTitle);
