import { selectDiscoveryMetric } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import {
  resetDiscoveryMetric,
  saveDiscoveryMetric
} from "../../../store/actions/discoveries";
import { connect } from "react-redux";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useString as s } from "../../../components/StringProvider";
import { SettingsContext } from "../../../components/SettingsProvider";
import * as PropTypes from "prop-types";
import NumberInput from "../../../components/Input/NumberInput";
import TooltipIcon from "../../../components/TooltipIcon";
import ResetButton from "./ResetButton";
import {
  getMetricFormatter,
  getMetricParser,
  getMetricTooltipFormatter
} from "../../../utils/formatting";

const isNumber = (value) => {
  return !isNaN(value) && !isNaN(parseFloat(value));
};

const SubtotalTableRow = ({
  metricCode,
  discoveryId,
  currency,
  discoveryMetric,
  resetDiscoveryMetric,
  saveDiscoveryMetric
}) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;

  const [modified, setModified] = useState(discoveryMetric.modified);
  const [metricValue, setMetricValue] = useState(discoveryMetric.value);
  const [initialMetricValue, setInitialMetricValue] = useState(
    discoveryMetric.value
  );
  const [isUpdating, setUpdating] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    setModified(discoveryMetric.modified);
  }, [discoveryMetric.modified]);

  useEffect(() => {
    setUpdating(false);
  }, [discoveryMetric]);

  useEffect(() => {
    setMetricValue(discoveryMetric.value);
    setInitialMetricValue(discoveryMetric.value);
  }, [discoveryMetric.value]);

  const formatter = getMetricFormatter({
    type: discoveryMetric.type,
    locale,
    currency,
    options: { notation: "standard" }
  });
  const parser = getMetricParser({
    type: discoveryMetric.type,
    locale,
    currency
  });
  const tooltipFormatter = getMetricTooltipFormatter({
    type: discoveryMetric.type,
    locale,
    currency
  });

  const tooltipText = s(
    "discovery.metrics.page.reset.tooltip",
    "Reset to default {defaultValue}",
    { defaultValue: tooltipFormatter(discoveryMetric.defaultValue) }
  );

  const onChange = (v) => {
    if (isNumber(v)) {
      if (discoveryMetric.type === "Percentage" && v > 100) {
        setMetricValue(100);
      } else {
        setMetricValue(v);
      }
    }
  };

  const onSaveChange = (v) => {
    if (isNumber(v)) {
      const newValue = v < 0 ? discoveryMetric.minimumUserValue : v;
      setMetricValue(newValue);

      if (!Object.is(Number(initialMetricValue), Number(newValue))) {
        const changes = { value: newValue };
        setUpdating(true);
        saveDiscoveryMetric({
          discoveryId,
          metricCode: discoveryMetric.metricCode,
          changes
        });
      }
    } else {
      setMetricValue(initialMetricValue);
    }
  };

  const onResetMetric = () => {
    setModified(false);
    setMetricValue(discoveryMetric.defaultValue);
    resetDiscoveryMetric({ discoveryId, metricCode: metricCode });
  };

  return (
    <tr>
      <td>
        {discoveryMetric.name}{" "}
        <TooltipIcon title={discoveryMetric.description} />
      </td>
      <td>
        <NumberInput
          size="large"
          value={metricValue}
          formatter={formatter}
          parser={parser}
          onChange={onChange}
          onCommit={onSaveChange}
          ref={inputRef}
        />
      </td>
      <td>
        <ResetButton
          visible={modified && !isUpdating}
          onReset={onResetMetric}
          tooltipText={tooltipText}
        />
      </td>
    </tr>
  );
};

SubtotalTableRow.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  metricCode: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => ({
  discoveryMetric: selectDiscoveryMetric(state, props.metricCode)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryMetric,
      resetDiscoveryMetric
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SubtotalTableRow
);
