import NotificationTypeNames from "./notification-type-names";
import { useStringTemplate } from "../StringProvider";

export const NotificationCategory = {
  NOTIFICATIONS: "notifications",
  ENGAGEMENT_NOTIFICATIONS: "engagementNotifications"
};

const getNotificationText = ({ type, category }) => {
  const stringsMap = {
    [NotificationTypeNames.COMMENT_ADDED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} added a new comment to ["{objectName}"]({objectURL}) {objectTypeName} in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Added a new comment to the ["{objectName}"]({objectURL}) {objectTypeName}'
    },
    [NotificationTypeNames.EDITED_KPI_VALUE]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} edited a value for ["{objectName}"](/discoveries/{discoveryId}/value-hypothesis) {objectTypeName} in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Edited a value for ["{objectName}"](/discoveries/{discoveryId}/value-hypothesis) {objectTypeName}'
    },
    [NotificationTypeNames.CHALLENGE_ADDED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has added the new ["{objectName}"]({objectURL}) challenge in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has added the new ["{objectName}"]({objectURL}) challenge'
    },
    [NotificationTypeNames.CHALLENGE_REMOVED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has removed the ["{objectName}"]({objectURL}) challenge in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has removed the ["{objectName}"]({objectURL}) challenge'
    },
    [NotificationTypeNames.CHALLENGE_DELETED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has deleted the ["{objectName}"]({objectURL}) challenge in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has deleted the ["{objectName}"]({objectURL}) challenge'
    },
    [NotificationTypeNames.CHALLENGE_TITLE_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has updated the title of the ["{objectName}"]({objectURL}) challenge in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has updated the title of the ["{objectName}"]({objectURL}) challenge'
    },
    [NotificationTypeNames.CHALLENGE_DETAIL_TEXT_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has updated the detail text of the ["{objectName}"]({objectURL}) challenge in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has updated the detail text of the ["{objectName}"]({objectURL}) challenge'
    },
    [NotificationTypeNames.CHALLENGE_DESELECTED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has de-selected the ["{objectName}"]({objectURL}) challenge in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has de-selected the ["{objectName}"]({objectURL}) challenge'
    },
    [NotificationTypeNames.CHALLENGE_SELECTED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has selected the ["{objectName}"]({objectURL}) challenge in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has selected the ["{objectName}"]({objectURL}) challenge'
    },
    [NotificationTypeNames.CHALLENGE_IMPORTANCE_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has changed the importance of the ["{objectName}"]({objectURL}) challenge in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has changed the importance of the ["{objectName}"]({objectURL}) challenge'
    },
    [NotificationTypeNames.METRIC_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has changed the value of ["{objectName}"]({objectURL}) on the inputs page in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has changed the value of ["{objectName}"]({objectURL}) on the inputs page'
    },
    [NotificationTypeNames.ROI_REQUESTED]: {
      [NotificationCategory.NOTIFICATIONS]:
        "{username} requested ROI evaluation for [{discoveryName}](/discoveries/{discoveryId}) discovery.",
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        "Requested ROI evaluation"
    },
    [NotificationTypeNames.KPI_SELECTED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has selected the ["{objectName}"]({objectURL}) outcome on [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has selected the ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_DESELECTED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has de-selected the ["{objectName}"]({objectURL}) outcome on [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has de-selected the ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_ADDED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has added the ["{objectName}"]({objectURL}) outcome on [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has added the ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_REMOVED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has removed the ["{objectName}"]({objectURL}) outcome on [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has removed the ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_DELETED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has deleted the ["{objectName}"]({objectURL}) outcome on [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has deleted the ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_NAME_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has edited the title of ["{objectName}"]({objectURL}) outcome in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has edited the title of ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_DESCRIPTION_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has edited the description of ["{objectName}"]({objectURL}) outcome in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has edited the description of ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_DETAIL_TEXT_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has edited the detail text of ["{objectName}"]({objectURL}) outcome in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has edited the detail text of ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_IMPORTANCE_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has changed the importance of ["{objectName}"]({objectURL}) outcome in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has changed the importance of ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_PERCENTAGE_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has edited an impact percentage for ["{objectName}"]({objectURL}) outcome on [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has edited an impact percentage for ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_VALUE_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has edited an impact value for ["{objectName}"]({objectURL}) outcome in [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has edited an impact value for ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.KPI_RANGE_UPDATED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has changed the range of the ["{objectName}"]({objectURL}) outcome on [{discoveryName}](/discoveries/{discoveryId}) discovery.',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has changed the range of the ["{objectName}"]({objectURL}) outcome'
    },
    [NotificationTypeNames.ACCESS_REQUESTED]: {
      [NotificationCategory.NOTIFICATIONS]:
        '{username} has requested access to [{discoveryName}](/discoveries/{discoveryId}) discovery. {message, select, undefined {} other {\n\n"```{message}```"}} \n\n[Grant access](/access/{accessId}/grant)',
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        'Has requested access. {message, select, undefined {} other {\n\n"```{message}```"}} \n\n[Grant access](/access/{accessId}/grant)'
    },
    [NotificationTypeNames.ACCESS_GRANTED]: {
      [NotificationCategory.NOTIFICATIONS]:
        "{username} has granted edit access to [{discoveryName}](/discoveries/{discoveryId}) discovery.",
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        "Has granted edit access to the discovery"
    },
    [NotificationTypeNames.DISCOVERY_ASSIGNED]: {
      [NotificationCategory.NOTIFICATIONS]:
        "[{discoveryName}](/discoveries/{discoveryId}) discovery has been assigned to you.",
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        "The discovery has been assigned to you"
    },
    [NotificationTypeNames.PROMOTION_REQUESTED]: {
      [NotificationCategory.NOTIFICATIONS]:
        "{username} requested a configuration promotion for {masterDataFileName}",
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        "Requested a configuration promotion for {masterDataFileName}"
    },
    [NotificationTypeNames.PROMOTION_REJECTED]: {
      [NotificationCategory.NOTIFICATIONS]: `{rejectedByUserName} rejected the configuration promotion for {masterDataFileName} with the reason: {rejectionReason}`,
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]: `Rejected the configuration promotion for {masterDataFileName} with the reason: {rejectionReason}`
    },
    [NotificationTypeNames.EXTERNAL_USER_SIGNED_IN]: {
      [NotificationCategory.NOTIFICATIONS]:
        "{username} has signed into [{discoveryName}](/discoveries/{discoveryId}) discovery.",
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        "Has signed into the discovery"
    },
    [NotificationTypeNames.NEW_GUEST_VIEWED_REPORT]: {
      [NotificationCategory.NOTIFICATIONS]:
        "{username} has viewed the discovery report for [{discoveryName}](/discoveries/{discoveryId}).",
      [NotificationCategory.ENGAGEMENT_NOTIFICATIONS]:
        "Has viewed the discovery report"
    }
  };

  if (!stringsMap[type]) {
    return "Error";
  }

  return useStringTemplate(`${category}.${type}`, stringsMap[type][category]);
};

export default getNotificationText;
