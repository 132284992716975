import types from "../actionTypes";

const INITIAL_STATE = {
  kpiLibraryOpen: false,
  scrollToKPICode: null
};

const kpiLibrary = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_KPI_LIBRARY_OPEN:
      return {
        ...state,
        kpiLibraryOpen: action.payload
      };

    case types.SET_SCROLL_TO_KPI_CODE:
      return {
        ...state,
        scrollToKPICode: action.payload
      };

    case types.CLEAR_SCROLL_TO_KPI_CODE:
      return {
        ...state,
        scrollToKPICode: null
      };

    default:
      return state;
  }
};

export default kpiLibrary;
export const selectKPILibraryOpen = (kpiLibraryData) =>
  kpiLibraryData.kpiLibraryOpen;
export const selectScrollToKPICode = (kpiLibraryData) =>
  kpiLibraryData.scrollToKPICode;
