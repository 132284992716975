import React, { useEffect, useState } from "react";
import { useTheme } from "../../components/ThemeProvider";
import { SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import {
  isAuthenticated,
  selectForgotPasswordEmail,
  selectRequestError,
  selectRequestState
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Form from "../../components/Form";
import PropTypes from "prop-types";
import { login, resetPassword } from "../../store/actions/authentication";
import ResetPasswordForm from "./ResetPasswordForm";
import useLoadingState from "../../utils/use-loading-state";
import actionTypes from "../../store/actionTypes";

const ResetPassword = ({
  invitation,
  resetPasswordLoadingState,
  resetPasswordError,
  forgotPasswordEmail,
  isAuthenticated,
  history,
  resetPassword,
  login
}) => {
  const [form] = Form.useForm();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  const invitationCover = useTheme("assets.share_login_cover");
  const nonInvitationCover = useTheme("assets.reset_cover");

  const onFinish = async ({ code, password }) => {
    setPassword(password);
    resetPassword({
      email: forgotPasswordEmail,
      confirmationCode: code,
      password
    });
  };

  useEffect(() => {
    if (!forgotPasswordEmail) {
      if (invitation) {
        history.push({
          pathname: `/invitations/${invitation.code}/enter-email`
        });
      } else {
        history.push({
          pathname: `/forgot-password`
        });
      }
    }
  }, [forgotPasswordEmail]);

  useEffect(() => {
    if (isAuthenticated) {
      if (invitation) {
        history.push({
          pathname: `/discoveries/${invitation.discoveryId}/select-challenges`
        });
      } else {
        history.push({
          pathname: `/`
        });
      }
    }
  }, [isAuthenticated]);

  useLoadingState(
    resetPasswordLoadingState,
    () => {
      setLoading(false);
      login({ email: forgotPasswordEmail, password });
    },
    () => {
      setLoading(false);
    },
    () => {
      setLoading(true);
    }
  );

  return (
    <Page>
      <SplitPageLayout
        image={
          invitation
            ? invitationCover || nonInvitationCover
            : nonInvitationCover
        }
      >
        <ResetPasswordForm
          form={form}
          error={resetPasswordError?.data?.message}
          onFinish={onFinish}
          loading={loading}
        />
      </SplitPageLayout>
    </Page>
  );
};

ResetPassword.propTypes = {
  invitation: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    forgotPasswordEmail: selectForgotPasswordEmail(state),
    resetPasswordLoadingState: selectRequestState(
      state,
      actionTypes.RESET_PASSWORD_REQUEST
    ),
    resetPasswordError: selectRequestError(
      state,
      actionTypes.RESET_PASSWORD_REQUEST
    ),
    isAuthenticated: isAuthenticated(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword,
      login
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);
