import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../StringProvider";
import PropTypes from "prop-types";
import Text from "../../Text";
import styled from "styled-components";
import {
  deleteComment,
  deleteReportComment,
  setDeletingComment
} from "../../../store/actions/comments";
import { selectDeleteCommentLoadingState } from "../../../store/reducers";
import LoadingState from "../../../utils/loading-state";
import NewButton from "../../NewButton";
import AlertBox from "../../AlertBox";
import ButtonGroup from "../../ButtonGroup";
import Modal from "../../Modal";

const DeleteCommentModal = ({
  commentId,
  topic,
  reportId,
  loadingState,
  onClose,
  deleteComment,
  deleteReportComment,
  setDeletingComment
}) => {
  const okText = s("delete_comment.popup.button.ok", "Delete");
  const cancelText = s(
    "delete_comment.popup.button.cancel",
    "Keep the comment"
  );
  const errorText = s("delete_comment.popup.error", "Failed to delete comment");
  const [isInProgress, setInProgress] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    setInProgress(false);
  }, [commentId]);

  useEffect(() => {
    setDeletingComment({ deletingComment: true });

    return () => setDeletingComment({ deletingComment: false });
  }, []);

  useEffect(() => {
    switch (loadingState) {
      case LoadingState.IN_PROGRESS:
        setInProgress(true);
        setError(false);
        break;
      case LoadingState.COMPLETED:
        if (isInProgress) {
          onClose();
        }
        break;
      case LoadingState.FAILED:
        if (isInProgress) {
          setError(true);
          setInProgress(false);
        }
        break;
    }
  }, [loadingState]);

  const onFinish = async () => {
    if (reportId) {
      deleteReportComment({ reportId, commentId, topic });
    } else {
      deleteComment({ commentId, topic });
    }
  };

  return (
    <Modal
      title={s("delete_comment.popup.header", "Delete comment")}
      open={true}
      onCancel={onClose}
      zIndex={1300}
      maskCloseable={false}
    >
      {isError ? <DiscoveryModalAlert title={errorText} /> : null}
      <Text>
        {s(
          "delete_comment.popup.text",
          "Deleting will remove this comment for all."
        )}
      </Text>
      <Footer>
        <ButtonGroup>
          <NewButton
            type={"primary"}
            onClick={onFinish}
            data-cy={"delete-comment-button"}
          >
            {okText}
          </NewButton>
          <NewButton
            type={"secondary"}
            onClick={onClose}
            data-cy={"cancel-delete-comment-button"}
          >
            {cancelText}
          </NewButton>
        </ButtonGroup>
      </Footer>
    </Modal>
  );
};

const Footer = styled.div`
  padding-top: 24px;
`;

const DiscoveryModalAlert = styled(AlertBox)`
  margin-bottom: 16px;
`;

DeleteCommentModal.propTypes = {
  commentId: PropTypes.string.isRequired,
  reportId: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectDeleteCommentLoadingState(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteComment,
      deleteReportComment,
      setDeletingComment
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeleteCommentModal
);
