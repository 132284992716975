import { useString as s } from "../StringProvider";
import NewButton from "../NewButton";
import React from "react";
import {
  hasPermission,
  isDiscoveryRegisterInterestComplete,
  selectDiscoveryId
} from "../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import OnlyIfUserCanAccessDiscoveryPath from "../../Pages/Discovery/components/OnlyIfUserCanAccessDiscoveryPath";
import { ArrowRightOutlined } from "@ant-design/icons";
import RegisterInterestButton from "./RegisterInterestButton";
import { getCheckpointForFormPlacement } from "../../Pages/Discovery/RegisterInterestPrompt";
import Permissions from "../../utils/permissions";
import { selectRegisterInterestFormPlacement } from "../../store/selectors/config";

const GoToValueHypothesisButton = ({
  discoveryId,
  history,
  canSeeLeadGeneration,
  formPlacement,
  isRegistered
}) => {
  const nextButtonText = s("discovery.metrics.button1.text", "Next");
  const isRegisterInterestButton =
    canSeeLeadGeneration &&
    getCheckpointForFormPlacement(formPlacement) === "/business-metrics" &&
    !isRegistered;

  const onClick = () => {
    history.push({
      pathname: `/discoveries/${discoveryId}/value-hypothesis`
    });
  };

  return isRegisterInterestButton ? (
    <OnlyIfUserCanAccessDiscoveryPath path={"value-hypothesis"}>
      <RegisterInterestButton onClick={onClick} />
    </OnlyIfUserCanAccessDiscoveryPath>
  ) : (
    <OnlyIfUserCanAccessDiscoveryPath path={"value-hypothesis"}>
      <NewButton
        type={"primary"}
        data-cy={"modal-animation-button"}
        onClick={onClick}
        className={"up-business-metrics-next-button"}
      >
        {nextButtonText}&nbsp;
        <ArrowRightOutlined />
      </NewButton>
    </OnlyIfUserCanAccessDiscoveryPath>
  );
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  isRegistered: isDiscoveryRegisterInterestComplete(state),
  formPlacement: selectRegisterInterestFormPlacement(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(GoToValueHypothesisButton);
