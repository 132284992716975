import styled from "styled-components";
import { themeProp } from "../theme";

const CardContainer = styled.div.attrs(() => ({ className: "card" }))`
  background: ${themeProp("palette.surface")};
  border-radius: ${themeProp("shape.borderRadius")};
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  flex: 1;

  @media print {
    box-shadow: none;
    border: 1px solid ${themeProp("palette.gray2")};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  padding: 24px 24px 0 24px;
`;
export const CardBody = styled.div.attrs(() => ({ className: "card-body" }))`
  padding: 16px 24px;
`;
export default CardContainer;
