import Select from "../Select";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  selectUsersDiscoveryOwners,
  selectUsersLoadingState
} from "../../store/reducers";
import LoadingState from "../../utils/loading-state";
import { useEffect } from "react";
import { getUsersDiscoveryOwners } from "../../store/actions/users";
import PropTypes from "prop-types";

const SelectUser = ({
  loadingState,
  excludeUsers,
  usersDiscoveryOwners,
  getUsersDiscoveryOwners,
  ...props
}) => {
  useEffect(() => {
    getUsersDiscoveryOwners();
  }, []);

  return loadingState === LoadingState.COMPLETED ? (
    <Select
      showSearch
      optionFilterProp="label"
      options={usersDiscoveryOwners
        .filter((user) => !excludeUsers.includes(user.userId))
        .map((user) => {
          return {
            value: user.userId,
            label: user.email
          };
        })}
      {...props}
    />
  ) : null;
};

SelectUser.propTypes = {
  excludeUsers: PropTypes.array
};

SelectUser.defaultProps = {
  excludeUsers: []
};

const mapStateToProps = (state) => ({
  loadingState: selectUsersLoadingState(state),
  usersDiscoveryOwners: selectUsersDiscoveryOwners(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsersDiscoveryOwners
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectUser
);
