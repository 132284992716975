import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Group from "../../components/Discovery/Group";
import {
  selectDiscoveryId,
  selectDiscoveryKPIsByGroup,
  selectDiscoverySetting
} from "../../store/reducers";
import KPICardAndModals from "../../components/Discovery/KPICard/KPICardAndModals";
import { Space } from "antd";
import { useMobileMediaQuery } from "../../components/Responsive";
import PropTypes from "prop-types";

const KPIGroup = ({ groupCode, initiallyCollapsed, discoveryKPIs }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <Group groupCode={groupCode} initiallyCollapsed={initiallyCollapsed}>
      <Space direction={"vertical"} size={isMobile ? 8 : 14}>
        {discoveryKPIs.map((discoveryKPI) => (
          <KPICardAndModals
            discoveryKPI={discoveryKPI}
            key={discoveryKPI.kpiCode}
          />
        ))}
      </Space>
    </Group>
  );
};

KPIGroup.propTypes = {
  groupCode: PropTypes.string.isRequired,
  initiallyCollapsed: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => ({
  discoveryId: selectDiscoveryId(state),
  discoveryKPIs: selectDiscoveryKPIsByGroup(
    state,
    props.groupCode,
    selectDiscoverySetting(state, "showKPIs", "all")
  )
});

export default compose(connect(mapStateToProps))(KPIGroup);
