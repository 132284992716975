import types from "../actionTypes";
import rolesService from "../../services/roles.service";

export const getRole =
  ({ roleId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_ROLE_REQUEST
    });

    return rolesService
      .getRole({ roleId })
      .then((payload) => {
        dispatch({
          type: types.GET_ROLE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ROLE_FAILURE,
          error
        });
      });
  };

export const getRoleByCode =
  ({ roleCode }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_ROLE_BY_CODE_REQUEST
    });

    return rolesService
      .getRoleByCode({ roleCode })
      .then((payload) => {
        dispatch({
          type: types.GET_ROLE_BY_CODE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ROLE_BY_CODE_FAILURE,
          error
        });
      });
  };

export const getRoles =
  ({ start, count, search, sort, order }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_ROLES_REQUEST
    });

    return rolesService
      .getRoles({ start, count, search, sort, order })
      .then((payload) => {
        dispatch({
          type: types.GET_ROLES_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ROLES_FAILURE,
          error
        });
      });
  };

export const createRole =
  ({ name, description, permissions }) =>
  (dispatch) => {
    dispatch({
      type: types.CREATE_ROLE_REQUEST
    });

    return rolesService
      .createRole({ name, description, permissions })
      .then((payload) => {
        dispatch({
          type: types.CREATE_ROLE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_ROLE_FAILURE,
          error
        });
      });
  };

export const updateRole =
  ({ roleId, name, description, permissions }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_ROLE_REQUEST
    });

    return rolesService
      .updateRole({ roleId, name, description, permissions })
      .then((payload) => {
        dispatch({
          type: types.UPDATE_ROLE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_ROLE_FAILURE,
          error
        });
      });
  };

export const deleteRole =
  ({ roleId }) =>
  (dispatch) => {
    dispatch({
      type: types.DELETE_ROLE_REQUEST
    });

    return rolesService
      .deleteRole({ roleId })
      .then(() => {
        dispatch({
          type: types.DELETE_ROLE_SUCCESS,
          payload: roleId
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_ROLE_FAILURE,
          error
        });
      });
  };
