import { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import styled from "styled-components";
const { Paragraph } = Typography;

const TruncatedRange = ({ fullText, rows, placement, children }) => {
  const [truncatedInternal, setTruncated] = useState(false);
  const onEllipsisInternal = () => {
    setTruncated(true);
  };

  return (
    <Container className={truncatedInternal ? "hasToolip" : undefined}>
      <Paragraph
        style={{ color: "inherit" }}
        ellipsis={{
          rows,
          expandable: true,
          symbol: <></>,
          tooltip: { title: fullText, placement },
          onEllipsis: onEllipsisInternal
        }}
      >
        {children}
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  &.hasToolip {
    cursor: help;
  }
`;

TruncatedRange.propTypes = {
  fullText: PropTypes.any.isRequired,
  placement: PropTypes.string
};

TruncatedRange.defaultProps = {
  placement: "top",
  rows: 2
};

export default TruncatedRange;
