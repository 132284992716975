import { BasicBrandHeader, SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectDiscoveryPhase, selectDiscoveryROI } from "../../store/reducers";
import { useTheme } from "../../components/ThemeProvider";
import { useString as s } from "../../components/StringProvider";
import NameAndEmailForm from "../../components/DiscoveryOperations/ShareDiscoveryModal/NameAndEmailForm";
import Heading from "../../components/Heading";
import TextBody from "../../components/TextBody";
import styled from "styled-components";
import restrictROITemplate from "../../utils/restrict-roi-template";
import ManageDiscoveryAccess from "../../components/DiscoveryOperations/ShareDiscoveryModal/ManageDiscoveryAccess";
import NewButton from "../../components/NewButton";
import Icon from "../../components/Icon";

const ShareDiscovery = ({ phase, roi }) => {
  const header = s("share.page.header", "" + "Share Discovery");
  const subheader = s(
    "share.page.subheader",
    "Allow your customer or colleague to edit the discovery content, or simply share the report"
  );

  const [page, setPage] = useState(1);

  const onNext = () => {
    setPage(2);
  };

  const onPrevious = () => {
    setPage(1);
  };

  const renderPage = () => {
    switch (page) {
      case 1:
        return (
          <NameAndEmailForm
            template={restrictROITemplate({ templateToRestrict: phase, roi })}
            fixActionButtons={false}
            onNext={onNext}
          />
        );

      case 2:
        return (
          <ManageDiscoveryAccess
            onPrevious={onPrevious}
            fixActionButtons={false}
          />
        );

      default:
    }
  };

  const manageAccessTitle = () => {
    return (
      <ManageAccessTitleContainer>
        <NewButton
          type={"iconSecondary"}
          onClick={onPrevious}
          data-cy={"manage-access-back-arrow-button"}
        >
          <StyledIcon name={"arrowLeft2"} size={"larger"} />
        </NewButton>
        <div>{s("share.popup.header.page2", "Manage Access")}</div>
      </ManageAccessTitleContainer>
    );
  };

  return (
    <Page header={<BasicBrandHeader />}>
      <SplitPageLayout
        image={useTheme("assets.salesforce_share_discovery_cover")}
        hasHeader={true}
      >
        <Container>
          <FormContainer>
            <Heading data-cy="share-discovery-header" level={"h2"}>
              {page === 1 ? header : manageAccessTitle()}
            </Heading>
            {page === 1 && (
              <SubHeaderContainer>
                <TextBody data-cy="share-discovery-subheader">
                  {subheader}
                </TextBody>
              </SubHeaderContainer>
            )}
            {renderPage()}
          </FormContainer>
        </Container>
      </SplitPageLayout>
    </Page>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  margin-top: 120px;
  margin-left: 85px;
  margin-right: 85px;
`;

const SubHeaderContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledIcon = styled(Icon)`
  margin-top: 2px;
  margin-left: -8px;
  color: black;
`;

const ManageAccessTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
`;

const mapStateToProps = (state) => {
  return {
    phase: selectDiscoveryPhase(state),
    roi: selectDiscoveryROI(state)
  };
};

export default compose(connect(mapStateToProps))(ShareDiscovery);
