const adaptAmplifyConfig = (auth) => {
  return {
    Cognito: {
      userPoolId: auth.userPoolId,
      userPoolClientId: auth.userPoolWebClientId,
      signUpVerificationMethod: "code",
      loginWith: auth?.oauth
        ? {
            oauth: {
              ...auth.oauth,
              redirectSignIn: [auth.oauth.redirectSignIn],
              redirectSignOut: [auth.oauth.redirectSignOut],
              scopes: ["openid"]
            }
          }
        : undefined
    }
  };
};

export default adaptAmplifyConfig;
