import types from "../actionTypes";

export const openNotificationMessage = (data) => (dispatch) => {
  dispatch({
    type: types.NOTIFICATION_MESSAGE_OPEN,
    payload: data
  });
};

export const closeNotificationMessage =
  ({ key }) =>
  (dispatch) => {
    dispatch({
      type: types.NOTIFICATION_MESSAGE_CLOSE,
      payload: { key }
    });
  };
