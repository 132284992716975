import styled from "styled-components";
import React from "react";
import PoweredByCuvama from "../PoweredByCuvama";

const FullPageLayout = ({ children, ...props }) => (
  <Container {...props}>
    <Main>{children}</Main>
    <PoweredByCuvama />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const Main = styled.div`
  flex-direction: column;
  flex: 1;
`;

export default FullPageLayout;
