import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import PropType from "prop-types";
import KPISummaryCards from "../../KPISummary/KPISummaryCards";

const PrintReportKPIs = ({ discovery }) => {
  const header = s(
    "report.kpi.header",
    "Success Outcomes for {discoveryName}",
    {
      discoveryName: discovery.name
    }
  );
  return (
    <Container>
      <Heading level={"h1"} align={"center"}>
        {header}
      </Heading>
      <Heading level="h4" align="center">
        {s(
          "report.kpis.subheader",
          "By solving the identified business challenges together, we will target the following Success Outcomes"
        )}
      </Heading>
      <MarginContainer>
        <KPISummaryCards reportId={discovery.reportId} printMode={true} />
      </MarginContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${themeProp("palette.surface")};
  display: flex;
  flex-direction: column;
`;

const MarginContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

PrintReportKPIs.propTypes = {
  discovery: PropType.object.isRequired
};

export default PrintReportKPIs;
