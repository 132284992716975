import { bindActionCreators, compose } from "redux";
import {
  fetchDiscovery,
  refreshDiscovery,
  resetDiscoveryKPIInfoBox
} from "../../store/actions/discoveries";
import { getMasterData } from "../../store/actions/master-data";
import { getDiscoveryCommentCount } from "../../store/actions/comments";
import { getCookie } from "../../store/actions/cookies";
import { getAdminSettings } from "../../store/actions/config";
import { Prompt, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  hasPermission,
  isDiscoveryRegisterInterestComplete,
  selectDiscoveryId
} from "../../store/reducers";
import Permissions from "../../utils/permissions";
import RegisterInterestPlacements from "../../utils/register-interest-placements";
import getCheckpointFromPathname from "../../utils/get-checkpoint-from-pathname";
import { compareCheckpoints } from "./components/OnlyIfUserCanAccessDiscoveryPath";
import { useEffect, useState } from "react";
import RegisterInterestModal from "../../components/Discovery/RegisterInterestModal";
import { selectRegisterInterestFormPlacement } from "../../store/selectors/config";

export const getCheckpointForFormPlacement = (formPlacement) => {
  switch (formPlacement) {
    case RegisterInterestPlacements.AFTER_SELECT_KPIS:
      return "/select-kpis";
    case RegisterInterestPlacements.AFTER_METRICS:
      return "/business-metrics";
    case RegisterInterestPlacements.AFTER_SELECT_CHALLENGES:
      return "/select-challenges";
  }
};

const RegisterInterestPrompt = ({
  discoveryId,
  formPlacement,
  canSeeLeadGeneration,
  isRegistered,
  history
}) => {
  const [checkpointAfterForm, setCheckpointAfterForm] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (isRegistered && checkpointAfterForm) {
      history.push({
        pathname: `/discoveries/${discoveryId}${checkpointAfterForm}`
      });
      setCheckpointAfterForm("");
    }
  }, [isRegistered]);

  const onClose = () => {
    setModalVisible(false);
  };

  if (!canSeeLeadGeneration || isRegistered) {
    return null;
  }

  return (
    <>
      <Prompt
        message={(location) => {
          const checkpoint = getCheckpointFromPathname(location);
          const formCheckpoint = getCheckpointForFormPlacement(formPlacement);

          if (
            compareCheckpoints({
              checkpoint1: checkpoint,
              checkpoint2: formCheckpoint
            }) <= 0
          ) {
            return true;
          }

          setCheckpointAfterForm(checkpoint);
          setModalVisible(true);

          return false;
        }}
      />
      {modalVisible && <RegisterInterestModal onClose={onClose} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
    isRegistered: isDiscoveryRegisterInterestComplete(state),
    formPlacement: selectRegisterInterestFormPlacement(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDiscovery,
      resetDiscoveryKPIInfoBox,
      getMasterData,
      getDiscoveryCommentCount,
      refreshDiscovery,
      getCookie,
      getAdminSettings
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RegisterInterestPrompt);
