import types from "../actionTypes";
import crmService from "../../services/crm.service";

export const getAccounts =
  ({ search, start, count } = {}) =>
  (dispatch) => {
    dispatch({
      type: types.GET_CRM_ACCOUNTS_REQUEST
    });

    return crmService
      .getAccounts({ search, start, count })
      .then((payload) => {
        dispatch({
          type: types.GET_CRM_ACCOUNTS_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CRM_ACCOUNTS_FAILURE,
          error
        });
      });
  };

export const getOpportunities =
  ({ accountId, search, start, count } = {}) =>
  (dispatch) => {
    dispatch({
      type: types.GET_CRM_OPPORTUNITIES_REQUEST
    });

    return crmService
      .getOpportunities({
        accountId,
        search,
        start,
        count
      })
      .then((payload) => {
        dispatch({
          type: types.GET_CRM_OPPORTUNITIES_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CRM_OPPORTUNITIES_FAILURE,
          error
        });
      });
  };
