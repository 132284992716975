import types from "../actionTypes";
import usersService from "../../services/users.service";

export const getAllUsers =
  ({ start, count, search, sort, order, userType }) =>
  (dispatch) => {
    dispatch({
      type: types.USERS_FETCH_REQUEST
    });

    return usersService
      .getAllUsers({ start, count, search, sort, order, userType })
      .then((data) => {
        dispatch({
          type: types.USERS_FETCH_SUCCESS,
          payload: { data }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.USERS_FETCH_FAILURE,
          payload: { error }
        });
      });
  };

export const getUsersDiscoveryOwners = () => (dispatch) => {
  dispatch({
    type: types.USERS_DISCOVERY_OWNERS_FETCH_REQUEST
  });

  return usersService
    .getUsersDiscoveryOwners()
    .then((data) => {
      dispatch({
        type: types.USERS_DISCOVERY_OWNERS_FETCH_SUCCESS,
        payload: { data }
      });
    })
    .catch((error) => {
      dispatch({
        type: types.USERS_DISCOVERY_OWNERS_FETCH_FAILURE,
        payload: { error }
      });
    });
};

export const updateUser =
  ({ userId, roleIds, newEmail, firstName, lastName, jobTitle, country }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_USER_REQUEST
    });

    return usersService
      .updateUser({
        userId,
        roleIds,
        newEmail,
        firstName,
        lastName,
        jobTitle,
        country
      })
      .then((payload) => {
        dispatch({
          type: types.UPDATE_USER_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_USER_FAILURE,
          error
        });
      });
  };

export const createUser =
  ({ email, roleIds, firstName, lastName, jobTitle, country }) =>
  (dispatch) => {
    dispatch({
      type: types.CREATE_USER_REQUEST
    });

    return usersService
      .createUser({ email, roleIds, firstName, lastName, jobTitle, country })
      .then((payload) => {
        dispatch({
          type: types.CREATE_USER_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_USER_FAILURE,
          error
        });
      });
  };

export const deactivateUser =
  ({ userId }) =>
  (dispatch) => {
    dispatch({
      type: types.USERS_DEACTIVATE_REQUEST
    });

    return usersService
      .deactivateUser({ userId })
      .then((payload) => {
        dispatch({
          type: types.USERS_DEACTIVATE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.USERS_DEACTIVATE_FAILURE,
          error
        });
      });
  };

export const reactivateUser =
  ({ userId, roleIds }) =>
  (dispatch) => {
    dispatch({
      type: types.USERS_REACTIVATE_REQUEST
    });

    return usersService
      .reactivateUser({ userId, roleIds })
      .then((payload) => {
        dispatch({
          type: types.USERS_REACTIVATE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.USERS_REACTIVATE_FAILURE,
          error
        });
      });
  };

export const generatePassword =
  ({ userId }) =>
  (dispatch) => {
    dispatch({
      type: types.USER_GENERATE_PASSWORD_REQUEST
    });

    return usersService
      .generatePassword({ userId })
      .then((payload) => {
        dispatch({
          type: types.USER_GENERATE_PASSWORD_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.USER_GENERATE_PASSWORD_FAILURE,
          error
        });
      });
  };

export const resetPassword = () => (dispatch) => {
  dispatch({
    type: types.USER_RESET_PASSWORD_REQUEST
  });
};

export const startAccessManagementOperation =
  ({ operationId, userId, roleId }) =>
  (dispatch) => {
    dispatch({
      type: types.START_ACCESS_MGMT_OPERATION,
      payload: {
        operationId,
        userId,
        roleId
      }
    });

    return Promise.resolve();
  };

export const stopAccessManagementOperation = () => (dispatch) => {
  dispatch({
    type: types.STOP_ACCESS_MGMT_OPERATION
  });

  return Promise.resolve();
};

const users = {
  getAllUsers
};

export default users;
