import PropType from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../SettingsProvider";
import { useString as s } from "../../StringProvider";
import Text from "../../Text";
import Form, { FormItem } from "../../Form";
import { Space } from "antd";
import styled from "styled-components";
import KPINumberInput from "./KPINumberInput";
import NewButton from "../../NewButton";
import Input from "../../Input";
import DefinitionTypes from "../../../utils/definition-types";
import KPITypes from "../../../utils/kpi-types";
import {
  getCurrencyFormatter,
  getCurrencySymbol,
  getMetricParser,
  getPercentageFormatter
} from "../../../utils/formatting";
import { useMobileMediaQuery } from "../../Responsive";
import ButtonGroup from "../../ButtonGroup";
import MetricTypes from "../../../utils/metric-types";

const EditKPIValueForm = ({
  form,
  currency,
  discoveryKPI,
  hasComment,
  onFinish,
  onCancel
}) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const isMobile = useMobileMediaQuery();
  const localSettings = { ...settings, currency };
  const helpText = s(
    "editKPIValue.popup.subheader",
    "Please provide % impact and/or value for the new customer outcome that has been agreed:"
  );
  const impactPercentageLabelText = s(
    "editKPIValue.popup.impact.label",
    "Impact for you"
  );
  const impactValueLabelText = s("editKPIValue.popup.value.label", "Value");
  const commentPlaceholderText = s(
    "editKPIValue.popup.commentText.placeholder",
    "What is the source of value numbers?"
  );
  const commentValidationText = s(
    "editKPIValue.popup.commentText.validationText",
    "Please add a note to save {currencySymbol} value",
    { currencySymbol: getCurrencySymbol({ settings: localSettings }) }
  );
  const commentRevalidationText = s(
    "editKPIValue.popup.commentText.revalidationText",
    "Please review the note to save {currencySymbol} value",
    { currencySymbol: getCurrencySymbol({ settings: localSettings }) }
  );
  const okText = s("editKPIValue.popup.success.button", "Save");
  const cancelText = s("editKPIValue.popup.cancel.button", "Cancel");
  const to = s("editKPIValue.popup.to", "to");
  const [commentVisible, setCommentVisible] = useState(hasComment);
  const [lowImpactValue, setLowImpactValue] = useState(
    discoveryKPI.lowImpactValue
  );
  const [potentialImpactValue, setPotentialImpactValue] = useState(
    discoveryKPI.potentialImpactValue
  );
  const [validateComment, setValidateComment] = useState(false);

  useEffect(() => {
    setCommentVisible(
      hasComment ||
        (lowImpactValue && lowImpactValue !== 0) ||
        (potentialImpactValue && potentialImpactValue !== 0) ||
        discoveryKPI.lowImpactValue ||
        discoveryKPI.potentialImpactValue
    );
  }, [discoveryKPI, lowImpactValue, potentialImpactValue, hasComment]);

  useEffect(() => {
    if (
      hasComment &&
      (discoveryKPI.lowImpactValue !== lowImpactValue ||
        discoveryKPI.potentialImpactValue !== potentialImpactValue)
    ) {
      setValidateComment(true);
    }
  }, [discoveryKPI, lowImpactValue, potentialImpactValue, hasComment]);

  useEffect(() => {
    form.validateFields(["commentText"]);
  }, [validateComment]);

  const formatPercentage = getPercentageFormatter({});
  const parsePercentage = getMetricParser({
    type: MetricTypes.PERCENTAGE,
    locale,
    currency
  });
  const formatCurrency = getCurrencyFormatter({ settings: localSettings });
  const parseCurrency = getMetricParser({
    type: MetricTypes.CURRENCY,
    locale,
    currency
  });
  const disablePercentages =
    discoveryKPI.definition.status !== DefinitionTypes.NEW &&
    discoveryKPI.definition.type !== KPITypes.UNQUANTIFIED;

  const onCommentFocus = () => {
    setValidateComment(false);
  };

  const lowImpactPercentFormItem = (
    <FormItem name="lowImpactPercentage">
      <StyledNumberInput
        size={"medium"}
        isMobile={isMobile}
        formatter={formatPercentage}
        parser={parsePercentage}
        placeholder={"%"}
        disabled={disablePercentages}
      />
    </FormItem>
  );

  const highImpactPercentFormItem = (
    <FormItem name="highImpactPercentage">
      <StyledNumberInput
        size={"medium"}
        isMobile={isMobile}
        formatter={formatPercentage}
        parser={parsePercentage}
        placeholder={"%"}
        disabled={disablePercentages}
      />
    </FormItem>
  );

  const toText = <Text variant={"body"}>&nbsp;{to}&nbsp;</Text>;

  const impactPercentageLabel = (
    <Text
      variant={"body"}
      color={"gray4"}
      style={{ display: "block", marginBottom: isMobile ? "10px" : "0px" }}
    >
      {impactPercentageLabelText}
    </Text>
  );

  const impactValueLabel = (
    <Text variant={"body"} color={"gray4"} style={{ display: "block" }}>
      {impactValueLabelText}
    </Text>
  );

  const lowImpactValueFormItem = (
    <FormItem name="lowImpactValue">
      <StyledNumberInput
        size={"medium"}
        isMobile={isMobile}
        formatter={formatCurrency}
        parser={parseCurrency}
        placeholder={getCurrencySymbol({ settings: localSettings })}
        onChange={setLowImpactValue}
      />
    </FormItem>
  );

  const potentialImpactValueFormItem = (
    <FormItem name="potentialImpactValue">
      <StyledNumberInput
        size={"medium"}
        isMobile={isMobile}
        formatter={formatCurrency}
        parser={parseCurrency}
        placeholder={getCurrencySymbol({ settings: localSettings })}
        onChange={setPotentialImpactValue}
      />
    </FormItem>
  );

  return (
    <Form form={form} layout="vertical" initialValues={{}} onFinish={onFinish}>
      <Text
        variant={isMobile ? "bodyMobile" : "body"}
        style={{ display: "block", marginBottom: 16 }}
      >
        {helpText}
      </Text>
      <Text variant={"bMedium"} style={{ display: "block", marginBottom: 8 }}>
        {discoveryKPI.definition.name}
      </Text>
      <Space direction={"vertical"}>
        {impactPercentageLabel}
        <Space direction={"horizontal"} align={"baseline"}>
          {lowImpactPercentFormItem}
          {toText}
          {highImpactPercentFormItem}
        </Space>

        {impactValueLabel}
        <Space direction={"horizontal"} align={"baseline"}>
          {lowImpactValueFormItem}
          {toText}
          {potentialImpactValueFormItem}
        </Space>
      </Space>
      {commentVisible ? (
        <FormItem
          name="commentText"
          rules={[
            {
              required: true,
              message: commentValidationText,
              whitespace: true
            },
            () => ({
              validator() {
                if (!validateComment) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error(commentRevalidationText));
                }
              }
            })
          ]}
        >
          <Input
            placeholder={commentPlaceholderText}
            onFocus={onCommentFocus}
          />
        </FormItem>
      ) : null}
      <FormItem>
        <ButtonContainer>
          <ButtonGroup>
            <NewButton
              type={"submit"}
              data-cy={"editKPIValue-submit-button"}
              className={"up-edit-outcome-modal-save-button"}
            >
              {okText}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onCancel}
              data-cy={"editKPIValue-cancel-button"}
            >
              {cancelText}
            </NewButton>
          </ButtonGroup>
        </ButtonContainer>
      </FormItem>
    </Form>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 24px;
`;

const StyledNumberInput = styled(KPINumberInput)`
  & .ant-input-number-input {
    width: ${({ isMobile }) => (isMobile ? "100px" : "120px")};
    min-width: ${({ isMobile }) => (isMobile ? "100px" : "120px")};
    max-width: ${({ isMobile }) => (isMobile ? "100px" : "120px")};
  }
`;

EditKPIValueForm.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  form: PropType.object.isRequired,
  onFinish: PropType.func.isRequired,
  onCancel: PropType.func.isRequired
};

export default EditKPIValueForm;
