import PropTypes from "prop-types";
import Select, { Option } from "../Select";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { selectRequestData, selectRequestState } from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import { useEffect } from "react";
import useLoadingState from "../../utils/use-loading-state";
import { useString as s } from "../StringProvider";
import { message } from "antd";
import { getDiscoveryExportTemplates } from "../../store/actions/discoveries";

const SelectExportTemplate = ({
  format,
  discoveryId,
  getDiscoveryExportTemplates,
  loadingState,
  templates,
  ...props
}) => {
  const failureText = s(
    "export_powerpoint.popup.selectExportTemplate.messages.failure",
    "Failed to get export templates"
  );

  useEffect(() => {
    getDiscoveryExportTemplates({ discoveryId, format });
  }, [discoveryId, format]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      message.error(failureText);
    }
  );

  return (
    <Select {...props}>
      {templates.map((template) => (
        <Option key={template.code} value={template.code}>
          <span>{template.name}</span>
        </Option>
      ))}
    </Select>
  );
};

SelectExportTemplate.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  format: PropTypes.oneOf(["powerpoint", "email"])
};

const mapStateToProps = (state) => ({
  templates:
    selectRequestData(
      state,
      actionTypes.GET_DISCOVERY_EXPORT_TEMPLATES_REQUEST
    ) || [],
  loadingState: selectRequestState(
    state,
    actionTypes.GET_DISCOVERY_EXPORT_TEMPLATES_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDiscoveryExportTemplates
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectExportTemplate
);
