import types from "../actionTypes";
import invitationsService from "../../services/invitations.service";

export const getInvitation =
  ({ invitationCode }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_INVITATION_REQUEST
    });

    return invitationsService
      .getInvitation({
        invitationCode
      })
      .then((payload) => {
        dispatch({
          type: types.GET_INVITATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_INVITATION_FAILURE,
          error
        });
      });
  };

export const invitationCheckEmailStatus =
  ({ invitationCode, email }) =>
  (dispatch) => {
    dispatch({
      type: types.INVITATION_CHECK_EMAIL_STATUS_REQUEST
    });

    return invitationsService
      .checkEmailStatus({
        invitationCode,
        email
      })
      .then((payload) => {
        dispatch({
          type: types.INVITATION_CHECK_EMAIL_STATUS_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.INVITATION_CHECK_EMAIL_STATUS_FAILURE,
          error
        });
      });
  };

export const updateMyInvitations = () => (dispatch) => {
  dispatch({
    type: types.UPDATE_MY_INVITATIONS_REQUEST
  });

  return invitationsService
    .updateMyDiscoveries()
    .then((payload) => {
      dispatch({
        type: types.UPDATE_MY_INVITATIONS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_MY_INVITATIONS_FAILURE,
        error
      });
    });
};
