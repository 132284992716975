import types from "../actionTypes";
import {
  fetchAuthSession,
  signIn,
  signOut,
  resendSignUpCode
} from "aws-amplify/auth";
import usersService from "../../services/users.service";
import httpService from "../../services/http.service";
import { RoleCodes } from "../../utils/role-codes";
import cognitoStorageService from "../../services/cognito-storage.service";
import { Userpilot } from "userpilot";

export const loginAsLeadGenerationUser = () => (dispatch) => {
  dispatch({
    type: types.LOGIN_REQUEST
  });

  usersService
    .loginAsLeadGenerationUser()
    .then(({ tokens, config, user }) => {
      const cookieStorage = cognitoStorageService.getStorage();
      const shortPrefix = `CognitoIdentityServiceProvider.${config.userPoolWebClientId}`;
      const prefix = `${shortPrefix}.${user.email}`;
      const userData = {
        loginId: user.email,
        authFlowType: "USER_PASSWORD_AUTH"
      };

      cookieStorage.setItem(`${prefix}.accessToken`, tokens.accessToken);
      cookieStorage.setItem(`${prefix}.refreshToken`, tokens.refreshToken);
      cookieStorage.setItem(`${prefix}.idToken`, tokens.idToken);
      cookieStorage.setItem(`${shortPrefix}.LastAuthUser`, user.email);
      cookieStorage.setItem(`${prefix}.clockDrift`, 0);
      cookieStorage.setItem(
        `${prefix}.signInDetails`,
        JSON.stringify(userData)
      );

      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: {
          token: tokens.accessToken
        }
      });

      dispatch({
        type: types.AMPLIFY_SIGNED_IN,
        payload: {
          token: tokens.accessToken
        }
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: types.LOGIN_FAILURE,
        payload: { error }
      });
    });
};

export const login =
  ({ email, password }) =>
  (dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST
    });

    signIn({
      username: email,
      password,
      options: { authFlowType: "USER_PASSWORD_AUTH" }
    })
      .then(() => {
        fetchAuthSession().then(({ tokens }) => {
          dispatch({
            type: types.LOGIN_SUCCESS,
            payload: {
              token: tokens.accessToken
            }
          });
        });
      })
      .catch((error) => {
        const isLoginWithUnconfirmedUser =
          error?.code === "UserNotConfirmedException";
        const username = isLoginWithUnconfirmedUser ? email : undefined;

        dispatch({
          type: types.LOGIN_FAILURE,
          payload: { isLoginWithUnconfirmedUser, username },
          error
        });
      });
  };

export const registerUser =
  ({ email, password }) =>
  (dispatch) => {
    dispatch({
      type: types.REGISTER_USER_REQUEST
    });

    usersService
      .registerUser({
        email,
        password
      })
      .then(() => {
        dispatch({
          type: types.REGISTER_USER_SUCCESS,
          payload: { username: email }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.REGISTER_USER_FAILURE,
          error
        });
      });
  };

export const confirmSignUp =
  ({ email, confirmationCode, password }) =>
  (dispatch) => {
    dispatch({
      type: types.CONFIRM_USER_REQUEST
    });

    usersService
      .confirmSignUp({ email, confirmationCode, password })
      .then((payload) => {
        dispatch({
          type: types.CONFIRM_USER_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CONFIRM_USER_FAILURE,
          error
        });
      });
  };

export const resendSignUp =
  ({ username }) =>
  (dispatch) => {
    dispatch({
      type: types.RESEND_CONFIRMATION_REQUEST
    });

    resendSignUpCode({ username })
      .then((confirmation) => {
        dispatch({
          type: types.RESEND_CONFIRMATION_SUCCESS,
          payload: { confirmation }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.RESEND_CONFIRMATION_FAILURE,
          payload: { error: error }
        });
      });
  };

export const logout = () => (dispatch) => {
  dispatch({
    type: types.LOGOUT_REQUEST
  });

  signOut()
    .then(() => {
      dispatch({
        type: types.LOGOUT_SUCCESS
      });
    })
    .catch((error) => {
      dispatch({
        type: types.LOGOUT_FAILURE,
        payload: { error: error }
      });
    });
};

const getRolesForUserPilot = ({ roles }) => {
  return roles.filter((r) => RoleCodes.ROLES_FOR_USER_PILOT.includes(r.code));
};

export const getMyUserInformation = () => (dispatch) => {
  dispatch({
    type: types.USER_INFO_REQUEST
  });

  usersService
    .getMyUserInformation()
    .then((user) => {
      dispatch({
        type: types.USER_INFO_SUCCESS,
        payload: { user }
      });

      if (user.roles.find((role) => role.name === "Guest")) {
        console.log("don't call UserPilot or Clarity for guests");
        return;
      }

      const dominantRole = RoleCodes.getDominantRole({
        codes: user.roles.map((role) => role.code)
      });

      const roles = getRolesForUserPilot({ roles: user.roles });
      const rolesMap = roles
        .map((role, index) => ({
          key: `role${index}`,
          value: role.code
        }))
        .reduce((map, item) => {
          map[item.key] = item.value;
          return map;
        }, {});

      console.log("Userpilot.identify");
      const tenantId = httpService.getTenantId();
      Userpilot.identify(user.userId, {
        email: user.email,
        created_at: user.createdAt,
        role: dominantRole,
        tenantId,
        company: {
          id: tenantId,
          name: tenantId
        },
        hostname: location.hostname,
        ...rolesMap
      });

      if (window.clarity) {
        console.log("Clarity.identify");
        window.clarity("identify", user.userId);
        window.clarity("set", "role", dominantRole);
        window.clarity("set", "tenantId", httpService.getTenantId());
        window.clarity("set", "hostname", location.hostname);
      }
    })
    .catch((error) => {
      dispatch({
        type: types.USER_INFO_FAILURE,
        payload: { error: error }
      });
    });
};

export const forgotPassword =
  ({ email, verifyAccount }) =>
  (dispatch) => {
    dispatch({
      type: types.FORGOT_PASSWORD_REQUEST
    });

    usersService
      .forgotPassword({ email, verifyAccount })
      .then(() => {
        dispatch({
          type: types.FORGOT_PASSWORD_SUCCESS,
          payload: {
            email
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.FORGOT_PASSWORD_FAILURE,
          payload: { error }
        });
      });
  };

export const resetPassword =
  ({ email, confirmationCode, password }) =>
  (dispatch) => {
    dispatch({
      type: types.RESET_PASSWORD_REQUEST
    });

    usersService
      .resetPassword({ email, confirmationCode, password })
      .then(() => {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESS,
          payload: {
            email
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.RESET_PASSWORD_FAILURE,
          payload: { error },
          error
        });
      });
  };

export const amplifyInitialized =
  ({ token } = {}) =>
  (dispatch) => {
    dispatch({
      type: types.AMPLIFY_INITIALIZED,
      payload: { token }
    });
  };

export const amplifySignedIn =
  ({ token }) =>
  (dispatch) => {
    dispatch({
      type: types.AMPLIFY_SIGNED_IN,
      payload: { token }
    });
  };

export const amplifySignedOut = () => (dispatch) => {
  dispatch({
    type: types.AMPLIFY_SIGNED_OUT
  });
};
