import types from "../actionTypes";

export const setKPILibraryOpen =
  ({ open }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_KPI_LIBRARY_OPEN,
      payload: open
    });
  };

export const setScrollToKPICode =
  ({ kpiCode }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_SCROLL_TO_KPI_CODE,
      payload: kpiCode
    });
  };

export const clearScrollToKPICode = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_SCROLL_TO_KPI_CODE
  });
};
