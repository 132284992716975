import {
  selectNotificationCount,
  selectNotificationCountLoadingState,
  selectUserId
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NewButton from "../NewButton";
import Icon from "../Icon";
import {
  addNotification,
  getNotificationCount
} from "../../store/actions/notifications";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { themeProp } from "../../theme";
import NotificationsList from "./NotificationsPanel";
import { useString as s } from "../StringProvider";
import Popover from "../Popover";
import LoadingState from "../../utils/loading-state";
import { message } from "antd";
import pusherService from "../../services/pusher.service";
import ChannelNames from "../../utils/channel-names";
import httpService from "../../services/http.service";
import PusherEventNames from "../../utils/constants/pusher-event-names";

const NotificationsButton = ({
  loadingState,
  userId,
  notificationCount,
  getNotificationCount,
  addNotification
}) => {
  const [notificationsExist, setNotificationsExist] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const notificationsTooltipText = s("notifications.tooltip", "Notifications");
  const errorText = s(
    "notifications.error.text",
    "Failed to load notifications count"
  );

  const onNewNotification = (data) => {
    addNotification({ notification: data });
  };
  const tenantId = httpService.getTenantId();

  useEffect(() => {
    pusherService.subscribe({
      channelName: ChannelNames.NOTIFICATIONS({ userId, tenantId }),
      eventName: PusherEventNames.ADD_NOTIFICATION,
      callback: onNewNotification
    });

    return () => {
      pusherService.unsubscribe({
        channelName: ChannelNames.NOTIFICATIONS({ userId, tenantId }),
        eventName: PusherEventNames.ADD_NOTIFICATION,
        callback: onNewNotification
      });
    };
  }, [userId]);

  useEffect(() => {
    if (loadingState === LoadingState.FAILED) {
      message.error(errorText);
    }
  }, [loadingState]);

  useEffect(() => {
    if (!popoverVisible) {
      getNotificationCount();
    }
  }, [popoverVisible]);

  useEffect(() => {
    setNotificationsExist(notificationCount > 0);
  }, [notificationCount]);

  const onPopoverChange = (visible) => {
    setPopoverVisible(visible);
  };

  return (
    <Popover
      placement={"bottomRight"}
      trigger={"click"}
      content={<NotificationsList visible={popoverVisible} />}
      onOpenChange={onPopoverChange}
    >
      <NewButton
        type={"iconSecondary"}
        data-cy={"notifications"}
        tooltip={notificationsTooltipText}
        style={{ position: "relative" }}
      >
        <Icon name={"bell"} size={"large"} />
        {notificationsExist ? <NotificationBadge /> : null}
      </NewButton>
    </Popover>
  );
};

const NotificationBadge = styled.div`
  position: absolute;
  border-radius: 3px;
  border: 3px solid ${themeProp(`palette.primary`)};
  bottom: 4px;
  right: 7px;
  z-index: 100;
`;

const mapStateToProps = (state) => {
  return {
    notificationCount: selectNotificationCount(state),
    loadingState: selectNotificationCountLoadingState(state),
    userId: selectUserId(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotificationCount,
      addNotification
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationsButton);
