import types from "../actionTypes";

export const setChallengeLibraryOpen =
  ({ open }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_CHALLENGE_LIBRARY_OPEN,
      payload: open
    });
  };

export const setScrollToChallengeCode =
  ({ challengeCode }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_SCROLL_TO_CHALLENGE_CODE,
      payload: { challengeCode }
    });
  };

export const clearScrollToChallengeCode = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_SCROLL_TO_CHALLENGE_CODE
  });
};
