import PropTypes from "prop-types";
import { Phases } from "../../utils/phases";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { startDiscoveryOperation } from "../../store/actions/discovery-operations";
import { Operations } from "./DiscoveryOperations";
import NewButton from "../NewButton";
import Icon from "../Icon";
import Permissions from "../../utils/permissions";
import { hasPermission } from "../../store/reducers";

const ShareButton = ({
  discoveryId,
  template,
  canSeeShareButton,
  startDiscoveryOperation,
  dataCy = "share-button",
  tooltip,
  type
}) => {
  const onClick = () => {
    startDiscoveryOperation({
      operationId: Operations.SHARE,
      operationDiscoveryId: discoveryId,
      parameters: {
        template
      }
    });
  };

  return canSeeShareButton ? (
    <NewButton
      type={type}
      onClick={onClick}
      tooltip={tooltip}
      data-cy={dataCy}
      className={"up-share-button"}
    >
      <Icon name={"share"} size={"medium"} />
    </NewButton>
  ) : null;
};

ShareButton.propTypes = {
  template: PropTypes.oneOf(Phases.ALL),
  discoveryId: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["iconPrimary", "iconSecondary", "iconDarkPrimary"])
};

const mapStateToProps = (state) => ({
  canSeeShareButton: hasPermission(state, Permissions.SEE_SHARE_BUTTON)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startDiscoveryOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ShareButton
);
