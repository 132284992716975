import React from "react";
import { NewButton } from "../../../../components";
import { Icon } from "../../../../components";
import { useString as s } from "../../../../components/StringProvider";
import PropType from "prop-types";

const SettingsButton = ({ openEditModal }) => {
  const tooltipText = s(
    "discovery.opportunity.page.settings.button",
    "Edit assumptions"
  );

  return (
    <NewButton
      type={"iconSecondary"}
      tooltip={tooltipText}
      onClick={openEditModal}
      className={"outcomes-settings-button"}
    >
      <Icon name={"tune"} />
    </NewButton>
  );
};

SettingsButton.propTypes = {
  openEditModal: PropType.func.isRequired
};

export default SettingsButton;
