import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../theme";

const Text = (props) => <TextContainer {...props} />;
Text.propTypes = {
  variant: PropTypes.oneOf([
    "body",
    "smallBody",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "label",
    "small",
    "micro",
    "bMedium",
    "bodyMobile",
    "bMediumMobile",
    "statisticsMobile"
  ]),
  color: PropTypes.oneOf([
    "gray3",
    "gray4",
    "text",
    "surface",
    "primary",
    "secondary",
    "error",
    "warning"
  ]),
  alignment: PropTypes.oneOf(["center", "right"])
};
Text.defaultProps = {
  variant: "body",
  color: "text"
};

export default Text;

const TextContainer = styled.span`
  font-size: ${(props) =>
    themeProp(`typography.${props.variant}.fontSize`)(props)};
  font-family: ${(props) =>
    themeProp(`typography.${props.variant}.fontFamily`)(props)};
  font-weight: ${(props) =>
    themeProp(`typography.${props.variant}.fontWeight`)(props)};
  line-height: ${(props) =>
    themeProp(`typography.${props.variant}.lineHeight`)(props)};
  color: ${(props) => themeProp(`palette.${props.color}`)(props)};
  ${(props) => (props.alignment === "center" ? "text-align: center;" : "")}
  ${(props) => (props.alignment === "center" ? "display: block;" : "")}
`;
