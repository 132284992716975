const ValidationErrorCodes = {
  MANDATORY_WORKSHEET_NOT_FOUND: "MANDATORY_WORKSHEET_NOT_FOUND",
  NOT_DATA_FOUND: "NOT_DATA_FOUND",
  INVALID_VALUE: "INVALID_VALUE",
  SYSTEM_VALUE: "SYSTEM_VALUE",
  RANGE_VALUE_LESS_THAN: "RANGE_VALUE_LESS_THAN",
  RANGE_VALUE_GREATER_THAN: "RANGE_VALUE_GREATER_THAN",
  MISSING_REQUIRED_VALUE: "MISSING_REQUIRED_VALUE",
  FIELD_MUST_CONTAIN_IMPACT: "FIELD_MUST_CONTAIN_IMPACT",
  UNKNOWN_IDENTIFIER_IN_FORMULA: "UNKNOWN_IDENTIFIER_IN_FORMULA",
  ERROR_WHILE_PARSING_FORMULA: "ERROR_WHILE_PARSING_FORMULA",
  INVALID_MEMBER_EXPRESSION_IN_FORMULA: "INVALID_MEMBER_EXPRESSION_IN_FORMULA",
  REFERENCED_OBJECT_NOT_FOUND: "REFERENCED_OBJECT_NOT_FOUND",
  MANDATORY_COLUMN_NOT_FOUND: "MANDATORY_COLUMN_NOT_FOUND",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  FILTER_PARSE_ERROR: "FILTER_PARSE_ERROR",
  NO_KPI_CODES_IN_KPI_DEPENDENCY: "NO_KPI_CODES_IN_KPI_DEPENDENCY",
  DISCOVERY_TYPE_HAS_NO_QUESTIONS: "DISCOVERY_TYPE_HAS_NO_QUESTIONS",
  FIRST_QUESTION_CANNOT_HAVE_ACTIVATION_FORMULA:
    "FIRST_QUESTION_CANNOT_HAVE_ACTIVATION_FORMULA",
  DUPLICATE_OBJECT_CODE: "DUPLICATE_OBJECT_CODE",

  CONFLICTING_ACCOUNT_FOUND: "CONFLICTING_ACCOUNT_FOUND",
  CONFLICTING_OPPORTUNITY_FOUND: "CONFLICTING_OPPORTUNITY_FOUND",
  DUPLICATED_OPPORTUNITY: "DUPLICATED_OPPORTUNITY",
  INVALID_OPPORTUNITY_DATA_NUMBER: "INVALID_OPPORTUNITY_DATA_NUMBER",
  INVALID_OPPORTUNITY_DATA_BOOLEAN: "INVALID_OPPORTUNITY_DATA_BOOLEAN",

  POWERPOINT_TEMPLATE_FILE_ALREADY_EXISTS:
    "POWERPOINT_TEMPLATE_FILE_ALREADY_EXISTS",
  POWERPOINT_TEMPLATE_FILE_NOT_FOUND_IN_MD:
    "POWERPOINT_TEMPLATE_FILE_NOT_FOUND_IN_MD"
};

ValidationErrorCodes.ALL = Object.values(ValidationErrorCodes);

export default ValidationErrorCodes;
