import CookieStorage from "./CookieStorage";

let cookieStorage;

const configure = (config) => {
  if (cookieStorage) {
    throw new Error("cookieStorage is already configured");
  }

  cookieStorage = new CookieStorage(config);
  return cookieStorage;
};

const getStorage = () => {
  if (!cookieStorage) {
    throw new Error("cookieStorage is not configured");
  }

  return cookieStorage;
};

export default {
  configure,
  getStorage
};
