const SolutionStatus = {
  LINKED: "linked",
  NOT_LINKED: "notLinked",
  OUT_OF_SCOPE: "outOfScope",
  INCOMPATIBLE: "incompatible",
  INCOMPATIBLE_OUT_OF_SCOPE: "incompatibleOutOfScope"
};

SolutionStatus.ALL = Object.values(SolutionStatus);

SolutionStatus.isEffectivelyOutOfScope = (status) =>
  [
    SolutionStatus.OUT_OF_SCOPE,
    SolutionStatus.INCOMPATIBLE,
    SolutionStatus.INCOMPATIBLE_OUT_OF_SCOPE
  ].includes(status);

export default SolutionStatus;
