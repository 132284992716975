import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";

const ConfigurationLogType = ({ type }) => {
  const types = {};

  types["notesTemplate"] = s(
    "organization.page.configurationLog.table.type.notesTemplate",
    "Notes Template"
  );

  types["masterData"] = s(
    "organization.page.configurationLog.table.type.masterData",
    "MD"
  );

  types["powerpointTemplate"] = s(
    "organization.page.configurationLog.table.type.powerpointTemplate",
    "PPT Template"
  );

  types["multiple"] = s(
    "organization.page.configurationLog.table.type.multiple",
    ""
  );

  return types[type] !== undefined ? types[type] : type || "";
};

ConfigurationLogType.propTypes = {
  type: PropTypes.string.isRequired
};

export default ConfigurationLogType;
