import PropTypes from "prop-types";
import LoadingState from "../../../../utils/loading-state";
import Menu, { MenuItem } from "../../../Menu";
import { Space } from "antd";
import Text from "../../../Text";
import ShowLoadingState from "./ShowLoadingState";
import { useString as s } from "../../../StringProvider";
import { useFeature } from "../../../FeaturesProvider";

const DropdownMenu = ({
  value,
  textValue,
  items,
  loadingState,
  crmGroupName,
  customGroupName,
  suggestion,
  lastUpdated,
  onFocus,
  onBlur,
  onClickMenuItem,
  width,
  ...props
}) => {
  const dataCy = props["data-cy"] || "";
  const options = [];
  const useName = s("selectAccount.component.useName", "Use ''{textValue}''", {
    textValue
  });
  const keepName = s("selectAccount.component.keepName", "Keep ''{value}''", {
    value
  });
  const noRecordMatching = s(
    "selectAccount.component.noRecordsMatching",
    "No record matching ''{textValue}'' in this app.",
    {
      textValue
    }
  );
  const crmAccountsIntegration = useFeature("crmAccountsIntegration", false);

  if (items) {
    options.push({
      key: "crmGroup",
      type: "group",
      label: crmGroupName,
      items: items
        .filter(
          ({ name }) =>
            textValue && name.toLowerCase().includes(textValue.toLowerCase())
        )
        .map(({ id, name }) => ({
          key: id,
          label: name,
          dataCy: `${dataCy}-menu-item-crm`
        }))
    });
  }

  const children = [];

  if (
    value &&
    !items.find(({ name }) => name.toLowerCase() === value.toLowerCase())
  ) {
    children.push({
      key: "keepName",
      label: keepName,
      dataCy: `${dataCy}-menu-item-keepName`
    });
  }

  if (textValue) {
    if (
      value !== textValue &&
      !items.find(({ name }) => {
        return name.toLowerCase() === textValue.toLowerCase();
      })
    ) {
      children.push({
        key: "useName",
        label: useName,
        dataCy: `${dataCy}-menu-item-useName`
      });
    }
  }

  if (children.length) {
    options.push({
      key: "customGroup",
      type: "group",
      label: customGroupName,
      items: children
    });
  }

  return (
    <Menu
      onClick={onClickMenuItem}
      data-cy={`${dataCy}-menu`}
      style={{ width, maxWidth: width }}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {options.map((option) => (
        <Menu.ItemGroup
          title={
            option.key === "customGroup" ? (
              <Space direction={"vertical"} size={5}>
                <Text variant={"small"} color={"gray4"}>
                  {option.label}
                </Text>
                <Text variant={"body"} color={"gray4"}>
                  {suggestion}
                </Text>
              </Space>
            ) : (
              <Space direction={"vertical"} size={5}>
                {crmAccountsIntegration ? (
                  <Text variant={"small"} color={"gray4"}>
                    {option.label} - {lastUpdated}
                  </Text>
                ) : (
                  <Text variant={"small"} color={"gray4"}>
                    {option.label}
                  </Text>
                )}
                <ShowLoadingState loadingState={loadingState} />
                {textValue &&
                  option.items.length === 0 &&
                  loadingState === LoadingState.COMPLETED && (
                    <Text variant={"body"} color={"gray4"}>
                      {noRecordMatching}
                    </Text>
                  )}
              </Space>
            )
          }
          key={option.key}
        >
          {option.items.map((child) => (
            <MenuItem key={child.key} data-cy={child.dataCy}>
              {option.key === "customGroup" ? (
                <Text color={"primary"}>
                  <b>{child.label}</b>
                </Text>
              ) : (
                child.label
              )}
            </MenuItem>
          ))}
        </Menu.ItemGroup>
      ))}
    </Menu>
  );
};

DropdownMenu.propTypes = {
  value: PropTypes.string,
  textValue: PropTypes.string,
  items: PropTypes.array.isRequired,
  loadingState: PropTypes.oneOf(LoadingState.ALL),
  crmGroupName: PropTypes.string,
  customGroupName: PropTypes.string,
  suggestion: PropTypes.string,
  lastUpdated: PropTypes.string,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onClickMenuItem: PropTypes.func.isRequired,
  width: PropTypes.number
};

export default DropdownMenu;
