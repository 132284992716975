import PropTypes from "prop-types";
import Text from "../../../Text";
import LoadingState from "../../../../utils/loading-state";
import { useString as s } from "../../../StringProvider";
import { useEffect, useState } from "react";

const ShowLoadingState = ({ loadingState }) => {
  const [initialized, setInitialized] = useState(false);
  const loading = s(
    "selectAccount.component.loading.inProgress",
    "Loading matches..."
  );
  const uninitialized = s(
    "selectAccount.component.loading.uninitialized",
    "Start typing..."
  );
  const failed = s(
    "selectAccount.component.loading.failed",
    "Failed to load matches"
  );

  useEffect(() => {
    if (loadingState === LoadingState.COMPLETED) {
      setInitialized(true);
    }
  }, [loadingState]);

  switch (loadingState) {
    case LoadingState.IN_PROGRESS:
      return !initialized ? (
        <Text variant={"body"} color={"gray4"}>
          {loading}
        </Text>
      ) : null;
    case LoadingState.UNINITIALIZED:
      return (
        <Text variant={"body"} color={"gray4"}>
          {uninitialized}
        </Text>
      );
    case LoadingState.FAILED:
      return (
        <Text variant={"body"} color={"gray4"}>
          {failed}
        </Text>
      );
  }

  return null;
};

ShowLoadingState.propTypes = {
  loadingState: PropTypes.oneOf(LoadingState.ALL)
};

export default ShowLoadingState;
