import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Status from "../Status";
import { Heading } from "../index";

const ConnectionLayout = ({ title, helperText, isConnected, children }) => {
  return (
    <Container>
      <TitleBar>
        {title && <Heading level={"h4"}>{title}</Heading>}
        <StatusContainer>
          <Status status={isConnected ? "connected" : "notConnected"} />
        </StatusContainer>
        {helperText}
      </TitleBar>
      {children}
    </Container>
  );
};

const Container = styled.div`
  max-width: 550px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StatusContainer = styled.div`
  margin: 8px 0 24px 0;
`;

ConnectionLayout.propTypes = {
  title: PropTypes.string,
  helperText: PropTypes.string,
  isConnected: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default ConnectionLayout;
