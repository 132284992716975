import Markdown from "../Markdown";
import formatMarkdownText from "../../../utils/format-markdown-text";
import RelatedChallenges from "../KPICard/RelatedChallenges";
import KPILinks from "../KPICard/KPILinks";
import LinkedSolutions from "../KPICard/LinkedSolutions";
import { Space } from "antd";
import React from "react";
import AboutThisCard from "../AboutThisCard";
import { useString as s } from "../../StringProvider";
import AboutThisFormItem from "../ChallengeCard/AboutThisFormItem";
import PropType from "prop-types";

const AboutThisKPI = ({
  discoveryKPI,
  reportId,
  style,
  editMode = false,
  printMode
}) => {
  const aboutThisKPIButton = s(
    "discovery.kpi.card.aboutThisKPI",
    "About this Outcome"
  );

  return (
    <AboutThisCard
      text={aboutThisKPIButton}
      style={style}
      defaultExpandedState={editMode}
      printMode={printMode}
    >
      <Space direction={"vertical"} size={16}>
        {!editMode && (
          <Markdown size={"small"} paragraphMargin={true}>
            {formatMarkdownText(discoveryKPI?.definition?.detailText)}
          </Markdown>
        )}
        {editMode && <AboutThisFormItem />}
        {discoveryKPI && !editMode && (
          <RelatedChallenges discoveryKPI={discoveryKPI} />
        )}
        {discoveryKPI && !editMode && (
          <KPILinks discoveryKPI={discoveryKPI} reportId={reportId} />
        )}
        {discoveryKPI && !editMode && (
          <LinkedSolutions discoveryKPI={discoveryKPI} />
        )}
      </Space>
    </AboutThisCard>
  );
};

AboutThisKPI.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  reportId: PropType.string,
  editMode: PropType.bool,
  printMode: PropType.bool
};

export default AboutThisKPI;
