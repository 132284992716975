import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";

const ConfigurationLogStatus = ({ status }) => {
  const statusCodes = {};

  statusCodes["deployedToSandbox"] = s(
    "organization.page.configurationLog.table.status.deployedToSandbox",
    "Deployed to Sandbox"
  );

  statusCodes["deployedToProduction"] = s(
    "organization.page.configurationLog.table.status.deployedToProduction",
    "Deployed to Production"
  );

  statusCodes["validationFailed"] = s(
    "organization.page.configurationLog.table.status.validationFailed",
    "Validation Failed"
  );

  statusCodes["validationPassed"] = s(
    "organization.page.configurationLog.table.status.validationPassed",
    "Validated"
  );

  statusCodes["promotionRequested"] = s(
    "organization.page.configurationLog.table.status.promotionRequested",
    "Promotion Requested"
  );

  statusCodes["promotionRejected"] = s(
    "organization.page.configurationLog.table.status.promotionRejected",
    "Promotion Rejected"
  );

  statusCodes["promotionFailed"] = s(
    "organization.page.configurationLog.table.status.promotionFailed",
    "Promotion Failed"
  );

  return statusCodes[status] || status;
};

ConfigurationLogStatus.propTypes = {
  status: PropTypes.string.isRequired
};

export default ConfigurationLogStatus;
