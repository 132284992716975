import NotFound from "./NotFound";
import Forbidden from "./Forbidden";
import InternalServerError from "./InternalServerError";
import PropTypes from "prop-types";

const ErrorPage = ({ error }) => {
  switch (error ? error.status : null) {
    case 404:
      return <NotFound />;
    case 403:
      return <Forbidden />;
    default:
      return <InternalServerError />;
  }
};

ErrorPage.propTypes = {
  error: PropTypes.shape({ status: PropTypes.number })
};

export default ErrorPage;
