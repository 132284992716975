import { isPhaseLessThanOrEqualTo, Phases } from "../../../../utils/phases";
import PhaseName from "../../../../components/PhaseName";
import { useString as s } from "../../../../components/StringProvider";

const getMenuItems = ({ discovery, template }) => {
  const menuItems = [];

  if (isPhaseLessThanOrEqualTo(Phases.ROI, template) && discovery.roi) {
    menuItems.push({
      name: <PhaseName phase={Phases.ROI} />,
      sectionId: "report-roi"
    });
  }

  if (isPhaseLessThanOrEqualTo(Phases.VALUE_HYPOTHESIS, template)) {
    menuItems.push({
      name: <PhaseName phase={Phases.VALUE_HYPOTHESIS} />,
      sectionId: "report-value-hypothesis"
    });
  }

  if (isPhaseLessThanOrEqualTo(Phases.KPIS_IMPACTED, template)) {
    menuItems.push({
      name: <PhaseName phase={Phases.KPIS_IMPACTED} />,
      sectionId: "report-kpis-impacted"
    });
  }

  menuItems.push({
    name: <PhaseName phase={Phases.CHALLENGES_SOLVED} />,
    sectionId: "report-challenges-solved"
  });

  menuItems.push({
    name: s("report.your_situation.header", "Your Situation"),
    sectionId: "report-your-situation"
  });

  return discovery && template ? menuItems : [];
};

const scrollToReportSection = ({ sectionId }) => {
  const element = document.getElementById(sectionId);

  if (element) {
    element.scrollIntoView({
      behavior: !window.Cypress ? "smooth" : undefined
    });
  }
};

const getCoordinates = (element) => {
  let box = element.getBoundingClientRect();

  return {
    top: box.top + window.pageYOffset,
    right: box.right + window.pageXOffset,
    bottom: box.bottom + window.pageYOffset,
    left: box.left + window.pageXOffset
  };
};

const scrollToSection = ({ sectionId }) => {
  const element = document.getElementById(sectionId);
  const discoveryHeaderOffset = 175;
  if (element) {
    const coords = getCoordinates(element);

    window.scrollTo({
      left: coords.left,
      top: coords.top - discoveryHeaderOffset,
      behavior: !window.Cypress ? "smooth" : undefined
    });
  }
};

const isInView = (element, headerHeight = 80, footerHeight = 0) => {
  if (!element) {
    return false;
  }

  const offset = 0;
  const rect = element.getBoundingClientRect();
  const winH = window.innerHeight;
  const scrollTop =
    document.documentElement.scrollTop ||
    document.body.parentNode.scrollTop ||
    document.body.scrollTop;
  const scrollBottom = scrollTop + winH;
  const elTop = rect.top + scrollTop + offset;
  const elBottom = elTop + element.offsetHeight - headerHeight;

  return elTop + footerHeight < scrollBottom && elBottom > scrollTop;
};

export { getMenuItems, scrollToReportSection, scrollToSection, isInView };
