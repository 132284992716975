import OnlyIfUserCanAccessDiscoveryPath from "../../Pages/Discovery/components/OnlyIfUserCanAccessDiscoveryPath";
import { useString as s } from "../StringProvider";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import NewButton from "../NewButton";
import { selectDiscoveryId } from "../../store/reducers";
import { connect } from "react-redux";

const GoToSelectKPIsButton = ({ history, discoveryId }) => {
  const exploreKPIs = s(
    "discovery.challenges.page.button1",
    "Explore outcomes"
  );

  const onClick = () => {
    history.push({ pathname: `/discoveries/${discoveryId}/select-kpis` });
  };

  return (
    <OnlyIfUserCanAccessDiscoveryPath path={"select-kpis"}>
      <NewButton
        type={"primary"}
        data-cy={"modal-animation-button"}
        className={"up-explore-outcomes"}
        onClick={onClick}
      >
        {exploreKPIs}
      </NewButton>
    </OnlyIfUserCanAccessDiscoveryPath>
  );
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(GoToSelectKPIsButton);
