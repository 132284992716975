import { useString as s } from "../../components/StringProvider";
import { Space } from "antd";
import TextBody from "../../components/TextBody";
import AboutThisCard from "../../components/Discovery/AboutThisCard";
import { useEffect, useState } from "react";
import {
  selectDiscoveryDifference,
  selectRequestState
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Text } from "../../components";
import styled from "styled-components";
import ScrollZone from "../../components/ScrollZone";
import { useMobileOrTabletMediaQuery } from "../../components/Responsive";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import KPIName from "./KPIName";
import { openNotificationMessage } from "../../store/actions/notification-message";

const Lists = {
  ADDED: "added",
  REMOVED: "removed",
  UPDATED: "updated"
};

Lists.ALL = [Lists.REMOVED, Lists.ADDED, Lists.UPDATED];

const KPIDifferenceNotification = ({
  difference,
  loadingState,
  openNotificationMessage
}) => {
  const isMobileOrTablet = useMobileOrTabletMediaQuery();
  const header = s(
    "discovery.kpi.page.differenceNotification.header",
    "Outcomes list was updated to match Solutions in scope. Here’s what has changed."
  );
  const viewDetails = s(
    "discovery.kpi.page.differenceNotification.viewDetails",
    "View Details"
  );
  const listHeaders = {};
  const [currentDifference, setCurrentDifference] = useState(null);

  listHeaders[Lists.ADDED] = s(
    "discovery.kpi.page.differenceNotification.added",
    "Outcomes Added:"
  );
  listHeaders[Lists.REMOVED] = s(
    "discovery.kpi.page.differenceNotification.removed",
    "Outcomes Removed:"
  );
  listHeaders[Lists.UPDATED] = s(
    "discovery.kpi.page.differenceNotification.updated",
    "Outcomes Updated:"
  );

  useLoadingState(loadingState, () => {
    setCurrentDifference(difference);
  });

  useEffect(() => {
    if (
      currentDifference &&
      (currentDifference.kpis.added.length ||
        currentDifference.kpis.removed.length ||
        currentDifference.kpis.updated.length)
    ) {
      openNotificationMessage({
        description: (
          <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
            <TextBody>{header}</TextBody>
            <AboutThisCard text={viewDetails}>
              <ListContainer>
                <Space direction={"vertical"} size={16}>
                  {Lists.ALL.map((listName) =>
                    currentDifference.kpis[listName].length > 0 ? (
                      <Space
                        direction={"vertical"}
                        size={8}
                        key={listName}
                        style={{ marginBottom: "8px" }}
                      >
                        <Text variant={"bMediumMobile"}>
                          <b>{listHeaders[listName]}</b>
                        </Text>
                        {currentDifference.kpis[listName].map((kpiCode) => (
                          <KPIName kpiCode={kpiCode} key={kpiCode} />
                        ))}
                      </Space>
                    ) : null
                  )}
                </Space>
              </ListContainer>
            </AboutThisCard>
          </Space>
        ),
        placement: "bottomLeft",
        bottom: isMobileOrTablet ? 120 : 50,
        duration: 0
      });
    }
  }, [currentDifference]);

  return null;
};

const ListContainer = styled(ScrollZone)`
  max-height: 200px;
  overflow-y: scroll;
`;

const mapStateToProps = (state) => {
  return {
    difference: selectDiscoveryDifference(state),
    loadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_SAVE_SOLUTIONS_SUCCESS
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openNotificationMessage
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  KPIDifferenceNotification
);
