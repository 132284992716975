import Form, { FormItem } from "../../Form";
import { useString as s } from "../../StringProvider";
import React, { useEffect, useRef } from "react";
import { Space } from "antd";
import CategoryBadges from "../CategoryBadges";
import PropType from "prop-types";
import ChallengeDescriptionTextArea from "./ChallengeDescriptionTextArea";
import NewButton from "../../NewButton";
import AboutThisChallenge from "../ChallengeCard/AboutThisChallenge";
import ButtonGroup from "../../ButtonGroup";

const ExistingChallengeForm = ({
  form,
  discoveryChallenge,
  onFinish,
  onCancel
}) => {
  const okText = s("editChallenge.popup.save.button", "Save card");
  const cancelText = s("editChallenge.popup.cancel.button", "Don't change");
  const descriptionValidationText = s(
    "editChallenge.popup.description.validationText",
    "Please add a challenge description to save"
  );
  const textRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (textRef.current) {
        textRef.current.focus({
          cursor: "end",
          preventScroll: true
        });
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [textRef]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Space direction={"vertical"} size={18} style={{ width: "100%" }}>
        <FormItem
          name="description"
          rules={[
            {
              required: true,
              message: descriptionValidationText,
              whitespace: true
            }
          ]}
        >
          <ChallengeDescriptionTextArea autoSize={true} ref={textRef} />
        </FormItem>
        <CategoryBadges
          definition={discoveryChallenge.definition}
          disabled={true}
        />
        {discoveryChallenge.definition.detailText ||
        discoveryChallenge.definition.links.length ? (
          <AboutThisChallenge
            discoveryChallenge={discoveryChallenge}
            editMode={true}
          />
        ) : null}
        <FormItem>
          <ButtonGroup>
            <NewButton type={"submit"}>{okText}</NewButton>
            <NewButton
              type={"secondary"}
              onClick={onCancel}
              data-cy={"cancel-button"}
            >
              {cancelText}
            </NewButton>
          </ButtonGroup>
        </FormItem>
      </Space>
    </Form>
  );
};

ExistingChallengeForm.propTypes = {
  discoveryChallenge: PropType.object.isRequired,
  form: PropType.object.isRequired,
  onFinish: PropType.func.isRequired,
  onCancel: PropType.func.isRequired
};

export default ExistingChallengeForm;
