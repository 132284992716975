import httpService from "./http.service";

const getLatestVersion = () =>
  httpService
    .get(`/versions/latest`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getLatestVersion
};

export default methods;
