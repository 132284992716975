import { Phases } from "../../../utils/phases";

export const getReportLink = ({ reportId, template, invitation }) => {
  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;

  return invitation
    ? `${protocol}//${host}${
        port && port !== 443 && port !== 80 ? ":" + port : ""
      }/invitations/${invitation.code}${
        template ? "?template=" + template.replaceAll(" ", "%20") : ""
      }`
    : `${protocol}//${host}${
        port && port !== 443 && port !== 80 ? ":" + port : ""
      }/reports/${reportId}${
        template ? "?template=" + template.replaceAll(" ", "%20") : ""
      }`;
};

export const getInvitationLink = ({ template, invitationCode }) => {
  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;

  return `${protocol}//${host}${
    port && port !== 443 && port !== 80 ? ":" + port : ""
  }/invitations/${invitationCode}${
    template ? "?template=" + template.replaceAll(" ", "%20") : ""
  }`;
};

export const getMailtoLink = ({
  email,
  subjectMessage,
  ccEmail,
  crmBccEmail
}) => {
  return (
    `mailto:${encodeURIComponent(email)}` +
    `?subject=${encodeURIComponent(subjectMessage)}` +
    (crmBccEmail ? `&bcc=${crmBccEmail}` : "") +
    (ccEmail ? `&cc=${ccEmail}` : "")
  );
};

export const getUniqueLinks = (discovery, template) => {
  const allLinks = [];

  for (const discoveryChallenge of discovery.challenges) {
    if (discoveryChallenge.selected) {
      for (const link of discoveryChallenge.definition.links) {
        if (!allLinks.find((l) => l.text === link.text)) {
          allLinks.push(link);
        }
      }
    }
  }

  if (template && template !== Phases.CHALLENGES_SOLVED) {
    for (const discoveryKPI of discovery.kpis) {
      if (
        discoveryKPI.relevant &&
        discoveryKPI.selected &&
        discoveryKPI.definition.links
      ) {
        for (const link of discoveryKPI.definition.links) {
          if (!allLinks.find((l) => l.text === link.text)) {
            allLinks.push(link);
          }
        }
      }
    }
  }

  return allLinks;
};
