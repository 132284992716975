import styled from "styled-components";
import { Popover as ANTPopover } from "antd";
import { POPUP_CONTAINER } from "./FullscreenProvider";

const Popover = styled(ANTPopover).attrs(() => ({
  arrow: { pointAtCenter: true },
  getPopupContainer: () => document.getElementById(POPUP_CONTAINER)
}))``;

export default Popover;
