import { useString as s } from "../../components/StringProvider";
import { Alert, Icon } from "../../components";

const SSOError = ({ errorDescription }) => {
  let error = s("login.page.ssoError.error", "SSO Error");
  const contact = s(
    "login.page.ssoError.contact",
    "Please contact Cuvama at support@cuvama.com"
  );
  let message = errorDescription
    .replaceAll(/^(.*?)([\s\.]+)$/g, "$1")
    .concat(". ")
    .concat(contact);

  const matches = errorDescription.match(
    /^(Invalid SAML response received):(.*?)[\s\.]+$/
  );

  if (matches) {
    error = matches[1];
    message = matches[2] + `. ${contact}`;
  }

  return (
    <Alert
      data-cy={"sso-error"}
      message={error}
      description={message}
      type={"error"}
      icon={<Icon size={"larger"} name={"alert"} />}
      showIcon
    />
  );
};

export default SSOError;
