import httpService from "./http.service";

const getRole = ({ roleId }) =>
  httpService
    .get(`/roles/${roleId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getRoleByCode = ({ roleCode }) =>
  httpService
    .get(`/roles/code/${roleCode}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getRoles = ({ start, count, search, sort, order }) =>
  httpService
    .get(`/roles`, null, { start, count, search, sort, order })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const createRole = ({ name, description, permissions }) =>
  httpService
    .post("/roles/", { name, description, permissions })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateRole = ({ roleId, name, description, permissions }) =>
  httpService
    .patch(`/roles/${roleId}`, { name, description, permissions })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteRole = ({ roleId }) =>
  httpService
    .delete(`/roles/${roleId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getRole,
  getRoleByCode,
  getRoles,
  createRole,
  updateRole,
  deleteRole
};
export default methods;
