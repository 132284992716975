import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { getConfigurationLogs } from "../../../../store/actions/configurations";
import actionTypes from "../../../../store/actionTypes";
import React, { useContext, useEffect, useState } from "react";
import useLoadingState from "../../../../utils/use-loading-state";
import styled from "styled-components";
import { message } from "antd";
import { useString as s } from "../../../../components/StringProvider";
import Table from "../../../../components/Table";
import ConfigurationLogType from "./ConfigurationLogType";
import ConfigurationLogDetails from "./ConfigurationLogDetails";
import pusherService from "../../../../services/pusher.service";
import ChannelNames from "../../../../utils/channel-names";
import httpService from "../../../../services/http.service";
import { formatDate } from "../../../../utils/formatting";
import { SettingsContext } from "../../../../components/SettingsProvider";
import ConfigurationLogStatusWithTooltipIcon from "./ConfigurationLogStatusWithTooltipIcon";
import PusherEventNames from "../../../../utils/constants/pusher-event-names";

const ConfigurationLogs = ({
  getConfigurationLogs,
  loadingState,
  logs,
  pagination
}) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const tenantId = httpService.getTenantId();
  const [ready, setReady] = useState(false);
  const failedToLoad = s(
    "organization.page.configurationLogs.messages.failedToLoad",
    "Failed to load changes log"
  );
  const [data, setData] = useState([]);
  const [start, setStart] = useState(0);
  const [count, setCount] = useState(10);
  const [sort, setSort] = useState();
  const [order, setOrder] = useState();
  const status = s("organization.page.configurationLogs.status", "Status");
  const type = s("organization.page.configurationLogs.type", "Type");
  const version = s(
    "organization.page.configurationLogs.version",
    "MD Version"
  );
  const templateVersion = s(
    "organization.page.configurationLog.version",
    "MD Model"
  );
  const createdAt = s("organization.page.configurationLogs.createdAt", "Date");
  const username = s(
    "organization.page.configurationLogs.username",
    "Username"
  );
  const createdByDefault = s(
    "accessManagement.page.roles.createdBy.default",
    "Cuvama"
  );
  const [selectedRowKey, setSelectedRowKey] = useState("");

  useEffect(() => {
    doQuery();
  }, [start, sort, order, count]);

  useEffect(() => {
    if (logs) {
      setData(logs.map((log) => ({ ...log, key: log._id })));

      if (!selectedRowKey && pagination?.start === 0 && logs.length) {
        setSelectedRowKey(logs[0]._id);
      }
    }
  }, [logs, pagination, selectedRowKey]);

  useLoadingState(
    loadingState,
    () => {
      setReady(true);
    },
    () => {
      message.error(failedToLoad);
    }
  );

  useEffect(() => {
    pusherService.subscribe({
      channelName: ChannelNames.CURRENT_CONFIGURATION({ tenantId }),
      eventName: PusherEventNames.UPDATE,
      callback: onConfigurationUpdate
    });

    return () => {
      pusherService.unsubscribe({
        channelName: ChannelNames.CURRENT_CONFIGURATION({ tenantId }),
        eventName: PusherEventNames.UPDATE,
        callback: onConfigurationUpdate
      });
    };
  }, []);

  const onConfigurationUpdate = () => {
    doQuery();
  };

  const doQuery = () => {
    getConfigurationLogs({ start, sort, order, count });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const mapOrder = (order) => {
      switch (order) {
        case "ascend":
          return "asc";
        case "descend":
          return "desc";
        default:
          return;
      }
    };

    if (pagination.current) {
      setStart((pagination.current - 1) * pagination.pageSize);
    }

    setCount(pagination.pageSize);

    if (sorter.order) {
      setSort(sorter.columnKey);
      setOrder(mapOrder(sorter.order));
    } else {
      setSort(undefined);
      setOrder(undefined);
    }
  };

  const currentPagination = pagination
    ? {
        current: Math.floor(pagination.start / count) + 1,
        total: pagination.total,
        pageSize: count
      }
    : undefined;

  const columns = [
    {
      title: status,
      dataIndex: "status",
      key: "status",
      className: "status",
      ellipsis: { showTitle: true },
      sorter: true,
      render: (value) => (
        <ConfigurationLogStatusWithTooltipIcon status={value} />
      )
    },
    {
      title: type,
      dataIndex: "type",
      key: "type",
      className: "type",
      ellipsis: { showTitle: true },
      sorter: true,
      width: "150px",
      render: (value) => <ConfigurationLogType type={value} />
    },
    {
      title: version,
      dataIndex: "version",
      key: "version",
      className: "version",
      align: "left",
      width: "90px",
      sorter: true
    },
    {
      title: templateVersion,
      dataIndex: "templateVersion",
      key: "templateVersion",
      className: "templateVersion",
      align: "left",
      width: "90px",
      sorter: true
    },
    {
      title: createdAt,
      dataIndex: "createdAt",
      key: "createdAt",
      className: "createdAt",
      align: "left",
      width: "180px",
      ellipsis: { showTitle: true },
      render: (time) => formatDate({ time, locale }),
      sorter: true
    },
    {
      title: username,
      dataIndex: "username",
      key: "username",
      className: "username",
      ellipsis: { showTitle: true },
      render: (value) => value || createdByDefault
    }
  ];

  return (
    ready && (
      <Container>
        <Table
          rowClassName={(record) =>
            record.key === selectedRowKey ? "ant-table-row-selected" : ""
          }
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          pagination={currentPagination}
          onRow={(record) => {
            return {
              onClick: () => {
                setSelectedRowKey(record.key);
              }
            };
          }}
        />
        {selectedRowKey && (
          <ConfigurationLogDetails configurationLogId={selectedRowKey} />
        )}
      </Container>
    )
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  & > * {
    flex-grow: 1;
    align-items: stretch;
  }
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_CONFIGURATION_LOGS_REQUEST
  ),
  logs: selectRequestData(state, actionTypes.GET_CONFIGURATION_LOGS_REQUEST)
    ?.items,
  pagination: selectRequestData(
    state,
    actionTypes.GET_CONFIGURATION_LOGS_REQUEST
  )?.pagination
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getConfigurationLogs
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfigurationLogs
);
