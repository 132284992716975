import styled from "styled-components";
import Icon from "../../components/Icon";
import { themeProp } from "../../theme";

const CanvasLogo = ({ canvasLogoPath, isFullscreen }) => {
  if (!canvasLogoPath && isFullscreen) {
    return null;
  }

  return (
    <Container
      data-cy={canvasLogoPath ? "canvas-logo" : "canvas-logo-placeholder"}
    >
      {canvasLogoPath && <img src={"/" + canvasLogoPath} />}
      {!canvasLogoPath && (
        <IconContainer>
          <Icon name={"image"} size={"larger"} colour={"gray3"} />
        </IconContainer>
      )}
    </Container>
  );
};
const IconContainer = styled.div`
  background-color: ${themeProp("palette.gray1")};
  border-radius: 4px;
  width: 40px;
  height: 40px;
  padding: 8px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  img {
    height: 40px;
    width: auto;
  }
`;

export default CanvasLogo;
