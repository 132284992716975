import { bindActionCreators, compose } from "redux";
import { deleteDiscovery } from "../../store/actions/discoveries";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";
import { useString as s } from "../StringProvider";
import { message } from "antd";
import PropTypes from "prop-types";
import Text from "../Text";
import styled from "styled-components";
import NewButton from "../NewButton";
import { selectRequestError, selectRequestState } from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import ButtonGroup from "../ButtonGroup";
import Modal from "../Modal";

const DeleteDiscoveryModal = ({
  discoveryId,
  onClose,
  deleteDiscovery,
  loadingState,
  error
}) => {
  const okText = s("delete_discovery.popup.button.ok", "Yes, delete");
  const cancelText = s("delete_discovery.popup.button.cancel", "Don't delete");
  const success = s(
    "delete_discovery.popup.messages.success",
    "Discovery was removed"
  );
  const failed = s(
    "delete_discovery.popup.messages.error",
    "Failed to remove discovery"
  );
  const failedDueToPrimary = s(
    "delete_discovery.popup.messages.primaryError",
    "You can’t delete a primary, you need to set a new primary in Salesforce."
  );

  useLoadingState(
    loadingState,
    () => {
      message.success(success);
      onClose();
    },
    () => {
      if (error && error.status === 409) {
        message.error(failedDueToPrimary);
      } else {
        message.error(failed);
      }
    }
  );

  const onFinish = async () => {
    deleteDiscovery({ discoveryId });
  };

  return (
    <Modal
      title={s("delete_discovery.popup.header", "Delete discovery")}
      open={true}
      onCancel={onClose}
    >
      <Text>
        {s(
          "delete_discovery.popup.text1",
          "Would you like to delete the selected discovery?"
        )}
        <br />
        {s("delete_discovery.popup.text2", "Action can't be undone.")}
      </Text>
      <Footer>
        <ButtonGroup>
          <NewButton type={"primary"} onClick={onFinish}>
            {okText}
          </NewButton>
          <NewButton type={"secondary"} onClick={onClose}>
            {cancelText}
          </NewButton>
        </ButtonGroup>
      </Footer>
    </Modal>
  );
};

const Footer = styled.div`
  padding-top: 24px;
`;

DeleteDiscoveryModal.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.DELETE_DISCOVERY_REQUEST),
  error: selectRequestError(state, actionTypes.DELETE_DISCOVERY_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteDiscovery
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteDiscoveryModal);
