import { hasPermission, selectRequestState } from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { BasicBrandHeader, SplitPageLayout } from "../../components/Layout";
import { useTheme } from "../../components/ThemeProvider";
import Page from "../../components/Page";
import React, { useEffect, useState } from "react";
import NewButton from "../../components/NewButton";
import { useString as s } from "../../components/StringProvider";
import { Space } from "antd";
import Heading from "../../components/Heading";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  DesktopOrTablet,
  Mobile,
  useMobileMediaQuery
} from "../../components/Responsive";
import ButtonGroup from "../../components/ButtonGroup";
import { Text } from "../../components";
import useLoadingState from "../../utils/use-loading-state";
import { logout } from "../../store/actions/authentication";
import Permissions from "../../utils/permissions";
import ErrorPage from "../ErrorPage";
import Loader from "../../components/Loader";
import { fetchDiscoveryByReportId } from "../../store/actions/discoveries";
import actionTypes from "../../store/actionTypes";

const LandingPage = ({
  invitation,
  history,
  isLeadGenerationUser,
  logout,
  loadingState,
  discoveryLoadingState,
  fetchDiscoveryByReportId
}) => {
  const isMobile = useMobileMediaQuery();
  const [error, setError] = useState(false);
  const [ready, setReady] = useState(false);
  const header = s(
    "discovery.invitations.page.header",
    "Discovery for {customerName}",
    { customerName: invitation.discovery.name }
  );
  const description = s(
    "discovery.invitations.page.description",
    "Please review the business challenges you need to solve and the key success outcomes we will achieve together"
  );
  const editDiscovery = s(
    "discovery.invitations.page.editDescription",
    "Edit discovery"
  );
  const viewReport = s("discovery.invitations.page.viewReport", "View report");
  const image =
    useTheme("assets.salesforce_share_discovery_cover") ||
    useTheme("assets.share_discovery_cover");

  useEffect(() => {
    if (isLeadGenerationUser) {
      logout();
    }
  }, [isLeadGenerationUser]);

  useEffect(() => {
    if (invitation) {
      setReady(false);
      fetchDiscoveryByReportId({ reportId: invitation.discovery.reportId });
    }
  }, [invitation]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      setError(true);
    }
  );

  useLoadingState(
    discoveryLoadingState,
    () => {
      setReady(true);
    },
    () => {
      setError(true);
    }
  );

  const onViewReport = () => {
    history.push({
      pathname: `/reports/${invitation.discovery.reportId}`
    });
  };

  const onEditDiscovery = () => {
    history.push({
      pathname: `/invitations/${invitation.code}/check-email`
    });
  };

  return error ? (
    <ErrorPage />
  ) : isLeadGenerationUser || !ready ? (
    <Loader />
  ) : (
    <Page header={isMobile ? <BasicBrandHeader /> : undefined}>
      <SplitPageLayout image={image} hasHeader={isMobile}>
        <Mobile>
          <Container className={"mobile"}>
            <MobileImage image={image} />
            <Space size={32} direction={"vertical"}>
              <Space size={16} direction={"vertical"}>
                <Heading level={"h2"}>{header}</Heading>
                <Text variant={"bodyMobile"}>{description}</Text>
              </Space>
              <ButtonGroup>
                <NewButton
                  type={"primary"}
                  onClick={onEditDiscovery}
                  data-cy={"edit-discovery-button"}
                >
                  {editDiscovery}
                </NewButton>
                <NewButton
                  type={"secondary"}
                  onClick={onViewReport}
                  data-cy={"view-report-button"}
                >
                  {viewReport}
                </NewButton>
              </ButtonGroup>
            </Space>
          </Container>
        </Mobile>
        <DesktopOrTablet>
          <div style={{ position: "relative" }}>
            <Container>
              <Heading level={"h1"}>{header}</Heading>
              <Heading level={"h3"}>{description}</Heading>
              <Space size={16} style={{ marginTop: 40 }}>
                <NewButton
                  type={"primary"}
                  onClick={onEditDiscovery}
                  data-cy={"edit-discovery-button"}
                >
                  {editDiscovery}
                </NewButton>
                <NewButton
                  type={"secondary"}
                  onClick={onViewReport}
                  data-cy={"view-report-button"}
                >
                  {viewReport}
                </NewButton>
              </Space>
            </Container>
          </div>
        </DesktopOrTablet>
      </SplitPageLayout>
    </Page>
  );
};

LandingPage.propTypes = {
  invitation: PropTypes.object.isRequired
};

const Container = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  &.mobile {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;

    position: static;
    display: flex;
    flex-direction: column;

    vertical-align: unset;
    transform: unset;
    -ms-transform: unset;
    top: unset;
  }

  &.mobile h2 {
    text-align: center;
    margin-bottom: 0;
  }
`;

const MobileImage = styled.div`
  ${({ image }) => (image ? `background-image: url(${image});` : null)}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  margin-top: 20px;
  margin-bottom: 20px;
  flex: 1;
`;

const mapStateToProps = (state) => ({
  isLeadGenerationUser: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  loadingState: selectRequestState(state, actionTypes.LOGOUT_REQUEST),
  discoveryLoadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_FETCH_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      fetchDiscoveryByReportId
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LandingPage);
