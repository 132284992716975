export const RoleCodes = {
  ACCESS_ADMIN: "access_admin",
  ADMIN: "admin",
  GHOST: "ghost",
  USER: "user",
  EXTERNAL_USER: "external_user",
  LEAD_GEN_USER: "lead_gen_user",
  SANDBOX_ADMIN: "sandbox_admin",
  CONFIGURATION_ADMIN: "configuration_admin",
  INTERNAL_ADMIN: "internal_admin",
  PROMOTION_ADMIN: "promotion_admin",
  GUEST: "guest",
  BETA_USER: "beta_user"
};

RoleCodes.getDominantRole = ({ codes }) => {
  for (const code of [
    RoleCodes.ADMIN,
    RoleCodes.USER,
    RoleCodes.EXTERNAL_USER,
    RoleCodes.LEAD_GEN_USER
  ]) {
    if (codes.includes(code)) {
      return code;
    }
  }

  return undefined;
};

RoleCodes.ROLES_FOR_USER_PILOT = [
  RoleCodes.ADMIN,
  RoleCodes.USER,
  RoleCodes.EXTERNAL_USER,
  RoleCodes.LEAD_GEN_USER,
  RoleCodes.BETA_USER
];
