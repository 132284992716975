import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { scrollToReportSection } from "./ScrollUtils";
import { themeProp } from "../../../../theme";
import Select from "../../../../components/Select";
import Progress from "../../../../components/Progress";
import { useMobileMediaQuery } from "../../../../components/Responsive";

const ReportDropdown = ({ menuItems, activeSection }) => {
  const isMobile = useMobileMediaQuery();

  const [selectedValue, setSelectedValue] = useState("");

  const onSelect = (value) => {
    setSelectedValue(value);
    scrollToReportSection({ sectionId: value });
  };

  useEffect(() => {
    if (menuItems?.[0]?.sectionId && !selectedValue) {
      setSelectedValue(menuItems[0].sectionId);
    }
  }, [menuItems, selectedValue]);

  useEffect(() => {
    if (activeSection) {
      setSelectedValue(activeSection);
    }
  }, [activeSection]);

  const options = menuItems.map((item) => {
    return { value: item.sectionId, label: item.name };
  });

  return (
    <MenuContainer isMobile={isMobile}>
      <Select options={options} onSelect={onSelect} value={selectedValue} />
      <Progress color={"secondary"} percent={100} showInfo={false} />
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    position: relative;
  }

  .ant-select-dropdown {
    min-width: 160px !important;
  }

  .ant-select {
    height: 100%;
  }

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-selection-item {
    color: ${themeProp("palette.secondary")} !important;
    font-weight: ${themeProp(`typography.bMedium.fontWeight`)};
    font-size: ${themeProp("typography.body.fontSize")} !important;
  }

  .ant-select-arrow svg {
    color: ${themeProp("palette.secondary")};
  }
`;

export default ReportDropdown;
