import Heading from "../../Heading";
import { useString as s } from "../../StringProvider";
import { Space, message } from "antd";
import Text from "../../Text";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import {
  selectDiscoveryId,
  selectDiscoverySaveSolutionsLoadingState,
  selectDiscoverySolutions,
  selectPreviewSolutionsLoadingState,
  selectPreviewSolutionsMap
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import SolutionStatus from "../../../utils/solution-status";
import SolutionBadges from "./SolutionBadges";
import TooltipIcon from "../../TooltipIcon";
import ItemTypes from "../../ItemTypes";
import { useEffect, useState } from "react";
import NewButton from "../../NewButton";
import DropZone from "./DropZone";
import PropTypes from "prop-types";
import {
  previewDiscoverySolutions,
  saveDiscoverySolutions
} from "../../../store/actions/discoveries";
import RelatedKPIs from "./RelatedKPIs";
import ScrollZone from "../../ScrollZone";
import useLoadingState from "../../../utils/use-loading-state";
import Icon from "../../Icon";

const SolutionsPanel = ({
  editable,
  discoveryId,
  solutionsMap,
  saveSolutionsLoadingState,
  previewSolutionsMap,
  previewSolutionsLoadingState,
  onClose,
  saveDiscoverySolutions,
  previewDiscoverySolutions
}) => {
  const headerText = s("solutions.popover.header", "Solutions");
  const viewSubHeaderText = s(
    "solutions.popover.subHeader",
    "Solutions being evaluated in current discovery and those that have been excluded from scope."
  );
  const editSubHeaderText = s(
    "solutions.popover.subHeaderEdit",
    'Drag solutions to "Excluded from Scope" if you wish to rule them out.'
  );
  const linkedText = s("solutions.popover.linkedHeader", "Linked to Discovery");
  const linkedTooltip = s(
    "solutions.popover.linkedTooltip",
    "Solutions linked to selected outcomes in current Discovery"
  );
  const notLinkedText = s(
    "solutions.popover.notLinkedHeader",
    "Not Linked to Discovery"
  );
  const notLinkedTooltip = s(
    "solutions.popover.notLinkedTooltip",
    "Solutions that are not linked to selected outcomes in current Discovery"
  );
  const outOfScopeText = s(
    "solutions.popover.outOfScopeHeader",
    "Excluded from Scope"
  );
  const outOfScopeTooltip = s(
    "solutions.popover.outOfScopeTooltip",
    "Solutions that have been excluded from the scope of this Discovery evaluation"
  );
  const noSolutionsText = s(
    "solutions.popover.allSolutionsInScope",
    "All solutions are in scope"
  );
  const allSolutionsInScopeText = s(
    "solutions.popover.allSolutionsInScope",
    "All solutions are in scope"
  );
  const viewModeText = s(
    "solutions.popover.viewMode",
    "You’re in view only mode"
  );
  const applyText = s("solutions.popover.apply", "Apply");
  const cancelText = s("solutions.popover.cancel", "Cancel");
  const saveErrorText = s(
    "solutions.popover.error.apply",
    "Failed to save solutions"
  );
  const previewErrorText = s(
    "solutions.popover.error.preview",
    "Failed to preview solutions"
  );

  const [currentSolutionsMap, setCurrentSolutionsMap] = useState(() => ({
    ...solutionsMap
  }));
  const [originalSolutionsMap, setOriginalSolutionsMap] = useState(() => ({
    ...solutionsMap
  }));
  const [isDirty, setDirty] = useState(false);
  const [currentSolutionCode, setCurrentSolutionCode] = useState("");

  useEffect(() => {
    if (isDirty) {
      const newSolutionsMap = { ...solutionsMap };

      for (const code of Object.keys(currentSolutionsMap)) {
        if (currentSolutionsMap[code] !== originalSolutionsMap[code]) {
          newSolutionsMap[code] = currentSolutionsMap[code];
        }
      }

      setCurrentSolutionsMap(newSolutionsMap);
    } else {
      setCurrentSolutionsMap({ ...solutionsMap });
      setOriginalSolutionsMap({ ...solutionsMap });
    }
  }, [solutionsMap]);

  useLoadingState(
    saveSolutionsLoadingState,
    () => {
      onClose();
    },
    () => {
      message.error(saveErrorText);
    }
  );

  useLoadingState(
    previewSolutionsLoadingState,
    () => {
      setCurrentSolutionsMap(() => ({ ...previewSolutionsMap }));
      setDirty(true);
    },
    () => {
      message.error(previewErrorText);
    }
  );

  const filterSolutions = (solutions) => {
    const filteredSolutions = {};

    for (const key of Object.keys(solutions)) {
      if (
        [
          SolutionStatus.LINKED,
          SolutionStatus.NOT_LINKED,
          SolutionStatus.OUT_OF_SCOPE
        ].includes(solutions[key])
      ) {
        filteredSolutions[key] = solutions[key];
      }
    }

    return filteredSolutions;
  };

  const moveOutOfScope = ({ code }) => {
    setCurrentSolutionsMap((currentSolutionsMap) => {
      const newSolutionsMap = { ...currentSolutionsMap };
      newSolutionsMap[code] = SolutionStatus.OUT_OF_SCOPE;
      return newSolutionsMap;
    });
    setDirty(true);
  };

  const moveIntoScope = ({ code }) => {
    setCurrentSolutionsMap((currentSolutionsMap) => {
      const newSolutionsMap = { ...currentSolutionsMap };
      newSolutionsMap[code] = SolutionStatus.LINKED;
      previewDiscoverySolutions({
        discoveryId,
        solutionsMap: filterSolutions(newSolutionsMap)
      });
      return currentSolutionsMap;
    });
  };

  const onApply = () => {
    saveDiscoverySolutions({
      discoveryId,
      solutionsMap: filterSolutions(currentSolutionsMap)
    });
  };

  const onClick = (solutionCode) => {
    setCurrentSolutionCode(solutionCode);
  };

  const onCloseSolution = () => {
    setCurrentSolutionCode("");
  };

  const linkedSolutions = [];
  const notLinkedSolutions = [];
  const outOfScopeSolutions = [];

  for (const code of Object.keys(currentSolutionsMap)) {
    switch (currentSolutionsMap[code]) {
      case SolutionStatus.LINKED:
        linkedSolutions.push({ code, status: currentSolutionsMap[code] });
        break;
      case SolutionStatus.NOT_LINKED:
        notLinkedSolutions.push({ code, status: currentSolutionsMap[code] });
        break;
      case SolutionStatus.OUT_OF_SCOPE:
        outOfScopeSolutions.push({ code, status: currentSolutionsMap[code] });
        break;
    }
  }

  return (
    <Container data-cy={"solutions-panel"}>
      {currentSolutionCode ? (
        <RelatedKPIs
          solutionCode={currentSolutionCode}
          onClose={onCloseSolution}
        />
      ) : (
        <>
          <div>
            <HeaderContainer>
              <Heading level={"h3"} color={"surface"}>
                {headerText}
              </Heading>
              <StyledButton type={"darkSecondary"} onClick={onClose}>
                <Icon name={"close"} size={"medium"} />
              </StyledButton>
            </HeaderContainer>
            <Space direction={"vertical"} size={16}>
              <Text variant={"small"} color={"surface"}>
                {editable ? editSubHeaderText : viewSubHeaderText}
              </Text>
              {editable ? null : (
                <ViewOnlyContainer>
                  <Icon name={"eye"} colour={"gray3"} size={"medium"} />
                  <Text variant={"small"} color={"gray3"}>
                    {viewModeText}
                  </Text>
                </ViewOnlyContainer>
              )}
            </Space>
          </div>
          <ScrollZone style={{ marginTop: "16px", marginBottom: "16px" }}>
            <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
              <DropZone
                onDrop={({ code, name }) => moveIntoScope({ code, name })}
                accept={ItemTypes.OUT_OF_SCOPE_SOLUTION}
                editable={editable}
              >
                <div>
                  <Heading
                    level={"h4"}
                    color={"surface"}
                    data-cy={"linked-to-discovery-header"}
                  >
                    {linkedText}{" "}
                    <TooltipIcon title={linkedTooltip} color={"surface"} />
                  </Heading>
                  <SolutionBadges
                    solutions={linkedSolutions}
                    emptyText={noSolutionsText}
                    editable={!!editable}
                    onClick={onClick}
                  />
                </div>
                <div style={{ marginTop: 16 }}>
                  <Heading
                    level={"h4"}
                    color={"surface"}
                    data-cy={"not-linked-to-discovery-header"}
                  >
                    {notLinkedText}{" "}
                    <TooltipIcon title={notLinkedTooltip} color={"surface"} />
                  </Heading>
                  <SolutionBadges
                    solutions={notLinkedSolutions}
                    emptyText={noSolutionsText}
                    editable={!!editable}
                    onClick={onClick}
                  />
                </div>
              </DropZone>
              <DropZone
                onDrop={moveOutOfScope}
                accept={ItemTypes.SOLUTION}
                editable={editable}
              >
                <div>
                  <Heading level={"h4"} color={"surface"}>
                    {outOfScopeText}{" "}
                    <TooltipIcon title={outOfScopeTooltip} color={"surface"} />
                  </Heading>
                  <SolutionBadges
                    solutions={outOfScopeSolutions}
                    emptyText={allSolutionsInScopeText}
                    editable={editable}
                    onClick={onClick}
                  />
                </div>
              </DropZone>
            </Space>
          </ScrollZone>
          {editable ? (
            <Space direction={"horizontal"} size={16}>
              <NewButton
                type={"darkPrimary"}
                disabled={!isDirty}
                onClick={onApply}
              >
                {applyText}
              </NewButton>
              <NewButton type={"darkSecondary"} onClick={onClose}>
                {cancelText}
              </NewButton>
            </Space>
          ) : null}
        </>
      )}
    </Container>
  );
};

SolutionsPanel.propTypes = {
  editable: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Container = styled.div`
  padding: 32px;
  max-width: 435px;
  min-width: 435px;
  max-height: calc(100vh - 180px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  & h3,
  & h4 {
    margin-bottom: 0;
  }

  & div {
    font-size: ${themeProp(`typography.small.fontSize`)};
    font-family: ${themeProp(`typography.small.fontFamily`)};
    line-height: ${themeProp(`typography.small.lineHeight`)};
  }

  overflow: hidden;

  background: linear-gradient(${themeProp("palette.text")} 33%, transparent),
    /* hide top */
      linear-gradient(transparent, ${themeProp("palette.text")} 66%) 0 100%,
    /* hide bottom */
      radial-gradient(farthest-side at 50% 0, black, transparent),
    /* top shadow */
      radial-gradient(farthest-side at 50% 100%, black, transparent) 0 100%; /* bottom shadow */
  background-color: ${themeProp("palette.text")};
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 45px, 100% 45px, 100% 25px, 100% 25px; /* top/bottom hide, top/bottom shadow */
`;

const StyledButton = styled(NewButton)`
  width: 34px;
  height: 34px;
`;

const ViewOnlyContainer = styled.div`
  display: inline-block;

  svg {
    margin-right: 8px;
    margin-bottom: 2px;
    display: inline-block;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
`;

function mapStateToProps(state) {
  return {
    discoveryId: selectDiscoveryId(state),
    solutionsMap: selectDiscoverySolutions(state),
    saveSolutionsLoadingState: selectDiscoverySaveSolutionsLoadingState(state),
    previewSolutionsMap: selectPreviewSolutionsMap(state),
    previewSolutionsLoadingState: selectPreviewSolutionsLoadingState(state)
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoverySolutions,
      previewDiscoverySolutions
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SolutionsPanel
);
