import styled from "styled-components";
import { themeProp } from "../../theme";

export const ValueMapCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  border-radius: 6px;
  background: ${themeProp("palette.surface")};
  padding: 16px 16px;
  overflow: hidden;
  transform: translate(0, 0);
  gap: 10px;
  margin: 0 3px;
  position: relative;

  &.dragging {
    visibility: hidden;
    opacity: 0;
  }

  &:hover,
  &:hover:not(.selected) {
    cursor: pointer;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &.creating {
    margin-top: 6px;
    transform: translateX(-3px);
  }

  &.editing,
  &.creating {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    overflow: unset;
    max-height: fit-content;
    min-height: fit-content;
  }

  &.selected {
    color: ${themeProp("palette.text")};
  }

  &.selected.keyObjective {
    background: ${themeProp("palette.secondary")};
    color: ${themeProp("palette.surface")};

    & .ant-btn {
      color: #ffffff80;
    }
  }

  &.dragging,
  &.dragging:hover {
    cursor: grabbing;
  }

  & .ant-btn-iconSecondary {
    visibility: hidden;
  }

  &:hover:not(.dragging) .ant-btn-iconSecondary {
    visibility: visible;
  }

  &.autocomplete-dropdown-visible {
    border-radius: 6px 6px 0px 0px;
    position: relative;

    &::before {
      content: "";
      background-color: ${themeProp("palette.gray1")};
      height: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(20px);
      z-index: 100000;
    }
  }

  &.autocomplete-dropdown-not-visible {
    border-radius: 6px;
  }

  &.creating-key-objective {
    background: ${themeProp("palette.secondary")};
    color: ${themeProp("palette.surface")};

    svg {
      color: ${themeProp("palette.surface")};
    }
  }
`;

export const TickContainer = styled.div`
  margin-bottom: 3px;
`;

export const DropContainer = styled.div`
  margin: 1.5px 20px 1.5px 20px;
  height: 3px;
  max-height: 3px;
  min-height: 3px;

  .active-bottom + * > &,
  &:has(+ .active-top) {
    background: #3c9dff;
  }
`;
