import {
  selectDiscoveryId,
  selectRequestParameters,
  selectRequestState
} from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { addDiscoveryChallengeFromLibrary } from "../../store/actions/discoveries";
import { connect } from "react-redux";
import useLoadingState from "../../utils/use-loading-state";
import { useString as s } from "../../components/StringProvider";
import CanvasNotificationPanel from "./CanvasNotificationPanel";
import { restoreDiscoveryChallenge } from "../../store/actions/create-challenges";
import {
  closeNotificationMessage,
  openNotificationMessage
} from "../../store/actions/notification-message";

const DeleteOrRemoveChallengeNotification = ({
  discoveryId,
  removeLoadingState,
  deleteLoadingState,
  removeParameters,
  deleteParameters,
  addDiscoveryChallengeFromLibrary,
  restoreDiscoveryChallenge,
  restoreLoadingState,
  restoreParameters,
  addLoadingState,
  addParameters,
  openNotificationMessage,
  closeNotificationMessage
}) => {
  const removedSucceeded = s(
    "discovery.challenge.card.removeFromList.succeeded",
    "Challenge removed from the discovery!"
  );
  const deletedSucceeded = s(
    "discovery.challenge.card.delete.succeeded",
    "Challenge deleted from the discovery!"
  );
  const notificationPlacement = "bottomLeft";

  useLoadingState(
    removeLoadingState,
    () => {
      const challengeCode = removeParameters.challengeCode;

      openNotificationMessage({
        key: challengeCode,
        description: (
          <CanvasNotificationPanel
            description={removedSucceeded}
            onClick={() => {
              addDiscoveryChallengeFromLibrary({
                discoveryId,
                challengeCode: challengeCode
              });
            }}
            close={() => closeNotificationMessage({ key: challengeCode })}
          />
        ),
        closeIcon: null,
        placement: notificationPlacement
      });
    },
    () => {}
  );

  useLoadingState(
    deleteLoadingState,
    () => {
      const challengeCode = deleteParameters.challengeCode;

      openNotificationMessage({
        key: challengeCode,
        description: (
          <CanvasNotificationPanel
            description={deletedSucceeded}
            onClick={() => {
              restoreDiscoveryChallenge({
                discoveryId,
                challengeCode: challengeCode
              });
            }}
            close={() => closeNotificationMessage({ key: challengeCode })}
          />
        ),
        closeIcon: null,
        placement: notificationPlacement
      });
    },
    () => {}
  );

  useLoadingState(restoreLoadingState, () => {
    closeNotificationMessage({ key: restoreParameters.challengeCode });
  });

  useLoadingState(addLoadingState, () => {
    closeNotificationMessage({ key: addParameters.challengeCode });
  });

  return "";
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    deleteLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_DELETE_CHALLENGE_REQUEST
    ),
    deleteParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_DELETE_CHALLENGE_REQUEST
    ),
    removeLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_REQUEST
    ),
    removeParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_REQUEST
    ),
    restoreLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_RESTORE_CHALLENGE_REQUEST
    ),
    restoreParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_RESTORE_CHALLENGE_REQUEST
    ),
    addLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_REQUEST
    ),
    addParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDiscoveryChallengeFromLibrary,
      restoreDiscoveryChallenge,
      openNotificationMessage,
      closeNotificationMessage
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeleteOrRemoveChallengeNotification
);
