import { withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { isAuthenticated, selectRequestState } from "../../store/reducers";
import { useEffect } from "react";
import { logout } from "../../store/actions/authentication";
import useLoadingState from "../../utils/use-loading-state";
import actionTypes from "../../store/actionTypes";

const Logout = ({ history, isAuthenticated, loadingState, logout }) => {
  useEffect(() => {
    if (isAuthenticated) {
      logout();
    } else {
      history.push("/");
    }
  }, [isAuthenticated]);

  useLoadingState(
    loadingState,
    () => {
      history.push("/");
    },
    () => {
      history.push("/");
    }
  );

  return null;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    loadingState: selectRequestState(state, actionTypes.LOGOUT_REQUEST)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Logout);
