import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SettingsContext } from "./SettingsProvider";
import { formatCurrencyString } from "../utils/formatting";
import Tooltip from "./Tooltip";

const Currency = ({ format, currency, children, showTooltip, ...props }) => {
  const { settings } = useContext(SettingsContext);
  const localSettings = { ...settings, currency };

  const renderString = () => {
    switch (format) {
      case "short":
        return formatCurrencyString({
          value: children,
          settings: localSettings,
          options: {
            notation: "compact"
          }
        });
      case "long":
        return formatCurrencyString({
          value: children,
          settings: {
            ...localSettings,
            trim: false
          }
        });
      default:
        return formatCurrencyString({
          value: children,
          settings: localSettings
        });
    }
  };

  const tooltip = formatCurrencyString({
    value: children,
    settings: {
      ...localSettings,
      trim: false
    }
  });

  return (
    <CurrencyContainer
      title={showTooltip ? tooltip : null}
      showTooltip={showTooltip}
    >
      <span {...props}>{renderString()}</span>
    </CurrencyContainer>
  );
};

const CurrencyContainer = styled(Tooltip)`
  cursor: ${(props) => (props.showTooltip ? "help" : "auto")};
`;

Currency.propTypes = {
  format: PropTypes.oneOf(["default", "short", "long"]),
  currency: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool
};
Currency.defaultProps = {
  format: "default",
  showTooltip: true
};

export default Currency;
