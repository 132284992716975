import PropTypes from "prop-types";
import { useString as s } from "../../../components/StringProvider";
import ChartToggle from "./ChartToggle";
import {
  isAuthenticated,
  selectDiscoveryId,
  selectDiscoveryShowOutcomesByCategory
} from "../../../store/reducers";
import {
  setDiscoveryShowOutcomesByCategory,
  updateDiscoverySettings
} from "../../../store/actions/discoveries";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import actionTypes from "../../../store/actionTypes";

const ShowOutcomeByCategory = ({
  isAuthenticated,
  discoveryId,
  reportMode,
  printMode,
  updateDiscoverySettings,
  setDiscoveryShowOutcomesByCategory,
  showOutcomesByCategory
}) => {
  const showCategoryText = s(
    "discovery.cosq.show.category.text",
    "View by Category"
  );
  const toolTipText = s(
    "discovery.cosq.show.category.toolTip",
    "Display benefit grouped by Category instead of by individual Outcome. The option to view additional benefits will be disabled."
  );

  const onChange = (value) => {
    if (reportMode && !isAuthenticated) {
      setDiscoveryShowOutcomesByCategory({ showOutcomesByCategory: value });
    } else {
      updateDiscoverySettings({
        discoveryId,
        changes: {
          showOutcomeByCategory: value
        }
      });
    }
  };

  if (printMode) {
    return null;
  }

  return (
    <ChartToggle
      dataCy={"cosq-show-outcome-by-category-toggle"}
      userpilotTag={"up-cosq-show-outcome-by-category-toggle"}
      checked={showOutcomesByCategory}
      onChange={onChange}
      toggleText={showCategoryText}
      toggleTooltip={toolTipText}
    />
  );
};

ShowOutcomeByCategory.propTypes = {
  reportMode: PropTypes.bool.isRequired,
  printMode: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => ({
  isAuthenticated: isAuthenticated(state),
  discoveryId: selectDiscoveryId(state),
  showOutcomesByCategory: selectDiscoveryShowOutcomesByCategory({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_SHOW_OUTCOMES_BY_CATEGORY_REQUEST
  })
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettings,
      setDiscoveryShowOutcomesByCategory
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ShowOutcomeByCategory);
