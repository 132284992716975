import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import ROITable from "../../ROI/components/ROITable";
import ROIChart from "../../ROI/components/ROIChart";
import Card from "../../../components/Card";
import { selectDiscoveryName } from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";

const PrintReportROITwo = ({ discoveryName }) => {
  return (
    <Container>
      <Heading level={"h1"} align={"center"}>
        {s("report.roi.header", "Return on Investment for {discoveryName}", {
          discoveryName
        })}
      </Heading>
      <Card>
        <ROITable />
      </Card>
      <ROIChart />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${themeProp("palette.background")};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;

  @media print {
    canvas.chartjs-render-monitor {
      min-width: 100%;
    }
  }
`;

const mapStateToProps = (state) => ({
  discoveryName: selectDiscoveryName(state)
});

export default compose(connect(mapStateToProps))(PrintReportROITwo);
