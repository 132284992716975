import React from "react";
import styled from "styled-components";
import AlertBox from "../../../../components/AlertBox";
import { useString as s } from "../../../../components/StringProvider";

const DuplicateRoleAlert = () => {
  const title = s(
    "accessManagement.page.popup.messages.role.duplicateErrorTitle",
    "Please use different values"
  );
  const message = s(
    "accessManagement.page.popup.messages.role.duplicateError",
    "A Role with that name already exists"
  );
  return (
    <NewUserModalAlert title={title}>
      <span data-cy={"role-modal-alert"}>{message}</span>
    </NewUserModalAlert>
  );
};

const NewUserModalAlert = styled(AlertBox)`
  margin-bottom: 16px;
`;

export default DuplicateRoleAlert;
