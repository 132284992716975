import httpService from "./http.service";

const getReport = ({ reportId }) =>
  httpService
    .get(`/reports/${reportId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getReportSummary = ({ reportId }) =>
  httpService
    .get(`/reports/${reportId}/summary`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const incrementViewCount = ({ reportId }) =>
  httpService
    .post(`/reports/${reportId}/incrementViewCount`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const calculateKPIFormula = ({ reportId, kpiCode, estimationScenario }) =>
  httpService
    .post(`/reports/${reportId}/kpis/${kpiCode}/calculateDescription`, {
      estimationScenario
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const previewCashflow = ({ reportId, scenario, benefits }) =>
  httpService
    .post(`/reports/${reportId}/previewCashflow`, {
      scenario,
      benefits
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getReport,
  getReportSummary,
  incrementViewCount,
  calculateKPIFormula,
  previewCashflow
};
export default methods;
