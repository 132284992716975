import { selectRequestData } from "../reducers";
import actionTypes from "../actionTypes";

export const isConfigurationComplete = (state) => {
  const config = selectRequestData(state, actionTypes.CONFIG_SUCCESS);

  return !!config;
};

export const selectLatestTemplateVersion = (state) => {
  const config = selectRequestData(state, actionTypes.CONFIG_SUCCESS);

  return config ? config?.other?.templateVersion : null;
};

export const isSandbox = (state) => {
  const config = selectRequestData(state, actionTypes.CONFIG_SUCCESS);

  return config.sandbox !== undefined;
};

export const hasSandbox = (state) => {
  const config = selectRequestData(state, actionTypes.CONFIG_SUCCESS);

  return config.production !== undefined;
};

export const selectAuthConfiguration = (state) => {
  const config = selectRequestData(state, actionTypes.CONFIG_SUCCESS);

  return config ? config?.auth : undefined;
};

export const selectRegisterInterestFormPlacement = (state) => {
  const adminSettings = selectRequestData(
    state,
    actionTypes.GET_ADMIN_SETTINGS_REQUEST
  );

  return adminSettings?.prospectLedDiscovery?.registerInterestPlacement;
};
