import Select, { Option } from "../../../components/Select";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  selectDiscoveryKPIs,
  selectROIOverrideKpiCode,
  selectROIOverrideIsEdit,
  selectDiscoveryRoiOverrides,
  selectROIOverrideType
} from "../../../store/reducers";
import { selectedKPIsFilter } from "../../../utils/filters/kpi-filters";
import { startROIOverrideOperation } from "../../../store/actions/roi-overrides-operations";
import { useString as s } from "../../../components/StringProvider";
import styled from "styled-components";

const SelectOutcomesOverride = ({
  value,
  onChange,
  discoveryKPIs,
  roiOverrides,
  outcomeOverrideType,
  overrideKpiCode,
  overrideIsEdit,
  disabled,
  startROIOverrideOperation,
  ...props
}) => {
  const placeholderText = s(
    "discovery.roi.overrideModal.dropdown.placeholder",
    "Choose outcome"
  );

  const disabledOutcomeText = s(
    "discovery.roi.overrideModal.dropdown.item.disabled.text",
    "Override already applied"
  );

  const selectedOutcomes = selectedKPIsFilter(discoveryKPIs)
    .filter(
      (discoveryKPI) => discoveryKPI.formula || discoveryKPI.hasImpactValue
    )
    .map((kpi) => {
      return {
        kpiCode: kpi.kpiCode,
        name: kpi?.definition?.name
      };
    });

  const hasOverride = (kpiCode) => {
    return roiOverrides?.[outcomeOverrideType]?.[kpiCode];
  };

  return (
    <StyledSelect
      value={value}
      onChange={onChange}
      placeholder={placeholderText}
      style={{ width: "100%" }}
      disabled={overrideIsEdit}
      {...props}
    >
      {selectedOutcomes.map((outcome) => (
        <Option
          key={outcome.kpiCode}
          value={outcome.kpiCode}
          disabled={hasOverride(outcome.kpiCode)}
        >
          <MenuItemInner>
            <div>{outcome.name}</div>
            {!disabled && hasOverride(outcome.kpiCode) && (
              <div>{disabledOutcomeText}</div>
            )}
          </MenuItemInner>
        </Option>
      ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  max-width: 584px;
`;

const MenuItemInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
`;

const mapStateToProps = (state) => ({
  discoveryKPIs: selectDiscoveryKPIs(state),
  roiOverrides: selectDiscoveryRoiOverrides(state),
  overrideKpiCode: selectROIOverrideKpiCode(state),
  outcomeOverrideType: selectROIOverrideType(state),
  overrideIsEdit: selectROIOverrideIsEdit(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startROIOverrideOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectOutcomesOverride
);
