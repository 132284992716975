import Select from "../Select";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectDiscoverySelectedChallenges } from "../../store/reducers";
import Tooltip from "../Tooltip";
import { useString as s } from "../StringProvider";

const SelectLinkedChallenges = ({ challenges, ...props }) => {
  const challengesPlaceholder = s(
    "createKPI.popup.challenges.placeholder",
    "Pick from your selected challenges"
  );

  const sortedChallenges = [...challenges];

  sortedChallenges.sort((a, b) =>
    a.definition.description.localeCompare(b.definition.description)
  );

  const options = sortedChallenges.map((challenge) => {
    return {
      value: challenge.challengeCode,
      label: challenge.definition.description
    };
  });

  return (
    <Select
      mode={"multiple"}
      allowClear
      placeholder={challengesPlaceholder}
      data-cy={"select-challenges"}
      optionFilterProp="label"
      options={options}
      optionRender={(option) => (
        <Tooltip title={option.data.label} placement={"left"}>
          {option.data.label}
        </Tooltip>
      )}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  challenges: selectDiscoverySelectedChallenges(state)
});

export default compose(connect(mapStateToProps))(SelectLinkedChallenges);
