import styled from "styled-components";
import { Tabs as ANTTabs } from "antd";
import { themeProp } from "../theme";

const Tabs = styled(ANTTabs)`
  .ant-tabs-tab {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ant-tabs-tab-btn {
    color: ${themeProp("palette.gray4")};
    font-size: ${themeProp("typography.small.fontSize")};
    line-height: ${themeProp("typography.small.lineHeight")};
    font-weight: 700;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: ${themeProp("palette.secondary")};
      font-weight: 700;
    }
  }

  .ant-tabs-ink-bar {
    background: ${themeProp("palette.secondary")};
  }
  .ant-tabs-nav::before {
    border: 1px solid ${themeProp("palette.gray2")};
    background: ${themeProp("palette.gray2")};
  }
`;

export default Tabs;
