import { Link as TheRouterLink } from "react-router-dom";
import styled from "styled-components";
import { themeProp } from "../theme";
import PropTypes from "prop-types";

const RouterLink = styled(TheRouterLink)`
  color: ${(props) => themeProp(`components.button.${props.color}.base`)};
  transition: color 0.1s ease-out;

  &:hover {
    color: ${(props) => themeProp(`components.button.${props.color}.hover`)};
  }
  &:focus {
    color: ${(props) => themeProp(`components.button.${props.color}.focus`)};
  }
  &[disabled] {
    &:hover {
      color: ${(props) => themeProp(`components.button.${props.color}.base`)};
      opacity: 50%;
    }
  }
`;

RouterLink.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "grayPrimary",
    "headerSelected",
    "headerUnselected",
    "subheaderSelected",
    "subheaderUnselected"
  ])
};

RouterLink.defaultProps = {
  color: "primary"
};

export default RouterLink;
