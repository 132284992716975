import PropType from "prop-types";
import { selectDiscoveryChallenge } from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";

const RelatedChallengeDescription = ({ challengeCode, discoveryChallenge }) => {
  return discoveryChallenge.definition.description;
};

RelatedChallengeDescription.propTypes = {
  challengeCode: PropType.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    discoveryChallenge: selectDiscoveryChallenge(state, props.challengeCode)
  };
};

export default compose(connect(mapStateToProps))(RelatedChallengeDescription);
