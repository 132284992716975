import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Select as ANTSelect } from "antd";
import { themeProp } from "../theme";

const { Option, OptGroup } = ANTSelect;

const Select = styled(ANTSelect).attrs(() => {
  return { getPopupContainer: (element) => element };
})`
  ${({ size }) =>
    size === "large"
      ? css`
          min-height: 54px;
        `
      : css`
          min-height: 40px;
        `}

  &.ant-select .ant-select-selector {
    border-radius: 0;
  }

  &.ant-select-disabled .ant-select-selector {
    padding: 0px 12px;
    background-color: ${themeProp("palette.disabled")} !important;
    color: ${themeProp("palette.gray4")} !important;
    border-bottom-color: ${themeProp("palette.gray2")} !important;
  }

  &.ant-select-disabled .ant-select-arrow {
    display: none;
  }

  &.ant-select .ant-select-selector {
    background-color: transparent;
  }

  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border: none;
      border-bottom: 1px solid ${themeProp("palette.secondary")};
    }

    &:hover {
      .ant-select-selector {
        border-bottom: 1px solid ${themeProp("palette.secondary")};
      }
    }
  }
  &.ant-select-focused:not(.ant-select-customize-input) {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }
  .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding-right: 24px;
    }
  }
  &.ant-select-single .ant-select-selector .ant-select-selection-search-input {
    height: 55px;
  }

  .ant-select-arrow {
    color: ${themeProp("palette.primary")};
  }

  .ant-select-item-option-selected {
    font-weight: ${themeProp("typography.body.fontWeight")};
    background-color: inherit;
  }

  & .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    font-weight: ${themeProp("typography.bMedium.fontWeight")};
    background-color: #f5f5f5 !important;
  }

  &.ant-select.ant-select-multiple span.ant-select-selection-item {
    background-color: ${themeProp("palette.gray3")};
    color: ${themeProp("palette.surface")};
    font-size: ${themeProp("typography.small.fontSize")};
    font-family: ${themeProp("typography.small.fontFamily")};
    font-weight: 700;
    line-height: ${themeProp("typography.small.lineHeight")};
    height: unset;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
  }

  &.ant-select.ant-select-multiple span.ant-select-selection-item-disabled {
    background-color: ${themeProp("palette.gray2")};
  }

  &.ant-select.ant-select-multiple .ant-select-item-option-state {
    color: ${themeProp("palette.gray3")};
  }

  &.ant-select.ant-select-multiple span.ant-select-selection-item-content {
    display: inline-block;
    width: 100px;
    max-width: 100px;
    text-overflow: ellipsis;
  }

  &.ant-select.ant-select-multiple.wide span.ant-select-selection-item-content {
    width: 200px;
    max-width: 200px;
  }

  &.ant-select.ant-select-multiple .ant-select-selection-item-remove {
    color: ${themeProp("palette.surface")};
    height: 11px;
    width: 11px;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    font-size: ${themeProp("typography.body.fontSize")};
  }
`;

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.label,
      value: PropTypes.string.isRequired
    })
  ),
  size: PropTypes.oneOf(["medium", "large"])
};

Select.defaultProps = {
  size: "large"
};

export default Select;
export { Option, OptGroup };
