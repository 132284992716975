import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Markdown from "../../../components/Discovery/Markdown";
import PropTypes from "prop-types";
import Checkbox from "../../../components/Checkbox";

const CheckBoxCard = ({ text, editable, style, value }) => {
  return (
    <StyledCheckbox
      value={value}
      disabled={!editable}
      style={style}
      data-cy={`question-option-card-${value}`}
    >
      <Markdown>{text}</Markdown>
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled(Checkbox)`
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  border-radius: 8px;
  padding: 16px;
  background: ${themeProp("palette.surface")};
  user-select: none;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  width: 100%;

  &.ant-checkbox-wrapper-checked {
    background: ${themeProp("palette.secondary")};

    & span:not(.ant-checkbox) * {
      color: white;
    }
  }

  & .ant-checkbox-wrapper {
    align-items: center;
  }

  & .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-top: 0;
    margin-left: 0;
  }

  & .ant-checkbox {
    margin-right: 5px;
    top: unset;
  }
`;

CheckBoxCard.propTypes = {
  value: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  style: PropTypes.any
};

export default CheckBoxCard;
