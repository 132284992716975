import React from "react";
import PropType from "prop-types";
import { useString as s } from "../../../../components/StringProvider";
import Heading from "../../../../components/Heading";
import styled from "styled-components";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { startEditingKPI } from "../../../../store/actions/edit-kpis";
import Range from "../../../../components/Range";
import KPIImpactFormats from "../../../../utils/kpi-impact-formats";

const NewKPIImpactPercentage = ({
  discoveryKPI,
  reportMode,
  startEditingKPI
}) => {
  const notQuantifiedText = s(
    "discovery.kpi.card.impact.notQuantified",
    "Not quantified"
  );

  const onClick = () => {
    startEditingKPI({ kpiCode: discoveryKPI.kpiCode });
  };

  return (
    <Container
      color={
        discoveryKPI.hasImpactValue ||
        discoveryKPI.hasImpactPercentage ||
        reportMode
          ? "text"
          : "primary"
      }
    >
      {discoveryKPI.hasImpactPercentage ? (
        <Heading level={"h3"}>
          <Range
            first={discoveryKPI.lowImpactPercentage}
            second={discoveryKPI.highImpactPercentage}
            type={
              discoveryKPI.definition.impactFormat ===
              KPIImpactFormats.MULTIPLIER
                ? KPIImpactFormats.MULTIPLIER
                : KPIImpactFormats.PERCENTAGE
            }
          />
        </Heading>
      ) : (
        <>
          {reportMode ? (
            <Heading
              level={discoveryKPI.hasImpactValue ? "h3" : "h4"}
              color={"text"}
            >
              {notQuantifiedText}
            </Heading>
          ) : (
            <>
              <a onClick={onClick}>
                <Heading
                  level={discoveryKPI.hasImpactValue ? "h3" : "h4"}
                  color={"text"}
                >
                  {discoveryKPI.definition.noFormulaReason || notQuantifiedText}
                </Heading>
              </a>
              <br />
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  text-align: right;

  & h4,
  & h3 {
    margin-bottom: 0;
    text-align: right;
    display: inline;
  }
`;

NewKPIImpactPercentage.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  reportMode: PropType.bool.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startEditingKPI
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewKPIImpactPercentage
);
