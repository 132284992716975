import OnlyIfUserCanAccessDiscoveryPath from "../../Pages/Discovery/components/OnlyIfUserCanAccessDiscoveryPath";
import React from "react";
import { useString as s } from "../StringProvider";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import NewButton from "../NewButton";
import { hasPermission, isDiscoveryROIComplete } from "../../store/reducers";
import Permissions from "../../utils/permissions";
import { connect } from "react-redux";

const GoToROIButton = ({
  history,
  discoveryId,
  isROIComplete,
  canUpdateROIValues,
  hasLeadGenerationPermission
}) => {
  const evaluateROI = s("discovery.opportunity.page.button1", "Evaluate ROI");

  if (hasLeadGenerationPermission) {
    return null;
  }

  const onClick = () => {
    history.push({
      pathname: `/discoveries/${discoveryId}/roi`,
      search: isROIComplete
        ? "?edit-assumptions=false"
        : "?edit-assumptions=true"
    });
  };

  return isROIComplete || canUpdateROIValues ? (
    <OnlyIfUserCanAccessDiscoveryPath path={"roi"}>
      <NewButton
        type={"primary"}
        onClick={onClick}
        data-cy={"evaluate-roi-button"}
        className={"up-evaluate-roi-button"}
      >
        {evaluateROI}
      </NewButton>
    </OnlyIfUserCanAccessDiscoveryPath>
  ) : null;
};

GoToROIButton.propTypes = {
  discoveryId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    canUpdateROIValues: hasPermission(state, Permissions.UPDATE_ROI_VALUES),
    isROIComplete: isDiscoveryROIComplete(state),
    hasLeadGenerationPermission: hasPermission(
      state,
      Permissions.SEE_LEAD_GENERATION
    )
  };
};

export default compose(withRouter, connect(mapStateToProps))(GoToROIButton);
