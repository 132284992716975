import types from "../actionTypes";
import deploymentsService from "../../services/deployments.service";

export const downloadAllPowerpointFileDeployments = () => (dispatch) => {
  dispatch({
    type: types.DOWNLOAD_ALL_FILE_DEPLOYMENTS_REQUEST
  });

  return deploymentsService
    .downloadAllPowerpointFileDeployments()
    .then((payload) => {
      dispatch({
        type: types.DOWNLOAD_ALL_FILE_DEPLOYMENTS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.DOWNLOAD_ALL_FILE_DEPLOYMENTS_FAILURE,
        error
      });
    });
};
