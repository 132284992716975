import PropType from "prop-types";
import React from "react";
import AboutThisChallenge from "./AboutThisChallenge";

const StandardDetails = ({
  discoveryChallenge,
  selected,
  reportId,
  canSeeInternalLinksAndComments,
  printMode
}) => {
  return selected ? (
    <div>
      {discoveryChallenge.definition.detailText ||
      discoveryChallenge.definition.kpiMappings.length > 0 ? (
        <AboutThisChallenge
          discoveryChallenge={discoveryChallenge}
          reportMode={!!reportId}
          canSeeInternalLinksAndComments={canSeeInternalLinksAndComments}
          printMode={printMode}
        />
      ) : null}
    </div>
  ) : null;
};

StandardDetails.propTypes = {
  discoveryChallenge: PropType.object.isRequired,
  selected: PropType.bool.isRequired,
  reportId: PropType.string,
  canSeeInternalLinksAndComments: PropType.bool.isRequired,
  printMode: PropType.bool
};

StandardDetails.defaultProps = {
  onChangeImportance: () => {},
  editable: false
};

export default StandardDetails;
