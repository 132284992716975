import styled from "styled-components";
import { TextArea } from "../../Input";
import { themeProp } from "../../../theme";
import { useMobileMediaQuery } from "../../Responsive";
import React from "react";

const KPINameTextArea = React.forwardRef(({ ...props }, inputRef) => {
  const isMobile = useMobileMediaQuery();

  return (
    <StyledTextArea
      {...props}
      className={isMobile ? "mobile" : undefined}
      ref={inputRef}
    />
  );
});

const StyledTextArea = styled(TextArea)`
  font-family: ${themeProp("typography.h3.fontFamily")};
  font-size: ${themeProp("typography.h3.fontSize")};
  font-weight: ${themeProp("typography.h3.fontWeight")};
  line-height: ${themeProp("typography.h3.lineHeight")};
  padding-left: 0px;

  &.mobile {
    font-family: ${themeProp("typography.bMediumMobile.fontFamily")};
    font-size: ${themeProp("typography.bMediumMobile.fontSize")};
    font-weight: ${themeProp("typography.bMediumMobile.fontWeight")};
    line-height: ${themeProp("typography.bMediumMobile.lineHeight")};
    padding-left: 11px;
  }
`;

export default KPINameTextArea;
