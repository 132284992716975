import types from "../actionTypes";

export const startEditingChallenge =
  ({ challengeCode, position }) =>
  (dispatch) => {
    dispatch({
      type: types.START_EDITING_CHALLENGE,
      payload: { challengeCode, position }
    });
  };

export const stopEditingChallenge = () => (dispatch) => {
  dispatch({
    type: types.STOP_EDITING_CHALLENGE
  });
};
