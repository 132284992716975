import styled from "styled-components";
import { themeProp } from "../theme";
import PropTypes from "prop-types";
import RouterLink from "./RouterLink";

const Link = ({ to, color, disabled, children, ...props }) => {
  if (disabled) {
    return (
      <DisabledLink color={color} {...props}>
        {children}
      </DisabledLink>
    );
  }

  return (
    <RouterLink to={to} color={color} {...props}>
      {children}
    </RouterLink>
  );
};

const DisabledLink = styled.div`
  cursor: not-allowed;
  color: ${(props) => themeProp(`components.button.${props.color}.base`)};
  opacity: 50%;
`;

Link.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "grayPrimary",
    "headerSelected",
    "headerUnselected",
    "subheaderSelected",
    "subheaderUnselected"
  ])
};

Link.defaultProps = {
  color: "primary"
};

export default Link;
