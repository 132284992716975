import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  fetchDiscovery,
  refreshDiscovery,
  saveDiscoveryCheckpoint
} from "../../store/actions/discoveries";
import styled from "styled-components";
import FadeLoader from "react-spinners/FadeLoader";
import { useTheme } from "../../components/ThemeProvider";
import {
  selectAllPermissions,
  selectDiscoveryLastViewedPage,
  selectDiscoveryCheckpoint,
  selectDiscoveryLoadingError,
  selectDiscoveryLoadingState,
  selectDiscoveryMasterDataVersion,
  selectDiscoveryMaximumPhase,
  selectDiscoveryRefreshLoadingState,
  selectMasterDataLoadingState,
  selectMasterDataVersion,
  selectUserInfoLoadingState
} from "../../store/reducers";
import DiscoveryOperations from "../../components/DiscoveryOperations";
import { getMasterData } from "../../store/actions/master-data";
import DiscoveryOperationsConfirmation from "../../components/DiscoveryOperations/DiscoveryOperationsConfirmation";
import ErrorPage from "../ErrorPage";
import LoadingState, { combineLoadingStates } from "../../utils/loading-state";
import { getDiscoveryCommentCount } from "../../store/actions/comments";
import ExportDiscoveryMonitor from "../../components/Discovery/ExportDiscoveryMonitor";
import DiscoveryPageContents from "./DiscoveryPageContents";
import useLoadingState from "../../utils/use-loading-state";
import DiscoveryForbiddenPage from "./DiscoveryForbiddenPage";
import CookieNames from "../../utils/cookie-names";
import { getCookie } from "../../store/actions/cookies";
import actionTypes from "../../store/actionTypes";
import { getAdminSettings } from "../../store/actions/config";
import RegisterInterestPrompt from "./RegisterInterestPrompt";
import DiscoveryPage from "./DiscoveryPage";

const Discovery = ({
  loadingState,
  refreshLoadingState,
  loadingError,
  masterDataVersion,
  discoveryMasterDataVersion,
  match,
  checkpoint,
  lastViewedPage,
  fetchDiscovery,
  saveDiscoveryCheckpoint,
  getMasterData,
  getDiscoveryCommentCount,
  refreshDiscovery,
  getCookie,
  getAdminSettings,
  permissions,
  maximumPhase
}) => {
  const discoveryId = match.params.discoveryId;
  const location = useLocation();
  const loaderColor = useTheme("palette.primary");
  const { pathname, search } = location;
  const [currentPath, setCurrentPath] = useState("");
  const matches = pathname.match(
    /^\/discoveries\/[^\/]+(\/[^\/]+)(\/[^\/]+)?$/
  );
  const newPath = (matches ? matches[1] : "") + search;

  useEffect(() => {
    getCookie({ name: CookieNames.SHOW_DISCOVERY_ON_UNSUPPORTED_RESOLUTION });
  }, []);

  useEffect(() => {
    getAdminSettings();
  }, []);

  useEffect(() => {
    fetchDiscovery(discoveryId);
  }, [discoveryId, fetchDiscovery]);

  useEffect(() => {
    if (
      discoveryMasterDataVersion &&
      discoveryMasterDataVersion !== masterDataVersion
    ) {
      getMasterData({ version: discoveryMasterDataVersion });
    }
  }, [discoveryMasterDataVersion, masterDataVersion, getMasterData]);

  useEffect(() => {
    if (newPath !== currentPath) {
      if (currentPath) {
        refreshDiscovery({ discoveryId });
      }

      setCurrentPath(newPath);
    } else if (currentPath) {
      saveDiscoveryCheckpoint({
        discoveryId,
        checkpoint: pathname,
        lastViewedPage: pathname + location.search
      });
    }
  }, [newPath, pathname]);

  useLoadingState(
    loadingState,
    () => {
      saveDiscoveryCheckpoint({
        discoveryId,
        checkpoint: pathname,
        lastViewedPage: pathname + location.search
      });
    },
    () => {}
  );

  useLoadingState(
    refreshLoadingState,
    () => {
      saveDiscoveryCheckpoint({
        discoveryId,
        checkpoint: pathname,
        lastViewedPage: pathname + location.search
      });
    },
    () => {}
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [newPath]);

  useEffect(() => {
    getDiscoveryCommentCount({ discoveryId });
  }, [discoveryId, getDiscoveryCommentCount]);

  if (loadingState === LoadingState.FAILED) {
    return loadingError?.status === 403 ? (
      <DiscoveryForbiddenPage discoveryId={discoveryId} error={loadingError} />
    ) : (
      <ErrorPage error={loadingError} />
    );
  } else if (
    loadingState === LoadingState.UNINITIALIZED ||
    loadingState === LoadingState.IN_PROGRESS
  ) {
    return (
      <LoaderContainer>
        <Loader>
          <FadeLoader color={loaderColor} size="50" />
        </Loader>
      </LoaderContainer>
    );
  }

  return (
    <>
      <RegisterInterestPrompt />
      <DiscoveryPage>
        <DiscoveryPageContents
          discoveryId={discoveryId}
          checkpoint={checkpoint}
          lastViewedPage={lastViewedPage}
          maximumPhase={maximumPhase}
          permissions={permissions}
        />
      </DiscoveryPage>
      <DiscoveryOperations />
      <DiscoveryOperationsConfirmation />
      <ExportDiscoveryMonitor
        actionType={actionTypes.DISCOVERY_EXPORT_REQUEST}
      />
    </>
  );
};

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  width: 100%;
  height: 100vh;
`;
const Loader = styled.div`
  position: relative;
  height: 50px;
  width: 50px;
`;

function mapStateToProps(state) {
  return {
    userInfoLoadingState: selectUserInfoLoadingState(state),
    loadingState: combineLoadingStates([
      selectDiscoveryLoadingState(state),
      selectMasterDataLoadingState(state)
    ]),
    refreshLoadingState: selectDiscoveryRefreshLoadingState(state),
    loadingError: selectDiscoveryLoadingError(state),
    discoveryMasterDataVersion: selectDiscoveryMasterDataVersion(state),
    masterDataVersion: selectMasterDataVersion(state),
    lastViewedPage: selectDiscoveryLastViewedPage(state),
    checkpoint: selectDiscoveryCheckpoint(state),
    maximumPhase: selectDiscoveryMaximumPhase(state),
    permissions: selectAllPermissions(state)
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDiscovery,
      saveDiscoveryCheckpoint,
      getMasterData,
      getDiscoveryCommentCount,
      refreshDiscovery,
      getCookie,
      getAdminSettings
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Discovery);
