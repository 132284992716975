import types from "../actionTypes";
import jobsService from "../../services/jobs.service";

export const getJob =
  ({ jobId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_JOB_REQUEST,
      parameters: { jobId }
    });

    return jobsService
      .getJob({
        jobId
      })
      .then((payload) => {
        dispatch({
          type: types.GET_JOB_SUCCESS,
          payload,
          parameters: { jobId }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_JOB_FAILURE,
          error,
          parameters: { jobId }
        });
      });
  };
