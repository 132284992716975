import { TruncatedParagraph } from "../../components/Typography";
import styled, { withTheme } from "styled-components";
import { themeProp } from "../../theme";
import PropTypes from "prop-types";
import Color from "color";
import React from "react";

const ValueMapCardText = React.forwardRef(
  ({ text, type, code, maxRows }, ref) => {
    const variant = "body";

    return (
      <Container
        data-cy={`${type}-${code}-text`}
        className={"value-map-card-title"}
        ref={ref}
      >
        <TruncatedParagraph
          fullText={text}
          rows={maxRows}
          variant={variant}
          hasTooltip={false}
        >
          {text}
        </TruncatedParagraph>
      </Container>
    );
  }
);

const Container = styled.div`
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  & .ant-typography {
    margin-bottom: 0;
    color: ${(props) => {
      const rgbArray = Color(themeProp("palette.gray4")(props)).rgb().array();
      return `rgba(${rgbArray.join(",")}, 0.7)`;
    }};
    font-size: ${themeProp(`typography.body.fontSize`)} !important;
    font-family: ${themeProp(`typography.body.fontFamily`)} !important;
    font-weight: 600 !important;
    line-height: ${themeProp(`typography.body.lineHeight`)} !important;
  }

  *:hover > & .ant-typography {
    color: ${themeProp("palette.gray4")};
  }
  .selected > & .ant-typography {
    color: ${themeProp("palette.text")};
  }
  .selected.keyObjective > & .ant-typography {
    color: ${themeProp("palette.surface")};
  }

  .selected.keyObjective & .ant-typography {
    color: ${themeProp("palette.surface")};
  }
`;

ValueMapCardText.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  maxRows: PropTypes.number.isRequired
};

export default withTheme(ValueMapCardText);
