import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../../theme";

const ValueMapTextForm = ({
  text,
  onCancel,
  onSubmit,
  customKeydown,
  minHeight,
  placeholder,
  ...props
}) => {
  const textRef = useRef();
  const [value, setValue] = useState(text);

  const onKeyUp = (e) => {
    if (e.keyCode === 27) {
      onCancel();
      e.preventDefault();
    }
  };

  const onKeyDown = (e) => {
    if (customKeydown && typeof customKeydown === "function") {
      customKeydown(e);
      return;
    }

    if (e.keyCode === 13) {
      onSubmit({ text: e.target.value });
      e.preventDefault();
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (textRef.current) {
        textRef.current.focus({
          preventScroll: true
        });
        textRef.current.selectionStart = textRef.current.selectionEnd = 10000;
        textRef.current.style.height = "";
        textRef.current.style.height = textRef.current.scrollHeight + "px";
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [textRef]);

  const onBlur = (e) => {
    onSubmit({ text: e.target.value });
  };

  return (
    <StyledTextArea
      ref={textRef}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onInput={(e) => {
        e.target.style.height = e.target.scrollHeight + "px";
      }}
      value={value}
      rows={1}
      minHeight={minHeight}
      placeholder={placeholder}
      {...props}
    />
  );
};

const StyledTextArea = styled.textarea`
  min-height: ${(props) =>
    props.minHeight
      ? `${props.minHeight}px`
      : themeProp(`typography.body.lineHeight`)};
  margin: 0;
  background: transparent;
  border: 0;
  padding: 0;
  resize: none;
  overflow: hidden;
  font-weight: 600;
  width: 100%;

  ::placeholder {
    color: ${themeProp("palette.surface")};
    opacity: 0.5;
  }
`;

ValueMapTextForm.propTypes = {
  text: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  customKeydown: PropTypes.func,
  minHeight: PropTypes.number,
  placeholder: PropTypes.string
};

export default ValueMapTextForm;
