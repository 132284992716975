import React, { createContext, useEffect, useState, useRef } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  selectDiscoveryChallenges,
  selectDiscoveryId,
  selectDiscoveryBusinessCriticalChallengeCode,
  selectDiscoverySetting
} from "../../store/reducers";
import { reorderDiscoveryChallenges } from "../../store/actions/discoveries";
import { reorderItems } from "../ValueMap/value-map-utils";
import { DropDirection as DragDirection } from "../ValueMap/ValueMapCard";
import {
  sendUserPilotEvent,
  UserPilotEventNames
} from "../../utils/user-pilot-util";
import {
  isCustomChallenge,
  isTypicalChallenge
} from "../../utils/filters/challenge-filters";
import { sortDiscoveryChallengesByOrder } from "../../utils/sorting";
import DiscoverySettings from "../../utils/discoverySettings";
import ShowCardOptions from "../../utils/show-card-options";

export const SelectChallengesContext = createContext();

export const SelectChallengesProvider = ({
  discoveryChallenges,
  discoveryId,
  businessCriticalChallengeCode,
  reorderDiscoveryChallenges,
  showChallenges,
  children
}) => {
  const [keyObjective, setKeyObjective] = useState(null);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [itemsAndKeyObjectiveCode, setItemsAndKeyObjectiveCode] = useState({
    keyObjectiveCode: "",
    items: []
  });

  const oldItemsRef = useRef([]);
  const oldKeyObjectiveCodeRef = useRef(undefined);

  useEffect(() => {
    setShowSelectedOnly(showChallenges === ShowCardOptions.SELECTED_CARDS);
  }, [showChallenges]);

  const moveKeyObjectiveCodeToStartOfList = ({ keyObjectiveCode, codes }) => {
    return [
      keyObjectiveCode,
      ...codes.filter((code) => code !== keyObjectiveCode)
    ];
  };

  const onChangeKeyObjective = (code) => {
    setItemsAndKeyObjectiveCode((oldItemsAndKeyObjectiveCode) => ({
      keyObjectiveCode: code,
      items: reorderItems({
        code: code,
        destinationCode: oldItemsAndKeyObjectiveCode.items[0]?.code,
        items: oldItemsRef.current,
        direction: DragDirection.KEY_OBJECTIVE,
        showSelectedOnly: showSelectedOnly.current
      })
    }));

    sendUserPilotEvent({
      eventName: UserPilotEventNames.MARK_AS_CBI,
      data: { discoveryId, code: code }
    });

    const codes = moveKeyObjectiveCodeToStartOfList({
      keyObjectiveCode: code,
      codes: oldItemsRef.current.map((i) => i.code)
    });

    reorderDiscoveryChallenges({
      discoveryId,
      keyObjectiveCode: code,
      codes
    });
  };

  const onReorder = ({ code, destinationCode, direction }) => {
    const newItems = reorderItems({
      code,
      destinationCode,
      direction,
      items: oldItemsRef.current,
      showSelectedOnly: showSelectedOnly.current
    });

    setItemsAndKeyObjectiveCode((oldItemsAndKeyObjectiveCode) => ({
      keyObjectiveCode:
        oldItemsAndKeyObjectiveCode.keyObjectiveCode === code
          ? ""
          : oldItemsAndKeyObjectiveCode.keyObjectiveCode,
      items: newItems
    }));

    const keyObjectiveCode =
      direction === DragDirection.REMOVE_KEY_OBJECTIVE
        ? ""
        : oldKeyObjectiveCodeRef.current;

    reorderDiscoveryChallenges({
      discoveryId,
      keyObjectiveCode,
      codes: newItems.map((i) => i.code)
    });

    sendUserPilotEvent({
      eventName: UserPilotEventNames.UNMARK_AS_CBI,
      data: { discoveryId }
    });
  };

  const removeKeyObjective = (code) => {
    onReorder({
      code: code,
      destinationCode: code,
      direction: DragDirection.REMOVE_KEY_OBJECTIVE
    });
  };

  useEffect(() => {
    if (discoveryChallenges) {
      const challenges = [...discoveryChallenges.filter(isTypicalChallenge)];

      challenges.sort(sortDiscoveryChallengesByOrder);

      const newItems = challenges.map((c) => ({
        code: c.challengeCode,
        text: c.definition.description,
        selected: c.selected,
        custom: isCustomChallenge({ discoveryChallenge: c })
      }));

      setItemsAndKeyObjectiveCode({
        keyObjectiveCode: businessCriticalChallengeCode,
        items: newItems
      });
    }
  }, [discoveryChallenges, businessCriticalChallengeCode]);

  useEffect(() => {
    if (itemsAndKeyObjectiveCode?.items?.length) {
      setKeyObjective(
        itemsAndKeyObjectiveCode.items.find(
          (i) => i.code === itemsAndKeyObjectiveCode.keyObjectiveCode
        )
      );
    }

    oldItemsRef.current = itemsAndKeyObjectiveCode?.items;
    oldKeyObjectiveCodeRef.current = itemsAndKeyObjectiveCode?.keyObjectiveCode;
  }, [itemsAndKeyObjectiveCode]);

  return (
    <SelectChallengesContext.Provider
      value={{
        onReorder,
        onChangeKeyObjective,
        removeKeyObjective,
        keyObjective
      }}
    >
      {children}
    </SelectChallengesContext.Provider>
  );
};

const mapStateToProps = (state) => {
  const showChallenges = selectDiscoverySetting(
    state,
    DiscoverySettings.SHOW_CHALLENGES,
    ShowCardOptions.ALL_CARDS
  );

  return {
    showChallenges,
    discoveryChallenges: selectDiscoveryChallenges(state),
    discoveryId: selectDiscoveryId(state),
    businessCriticalChallengeCode:
      selectDiscoveryBusinessCriticalChallengeCode(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      reorderDiscoveryChallenges
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectChallengesProvider
);
