import Form, { FormItem } from "../../components/Form";
import { EmailInput } from "../../components/Input";
import { useString as s } from "../../components/StringProvider";
import NewButton from "../../components/NewButton";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Hyperlink from "../../components/Hyperlink";
import { Text } from "../../components";
import LoginFormContainer, { FormContainer } from "../Login/LoginFormContainer";
import Heading from "../../components/Heading";
import ButtonGroup from "../../components/ButtonGroup";
import styled from "styled-components";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

const ForgotPasswordForm = ({
  history,
  form,
  onFinish,
  error,
  invitation,
  loading
}) => {
  const invitationPageHeader = s(
    "forgotPassword.page.invitations.header",
    "Welcome"
  );
  const pageHeader = s("forgotPassword.page.header", "Password Reset");
  const invitationPageSubheader = s(
    "forgotPassword.page.invitations.subheader",
    "Enter your email to create your account."
  );
  const pageSubheader = s(
    "forgotPassword.page.subheader",
    "No worries, just type your email and we'll help you. If this account exists, we’ll send a code. If you’ve never logged in, go to sign up instead."
  );
  const backToLogin = s("forgotPassword.page.backToLogin", "Back to log in");

  const emailValidationError = s(
    "enterEmail.email.validation",
    "Please input your email"
  );
  const emailPlaceholder = s("enterEmail.email.placeholder", "Your Email");
  const sendVerificationCode = s("enterEmail.button", "Send verification code");
  const privacyText = s(
    "signup.page.privacy",
    "By signing up, you’re agreeing to our"
  );
  const privacyPolicyText = s("signup.page.privacyPolicy", "Privacy Policy");

  const [emailError, setEmailError] = useState(error);

  useEffect(() => {
    if (error) {
      setEmailError(error);
      form.validateFields(["email"]);
    }
  }, [error]);

  const onEmailFocus = () => {
    setEmailError("");
  };

  const onLogin = () => {
    if (invitation) {
      history.push({ pathname: `/invitations/${invitation?.code}/login` });
    } else {
      history.push({ pathname: "/login" });
    }
  };

  return (
    <LoginFormContainer>
      <FormContainer>
        <Heading level={"h2"} data-cy={"page-header"}>
          {invitation ? invitationPageHeader : pageHeader}
        </Heading>
        <div className={"subheader"} data-cy={"page-subheader"}>
          <Text>{invitation ? invitationPageSubheader : pageSubheader}</Text>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          className={"login"}
          layout={"vertical"}
        >
          <FormItem
            name="email"
            rules={[
              { required: true, message: emailValidationError, type: "email" },
              () => ({
                validator() {
                  if (!emailError) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error(emailError));
                  }
                }
              })
            ]}
          >
            <EmailInput
              placeholder={emailPlaceholder}
              autoComplete={"email"}
              onFocus={onEmailFocus}
            />
          </FormItem>
          {invitation ? (
            <Privacy>
              <Text variant={"bodyMobile"} color="gray4">
                {privacyText}{" "}
                <Hyperlink
                  to={"https://cuvama.com/privacy-policy/"}
                  target={"_blank"}
                >
                  {privacyPolicyText}
                </Hyperlink>
              </Text>
            </Privacy>
          ) : null}
          <ButtonGroup mobile={true}>
            <NewButton
              type={"submit"}
              data-cy={"forgot-password-button"}
              loading={loading}
              disabled={loading}
            >
              {sendVerificationCode}
            </NewButton>
            <NewButton
              type={"secondary"}
              to={
                invitation ? `/invitations/${invitation?.code}/login` : "/login"
              }
              data-cy={"back-to-login-button"}
              onClick={onLogin}
            >
              {backToLogin}
            </NewButton>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </LoginFormContainer>
  );
};

const Privacy = styled.div`
  margin-top: 16px;
`;

ForgotPasswordForm.propTypes = {
  form: PropTypes.object.isRequired,
  error: PropTypes.string,
  onFinish: PropTypes.func.isRequired,
  invitation: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default compose(withRouter)(ForgotPasswordForm);
