import httpService from "./http.service";

const getFeatures = () =>
  httpService
    .get(`/features`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateFeatures = ({ changes }) =>
  httpService
    .patch(`/features`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getFeatures,
  updateFeatures
};
export default methods;
