import { withRouter } from "react-router-dom";
import {
  isAuthenticated,
  selectRequestData,
  selectRequestError
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { selectAuthConfiguration } from "../../store/selectors/config";
import Loader from "../../components/Loader";
import redirectToOAuthLogin from "../../utils/redirect-to-oauth-login";
import actionTypes from "../../store/actionTypes";
import errorsService from "../../services/errors.service";

const Callback = ({
  history,
  authConfiguration,
  isAuthenticated,
  stateData,
  ssoError
}) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      if (stateData) {
        history.push(stateData);
        return;
      }

      history.push({ pathname: "/MyDiscoveries" });
    } else {
      if (!stateData || stateData.retry) {
        setError(true);
      } else {
        redirectToOAuthLogin({
          stateData: {
            ...stateData,
            retry: 1
          },
          identityProvider: authConfiguration.oauth.identityProvider
        });
      }
    }
  }, [isAuthenticated, stateData]);

  useEffect(() => {
    if (error && ssoError) {
      errorsService
        .logError({
          category: "sso-callback",
          data: ssoError.errorDescription,
          error: ssoError.errorDescription
        })
        .then(() => {
          console.log("Logged SSO error to backend");
          history.push({ pathname: "/login" });
        })
        .catch((e) => {
          console.log("Failed to log error to backend", e);
          history.push({ pathname: "/login" });
        });
    }
  }, [error, ssoError]);

  return <Loader />;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    authConfiguration: selectAuthConfiguration(state),
    stateData: selectRequestData(state, actionTypes.LOGIN_STATE_SUCCESS),
    ssoError: selectRequestError(state, actionTypes.SSO_LOGIN_FAILURE)
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Callback);
