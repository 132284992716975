import { selectRequestData } from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { generateAutoconfiguration } from "../../../../store/actions/autoconfigurations";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AutoconfigurationLayout from "./AutoconfigurationLayout";
import { useEffect, useState } from "react";
import Table from "../../../../components/Table";
import { useString as s } from "../../../../components/StringProvider";

const AutoconfigurationMappings = ({
  autoconfiguration,
  generateAutoconfiguration
}) => {
  const [ready, setReady] = useState(false);
  const [data, setData] = useState([]);
  const columns = [];

  useEffect(() => {
    if (autoconfiguration) {
      setData(
        (autoconfiguration?.challenges || []).map((o) => ({
          ...o,
          key: o.code
        }))
      );
    }
  }, [autoconfiguration]);

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.mappings.code",
      "Code"
    ),
    dataIndex: "code",
    key: "code"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.mappings.description",
      "Description"
    ),
    dataIndex: "description",
    key: "description"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.challenges.mappings",
      "KPIs"
    ),
    dataIndex: "kpiMappings",
    key: "kpiMappings",
    render: (values) => (
      <ul>
        {values.map((code) => (
          <li key={code}>
            {autoconfiguration.kpis.find((k) => k.code === code)?.name}
          </li>
        ))}
      </ul>
    )
  });

  useEffect(() => {
    if (!autoconfiguration?.challenges?.length) {
      return;
    }

    const challengeWithMapping = autoconfiguration?.challenges?.find(
      (c) => !!c?.kpiMappings?.length
    );
    if (!challengeWithMapping) {
      generateAutoconfiguration({
        autoconfigurationId: autoconfiguration._id,
        section: "mappings"
      });
    } else if (autoconfiguration) {
      setReady(true);
    }
  }, [autoconfiguration]);

  return (
    <AutoconfigurationLayout>
      {ready && (
        <Table dataSource={data} columns={columns} pagination={false} />
      )}
    </AutoconfigurationLayout>
  );
};

const mapStateToProps = (state) => ({
  autoconfiguration: selectRequestData(
    state,
    actionTypes.GET_AUTOCONFIGURATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generateAutoconfiguration
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AutoconfigurationMappings);
