import Menu, { MenuItem } from "../../components/Menu";
import React from "react";
import { useString as s } from "../../components/StringProvider";
import Icon from "../../components/Icon";
import Dropdown from "../../components/Dropdown";
import styled from "styled-components";
import NewButton from "../../components/NewButton";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { exportDiscoveries } from "../../store/actions/discoveries";

const CSV = "csv";
const XLSX = "xlsx";

const ExportDiscoveriesDropdown = ({ exportDiscoveries }) => {
  const exportCSVText = s("discovery.operation.exportCSV.name", "Headers only");
  const exportXLSXText = s(
    "discovery.operation.exportXLSX.name",
    "Headers and content"
  );

  const onClick = (e) => {
    if (e.key) {
      exportDiscoveries({ format: e.key });
    }
  };

  const renderMenu = () => (
    <NarrowMenu onClick={onClick}>
      <MenuItem key={CSV} icon={<Icon name={CSV} />}>
        {exportCSVText}
      </MenuItem>
      <MenuItem key={XLSX} icon={<Icon name={"xls"} />}>
        {exportXLSXText}
      </MenuItem>
    </NarrowMenu>
  );

  return (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomRight"}
      trigger={["click"]}
    >
      <NewButton type={"text"} onClick={onClick} data-cy={"export-discoveries"}>
        <Icon name={"download"} />
        {s("discovery.operation.export.name", "Export")}
      </NewButton>
    </Dropdown>
  );
};

const NarrowMenu = styled(Menu)`
  max-width: 240px;
  min-width: 240px;
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportDiscoveries
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExportDiscoveriesDropdown
);
