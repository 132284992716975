import types from "../actionTypes";

const initialState = {
  features: {}
};

const featuresReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_FEATURES_SUCCESS:
    case types.UPDATE_FEATURES_SUCCESS:
      return { ...state, features: payload };

    default:
      return state;
  }
};

export const selectFeatures = (featuresData) => featuresData.features;

export default featuresReducer;
