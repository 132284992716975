import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useString as s } from "../../components/StringProvider";
import Text from "../../components/Text";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import {
  fetchDiscovery,
  freezeDiscoveryKPIs,
  saveDiscoveryCheckpoint
} from "../../store/actions/discoveries";
import { connect } from "react-redux";
import {
  selectDiscoveryKPIs,
  selectDiscoveryName,
  selectRequestState,
  isFullscreen,
  selectDiscoveryCanvasLogoPath,
  selectDiscoverySetting,
  selectDiscoveryCanvasTitle
} from "../../store/reducers";
import useLoadingState from "../../utils/use-loading-state";
import types from "../../store/actionTypes";
import ValueMapChallengeList from "./ValueMapChallengeList";
import ValueMapOutcomeList from "./ValueMapOutcomeList";
import ErrorPage from "../ErrorPage";
import { combineLoadingStates } from "../../utils/loading-state";
import { Space } from "antd";
import DiscoveryOperations from "../../components/DiscoveryOperations";
import { NewButton } from "../../components";
import Icon from "../../components/Icon";
import { setFullscreen } from "../../store/actions/fullscreen";
import DiscoveryOperationsConfirmation from "../../components/DiscoveryOperations/DiscoveryOperationsConfirmation";
import ExportDiscoveryMonitor from "../../components/Discovery/ExportDiscoveryMonitor";
import actionTypes from "../../store/actionTypes";
import EditCanvasHeaderModal from "./EditCanvasHeaderModal/EditCanvasHeaderModal";
import DiscoveryHeader from "../Discovery/DiscoveryHeader";
import PoweredByCuvama from "../../components/PoweredByCuvama";
import classNames from "classnames";
import CanvasLogo from "./CanvasLogo";
import DeleteOrRemoveChallengeNotification from "./DeleteOrRemoveChallengeNotification";
import DeleteOrRemoveOutcomeNotification from "./DeleteOrRemoveOutcomeNotification";
import { themeProp } from "../../theme";
import { getDiscoveryCommentCount } from "../../store/actions/comments";

const ValueMap = ({
  fetchDiscovery,
  freezeDiscoveryKPIs,
  discoveryKPIs,
  kpisEditMode,
  challengesEditMode,
  loadingState,
  discoveryName,
  setFullscreen,
  isFullscreen,
  canvasLogoPath,
  canvasTitle,
  saveDiscoveryCheckpoint,
  getDiscoveryCommentCount
}) => {
  const { discoveryId } = useParams();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [error, setError] = useState(false);
  const [ready, setReady] = useState(false);
  const [showUpdateCanvasModal, setShowUpdateCanvasModal] = useState(false);
  const [relevantKPICodes, setRelevantKPICodes] = useState(null);
  const fullScreenTooltip = s("canvas.fullScreen.tooltip", "View full screen");
  const closeFullScreenTooltip = s(
    "canvas.closeFullScreen.tooltip",
    "Exit full screen"
  );

  useEffect(() => {
    return () => {
      setFullscreen({ fullscreen: false });
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search);

    params.set("outcomes", kpisEditMode ? "true" : "false");
    params.set("challenges", challengesEditMode ? "true" : "false");
    history.replace({
      search: params.toString()
    });
  }, [kpisEditMode, challengesEditMode]);

  const OpenFullScreen = ({ onClick }) => {
    return (
      <NewButton
        type={"iconSecondary"}
        onClick={onClick}
        tooltip={fullScreenTooltip}
        data-cy={"value-map-full-screen"}
        className={"up-canvas-full-screen-open"}
      >
        <Icon name={"valueMapOpenFullScreen"} colour="gray4" size={"medium"} />
      </NewButton>
    );
  };

  const CloseFullScreen = ({ onClick }) => {
    return (
      <NewButton
        type={"iconSecondary"}
        onClick={onClick}
        data-cy={"value-map-full-screen-close"}
        tooltip={closeFullScreenTooltip}
        className={"up-canvas-full-screen-close"}
      >
        <Icon name={"closeValueMap"} colour="gray4" size={"medium"} />
      </NewButton>
    );
  };

  const pageTitle = canvasTitle
    ? canvasTitle
    : s("valueMap.page.title", "{discoveryName}: Current Situation", {
        discoveryName
      });

  useEffect(() => {
    fetchDiscovery(discoveryId);
  }, [discoveryId]);

  useEffect(() => {
    if (discoveryKPIs) {
      const list = discoveryKPIs.filter((k) => k.inScope && k.relevant);

      list.sort();
      setRelevantKPICodes(list.join(","));
    }
  }, [discoveryKPIs]);

  useEffect(() => {
    if (relevantKPICodes !== null) {
      freezeDiscoveryKPIs(discoveryId);
    }
  }, [relevantKPICodes]);

  useEffect(() => {
    getDiscoveryCommentCount({ discoveryId });
  }, [discoveryId, getDiscoveryCommentCount]);

  useLoadingState(
    loadingState,
    () => {
      saveDiscoveryCheckpoint({
        discoveryId,
        checkpoint: pathname,
        lastViewedPage: pathname + search
      });
      setError(false);
      setReady(true);
    },
    () => {
      setError(true);
    }
  );

  const onUpdateCanvasClick = () => {
    setShowUpdateCanvasModal(true);
  };

  const onClose = () => {
    setShowUpdateCanvasModal(false);
  };

  return error ? (
    <ErrorPage />
  ) : (
    ready && (
      <Wrapper className={classNames({ fullscreen: isFullscreen })}>
        {!isFullscreen && <DiscoveryHeader />}
        <Header>
          <LogoAndTitle>
            <CanvasLogo
              canvasLogoPath={canvasLogoPath}
              isFullscreen={isFullscreen}
            />
            {showUpdateCanvasModal && (
              <EditCanvasHeaderModal
                onClose={onClose}
                canvasTitle={pageTitle}
              />
            )}
            <Text variant={"h2"} color={"text"}>
              {discoveryName && pageTitle}
            </Text>
            {!isFullscreen && (
              <IconContainer>
                <NewButton
                  data-cy={"canvas-header-edit"}
                  type={"iconSecondary"}
                  onClick={onUpdateCanvasClick}
                  tooltip={"Edit"}
                >
                  <Icon name={"edit"} size={"large"} colour={"gray3"} />
                </NewButton>
              </IconContainer>
            )}
          </LogoAndTitle>
          <Space direction={"horizontal"} size={10}>
            {!isFullscreen && (
              <OpenFullScreen
                onClick={() => {
                  setFullscreen({ fullscreen: true });
                }}
              />
            )}
            {isFullscreen && (
              <CloseFullScreen
                onClick={() => {
                  setFullscreen({ fullscreen: false });
                }}
              />
            )}
          </Space>
        </Header>
        <Container>
          <ValueMapChallengeList />
          <ValueMapOutcomeList />
        </Container>
        {!isFullscreen ? <PoweredByCuvama /> : null}
        <DiscoveryOperations />
        <DiscoveryOperationsConfirmation />
        <ExportDiscoveryMonitor
          actionType={actionTypes.DISCOVERY_EXPORT_REQUEST}
        />
        <DeleteOrRemoveChallengeNotification />
        <DeleteOrRemoveOutcomeNotification />
      </Wrapper>
    )
  );
};

export const Wrapper = styled.div`
  background: ${themeProp("palette.surface")};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  min-height: 100vh;
  max-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  gap: 32px;
  min-height: calc(100vh - 80px - 16px - 42px - 32px - 50px);
  max-height: calc(100vh - 80px - 16px - 42px - 32px - 50px);
  padding: 0 32px;
  margin-top: 16px;

  & > div {
    flex: 1;
  }

  .fullscreen > & {
    min-height: calc(100vh - 24px - 42px - 24px - 24px);
    max-height: calc(100vh - 24px - 42px - 24px - 24px);
    margin-bottom: 24px;
    margin-top: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 112px;
  padding: 0 32px;
  gap: 16px;

  .fullscreen > & {
    margin-top: 32px;
  }
`;

export const LogoAndTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;

export const IconContainer = styled.div`
  margin-top: 6px;
`;

const mapStateToProps = (state) => {
  return {
    loadingState: combineLoadingStates([
      selectRequestState(state, types.FREEZE_DISCOVERY_KPIS_REQUEST),
      selectRequestState(state, types.DISCOVERY_FETCH_REQUEST)
    ]),
    discoveryName: selectDiscoveryName(state),
    discoveryKPIs: selectDiscoveryKPIs(state),
    canvasLogoPath: selectDiscoveryCanvasLogoPath(state),
    isFullscreen: isFullscreen(state),
    kpisEditMode: selectDiscoverySetting(state, "canvasKPIsEditMode", false),
    challengesEditMode: selectDiscoverySetting(
      state,
      "canvasChallengesEditMode",
      false
    ),
    canvasTitle: selectDiscoveryCanvasTitle(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDiscovery,
      freezeDiscoveryKPIs,
      setFullscreen,
      saveDiscoveryCheckpoint,
      getDiscoveryCommentCount
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(ValueMap);
