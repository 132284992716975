import { CurrencyInput } from "../../../components/Input";
import React, { useEffect, useState } from "react";
import { InputGroup, NumberInput } from "../../../components/Input";
import { CommentsButton } from "../../../components/Discovery/Comments";
import styled from "styled-components";
import { useStringTemplate } from "../../../components/StringProvider";
import { IntlMessageFormat } from "intl-messageformat";
import { UserPilotEventNames } from "../../../utils/user-pilot-util";
import { themeProp } from "../../../theme";

const ListOfCurrencyValues = ({
  length,
  currency,
  onChange,
  topic,
  value,
  startingValue,
  disabled,
  annualAdjustmentMode,
  dataCy
}) => {
  const [values, setValues] = useState([]);
  const yearTemplate = useStringTemplate(
    "discovery.roi.form.yearTemplate",
    "Year {year}"
  );

  useEffect(() => {
    if (Array.isArray(value) && !_.isEqual(value, values)) {
      setValues(value);
    }
  }, []);

  useEffect(() => {
    if (!annualAdjustmentMode) {
      return;
    }

    if (topic && topic.match(/upfrontInvestment/)) {
      setValues((currentValues) =>
        _.assign(
          _.fill(_.fill(new Array(length), startingValue || 0, 0, 1), 0, 1),
          [...currentValues]
        )
      );
      return;
    }

    setValues((currentValues) =>
      _.assign(_.fill(new Array(length), startingValue || 0), [
        ...currentValues
      ])
    );
  }, [length, startingValue, annualAdjustmentMode]);

  useEffect(() => {
    if (onChange) {
      onChange(values);
    }
  }, [values]);

  const onCellChange = (index, value) => {
    setValues((currentValues) => {
      const newValues = [...currentValues];
      newValues[index] = value;
      return newValues;
    });
  };

  return (
    <Container>
      {[...Array(length).keys()].map((index) => (
        <InputGroup
          key={index}
          title={new IntlMessageFormat(yearTemplate, "en-US").format({
            year: index + 1
          })}
          size={"medium"}
        >
          <CurrencyInput
            currency={currency}
            value={values[index]}
            onChange={(value) => onCellChange(index, value)}
            disabled={disabled}
            size={"medium"}
            data-cy={`currency-input-${dataCy}-${index + 1}`}
          />
        </InputGroup>
      ))}
      {!!topic && (
        <div className={"visible-on-hover"}>
          <CommentsButton
            topic={topic}
            mobilePlacement={"bottom"}
            eventName={UserPilotEventNames.ADD_NEW_COMMENT_ROI}
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  span {
    font-weight: ${themeProp(`typography.body.fontWeight`)} !important;
  }
`;

export default ListOfCurrencyValues;
