import {
  hasPermission,
  isAuthenticated,
  selectAddDiscoveryError,
  selectAddDiscoveryLoadingState,
  selectAddDiscoveryResult,
  selectAllDiscoveryTypes,
  selectMasterDataVersion,
  selectRequestState
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { addDiscovery } from "../../store/actions/discoveries";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { loginAsLeadGenerationUser } from "../../store/actions/authentication";
import {
  useString as s,
  useStringTemplate
} from "../../components/StringProvider";
import Page from "../../components/Page";
import { useSetting } from "../../components/SettingsProvider";
import ErrorPage, { Forbidden } from "../ErrorPage";
import Permissions from "../../utils/permissions";
import { IntlMessageFormat } from "intl-messageformat";
import useLoadingState from "../../utils/use-loading-state";
import Heading from "../../components/Heading";
import ButtonGroup from "../../components/ButtonGroup";
import NewButton from "../../components/NewButton";
import { BasicBrandHeader, SplitPageLayout } from "../../components/Layout";
import {
  DesktopOrTablet,
  Mobile,
  useMobileMediaQuery,
  useTinyMobileMediaQuery
} from "../../components/Responsive";
import { useTheme } from "../../components/ThemeProvider";
import MobilePageCentredLayout from "../../components/Layout/MobilePageCentredLayout";
import styled from "styled-components";
import Loader from "../../components/Loader";
import { ErrorNotificationBox } from "../../components/NotificationBox";
import { selectLatestVersionNumber } from "../../store/selectors/versions";
import { getMasterData } from "../../store/actions/master-data";
import { getLatestVersion } from "../../store/actions/versions";
import actionTypes from "../../store/actionTypes";

const LeadGeneration = ({
  isAuthenticated,
  addDiscoveryLoadingState,
  loginLoadingState,
  hasLeadGenerationPermission,
  newDiscovery,
  addDiscovery,
  loginAsLeadGenerationUser,
  latestVersionNumber,
  masterDataVersion,
  discoveryTypes,
  getMasterData,
  getLatestVersion,
  masterDataLoadingState,
  history
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [error, setError] = useState(false);
  const [createError, setCreateError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [masterDataLoaded, setMasterDataLoaded] = useState(false);

  const currency = useSetting("currency", "GBP");
  const defaultDiscoveryTypeCode = useSetting(
    "discovery.discoveryTypeCode",
    null
  );

  const discoveryTypeCode = params.get("discoveryTypeCode");
  const discoveryTypeMD =
    discoveryTypes && discoveryTypes.length > 0
      ? discoveryTypes[0].code
      : undefined;
  const previewDiscoveryTypeCode =
    discoveryTypeCode || defaultDiscoveryTypeCode || discoveryTypeMD;

  const isMobile = useMobileMediaQuery();
  const isTinyMobile = useTinyMobileMediaQuery();
  const coverImage = useTheme("assets.discovery_cover");

  const accountName = s("lead_generation.page.defaultCustomerName", "You");
  const opportunityName = s(
    "lead_generation.page.defaultOpportunity",
    "New lead",
    {},
    previewDiscoveryTypeCode
  );
  const descriptionTemplate = useStringTemplate(
    "lead_generation.page.descriptionTemplate",
    "Created from website at {date} and {time}",
    previewDiscoveryTypeCode
  );
  const header = s(
    "start_discovery.page.header",
    "Explore how we help companies like you",
    {},
    previewDiscoveryTypeCode
  );
  const subheader = s(
    "start_discovery.page.subheader",
    "Answer a few questions to discover the business challenges we will help you solve and align on your success outcomes",
    {},
    previewDiscoveryTypeCode
  );
  const letsStart = s(
    "start_discovery.page.button",
    "Let's start",
    {},
    previewDiscoveryTypeCode
  );
  const errorTitle = s(
    "lead_generation.popup.error.title",
    "Oops!",
    {},
    previewDiscoveryTypeCode
  );
  const errorDescription = s(
    "lead_generation.popup.error.description",
    "We can't start this discovery. Please try again later.",
    {},
    previewDiscoveryTypeCode
  );

  useLoadingState(masterDataLoadingState, () => {
    setMasterDataLoaded(true);
  });

  useLoadingState(
    loginLoadingState,
    () => {
      setError(false);
    },
    () => {
      setError(true);
    }
  );

  useLoadingState(
    addDiscoveryLoadingState,
    () => {
      setCreateError(false);
      for (const [index, question] of Object.entries(newDiscovery.questions)) {
        if (question.active) {
          history.push({
            pathname: `/discoveries/${newDiscovery["_id"]}/questions/${index}`
          });
          return;
        }
      }

      history.push({
        pathname: `/discoveries/${newDiscovery["_id"]}/select-challenges`
      });
    },
    () => {
      setCreateError(true);
    }
  );

  useEffect(() => {
    if (!isAuthenticated) {
      loginAsLeadGenerationUser();
    } else {
      getLatestVersion();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated || !latestVersionNumber) {
      return;
    }

    if (latestVersionNumber !== masterDataVersion) {
      getMasterData({ version: latestVersionNumber });
    } else {
      setMasterDataLoaded(true);
    }
  }, [isAuthenticated, latestVersionNumber, masterDataVersion]);

  useEffect(() => {
    if (!isAuthenticated || !masterDataLoaded) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, masterDataLoaded]);

  const onClick = () => {
    const date = new Date();
    const description = new IntlMessageFormat(
      descriptionTemplate,
      "en-US"
    ).format({
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString()
    });

    const parameters = {
      accountName,
      opportunityName,
      version: description,
      currency
    };

    const _discoveryTypeCode =
      discoveryTypeCode || defaultDiscoveryTypeCode || discoveryTypeMD;

    if (_discoveryTypeCode) {
      parameters["discoveryTypeCode"] = _discoveryTypeCode;
    }

    addDiscovery(parameters);
  };

  const content = (
    <Container className={isMobile ? "mobile" : undefined}>
      <Heading level={isTinyMobile ? "h3" : isMobile ? "h2" : "h1"}>
        {header}
      </Heading>
      <Heading level={isMobile ? "body" : "h3"}>{subheader}</Heading>

      <ButtonContainer className={isMobile ? "mobile" : undefined}>
        <ButtonGroup>
          <NewButton
            type={"primary"}
            onClick={onClick}
            data-cy={"lets-start-button"}
            disabled={isLoading}
          >
            {letsStart}
          </NewButton>
        </ButtonGroup>
      </ButtonContainer>
    </Container>
  );

  if (error) {
    return <ErrorPage />;
  }

  if (isAuthenticated && !hasLeadGenerationPermission) {
    return <Forbidden />;
  }

  return (
    <Page header={<BasicBrandHeader />}>
      {isLoading && (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )}
      <DesktopOrTablet>
        <SplitPageLayout image={coverImage} hasHeader={true}>
          {content}
        </SplitPageLayout>
      </DesktopOrTablet>
      <Mobile>
        <MobilePageCentredLayout hasHeader={true} image={coverImage}>
          {content}
        </MobilePageCentredLayout>
      </Mobile>
      {createError && (
        <ErrorNotificationBox
          title={errorTitle}
          description={errorDescription}
        />
      )}
    </Page>
  );
};

const ButtonContainer = styled.div`
  &.mobile {
    margin-top: 32px;
  }
`;

const Container = styled.div`
  padding-left: 80px;
  padding-top: 155px;
  display: flex;
  flex-direction: column;

  h1 {
    max-width: 568px;
  }

  h3 {
    max-width: 489px;
    margin: 16px 0 40px 0;
  }

  &.mobile {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  &.mobile h2 {
    text-align: center;
    margin-bottom: 16px;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 16px;
  }

  &.mobile body {
    text-align: center;
    margin-bottom: 16px;
  }
`;

const LoadingContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 1;
`;

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    newDiscovery: selectAddDiscoveryResult(state),
    error: selectAddDiscoveryError(state),
    addDiscoveryLoadingState: selectAddDiscoveryLoadingState(state),
    loginLoadingState: selectRequestState(state, actionTypes.LOGIN_REQUEST),
    hasLeadGenerationPermission: hasPermission(
      state,
      Permissions.SEE_LEAD_GENERATION
    ),
    discoveryTypes: selectAllDiscoveryTypes(state),
    latestVersionNumber: selectLatestVersionNumber(state),
    masterDataVersion: selectMasterDataVersion(state),
    masterDataLoadingState: selectRequestState(
      state,
      actionTypes.GET_MASTER_DATA_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginAsLeadGenerationUser,
      addDiscovery,
      getMasterData,
      getLatestVersion
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LeadGeneration);
