import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { getCurrentConfigurations } from "../../../../store/actions/configurations";
import actionTypes from "../../../../store/actionTypes";
import { useEffect, useState } from "react";
import useLoadingState from "../../../../utils/use-loading-state";
import styled from "styled-components";
import ConfigurationCard from "./ConfigurationCard";
import ConfigurationCardTypes from "./ConfigurationCardTypes";
import { isSandbox } from "../../../../store/selectors/config";
import pusherService from "../../../../services/pusher.service";
import ChannelNames from "../../../../utils/channel-names";
import httpService from "../../../../services/http.service";
import { message } from "antd";
import { useString as s } from "../../../../components/StringProvider";
import { getActivePromotion } from "../../../../store/actions/promotions";
import PusherEventNames from "../../../../utils/constants/pusher-event-names";

const CurrentConfiguration = ({
  getCurrentConfigurations,
  getActivePromotion,
  loadingState,
  configurations,
  isSandbox
}) => {
  const [ready, setReady] = useState(false);
  const tenantId = httpService.getTenantId();
  const failedToLoad = s(
    "organization.page.currentConfiguration.messages.failedToLoad",
    "Failed to load configuration"
  );

  useLoadingState(
    loadingState,
    () => {
      setReady(true);
    },
    () => {
      message.error(failedToLoad);
    }
  );

  useEffect(() => {
    pusherService.subscribe({
      channelName: ChannelNames.CURRENT_CONFIGURATION({ tenantId }),
      eventName: PusherEventNames.UPDATE,
      callback: onConfigurationUpdate
    });

    getCurrentConfigurations();
    getActivePromotion();

    return () => {
      pusherService.unsubscribe({
        channelName: ChannelNames.CURRENT_CONFIGURATION({ tenantId }),
        eventName: PusherEventNames.UPDATE,
        callback: onConfigurationUpdate
      });
    };
  }, []);

  const onConfigurationUpdate = () => {
    getCurrentConfigurations();
    getActivePromotion();
  };

  return (
    ready && (
      <Container className={isSandbox ? "double-card" : undefined}>
        <ConfigurationCard
          configuration={configurations?.current}
          cardType={ConfigurationCardTypes.SANDBOX}
        />
        {isSandbox && (
          <ConfigurationCard
            configuration={configurations?.production}
            cardType={ConfigurationCardTypes.PRODUCTION}
          />
        )}
      </Container>
    )
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > * {
    flex-grow: 1;
    align-items: stretch;
  }

  &.double-card > * {
    max-width: calc(50% - 8px);
  }
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_CURRENT_CONFIGURATIONS_REQUEST
  ),
  configurations: selectRequestData(
    state,
    actionTypes.GET_CURRENT_CONFIGURATIONS_REQUEST
  ),
  isSandbox: isSandbox(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCurrentConfigurations,
      getActivePromotion
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CurrentConfiguration
);
