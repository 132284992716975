export const Phases = {
  QUESTIONS: "Questions",
  CHALLENGES_SOLVED: "Challenges Solved",
  METRICS: "Metrics",
  KPIS_IMPACTED: "KPIs Impacted",
  VALUE_HYPOTHESIS: "Value Hypothesis",
  ROI: "Return on Investment"
};

Phases.ALL = [
  Phases.QUESTIONS,
  Phases.CHALLENGES_SOLVED,
  Phases.KPIS_IMPACTED,
  Phases.METRICS,
  Phases.VALUE_HYPOTHESIS,
  Phases.ROI
];

export const isPhaseLessThanOrEqualTo = (a, b) => {
  switch (b) {
    case Phases.QUESTIONS:
      return a === Phases.QUESTIONS;
    case Phases.CHALLENGES_SOLVED:
      return [Phases.CHALLENGES_SOLVED, Phases.QUESTIONS].includes(a);
    case Phases.KPIS_IMPACTED:
      return [
        Phases.CHALLENGES_SOLVED,
        Phases.KPIS_IMPACTED,
        Phases.QUESTIONS
      ].includes(a);
    case Phases.METRICS:
      return [
        Phases.METRICS,
        Phases.CHALLENGES_SOLVED,
        Phases.KPIS_IMPACTED,
        Phases.QUESTIONS
      ].includes(a);
    case Phases.VALUE_HYPOTHESIS:
      return [
        Phases.METRICS,
        Phases.CHALLENGES_SOLVED,
        Phases.KPIS_IMPACTED,
        Phases.QUESTIONS,
        Phases.VALUE_HYPOTHESIS
      ].includes(a);
    case Phases.ROI:
      return [
        Phases.METRICS,
        Phases.CHALLENGES_SOLVED,
        Phases.KPIS_IMPACTED,
        Phases.QUESTIONS,
        Phases.VALUE_HYPOTHESIS,
        Phases.ROI
      ].includes(a);
    default:
      return false;
  }
};
