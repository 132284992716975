import httpService from "./http.service";

const getAccounts = ({ search, start = 0, count = 50 }) =>
  httpService
    .get(
      `/crm/accounts`,
      {},
      {
        current: true,
        search,
        start,
        count
      }
    )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getOpportunities = ({ search, accountId, start = 0, count = 50 }) =>
  httpService
    .get(
      `/crm/opportunities`,
      {},
      {
        current: true,
        accountId,
        search,
        start,
        count
      }
    )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getAccounts,
  getOpportunities
};

export default methods;
