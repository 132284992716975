import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";

const INITIAL_STATE = {
  kpiCode: null,
  position: null,
  loadingState: LoadingState.UNINITIALIZED,
  error: null
};

const editKPIsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERIES_FETCH_REQUEST:
    case types.DISCOVERY_FETCH_REQUEST:
      return INITIAL_STATE;

    case types.START_EDITING_KPI:
      return {
        ...state,
        kpiCode: action.payload.kpiCode,
        position: action.payload.position,
        loadingState: LoadingState.UNINITIALIZED,
        error: null
      };

    case types.STOP_EDITING_KPI:
      return {
        ...state,
        kpiCode: null
      };

    default:
      return state;
  }
};

export default editKPIsReducer;

export const selectEditKPICode = (data) => data.kpiCode;
