import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection
} from "lexical";
import { $isLinkNode } from "@lexical/link";
import { $isParentElementRTL, $isAtNodeEnd } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import { Divider } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import Text from "../../../Text";
import BoldButton from "./ToolbarPlugin/BoldButton";
import ItalicButton from "./ToolbarPlugin/ItalicButton";
import UnderlineButton from "./ToolbarPlugin/UnderlineButton";
import OrderedListButton from "./ToolbarPlugin/OrderedListButton";
import UnorderedListButton from "./ToolbarPlugin/UnorderedListButton";
import { themeProp } from "../../../../theme";
import StrikethroughButton from "./ToolbarPlugin/StrikethroughButton";
import CodeButton from "./ToolbarPlugin/CodeButton";
import FormatDropdown from "./ToolbarPlugin/FormatDropdown";
import LeftAlignButton from "./ToolbarPlugin/LeftAlignButton";
import RightAlignButton from "./ToolbarPlugin/RightAlignButton";
import CenterAlignButton from "./ToolbarPlugin/CenterAlignButton";
import JustifyButton from "./ToolbarPlugin/JustifyButton";

const LowPriority = 1;

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

const ToolbarPlugin = ({ tools, dropdownOptions }) => {
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const renderButton = (tool, key) => {
    switch (tool) {
      case "divider":
        return <Divider type={"vertical"} key={key} />;

      case "bold":
        return (
          <BoldButton
            key={key}
            active={isBold}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
          />
        );

      case "italic":
        return (
          <ItalicButton
            key={key}
            active={isItalic}
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
            }
          />
        );

      case "underlineMarkdown":
        return (
          <UnderlineButton
            key={key}
            active={isItalic}
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
            }
          />
        );

      case "underline":
        return (
          <UnderlineButton
            key={key}
            active={isUnderline}
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
            }
          />
        );

      case "strikethrough":
        return (
          <StrikethroughButton
            key={key}
            active={isStrikethrough}
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
            }
          />
        );

      case "code":
        return (
          <CodeButton
            key={key}
            active={isCode}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")}
          />
        );

      case "left":
        return (
          <LeftAlignButton
            key={key}
            onClick={() =>
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")
            }
          />
        );

      case "right":
        return (
          <RightAlignButton
            key={key}
            onClick={() =>
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")
            }
          />
        );

      case "center":
        return (
          <CenterAlignButton
            key={key}
            onClick={() =>
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")
            }
          />
        );

      case "justify":
        return (
          <JustifyButton
            key={key}
            onClick={() =>
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")
            }
          />
        );

      case "orderedList":
        return (
          <OrderedListButton
            key={key}
            active={blockType === "ol"}
            onClick={() => {
              if (blockType !== "ol") {
                editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
              } else {
                editor.dispatchCommand(REMOVE_LIST_COMMAND);
              }
            }}
          />
        );

      case "unorderedList":
        return (
          <UnorderedListButton
            key={key}
            active={blockType === "ul"}
            onClick={() => {
              if (blockType !== "ul") {
                editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
              } else {
                editor.dispatchCommand(REMOVE_LIST_COMMAND);
              }
            }}
          />
        );

      case "dropdown":
        break;

      default:
        return <Text key={key}>{tool}</Text>;
    }

    return null;
  };

  return (
    <ToolbarContainer>
      <FormatDropdown
        blockType={blockType}
        editor={editor}
        options={dropdownOptions}
      />
      {tools.map((tool, index) => renderButton(tool, index))}
    </ToolbarContainer>
  );
};

const ToolbarContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${themeProp("palette.gray1")};

  & button {
    margin-left: 4px;
    margin-right: 4px;
  }

  & button:first-child {
    margin-left: 0;
  }
  & button:last-child {
    margin-right: 0;
  }
`;

ToolbarPlugin.propTypes = {
  tools: PropTypes.arrayOf(
    PropTypes.oneOf([
      "divider",
      "bold",
      "italic",
      "underline",
      "underlineMarkdown",
      "strikethrough",
      "code",
      "orderedList",
      "unorderedList",
      "dropdown",
      "left",
      "right",
      "center",
      "justify"
    ])
  ),
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.oneOf(["paragraph", "h1", "h2", "ul", "ol", "code", "quote"])
  )
};

ToolbarPlugin.defaultProps = {
  tools: [
    "dropdown",
    "divider",
    "bold",
    "italic",
    "underline",
    "divider",
    "orderedList",
    "unorderedList"
  ],
  dropdownOptions: ["paragraph", "h1", "h2", "ul", "ol"]
};

export default ToolbarPlugin;
