import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { useString as s } from "../../../../components/StringProvider";
import { message } from "antd";
import PropTypes from "prop-types";
import {
  selectRequestError,
  selectRequestState,
  selectRole
} from "../../../../store/reducers";
import NewButton from "../../../../components/NewButton";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import ButtonGroup from "../../../../components/ButtonGroup";
import { deleteRole, getRole } from "../../../../store/actions/roles";
import styled from "styled-components";
import { Modal } from "../../../../components";

const DeleteRoleModal = ({
  roleId,
  role,
  getRole,
  loadingState,
  error,
  onClose,
  deleteRole
}) => {
  const submitText = s(
    "accessManagement.page.roles.delete.submit",
    "Yes, delete"
  );
  const cancelText = s(
    "accessManagement.page.roles.delete.cancel",
    "Don't delete"
  );
  const errorText = s(
    "accessManagement.page.roles.deactivate.error",
    "Failed to delete Role"
  );
  const successText = s(
    "accessManagement.page.roles.deactivate.success",
    "Role was successfully deleted"
  );

  const onSubmit = () => {
    deleteRole({ roleId });
  };

  useEffect(() => {
    if (roleId) {
      getRole({ roleId });
    }
  }, [roleId]);

  const deleteWarning =
    role &&
    `This role is in use by ${role.userCount} users and will be removed from all of them if you proceed. Users without a role will not be able to access any functionality in the application.`;

  useLoadingState(
    loadingState,
    () => {
      onClose();
      message.success(successText);
    },
    () => {
      if (error) {
        message.error(errorText);
      }
    }
  );

  return (
    <Modal
      title={s("accessManagement.popup.deleteRole.header", "Delete role")}
      open={true}
      onCancel={onClose}
    >
      <WarningContainer>{deleteWarning}</WarningContainer>
      <ButtonGroup>
        <NewButton type={"submit"} onClick={onSubmit}>
          {submitText}
        </NewButton>
        <NewButton type={"secondary"} onClick={onClose}>
          {cancelText}
        </NewButton>
      </ButtonGroup>
    </Modal>
  );
};

const WarningContainer = styled.div`
  margin-bottom: 20px;
`;

DeleteRoleModal.propTypes = {
  roleId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.DELETE_ROLE_REQUEST),
  error: selectRequestError(state, actionTypes.DELETE_ROLE_REQUEST),
  role: selectRole(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteRole,
      getRole
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeleteRoleModal
);
