import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../components/SettingsProvider";
import { useString as s } from "../../../components/StringProvider";
import Card, { CardBody, CardHeader } from "../../../components/Card";
import Heading from "../../../components/Heading";
import Metric from "../../../components/Metric";
import PropTypes from "prop-types";
import styled from "styled-components";
import { formatRangeNumber } from "../../../utils/formatting";
import NewButton from "../../../components/NewButton";
import { useMobileMediaQuery } from "../../../components/Responsive";
import {
  selectDiscoveryKPIs,
  selectDiscoveryScenario
} from "../../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  sortKPIsByImportance,
  additionalBenefitsKPIFilter
} from "../../../utils/filters/kpi-filters";
import TooltipIcon from "../../../components/TooltipIcon";
import TruncatedRange from "../../../components/TruncatedRange";

const selectImpactPercentage = (scenario) => {
  switch (scenario) {
    case 0:
      return "lowImpactPercentage";
    case 50:
      return "typicalImpactPercentage";
    case 100:
      return "highImpactPercentage";
    default:
      return "typicalImpactPercentage";
  }
};

const AdditionalBenefit = ({ discoveryKPIs, scenario, printMode }) => {
  const isMobile = useMobileMediaQuery();
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const header1 = s(
    "discovery.roi.summary.additionalBenefit.header1",
    "Additional Benefits"
  );
  const header2 = s(
    "discovery.roi.summary.additionalBenefit.header2",
    "Unquantified Benefits"
  );
  const toolTipText1 = s(
    "discovery.roi.summary.additionalBenefit.tooltip",
    "The benefit from these outcomes has not been financially quantified and therefore is not directly included in the evaluation."
  );

  const toolTipText2 = s(
    "discovery.roi.summary.unquantifiedBenefit.tooltip",
    "Benefits that may be ‘soft’ or ‘strategic’ in nature."
  );
  const notSpecifiedText = s("discovery.roi.notSpecified", "Soft benefit");
  const showAllBenefitsButton = s(
    "report.roi.showAllBenefits",
    "Show all benefits"
  );
  const hideBenefitsButton = s("report.roi.hideBenefits", "Hide benefits");

  const maxBenefitCount = 4;

  const [showAll, setShowAll] = useState(false);
  const allKPIs = additionalBenefitsKPIFilter(discoveryKPIs);

  sortKPIsByImportance({ kpis: allKPIs });

  const benefits = allKPIs.map((benefit) => ({
    ...benefit,
    typicalImpactPercentage:
      (benefit.lowImpactPercentage + benefit.highImpactPercentage) / 2
  }));

  const additionalBenefits = benefits?.filter(
    ({ hasImpactPercentage }) => hasImpactPercentage
  );

  const unquantifiedBenefits = benefits?.filter(
    ({ hasImpactPercentage }) => !hasImpactPercentage
  );

  const additionalBenefitsOnMobile = showAll
    ? additionalBenefits.length
    : maxBenefitCount;

  const unquantifiedBenefitsOnMobile = showAll
    ? unquantifiedBenefits.length
    : maxBenefitCount;

  const onToggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (!benefits || benefits.length === 0) {
    return null;
  }

  const renderMetric = (benefit) => {
    const text = formatRangeNumber({
      value: benefit[selectImpactPercentage(scenario)],
      type: benefit.definition.impactFormat,
      options: {
        locale,
        showUnits: true,
        customImpactFormat: benefit.definition.customImpactFormat
      }
    });

    return <TruncatedRange fullText={text}>{text}</TruncatedRange>;
  };

  return (
    <Card data-cy={"additional-benefits-box"}>
      {additionalBenefits.length > 0 && (
        <>
          <CardHeader>
            <Heading
              data-cy={"additional-benefits-box-header1"}
              level="h5"
              bold
            >
              {header1}{" "}
              <TooltipIcon
                dataCy={"additional-benefits-box-header1-tooltip-icon"}
                title={toolTipText1}
              />
            </Heading>
          </CardHeader>
          <AdditionalBenefitBody>
            {additionalBenefits
              .slice(
                0,
                isMobile
                  ? additionalBenefitsOnMobile
                  : additionalBenefits.length
              )
              .map((benefit) => (
                <MetricTile
                  key={benefit.kpiCode}
                  className={isMobile ? "mobile" : undefined}
                >
                  <Metric label={benefit.definition.name} printMode={printMode}>
                    {benefit.hasImpactPercentage
                      ? renderMetric(benefit)
                      : notSpecifiedText}
                  </Metric>
                </MetricTile>
              ))}
          </AdditionalBenefitBody>
        </>
      )}
      {unquantifiedBenefits.length > 0 && (
        <>
          <CardHeader>
            <Heading
              data-cy={"additional-benefits-box-header2"}
              level="h5"
              bold
            >
              {header2}{" "}
              <TooltipIcon
                dataCy={"additional-benefits-box-header2-tooltip-icon"}
                title={toolTipText2}
              />
            </Heading>
          </CardHeader>
          <AdditionalBenefitBody>
            {unquantifiedBenefits
              .slice(
                0,
                isMobile
                  ? unquantifiedBenefitsOnMobile
                  : unquantifiedBenefits.length
              )
              .map((benefit) => (
                <MetricTile
                  key={benefit.kpiCode}
                  className={isMobile ? "mobile" : undefined}
                >
                  <Metric
                    label={benefit.definition.name}
                    printMode={printMode}
                  />
                </MetricTile>
              ))}
          </AdditionalBenefitBody>
        </>
      )}
      {isMobile &&
      (unquantifiedBenefits.length > maxBenefitCount ||
        additionalBenefits.length > maxBenefitCount) ? (
        <ButtonContainer>
          <NewButton type={"text"} onClick={onToggleShowAll}>
            {showAll ? hideBenefitsButton : showAllBenefitsButton}
          </NewButton>
        </ButtonContainer>
      ) : null}
    </Card>
  );
};

const AdditionalBenefitBody = styled(CardBody)`
  display: flex;
  flex-wrap: wrap;
`;

const MetricTile = styled.div`
  flex: 0 0 33%;
  padding: 8px;
  text-align: center;

  & h4 {
    text-align: center;
  }

  &.mobile {
    flex: 0 0 50%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

AdditionalBenefit.propTypes = {
  printMode: PropTypes.bool
};

const mapStateToProps = (state) => ({
  scenario: selectDiscoveryScenario(state),
  discoveryKPIs: selectDiscoveryKPIs(state)
});

export default compose(withRouter, connect(mapStateToProps))(AdditionalBenefit);
