import styled from "styled-components";
import { Menu as ANTMenu } from "antd";
import { themeProp } from "../theme";

const Menu = styled(ANTMenu)`
  min-width: 200px;
`;

export const MenuItem = styled(Menu.Item)`
  line-height: ${(props) => themeProp(`typography.body.lineHeight`)(props)};

  span {
    font-size: ${(props) => themeProp(`typography.body.fontSize`)(props)};
    font-family: ${(props) => themeProp(`typography.body.fontFamily`)(props)};
    font-weight: ${(props) => themeProp(`typography.body.fontWeight`)(props)};
    line-height: inherit;
    color: ${(props) => themeProp(`palette.black`)(props)};
    vertical-align: middle;
  }

  &.ant-dropdown-menu-item-active span {
    color: ${themeProp(`palette.secondary`)};
  }

  &.ant-dropdown-menu-item span {
    display: flex;
    align-items: center;
  }

  &.ant-dropdown-menu-item svg {
    line-height: normal;
    margin-right: 8px;
    height: 16px;
    width: auto;
  }

  &.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-active) svg {
    color: ${(props) => themeProp(`palette.gray3`)(props)};
  }

  &.ant-dropdown-menu-item-active svg {
    color: ${(props) => themeProp(`palette.black`)(props)};
  }
`;

export default Menu;
