import styled from "styled-components";
import NotificationCard from "./NotificationCard";
import React, { useEffect } from "react";
import _ from "lodash";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { markNotificationsAsRead } from "../../store/actions/notifications";

const isInView = (element) => {
  const container = element.parentElement;
  const scrollTop = container.scrollTop;
  const scrollBottom = scrollTop + container.clientHeight;
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const rect = {
    top: elementRect.top - containerRect.top,
    right: elementRect.right - containerRect.right,
    bottom: elementRect.bottom - containerRect.bottom,
    left: elementRect.left - containerRect.left
  };

  const elementTop = rect.top + scrollTop;
  const elementBottom = elementTop + element.offsetHeight;
  const visibleTop = elementTop < scrollTop ? scrollTop : elementTop;
  const visibleBottom =
    elementBottom > scrollBottom ? scrollBottom : elementBottom;

  return (
    element.clientHeight !== 0 &&
    visibleBottom - visibleTop >= element.clientHeight / 2
  );
};

const NotificationsList = ({
  notificationIds,
  visible,
  markNotificationsAsRead
}) => {
  const handleScroll = _.throttle(() => {
    const container = document.getElementById("notifications-list");
    const visibleNotificationIds = [];

    for (const element of container.children) {
      if (element && isInView(element)) {
        const matches = element.id.match(/^notification-(.*)$/);

        if (matches) {
          visibleNotificationIds.push(matches[1]);
        }
      }
    }

    if (visibleNotificationIds.length) {
      markNotificationsAsRead({ notificationIds: visibleNotificationIds });
    }
  }, 1000);

  useEffect(() => {
    if (visible && notificationIds.length) {
      handleScroll();
    }
  }, [visible, notificationIds, handleScroll]);

  useEffect(() => {
    const container = document.getElementById("notifications-list");
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <Container id={"notifications-list"}>
      {notificationIds.map((notificationId, index) => (
        <NotificationCard notificationId={notificationId} key={index} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  max-height: 380px;
  overflow: auto;
`;

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      markNotificationsAsRead
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotificationsList
);
