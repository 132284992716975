import { useString as s } from "../../../../../components/StringProvider";
import { ReactComponent as Icon } from "../../../../../assets/editor/list-ol.svg";
import ToolbarButton from "./ToolbarButton";

const OrderedListButton = ({ onClick, active }) => {
  return (
    <ToolbarButton
      active={active}
      onClick={onClick}
      label={s("editor.buttons.orderedList.label", "Format Numbered List")}
    >
      <Icon />
    </ToolbarButton>
  );
};

export default OrderedListButton;
