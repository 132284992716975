import Header, { BrandBadge } from "../../components/Header";
import React, { useEffect, useState } from "react";
import { useMobileMediaQuery } from "../Responsive";
import Link from "../Link";
import Icon from "../Icon";
import { useString as s } from "../StringProvider";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { hasPermission, selectDiscovery } from "../../store/reducers";
import Permissions from "../../utils/permissions";
import styled from "styled-components";
import Tooltip from "../Tooltip";

const BasicBrandHeader = ({
  discovery,
  canSeeMyDiscoveries,
  canSeeLeadGeneration,
  showClose = false
}) => {
  const isMobile = useMobileMediaQuery();
  const [saveAndCloseLink, setSaveAndCloseLink] = useState("/");

  useEffect(() => {
    if (!canSeeMyDiscoveries && discovery) {
      setSaveAndCloseLink(`/invitations/${discovery.invitationCode}`);
    }
  }, [discovery, canSeeMyDiscoveries]);

  const saveAndCloseTooltip = s(
    "discovery.page.header.save.tooltip",
    "Close Discovery"
  );

  return (
    <Header className={isMobile ? "mobile" : undefined}>
      <BrandBadge
        linkTo={!canSeeLeadGeneration ? "/" : undefined}
        className={isMobile ? "mobile" : undefined}
      />
      {showClose && (
        <CloseContainer>
          <Tooltip title={saveAndCloseTooltip} placement={"left"}>
            <Link to={saveAndCloseLink} data-cy={"save-and-close-link"}>
              <Icon name="close" colour="gray4" />
            </Link>
          </Tooltip>
        </CloseContainer>
      )}
    </Header>
  );
};

const CloseContainer = styled.div`
  margin-top: 18px;
`;

const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  canSeeMyDiscoveries: hasPermission(state, Permissions.SEE_MY_DISCOVERIES),
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION)
});

export default compose(withRouter, connect(mapStateToProps))(BasicBrandHeader);
