import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  dismissDiscoveryKPIInfoBox,
  resetDiscoveryKPIInfoBox,
  saveDiscoveryKPI,
  updateDiscoverySettings
} from "../../store/actions/discoveries";
import { DiscoveryFooter, DiscoveryTitleBar } from "../../components/Discovery";
import { useString as s } from "../../components/StringProvider";
import InfoBox from "../../components/InfoBox";
import Text from "../../components/Text";
import {
  hasPermission,
  isDiscoveryRegisterInterestComplete,
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryName,
  selectDiscoverySetting,
  selectKPILibraryOpen,
  selectRequestState
} from "../../store/reducers";
import Heading from "../../components/Heading";
import NewButton from "../../components/NewButton";
import { Dropdown, message, Space } from "antd";
import Permissions from "../../utils/permissions";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import KPIDifferenceNotification from "./KPIDifferenceNotification";
import Toggle from "../../components/Toggle";
import TextBody from "../../components/TextBody";
import ShowCardOptions from "../../utils/show-card-options";
import ViewKPIsBy from "../../utils/view-kpis-by";
import Icon from "../../components/Icon";
import Menu, { MenuItem } from "../../components/Menu";
import ViewKPIsByGroup from "./ViewKPIsByGroup";
import ViewKPIsByImportance from "./ViewKPIsByImportance";
import {
  GoToBusinessMetricsButton,
  GoToKPISummaryButton
} from "../../components/DiscoveryButtons";
import { useMobileMediaQuery } from "../../components/Responsive";
import DeleteKPIModal from "../../components/Discovery/KPICard/DeleteKPIModal";
import { KPILibrary } from "../../components/Library";
import useLoadingState from "../../utils/use-loading-state";
import actionTypes from "../../store/actionTypes";
import DiscoverySubHeader from "../Discovery/DiscoverySubHeader";
import { Phases } from "../../utils/phases";
const kpiLibraryWidth = 378;

const SelectKPIs = ({
  discoveryId,
  discoveryName,
  showKPIInfoBox,
  dismissDiscoveryKPIInfoBox,
  showKPIs,
  viewBy,
  updateDiscoverySettings,
  kpiLibraryOpen,
  canSeeKPILibrary,
  canSeeLeadGeneration,
  isRegistered,
  canSeeSelectKPIs,
  canSeeValueHypothesis,
  canSeeKPISummary,
  resetDiscoveryKPIInfoBox,
  saveLoadingState
}) => {
  const isMobile = useMobileMediaQuery();
  const showSelectedOnlyText = s(
    "discovery.kpi.summary.page.showSelectedOnly",
    "Show selected only"
  );
  const group = s("discovery.kpi.page.viewBy.group", "Group");
  const importance = s("discovery.kpi.page.viewBy.importance", "Importance");
  const link1Text = s(
    "discovery.kpi.page.navigation.subheader.link1",
    "Edit Outcomes"
  );

  const infoBoxText = s(
    "discovery.kpi.page.infobox",
    "The default impact is based on our experience. Please personalize the outcomes to match your business."
  );
  const failedToSave = s("editKPI.popup.error.text", "Failed to save outcome");
  const link2Text = s(
    "discovery.kpi.page.navigation.subheader.link2",
    "Summary"
  );
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [currentViewBy, setCurrentViewBy] = useState(ViewKPIsBy.GROUP);

  useEffect(() => {
    const isInfoBoxTextPopulated = infoBoxText && infoBoxText.trim() !== "";
    resetDiscoveryKPIInfoBox({ showKPIInfoBox: isInfoBoxTextPopulated });
  }, []);

  useEffect(() => {
    setShowSelectedOnly(showKPIs === ShowCardOptions.SELECTED_CARDS);
  }, [showKPIs]);

  useEffect(() => {
    setCurrentViewBy(viewBy);
  }, [viewBy]);

  useLoadingState(
    saveLoadingState,
    () => {},
    () => {
      message.error(failedToSave);
    }
  );

  const onToggleShowSelected = () => {
    setShowSelectedOnly(!showSelectedOnly);
    updateDiscoverySettings({
      discoveryId,
      changes: {
        showKPIs:
          showKPIs === ShowCardOptions.SELECTED_CARDS
            ? ShowCardOptions.ALL_CARDS
            : ShowCardOptions.SELECTED_CARDS
      }
    });
  };

  const clickViewBy = ({ key }) => {
    setCurrentViewBy(key);
    updateDiscoverySettings({
      discoveryId,
      changes: {
        viewKPIsBy: key
      }
    });
  };
  const renderInfobox = () =>
    showKPIInfoBox ? (
      <StyledInfoBox onClose={() => dismissDiscoveryKPIInfoBox()}>
        <Text variant={isMobile ? "bodyMobile" : "body"}>{infoBoxText}</Text>
      </StyledInfoBox>
    ) : null;

  const viewByMenu = (
    <Menu onClick={clickViewBy} data-cy={"view-by-menu"}>
      {ViewKPIsBy.ALL.map((item) => {
        return (
          <MenuItem key={item}>
            <span>{item === ViewKPIsBy.GROUP ? group : importance}</span>
          </MenuItem>
        );
      })}
    </Menu>
  );

  let linkConfig = [
    {
      to: `/discoveries/${discoveryId}/select-kpis`,
      label: link1Text,
      visible: true
    },
    {
      to: `/discoveries/${discoveryId}/kpi-summary`,
      label: link2Text,
      visible: canSeeKPISummary
    }
  ];

  if (canSeeLeadGeneration && canSeeSelectKPIs && !canSeeValueHypothesis) {
    linkConfig[1].conditionToEnableLink = canSeeLeadGeneration && isRegistered;
  }

  const filteredLinkConfig = linkConfig.filter((link) => link.visible);

  return (
    <KpiLibraryMargin isMobile={isMobile} kpiLibraryOpen={kpiLibraryOpen}>
      {filteredLinkConfig.length > 1 ? (
        <DiscoverySubHeader
          rocketShipEditable={true}
          phase={Phases.KPIS_IMPACTED}
          linkConfig={filteredLinkConfig}
        />
      ) : null}
      <DiscoveryContentWrapper
        isMobile={isMobile}
        kpiLibraryOpen={kpiLibraryOpen}
        kpiLibraryVisible={canSeeKPILibrary}
      >
        <Header className={isMobile ? "mobile" : undefined}>
          <DiscoveryTitleBar>
            <Heading
              level={isMobile ? "h3" : "h2"}
              data-cy={"discovery-kpi-page-header"}
            >
              {s(
                "discovery.kpi.page.header",
                "Success Outcomes for {discoveryName}",
                {
                  discoveryName
                }
              )}
            </Heading>
          </DiscoveryTitleBar>
          <Text variant={isMobile ? "bodyMobile" : "body"}>
            {s(
              "discovery.kpi.page.subheader",
              "Please review and prioritize your most important outcomes"
            )}
          </Text>
          {renderInfobox()}
        </Header>
        <ViewContainer>
          {!canSeeLeadGeneration && (
            <GroupByContainer>
              <Heading level={"h5"} color={"gray4"}>
                {s("discovery.kpi.page.sort.text", "View by")}
              </Heading>
              <Dropdown overlay={viewByMenu} trigger="click">
                <NewButton
                  type={"text"}
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                  data-cy={"view-by-button"}
                >
                  {viewBy === ViewKPIsBy.GROUP ? group : importance}
                  <Icon name={"angleDown"} />
                </NewButton>
              </Dropdown>
            </GroupByContainer>
          )}
          {!canSeeLeadGeneration && (
            <Space>
              <Toggle
                data-cy={"show-selected-kpis-toggle"}
                checked={showSelectedOnly}
                onChange={onToggleShowSelected}
              />{" "}
              <TextBody>{showSelectedOnlyText}</TextBody>
            </Space>
          )}
        </ViewContainer>
        {currentViewBy === ViewKPIsBy.GROUP ? (
          <ViewKPIsByGroup />
        ) : (
          <ViewKPIsByImportance />
        )}
        <DeleteKPIModal />
        <DiscoveryFooter>
          <GoToBusinessMetricsButton />
          <GoToKPISummaryButton />
        </DiscoveryFooter>
      </DiscoveryContentWrapper>
      <KPIDifferenceNotification />
      {canSeeKPILibrary && <KPILibrary />}
    </KpiLibraryMargin>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;

  &.mobile {
    text-align: center;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 0;
  }
`;

const KpiLibraryMargin = styled.div`
  margin-right: ${({ isMobile, kpiLibraryOpen }) => {
    if (!isMobile && kpiLibraryOpen) {
      return kpiLibraryWidth + "px";
    }

    return 0;
  }};
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 25px;
  margin-bottom: 10px;
`;

const GroupByContainer = styled.div`
  display: flex;
  align-items: baseline;

  h5 {
    margin-right: 14px;
  }
`;

const StyledInfoBox = styled(InfoBox)`
  margin-top: 16px;
`;

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  currency: selectDiscoveryCurrency(state),
  discoveryName: selectDiscoveryName(state),
  showKPIInfoBox: state.discoveryData.showKPIInfoBox,
  showKPIs: selectDiscoverySetting(state, "showKPIs", "all"),
  viewBy: selectDiscoverySetting(state, "viewKPIsBy", ViewKPIsBy.GROUP),
  canSeeKPILibrary: hasPermission(state, Permissions.SEE_KPI_LIBRARY),
  kpiLibraryOpen: selectKPILibraryOpen(state),
  isRegistered: isDiscoveryRegisterInterestComplete(state),
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  canSeeSelectKPIs: hasPermission(state, Permissions.SEE_SELECT_KPIS),
  canSeeValueHypothesis: hasPermission(state, Permissions.SEE_VALUE_HYPOTHESIS),
  canSeeKPISummary: hasPermission(state, Permissions.SEE_KPI_SUMMARY),
  saveLoadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_SAVE_KPI_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryKPI,
      dismissDiscoveryKPIInfoBox,
      updateDiscoverySettings,
      resetDiscoveryKPIInfoBox
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectKPIs
);
