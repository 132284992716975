import { useString as s } from "../../StringProvider";
import Form, { FormItem } from "../../Form";
import Input, { EmailInput, PhoneInput } from "../../Input";
import Text from "../../Text";
import { Space } from "antd";
import NewButton from "../../NewButton";
import Checkbox from "../../Checkbox";
import PrivacyText from "./PrivacyText";
import { useSetting, SettingsContext } from "../../SettingsProvider";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { SelectCountry } from "../../../components";
import isBlockedDomain from "../../../utils/is-blocked-domain";

const RegisterInterestForm = ({ onFinish }) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);

  const { settings } = useContext(SettingsContext);

  const isPhoneNumberEnabled = useSetting(
    "discovery.prospectLed.phoneNumber.enabled",
    false
  );
  const isPhoneNumberMandatory = useSetting(
    "discovery.prospectLed.phoneNumber.mandatory",
    false
  );
  const phoneNumberCountryCode = useSetting(
    "discovery.prospectLed.phoneNumber.countryCode",
    undefined
  );
  const phoneNumberCountryCodes = useSetting(
    "discovery.prospectLed.phoneNumber.countryCodes",
    undefined
  );
  const isCountryEnabled = useSetting(
    "discovery.prospectLed.country.enabled",
    false
  );
  const isCountryMandatory = useSetting(
    "discovery.prospectLed.country.mandatory",
    false
  );
  const defaultCountryCode = useSetting(
    "discovery.prospectLed.country.defaultCountryCode",
    undefined
  );
  const countryCountryCodes = useSetting(
    "discovery.prospectLed.country.countryCodes",
    undefined
  );
  const countryPreferredCountryCodes = useSetting(
    "discovery.prospectLed.country.preferredCountryCodes",
    undefined
  );
  const okText = s("register_interest.popup.okButton", "Continue");
  const firstNameLabel = s(
    "register_interest.popup.firstName.label",
    "What’s your first name?"
  );
  const firstNameValidation = s(
    "register_interest.popup.firstName.validation",
    "Please enter your first name"
  );
  const lastNameLabel = s(
    "register_interest.popup.lastName.label",
    "What’s your last name?"
  );
  const lastNameValidation = s(
    "register_interest.popup.lastName.validation",
    "Please enter your last name"
  );
  const countryLabelOptional = s(
    "register_interest.popup.countryOptional.label",
    "What’s your country (optional)?"
  );
  const countryLabelMandatory = s(
    "register_interest.popup.countryMandatory.label",
    "What’s your country?"
  );
  const countryValidation = s(
    "register_interest.popup.country.validation",
    "Please enter your country"
  );
  const customerNameLabel = s(
    "register_interest.popup.customerName.label",
    "What’s your company?"
  );
  const customerNameValidation = s(
    "register_interest.popup.customerName.validation",
    "Please enter your company name"
  );
  const emailLabel = s(
    "register_interest.popup.email.label",
    "What’s your company email?"
  );
  const emailValidation = s(
    "register_interest.popup.email.validation",
    "Please enter a valid business email address"
  );
  const privacyValidation = s(
    "register_interest.popup.privacy.validation",
    "You must agree to the privacy policy"
  );
  const contactLabel = s(
    "register_interest.popup.contact.label",
    "I am happy for {companyName} to contact me"
  );
  const smallText = s(
    "register_interest.popup.smallText",
    "We will use your details to send you the discovery report and allow you to edit and share access"
  );
  const phoneLabel = s(
    "register_interest.popup.phone.label",
    "What’s your phone number?"
  );
  const phoneValidation = s(
    "register_interest.popup.phone.validation",
    "Please enter your phone number"
  );
  const phoneFormatValidation = s(
    "register_interest.popup.phone.formatValidation",
    "Please make sure your phone number is correct"
  );

  const mandatoryFields = [
    "firstName",
    "lastName",
    "customerName",
    "email",
    "privacyPolicy"
  ];

  if (isPhoneNumberEnabled && isPhoneNumberMandatory) {
    mandatoryFields.push("phoneNumber");
  }

  if (isCountryEnabled) {
    if (isCountryMandatory) {
      mandatoryFields.push("country");
    }
  }

  useEffect(() => {
    if (defaultCountryCode) {
      form.setFieldValue("country", defaultCountryCode);
    }
  }, [defaultCountryCode]);

  const onValuesChange = () => {
    const someErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length);

    const isFieldsFilled = mandatoryFields.every((field) =>
      form.getFieldValue(field)
    );

    setDisabled(!isFieldsFilled || someErrors);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFieldsChange={onValuesChange}
      name={"search"}
    >
      <FormItem
        label={firstNameLabel}
        name="firstName"
        rules={[
          {
            required: true,
            message: firstNameValidation,
            whitespace: true
          }
        ]}
      >
        <Input block />
      </FormItem>
      <FormItem
        label={lastNameLabel}
        name="lastName"
        rules={[
          {
            required: true,
            message: lastNameValidation,
            whitespace: true
          }
        ]}
      >
        <Input block />
      </FormItem>
      <FormItem
        label={customerNameLabel}
        name="customerName"
        rules={[
          {
            required: true,
            message: customerNameValidation,
            whitespace: true
          }
        ]}
      >
        <Input block />
      </FormItem>
      <FormItem
        label={emailLabel}
        name="email"
        rules={[
          {
            required: true,
            message: emailValidation,
            whitespace: true,
            type: "email"
          },
          () => ({
            validator(_, value) {
              if (
                isBlockedDomain({
                  email: value,
                  blockedDomains: settings?.blockedDomains || []
                })
              ) {
                return Promise.reject(new Error(emailValidation));
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <EmailInput autoComplete={"false"} />
      </FormItem>
      {isCountryEnabled && (
        <FormItem
          label={
            isCountryMandatory ? countryLabelMandatory : countryLabelOptional
          }
          name="country"
          rules={[
            {
              required: isCountryMandatory,
              message: countryValidation,
              whitespace: true
            }
          ]}
        >
          <SelectCountry
            countryCodes={countryCountryCodes}
            preferredCountryCodes={countryPreferredCountryCodes}
            popupMatchSelectWidth={true}
          />
        </FormItem>
      )}
      {isPhoneNumberEnabled && (
        <FormItem
          label={phoneLabel}
          name="phoneNumber"
          rules={[
            {
              required: isPhoneNumberMandatory,
              message: phoneValidation,
              whitespace: true
            },
            () => ({
              validator(_, value) {
                if (value && !isValidPhoneNumber(value)) {
                  return Promise.reject(new Error(phoneFormatValidation));
                }

                return Promise.resolve();
              }
            })
          ]}
        >
          <PhoneInput
            defaultCountry={phoneNumberCountryCode}
            countryOptionsOrder={
              phoneNumberCountryCodes
                ? [...phoneNumberCountryCodes, "|"]
                : undefined
            }
            addInternationalOption={!phoneNumberCountryCodes}
          />
        </FormItem>
      )}
      <div style={{ marginBottom: "16px" }}>
        <Text variant={"small"}>{smallText}</Text>
      </div>
      <FormItem>
        <Space size={16} direction={"horizontal"}>
          <NewButton
            type={"submit"}
            disabled={disabled ? true : undefined}
            data-cy={"register-interest-form-button"}
          >
            {okText}
          </NewButton>
        </Space>
      </FormItem>
      <FormItem
        name="privacyPolicy"
        valuePropName={"checked"}
        className={"checkbox"}
        rules={[
          {
            type: "boolean"
          },
          () => ({
            validator(_, value) {
              if (!value) {
                return Promise.reject(new Error(privacyValidation));
              }

              return Promise.resolve();
            }
          })
        ]}
      >
        <Checkbox>
          <PrivacyText />
        </Checkbox>
      </FormItem>
      <FormItem
        name="contactMe"
        valuePropName={"checked"}
        className={"checkbox"}
        rules={[
          {
            type: "boolean"
          }
        ]}
      >
        <Checkbox>{contactLabel}</Checkbox>
      </FormItem>
    </Form>
  );
};

RegisterInterestForm.propTypes = {
  onFinish: PropTypes.func.isRequired
};

export default RegisterInterestForm;
