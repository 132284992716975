import { isEmpty } from "lodash";

export const isMissingAnswerForCompulsorySelection = ({
  discoveryQuestion,
  questionMD
}) => {
  return (
    (isEmpty(discoveryQuestion.answer) &&
      questionMD?.visualizationOptions?.compulsorySelection) ||
    false
  );
};

const allMandatoryQuestionsAnsweredLocal = ({
  discovery,
  questions,
  currentQuestion,
  isQuestionFormDisabled
}) => {
  let isAllMandatoryQuestionsAnswered = true;
  const discoveryQuestions = discovery.questions.filter(
    (discoveryQuestion) => discoveryQuestion.active
  );

  for (const discoveryQuestion of discoveryQuestions) {
    const question = questions.find(
      (question) => question.code === discoveryQuestion.questionCode
    );

    const missingAnswerForCompulsorySelection =
      isMissingAnswerForCompulsorySelection({ discoveryQuestion, question });

    if (missingAnswerForCompulsorySelection) {
      if (
        currentQuestion &&
        currentQuestion.code === discoveryQuestion.questionCode
      ) {
        if (isQuestionFormDisabled) {
          isAllMandatoryQuestionsAnswered = false;
        }
      } else {
        isAllMandatoryQuestionsAnswered = false;
      }
    }
  }
  return isAllMandatoryQuestionsAnswered;
};

export default allMandatoryQuestionsAnsweredLocal;
