import React from "react";
import { useString as s } from "../../../../components/StringProvider";
import NewButton from "../../../../components/NewButton";
import { Space } from "antd";
import { hasPermission } from "../../../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Permissions from "../../../../utils/permissions";
import styled from "styled-components";
import SearchBar from "../../../../components/SearchBar";

const RolesSearchBar = ({ canSeeNewRoleButton, onSearch, history }) => {
  const onNewRole = () => {
    history.push(`/organizations/access-management/roles/create`);
  };

  const searchPlaceholderText = s(
    "accessManagement.page.roles.search.placeholder",
    "Search for a role"
  );

  const newUser = s("accessManagement.page.roles.newrole.button", "Add Role");

  return (
    <Header>
      <HeaderLeftSection>
        <SearchBar
          placeholder={searchPlaceholderText}
          onSearch={onSearch}
          data-cy={"search-user"}
        />
      </HeaderLeftSection>
      <HeaderRightSection>
        <Space size={20}>
          {canSeeNewRoleButton ? (
            <NewButton
              type={"primary"}
              onClick={onNewRole}
              data-cy={"new-user-button"}
            >
              {newUser}
            </NewButton>
          ) : null}
        </Space>
      </HeaderRightSection>
    </Header>
  );
};

export const HeaderRightSection = styled(Space)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  padding-top: 0;
  align-self: flex-end;
`;

export const HeaderLeftSection = styled(Space)`
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
`;

const Header = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  & .ant-input-search {
    width: 400px;
  }
`;

const mapStateToProps = (state) => {
  return {
    canSeeNewRoleButton: hasPermission(
      state,
      Permissions.EDIT_ACCESS_MANAGEMENT
    )
  };
};

export default compose(withRouter, connect(mapStateToProps))(RolesSearchBar);
