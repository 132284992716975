import types from "../actionTypes";
import validationsService from "../../services/validations.service";

export const createValidation =
  ({ fileId, updateType, description, workflowType }) =>
  (dispatch) => {
    dispatch({
      type: types.CREATE_VALIDATION_REQUEST,
      parameters: { fileId, updateType, description, workflowType }
    });

    return validationsService
      .createValidation({
        fileId,
        updateType,
        description,
        workflowType
      })
      .then((payload) => {
        dispatch({
          type: types.CREATE_VALIDATION_SUCCESS,
          payload,
          parameters: { fileId, updateType, description, workflowType }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_VALIDATION_FAILURE,
          error,
          parameters: { fileId, updateType, description, workflowType }
        });
      });
  };

export const exportValidation =
  ({ validationId, type }) =>
  (dispatch) => {
    dispatch({
      type: types.EXPORT_VALIDATION_REQUEST
    });

    return validationsService
      .exportValidation({
        validationId,
        type
      })
      .then((payload) => {
        dispatch({
          type: types.EXPORT_VALIDATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.EXPORT_VALIDATION_FAILURE,
          error
        });
      });
  };

export const deployValidation =
  ({ validationId }) =>
  (dispatch) => {
    dispatch({
      type: types.DEPLOY_VALIDATION_REQUEST,
      parameters: {
        validationId
      }
    });

    return validationsService
      .deployValidation({
        validationId
      })
      .then((payload) => {
        dispatch({
          type: types.DEPLOY_VALIDATION_SUCCESS,
          payload,
          parameters: {
            validationId
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DEPLOY_VALIDATION_FAILURE,
          error,
          parameters: {
            validationId
          }
        });
      });
  };
