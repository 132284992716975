import { hasSandbox, isSandbox } from "../../../../store/selectors/config";
import { bindActionCreators, compose } from "redux";
import { getCurrentConfigurations } from "../../../../store/actions/configurations";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import ConfigurationNotesTemplate from "./ConfigurationNotesTemplate";
import ConfigurationCardTypes from "./ConfigurationCardTypes";
import ConfigurationPowerpointTemplates from "./ConfigurationPowerpointTemplates";
import ConfigurationMasterData from "./ConfigurationMasterData";
import { Divider } from "antd";
import PromotionButtons from "./PromotionButtons";

const ConfigurationCard = ({ configuration, cardType, isSandbox }) => {
  return (
    <Container>
      <ConfigurationMasterData
        masterData={configuration?.masterData}
        cardType={cardType}
      />
      <ConfigurationPowerpointTemplates
        powerpointTemplates={configuration?.powerpointTemplates || []}
        cardType={cardType}
      />
      <ConfigurationNotesTemplate
        notesTemplate={configuration?.notesTemplate}
        cardType={cardType}
      />
      {isSandbox && cardType === ConfigurationCardTypes.SANDBOX && (
        <>
          <Divider />
          <PromotionButtons />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${themeProp("palette.surface")};
  border: 1px solid ${themeProp("palette.gray1")};
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;

  & .ant-divider-horizontal {
    margin: 0;
  }
`;

const mapStateToProps = (state) => ({
  hasSandbox: hasSandbox(state),
  isSandbox: isSandbox(state)
});

ConfigurationCard.propTypes = {
  configuration: PropTypes.object,
  cardType: PropTypes.oneOf(ConfigurationCardTypes.ALL)
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCurrentConfigurations
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfigurationCard);
