import React from "react";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import Heading from "../../Heading";
import { Paragraph } from "../../Typography";
import { Userpilot } from "userpilot";
import { AI_FEEDBACK_SURVEY } from "../../../utils/user-pilot-util";
import NewButton from "../../NewButton";
import { themeProp } from "../../../theme";

const ChatGuide = () => {
  const triggerUserpilotFeedbackSurvey = () => {
    Userpilot.trigger(AI_FEEDBACK_SURVEY);
  };

  const content = {
    title: s("aiDiscoveryModal.popup.chatGuide.title", "How to use Cuvama AI"),
    subTitle1: s(
      "aiDiscoveryModal.popup.chatGuide.subTitle1",
      "Welcome to the Cuvama AI assistant!"
    ),
    subTitle2: s(
      "aiDiscoveryModal.popup.chatGuide.subTitle2",
      "To use this feature, pick the types of data from the left panel you want the Cuvama AI model to use (eg. Challenges & Outcomes if you want to generate an email summarizing these), select and edit a prompt from the examples or create your own, and click send to generate a result."
    ),
    subTitle3: s(
      "aiDiscoveryModal.popup.chatGuide.subTitle3",
      "Here’s a few examples of what you can and cannot (currently) do with the feature:  "
    ),
    heading1: {
      title: s(
        "aiDiscoveryModal.popup.chatGuide.heading1.title",
        "You Can... "
      ),
      list: [
        s(
          "aiDiscoveryModal.popup.chatGuide.heading1.list.0",
          "Generate an email summarizing the value you will deliver to a customer or the detailed business case"
        ),
        s(
          "aiDiscoveryModal.popup.chatGuide.heading1.list.1",
          "Create a discovery cheat-sheet to help you run a call that outlines the top business challenges to explore with a customer and example deeper dive questions to help you implicate the pain"
        )
      ]
    },
    heading2: {
      title: s(
        "aiDiscoveryModal.popup.chatGuide.heading2.title",
        "Currently you cannot…"
      ),
      list: [
        s(
          "aiDiscoveryModal.popup.chatGuide.heading2.list.0",
          "Ask the AI model to update the content of the discovery – based on the options you select on the left, the model can access all the data from a discovery but it cannot make any changes"
        ),
        s(
          "aiDiscoveryModal.popup.chatGuide.heading2.list.1",
          "Ask the model about how to use the Cuvama app e.g. “how do I create a presentation?” – this is an enhancement on our roadmap but not available right now"
        )
      ]
    },
    heading3: {
      title: s(
        "aiDiscoveryModal.popup.chatGuide.heading3.title",
        "Your Feedback Matters!"
      ),
      subTitle: {
        subTitle1: s(
          "aiDiscoveryModal.popup.chatGuide.heading3.subTitle1",
          "Let us know what you think and how we can improve. Email us at support@cuvama.com or"
        ),
        subTitle2: s(
          "aiDiscoveryModal.popup.chatGuide.heading3.subTitle2",
          "click here"
        ),
        subTitle3: s(
          "aiDiscoveryModal.popup.chatGuide.heading3.subTitle3",
          "to provide your feedback and help us enhance Cuvama."
        )
      }
    }
  };

  return (
    <ChatGuideContainer data-cy={"chat-guide"}>
      <ChatGuideInner>
        <ChatGuideSection>
          <Heading level={"h3"}>{content.title}</Heading>
          <Paragraph>{content.subTitle1}</Paragraph>
          <Paragraph>{content.subTitle2}</Paragraph>
          <Paragraph>{content.subTitle3}</Paragraph>
        </ChatGuideSection>
        <ChatGuideSection>
          <Paragraph variant={"bMediumMobile"}>
            {content.heading1.title}
          </Paragraph>
          <ChatGuideList>
            {content.heading1.list.map((item, index) => (
              <ChatGuideListItem key={index}>{item}</ChatGuideListItem>
            ))}
          </ChatGuideList>
        </ChatGuideSection>
        <ChatGuideSection>
          <Paragraph variant={"bMediumMobile"}>
            {content.heading2.title}
          </Paragraph>
          <ChatGuideList>
            {content.heading2.list.map((item, index) => (
              <ChatGuideListItem key={index}>{item}</ChatGuideListItem>
            ))}
          </ChatGuideList>
        </ChatGuideSection>
        <ChatGuideSection>
          <Paragraph variant={"bMediumMobile"}>
            {content.heading3.title}
          </Paragraph>
          <Paragraph>
            <span>{content.heading3.subTitle.subTitle1}</span>
            <span>
              <NewButton type={"link"} onClick={triggerUserpilotFeedbackSurvey}>
                {content.heading3.subTitle.subTitle2}
              </NewButton>
            </span>
            <span>{content.heading3.subTitle.subTitle3}</span>
          </Paragraph>
        </ChatGuideSection>
      </ChatGuideInner>
    </ChatGuideContainer>
  );
};

const ChatGuideContainer = styled.section`
  width: 100%;
  height: 100%;
`;

const ChatGuideInner = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ChatGuideSection = styled.div`
  & > *:first-child {
    margin-bottom: 5px;
  }

  & > * {
    margin-bottom: 10px !important;
  }

  span {
    padding-right: 2px;
  }

  button {
    font-weight: ${themeProp("typography.button.fontWeight")};
  }
`;

const ChatGuideList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ChatGuideListItem = styled.li`
  list-style-type: disc;
  margin-left: 20px;
`;

export default ChatGuide;
