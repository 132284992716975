import httpService from "./http.service";

let pusherClient;
import Pusher from "pusher-js";

const authorizer = (channel) => {
  return {
    authorize: (socketId, callback) => {
      console.log(`authorizer ${socketId} ${channel.name}`);
      httpService
        .post(`/pusher/auth`, {
          socketId: socketId,
          channel: channel.name
        })
        .then(({ data }) => callback(null, data))
        .catch((err) =>
          callback(err, {
            auth: ""
          })
        );
    }
  };
};

const setPusherConfiguration = (configuration) => {
  const key = configuration.key;
  delete configuration.key;
  configuration.authorizer = authorizer;
  pusherClient = new Pusher(key, configuration);
  pusherClient.connection.bind("connected", () => {
    console.log(`PUSHER: connected`);
  });
};

const subscribe = ({ channelName, eventName, callback }) => {
  const channel = pusherClient.subscribe(channelName);
  channel.bind(eventName, callback);
  console.log(`PUSHER: Subscribing to ${channelName}/${eventName}`);
};

const unsubscribe = ({ channelName, eventName, callback }) => {
  const channel = pusherClient.channel(channelName);
  channel.unbind(eventName, callback);
  console.log(`PUSHER: Unsubscribing from ${channelName}/${eventName}`);
};

const isSubscribed = (channelName) => {
  const channel = pusherClient.channel(channelName);
  return channel !== undefined;
};

export default {
  setPusherConfiguration,
  subscribe,
  unsubscribe,
  isSubscribed
};
