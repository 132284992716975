import Form, { FormItem } from "../../../../components/Form";
import { useString as s } from "../../../../components/StringProvider";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../../../../components/ButtonGroup";
import NewButton from "../../../../components/NewButton";
import { compose } from "redux";
import { connect } from "react-redux";
import getFeatureGroups from "./get-feature-groups";
import GroupsOfCheckBoxes from "../../../../components/GroupsOfCheckBoxes";
import { selectRequestState } from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";

const getDependenciesMap = ({ groups }) => {
  const map = {};

  for (const group of groups) {
    for (const feature of group.items) {
      if (feature.dependsOn) {
        if (map[feature.dependsOn]) {
          map[feature.dependsOn].push(feature.name);
        } else {
          map[feature.dependsOn] = [feature.name];
        }
      }
    }
  }

  return map;
};

const ManageFeaturesForm = ({ form, onFinish, loadingState }) => {
  const submitButtonText = s("manageFeatures.form.submitButton.label", "Save");
  const [loading, setLoading] = useState(false);
  const featureGroups = getFeatureGroups({ s });
  const dependenciesMap = getDependenciesMap({ groups: featureGroups });
  const [currentFeatures, setCurrentFeatures] = useState("");
  const formFeatures = Form.useWatch("features", form);

  useLoadingState(
    loadingState,
    () => {
      setLoading(false);
    },
    () => {
      setLoading(false);
    },
    () => {
      setLoading(true);
    }
  );

  useEffect(() => {
    if (formFeatures) {
      const newFeatures = [...formFeatures];

      newFeatures.sort();
      const removedFeature = currentFeatures
        .split(",")
        .find((p) => !newFeatures.includes(p));

      if (removedFeature && dependenciesMap[removedFeature]) {
        form.setFieldsValue({
          features: newFeatures.filter(
            (p) => !dependenciesMap[removedFeature]?.includes(p)
          )
        });
      }

      setCurrentFeatures(newFeatures.join(","));
    }
  }, [formFeatures]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name={"featuresForm"}
    >
      <FormItem name="features">
        <GroupsOfCheckBoxes
          groups={featureGroups}
          selectedItems={currentFeatures}
        />
      </FormItem>
      <FormItem>
        <ButtonGroup>
          <NewButton
            type={"submit"}
            data-cy={"features-save-button"}
            disabled={loading}
            loading={loading}
          >
            {submitButtonText}
          </NewButton>
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

ManageFeaturesForm.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.GET_FEATURES_REQUEST)
});

export default compose(connect(mapStateToProps))(ManageFeaturesForm);
