const getDomain = ({ email }) => {
  const matches = email?.match(/^.*@(.*)$/);

  if (matches) {
    return matches[1];
  }

  return email;
};

module.exports = getDomain;
