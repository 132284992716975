import React, { useEffect } from "react";
import {
  hasPermission,
  selectDiscoveryId,
  selectDiscoveryKPIs,
  selectDiscoverySetting,
  selectIsCreatingKPI,
  selectRequestData,
  selectRequestParameters,
  selectRequestState,
  selectScrollToKPICode
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import KPICardAndModals from "../../components/Discovery/KPICard/KPICardAndModals";
import styled from "styled-components";
import { Space } from "antd";
import ShowCardOptions from "../../utils/show-card-options";
import { useMobileMediaQuery } from "../../components/Responsive";
import { CreateKPIModal } from "../../components/Discovery/KPIModal";
import Permissions from "../../utils/permissions";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import { scrollToSection } from "../Report/components/Header/ScrollUtils";
import { sortDiscoveryKPIsByOrder } from "../../utils/sorting";
import { clearScrollToKPICode } from "../../store/actions/kpi-library";

const ViewKPIsByImportance = ({
  discoveryKPIs,
  showKPIs,
  isCreatingKPI,
  canSeeKPILibrary,
  addDiscoveryKPILoadingState,
  addDiscoveryKPIParameters,
  createKPILoadingState,
  createKPIData,
  scrollToKPICode,
  clearScrollToKPICode
}) => {
  const isMobile = useMobileMediaQuery();

  useEffect(() => {
    scrollToSection({ sectionId: `kpi-card-${scrollToKPICode}` });
    clearScrollToKPICode();
  }, [scrollToKPICode]);

  useLoadingState(
    addDiscoveryKPILoadingState,
    () => {
      const kpiCode = addDiscoveryKPIParameters.kpiCode;
      scrollToSection({ sectionId: `kpi-card-${kpiCode}` });
    },
    () => {}
  );

  useLoadingState(
    createKPILoadingState,
    () => {
      const kpiCode = createKPIData.kpiCode;
      setTimeout(() => {
        scrollToSection({ sectionId: `kpi-card-${kpiCode}` });
      }, 100);
    },
    () => {}
  );

  const sortedDiscoveryKPIs = [...discoveryKPIs].filter(
    (discoveryKPI) =>
      discoveryKPI.relevant &&
      (discoveryKPI.selected || showKPIs === ShowCardOptions.ALL_CARDS)
  );

  sortedDiscoveryKPIs.sort((a, b) => {
    const importance =
      (b.selected ? b.importance : 0) - (a.selected ? a.importance : 0);

    if (importance !== 0) {
      return importance;
    }

    const selected = (b.selected ? 1 : 0) - (a.selected ? 1 : 0);

    if (selected !== 0) {
      return selected;
    }

    return sortDiscoveryKPIsByOrder(a, b);
  });

  return (
    <Cards direction={"vertical"} size={isMobile ? 8 : 14}>
      {isCreatingKPI && canSeeKPILibrary && (
        <CreateKPIModal kpiCode={undefined} />
      )}
      {sortedDiscoveryKPIs.length
        ? sortedDiscoveryKPIs.map((discoveryKPI) => (
            <KPICardAndModals
              discoveryKPI={discoveryKPI}
              key={discoveryKPI.kpiCode}
            />
          ))
        : null}
    </Cards>
  );
};

const Cards = styled(Space)``;

const mapStateToProps = (state) => {
  const scrollToKPICode = selectScrollToKPICode(state);

  return {
    scrollToKPICode,
    discoveryId: selectDiscoveryId(state),
    discoveryKPIs: selectDiscoveryKPIs(state),
    showKPIs: selectDiscoverySetting(state, "showKPIs", "all"),
    isCreatingKPI: selectIsCreatingKPI(state),
    canSeeKPILibrary: hasPermission(state, Permissions.SEE_KPI_LIBRARY),
    addDiscoveryKPILoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST
    ),
    createKPILoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_CREATE_KPI_REQUEST
    ),
    createKPIData: selectRequestData(
      state,
      actionTypes.DISCOVERY_CREATE_KPI_REQUEST
    ),
    addDiscoveryKPIParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearScrollToKPICode
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ViewKPIsByImportance
);
