import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";

const INITIAL_STATE = {
  solutionsMap: null,
  loadingState: LoadingState.UNINITIALIZED,
  error: null
};

const previewSolutionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERY_PREVIEW_SOLUTIONS_REQUEST:
      return {
        ...state,
        loadingState: LoadingState.IN_PROGRESS,
        error: null
      };

    case types.DISCOVERY_PREVIEW_SOLUTIONS_FAILURE:
      return {
        ...state,
        loadingState: LoadingState.FAILED,
        error: action.payload.error
      };

    case types.DISCOVERY_PREVIEW_SOLUTIONS_SUCCESS:
      return {
        ...state,
        loadingState: LoadingState.COMPLETED,
        solutionsMap: action.payload.solutionsMap
      };

    default:
      return state;
  }
};

export default previewSolutionsReducer;

export const selectPreviewSolutionsMap = (data) => data.solutionsMap;
export const selectPreviewSolutionsError = (data) => data.error;
export const selectPreviewSolutionsLoadingState = (data) => data.loadingState;
