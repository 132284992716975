import CheckboxGroup from "./CheckboxGroup";
import styled from "styled-components";
import { themeProp } from "../theme";
import Checkbox from "./Checkbox";
import PropTypes from "prop-types";
import { useMobileMediaQuery, useTabletMediaQuery } from "./Responsive";

const GroupsOfCheckBoxes = ({ groups, selectedItems, ...props }) => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const fullInputWidth = "673px";

  return (
    <CheckboxGroup {...props}>
      {groups.map((group, index) => (
        <GroupContainer
          $isMobile={isMobile}
          $isTablet={isTablet}
          $inputWidth={fullInputWidth}
          key={index}
        >
          <GroupTitle>{group.name}</GroupTitle>
          {group.items.map((item) => (
            <div key={item.name} className={"item"}>
              <StyledCheckbox
                value={item.name}
                disabled={
                  item.disabled ||
                  (item.dependsOn &&
                    !selectedItems.split(",").includes(item.dependsOn))
                }
              >
                {item.label || item.name}
              </StyledCheckbox>
              {item.description && (
                <Description $isMobile={isMobile} $isTablet={isTablet}>
                  {item.description}
                </Description>
              )}
              <Divider />
            </div>
          ))}
        </GroupContainer>
      ))}
    </CheckboxGroup>
  );
};

const Divider = styled.hr`
  border-top: 1px solid ${themeProp("palette.gray2")};
`;

const GroupContainer = styled.div`
  margin-bottom: 30px;
  background-color: white;
  width: ${({ $isMobile, $isTablet, $inputWidth }) =>
    $isTablet ? "420px" : $isMobile ? undefined : $inputWidth};
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > .item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    margin-bottom: 8px;
  }

  & > .item:last-child {
    margin-bottom: 0;
  }
`;

const GroupTitle = styled.div`
  margin-bottom: 10px;
  background-color: ${themeProp("palette.gray2")};
  color: ${themeProp("palette.gray4")};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  font-weight: bold;
`;

const Description = styled.div`
  padding-left: 48px;
  font-size: 11px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-left: 16px;
`;

GroupsOfCheckBoxes.propTypes = {
  groups: PropTypes.array.isRequired,
  selectedItems: PropTypes.string.isRequired
};

export default GroupsOfCheckBoxes;
