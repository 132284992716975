import React, { useEffect, useState } from "react";
import { themeProp } from "../../../theme";
import styled, { css } from "styled-components";
import NewButton from "../../NewButton";
import { useString as s } from "../../../components/StringProvider";
import CheckboxGroup from "../../CheckboxGroup";
import Checkbox from "../../Checkbox";
import { Paragraph } from "../../Typography";
import Form, { FormItem, FormItemLabel } from "../../Form";
import Radio from "../../Radio";
import MultipleEmailInput, {
  allEmailsValid,
  getEmailOptions
} from "../../Input/MultipleEmailInput";
import { useChat } from "./ChatProvider";
import AiChatShareAccessTypes from "../../../utils/constants/ai-chat-share-access-types";
import AIChatConversationOptions from "../../../utils/constants/ai-chat-conversation-options";

const ChatMenuNames = {
  CHECK_ALL: "checkAll",
  DATA_SOURCES: "dataSources",
  EDIT_ACCESS_EMAILS: "editAccessEmails",
  SHARE_ACCESS: "shareAccess"
};

const ChatMenu = () => {
  const startChatButton = s(
    "aiDiscoveryModal.chat.menu.start.button",
    "Start new chat"
  );
  const dataSourcesLabel = s(
    "aiDiscoveryModal.chat.menu.data.sources.label",
    "Select data sources"
  );
  const dataSourcesTooltip = s(
    "aiDiscoveryModal.chat.menu.data.sources.tooltip",
    'Pick which elements of the Cuvama discovery record you would like the AI to use to help create your response. Note the customer name and the segmentation answers are always provided. These will be passed to the model as “Sales Discovery Information"'
  );
  const shareAccessLabel = s(
    "aiDiscoveryModal.chat.menu.share.access.label",
    "Share access"
  );
  const shareAccessTooltip = s(
    "aiDiscoveryModal.chat.menu.share.access.tooltip",
    "View Access: only view the report. Edit Access: modify discovery challenges, outcomes, and inputs."
  );
  const viewAccess = s(
    "aiDiscoveryModal.chat.menu.view.access.radio.button",
    "View access (Report)"
  );
  const editAccess = s(
    "aiDiscoveryModal.chat.menu.edit.access.radio.button",
    "Edit access (Discovery)"
  );
  const emailPlaceholder = s(
    "aiDiscoveryModal.chat.menu.email.placeholder",
    "Enter email(s)"
  );
  const emailValidation = s(
    "aiDiscoveryModal.chat.menu.email.validation",
    "Please enter a valid email"
  );
  const selectAll = s("dataSources.option.selectAll", "Select all");
  const challenges = s("dataSources.option.challenges", "Challenges");
  const outcomes = s("dataSources.option.outcomes", "Outcomes");
  const inputs = s("dataSources.option.inputs", "Inputs");
  const valueHypothesis = s(
    "dataSources.option.valueHypothesis",
    "Value Hypothesis"
  );
  const roi = s("dataSources.option.roi", "ROI");
  const discoveryNotes = s(
    "dataSources.option.discoveryNotes",
    "Discovery Notes"
  );
  const includeShareLinks = s(
    "dataSources.option.includeShareLinks",
    "Include Share Links"
  );

  const dataSourcesOption = [
    { label: challenges, value: AIChatConversationOptions.CHALLENGES },
    { label: outcomes, value: AIChatConversationOptions.OUTCOMES },
    { label: inputs, value: AIChatConversationOptions.INPUTS },
    {
      label: valueHypothesis,
      value: AIChatConversationOptions.VALUE_HYPOTHESIS
    },
    { label: roi, value: AIChatConversationOptions.ROI },
    {
      label: discoveryNotes,
      value: AIChatConversationOptions.DISCOVERY_NOTES
    },
    {
      label: includeShareLinks,
      value: AIChatConversationOptions.SHARE_ACCESS_LINK
    }
  ];

  const defaultDataSourcesOption = dataSourcesOption
    .slice(0, 5)
    .map((option) => option?.value);

  const shareAccessOption = [
    {
      label: viewAccess,
      value: AiChatShareAccessTypes.VIEW,
      dataCy: "view-access-option-button"
    },
    {
      label: editAccess,
      value: AiChatShareAccessTypes.EDIT,
      dataCy: "edit-access-option-button"
    }
  ];

  const defaultFormValues = {
    [ChatMenuNames.DATA_SOURCES]: defaultDataSourcesOption,
    [ChatMenuNames.SHARE_ACCESS]: AiChatShareAccessTypes.VIEW,
    [ChatMenuNames.EDIT_ACCESS_EMAILS]: []
  };

  const [form] = Form.useForm();
  const {
    isChatActive,
    handleResetConversation,
    setCanEnterPrompt,
    setChatOptions,
    conversationId,
    chatData
  } = useChat();

  const [showShareOption, setShowShareOption] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [values, setValues] = useState(defaultFormValues);

  useEffect(() => {
    if (conversationId && chatData) {
      const { options = [], accessType = null, emails = [] } = chatData;

      setValues({
        [ChatMenuNames.DATA_SOURCES]: options,
        [ChatMenuNames.SHARE_ACCESS]: accessType,
        [ChatMenuNames.EDIT_ACCESS_EMAILS]: emails
      });
    }
  }, [conversationId, chatData]);

  useEffect(() => {
    form.setFieldsValue(values);

    if (
      values[ChatMenuNames.DATA_SOURCES]?.includes(
        AIChatConversationOptions.SHARE_ACCESS_LINK
      )
    ) {
      setShowShareOption(true);
    } else {
      setShowShareOption(false);
    }

    if (values[ChatMenuNames.DATA_SOURCES]) {
      setCheckAll(
        values[ChatMenuNames.DATA_SOURCES].length === dataSourcesOption.length
      );
    }

    if (
      values[ChatMenuNames.SHARE_ACCESS] === AiChatShareAccessTypes.EDIT &&
      !values[ChatMenuNames.EDIT_ACCESS_EMAILS]?.length
    ) {
      setCanEnterPrompt(false);
    } else {
      setCanEnterPrompt(true);
    }

    if (values) {
      const canShareAccess = values[ChatMenuNames.DATA_SOURCES].includes(
        AIChatConversationOptions.SHARE_ACCESS_LINK
      );

      setChatOptions({
        options: values[ChatMenuNames.DATA_SOURCES],
        accessType: canShareAccess ? values[ChatMenuNames.SHARE_ACCESS] : null,
        emails: canShareAccess
          ? values[ChatMenuNames.EDIT_ACCESS_EMAILS]?.map(
              (email) => email?.value
            )
          : []
      });
    }
  }, [values]);

  const onValuesChange = (updatedValues) => {
    if (updatedValues.hasOwnProperty(ChatMenuNames.EDIT_ACCESS_EMAILS)) {
      const emails = getEmailOptions(
        updatedValues[ChatMenuNames.EDIT_ACCESS_EMAILS]
      );
      const hasInvalidEmails = !allEmailsValid(
        updatedValues[ChatMenuNames.EDIT_ACCESS_EMAILS]
      );
      setCanEnterPrompt(!hasInvalidEmails);

      if (!hasInvalidEmails) {
        setValues((prev) => ({
          ...prev,
          [ChatMenuNames.EDIT_ACCESS_EMAILS]: emails
        }));
      }
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        ...updatedValues
      }));
    }
  };

  const onChangeDataSources = (values) => {
    onValuesChange({ [ChatMenuNames.DATA_SOURCES]: values });
  };

  const onCheckAllDataSources = (e) => {
    onValuesChange({
      [ChatMenuNames.DATA_SOURCES]: e.target.checked
        ? dataSourcesOption?.map((option) => option?.value)
        : [],
      [ChatMenuNames.CHECK_ALL]: e.target.checked
    });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleStartNewChat = () => {
    setValues(defaultFormValues);
    handleResetConversation();
  };

  const dataSources = (
    <>
      <FormItem
        name={ChatMenuNames.CHECK_ALL}
        className={"check-all"}
        label={
          <FormItemLabel
            label={dataSourcesLabel}
            tooltip={dataSourcesTooltip}
            bold={true}
            size={"medium"}
          />
        }
      >
        <StyledCheckbox
          size={"small"}
          indeterminate={indeterminate}
          checked={checkAll}
          onChange={onCheckAllDataSources}
        >
          <Paragraph color={"text"} variant={"body"}>
            {selectAll}
          </Paragraph>
        </StyledCheckbox>
      </FormItem>
      <FormItem name={ChatMenuNames.DATA_SOURCES}>
        <StyledCheckboxGroup onChange={onChangeDataSources}>
          {dataSourcesOption.map((item, index) => (
            <StyledCheckbox key={index} value={item.value} size={"small"}>
              <Paragraph color={"text"} variant={"body"}>
                {item.label}
              </Paragraph>
            </StyledCheckbox>
          ))}
        </StyledCheckboxGroup>
      </FormItem>
    </>
  );

  const shareAccess = showShareOption ? (
    <FormItem
      label={
        <FormItemLabel
          label={shareAccessLabel}
          tooltip={shareAccessTooltip}
          bold={true}
          size={"medium"}
        />
      }
      name={ChatMenuNames.SHARE_ACCESS}
    >
      <StyledRadioGroup>
        {shareAccessOption.map((access, index) => (
          <Radio value={access.value} key={index} data-cy={access.dataCy}>
            <Paragraph color={"text"} variant={"body"}>
              {access.label}
            </Paragraph>
          </Radio>
        ))}
      </StyledRadioGroup>
    </FormItem>
  ) : null;

  const editAccessEmail =
    showShareOption &&
    values[ChatMenuNames.SHARE_ACCESS] === AiChatShareAccessTypes.EDIT ? (
      <EmailContainer data-cy={"edit-access-email"}>
        <FormItem
          name={ChatMenuNames.EDIT_ACCESS_EMAILS}
          rules={[
            () => ({
              validator(_, value) {
                if (!allEmailsValid(value)) {
                  return Promise.reject(new Error(emailValidation));
                }

                return Promise.resolve();
              }
            })
          ]}
        >
          <MultipleEmailInput
            placeholder={emailPlaceholder}
            variant={"secondary"}
            maxTagRows={2}
          />
        </FormItem>
      </EmailContainer>
    ) : null;

  return (
    <ChatMenuContainer>
      <StyledForm
        form={form}
        layout={"vertical"}
        onValuesChange={onValuesChange}
        disabled={isChatActive}
        $disabled={isChatActive}
      >
        {dataSources}
        {shareAccess}
        {editAccessEmail}
      </StyledForm>
      {isChatActive && (
        <ChatMenuButton>
          <NewButton
            type={"alternate"}
            onClick={handleStartNewChat}
            data-cy={"start-button"}
          >
            {startChatButton}
          </NewButton>
        </ChatMenuButton>
      )}
    </ChatMenuContainer>
  );
};

const ChatMenuContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  position: relative;

  label[for="${ChatMenuNames.DATA_SOURCES}"],
  label[for="${ChatMenuNames.SHARE_ACCESS}"],
  label[for="${ChatMenuNames.CHECK_ALL}"] {
    font-weight: 700;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  > :first-child {
    margin-bottom: 16px;
  }

  .check-all {
    margin-bottom: 0;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const EmailContainer = styled.div``;
const ChatMenuButton = styled.div``;

const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  & .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${themeProp("palette.secondary")};
    border-color: ${themeProp("palette.secondary")};

    &::after {
      border-color: ${themeProp("palette.surface")};
    }
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default ChatMenu;
