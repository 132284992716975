import React from "react";
import { useDragLayer } from "react-dnd";
import styled from "styled-components";
import ItemTypes from "../../components/ItemTypes";
import { themeProp } from "../../theme";
import PropTypes from "prop-types";

const CustomDragLayer = ({ dimensions, children }) => {
  const { left, width, height } = dimensions;

  const { itemType, isDragging, currentOffset, initialOffset } = useDragLayer(
    (monitor) => ({
      itemType: monitor.getItemType(),
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getClientOffset()
    })
  );

  const getItemStyles = () => {
    if (!initialOffset || !currentOffset) {
      return {
        display: "none"
      };
    }

    const { y } = currentOffset;

    return {
      transform: `translate(${left}px, ${y}px)`,
      WebkitTransform: `translate(${left}px, ${y}px)`
    };
  };

  const getContainerStyles = () => {
    if (!isDragging) {
      return {
        display: "none"
      };
    }

    return {
      position: "fixed",
      pointerEvents: "none",
      zIndex: 1000,
      left: 0,
      top: 0,
      width: `${width}px`,
      height: `${height}px`,
      opacity: `90%`,
      cursor: "grabbing"
    };
  };

  return (
    <div style={getContainerStyles()}>
      <DragPreviewContainer style={getItemStyles()}>
        {(itemType === ItemTypes.VALUE_MAP_CHALLENGE ||
          itemType === ItemTypes.VALUE_MAP_KPI) && (
          <CustomDragPreview height={`${height}px`}>
            {children}
          </CustomDragPreview>
        )}
      </DragPreviewContainer>
    </div>
  );
};

const DragPreviewContainer = styled.div`
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  top: -40%;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CustomDragPreview = styled.div`
  background-color: ${themeProp("palette.gray1")};
  width: 100%;
  height: ${(props) => props.height};
  font-weight: ${themeProp("typography.body.fontWeight")};
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;

  & > * {
    background-color: transparent !important;
    cursor: grabbing !important;
    width: 100%;

    &.dragging {
      visibility: visible;
      opacity: 1;
      cursor: grabbing;
    }

    &:hover,
    &:hover:not(.selected) {
      cursor: grabbing !important;
    }

    & .ant-btn-iconSecondary {
      visibility: visible !important;
    }

    &:hover:not(.dragging) .ant-btn-iconSecondary {
      visibility: visible;
    }
  }
`;

CustomDragLayer.propTypes = {
  dimensions: PropTypes.shape({
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number
  }),
  children: PropTypes.node
};

export default CustomDragLayer;
