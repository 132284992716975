import types from "../actionTypes";

const initialState = {
  integration: null
};

const integrationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CREATE_INTEGRATION_SUCCESS:
    case types.UPDATE_INTEGRATION_SUCCESS:
    case types.GET_INTEGRATION_SUCCESS:
      return { ...state, integration: payload };

    default:
      return state;
  }
};

export const selectIntegration = (integrationsData) => integrationsData.guest;

export default integrationsReducer;
