import ReactDOM from "react-dom";
import styled from "styled-components";
import React from "react";
import { themeProp } from "../../../theme";
import { applyStringTemplate } from "../../../utils/formatting";
import { useStringTemplate } from "../../StringProvider";

const KPISliderValue = ({ node, disabled, range, formatter, reversed }) => {
  const rangeText = useStringTemplate(
    "discovery.kpi.card.slider.range",
    "{low} to {high}"
  );

  return node
    ? ReactDOM.createPortal(
        <SliderValueContainer>
          <SliderValueWrapper disabled={disabled}>
            {range && range.length && (
              <>
                {!reversed
                  ? applyStringTemplate({
                      template: rangeText,
                      placeholders: {
                        low: formatter(range[0]),
                        high: formatter(range[1])
                      }
                    })
                  : applyStringTemplate({
                      template: rangeText,
                      placeholders: {
                        low: formatter(range[1]),
                        high: formatter(range[0])
                      }
                    })}
              </>
            )}
          </SliderValueWrapper>
        </SliderValueContainer>,
        node
      )
    : null;
};

const SliderValueContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;
const SliderValueWrapper = styled.div`
  font-weight: bold;
  color: ${(props) =>
    props.disabled ? themeProp("palette.gray3") : themeProp("palette.text")};
  background: ${themeProp("palette.surface")};
  white-space: nowrap;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    ${themeProp("palette.surface")} 10%,
    ${themeProp("palette.surface")} 90%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 0 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

export default KPISliderValue;
