import React, { useState } from "react";
import Page from "../../components/Page";
import {
  BasicBrandHeader,
  FullPageLayout,
  SplitPageLayout
} from "../../components/Layout";
import styled from "styled-components";
import { useString as s } from "../../components/StringProvider";
import { Space } from "antd";
import Text from "../../components/Text";
import { useParams } from "react-router-dom";
import JobMonitor from "../../components/JobMonitor";
import JobStatuses from "../../utils/job-statuses";
import ErrorPage from "../ErrorPage";
import Progress from "../../components/Progress";
import Heading from "../../components/Heading";
import ButtonGroup from "../../components/ButtonGroup";
import NewButton from "../../components/NewButton";
import DiscoveryHeader from "../Discovery/DiscoveryHeader";
import {
  DesktopOrTablet,
  Mobile,
  useMobileMediaQuery,
  useTinyMobileMediaQuery
} from "../../components/Responsive";
import { useTheme } from "../../components/ThemeProvider";
import MobilePageCentredLayout from "../../components/Layout/MobilePageCentredLayout";
import { selectRequestState } from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import { bindActionCreators } from "redux";
import { getStrings } from "../../store/actions/strings";
import { connect } from "react-redux";
import { getTheme } from "../../store/actions/themes";
import { combineLoadingStates } from "../../utils/loading-state";
import useLoadingState from "../../utils/use-loading-state";

const isInvalidJob = (job) => {
  return (
    job?.results?.questions?.length !== 2 ||
    job?.results?.challenges?.length !== 10 ||
    job?.results?.kpis?.length !== 10
  );
};

const JobProgress = ({ description }) => {
  const messages = {};
  const defaultMessage = s(
    "autoConfiguration.page.messages.defaultMessage",
    "Processing..."
  );

  messages["CHECK_COMPANY"] = s(
    "autoConfiguration.page.messages.CHECK_COMPANY",
    "Researching company..."
  );

  messages["BUILD_CATEGORIES"] = s(
    "autoConfiguration.page.messages.BUILD_CATEGORIES",
    "Building Categories..."
  );

  messages["BUILD_QUESTIONS"] = s(
    "autoConfiguration.page.messages.BUILD_QUESTIONS",
    "Building Segments / Personas..."
  );

  messages["BUILD_CHALLENGES"] = s(
    "autoConfiguration.page.messages.BUILD_CHALLENGES",
    "Building Challenges..."
  );

  messages["BUILD_KPIS"] = s(
    "autoConfiguration.page.messages.BUILD_KPIS",
    "Building KPIs..."
  );

  messages["BUILD_METRICS"] = s(
    "autoConfiguration.page.messages.BUILD_METRICS",
    "Building Metrics..."
  );

  messages["BUILD_CHALLENGE_MAPPINGS"] = s(
    "autoConfiguration.page.messages.BUILD_CHALLENGE_MAPPINGS",
    "Mapping Challenges to KPIs..."
  );

  messages["DEPLOYING_CONFIGURATION"] = s(
    "autoConfiguration.page.messages.DEPLOYING_CONFIGURATION",
    "Deploying Configuration..."
  );

  return (
    <Text variant={"body"}>
      {messages[description] || description || defaultMessage}
    </Text>
  );
};

const LoadingPage = ({ history, getTheme, getStrings, loadingState }) => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const isMobile = useMobileMediaQuery();
  const isTinyMobile = useTinyMobileMediaQuery();
  const pageTitle = s(
    "autoConfiguration.page.loading.title",
    "We are crafting your Cuvama experience..."
  );

  const pageSubTitle = s(
    "autoConfiguration.page.loading.subtitle",
    "Usually it takes just a few minutes. Please stay on this page and let the magic happen!"
  );

  const completedPageTitle = s(
    "autoConfiguration.page.completed.title",
    "Congratulations!"
  );

  const completedPageSubTitle = s(
    "autoConfiguration.page.completed.subtitle",
    "The {companyName} experience is now configured. You can now create your first discovery.",
    { companyName: job?.parameters?.companyName }
  );

  const buttonText = s(
    "autoConfiguration.page.completed.button",
    "Let's start"
  );

  const onChangeJob = (j) => {
    setJob(j);
  };

  const onStartClick = () => {
    getTheme();
    getStrings();
  };

  useLoadingState(
    loadingState,
    () => {
      history.push({
        pathname: `/discoveries/create`
      });
    },
    () => {}
  );

  const completedContext = (
    <CompletedContainer className={isMobile ? "mobile" : undefined}>
      <Heading level={isTinyMobile ? "h3" : isMobile ? "h2" : "h1"}>
        {completedPageTitle}
      </Heading>
      <Heading level={isMobile ? "body" : "h3"}>
        {completedPageSubTitle}
      </Heading>

      <ButtonContainer className={isMobile ? "mobile" : undefined}>
        <ButtonGroup>
          <NewButton type={"primary"} onClick={onStartClick}>
            {buttonText}
          </NewButton>
        </ButtonGroup>
      </ButtonContainer>
    </CompletedContainer>
  );

  return job?.status === JobStatuses.FAILED ||
    (job?.status === JobStatuses.COMPLETED && isInvalidJob(job)) ? (
    <ErrorPage />
  ) : job?.status === JobStatuses.COMPLETED ? (
    <Page
      header={
        isMobile ? <BasicBrandHeader /> : <DiscoveryHeader showMenu={false} />
      }
    >
      <DesktopOrTablet>
        <SplitPageLayout
          image={useTheme("assets.discovery_cover")}
          hasHeader={true}
        >
          {completedContext}
        </SplitPageLayout>
      </DesktopOrTablet>
      <Mobile>
        <MobilePageCentredLayout
          hasHeader={true}
          image={useTheme("assets.discovery_cover")}
        >
          {completedContext}
        </MobilePageCentredLayout>
      </Mobile>
    </Page>
  ) : (
    <Page header={<BasicBrandHeader />}>
      <FullPageLayout>
        <Container>
          <Content>
            <Space size={4} direction={"vertical"}>
              <Text variant={"body"}>
                <b>{pageTitle}</b>
              </Text>
              <Text variant={"body"}>{pageSubTitle}</Text>
              <JobProgress description={job?.progress?.description} />
              <Progress
                color={"primary"}
                percent={
                  job?.progress?.totalSteps
                    ? (100 * job.progress.currentStep) / job.progress.totalSteps
                    : 0
                }
              />
            </Space>
          </Content>
        </Container>
      </FullPageLayout>
      <JobMonitor jobId={jobId} onChange={onChangeJob} />
    </Page>
  );
};

const Content = styled.div`
  text-align: center;
  max-width: 500px;
  padding: 40px;
`;

const ButtonContainer = styled.div`
  &.mobile {
    margin-top: 32px;
  }
`;

const Container = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompletedContainer = styled.div`
  padding-left: 80px;
  padding-top: 240px;

  display: flex;
  flex-direction: column;

  h1 {
    max-width: 568px;
  }

  h3 {
    max-width: 489px;
    margin: 16px 0 40px 0;
  }

  &.mobile {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  &.mobile h2 {
    text-align: center;
    margin-bottom: 16px;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 16px;
  }

  &.mobile body {
    text-align: center;
    margin-bottom: 16px;
  }
`;

const mapStateToProps = (state) => ({
  loadingState: combineLoadingStates([
    selectRequestState(state, actionTypes.GET_THEME_REQUEST),
    selectRequestState(state, actionTypes.GET_STRINGS_REQUEST)
  ])
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStrings,
      getTheme
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);
