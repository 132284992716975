import React, { useEffect, useState } from "react";
import Select from "../../components/Select";
import styled from "styled-components";
import PhaseName from "../../components/PhaseName";
import Link from "../../components/Link";
import { themeProp } from "../../theme";
import { DisabledLink } from "./DiscoveryHeader";

const DiscoveryHeaderMobileMenu = ({
  menuItems,
  selectedMenuItem,
  discovery
}) => {
  const DEFAULT_MENU_PHASE = "Challenges Solved";

  const [selectedValue, setSelectedValue] = useState("");

  const onSelect = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    const updateFormValues = () => {
      if (selectedMenuItem) {
        setSelectedValue(selectedMenuItem);
      } else {
        setSelectedValue(DEFAULT_MENU_PHASE);
      }
    };

    updateFormValues();
  }, [selectedMenuItem]);

  const options = menuItems
    .filter((item) => item.visible)
    ?.map((item) => {
      return {
        value: item.phase,
        label: <PhaseName phase={item.phase} />,
        link: item.link,
        disabled: item.disabled || !item.link,
        isSelected: item.isSelected
      };
    });

  const optionRender = (option) => {
    if (!option) {
      return null;
    }

    const { link, label, isSelected } = option.data;

    return link ? (
      <Link
        to={`/discoveries/${discovery._id}${link}`}
        color={isSelected ? "headerSelected" : "headerUnselected"}
        data-cy={"discovery-header-menu-item"}
      >
        {label}
      </Link>
    ) : (
      <DisabledLink>{label}</DisabledLink>
    );
  };

  return (
    <MenuContainer isActive={Boolean(selectedMenuItem)}>
      <Select
        options={options}
        optionRender={optionRender}
        value={selectedValue}
        onSelect={onSelect}
      />
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    position: relative;
  }

  .ant-select-dropdown {
    min-width: 160px !important;
  }

  .ant-select {
    height: 100%;
  }

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-selection-item {
    color: ${themeProp("palette.secondary")} !important;
    font-weight: ${themeProp(`typography.bMedium.fontWeight`)};
    font-size: ${themeProp("typography.body.fontSize")} !important;
  }

  .ant-select-arrow svg {
    color: ${themeProp("palette.secondary")};
  }
`;

export default DiscoveryHeaderMobileMenu;
