import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { generatePassword } from "../../../../store/actions/users";
import { useString as s } from "../../../../components/StringProvider";
import { message, Space } from "antd";
import PropTypes from "prop-types";
import {
  selectRequestData,
  selectRequestError,
  selectRequestState
} from "../../../../store/reducers";
import NewButton from "../../../../components/NewButton";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import ButtonGroup from "../../../../components/ButtonGroup";
import { Modal } from "../../../../components";
import Text from "../../../../components/Text";
import Icon from "../../../../components/Icon";
import styled from "styled-components";
import useCopyClipboard from "../../../../utils/use-copy-clipboard";
import { requestsStoreReset } from "../../../../store/actions/requests";

const GeneratePasswordModal = ({
  userId,
  onClose,
  loadingState,
  error,
  generatePassword,
  generatedPassword,
  requestsStoreReset
}) => {
  const passwordText = s(
    "generatePasswordModal.password.label",
    "Password: {generatedPassword}",
    { generatedPassword }
  );

  const copySuccess = s(
    "generatePasswordModal.messages.copySuccess",
    "Password copied to clipboard"
  );
  const copyFailure = s(
    "generatePasswordModal.messages.copyFailure",
    "Password was not copied to clipboard"
  );
  const { copyToClipboard } = useCopyClipboard({
    showNotification: true,
    copySuccessMessage: copySuccess,
    copyFailedMessage: copyFailure
  });

  const submitText = s(
    "generatePasswordModal.submitButton.label",
    "Generate password"
  );
  const cancelText = s("generatePasswordModal.cancelButton.label", "Cancel");
  const closeText = s("generatePasswordModal.closeButton.label", "Close");
  const errorText = s(
    "generatePasswordModal.messages.error",
    "Failed to generate password"
  );

  const onSubmit = () => {
    generatePassword({ userId });
  };

  useLoadingState(
    loadingState,
    () => {},
    () => {
      if (error) {
        message.error(errorText);
      }
    }
  );

  const _onClose = () => {
    requestsStoreReset({
      actionType: actionTypes.USER_GENERATE_PASSWORD_RESET
    });
    onClose();
  };

  const copyAction = () => {
    copyToClipboard(generatedPassword);
  };

  return (
    <Modal
      title={s("generatePasswordModal.title", "Generate Password")}
      open={true}
      onCancel={_onClose}
    >
      <Space direction={"vertical"} size={16}>
        {generatedPassword && (
          <PasswordContainer>
            <Text data-cy={"password-text"}>{passwordText}</Text>
            <NewButton
              data-cy={"copy-password-button"}
              type={"iconSecondary"}
              onClick={copyAction}
            >
              <Icon name={"copyAll"} size={"large"}></Icon>
            </NewButton>
          </PasswordContainer>
        )}
        {!generatedPassword && <SpaceDiv />}
        <ButtonGroup>
          {!generatedPassword && (
            <NewButton
              type={"submit"}
              onClick={onSubmit}
              data-cy={"generate-password"}
            >
              {submitText}
            </NewButton>
          )}
          <NewButton
            type={"secondary"}
            onClick={_onClose}
            data-cy={"generate-password-cancel"}
          >
            {generatedPassword ? closeText : cancelText}
          </NewButton>
        </ButtonGroup>
      </Space>
    </Modal>
  );
};

const SpaceDiv = styled(ButtonGroup)`
  height: 24px;
`;

const PasswordContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

GeneratePasswordModal.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.USER_GENERATE_PASSWORD_REQUEST
  ),
  generatedPassword: selectRequestData(
    state,
    actionTypes.USER_GENERATE_PASSWORD_REQUEST
  )?.password,
  error: selectRequestError(state, actionTypes.USER_GENERATE_PASSWORD_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generatePassword,
      requestsStoreReset
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GeneratePasswordModal
);
