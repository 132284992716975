import { selectCategory } from "../../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import React, { useContext } from "react";
import { SettingsContext } from "../../../../components/SettingsProvider";
import { formatRangeNumber } from "../../../../utils/formatting";
import PropTypes from "prop-types";
import MetricTypes from "../../../../utils/metric-types";
import ChartColumn from "../ChartColumn";
import { getCategoryValue } from "../../value-hypothesis-util";

const getCategoryText = ({ category, scenario, delayTimeline, currency }) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const value = getCategoryValue({ category, scenario, delayTimeline });

  return formatRangeNumber({
    value,
    type: MetricTypes.CURRENCY,
    options: { locale, currency, notation: "compact" }
  });
};

const KPICategoryColumn = ({
  index,
  currency,
  category,
  scenario,
  delayTimeline,
  kpiCategory
}) => {
  const categoryTotal = getCategoryText({
    category: kpiCategory,
    scenario,
    delayTimeline,
    currency
  });

  return (
    <ChartColumn
      index={index}
      title={category.name}
      color={category?.chartColor}
      borderColor={category?.chartColor}
      value={categoryTotal}
      dataCy={"category-chart-column-bar"}
    />
  );
};

KPICategoryColumn.propTypes = {
  categoryCode: PropTypes.string.isRequired,
  totalImpactValue: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    category: selectCategory(state, props.categoryCode)
  };
};

export default compose(connect(mapStateToProps))(KPICategoryColumn);
