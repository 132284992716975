import types from "../actionTypes";

const initialState = {
  guest: null
};

const guestsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_GUEST_SUCCESS:
    case types.REGISTER_GUEST_SUCCESS:
      return { ...state, guest: payload };

    default:
      return state;
  }
};

export const selectGuest = (guestsData) => guestsData.guest;

export default guestsReducer;
