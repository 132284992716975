const JobStatuses = {
  PENDING: "pending",
  STARTED: "started",
  COMPLETED: "completed",
  FAILED: "failed"
};

JobStatuses.ALL = Object.values(JobStatuses);

export default JobStatuses;
