import React from "react";
import { NumberInput } from "../../../components/Input";
import styled from "styled-components";
import { useMobileOrTabletMediaQuery } from "../../../components/Responsive";
import SliderInput from "../../../components/Input/SliderInput";
import PropTypes from "prop-types";

const IntegerValueSimpleNumberEntryBox = ({ min, max, editable, ...props }) => {
  const isMobileOrTablet = useMobileOrTabletMediaQuery();
  const marks = Array.from(Array(max - min + 1).keys())
    .map((i) => i + min)
    .reduce((map, n) => {
      if (n === min || n === max || n % ((max - min) / 5) === 0) {
        map[n] = n;
      }
      return map;
    }, {});

  return isMobileOrTablet ? (
    <SliderContainer>
      <SliderInput
        disabled={!editable}
        min={min}
        max={max}
        marks={isMobileOrTablet ? marks : undefined}
        {...props}
      />
    </SliderContainer>
  ) : (
    <Container>
      <NumberInput min={min} max={max} disabled={!editable} {...props} />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 19px;
`;

const SliderContainer = styled.div`
  margin-bottom: 19px;
`;

IntegerValueSimpleNumberEntryBox.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired
};

export default IntegerValueSimpleNumberEntryBox;
