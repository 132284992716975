import React, { useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { hasPermission, selectDiscoveryId } from "../../../store/reducers";
import Menu, { MenuItem } from "../../Menu";
import Icon from "../../Icon";
import Dropdown from "../../Dropdown";
import { useString as s } from "../../../components/StringProvider";
import { startEditingKPI } from "../../../store/actions/edit-kpis";
import NewButton from "../../NewButton";
import { startCreatingKPI } from "../../../store/actions/create-kpis";
import Permissions from "../../../utils/permissions";
import FormulaPanel from "../FormulaValue/FormulaPanel";
import EstimationScenarioTypes from "../../../utils/estimation-scenario-types";
import Popover from "../../Popover";
import { useMobileMediaQuery } from "../../Responsive";
import {
  getDiscoveryKPILibrary,
  removeDiscoveryKPIFromLibrary
} from "../../../store/actions/discoveries";
import { isCustomKPI } from "../../../utils/filters/kpi-filters";
import { startDeletingKPI } from "../../../store/actions/delete-kpis";
import Tooltip from "../../Tooltip";

const EDIT_KPI = "editKPI";
const ADD_KPI = "addKPI";
const DELETE_KPI = "deleteKPI";
const REMOVE_FROM_LIST = "removeFromList";
const VIEW_FORMULA = "viewFormula";

const KPICardDropdown = ({
  discoveryId,
  discoveryKPI,
  hasFormulaDescription,
  startEditingKPI,
  startCreatingKPI,
  startDeletingKPI,
  removeDiscoveryKPIFromLibrary,
  canEditKPIs,
  canSeeKPILibrary
}) => {
  const isMobile = useMobileMediaQuery();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const editKPIText = s("discovery.kpi.card.editKPI.name", "Edit card");
  const deleteKPIText = s(
    "discovery.kpi.card.deleteKPI.name",
    "Delete custom card"
  );
  const removeFromListText = s(
    "discovery.kpi.card.removeFromList.name",
    "Remove from list"
  );
  const viewFormulaText = s(
    "discovery.kpi.card.viewFormula.name",
    "View formula"
  );
  const viewFormulaDisabled = s(
    "discovery.kpi.card.viewFormula.disabled",
    "Can't view the formula of a deselected KPI"
  );
  const moreActionsText = s(
    "discovery.kpi.card.moreActions.name",
    "More actions"
  );

  const onClick = (e) => {
    switch (e.key) {
      case EDIT_KPI:
        startEditingKPI({
          kpiCode: discoveryKPI.kpiCode
        });
        break;
      case ADD_KPI:
        startCreatingKPI({
          kpiCode: discoveryKPI.kpiCode
        });
        break;
      case REMOVE_FROM_LIST:
        removeDiscoveryKPIFromLibrary({
          discoveryId,
          kpiCode: discoveryKPI.kpiCode
        });
        break;
      case DELETE_KPI:
        startDeletingKPI({
          kpiCode: discoveryKPI.kpiCode
        });
        break;
      case VIEW_FORMULA:
        setPopoverVisible(true);
        break;
      default:
        break;
    }
  };

  const renderMenu = () => (
    <Menu onClick={onClick}>
      {canEditKPIs ? (
        <>
          <MenuItem key={EDIT_KPI} icon={<Icon name={"edit"} />}>
            {editKPIText}
          </MenuItem>
          {canSeeKPILibrary && isCustomKPI({ discoveryKPI }) && (
            <MenuItem key={DELETE_KPI} icon={<Icon name={"delete"} />}>
              {deleteKPIText}
            </MenuItem>
          )}
          {canSeeKPILibrary && !isCustomKPI({ discoveryKPI }) && (
            <MenuItem key={REMOVE_FROM_LIST} icon={<Icon name={"close"} />}>
              {removeFromListText}
            </MenuItem>
          )}
        </>
      ) : null}
      {hasFormulaDescription ? (
        discoveryKPI.selected ? (
          <MenuItem key={VIEW_FORMULA} icon={<Icon name={"tachometer"} />}>
            {viewFormulaText}
          </MenuItem>
        ) : (
          <MenuItem
            key={VIEW_FORMULA}
            icon={<Icon name={"tachometer"} />}
            disabled
          >
            <Tooltip title={viewFormulaDisabled} placement={"right"}>
              {viewFormulaText}
            </Tooltip>
          </MenuItem>
        )
      ) : null}
    </Menu>
  );

  return canEditKPIs || hasFormulaDescription ? (
    <Popover
      placement={isMobile ? "bottomRight" : "bottomLeft"}
      trigger={""}
      content={
        <FormulaPanel
          estimationScenario={EstimationScenarioTypes.EXPECTED}
          discoveryKPI={discoveryKPI}
          visible={popoverVisible}
          onClose={() => setPopoverVisible(false)}
        />
      }
      open={popoverVisible}
      onOpenChange={setPopoverVisible}
    >
      <Dropdown
        overlay={renderMenu()}
        placement={"bottomLeft"}
        trigger={["click"]}
      >
        <NewButton
          type={"iconSecondary"}
          tooltip={moreActionsText}
          data-cy={"kpi-card-dropdown"}
          className={"up-kpi-card-dropdown"}
        >
          <Icon name={"dropdown"} />
        </NewButton>
      </Dropdown>
    </Popover>
  ) : null;
};

KPICardDropdown.propTypes = {
  discoveryKPI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  canEditKPIs: hasPermission(state, Permissions.EDIT_KPIS),
  canSeeKPILibrary: hasPermission(state, Permissions.SEE_KPI_LIBRARY)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startEditingKPI,
      startCreatingKPI,
      startDeletingKPI,
      removeDiscoveryKPIFromLibrary,
      getDiscoveryKPILibrary
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  KPICardDropdown
);
