import redirectToOAuthLogin from "../../utils/redirect-to-oauth-login";
import {
  isAuthenticated,
  selectRequestData,
  selectRequestState
} from "../../store/reducers";
import { selectAuthConfiguration } from "../../store/selectors/config";
import actionType from "../../store/actionTypes";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useString as s } from "../../components/StringProvider";
import { useMobileMediaQuery } from "../../components/Responsive";
import { Button, Divider, Space } from "antd";
import { themeProp } from "../../theme";
import styled from "styled-components";
import Color from "color";
import { ReactComponent as SalesforceIcon } from "../../assets/Salesforce.svg";
import { ReactComponent as OktaIcon } from "../../assets/Okta.svg";
import { ReactComponent as MicrosoftIcon } from "../../assets/Microsoft.svg";
import actionTypes from "../../store/actionTypes";

const ContinueWithSSO = ({ location, authConfiguration }) => {
  const isMobile = useMobileMediaQuery();
  const or = s("continue_with_salesforce.component.or", "or");

  const identityProviderName = authConfiguration
    ? authConfiguration?.oauth?.identityProvider
    : undefined;
  const salesforceTitle = s(
    "continue_with_sso.component.provider.salesforce",
    "Continue with Salesforce"
  );
  const oktaTitle = s(
    "continue_with_sso.component.provider.okta",
    "Continue with Okta"
  );
  const microsoftTitle = s(
    "continue_with_sso.component.provider.microsoft",
    "Continue with Microsoft"
  );

  const onIdentityProvider = () => {
    redirectToOAuthLogin({
      stateData: location,
      identityProvider: identityProviderName
    });
  };

  const renderIcon = () => {
    switch (identityProviderName) {
      case "salesforce":
        return <SalesforceIcon />;
      case "okta":
        return <OktaIcon />;
      case "azure":
        return <MicrosoftIcon />;
    }
  };

  const renderTitle = () => {
    switch (identityProviderName) {
      case "salesforce":
        return salesforceTitle;
      case "okta":
        return oktaTitle;
      case "azure":
        return microsoftTitle;
    }
  };

  return identityProviderName ? (
    <Space
      size={isMobile ? 16 : 32}
      direction={"vertical"}
      style={{ width: "100%" }}
    >
      <StyledButton
        onClick={onIdentityProvider}
        data-cy={"salesforce-login-button"}
        style={{ width: "100%" }}
      >
        <div>
          {renderIcon()}
          <span>{renderTitle()}</span>
        </div>
      </StyledButton>
      <StyledDivider>{or}</StyledDivider>
    </Space>
  ) : null;
};
const StyledDivider = styled(Divider)`
  margin: 0;
`;

const StyledButton = styled(Button)`
  height: 57px;
  border: 1px solid transparent;
  font-size: ${themeProp(`typography.button.fontSize`)};
  font-family: ${themeProp(`typography.button.fontFamily`)};
  font-weight: ${themeProp(`typography.button.fontWeight`)};
  line-height: ${themeProp(`typography.button.lineHeight`)};
  border-radius: 6px;
  padding: 6px 16px;
  color: ${themeProp(`components.newButton.primary.base`)};
  background-color: #009ddc;
  box-shadow: none;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  & svg {
    margin-right: 10px;
    height: 48px;
    max-height: 32px;
    max-width: 46px;
  }

  &:active:not([disabled]),
  &.sb-active:not([disabled]) {
    color: ${themeProp(`components.newButton.primary.base`)};
    background-color: ${Color("#009ddc").darken(0.4).hex()};
  }

  &:hover:not(:active):not(:focus):not([disabled]),
  &.sb-hover {
    color: ${themeProp(`components.newButton.primary.base`)};
    background-color: ${Color("#009ddc").darken(0.2).hex()};
  }

  &:focus:not(:active):not(.ant-dropdown-open):not(.open-comments-button-open) {
    color: ${themeProp(`components.newButton.primary.base`)};
    background-color: ${Color("#009ddc").darken(0.4).hex()};
  }

  &:focus-visible,
  &.sb-focus-visible {
    box-shadow: 0 0 0 4px ${themeProp(`palette.focus`)};
  }
`;

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    loadingState: selectRequestState(state, actionTypes.LOGIN_REQUEST),
    authConfiguration: selectAuthConfiguration(state),
    identityProviderRecord: selectRequestData(
      state,
      actionType.IDENTITY_PROVIDER_SUCCESS
    )
  };
};

export default compose(withRouter, connect(mapStateToProps))(ContinueWithSSO);
