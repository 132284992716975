import { isPhaseLessThanOrEqualTo, Phases } from "../../../utils/phases";

const headerMenuItemStatus = {
  COMPLETED: "completed",
  ACTIVE: "active",
  ON_DECK: "ondeck"
};

const setLinks = ({
  items,
  mandatoryQuestionsCompleted,
  questionsLink,
  roiLink
}) => {
  if (mandatoryQuestionsCompleted) {
    items[0].link = questionsLink;
    items[1].link = "/select-challenges";
    items[2].link = "/select-kpis";
    items[3].link = "/business-metrics";
    items[4].link = "/value-hypothesis";
    items[5].link = roiLink;
  } else {
    items[0].link = questionsLink;
  }
};

const getDiscoveryHeaderMenuItems = ({
  discovery,
  pathname,
  canSeeSelectChallenges,
  canSeeSelectKPIs,
  canSeeValueHypothesis,
  canSeeROIPage,
  isDiscoveryRegisterInterestComplete,
  canSeeLeadGeneration,
  canUpdateROIValues
}) => {
  const isRoiPageVisible = () => {
    if (!canUpdateROIValues && !discovery.roi) {
      return false;
    }

    return (
      canSeeROIPage &&
      (!discovery.maximumPhase ||
        isPhaseLessThanOrEqualTo(Phases.ROI, discovery.maximumPhase))
    );
  };

  const items = [
    {
      visible: true,
      phase: Phases.QUESTIONS,
      isSelected: false,
      disabled: false
    },
    {
      visible: canSeeSelectChallenges,
      phase: Phases.CHALLENGES_SOLVED,
      isSelected: false,
      disabled: false
    },
    {
      visible:
        canSeeSelectKPIs &&
        (!discovery.maximumPhase ||
          isPhaseLessThanOrEqualTo(
            Phases.KPIS_IMPACTED,
            discovery.maximumPhase
          )),
      phase: Phases.KPIS_IMPACTED,
      isSelected: false,
      disabled: false
    },
    {
      visible:
        canSeeValueHypothesis &&
        (!discovery.maximumPhase ||
          isPhaseLessThanOrEqualTo(Phases.METRICS, discovery.maximumPhase)),
      phase: Phases.METRICS,
      isSelected: false,
      disabled: false
    },
    {
      visible:
        canSeeValueHypothesis &&
        (!discovery.maximumPhase ||
          isPhaseLessThanOrEqualTo(
            Phases.VALUE_HYPOTHESIS,
            discovery.maximumPhase
          )),
      phase: Phases.VALUE_HYPOTHESIS,
      isSelected: false,
      disabled: canSeeLeadGeneration
        ? canSeeValueHypothesis && !isDiscoveryRegisterInterestComplete
        : false
    },
    {
      visible: isRoiPageVisible(),
      phase: Phases.ROI,
      isSelected: false,
      disabled: false
    }
  ];

  if (discovery && discovery.questions) {
    if (pathname.includes("questions")) {
      items[0].status = headerMenuItemStatus.ACTIVE;
      items[0].isSelected = true;
    } else if (pathname.includes("select-challenges")) {
      items[1].isSelected = true;
    } else if (pathname.includes("challenge-summary")) {
      items[0].status = headerMenuItemStatus.COMPLETED;
      items[1].status = headerMenuItemStatus.ON_DECK;
      items[1].isSelected = true;
    } else if (pathname.includes("select-kpis")) {
      items[0].status = headerMenuItemStatus.COMPLETED;
      items[1].status = headerMenuItemStatus.ACTIVE;
      items[2].isSelected = true;
    } else if (pathname.includes("kpi-summary")) {
      items[0].status = headerMenuItemStatus.COMPLETED;
      items[1].status = headerMenuItemStatus.COMPLETED;
      items[2].status = headerMenuItemStatus.ON_DECK;
      items[2].isSelected = true;
    } else if (pathname.includes("business-metrics")) {
      items[0].status = headerMenuItemStatus.COMPLETED;
      items[1].status = headerMenuItemStatus.COMPLETED;
      items[2].status = headerMenuItemStatus.COMPLETED;
      items[3].status = headerMenuItemStatus.ACTIVE;
      items[3].isSelected = true;
    } else if (pathname.includes("value-hypothesis")) {
      items[0].status = headerMenuItemStatus.COMPLETED;
      items[1].status = headerMenuItemStatus.COMPLETED;
      items[2].status = headerMenuItemStatus.COMPLETED;
      items[3].status = headerMenuItemStatus.COMPLETED;
      items[4].status = headerMenuItemStatus.ACTIVE;
      items[4].isSelected = true;
    } else if (pathname.includes("roi")) {
      items[0].status = headerMenuItemStatus.COMPLETED;
      items[1].status = headerMenuItemStatus.COMPLETED;
      items[2].status = headerMenuItemStatus.COMPLETED;
      items[3].status = headerMenuItemStatus.COMPLETED;
      items[4].status = headerMenuItemStatus.COMPLETED;
      items[5].status = headerMenuItemStatus.ACTIVE;
      items[5].isSelected = true;
    }
  }

  const getQuestionsLink = () => {
    let firstPositionOfActiveQuestion = undefined;
    for (let i = 0; i < discovery.questions.length; i++) {
      if (
        discovery.questions[i].active &&
        firstPositionOfActiveQuestion === undefined
      ) {
        firstPositionOfActiveQuestion = i;
      }
      if (
        discovery.questions[i].active &&
        !discovery.questions[i].hasOwnProperty("answer")
      ) {
        return `/questions/${i}`;
      }
    }
    return `/questions/${firstPositionOfActiveQuestion}`;
  };

  const getCheckpoint = () => {
    if (discovery.checkpoint.includes("/questions")) {
      return "/questions";
    } else {
      return discovery.checkpoint;
    }
  };

  const checkpoint = getCheckpoint();
  const questionsLink = getQuestionsLink();
  const hasAssumptions = discovery.hasOwnProperty("roi");
  const roiLink = hasAssumptions
    ? "/roi?edit-assumptions=false"
    : "/roi?edit-assumptions=true";

  /*
    The user can re-answer the questions and move the state back
    to where some of the compulsory questions are not answered
    (depending how the MasterData is configured). So we check again
    the pathname and not just the checkpoint as in the next logic section.
   */
  if (pathname.includes("/questions/")) {
    setLinks({
      items,
      mandatoryQuestionsCompleted: discovery.mandatoryQuestionsCompleted,
      questionsLink,
      roiLink
    });

    if (!discovery.mandatoryQuestionsCompleted) {
      return items;
    }
  }

  switch (checkpoint) {
    case "/questions":
      setLinks({
        items,
        mandatoryQuestionsCompleted: discovery.mandatoryQuestionsCompleted,
        questionsLink,
        roiLink
      });

      break;
    case "/start-discovery":
      items[0].link = questionsLink;
      break;
    default:
      items[0].link = questionsLink;
      items[1].link = "/select-challenges";
      items[2].link = "/select-kpis";
      items[3].link = "/business-metrics";
      items[4].link = "/value-hypothesis";
      items[5].link = roiLink;
      break;
  }

  return items;
};

export { getDiscoveryHeaderMenuItems, headerMenuItemStatus as status };
