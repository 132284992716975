import { useCallback, useEffect, useRef, useState } from "react";
import Input from "../../../Input";
import Dropdown from "../../../Dropdown";
import PropTypes from "prop-types";
import SourceTypes from "../../../../utils/source-types";
import _ from "lodash";
import Icon from "../../../Icon";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import LoadingState from "../../../../utils/loading-state";
import DropdownMenu from "./DropdownMenu";

const InputWithDropdown = ({
  items,
  source,
  onUseId,
  onUseName,
  value,
  onChange,
  onLoadItems,
  crmGroupName,
  customGroupName,
  lastUpdated,
  suggestion,
  disabled,
  loadingState,
  ...props
}) => {
  const [textValue, setTextValue] = useState("");
  const [width, setWidth] = useState(undefined);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [sourceType, setSourceType] = useState(source);
  const [focused, setFocused] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const inputRef = useRef();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!focused && isInitialized) {
      setDropdownVisible(false);
    }
    setInitialized(true);
  }, [focused]);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      setWidth(inputRef.current.clientWidth);
    }
  }, [inputRef]);

  useEffect(() => {
    if (!dropdownVisible && loadingState === LoadingState.COMPLETED) {
      handleBlur(dropdownVisible);
    }
  }, [loadingState, dropdownVisible]);

  useEffect(() => {
    if (search) {
      onLoadItems(search);
    }
  }, [search]);

  const onTextChange = (e) => {
    setTextValue(e.target.value);
    setDropdownVisible(true);
    updateSearch(e.target.value);
  };

  const updateSearch = (s) => {
    if (s && s.length >= 3 && loadingState !== LoadingState.IN_PROGRESS) {
      setSearch(s.substring(0, 3));
    }
  };

  const onVisibleChange = (v) => {
    setDropdownVisible(v);
  };

  const debounceSetFocused = useCallback(
    _.debounce(setFocused, 100, {
      trailing: true
    }),
    []
  );

  const onInputFocus = () => {
    setDropdownVisible(true);
    debounceSetFocused(true);
  };

  const onFocus = () => {
    debounceSetFocused(true);
  };

  const onBlur = () => {
    debounceSetFocused(false);
  };

  const onPressEnter = (e) => {
    e.preventDefault();
    //handleBlur();
    setDropdownVisible(false);
  };

  const handleBlur = (dropdownVisible = false) => {
    if (!textValue) {
      setDropdownVisible(false);
      setSourceType(undefined);
      onUseName("");
      return;
    }

    const item = items.find(
      ({ name }) => textValue.toLowerCase() === name.toLowerCase()
    );

    if (item) {
      onUseId(item.id, item.name, item.source);
      setSourceType(SourceTypes.CRM_MANAGED);
    } else {
      onUseName(textValue);
      setSourceType(SourceTypes.MANUALLY_CREATED);
    }
    setDropdownVisible(dropdownVisible);
  };

  const onClickMenuItem = ({ key }) => {
    switch (key) {
      case "useName":
        onUseName(textValue);
        setSourceType(SourceTypes.MANUALLY_CREATED);
        break;
      case "keepName":
        setTextValue(value);
        updateSearch(value);
        break;
      default:
        const item = items.find(({ id }) => id === key);

        if (item) {
          onUseId(item.id, item.name, item.source);
          setSourceType(SourceTypes.CRM_MANAGED);
          setTextValue(item.name);
        }
        break;
    }
    setDropdownVisible(false);
  };

  const getIcon = () => {
    switch (sourceType) {
      case SourceTypes.CRM_MANAGED:
        return <Icon colour={"success"} name={"check"} />;
      case SourceTypes.MANUALLY_CREATED:
        return <Icon colour={"primary"} name={"alert"} />;
      default:
        return undefined;
    }
  };

  return (
    <Container ref={inputRef}>
      <Dropdown
        trigger={[]}
        overlay={
          <DropdownMenu
            items={items}
            loadingState={loadingState}
            value={value}
            textValue={textValue}
            lastUpdated={lastUpdated}
            crmGroupName={crmGroupName}
            customGroupName={customGroupName}
            onFocus={onFocus}
            onBlur={onBlur}
            onClickMenuItem={onClickMenuItem}
            width={width}
            {...props}
          />
        }
        open={dropdownVisible}
        onOpenChange={onVisibleChange}
        getPopupContainer={() => inputRef.current}
        disabled={disabled}
      >
        <Input
          value={textValue}
          onChange={onTextChange}
          onFocus={onInputFocus}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
          autoComplete={"off"}
          addonAfter={getIcon()}
          disabled={disabled}
          {...props}
        />
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  & .ant-input-group {
    margin-top: 12px;
  }

  & .ant-input-group input {
    font-size: ${themeProp("typography.body.fontSize")};
  }

  & .ant-input-group-addon {
    background: none;
    border: none;
  }

  & .ant-dropdown-menu-item-group-title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100%;
  }

  & .ant-dropdown-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
`;

InputWithDropdown.propTypes = {
  onUseId: PropTypes.func.isRequired,
  onUseName: PropTypes.func.isRequired,
  onLoadItems: PropTypes.func.isRequired,
  loadingState: PropTypes.oneOf(LoadingState.ALL),
  items: PropTypes.array.isRequired,
  crmGroupName: PropTypes.string,
  customGroupName: PropTypes.string,
  lastUpdated: PropTypes.string,
  suggestion: PropTypes.string,
  source: PropTypes.oneOf(SourceTypes.ALL)
};

export default InputWithDropdown;
