import React from "react";
import CheckBoxCard from "./CheckBoxCard";
import PropTypes from "prop-types";
import styled from "styled-components";
import QuestionVisualizations from "../../../utils/question-visualizations";
import { Col, Row, Space } from "antd";
import { useMobileOrTabletMediaQuery } from "../../../components/Responsive";
import {
  selectDiscoveryQuestion,
  selectQuestion
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import CheckboxGroup from "../../../components/CheckboxGroup";

const removeDispatchProperty = (props) => {
  const newProps = { ...props };

  delete newProps.dispatch;
  return newProps;
};

const CheckBoxes = ({
  questionNumber,
  question,
  discoveryQuestion,
  width,
  editable,
  columnCount,
  ...props
}) => {
  const isMobileOrTablet = useMobileOrTabletMediaQuery();
  const filteredOptions = question.options.filter(
    (option) => !discoveryQuestion.inactiveOptions.includes(option.value)
  );

  const OneColumnCheckBoxes = () => (
    <CheckBoxContainer
      width={width}
      columnCount={columnCount}
      isMobileOrTablet={isMobileOrTablet}
    >
      <FullWidthSpace direction={"vertical"} size={isMobileOrTablet ? 8 : 16}>
        {filteredOptions.map((item, index) => (
          <CheckBoxCard
            key={index}
            questionNumber={questionNumber}
            style={{
              padding: "18px 26px",
              alignItems: "center",
              display: "flex"
            }}
            text={item.label}
            editable={editable}
            value={item.value}
          />
        ))}
      </FullWidthSpace>
    </CheckBoxContainer>
  );

  if (isMobileOrTablet) {
    return (
      <CheckboxGroup {...removeDispatchProperty(props)}>
        <OneColumnCheckBoxes />
      </CheckboxGroup>
    );
  }
  return question.questionVisualization ===
    QuestionVisualizations.ONE_COLUMN_CHECK_BOXES ? (
    <CheckboxGroup {...removeDispatchProperty(props)}>
      <OneColumnCheckBoxes />
    </CheckboxGroup>
  ) : (
    <CheckboxGroup {...removeDispatchProperty(props)}>
      <Row gutter={[24, 8]} style={{ marginTop: 17, marginBottom: 19 }}>
        {filteredOptions.map((item, index) => (
          <Col span={12} key={index}>
            <CheckBoxCard
              questionNumber={questionNumber}
              style={{ maxWidth: 320, display: "flex", alignItems: "center" }}
              text={item.label}
              editable={editable}
              value={item.value}
            />
          </Col>
        ))}
      </Row>
    </CheckboxGroup>
  );
};

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  margin-top: 17px;
  margin-bottom: 19px;
  max-width: ${(props) => {
    if (props.isMobileOrTablet) {
      return "100%";
    }

    return props.width === "wide" ? "560px;" : "400px;";
  }}
  min-width: ${(props) => {
    if (props.isMobileOrTablet) {
      return "100%";
    }

    return props.width === "wide" ? "560px;" : "400px;";
  }}
  column-count: ${({ columnCount }) => columnCount};
  column-gap: 28px;
  column-width: ${({ columnCount }) => (columnCount === 2 ? "50%" : "100%")};
  width: 100%;
`;

CheckBoxes.propTypes = {
  width: PropTypes.oneOf(["normal", "wide"]).isRequired,
  editable: PropTypes.bool.isRequired,
  questionNumber: PropTypes.number.isRequired
};

CheckBoxes.defaultProps = {
  width: "normal"
};

const mapStateToProps = (state, props) => {
  const discoveryQuestion = selectDiscoveryQuestion(
    state,
    parseInt(props.questionNumber)
  );

  return {
    question: selectQuestion(state, discoveryQuestion.questionCode),
    discoveryQuestion
  };
};

export default compose(connect(mapStateToProps))(CheckBoxes);
