import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import PoweredByCuvama from "../PoweredByCuvama";
import { useMobileMediaQuery } from "../Responsive";

const MobilePageCentredLayout = ({ children, image, ...props }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <Container {...props} isMobile={isMobile}>
      <ContentContainer>
        {image && <MobileImage image={image} />}
        {children}
        <PoweredByCuvama />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  max-width: 100%;
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const MobileImage = styled.div`
  width: 100%;
  ${({ image }) => (image ? `background-image: url(${image});` : null)}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  height: 300px;

  img {
    min-width: 100%;
    min-height: 100%;
  }
`;

MobilePageCentredLayout.propTypes = {
  image: PropTypes.string,
  hasHeader: PropTypes.bool
};

export default MobilePageCentredLayout;
