import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { getAutoconfiguration } from "../../../../store/actions/autoconfigurations";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import useLoadingState from "../../../../utils/use-loading-state";
import { useString as s } from "../../../../components/StringProvider";
import { message } from "antd";
import AutoconfigurationQuestions from "./AutoconfigurationQuestions";
import AutoconfigurationCategories from "./AutoconfigurationCategories";
import AutoconfigurationChallenges from "./AutoconfigurationChallenges";
import AutoconfigurationKPIs from "./AutoconfigurationKPIs";
import AutoconfigurationMappings from "./AutoconfigurationMappings";

const AutoconfigurationWizardPanel = ({
  autoconfigurationId,
  getAutoconfiguration,
  loadingState
}) => {
  const error = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.messages.error",
    "Failed to load autoconfiguration"
  );
  const [ready, setReady] = useState(false);

  useEffect(() => {
    getAutoconfiguration({ autoconfigurationId });
  }, [autoconfigurationId]);

  useLoadingState(
    loadingState,
    () => {
      setReady(true);
    },
    () => {
      message.error(error);
    }
  );

  return (
    ready && (
      <Switch>
        <Route
          path="/organizations/autoconfigurations/:autoconfigurationId/questions"
          exact
          component={AutoconfigurationQuestions}
        />
        <Route
          path="/organizations/autoconfigurations/:autoconfigurationId/categories"
          exact
          component={AutoconfigurationCategories}
        />
        <Route
          path="/organizations/autoconfigurations/:autoconfigurationId/challenges"
          exact
          component={AutoconfigurationChallenges}
        />
        <Route
          path="/organizations/autoconfigurations/:autoconfigurationId/kpis"
          exact
          component={AutoconfigurationKPIs}
        />
        <Route
          path="/organizations/autoconfigurations/:autoconfigurationId/mappings"
          exact
          component={AutoconfigurationMappings}
        />
        <Redirect
          from={""}
          to={
            "/organizations/autoconfigurations/:autoconfigurationId/questions"
          }
          exact
        />
      </Switch>
    )
  );
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_AUTOCONFIGURATION_REQUEST
  ),
  autoconfiguration: selectRequestData(
    state,
    actionTypes.GET_AUTOCONFIGURATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAutoconfiguration
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AutoconfigurationWizardPanel);
