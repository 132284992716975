import React from "react";
import styled from "styled-components";
import Text from "./Text";
import TooltipIcon from "./TooltipIcon";
import PropTypes from "prop-types";
import Heading from "./Heading";
import { themeProp } from "../theme";

const Metric = ({
  label,
  size,
  active,
  printMode,
  children,
  help,
  tooltipColor,
  ...props
}) => (
  <MetricContainer active={active || printMode}>
    {children && (
      <MetricValue size={size} {...props} id="metric-value">
        {children}
      </MetricValue>
    )}

    <MetricLabelContainer>
      <MetricLabel printMode={printMode} id="metric-label">
        {label}
      </MetricLabel>
      <TooltipIcon title={help} color={tooltipColor} />
    </MetricLabelContainer>
  </MetricContainer>
);

const MetricValue = styled(Heading).attrs((props) => ({
  level: props.size
}))`
  color: inherit;
  margin-bottom: 0;
  max-height: ${(props) => themeProp(`typography.${props.size}.lineHeight`)};
  overflow: hidden;

  & > * {
    font-size: ${(props) => themeProp(`typography.${props.size}.fontSize`)};

    & > * {
      font-size: ${(props) => themeProp(`typography.${props.size}.fontSize`)};
    }
  }
`;

const MetricLabelContainer = styled.div`
  line-height: 18px;
`;

const MetricLabel = styled(Text).attrs((props) => ({
  variant: props.printMode ? "body" : "smallBody"
}))`
  color: inherit;
  margin-right: 4px;
`;

const MetricContainer = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }
`;

Metric.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  active: PropTypes.bool,
  help: PropTypes.string,
  tooltipColor: PropTypes.string,
  printMode: PropTypes.bool
};

Metric.defaultProps = {
  size: "h4"
};

export default Metric;
