import { NumberInput } from "../../Input";
import styled from "styled-components";
import { themeProp } from "../../../theme";

const KPINumberInput = styled(NumberInput)`
  & .ant-input-number-input {
    height: unset;
    font-size: ${themeProp("typography.body.fontSize")};
    font-weight: ${themeProp("typography.body.fontWeight")};
    font-family: ${themeProp("typography.body.fontFamily")};
    line-height: ${themeProp("typography.body.lineHeight")};
    width: 80px;
    max-width: 80px;
    padding: 4px;
  }

  &.ant-input-number {
    width: auto;
  }
`;

export default KPINumberInput;
