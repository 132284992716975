import React from "react";
import PropTypes from "prop-types";
import Modal from "../../Modal";
import styled from "styled-components";
import ChatLayout from "./ChatLayout";
import ChatProvider from "./ChatProvider";

const AIDiscoveryModal = ({ onClose, ...restProps }) => {
  return (
    <ChatProvider {...restProps}>
      <StyledModal
        open={true}
        width={980}
        data-cy={"ai-discovery-modal"}
        showCloseIcon={false}
      >
        <ChatLayout onClose={onClose} />
      </StyledModal>
    </ChatProvider>
  );
};

AIDiscoveryModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

const StyledModal = styled(Modal)`
  border-radius: 6px;

  .ant-modal-content {
    padding: 0;
  }
`;

export default AIDiscoveryModal;
