import { useString as s } from "../../../../components/StringProvider";
import { Text } from "../../../../components";
import Heading from "../../../../components/Heading";
import { Space } from "antd";
import styled from "styled-components";
import Icon from "../../../../components/Icon";

const GenerateError = ({}) => {
  const header = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.error.header",
    "Oh no, this is awkward! Master data generation has failed."
  );
  const subheader = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.error.subheader",
    'Please click "Regenerate Master Data" below to try again.'
  );

  return (
    <Container>
      <Space direction={"vertical"} size={"large"} align={"center"}>
        <Icon name={"error500"} size={"large"} colour={"gray4"} />
        <Heading level={"h3"} color={"gray4"}>
          {header}
        </Heading>
        <Text color={"gray4"}>{subheader}</Text>
      </Space>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default GenerateError;
