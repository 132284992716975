import types from "../actionTypes";
import integrationsService from "../../services/integrations.service";

export const setIntegration =
  ({ name, baseUrl, clientId, clientSecret }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_INTEGRATION_REQUEST
    });

    return integrationsService
      .setIntegration({ name, baseUrl, clientId, clientSecret })
      .then((payload) => {
        dispatch({
          type: types.SET_INTEGRATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_INTEGRATION_FAILURE,
          error
        });
      });
  };

export const getIntegration =
  ({ name }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_INTEGRATION_REQUEST
    });

    return integrationsService
      .getIntegration({ name })
      .then((payload) => {
        dispatch({
          type: types.GET_INTEGRATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_INTEGRATION_FAILURE,
          error
        });
      });
  };

export const deleteIntegration =
  ({ name }) =>
  (dispatch) => {
    dispatch({
      type: types.DELETE_INTEGRATION_REQUEST
    });

    return integrationsService
      .deleteIntegration({ name })
      .then((payload) => {
        dispatch({
          type: types.DELETE_INTEGRATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_INTEGRATION_FAILURE,
          error
        });
      });
  };

export const retrieveIntegrationToken =
  ({ name, code, redirect_uri }) =>
  (dispatch) => {
    dispatch({
      type: types.RETRIEVE_INTEGRATION_TOKEN_REQUEST
    });

    return integrationsService
      .retrieveIntegrationToken({ name, code, redirect_uri })
      .then((payload) => {
        dispatch({
          type: types.RETRIEVE_INTEGRATION_TOKEN_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.RETRIEVE_INTEGRATION_TOKEN_FAILURE,
          error
        });
      });
  };
