import Select, { Option } from "../Select";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectAllCategories } from "../../store/reducers";

const SelectCategory = ({ categories, dispatch, ...props }) => {
  return (
    <Select allowClear {...props}>
      {categories.map((option, index) => (
        <Option key={index} value={option.code}>
          <span>{option.name}</span>
        </Option>
      ))}
    </Select>
  );
};

const mapStateToProps = (state) => ({
  categories: selectAllCategories(state)
});

export default compose(connect(mapStateToProps))(SelectCategory);
