import { Text } from "../../../../components";
import Form, { FormItem } from "../../../../components/Form";
import Radio, { RadioGroup } from "../../../../components/Radio";
import { Space } from "antd";
import TooltipIcon from "../../../../components/TooltipIcon";
import Input from "../../../../components/Input";
import React from "react";
import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";
import NewButton from "../../../../components/NewButton";
import styled from "styled-components";

const UploadForm = ({ onFinish, disabled }) => {
  const descriptionValidation = s(
    "manageConfiguration.page.updateConfiguration.description.validation",
    "Enter a description"
  );
  const descriptionPlaceholder = s(
    "manageConfiguration.page.updateConfiguration.description.placeholder",
    "Describe what are you going to change"
  );
  const updateTypeHeader = s(
    "manageConfiguration.page.updateConfiguration.updateType.header",
    "What type of update are you going to make?"
  );
  const updateTypeValidation = s(
    "manageConfiguration.page.updateConfiguration.updateType.validation",
    "Select an update type"
  );
  const majorLabel = s(
    "manageConfiguration.page.updateConfiguration.updateType.major.label",
    "New discoveries only"
  );
  const majorTooltip = s(
    "manageConfiguration.page.updateConfiguration.updateType.major.tooltip",
    "This will affect new discoveries only. Consider using this for major updates like deleting a KPI, changing the mapping between challenges & KPIs, etc."
  );
  const minorLabel = s(
    "manageConfiguration.page.updateConfiguration.updateType.minor.label",
    "Existing and new discoveries"
  );
  const minorTooltip = s(
    "manageConfiguration.page.updateConfiguration.updateType.minor.placeholder",
    "This will affect ALL discoveries. Consider using this for minor updates like wording, PPT templates, adding a new KPI, etc."
  );
  const uploadFile = s(
    "manageConfiguration.page.updateConfiguration.uploadFile",
    "Upload file"
  );

  return (
    <Form onFinish={onFinish}>
      <FormItem
        name="updateType"
        rules={[
          {
            required: true,
            message: updateTypeValidation,
            whitespace: true
          }
        ]}
      >
        <RadioGroup>
          <Space direction={"vertical"} size={8}>
            <Text variant={"body"} color={disabled ? "gray3" : undefined}>
              {updateTypeHeader}
            </Text>
            <Radio value={"minor"} disabled={disabled}>
              {minorLabel} <TooltipIcon title={minorTooltip} />
            </Radio>
            <Radio value={"major"} disabled={disabled}>
              {majorLabel} <TooltipIcon title={majorTooltip} />
            </Radio>
          </Space>
        </RadioGroup>
      </FormItem>
      <FormItem
        name="description"
        rules={[
          {
            required: true,
            message: descriptionValidation,
            whitespace: true
          }
        ]}
      >
        <Input block placeholder={descriptionPlaceholder} disabled={disabled} />
      </FormItem>
      <ButtonContainer>
        <NewButton type={"submit"} disabled={disabled}>
          {uploadFile}
        </NewButton>
      </ButtonContainer>
    </Form>
  );
};

const ButtonContainer = styled.div`
  margin-top: 16px;
`;

UploadForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default UploadForm;
