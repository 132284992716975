import { Space } from "antd";
import CategoryBadges from "../CategoryBadges";
import React from "react";
import PropType from "prop-types";
import KPIData from "./KPIData";
import AboutThisKPI from "../KPIModal/AboutThisKPI";

const MobileDetails = ({
  discoveryKPI,
  reportId,
  currency,
  editable,
  selected,
  onChange,
  onChangeImportance
}) => {
  return (
    <>
      <Space
        direction={"vertical"}
        size={9}
        style={{ paddingRight: 20, width: "100%" }}
      >
        <div>{discoveryKPI.definition.benefitValueDriverDescription}</div>
        <CategoryBadges
          definition={discoveryKPI.definition}
          showStatus={!reportId}
        />
        {selected ? (
          <KPIData
            discoveryKPI={discoveryKPI}
            currency={currency}
            isEditable={editable}
            onChange={onChange}
            onChangeImportance={onChangeImportance}
          />
        ) : null}
      </Space>

      {(discoveryKPI.definition.detailText ||
        discoveryKPI.relatedChallengeCodes.length) &&
      selected ? (
        <AboutThisKPI discoveryKPI={discoveryKPI} reportId={reportId} />
      ) : null}
    </>
  );
};

MobileDetails.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  editable: PropType.bool.isRequired,
  reportId: PropType.string,
  onChange: PropType.func,
  onChangeImportance: PropType.func
};

export default MobileDetails;
