import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../theme";
import Icon from "./Icon";
import Text from "./Text";
import NewButton from "./NewButton";

const InfoBox = ({ children, onClose, ...props }) => (
  <InfoBoxContainer {...props}>
    <InfoBoxWrapper>
      <InfoBoxMessage variant="small">{children}</InfoBoxMessage>
    </InfoBoxWrapper>
    <InfoBoxIcon>
      <NewButton type={"closeIcon"} size={"small"} onClick={onClose}>
        <Icon name={"close"} colour={"text"} />
      </NewButton>
    </InfoBoxIcon>
  </InfoBoxContainer>
);
InfoBox.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func
};
InfoBox.defaultProps = {
  children: null,
  onClose: () => {}
};

export default InfoBox;

const InfoBoxContainer = styled.div`
  background: ${themeProp("palette.highlight")};
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  width: 100%;
`;
const InfoBoxIcon = styled.div`
  color: ${themeProp("palette.secondary")};
  line-height: 20px;
`;
const InfoBoxWrapper = styled.div`
  flex: 1;
`;
const InfoBoxMessage = styled(Text)``;
