import TextBody from "../../../../components/TextBody";
import NewButton from "../../../../components/NewButton";
import { useString as s } from "../../../../components/StringProvider";
import ButtonGroup from "../../../../components/ButtonGroup";
import { Modal } from "../../../../components";

const UpdateConfigurationModal = ({ onConfirm, onClose }) => {
  return (
    <Modal
      title={s(
        "manageConfiguration.page.updateConfiguration.modal.title",
        "Update Configuration"
      )}
      open={true}
      onCancel={onClose}
    >
      <TextBody>
        {s(
          "manageConfiguration.page.updateConfiguration.modal.text",
          "Updating Organization Configuration can change the list of challenges, KPIs, required metrics, KPIs impact and ROI formulas."
        )}
      </TextBody>
      <div style={{ paddingTop: 24 }}>
        <ButtonGroup>
          <NewButton type={"primary"} onClick={onConfirm}>
            {s(
              "manageConfiguration.page.updateConfiguration.modal.ok",
              "Yes, Update"
            )}
          </NewButton>
          <NewButton type={"secondary"} onClick={onClose}>
            {s(
              "manageConfiguration.page.updateConfiguration.modal.cancel",
              "Don’t Update"
            )}
          </NewButton>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

export default UpdateConfigurationModal;
