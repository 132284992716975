import React, { useEffect, useState } from "react";
import { useTheme } from "../../components/ThemeProvider";
import { SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Form from "../../components/Form";
import PropTypes from "prop-types";
import {
  isAuthenticated,
  selectRequestData,
  selectRequestState
} from "../../store/reducers";
import CheckEmailForm from "./CheckEmailForm";
import { invitationCheckEmailStatus } from "../../store/actions/invitations";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import EmailStatusCodes from "../../utils/constants/email-status-codes";

const CheckEmail = ({
  invitation,
  history,
  isAuthenticated,
  invitationCheckEmailStatus,
  loadingState,
  emailStatus
}) => {
  const [form] = Form.useForm();
  const invitationCover = useTheme("assets.share_login_cover");
  const backupInvitationCover = useTheme("assets.reset_cover");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      history.push({
        pathname: `/discoveries/${invitation.discoveryId}/select-challenges`
      });
    }
  }, [isAuthenticated]);

  useLoadingState(
    loadingState,
    () => {
      switch (emailStatus.status) {
        case EmailStatusCodes.LOGIN:
          history.push({
            pathname: `/invitations/${invitation.code}/login`
          });
          break;

        case EmailStatusCodes.CONFIRM_ACCOUNT:
          history.push({
            pathname: `/invitations/${invitation.code}/confirm-account`
          });
          break;

        case EmailStatusCodes.VERIFY_ACCOUNT:
          history.push({
            pathname: `/invitations/${invitation.code}/verify-account`
          });
          break;
      }
    },
    () => {
      setLoading(false);
    },
    () => {
      setLoading(true);
    }
  );

  const onFinish = ({ email }) => {
    invitationCheckEmailStatus({ invitationCode: invitation.code, email });
  };

  return (
    <Page>
      <SplitPageLayout image={invitationCover || backupInvitationCover}>
        <CheckEmailForm
          form={form}
          onFinish={onFinish}
          invitation={invitation}
          loading={loading}
        />
      </SplitPageLayout>
    </Page>
  );
};

CheckEmail.propTypes = {
  invitation: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    loadingState: selectRequestState(
      state,
      actionTypes.INVITATION_CHECK_EMAIL_STATUS_REQUEST
    ),
    emailStatus: selectRequestData(
      state,
      actionTypes.INVITATION_CHECK_EMAIL_STATUS_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      invitationCheckEmailStatus
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CheckEmail);
