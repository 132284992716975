import { Checkbox as ANTCheckbox } from "antd";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { themeProp } from "../theme";
import CheckIcon from "../assets/Check.svg";

const sizeVariants = {
  small: css`
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
    }
  `,
  large: css`
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
    }
  `
};

const Checkbox = styled(ANTCheckbox).attrs((props) => ({}))`
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${themeProp("palette.secondary")};
    border-color: ${themeProp("palette.secondary")};
  }

  & .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${themeProp("palette.gray2")};
    border-color: ${themeProp("palette.gray2")};

    &::after {
      border-color: ${themeProp("palette.surface")};
    }
  }

  &.ant-checkbox {
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")};
    line-height: ${themeProp("typography.body.lineHeight")};
    font-weight: ${themeProp("typography.body.fontWeight")};
  }

  &:hover .ant-checkbox-inner {
    border-color: ${themeProp("palette.secondary")};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${themeProp("palette.secondary")};
  }

  .ant-checkbox-inner::after {
    display: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${themeProp("palette.secondary")};
    border-color: ${themeProp("palette.secondary")};
    background-image: url(${CheckIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
  }

  ${({ size }) => sizeVariants[size || "large"]}
`;

Checkbox.propTypes = {
  size: PropTypes.oneOf(["small", "large"])
};

Checkbox.defaultProps = {
  size: "large"
};

export default Checkbox;
