const AIChatConversationOptions = {
  CHALLENGES: "challenges",
  OUTCOMES: "outcomes",
  INPUTS: "inputs",
  VALUE_HYPOTHESIS: "value-hypothesis",
  DISCOVERY_NOTES: "discovery-notes",
  SHARE_ACCESS_LINK: "share-access-link",
  ROI: "roi"
};

export default AIChatConversationOptions;
