import types from "../actionTypes";
import aiService from "../../services/ai.service";

export const companyResearch =
  ({
    email,
    companyName,
    companyWebsite,
    productName,
    marketSegment,
    top5Competitors
  }) =>
  (dispatch) => {
    dispatch({
      type: types.COMPANY_RESEARCH_REQUEST,
      parameters: {
        email,
        companyName,
        companyWebsite,
        productName,
        marketSegment,
        top5Competitors
      }
    });

    return aiService
      .researchCompany({
        email,
        companyName,
        companyWebsite,
        productName,
        marketSegment,
        top5Competitors
      })
      .then((payload) => {
        dispatch({
          type: types.COMPANY_RESEARCH_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.COMPANY_RESEARCH_FAILURE,
          error
        });
      });
  };

export const getConversationPrompts = () => (dispatch) => {
  dispatch({
    type: types.GET_CONVERSATION_PROMPTS_REQUEST
  });

  return aiService
    .getConversationPrompts()
    .then((payload) => {
      dispatch({
        type: types.GET_CONVERSATION_PROMPTS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_CONVERSATION_PROMPTS_FAILURE,
        error
      });
    });
};

export const startConversation =
  ({ discoveryId, options, accessType, emails, prompt }) =>
  (dispatch) => {
    dispatch({
      type: types.START_CONVERSATION_REQUEST
    });

    return aiService
      .startConversation({ discoveryId, options, accessType, emails, prompt })
      .then((payload) => {
        dispatch({
          type: types.START_CONVERSATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.START_CONVERSATION_FAILURE,
          error
        });
      });
  };

export const getConversation =
  ({ conversationId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_CONVERSATION_REQUEST
    });

    return aiService
      .getConversation({ conversationId })
      .then((payload) => {
        dispatch({
          type: types.GET_CONVERSATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CONVERSATION_FAILURE,
          error
        });
      });
  };

export const continueConversation =
  ({ conversationId, prompt }) =>
  (dispatch) => {
    dispatch({
      type: types.CONTINUE_CONVERSATION_REQUEST
    });

    return aiService
      .continueConversation({ conversationId, prompt })
      .then((payload) => {
        dispatch({
          type: types.CONTINUE_CONVERSATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CONTINUE_CONVERSATION_FAILURE,
          error
        });
      });
  };

export const retryConversation =
  ({ conversationId }) =>
  (dispatch) => {
    dispatch({
      type: types.RETRY_CONVERSATION_REQUEST
    });

    return aiService
      .retryConversation({ conversationId })
      .then((payload) => {
        dispatch({
          type: types.RETRY_CONVERSATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.RETRY_CONVERSATION_FAILURE,
          error
        });
      });
  };

export const getAllConversations =
  ({ start, count, sort, order }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_ALL_CONVERSATIONS_REQUEST
    });

    return aiService
      .getAllConversations({ start, count, sort, order })
      .then((payload) => {
        dispatch({
          type: types.GET_ALL_CONVERSATIONS_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_CONVERSATIONS_FAILURE,
          error
        });
      });
  };
