import Icon from "../../Icon";
import Text from "../../Text";
import React from "react";
import PropType from "prop-types";
import styled from "styled-components";
import { useString as s } from "../../StringProvider";
import Heading from "../../Heading";
import RelatedChallengeDescription from "./RelatedChallengeDescription";

const RelatedChallenges = ({ discoveryKPI, disabled }) => {
  const headerText = s("discovery.kpi.card.section.title", "Challenges Solved");
  const noChallengesText = s(
    "discovery.kpi.card.challengesSolved.noChallenges.text",
    "No challenges related"
  );

  return (
    <div>
      <Heading level={"h5"} color={"gray3"}>
        {headerText}
      </Heading>
      <DescriptionList>
        {discoveryKPI.relatedChallengeCodes.length ? (
          discoveryKPI.relatedChallengeCodes.map((challengeCode, index) => (
            <DescriptionItem
              key={index}
              data-cy={`related-challenge-${challengeCode}`}
            >
              <Icon name="check" colour={disabled ? "gray3" : "success"} />
              <Text
                variant={"small"}
                color={disabled ? "gray3" : "text"}
                data-cy={"related-challenge-description"}
              >
                <RelatedChallengeDescription challengeCode={challengeCode} />
              </Text>
            </DescriptionItem>
          ))
        ) : (
          <Text variant={"small"} color={"gray3"}>
            {noChallengesText}
          </Text>
        )}
      </DescriptionList>
    </div>
  );
};

const DescriptionList = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;

  svg {
    margin-top: 6px;
    display: block;
    margin-right: 10px;
  }
`;

RelatedChallenges.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  disabled: PropType.bool
};

export default RelatedChallenges;
