import PropTypes from "prop-types";
import DescriptionList from "../../../../components/DescriptionList";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import { useContext } from "react";
import { SettingsContext } from "../../../../components/SettingsProvider";
import { useString as s } from "../../../../components/StringProvider";
import { formatConfigurationDetailDate } from "../../../../utils/formatting";
import Heading from "../../../../components/Heading";
import Table from "../../../../components/Table";
import DownloadFileIconButton from "./DownloadFileIconButton";
import Text from "../../../../components/Text";
import DownloadMasterDataButton from "./DownloadMasterDataButton";

const MultipleDetails = ({ log }) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const lastUpdated = s(
    "organization.page.configurationLogs.details.lastUpdated",
    "Last Updated"
  );
  const updatedBy = s(
    "organization.page.configurationLogs.details.updatedBy",
    "Updated By"
  );
  const uploadedFiles = s(
    "organization.page.configurationLogs.details.uploadedFiles",
    "Uploaded PPT Files"
  );
  const rejectionReason = s(
    "organization.page.configurationLogs.details.rejectionReason",
    "Reason for Rejecting"
  );
  const createdByDefault = s(
    "accessManagement.page.roles.createdBy.default",
    "Cuvama"
  );
  const name = s(
    "organization.page.configurationLogs.details.powerpoint.name",
    "Name"
  );
  const version = s(
    "organization.page.configurationLogs.details.powerpoint.version",
    "Version"
  );
  const mdModel = s(
    "organization.page.configurationLogs.details.mdModel",
    "MD Model"
  );

  const columns = [
    {
      title: name,
      dataIndex: "name",
      key: "name",
      className: "name",
      ellipsis: true
    },
    {
      title: version,
      dataIndex: "version",
      key: "version",
      className: "version",
      align: "right",
      width: "90px",
      render: (version) => `${version}.0`
    },
    {
      title: "",
      dataIndex: "fileId",
      key: "fileId",
      className: "download",
      width: "30px",
      render: (fileId) => <DownloadFileIconButton fileId={fileId} />
    }
  ];

  return (
    <Container>
      <div>
        <DescriptionList>
          <dt>{lastUpdated}</dt>
          <dd>
            {formatConfigurationDetailDate({ time: log.createdAt, locale })}
          </dd>
          <dt>{updatedBy}</dt>
          <dd>{log.username || createdByDefault}</dd>
          <dt>{mdModel}</dt>
          <dd>{log.templateVersion}</dd>
          {log.status === "promotionRejected" && <dt>{rejectionReason}</dt>}
        </DescriptionList>
        {log.status === "promotionRejected" ? (
          <Text>{log.rejectionReason}</Text>
        ) : (
          <Text>{log.description}</Text>
        )}
      </div>
      {log?.masterData?.fileId && (
        <DownloadMasterDataButton fileId={log.masterData.fileId} />
      )}
      <div>
        <Heading level={"h4"}>{uploadedFiles}</Heading>
        <StyledTable
          removeHeaderStyle={true}
          columns={columns}
          dataSource={(log.powerpointTemplates || []).map((item, index) => ({
            ...item,
            key: index
          }))}
          pagination={false}
          className={"small"}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & h4 {
    margin-bottom: 8px;
  }
`;

const StyledTable = styled(Table)`
  background-color: ${themeProp("palette.accent2")};
  border-radius: 4px;
  padding: 16px;

  & thead.ant-table-thead > tr > th.ant-table-cell::before {
    content: none !important;
  }

  & .ant-table-thead .ant-table-cell {
    font-family: ${themeProp("typography.small.fontFamily")};
    font-size: ${themeProp("typography.small.fontSize")} !important;
    font-weight: ${themeProp("typography.small.fontWeight")} !important;
    line-height: ${themeProp("typography.small.lineHeight")} !important;
    color: ${themeProp("palette.gray4")} !important;
    padding: 0 16px 4px 0;
    background-color: ${themeProp("palette.accent2")} !important;
    border: none;
  }

  & .ant-table-tbody .ant-table-cell {
    font-family: ${themeProp("typography.small.fontFamily")};
    font-size: ${themeProp("typography.small.fontSize")} !important;
    font-weight: ${themeProp("typography.small.fontWeight")} !important;
    line-height: ${themeProp("typography.small.lineHeight")} !important;
    padding: 4px 16px 4px 0;
    background-color: ${themeProp("palette.accent2")};
    border: none;
    vertical-align: baseline;
  }

  & .ant-table-tbody .ant-table-cell.name {
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")} !important;
    font-weight: ${themeProp("typography.body.fontWeight")} !important;
    line-height: ${themeProp("typography.body.lineHeight")} !important;
  }
`;

MultipleDetails.propTypes = {
  log: PropTypes.object.isRequired
};

export default MultipleDetails;
