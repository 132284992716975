import styled from "styled-components";
import Heading from "../../Heading";
import { useString as s } from "../../StringProvider";
import TooltipIcon from "../../TooltipIcon";
import PropType from "prop-types";
import Text from "../../Text";
import { useMobileMediaQuery } from "../../Responsive";
import Tooltip from "../../Tooltip";

const ImpactTBD = ({ kpi, disabled }) => {
  const isMobile = useMobileMediaQuery();
  const tooltipText = s(
    "discovery.kpi.card.impact.tbd.tooltip",
    "The Impact estimate will be based on answers on the Metrics page and then shown on the Value Hypothesis page"
  );
  const tbdText = s("discovery.kpi.card.impact.tbd.text", "TBD");

  return (
    <Container
      type={kpi.definition.type}
      className={isMobile ? "mobile" : undefined}
    >
      <Heading level={isMobile ? "h5Small" : "h5"} color={"gray3"}>
        {s("discovery.kpi.card.impact.title", "Impact for You")}
      </Heading>

      <StyledText
        variant={isMobile ? "bMediumMobile" : "h4"}
        color={disabled ? "gray3" : undefined}
      >
        <Tooltip title={kpi?.definition?.impactPercentageFormulaDescription}>
          {tbdText}
        </Tooltip>
        <TooltipIcon title={tooltipText} disabled={disabled} />
      </StyledText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;

  &.mobile {
    margin-bottom: 0px;
  }
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

ImpactTBD.propTypes = {
  kpi: PropType.object.isRequired,
  disabled: PropType.bool
};

export default ImpactTBD;
