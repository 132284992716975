import OnlyIfUserCanAccessDiscoveryPath from "../../Pages/Discovery/components/OnlyIfUserCanAccessDiscoveryPath";
import React from "react";
import { useString as s } from "../StringProvider";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import NewButton from "../NewButton";
import {
  hasPermission,
  isDiscoveryRegisterInterestComplete,
  selectDiscoveryId
} from "../../store/reducers";
import { connect } from "react-redux";
import RegisterInterestButton from "./RegisterInterestButton";
import { getCheckpointForFormPlacement } from "../../Pages/Discovery/RegisterInterestPrompt";
import Permissions from "../../utils/permissions";
import { selectRegisterInterestFormPlacement } from "../../store/selectors/config";

const GoToKPISummaryButton = ({
  history,
  discoveryId,
  canSeeLeadGeneration,
  formPlacement,
  isRegistered
}) => {
  const reviewAndShare = s("discovery.kpi.page.button2", "Review summary");
  const isRegisterInterestButton =
    canSeeLeadGeneration &&
    getCheckpointForFormPlacement(formPlacement) === "/select-kpis" &&
    !isRegistered;

  const onClick = () => {
    history.push({ pathname: `/discoveries/${discoveryId}/kpi-summary` });
  };

  return isRegisterInterestButton ? (
    <OnlyIfUserCanAccessDiscoveryPath path={"kpi-summary"}>
      <RegisterInterestButton onClick={onClick} />
    </OnlyIfUserCanAccessDiscoveryPath>
  ) : (
    <OnlyIfUserCanAccessDiscoveryPath path={"kpi-summary"}>
      <NewButton
        onClick={onClick}
        type={"alternate"}
        data-cy={"review-and-share-button"}
      >
        {reviewAndShare}
      </NewButton>
    </OnlyIfUserCanAccessDiscoveryPath>
  );
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  isRegistered: isDiscoveryRegisterInterestComplete(state),
  formPlacement: selectRegisterInterestFormPlacement(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(GoToKPISummaryButton);
