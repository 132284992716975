import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import App from "./Pages/App";
import "./index.css";
import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import configService from "./services/config.service";
import pusherService from "./services/pusher.service";
import types from "./store/actionTypes";
import StringProvider from "./components/StringProvider";
import ThemeProvider from "./components/ThemeProvider";
import cognitoStorageService from "./services/cognito-storage.service";
import loginStateService from "./services/login-state.service";
import { Userpilot } from "userpilot";
import adaptAmplifyConfig from "./adapt-amplify-config";

const initializeClarity = (clarityId) => {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", clarityId);
};

configService
  .getConfig()
  .then((config) => {
    if (config?.tracking?.userPilotAppToken && !window.Cypress) {
      console.log("Initialize Userpilot");
      Userpilot.initialize(config?.tracking?.userPilotAppToken);
    }

    if (config?.tracking?.clarityId && !window.Cypress) {
      console.log("Initialize Clarity");
      initializeClarity(config?.tracking?.clarityId);
    }

    store.dispatch({
      type: types.CONFIG_SUCCESS,
      payload: config
    });

    const cookieStorage = cognitoStorageService.configure({
      ...config.auth.cookieStorage,
      debug: false
    });

    const currentURL = new URL(window.location.href);
    const state = currentURL.searchParams.get("state");
    const identityProvider = currentURL.searchParams.get("identity_provider");
    const errorDescription = currentURL.searchParams.get("error_description");

    if (state) {
      store.dispatch({
        type: types.LOGIN_STATE_SUCCESS,
        payload: loginStateService.getState({ state })
      });
    }

    if (identityProvider) {
      if (identityProvider === config?.auth?.oauth?.identityProvider) {
        store.dispatch({
          type: types.IDENTITY_PROVIDER_SUCCESS,
          payload: { identityProvider }
        });
      } else if (
        config?.auth?.oauth?.identityProvider &&
        identityProvider === "salesforce"
      ) {
        console.log(
          `Invalid identity provider ${identityProvider}. Using ${config?.auth?.oauth?.identityProvider} instead.`
        );
        store.dispatch({
          type: types.IDENTITY_PROVIDER_SUCCESS,
          payload: { identityProvider: config?.auth?.oauth?.identityProvider }
        });
      } else {
        console.log(`Invalid identity provider ${identityProvider}`);
      }
    }

    if (errorDescription) {
      store.dispatch({
        type: types.SSO_LOGIN_FAILURE,
        error: { errorDescription }
      });
    }

    if (
      currentURL.href.indexOf("salesforce-connection-callback") !== -1 ||
      currentURL.href.match(/^.*\/integrations\/[a-zA-Z0-9]+\/callback.*$/)
    ) {
      // VDT-6329: For integration callbacks, disable SSO configuration so that Amplify doesn't interfere
      console.log("Integration Callback");
      delete config.auth.oauth;
    }

    Amplify.configure({
      Auth: { ...adaptAmplifyConfig(config.auth) }
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);
    pusherService.setPusherConfiguration(config.pusher);
  })
  .catch((error) => {
    console.error("configuration retrieval failed", error);
    store.dispatch({
      type: types.INITIALIZATION_FAILURE,
      payload: { error: error || true }
    });
  });

ReactDOM.render(
  <Provider store={store}>
    <StringProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </StringProvider>
  </Provider>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store;
}
