import types from "../actionTypes";

const INITIAL_STATE = {
  challengeLibraryOpen: false,
  scrollToChallengeCode: null
};

const challengeLibrary = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CHALLENGE_LIBRARY_OPEN:
      return {
        ...state,
        challengeLibraryOpen: action.payload
      };

    case types.SET_SCROLL_TO_CHALLENGE_CODE:
      return {
        ...state,
        scrollToChallengeCode: action.payload.challengeCode
      };

    case types.CLEAR_SCROLL_TO_CHALLENGE_CODE:
      return {
        ...state,
        scrollToChallengeCode: null
      };

    default:
      return state;
  }
};

export default challengeLibrary;
export const selectChallengeLibraryOpen = (challengeLibraryData) =>
  challengeLibraryData.challengeLibraryOpen;
export const selectScrollToChallengeCode = (challengeLibraryData) =>
  challengeLibraryData.scrollToChallengeCode;
