import Text from "../../Text";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "../../Icon";

const RelatedKPI = ({ solutionCode, discoveryKPI }) => {
  const isRelated =
    discoveryKPI.definition.solutionMappings &&
    discoveryKPI.definition.solutionMappings.includes(solutionCode);
  return (
    <Container>
      <Icon name={"check"} colour={isRelated ? "surface" : "gray4"} />
      <Text color={"surface"} style={{ marginLeft: "13px" }}>
        {discoveryKPI.definition.name}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-right: 16px;
`;

RelatedKPI.propTypes = {
  solutionCode: PropTypes.string.isRequired,
  discoveryKPI: PropTypes.object.isRequired
};

export default RelatedKPI;
