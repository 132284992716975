import IntegrationNames from "../../../../utils/constants/integration-names";
import logsService from "../../../../services/errors.service";
import loginStateService from "../../../../services/login-state.service";
import getOAuthRedirectUri from "./get-oauth-redirect-uri";
import getSalesforceAuthenticationBaseUrl from "./get-salesforce-authentication-base-url";

const handleOAuthRedirect = ({
  baseUrl,
  clientId,
  name,
  salesforceIntegrationV2,
  salesforceSandbox
}) => {
  let url;
  const state = loginStateService.saveState({ hostname: window.location.host });
  const redirectUri = getOAuthRedirectUri({ name, salesforceIntegrationV2 });

  switch (name) {
    case IntegrationNames.HUBSPOT:
      url = new URL(`${baseUrl}/oauth/authorize`);

      url.searchParams.append("response_type", "code");
      url.searchParams.append("client_id", clientId);
      url.searchParams.append(
        "scope",
        "crm.objects.deals.read crm.objects.deals.write"
      );
      url.searchParams.append("state", state);
      url.searchParams.append("redirect_uri", redirectUri);
      break;

    case IntegrationNames.SALESFORCE:
      const salesforceAuthenticationBaseUrl =
        getSalesforceAuthenticationBaseUrl({
          baseUrl,
          salesforceIntegrationV2,
          salesforceSandbox
        });

      url = new URL(
        `${salesforceAuthenticationBaseUrl}/services/oauth2/authorize`
      );
      url.searchParams.append("response_type", "code");
      url.searchParams.append("client_id", clientId);
      url.searchParams.append("state", state);

      if (salesforceIntegrationV2) {
        url.searchParams.append("redirect_uri", redirectUri);
      } else {
        url.searchParams.append(
          "redirect_uri",
          `${window.location.origin}/organizations/general/salesforce-connection-callback`
        );
      }
      break;
  }

  logsService.logInfo({
    category: "integration-connection",
    data: `handleOAuthRedirect: ${url}`
  });

  window.location.href = url.toString();
};

export default handleOAuthRedirect;
