import httpService from "./http.service";

const companyResearch = ({
  email,
  companyName,
  companyWebsite,
  productName,
  marketSegment,
  top5Competitors
}) =>
  httpService
    .post(`/ai/companyResearch`, {
      email,
      companyName,
      companyWebsite,
      productName,
      marketSegment,
      top5Competitors
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getConversationPrompts = () =>
  httpService
    .get(`/ai/conversations/prompts`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const startConversation = ({
  discoveryId,
  options,
  accessType,
  emails,
  prompt
}) =>
  httpService
    .post(`/ai/conversations/startConversation`, {
      discoveryId,
      options,
      accessType,
      emails,
      prompt
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getConversation = ({ conversationId }) =>
  httpService
    .get(`/ai/conversations/${conversationId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const continueConversation = ({ conversationId, prompt }) =>
  httpService
    .post(`/ai/conversations/${conversationId}/continueConversation`, {
      prompt
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const retryConversation = ({ conversationId }) =>
  httpService
    .post(`/ai/conversations/${conversationId}/retry`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getAllConversations = ({ start, count, sort, order }) =>
  httpService
    .get(`/ai/conversations`, {}, { start, count, sort, order })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  researchCompany: companyResearch,
  getConversationPrompts,
  startConversation,
  getConversation,
  continueConversation,
  retryConversation,
  getAllConversations
};

export default methods;
