import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectSolution } from "../../../store/reducers";
import Badge from "../Badge";
import { themeProp } from "../../../theme";
import { useMobileMediaQuery } from "../../Responsive";

const SolutionBadge = ({ solutionCode, solution, disabled }) => {
  const isMobile = useMobileMediaQuery();
  return (
    <Badge
      bg={disabled ? themeProp("palette.gray2") : solution.backgroundColor}
      color={solution.color === "#FFFFFF" ? "surface" : solution.color}
      variant={isMobile ? "micro" : undefined}
      data-cy={`solution-badge-${solutionCode}`}
    >
      {solution.name}
    </Badge>
  );
};

SolutionBadge.propTypes = {
  solutionCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

const mapStateToProps = (state, props) => ({
  solution: selectSolution(state, props.solutionCode)
});

export default compose(connect(mapStateToProps))(SolutionBadge);
