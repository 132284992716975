import OriginalSelect, { Option } from "./Select";
import { getCountryCallingCode } from "react-phone-number-input";
import styled from "styled-components";
import Text from "./Text";
import { themeProp } from "../theme";

const SelectCountryCallingCode = ({
  options,
  iconComponent: Icon,
  ...props
}) => {
  return (
    <Select {...props} dropdownMatchSelectWidth={false}>
      {options.map((option) =>
        !option.divider ? (
          <Option key={option.value} value={option.value} label={option.label}>
            <Icon country={option.value} />
            <Text className={"PhoneCountryName"}>{option.label}</Text>
            <Text className={"PhoneCountryCode"}>
              +{getCountryCallingCode(option.value)}
            </Text>
          </Option>
        ) : null
      )}
    </Select>
  );
};

const Select = styled(OriginalSelect)`
  &.ant-select-single,
  &.ant-select-single .ant-select-selector {
    width: fit-content;
    max-width: fit-content;
    border: none !important;
  }

  & .PhoneInputCountryIcon--border {
    background-color: transparent;
    box-shadow: none;
  }

  & .ant-select-item .ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: left;

    & .PhoneInputCountryIcon {
      margin-right: 8px;
    }

    & .PhoneCountryName {
      flex-grow: 1;
      margin-right: 8px;
    }

    & .PhoneCountryCode {
      width: 40px;
      max-width: 40px;
      color: ${themeProp("palette.gray4")};
    }
  }

  & .ant-select-selector .ant-select-selection-item {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: left;

    & .PhoneCountryName {
      display: none;
    }
    & .PhoneInputCountryIcon {
      margin-top: 3px;
      margin-right: 8px;
      background: none;
    }
  }
`;

export default SelectCountryCallingCode;
