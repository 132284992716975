import NewButton from "../../../../components/NewButton";
import Icon from "../../../../components/Icon";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useString as s } from "../../../../components/StringProvider";
import { transformAndDownloadFile } from "../../../../store/actions/files";

const DownloadMasterDataButton = ({ fileId, transformAndDownloadFile }) => {
  const downloadLabel = s(
    "organization.page.currentConfiguration.masterData.downloadMD",
    "Download MD"
  );

  return (
    <NewButton
      type={"primaryDropdown"}
      onClick={() => {
        transformAndDownloadFile({
          fileId,
          transformationType: "latestMDSchema"
        });
      }}
    >
      {downloadLabel} <Icon name={"download"} />
    </NewButton>
  );
};

DownloadMasterDataButton.propTypes = {
  fileId: PropTypes.string.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      transformAndDownloadFile
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DownloadMasterDataButton
);
