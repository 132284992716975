import { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Slider from "../../Slider";
import {
  formatRangeNumber,
  getRangeNumberFormatter
} from "../../../utils/formatting";
import KPITypes from "../../../utils/kpi-types";
import { SettingsContext } from "../../SettingsProvider";
import Range from "../../Range";
import styled from "styled-components";
import MetricTypes from "../../../utils/metric-types";
import { useString as s } from "../../StringProvider";
import KPISliderValue from "./KPISliderValue";
import ReactDOM from "react-dom";

const getStep = ({ rangeMetricType }) => {
  switch (rangeMetricType) {
    case MetricTypes.NUMBER2DP:
      return 0.25;
    case MetricTypes.PERCENTAGE1DP:
      return 0.1;
  }

  return undefined;
};

const isWithinRange = (range, rangeMaxWidth) => {
  return Math.abs(range[0] - range[1]) <= rangeMaxWidth;
};

const isRangeDecreasing = (prevValue, newValue) => {
  const prevRange = Math.abs(prevValue[0] - prevValue[1]);
  const newRange = Math.abs(newValue[0] - newValue[1]);

  return newRange < prevRange;
};

const correctValue = (indexInFlight, anchorValue, rangeMaxWidth, reverse) => {
  return reverse
    ? indexInFlight
      ? anchorValue - rangeMaxWidth
      : anchorValue + rangeMaxWidth
    : indexInFlight
    ? anchorValue - rangeMaxWidth
    : anchorValue + rangeMaxWidth;
};

const KPISlider = ({ kpi, currency, disabled, onChange }) => {
  const containerRef = useRef(null);
  const [railNode, setRailNode] = useState(null);
  const isReversed =
    kpi.definition.reduceOrIncrease === "reduce" &&
    ![KPITypes.NEW_VALUE_DRIVEN].includes(kpi.definition.type);
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const marks = {};
  const options = {
    locale,
    currency
  };
  const valueFormatter = getRangeNumberFormatter({
    type: kpi.definition.rangeMetricType,
    options
  });
  const typicalRangeText = s(
    "discovery.kpi.card.slider.title",
    "Typical range"
  );
  const [range, setRange] = useState([]);
  const [prevValue, setPrevValue] = useState([]);
  const rangeMaxWidth = kpi.definition.rangeMaxWidth;

  useEffect(() => {
    if (kpi) {
      const newRange = isReversed
        ? [kpi.rangeMax, kpi.rangeMin]
        : [kpi.rangeMin, kpi.rangeMax];

      if (!range || range[0] !== newRange[0] || range[1] !== newRange[1]) {
        setPrevValue(newRange);
      }
    }
  }, []);

  useEffect(() => {
    const newRange = isReversed
      ? [kpi.rangeMax, kpi.rangeMin]
      : [kpi.rangeMin, kpi.rangeMax];

    if (!range || range[0] !== newRange[0] || range[1] !== newRange[1]) {
      setRange(newRange);
    }
  }, [kpi]);

  useEffect(() => {
    if (containerRef.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const sliderNode = ReactDOM.findDOMNode(containerRef.current);
      setRailNode(sliderNode.getElementsByClassName("ant-slider-rail")[0]);
    }
  }, [containerRef]);

  const handleChange = (newValue) => {
    if (
      !range ||
      !rangeMaxWidth ||
      isWithinRange(newValue, rangeMaxWidth) ||
      isRangeDecreasing(prevValue, newValue)
    ) {
      setPrevValue(newValue);
      setRange(newValue);
      return;
    }

    const indexInFlight = newValue[0] === prevValue[0] ? 1 : 0;
    const otherIndex = indexInFlight === 1 ? 0 : 1;
    const correctedValue = [0, 0];

    correctedValue[indexInFlight] = newValue[indexInFlight];
    correctedValue[otherIndex] = correctValue(
      indexInFlight,
      correctedValue[indexInFlight],
      rangeMaxWidth,
      isReversed
    );

    if (range[0] !== correctedValue[0] && range[1] !== correctedValue[1]) {
      setRange(correctedValue);
    }
  };

  marks[kpi.definition.rangeMin] = formatRangeNumber({
    value: kpi.definition.rangeMin,
    type: kpi.definition.rangeMetricType,
    options
  });

  marks[kpi.definition.rangeMax] = formatRangeNumber({
    value: kpi.definition.rangeMax,
    type: kpi.definition.rangeMetricType,
    options
  });

  if (kpi.definition.rangeLowMed) {
    marks[kpi.definition.rangeLowMed] = true;
  }

  if (kpi.definition.rangeMedHigh) {
    marks[kpi.definition.rangeMedHigh] = true;
  }

  const renderSliderValues = () => (
    <KPISliderValue
      node={railNode}
      disabled={disabled}
      range={range}
      reversed={isReversed}
      formatter={valueFormatter}
    />
  );

  const tooltipFormatter = (value) => {
    const absValue = Math.abs(value);
    const active = {
      low:
        absValue >= Math.abs(kpi.definition.rangeMin) &&
        absValue < Math.abs(kpi.definition.rangeLowMed),
      med:
        absValue >= Math.abs(kpi.definition.rangeLowMed) &&
        absValue <= Math.abs(kpi.definition.rangeMedHigh),
      high:
        absValue > Math.abs(kpi.definition.rangeMedHigh) &&
        absValue <= Math.abs(kpi.definition.rangeMax)
    };
    return (
      <TooltipContainer>
        {kpi.definition.lowText && (
          <TooltipItem active={active.low}>
            {kpi.definition.lowText}
          </TooltipItem>
        )}
        {kpi.definition.medText && (
          <TooltipItem active={active.med}>
            {kpi.definition.medText}
          </TooltipItem>
        )}
        {kpi.definition.highText && (
          <TooltipItem active={active.high}>
            {kpi.definition.highText}
          </TooltipItem>
        )}
        <TooltipItem style={{ textTransform: "uppercase" }}>
          {typicalRangeText}
          &nbsp;
          <Range
            first={kpi.definition.rangeLowMed}
            second={kpi.definition.rangeMedHigh}
            type={kpi.definition.rangeMetricType}
            currency={currency}
          />
        </TooltipItem>
      </TooltipContainer>
    );
  };

  return (
    <SliderContainer ref={containerRef}>
      <Slider
        reverse={isReversed}
        range={rangeMaxWidth ? { draggableTrack: true } : true}
        included={true}
        valueFormatter={valueFormatter}
        value={range}
        disabled={disabled}
        min={isReversed ? kpi.definition.rangeMax : kpi.definition.rangeMin}
        max={isReversed ? kpi.definition.rangeMin : kpi.definition.rangeMax}
        marks={marks}
        tooltip={{ formatter: !disabled ? tooltipFormatter : null }}
        step={getStep({ rangeMetricType: kpi.definition.rangeMetricType })}
        onChange={handleChange}
        onAfterChange={
          disabled
            ? undefined
            : (newRange) => {
                if (rangeMaxWidth && !isWithinRange(newRange, rangeMaxWidth)) {
                  return;
                }

                setPrevValue(newRange);
                onChange({
                  kpiCode: kpi.kpiCode,
                  changes: {
                    rangeMin: isReversed ? newRange[1] : newRange[0],
                    rangeMax: isReversed ? newRange[0] : newRange[1]
                  }
                });
              }
        }
      />
      {renderSliderValues()}
    </SliderContainer>
  );
};

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TooltipItem = styled.div`
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
`;

const SliderContainer = styled.div``;

KPISlider.propTypes = {
  kpi: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  rangeMaxWidth: PropTypes.number
};

export default KPISlider;
