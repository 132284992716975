import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { getConfigurationLog } from "../../../../store/actions/configurations";
import { connect } from "react-redux";
import useLoadingState from "../../../../utils/use-loading-state";
import { useEffect } from "react";
import Heading from "../../../../components/Heading";
import ConfigurationLogType from "./ConfigurationLogType";
import Badge from "../../../../components/Discovery/Badge";
import ConfigurationLogStatus from "./ConfigurationLogStatus";
import ConfigurationLogTypes from "../../../../utils/configuration-log-types";
import PowerpointTemplatesDetails from "./PowerpointTemplatesDetails";
import MasterDataDetails from "./MasterDataDetails";
import NotesTemplateDetails from "./NotesTemplateDetails";
import MultipleDetails from "./MultipleDetails";

const getBadgeColorByType = (type) => {
  switch (type) {
    default:
      return "gray3";
  }
};
const ConfigurationLogDetails = ({
  configurationLogId,
  getConfigurationLog,
  loadingState,
  log
}) => {
  useLoadingState(
    loadingState,
    () => {},
    () => {}
  );

  useEffect(() => {
    getConfigurationLog({ configurationLogId });
  }, [configurationLogId]);

  const renderSummary = (log) => {
    switch (log.type) {
      case ConfigurationLogTypes.NOTES_TEMPLATE:
        return <NotesTemplateDetails log={log} />;
      case ConfigurationLogTypes.MASTER_DATA:
        return <MasterDataDetails log={log} />;
      case ConfigurationLogTypes.POWERPOINT_TEMPLATE:
        return <PowerpointTemplatesDetails log={log} />;
      case ConfigurationLogTypes.MULTIPLE:
        return <MultipleDetails log={log} />;
      default:
        return null;
    }
  };

  return (
    log && (
      <Container>
        <TitleBar>
          <Heading level={"h4"}>
            <ConfigurationLogType type={log.type} /> {log.version}
          </Heading>
          <Badge
            bg={themeProp("palette." + getBadgeColorByType(log.type))}
            color={"surface"}
          >
            <ConfigurationLogStatus status={log.status} />
          </Badge>
        </TitleBar>
        {renderSummary(log)}
      </Container>
    )
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-width: 380px;
  max-width: 380px;
  background: ${themeProp("palette.surface")};
  border: 1px solid ${themeProp("palette.gray1")};
  border-radius: 4px;
  justify-content: flex-start;

  & > div:last-of-type {
    flex-grow: 1;
  }
`;

const TitleBar = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  gap: 8px;
`;

ConfigurationLogDetails.propTypes = {
  configurationLogId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_CONFIGURATION_LOG_REQUEST
  ),
  log: selectRequestData(state, actionTypes.GET_CONFIGURATION_LOG_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getConfigurationLog
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfigurationLogDetails
);
