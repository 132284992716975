import { useFeature } from "../../components/FeaturesProvider";
import Icon from "../../components/Icon";
import styled from "styled-components";
import { useString as s } from "../../components/StringProvider";
import { themeProp } from "../../theme";
import PropTypes from "prop-types";
import { Tooltip } from "../../components";

const AccountMatch = ({ crmMatched }) => {
  const tooltip = s(
    "discoveries.dashboard.accountMatch.tooltip",
    "The Discovery is not matched to your CRM"
  );
  const crmEnabled = useFeature("crmAccountsIntegration", false);
  const salesforceIntegrationEnabled = useFeature(
    "salesforceIntegration",
    false
  );

  return (crmEnabled || salesforceIntegrationEnabled) && !crmMatched ? (
    <Container>
      <Tooltip title={tooltip} placement={"right"}>
        <Icon name={"alert"} colour={"error"} />
      </Tooltip>
    </Container>
  ) : null;
};

AccountMatch.propTypes = {
  crmMatched: PropTypes.bool.isRequired
};

const Container = styled.div`
  border-radius: 4px;
  padding: 0 5px 0 5px;
  min-width: 26px;
  min-height: 26px;
  text-align: center;
  background-color: inherit;

  &:hover {
    background-color: ${themeProp(`palette.background`)};
  }
`;

export default AccountMatch;
