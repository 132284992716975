import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";
import UNCATEGORIZED_CATEGORY_CODE from "../../utils/uncategorized-category-code";

const INITIAL_STATE = {
  error: false,
  loadingState: LoadingState.UNINITIALIZED,
  data: {},
  maps: {}
};

const masterData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_MASTER_DATA_REQUEST:
      return {
        ...state,
        loadingState: LoadingState.IN_PROGRESS
      };

    case types.GET_MASTER_DATA_FAILURE:
      return {
        ...state,
        loadingState: LoadingState.FAILED,
        error: action.payload.error,
        data: {},
        maps: {}
      };

    case types.GET_MASTER_DATA_SUCCESS:
      const maps = {};

      [
        "groups",
        "categories",
        "questions",
        "challenges",
        "kpis",
        "metrics",
        "discoveryTypes",
        "solutions"
      ].forEach((mapName) => {
        maps[mapName] = action.payload.data[mapName].reduce((map, item) => {
          map[item.code] = item;
          return map;
        }, {});
      });

      return {
        ...state,
        loadingState: LoadingState.COMPLETED,
        data: action.payload.data,
        maps
      };

    default:
      return state;
  }
};

export default masterData;

export const selectMasterDataLoadingState = (md) => md.loadingState;
export const selectMasterDataLoadingError = (md) => md.error;
export const selectMasterDataVersion = (md) => md.data?.version?.version;

const selectMasterDataByCode = (md, arrayName, code) => {
  return md.maps && md.maps[arrayName] ? md.maps[arrayName][code] : null;
};

export const selectCategory = (md, categoryCode) =>
  selectMasterDataByCode(md, "categories", categoryCode);
export const selectGroup = (md, groupCode) =>
  selectMasterDataByCode(md, "groups", groupCode);
export const selectQuestion = (md, questionCode) =>
  selectMasterDataByCode(md, "questions", questionCode);
export const selectQuestions = (md) => selectMasterDataArray(md, "questions");
export const selectChallenge = (md, challengeCode) =>
  selectMasterDataByCode(md, "challenges", challengeCode);
export const selectKPI = (md, kpiCode) =>
  selectMasterDataByCode(md, "kpis", kpiCode);
export const selectMetric = (md, metricCode) =>
  selectMasterDataByCode(md, "metrics", metricCode);
export const selectSolution = (md, solutionCode) =>
  selectMasterDataByCode(md, "solutions", solutionCode);

const selectMasterDataArray = (md, arrayName) =>
  md.data && md.data[arrayName] ? md.data[arrayName] : [];

export const selectAllGroups = (md) => selectMasterDataArray(md, "groups");
export const selectAllCategories = (md) =>
  selectMasterDataArray(md, "categories").filter(
    (item) => item.code !== UNCATEGORIZED_CATEGORY_CODE
  );
export const selectAllQuestions = (md) =>
  selectMasterDataArray(md, "questions");
export const selectAllChallenges = (md) =>
  selectMasterDataArray(md, "challenges");
export const selectAllKPIs = (md) => selectMasterDataArray(md, "kpis");
export const selectAllMetrics = (md) => selectMasterDataArray(md, "metrics");
export const selectAllDiscoveryTypes = (md) =>
  selectMasterDataArray(md, "discoveryTypes");
export const isSolutionsEnabled = (md) =>
  selectMasterDataArray(md, "solutions").length > 0;

export const selectMasterDataMap = (md, mapName) =>
  md.maps && md.maps[mapName] ? md.maps[mapName] : null;
