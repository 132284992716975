import types from "../actionTypes";
import promotionsService from "../../services/promotions.service";

export const requestPromotion = () => (dispatch) => {
  dispatch({
    type: types.REQUEST_PROMOTION_REQUEST
  });

  return promotionsService
    .requestPromotion()
    .then((payload) => {
      dispatch({
        type: types.REQUEST_PROMOTION_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.REQUEST_PROMOTION_FAILURE,
        error
      });
    });
};

export const getActivePromotion = () => (dispatch) => {
  dispatch({
    type: types.GET_ACTIVE_PROMOTION_REQUEST
  });

  return promotionsService
    .getActivePromotion()
    .then((payload) => {
      dispatch({
        type: types.GET_ACTIVE_PROMOTION_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_ACTIVE_PROMOTION_FAILURE,
        error
      });
    });
};

export const approvePromotion =
  ({ promotionId }) =>
  (dispatch) => {
    dispatch({
      type: types.APPROVE_PROMOTION_REQUEST
    });

    return promotionsService
      .approvePromotion({ promotionId })
      .then((payload) => {
        dispatch({
          type: types.APPROVE_PROMOTION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.APPROVE_PROMOTION_FAILURE,
          error
        });
      });
  };

export const rejectPromotion =
  ({ promotionId, rejectionReason }) =>
  (dispatch) => {
    dispatch({
      type: types.REJECT_PROMOTION_REQUEST
    });

    return promotionsService
      .rejectPromotion({ promotionId, rejectionReason })
      .then((payload) => {
        dispatch({
          type: types.REJECT_PROMOTION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.REJECT_PROMOTION_FAILURE,
          error
        });
      });
  };
