import types from "../actionTypes";

const INITIAL_STATE = {
  operationId: false,
  operationDiscoveryId: false,
  operationReportId: false,
  confirmation: null,
  alert: null,
  parameters: {},
  results: {}
};

const discoveryOperations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.START_DISCOVERY_OPERATION:
      return {
        ...state,
        operationId: action.payload.operationId,
        operationDiscoveryId: action.payload.operationDiscoveryId,
        operationReportId: action.payload.operationReportId,
        parameters: action.payload.parameters || {},
        alert: null,
        confirmation: null,
        results: null
      };
    case types.STOP_DISCOVERY_OPERATION:
      return {
        ...state,
        operationId: false,
        parameters: {}
      };
    case types.CONFIRM_DISCOVERY_OPERATION:
      return {
        ...state,
        confirmation: null
      };
    case types.ALERT_DISCOVERY_OPERATION:
      return {
        ...state,
        alert: null
      };
    default:
      if (action.type.match(/^.*FAILURE$/)) {
        return {
          ...state,
          alert: action.type
        };
      } else if (action.type.match(/^.*SUCCESS$/)) {
        return {
          ...state,
          confirmation: action.type,
          results: action.payload
        };
      }
      break;
  }

  return state;
};

export default discoveryOperations;

export const selectDiscoveryOperationId = (discoveryOperationsData) =>
  discoveryOperationsData.operationId;
export const selectDiscoveryOperationDiscoveryId = (discoveryOperationsData) =>
  discoveryOperationsData.operationDiscoveryId;
export const selectDiscoveryOperationReportId = (discoveryOperationsData) =>
  discoveryOperationsData.operationReportId;
export const selectDiscoveryOperationConfirmation = (discoveryOperationsData) =>
  discoveryOperationsData.confirmation;
export const selectDiscoveryOperationAlert = (discoveryOperationsData) =>
  discoveryOperationsData.alert;
export const selectDiscoveryOperationParameters = (discoveryOperationsData) =>
  discoveryOperationsData.parameters;
export const selectDiscoveryOperationResults = (discoveryOperationsData) =>
  discoveryOperationsData.results;
