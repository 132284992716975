import styled from "styled-components";
import { Progress as ANTProgress } from "antd";
import { themeProp } from "../theme";
import PropTypes from "prop-types";

const Progress = styled(ANTProgress).attrs((props) => ({
  strokeColor: themeProp(`palette.${props.color}`)(props),
  trailColor: themeProp(`palette.gray2`)(props)
}))`
  height: 4px;
  max-height: 4px;

  & .ant-progress-inner {
    background: transparent;
    border-radius: 4px !important;
  }

  &.ant-progress-line {
    font-size: 0 !important;
    margin: 0;
    margin-inline: 0;
  }

  .ant-progress-bg {
    height: 4px !important;
    border-radius: 4px !important;
  }

  &.ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }

  @media print {
    display: none;
  }
`;

Progress.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "success"])
};

Progress.defaultProps = {
  color: "success"
};

export default Progress;
