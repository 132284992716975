import Form, { FormItem } from "../../components/Form";
import { useString as s } from "../../components/StringProvider";
import React from "react";
import PropTypes from "prop-types";
import Input from "../../components/Input";
import ButtonGroup from "../../components/ButtonGroup";
import NewButton from "../../components/NewButton";
import { useMobileMediaQuery } from "../../components/Responsive";

const AutoConfigurationForm = ({ form, onFinish, children }) => {
  const isMobile = useMobileMediaQuery();
  const submitButtonText = s(
    "autoConfiguration.form.submit.text",
    "Generate Experience"
  );
  const corporateEmailLabel = s(
    "autoConfiguration.form.corporateEmail.label",
    "Your Corporate Email"
  );
  const corporateEmailMessage = s(
    "autoConfiguration.form.corporateEmail.message",
    "Please enter you corporate email"
  );
  const companyNameLabel = s(
    "autoConfiguration.form.corporateName.label",
    "Company Name"
  );
  const companyNameMessage = s(
    "autoConfiguration.form.corporateName.message",
    "Please enter you company name"
  );
  const companyWebsiteLabel = s(
    "autoConfiguration.form.companyWebsite.label",
    "Company Website"
  );

  const companyWebsiteMessage = s(
    "autoConfiguration.form.companyWebsite.message",
    "Please enter your company website"
  );
  const companyWebsitePlaceholder = s(
    "autoConfiguration.form.companyWebsite.placeholder",
    "microsoft.com"
  );
  const productNameLabel = s(
    "autoConfiguration.form.productName.label",
    "Product Name"
  );
  const productNamePlaceholder = s(
    "autoConfiguration.form.productName.placeholder",
    "i.e. Microsoft Excel"
  );
  const marketSegmentLabel = s(
    "autoConfiguration.form.marketSegment.label",
    "Market Segment"
  );
  const marketSegmentPlaceholder = s(
    "autoConfiguration.form.marketSegment.placeholder",
    "e.g. Large Enterprise Companies"
  );
  const top5CompetitorsLabel = s(
    "autoConfiguration.form.top5Competitors.label",
    "Top 5 Competitors"
  );
  const top5CompetitorsPlaceholder = s(
    "autoConfiguration.form.top5Competitors.placeholder",
    "List company names separated by a comma"
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name={"autoConfigurationForm"}
    >
      <FormItem
        name="email"
        label={corporateEmailLabel}
        rules={[{ required: true, message: corporateEmailMessage }]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="companyName"
        label={companyNameLabel}
        rules={[{ required: true, message: companyNameMessage }]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="companyWebsite"
        label={companyWebsiteLabel}
        rules={[{ required: true, message: companyWebsiteMessage }]}
      >
        <Input placeholder={companyWebsitePlaceholder} />
      </FormItem>
      <FormItem name="productName" label={productNameLabel}>
        <Input placeholder={productNamePlaceholder} />
      </FormItem>
      <FormItem name="marketSegment" label={marketSegmentLabel}>
        <Input placeholder={marketSegmentPlaceholder} />
      </FormItem>
      <FormItem name="top5Competitors" label={top5CompetitorsLabel}>
        <Input placeholder={top5CompetitorsPlaceholder} />
      </FormItem>
      <FormItem>
        <ButtonGroup mobile={isMobile}>
          <NewButton type={"submit"} data-cy={"auto-configuration-form-save"}>
            {submitButtonText}
          </NewButton>
          {children}
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

AutoConfigurationForm.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired
};

export default AutoConfigurationForm;
