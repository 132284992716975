import { useString as s } from "../../StringProvider";
import PropTypes from "prop-types";
import Modal from "../../Modal";
import EmailModalContainer from "./EmailModalContainer";

const EmailDiscoveryModal = ({ onClose }) => {
  const title = s("email.popup.header", "Generate Email");

  return (
    <Modal
      title={title}
      width={960}
      open={true}
      onCancel={onClose}
      data-cy={"email-discovery-modal"}
    >
      <EmailModalContainer />
    </Modal>
  );
};

EmailDiscoveryModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default EmailDiscoveryModal;
