import httpService from "./http.service";

const getJob = ({ jobId }) =>
  httpService
    .get(`/jobs/${jobId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getJob
};

export default methods;
