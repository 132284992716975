import styled from "styled-components";
import PropTypes from "prop-types";
import { useMobileMediaQuery } from "./Responsive";
import { useString as s } from "./StringProvider";
import { Icon, NewButton, Heading } from "../components";

const GroupCollapse = ({
  children,
  title,
  collapsed,
  onCollapse,
  collapsible
}) => {
  const isMobile = useMobileMediaQuery();
  const collapseSectionTooltip = s(
    "groupCollapse.tooltip.label",
    "Collapse section"
  );
  const expandSectionTooltip = s("groupExpand.tooltip.label", "Expand section");

  return (
    <>
      <GroupHeader>
        <StyledHeader
          level={isMobile ? "h4" : "h3"}
          data-cy={"group-title"}
          onClick={onCollapse}
        >
          {title}
        </StyledHeader>
        {collapsible ? (
          <NewButton
            type={"iconSecondary"}
            onClick={onCollapse}
            tooltip={collapsed ? expandSectionTooltip : collapseSectionTooltip}
          >
            {collapsed ? <Icon name="plus" /> : <Icon name="minus" />}
          </NewButton>
        ) : null}
      </GroupHeader>
      {!collapsed || !collapsible ? children : null}
    </>
  );
};

const GroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledHeader = styled(Heading)`
  cursor: pointer;
`;

GroupCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  collapsible: PropTypes.bool
};

GroupCollapse.defaultProps = {
  collapsible: true
};

export default GroupCollapse;
