import { OrganizationPageLayout } from "../../../components/Layout";
import Page from "../../../components/Page";
import OrganizationHeader from "../OrganizationHeader";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import NavigationMenu from "../../../components/NavigationMenu";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from "react-router-dom";
import { NotFound } from "../../ErrorPage";
import { compose } from "redux";
import styled from "styled-components";
import { selectIsCuvamaUser } from "../../../store/reducers";
import { connect } from "react-redux";
import { useFeature } from "../../../components/FeaturesProvider";
import ProspectLedDiscovery from "./ProspectLedDiscovery";
import { IntegrationConnection } from "./Integration";
import ManageFeatures from "./ManageFeatures";

const Pages = {
  SALESFORCE_CONNECTION: "integrations/salesforce",
  PROSPECT_LED_DISCOVERY: "prospect-led-discovery",
  HUBSPOT_CONNECTION: "integrations/hubspot",
  FEATURES: "features"
};
Pages.ALL = Object.values(Pages);

const getSelectedPage = (pathname) => {
  for (const page of Pages.ALL) {
    if (pathname.endsWith(page)) {
      return page;
    }
  }

  return Pages.SALESFORCE_CONNECTION;
};

const General = ({ history, isCuvamaUser }) => {
  const header = s("organization.page.general.header", "General");
  const salesforceConnection = s(
    "organization.page.general.menu.salesforceConnection",
    "Salesforce Connect"
  );
  const prospectLedDiscovery = s(
    "organization.page.general.menu.prospectLedDiscovery",
    "Prospect-Led Discovery"
  );
  const hubspotConnection = s(
    "organization.page.general.menu.hubspotConnection",
    "HubSpot Connection"
  );
  const features = s("organization.page.general.menu.features", "Features");
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(
    Pages.PROSPECT_LED_DISCOVERY
  );
  const salesforceIntegration = useFeature("salesforceIntegration", false);
  const hubspotIntegration = useFeature("hubspotIntegration", false);

  useEffect(() => {
    setSelectedPage(getSelectedPage(location.pathname));
  }, [location.pathname]);

  const onChangePage = (e) => {
    history.push(`/organizations/general/${e.key}`);
  };

  const navigationMenuItems = [];
  let defaultPage = "";

  if (isCuvamaUser) {
    defaultPage = Pages.PROSPECT_LED_DISCOVERY;
  } else if (salesforceIntegration) {
    defaultPage = Pages.SALESFORCE_CONNECTION;
  } else if (hubspotIntegration) {
    defaultPage = Pages.HUBSPOT_CONNECTION;
  }

  if (isCuvamaUser) {
    navigationMenuItems.push({
      key: Pages.PROSPECT_LED_DISCOVERY,
      label: prospectLedDiscovery
    });
  }

  if (isCuvamaUser) {
    navigationMenuItems.push({
      key: Pages.FEATURES,
      label: features
    });
  }

  if (salesforceIntegration) {
    navigationMenuItems.push({
      key: Pages.SALESFORCE_CONNECTION,
      label: salesforceConnection
    });
  }

  if (hubspotIntegration) {
    navigationMenuItems.push({
      key: Pages.HUBSPOT_CONNECTION,
      label: hubspotConnection
    });
  }

  const isSalesforceConnectionNotFound =
    selectedPage === Pages.SALESFORCE_CONNECTION && !salesforceIntegration;
  const isHubspotConnectionNotFound =
    selectedPage === Pages.HUBSPOT_CONNECTION && !hubspotIntegration;

  if (isSalesforceConnectionNotFound || isHubspotConnectionNotFound) {
    return <NotFound />;
  }

  return (
    <Page header={<OrganizationHeader />}>
      <OrganizationPageLayout>
        <Heading level={"h3"}>{header}</Heading>
        <NavigationMenu
          mode={"horizontal"}
          selectedKeys={[selectedPage]}
          onClick={onChangePage}
          items={navigationMenuItems}
        />
        <Container>
          <Switch>
            <Route
              path="/organizations/general/integrations/:name"
              exact
              component={IntegrationConnection}
            />
            <Route
              path="/organizations/general/prospect-led-discovery"
              exact
              component={ProspectLedDiscovery}
            />
            {isCuvamaUser && (
              <Route
                path="/organizations/general/features"
                exact
                component={ManageFeatures}
              />
            )}
            {defaultPage ? (
              <Redirect
                from=""
                to={`/organizations/general/${defaultPage}`}
                exact
              />
            ) : null}

            <Route component={NotFound} />
          </Switch>
        </Container>
      </OrganizationPageLayout>
    </Page>
  );
};

const Container = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => ({
  isCuvamaUser: selectIsCuvamaUser(state)
});

export default compose(withRouter, connect(mapStateToProps))(General);
