import { useString as s } from "../../StringProvider";
import { TextArea } from "../../Input";
import Form, { FormItem } from "../../Form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import SelectTemplate from "../SelectTemplate";
import PropTypes from "prop-types";
import NewButton from "../../NewButton";
import Icon from "../../Icon";
import TooltipIcon from "../../TooltipIcon";
import { bindActionCreators, compose } from "redux";
import {
  hasPermission,
  selectDiscovery,
  selectDiscoveryId,
  selectDiscoveryPhase,
  selectDiscoveryReportId,
  selectHasManageDiscoveryAccessPermission,
  selectRequestState
} from "../../../store/reducers";
import { connect } from "react-redux";
import Permissions from "../../../utils/permissions";
import { message } from "antd";
import useLoadingState from "../../../utils/use-loading-state";
import { DesktopOrTablet, Mobile, useMobileMediaQuery } from "../../Responsive";
import ButtonGroup from "../../ButtonGroup";
import actionTypes from "../../../store/actionTypes";
import MultipleEmailInput, {
  allEmailsValid,
  getEmailOptions
} from "../../Input/MultipleEmailInput";
import { shareDiscoveryAccess } from "../../../store/actions/discoveries";
import { themeProp } from "../../../theme";
import { getReportLink, getInvitationLink } from "./functions";
import LoadingState from "../../../utils/loading-state";
import AccessTypes from "./access-types";

const Operations = {
  SEND_EMAIL: "SEND_EMAIL",
  COPY_LINK: "COPY_LINK"
};

const NameAndEmailForm = ({
  canShareEdit,
  phase,
  discoveryId,
  discovery,
  shareDiscoveryAccess,
  template,
  shareDiscoveryState,
  onClose,
  hasManageAccessPermission,
  onNext
}) => {
  const isMobile = useMobileMediaQuery();
  const [form] = Form.useForm();
  const [operation, setOperation] = useState("");
  const canEdit = Form.useWatch("isEditAccess", form);
  const [currentTemplate, setCurrentTemplate] = useState(template);
  const [hasInvalidEmails, setHasInvalidEmails] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const inProgress = shareDiscoveryState === LoadingState.IN_PROGRESS;

  const sendText = s("share.popup.button.send", "Send");
  const manageAccessText = s(
    "share.popup.button.manageAccess",
    "Manage Access"
  );
  const emailViewAccessLabel = s(
    "share.popup.email.view.label",
    "Emails (optional)"
  );
  const emailEditAccessLabel = s(
    "share.popup.email.edit.label",
    "Invite people"
  );
  const emailTooltip = s(
    "share.popup.email.tooltip",
    "Enter email addresses here to either send an invite or generate a link to edit this discovery"
  );
  const sendTooltip = s(
    "share.popup.send.tooltip",
    "Enter at least one email to continue"
  );
  const emailPlaceholder = s(
    "share.popup.email.placeholder",
    "Enter emails(s)"
  );
  const emailValidation = s(
    "share.popup.email.validation",
    "Please enter a valid email"
  );
  const templateLabel = s("share.popup.template.label", "Discovery Stage");
  const messageLabel = s("share.popup.message.label", "Message (optional)");
  const messagePlaceholder = s(
    "share.popup.message.placeholder",
    "Anything you'd like to say when sharing?"
  );
  const templateTooltip = s(
    "share.popup.template.tooltip",
    "The report you share will include discovery content up to and including the phase you choose on the drop down menu "
  );
  const copySuccess = s(`share.popup.copy.success`, "Link copied");
  const sendSuccess = s(`share.popup.send.success`, "Email(s) sent");
  const shareDiscoveryError = s(
    `share.popup.shareDiscovery.error`,
    "Failed to share the Discovery"
  );
  const copyEditAccessLink = s(
    "share.popup.button.copyEditAccessLink",
    "Copy edit access link"
  );
  const copyViewAccessLink = s(
    "share.popup.button.copyViewAccessLink",
    "Copy report link"
  );

  const editAccessText = s(
    "share.popup.button.editAccess",
    "Edit access (Discovery)"
  );
  const viewAccessText = s(
    "share.popup.button.viewAccess",
    "View access (Report)"
  );

  const setActionButtonStatuses = ({ emails, hasInvalidEmails }) => {
    if (canEdit && (emails.length === 0 || hasInvalidEmails)) {
      setSendButtonDisabled(true);
    } else if (!canEdit && (emails.length === 0 || hasInvalidEmails)) {
      setSendButtonDisabled(true);
    } else {
      setSendButtonDisabled(false);
    }
  };

  useEffect(() => {
    setActionButtonStatuses({
      emails: form.getFieldValue("emails") || [],
      hasInvalidEmails
    });
  }, [canEdit]);

  useEffect(() => {
    setCurrentTemplate(template);
  }, []);

  useLoadingState(
    shareDiscoveryState,
    () => {
      if (operation === Operations.SEND_EMAIL) {
        message.success(sendSuccess);
        onClose && onClose();
      }
    },
    () => {
      message.error(shareDiscoveryError);
    }
  );

  const onSelect = (template) => {
    setCurrentTemplate(template);
  };

  const onFinish = (values) => {
    const emails = values.emails.map((email) => email.value);

    if (!!emails.length) {
      shareDiscoveryAccess({
        discoveryId,
        emails: values.emails
          ? values.emails.map((email) => email.value)
          : undefined,
        accessType: canEdit ? AccessTypes.EDIT : AccessTypes.VIEW,
        sendEmail: operation === Operations.SEND_EMAIL,
        message: values.message,
        discoveryStage: currentTemplate
      });
    }
  };

  const onValuesChange = (values) => {
    if (values.emails) {
      const emails = getEmailOptions(values.emails);
      const hasInvalidEmails = !allEmailsValid(values.emails);
      setHasInvalidEmails(hasInvalidEmails);
      form.setFieldsValue({ emails });
      setActionButtonStatuses({ emails, hasInvalidEmails });
    }
  };

  const onCopyLink = () => {
    setOperation(Operations.COPY_LINK);

    if (!canEdit) {
      const reportLinkURL = getReportLink({
        reportId: discovery.reportId,
        template: currentTemplate
      });

      navigator.clipboard
        .writeText(reportLinkURL)
        .then(() => message.success(copySuccess))
        .catch((error) => console.log(error));
    } else {
      const invitationLinkURL = getInvitationLink({
        template: currentTemplate,
        invitationCode: discovery.invitationCode
      });

      navigator.clipboard
        .writeText(invitationLinkURL)
        .then(() => message.success(copySuccess))
        .catch((error) => console.log(error));
    }
  };

  const emailFormItem = (
    <FormItem
      label={
        <>
          {canEdit ? emailEditAccessLabel : emailViewAccessLabel}&nbsp;
          <TooltipIcon title={emailTooltip} />
        </>
      }
      name="emails"
      rules={[
        () => ({
          validator(_, value) {
            if (canEdit && value && !allEmailsValid(value)) {
              return Promise.reject(new Error(emailValidation));
            }
            return Promise.resolve();
          }
        })
      ]}
    >
      <MultipleEmailInput placeholder={emailPlaceholder} />
    </FormItem>
  );

  const copyLinkButton = (
    <NewButton
      type={"secondary"}
      htmlType={"submit"}
      onClick={onCopyLink}
      data-cy={"copy-link-button"}
    >
      <Icon name={"link"} />
      {canEdit ? copyEditAccessLink : copyViewAccessLink}
    </NewButton>
  );

  const sendButton = (
    <NewButton
      type={"submit"}
      onClick={() => setOperation(Operations.SEND_EMAIL)}
      disabled={sendButtonDisabled || inProgress}
      data-cy={"send-button"}
      tooltip={
        hasInvalidEmails
          ? emailValidation
          : sendButtonDisabled
          ? sendTooltip
          : undefined
      }
    >
      {sendText}
    </NewButton>
  );

  const manageAccessButton = (
    <NewButton
      type={"secondary"}
      onClick={() => onNext()}
      disabled={!hasManageAccessPermission}
      data-cy={"manage-access-button"}
    >
      {manageAccessText}
    </NewButton>
  );

  const actionButtons = (
    <>
      <Mobile>
        <ButtonGroup>
          {sendButton}
          {copyLinkButton}
          {canEdit && hasManageAccessPermission && onNext && manageAccessButton}
        </ButtonGroup>
      </Mobile>
      <DesktopOrTablet>
        <ActionButtons>
          <ActionButtonsLeft>
            {sendButton}
            {copyLinkButton}
          </ActionButtonsLeft>
          {canEdit && hasManageAccessPermission && onNext && manageAccessButton}
        </ActionButtons>
      </DesktopOrTablet>
    </>
  );

  const onEditClick = () => {
    form.setFieldsValue({ isEditAccess: true });
  };
  const onViewClick = () => {
    form.setFieldsValue({ isEditAccess: false });
  };

  const AccessTypeToggle = () => {
    return (
      <AccessTypeToggleContainer isMobile={isMobile}>
        <NewButton
          type={"toggle"}
          onClick={onEditClick}
          disabled={canEdit}
          data-cy={"edit-access-tab-button"}
        >
          {editAccessText}
        </NewButton>
        <NewButton
          type={"toggle"}
          onClick={onViewClick}
          disabled={!canEdit}
          data-cy={"view-access-tab-button"}
        >
          {viewAccessText}
        </NewButton>
      </AccessTypeToggleContainer>
    );
  };

  return (
    <StyledForm
      layout="vertical"
      form={form}
      initialValues={{ template, isEditAccess: true }}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      canShareEdit={canShareEdit}
    >
      <div>
        {canShareEdit && (
          <FormItem name="isEditAccess">
            <AccessTypeToggle />
          </FormItem>
        )}
        {!canEdit && (
          <FormItem
            label={
              <>
                {templateLabel}&nbsp;
                <TooltipIcon title={templateTooltip} />
              </>
            }
            name="template"
          >
            <SelectTemplate phase={phase} onSelect={onSelect} />
          </FormItem>
        )}
        {emailFormItem}
        {canEdit && (
          <FormItem label={messageLabel} name="message">
            <TextArea
              autoSize={{
                minRows: isMobile ? 2 : 7,
                maxRows: isMobile ? 2 : 7
              }}
              placeholder={messagePlaceholder}
            />
          </FormItem>
        )}
        {!canEdit && (
          <FormItem label={messageLabel} name="message">
            <TextArea
              autoSize={{
                minRows: 3,
                maxRows: 3
              }}
              placeholder={messagePlaceholder}
            />
          </FormItem>
        )}
      </div>

      <FormItem>
        <Actions canShareEdit={canShareEdit}>{actionButtons}</Actions>
      </FormItem>
    </StyledForm>
  );
};

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButtonsLeft = styled.div`
  display: flex;
  gap: 16px;
`;

const AccessTypeToggleContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column;" : "row;")};
  justify-content: space-between;
  border-radius: 6px;
  align-items: center;
  ${(props) => (props.isMobile ? `` : "height: 50px;")}
  background-color: ${themeProp("palette.gray1")};
`;

const Actions = styled.div`
  margin-top: ${(props) => (props.canShareEdit ? "0px" : "20px")};
`;

const StyledForm = styled(Form)`
  min-height: ${(props) => (props.canShareEdit ? "480px" : "0px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

NameAndEmailForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      shareDiscoveryAccess
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  discovery: selectDiscovery(state),
  hasManageAccessPermission: selectHasManageDiscoveryAccessPermission(state),
  phase: selectDiscoveryPhase(state),
  canShareEdit: hasPermission(state, Permissions.SHARE_EDIT),
  reportId: selectDiscoveryReportId(state),
  shareDiscoveryState: selectRequestState(
    state,
    actionTypes.SHARE_DISCOVERY_ACCESS_REQUEST
  )
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NameAndEmailForm
);
