import OrganizationHeader from "../OrganizationHeader";
import { OrganizationPageLayout } from "../../../components/Layout";
import { withRouter } from "react-router-dom";
import Page from "../../../components/Page";
import React, { useEffect, useState } from "react";
import { selectRequestData, selectRequestState } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import actionTypes from "../../../store/actionTypes";
import UpdateBrandingForm from "./UpdateBrandingThemesForm";
import { downloadStrings, getStrings } from "../../../store/actions/strings";
import { getTheme } from "../../../store/actions/themes";
import Form from "../../../components/Form";
import {
  updateBrandingStrings,
  updateBrandingThemes
} from "../../../store/actions/configurations";
import JobMonitor from "../../../components/JobMonitor";
import useLoadingState from "../../../utils/use-loading-state";
import { message, Space, Spin } from "antd";
import { useString as s } from "../../../components/StringProvider";
import JobStatuses from "../../../utils/job-statuses";
import styled from "styled-components";
import { useTheme } from "../../../components/ThemeProvider";
import { LoadingOutlined } from "@ant-design/icons";
import UpdateBrandingStringsForm from "./UpdateBrandingStringsForm";
import Text from "../../../components/Text";
import NewButton from "../../../components/NewButton";
import openDownload from "../../../utils/open-download";

const UpdateBranding = ({
  strings,
  theme,
  updateBrandingThemes,
  updateBrandingStrings,
  updateBrandingThemesJob,
  updateBrandingThemesLoadingState,
  updateBrandingStringsLoadingState,
  downloadStrings,
  downloadStringsLoadingState,
  downloadStringsFile,
  getTheme,
  getStrings
}) => {
  const [formThemes] = Form.useForm();
  const [formStrings] = Form.useForm();
  const [jobId, setJobId] = useState("");
  const [job, setJob] = useState({ status: JobStatuses.COMPLETED });
  const [allFormValues, setAllFormValues] = useState([]);
  const [clearFileLists, setClearFileLists] = useState(false);

  useEffect(() => {
    getTheme();
    getStrings();
  }, []);

  const updateBrandingThemesSuccess = s(
    "organization.page.updateBranding.popup.messages.themesSuccess",
    "The Theme has been updated successfully"
  );
  const updateBrandingThemesFailure = s(
    "organization.page.updateBranding.popup.messages.themesFailure",
    "The Themes update failed"
  );

  const updateBrandingStringsSuccess = s(
    "organization.page.updateBranding.popup.messages.stringsSuccess",
    "The Strings have been updated successfully"
  );
  const updateBrandingStringsFailure = s(
    "organization.page.updateBranding.popup.messages.stringsFailure",
    "The Strings update failed"
  );

  const pageTitle = s(
    "organization.page.updateBranding.page.title",
    "Update Branding"
  );

  const downloadStringsButtonText = s(
    "organization.page.updateBranding.downloadStrings.text",
    "Download JS Strings"
  );

  const downloadError = s(
    "organization.page.updateBranding.downloadError",
    "Error exporting file"
  );

  const themesTitle = s(
    "organization.page.updateBranding.themes.title",
    "THEMES"
  );

  const stringsTitle = s(
    "organization.page.updateBranding.strings.title",
    "STRINGS"
  );

  const themesSaveText = s(
    "organization.page.updateBranding.themes.save",
    "Save Themes"
  );

  const stringsSaveText = s(
    "organization.page.updateBranding.strings.save",
    "Save Strings"
  );

  const onSubmitThemes = () => {
    updateBrandingThemes(allFormValues);
  };

  const getThemesFormInitialValues = ({ theme }) => {
    return {
      primaryColor: theme && theme.palette.primary,
      secondaryColor: theme && theme.palette.secondary,
      heroFileName: theme && theme.assets.login_cover,
      logoFileName: theme && theme.assets.logo
    };
  };

  const onSubmitStrings = (data) => {
    updateBrandingStrings(data);
  };

  const successColor = useTheme("palette.primary");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: successColor }} spin />
  );

  useLoadingState(
    updateBrandingThemesLoadingState,
    () => {
      setJobId(updateBrandingThemesJob._id);
    },
    () => {}
  );

  useLoadingState(
    updateBrandingStringsLoadingState,
    () => {
      message.success(updateBrandingStringsSuccess);
      getStrings();
    },
    () => {
      message.error(updateBrandingStringsFailure);
    }
  );

  useLoadingState(
    downloadStringsLoadingState,
    () => {
      openDownload(downloadStringsFile.url);
    },
    () => {
      message.error(downloadError);
    }
  );

  const onJobFinish = (successful) => {
    if (successful) {
      setClearFileLists(true);
      getTheme();
      message.success(updateBrandingThemesSuccess);
    } else {
      message.error(updateBrandingThemesFailure);
    }
    setJobId("");
  };

  if (!(strings && theme)) {
    return null;
  }

  return (
    <Page header={<OrganizationHeader />}>
      <OrganizationPageLayout>
        <UpdateBrandingHeader>
          <Text variant={"h3"}>{pageTitle}</Text>
          <NewButton onClick={() => downloadStrings()}>
            {downloadStringsButtonText}
          </NewButton>
        </UpdateBrandingHeader>
        <ThemesContainer>
          <Space direction={"vertical"} size={16}>
            <Text variant={"h5"} color={"gray4"}>
              {themesTitle}
            </Text>
            <UpdateBrandingForm
              form={formThemes}
              initialValues={getThemesFormInitialValues({ theme })}
              onFinish={onSubmitThemes}
              submitButtonText={themesSaveText}
              jobId={jobId}
              clearFileLists={clearFileLists}
              setAllFormValues={setAllFormValues}
            />
          </Space>
        </ThemesContainer>
        <StringsContainer>
          <Space direction={"vertical"} size={16}>
            <Text
              variant={"h5"}
              color={"gray4"}
              style={{ marginBottom: "10px" }}
            >
              {stringsTitle}
            </Text>
            <UpdateBrandingStringsForm
              form={formStrings}
              initialValues={strings}
              onFinish={onSubmitStrings}
              submitButtonText={stringsSaveText}
              jobId={jobId}
              clearFileLists={clearFileLists}
            />
          </Space>
        </StringsContainer>
        {jobId && (
          <JobMonitor jobId={jobId} onChange={setJob} onFinish={onJobFinish} />
        )}
        {job?.status !== JobStatuses.FAILED &&
          job?.status !== JobStatuses.COMPLETED && (
            <LoaderDiv>
              <Spin indicator={antIcon} size={"large"} />
            </LoaderDiv>
          )}
      </OrganizationPageLayout>
      {jobId && <JobMonitor jobId={jobId} onChange={setJob} />}
    </Page>
  );
};

const UpdateBrandingHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
`;

const ThemesContainer = styled.div`
  padding: 16px;
`;

const StringsContainer = styled.div`
  padding: 16px;
`;

const LoaderDiv = styled.div`
  & .ant-spin.ant-spin-lg,
  & .ant-spin.ant-spin-lg .anticon {
    font-size: 48px !important;
  }
`;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStrings,
      getTheme,
      updateBrandingThemes,
      updateBrandingStrings,
      downloadStrings
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  theme: selectRequestData(state, actionTypes.GET_THEME_REQUEST) || {},
  strings: selectRequestData(state, actionTypes.GET_STRINGS_REQUEST) || {},
  updateBrandingThemesJob: selectRequestData(
    state,
    actionTypes.UPDATE_BRANDING_THEMES_REQUEST
  ),
  updateBrandingThemesLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_BRANDING_THEMES_REQUEST
  ),
  updateBrandingStringsLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_BRANDING_STRINGS_REQUEST
  ),
  downloadStringsLoadingState: selectRequestState(
    state,
    actionTypes.DOWNLOAD_STRINGS_REQUEST
  ),
  downloadStringsFile: selectRequestData(
    state,
    actionTypes.DOWNLOAD_STRINGS_REQUEST
  )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UpdateBranding);
