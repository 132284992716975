import { Checkbox as ANTCheckbox } from "antd";
import styled from "styled-components";
import { themeProp } from "../theme";

const CheckboxGroup = styled(ANTCheckbox.Group)`
  &.ant-checkbox-group {
    display: flex;
    flex-direction: column;

    .ant-checkbox-group-item {
      margin-bottom: 5px;
    }

    .ant-checkbox {
      &-checked {
        .ant-checkbox-inner {
          background-color: ${themeProp("palette.secondary")};
          border-color: ${themeProp("palette.secondary")};

          &::after {
            border-width: 3px;
            margin-top: 1px;
          }
        }

        &::after {
          border-color: ${themeProp("palette.secondary")};
        }
      }
      &:hover {
        .ant-checkbox-inner {
          border-color: ${themeProp("palette.secondary")};
        }
      }
    }
  }
`;

export default CheckboxGroup;
