import { useString as s } from "../../../../components/StringProvider";
import { Text } from "../../../../components";
import Heading from "../../../../components/Heading";
import { Space } from "antd";
import styled from "styled-components";
import FadeLoader from "react-spinners/FadeLoader";
import React from "react";
import { useTheme } from "../../../../components/ThemeProvider";

const GenerateProgress = ({ job }) => {
  const loaderColor = useTheme("palette.primary");
  const header = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.progress.header",
    "We are crafting your Cuvama experience..."
  );
  const subheader = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.progress.subheader",
    "This can take a minute. Please stay on this page and let the magic happen!"
  );
  const stages = {};

  stages["questions"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.progress.stage.questions",
    "Building Questions and Answers"
  );

  stages["categories"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.progress.stage.categories",
    "Building Categories"
  );

  stages["challenges"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.progress.stage.challenges",
    "Building Challenges"
  );

  stages["kpis"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.progress.stage.kpis",
    "Building KPIs"
  );

  stages["mappings"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.progress.stage.mappings",
    "Building Mappings"
  );

  return (
    <Container>
      <LoaderSpace size={16} direction={"vertical"} align={"center"}>
        <LoaderDiv>
          <FadeLoader color={loaderColor} size="50" />
        </LoaderDiv>
        <Heading level={"h3"} color={"gray4"}>
          {header}
        </Heading>
        <Text color={"gray4"}>{subheader}</Text>
        <Text color={"gray4"}>{stages[job?.parameters?.section] || "..."}</Text>
      </LoaderSpace>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const LoaderSpace = styled(Space)`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const LoaderDiv = styled.div`
  height: 50px;
  width: 50px;
`;

export default GenerateProgress;
