import React from "react";
import styled from "styled-components";
import AlertBox from "../../AlertBox";
import { useString as s } from "../../../components/StringProvider";

const DiscoveryAlert = () => {
  const title = s(
    "new_discovery.popup.messages.duplicateErrorTitle",
    "Please use different values"
  );
  const message = s(
    "new_discovery.popup.messages.duplicateError",
    "A Discovery with those Customer Name, Opportunity Number & Discovery Description values already exists"
  );
  return (
    <DiscoveryModalAlert title={title}>
      <span data-cy={"discovery-modal-alert"}>{message}</span>
    </DiscoveryModalAlert>
  );
};

const DiscoveryModalAlert = styled(AlertBox)`
  margin-bottom: 16px;
`;

export default DiscoveryAlert;
