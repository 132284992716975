import Form, { FormItem } from "../../components/Form";
import { useString as s } from "../../components/StringProvider";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Space } from "antd";
import Input from "../../components/Input";
import { selectRequestData, selectRequestState } from "../../store/reducers";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getUserSettings,
  setUserSettings
} from "../../store/actions/user-settings";
import LoadingState from "../../utils/loading-state";
import NewButton from "../../components/NewButton";
import Tabs from "../../components/Tabs";
import { Modal, Text } from "../../components";
import { useFeature } from "../../components/FeaturesProvider";
import Checkbox from "../../components/Checkbox";
import CheckboxGroup from "../../components/CheckboxGroup";
import { message } from "antd";
import ButtonGroup from "../../components/ButtonGroup";
import actionTypes from "../../store/actionTypes";

const { TabPane } = Tabs;

const TabKeys = {
  GENERAL: "general",
  EMAIL_NOTIFICATIONS: "emailNotifications"
};

const MySettingsModal = ({
  visible,
  onClose,
  loadingState,
  setLoadingState,
  userSettings,
  getUserSettings,
  setUserSettings
}) => {
  const [form] = Form.useForm();
  const generalTabEnabled = useFeature("crmBccEnabled", true);
  const okText = s("my_settings.popup.okButton", "Save");
  const cancelText = s("my_settings.popup.cancelButton", "Discard");
  const emailNotificationTab = s(
    "my_settings.popup.emailNotifications.tabTitle",
    "Email Notifications"
  );
  const generalTab = s("my_settings.popup.general", "General");
  const emailNotificationHeaderText = s(
    "my_settings.popup.emailNotification.header",
    "Receive email when:"
  );
  const emailNotificationFootnoteText = s(
    "my_settings.popup.emailNotification.footnote",
    "We send these emails once per hour if the activity happened."
  );
  const setErrorText = s(
    "my_settings.popup.error.save",
    "Failed to save settings"
  );
  const getErrorText = s(
    "my_settings.popup.error.get",
    "Failed to get settings"
  );
  const headerText = s("my_settings.popup.header", "My Settings");
  const emailPlaceholder = s(
    "my_settings.popup.crmBccEmail.placeholder",
    "Type an email address"
  );
  const emailValidation = s(
    "my_settings.popup.crmBccEmail.validation",
    "Please input a valid email address"
  );
  const crmBccEmailText = s(
    "my_settings.popup.crmBccEmail.label",
    "Email to CRM (BCC)"
  );
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (visible && loadingState !== LoadingState.COMPLETED) {
      getUserSettings();
    } else if (visible && loadingState === LoadingState.FAILED) {
      message.error(getErrorText);
    }
  }, [visible, loadingState, getUserSettings]);

  useEffect(() => {
    if (visible && userSettings) {
      form.setFieldsValue(userSettings);
      setDirty(false);
    }
  }, [visible, userSettings]);

  useEffect(() => {
    if (setLoadingState === LoadingState.FAILED) {
      message.error(setErrorText);
    }
  }, [setLoadingState]);

  const onFinish = async (values) => {
    setUserSettings(values);
    onClose();
  };

  const onChange = () => {
    setDirty(true);
  };

  const options = [
    {
      code: "COMMENT_ADDED",
      text: s(
        "my_settings.popup.emailNotifications.COMMENT_ADDED",
        "Comment added"
      )
    },
    {
      code: "DISCOVERY_CONTENT_CHANGED",
      text: s(
        "my_settings.popup.emailNotifications.DISCOVERY_CONTENT_CHANGED",
        "Discovery content changed"
      )
    },
    {
      code: "ACCESS_REQUESTED",
      text: s(
        "my_settings.popup.emailNotifications.ACCESS_REQUESTED",
        "Access requested"
      )
    },
    {
      code: "DISCOVERY_OWNERSHIP_CHANGED_OR_DELETED",
      text: s(
        "my_settings.popup.emailNotifications.DISCOVERY_OWNERSHIP_CHANGED_OR_DELETED",
        "Discovery ownership changed or discovery deleted"
      )
    },
    {
      code: "PROMOTION_REQUESTED",
      text: s(
        "my_settings.popup.emailNotifications.PROMOTION_REQUESTED",
        "Promotion requested"
      )
    }
  ];

  return userSettings && loadingState === LoadingState.COMPLETED ? (
    <Modal title={headerText} visible={visible} onCancel={onClose}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Tabs
          defaultActiveKey={
            generalTabEnabled ? TabKeys.GENERAL : TabKeys.EMAIL_NOTIFICATIONS
          }
          style={{ marginBottom: 24 }}
        >
          {generalTabEnabled ? (
            <TabPane tab={generalTab} key={TabKeys.GENERAL}>
              <FormItem
                label={crmBccEmailText}
                name="crmBccEmail"
                rules={[
                  {
                    type: "email",
                    message: emailValidation
                  }
                ]}
              >
                <Input
                  block
                  placeholder={emailPlaceholder}
                  onChange={onChange}
                />
              </FormItem>
            </TabPane>
          ) : null}
          <TabPane tab={emailNotificationTab} key={TabKeys.EMAIL_NOTIFICATIONS}>
            <Space size={16} direction={"vertical"}>
              <Text variant={"bMedium"}>{emailNotificationHeaderText}</Text>
              <FormItem name="emailNotifications">
                <CheckboxGroup onChange={onChange}>
                  <Space direction={"vertical"}>
                    {options.map((option, index) => (
                      <Row key={index}>
                        <Checkbox value={option.code}>
                          <Text>{option.text}</Text>
                        </Checkbox>
                      </Row>
                    ))}
                  </Space>
                </CheckboxGroup>
              </FormItem>
              <Text variant={"small"} color={"gray4"}>
                {emailNotificationFootnoteText}
              </Text>
            </Space>
          </TabPane>
        </Tabs>
        <FormItem>
          <ButtonGroup>
            <NewButton type={"submit"} disabled={!dirty}>
              {okText}
            </NewButton>
            <NewButton type={"secondary"} onClick={onClose}>
              {cancelText}
            </NewButton>
          </ButtonGroup>
        </FormItem>
      </Form>
    </Modal>
  ) : null;
};

MySettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_USER_SETTINGS_REQUEST
  ),
  userSettings: selectRequestData(state, actionTypes.GET_USER_SETTINGS_REQUEST),
  setLoadingState: selectRequestState(
    state,
    actionTypes.SET_USER_SETTINGS_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserSettings,
      setUserSettings
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MySettingsModal);
