import {
  isFileListErrorFree,
  selectAllFiles,
  selectFileListValidationIds,
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import NewButton from "../../../../components/NewButton";
import { message, Space } from "antd";
import { useString as s } from "../../../../components/StringProvider";
import styled from "styled-components";
import { FileDragger } from "../components";
import React, { useEffect, useState } from "react";
import PowerpointFileStatus from "./PowerpointFileStatus";
import {
  addFileToList,
  clearFileList
} from "../../../../store/actions/file-list";
import ButtonGroup from "../../../../components/ButtonGroup";
import { deployPowerpointTemplates } from "../../../../store/actions/templates";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import JobMonitor from "../../../../components/JobMonitor";
import { Modal } from "../../../../components";

const UpdatePowerpointTemplatesModal = ({
  onClose,
  onCloseAndOpenMasterData,
  files,
  clearFileList,
  addFileToList,
  canDeploy,
  deployPowerpointTemplates,
  validationIds,
  deployPowerpointLoadingState,
  deployPowerpointData
}) => {
  const [ready, setReady] = useState(false);
  const [gotoMasterData, setGotoMasterData] = useState(false);
  const [jobId, setJobId] = useState("");
  const titleText = s(
    "update_powerpoint_templates.popup.header",
    "Upload Templates"
  );
  const uploadFiles = s(
    "update_powerpoint_templates.popup.uploadFiles.text",
    "Upload Files"
  );
  const uploadFilesAndGotoMasterData = s(
    "update_powerpoint_templates.popup.uploadFilesAndGotoMasterData.text",
    "Upload & Go to Master Data"
  );
  const deploymentSuccess = s(
    "update_powerpoint_templates.popup.messages.deploymentSuccess",
    "PowerPoint templates were updated"
  );
  const deploymentFailure = s(
    "update_powerpoint_templates.popup.messages.deploymentFailure",
    "Failed to update PowerPoint templates"
  );
  const fileDescription = s(
    "update_powerpoint_templates.popup.fileDragger.description",
    "Make sure your template name matches the Master Data record."
  );

  useEffect(() => {
    clearFileList();
    setJobId("");
    setGotoMasterData(false);
  }, []);

  useEffect(() => {
    if (files && files.length === 0) {
      setReady(true);
    }
  }, [files]);

  useLoadingState(
    deployPowerpointLoadingState,
    () => {
      setJobId(deployPowerpointData._id);
    },
    () => {}
  );

  const onJobFinish = (successful) => {
    if (successful) {
      message.success(deploymentSuccess);
      if (gotoMasterData) {
        onCloseAndOpenMasterData();
      } else {
        onClose();
      }
    } else {
      message.error(deploymentFailure);
      setJobId("");
    }
  };

  const onStartUpload = (file) => {
    addFileToList({ file });
  };
  const onUploadFiles = () => {
    deployPowerpointTemplates({ validationIds });
  };

  const onUploadFilesAndGotoMasterData = () => {
    setGotoMasterData(true);
    deployPowerpointTemplates({ validationIds });
  };

  return (
    ready && (
      <Modal
        title={titleText}
        open={true}
        onCancel={onClose}
        style={{ minWidth: 860, maxWidth: 860, maxHeight: 860, minHeight: 860 }}
      >
        <Container direction={"vertical"} size={24}>
          <FileDragger
            onStartUpload={onStartUpload}
            acceptTypes={".pptx"}
            multiple={true}
            description={fileDescription}
          />
          {files.length > 0 && (
            <div>
              {files.map((file) => (
                <PowerpointFileStatus key={file.uid} file={file} />
              ))}
            </div>
          )}
          {canDeploy && (
            <ButtonGroup>
              <NewButton
                type={"primary"}
                onClick={onUploadFiles}
                disabled={!!jobId}
              >
                {uploadFiles}
              </NewButton>
              <NewButton
                type={"secondary"}
                onClick={onUploadFilesAndGotoMasterData}
                disabled={!!jobId}
              >
                {uploadFilesAndGotoMasterData}
              </NewButton>
            </ButtonGroup>
          )}
          {jobId && <JobMonitor jobId={jobId} onFinish={onJobFinish} />}
        </Container>
      </Modal>
    )
  );
};

const Container = styled(Space)`
  width: 100%;
`;

const mapStateToProps = (state) => ({
  files: selectAllFiles(state),
  canDeploy: isFileListErrorFree(state),
  validationIds: selectFileListValidationIds(state),
  deployPowerpointLoadingState: selectRequestState(
    state,
    actionTypes.DEPLOY_POWERPOINT_TEMPLATE_REQUEST
  ),
  deployPowerpointData: selectRequestData(
    state,
    actionTypes.DEPLOY_POWERPOINT_TEMPLATE_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearFileList,
      addFileToList,
      deployPowerpointTemplates
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UpdatePowerpointTemplatesModal
);
