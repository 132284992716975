export const getNotificationContext = (notification) => {
  const context = { message: "undefined", ...notification.data };

  switch (context.objectType) {
    case "questions":
      context.objectURL = `/discoveries/${context.discoveryId}/questions/${context.objectCode}`;
      context.objectTypeName = "question";
      break;

    case "challenges":
      context.objectURL = `/discoveries/${context.discoveryId}/select-challenges`;
      context.objectTypeName = "challenge";
      break;

    case "kpis":
      context.objectURL = `/discoveries/${context.discoveryId}/select-kpis`;
      context.objectTypeName = "outcome";
      break;

    case "metrics":
      context.objectURL = `/discoveries/${context.discoveryId}/business-metrics`;
      context.objectTypeName = "input";
      break;

    case "roi":
      context.objectURL = `/discoveries/${context.discoveryId}/roi`;
      context.objectTypeName = "";
      break;
  }

  return context;
};
