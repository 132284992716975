import styled from "styled-components";
import React from "react";
import { themeProp } from "../../theme";

const ReportPrintLayout = ({ children }) => <Container>{children}</Container>;

const Container = styled.div`
  min-width: 100%;
  min-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;

  & .selectable-card {
    box-shadow: none;
    border-color: ${themeProp("palette.gray2")};
  }

  & .financial-card {
    box-shadow: none;
    border-color: ${themeProp("palette.gray2")};
  }
`;

export default ReportPrintLayout;
