import Permissions from "../../../../utils/permissions";

const getRoleFormConfig = ({ s, isCuvamaUser }) => [
  {
    groupId: 1,
    name: s(
      "accessManagement.page.roleForm.group1.name",
      "Discovery Content Permissions"
    ),
    items: [
      {
        name: Permissions.EDIT_KPIS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EDIT_KPIS",
          "Edit outcomes"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EDIT_KPIS",
          'Access the "Edit card" option on outcome cards; edit the name and description of a Master Data-defined challenge for only the current discovery; edit the name, description, category, challenges solved, and impact for custom outcomes; edit the impact value for unquantified custom outcomes on the Value Hypothesis page.'
        )
      },
      {
        name: Permissions.EDIT_KPI_VALUE_WITHOUT_NOTIFICATION,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EDIT_KPI_VALUE_WITHOUT_NOTIFICATION",
          "Edit outcome without notification"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EDIT_KPI_VALUE_WITHOUT_NOTIFICATION",
          "Edit the impact value of an outcome without generating a notification to administrators. Assigned to administrators by default."
        )
      },
      {
        name: Permissions.EDIT_CHALLENGES,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EDIT_CHALLENGES",
          "Edit Challenges"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EDIT_CHALLENGES",
          'Access the "Edit card" option on challenge cards; edit the name of a Master Data-defined challenge for only the current discovery; edit the name, category, and linked outcomess of a custom challenge.'
        )
      },
      {
        name: Permissions.UPDATE_QUESTIONS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.UPDATE_QUESTIONS",
          "Update segmentation questions"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.UPDATE_QUESTIONS",
          "Update the answers to questions."
        )
      },
      {
        name: Permissions.UPDATE_ROI_VALUES,
        label: s(
          "accessManagement.page.roleForm.permissions.label.UPDATE_ROI_VALUES",
          "Update ROI values"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.UPDATE_ROI_VALUES",
          "Update protected investment fields that drive the ROI calculations: Annual Investment, Upfront Investment, Implementation Length, and Adoption Time."
        )
      },
      {
        name: Permissions.SEE_SOLUTIONS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_SOLUTIONS",
          "Access solutions enabler"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_SOLUTIONS",
          "See and update solutions in the solutions enabler."
        )
      },
      {
        name: Permissions.SEE_ALL_METRICS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_ALL_METRICS",
          "See all metrics"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_ALL_METRICS",
          "See the metrics page and all of the metrics in it."
        )
      },
      {
        name: Permissions.SEE_INTERNAL_LINKS_AND_COMMENTS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_INTERNAL_LINKS_AND_COMMENTS",
          "See internal links and comments"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_INTERNAL_LINKS_AND_COMMENTS",
          'See links that have been configured as "internal" in the Master Data. These links can appear on the challenge and outcome cards.'
        )
      },
      {
        name: Permissions.SEE_FINISH_HERE,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_FINISH_HERE",
          'See "Finish here" button'
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_FINISH_HERE",
          'See and click on the "Finish here" button. Appears on the Challenge Summary, Outcomes Summary, Value Hypothesis, and ROI pages and takes the user to the My Discoveries page. Should be revoked for users who cannot see the My Discoveries page.'
        )
      },
      {
        name: Permissions.SEE_DISCOVERY_NOTES,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_DISCOVERY_NOTES",
          "See discovery notes"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_DISCOVERY_NOTES",
          "See and edit discovery notes."
        )
      }
    ]
  },
  {
    groupId: 2,
    name: s(
      "accessManagement.page.roleForm.group2.name",
      "Discovery Content Sharing Permissions"
    ),
    items: [
      {
        name: Permissions.SHARE_EDIT,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SHARE_EDIT",
          "Share edit access and create email"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SHARE_EDIT",
          "Can share edit access to a discovery with another internal user or external email address, and can see and use the email function."
        )
      },
      {
        name: Permissions.SEE_SHARE_BUTTON,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_SHARE_BUTTON",
          "See share button"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_SHARE_BUTTON",
          "See the share button within the discovery."
        )
      },
      {
        name: Permissions.SEE_DISCOVERY_DROPDOWN_MENU,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_DISCOVERY_DROPDOWN_MENU",
          "See discovery dropdown menu"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_DISCOVERY_DROPDOWN_MENU",
          'See the "…" menu in the upper right side of the discovery.'
        )
      },
      {
        name: Permissions.SEE_CLONE_DISCOVERY_BUTTON,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_CLONE_DISCOVERY_BUTTON",
          "See clone discovery button"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_CLONE_DISCOVERY_BUTTON",
          'See the "Clone" button and use it to copy a discovery and its contents.'
        )
      },
      {
        name: Permissions.EXPORT_POWERPOINT,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EXPORT_POWERPOINT",
          "Export PowerPoint"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EXPORT_POWERPOINT",
          "Export discovery data into a pre-configured PowerPoint template."
        )
      },
      {
        name: Permissions.EXPORT_DISCOVERY_EXCEL,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EXPORT_DISCOVERY_EXCEL",
          "Export discovery to Excel"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EXPORT_DISCOVERY_EXCEL",
          "Export the contents of one discovery into an Excel workbook."
        )
      },
      {
        name: Permissions.EXPORT_TO_PDF,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EXPORT_TO_PDF",
          "Export to PDF"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EXPORT_TO_PDF",
          "Export the contents of one discovery into a PDF file."
        )
      }
    ]
  },
  {
    groupId: 3,
    name: s(
      "accessManagement.page.roleForm.group3.name",
      "Discovery Page Permissions"
    ),
    items: [
      {
        name: Permissions.SEE_ROI_PAGE,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_ROI_PAGE",
          "See ROI page"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_ROI_PAGE",
          "Access the Return on Investment page."
        )
      },
      {
        name: Permissions.SEE_SELECT_KPIS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_SELECT_KPIS",
          "See Select Outcomes"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_SELECT_KPIS",
          "Access the Select Outcomes page."
        )
      },
      {
        name: Permissions.SEE_KPI_SUMMARY,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_KPI_SUMMARY",
          "See Outcomes Summary"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_KPI_SUMMARY",
          "Access the KPI Summary page. (Requires: See Select Outcomes)"
        ),
        dependsOn: Permissions.SEE_SELECT_KPIS
      },
      {
        name: Permissions.SEE_SELECT_CHALLENGES,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_SELECT_CHALLENGES",
          "See Select Challenges"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_SELECT_CHALLENGES",
          "Access the Select Challenges page."
        )
      },
      {
        name: Permissions.SEE_CHALLENGE_SUMMARY,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_CHALLENGE_SUMMARY",
          "See Challenges Summary"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_CHALLENGE_SUMMARY",
          "Access the Challenges Summary page. (Requires: See Select Challenges)"
        ),
        dependsOn: Permissions.SEE_SELECT_CHALLENGES
      },
      {
        name: Permissions.SEE_VALUE_HYPOTHESIS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_VALUE_HYPOTHESIS",
          "See Value Hypothesis"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_VALUE_HYPOTHESIS",
          "Access the Value Hypothesis page."
        )
      },

      {
        name: Permissions.SEE_KPI_LIBRARY,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_KPI_LIBRARY",
          "See Outcomes Library"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_KPI_LIBRARY",
          "Access the Outcomes Library."
        )
      },
      {
        name: Permissions.SEE_CHALLENGE_LIBRARY,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_CHALLENGE_LIBRARY",
          "See Challenges Library"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_CHALLENGE_LIBRARY",
          "Access the Challenges Library."
        )
      },
      {
        name: Permissions.SEE_DISCOVERY_HELP,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_DISCOVERY_HELP",
          "See Discovery Help"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_DISCOVERY_HELP",
          "See the Help menu option within a discovery."
        )
      }
    ]
  },
  {
    groupId: 4,
    name: s(
      "accessManagement.page.roleForm.group4.name",
      "Application Access Permissions"
    ),
    items: [
      {
        name: Permissions.SEE_ALL_DISCOVERIES,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_ALL_DISCOVERIES",
          "See all discoveries"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_ALL_DISCOVERIES",
          "See all discoveries, including discoveries owned by other users."
        )
      },
      {
        name: Permissions.SEE_MY_DISCOVERIES,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_MY_DISCOVERIES",
          "See my discoveries"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_MY_DISCOVERIES",
          "See the My Discoveries page and see discoveries owned by this user."
        )
      },
      {
        name: Permissions.SEE_NEW_DISCOVERY_BUTTON,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_NEW_DISCOVERY_BUTTON",
          'See "New Discovery" button'
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_NEW_DISCOVERY_BUTTON",
          'See the "New Discovery" button on the My Discoveries page.'
        )
      },
      {
        name: Permissions.SEE_LEAD_GENERATION,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_LEAD_GENERATION",
          "See Lead Generation"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_LEAD_GENERATION",
          "Access the Lead Generation workflow: a limited version of the discovery workflow that is accessible without first creating a Cuvama account. Usually available from a public-facing website for marketing purposes."
        ),
        disabled: true
      }
    ]
  },
  {
    groupId: 5,
    name: s(
      "accessManagement.page.roleForm.group5.name",
      "Administration Permissions"
    ),
    items: [
      {
        name: Permissions.EXPORT_USERS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EXPORT_USERS",
          "Export users"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EXPORT_USERS",
          "Export a list of users from the user management tabs within the Access Management portal."
        )
      },
      {
        name: Permissions.EXPORT_DISCOVERIES,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EXPORT_DISCOVERIES",
          "Export discoveries"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EXPORT_DISCOVERIES",
          'Access the "Export" menu from the My Discoveries page and export information about all discoveries from it.'
        )
      },
      {
        name: Permissions.SEE_ORG_ADMIN,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_ORG_ADMIN",
          "Organization administration"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_ORG_ADMIN",
          "See the Organization Portal, the Manage Configuration workspace, and the Changes Log within the Manage Configuration workspace; export the original workbook and error log for a configuration change in the Changes Log; create, update, and delete Salesforce connector configuration; upload and deploy CRM data."
        )
      },
      {
        name: Permissions.REQUEST_PROMOTION,
        label: s(
          "accessManagement.page.roleForm.permissions.label.REQUEST_PROMOTION",
          "Request promotion"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.REQUEST_PROMOTION",
          "Request promotion of a configuration change from the sandbox environment to the production environment."
        )
      },
      {
        name: Permissions.PUBLISH_PROMOTION,
        label: s(
          "accessManagement.page.roleForm.permissions.label.PUBLISH_PROMOTION",
          "Publish promotion"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.PUBLISH_PROMOTION",
          "Publish a configuration change from the sandbox environment to the production environment."
        ),
        disabled: !isCuvamaUser
      },
      {
        name: Permissions.UPDATE_CONFIGURATION,
        label: s(
          "accessManagement.page.roleForm.permissions.label.UPDATE_CONFIGURATION",
          "Update configuration"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.UPDATE_CONFIGURATION",
          "Upload and deploy new Master Data configuration files to the sandbox environment."
        )
      },
      {
        name: Permissions.SEE_ACCESS_MANAGEMENT,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_ACCESS_MANAGEMENT",
          "See Access Management"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_ACCESS_MANAGEMENT",
          "See the Access Management workspace within the Organization Portal."
        )
      },
      {
        name: Permissions.EDIT_ACCESS_MANAGEMENT,
        label: s(
          "accessManagement.page.roleForm.permissions.label.EDIT_ACCESS_MANAGEMENT",
          "Edit users and roles"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.EDIT_ACCESS_MANAGEMENT",
          "Add, edit, and delete roles; add, edit, and deactivate users."
        )
      }
    ]
  },
  {
    groupId: 6,
    name: s("accessManagement.page.roleForm.group6.name", "Beta Features"),
    items: [
      {
        name: Permissions.SEE_CANVAS,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_CANVAS",
          "Can access Canvas"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_CANVAS",
          "Access the Canvas feature within a discovery"
        )
      },
      {
        name: Permissions.SEE_AI_EMAIL_TEMPLATES,
        label: s(
          "accessManagement.page.roleForm.permissions.label.SEE_AI_EMAIL_TEMPLATES",
          "Can access generative AI feature"
        ),
        description: s(
          "accessManagement.page.roleForm.permissions.description.SEE_AI_EMAIL_TEMPLATES",
          "Access generative AI feature"
        )
      }
    ]
  }
];

export default getRoleFormConfig;
