import { NewButton } from "../../components";
import styled from "styled-components";
import { themeProp } from "../../theme";

const QuestionButton = ({ tooltip, ...props }) => {
  return <StyledButton type={"iconSecondary"} tooltip={tooltip} {...props} />;
};

const StyledButton = styled(NewButton)`
  min-width: 24px !important;
  min-height: 24px !important;
  padding: 0 !important;
  color: ${themeProp(`palette.gray4`)} !important;

  &:hover {
    color: ${themeProp(`components.button.secondary.hover`)} !important;
  }
`;

export default QuestionButton;
