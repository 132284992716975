import NewButton from "../../../../components/NewButton";
import Icon from "../../../../components/Icon";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { downloadFile } from "../../../../store/actions/files";

const DownloadFileIconButton = ({ fileId, downloadFile }) => (
  <NewButton
    type={"iconPrimary"}
    onClick={() => {
      downloadFile({ fileId });
    }}
  >
    <Icon name={"download"} />
  </NewButton>
);

DownloadFileIconButton.propTypes = {
  fileId: PropTypes.string.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      downloadFile
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DownloadFileIconButton
);
