import React from "react";
import { useString as s } from "../StringProvider";
import Form, { FormItem } from "../Form";
import PropTypes from "prop-types";
import NewButton from "../NewButton";
import SelectExportTemplate from "./SelectExportTemplate";
import { selectRequestData, selectRequestState } from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import useLoadingState from "../../utils/use-loading-state";
import ButtonGroup from "../ButtonGroup";
import { exportDiscovery } from "../../store/actions/discoveries";
import Modal from "../Modal";

const ExportPowerpointModal = ({
  discoveryId,
  reportId,
  templates,
  templatesLoadingState,
  exportDiscovery,
  onClose
}) => {
  const [form] = Form.useForm();
  const okText = s("export_powerpoint.popup.button1", "Export");
  const titleText = s("export_powerpoint.popup.header", "Export PPT");
  const templateText = s(
    "export_powerpoint.popup.box1.header",
    "Discovery Template"
  );
  const cancelText = s("export_powerpoint.popup.cancel", "Cancel");

  useLoadingState(
    templatesLoadingState,
    () => {
      if (templates && templates.length) {
        form.setFieldsValue({ template: templates[0].code });
      }
    },
    () => {}
  );

  const onFinish = async ({ template }) => {
    exportDiscovery({ reportId, template, format: "powerpoint" });
    onClose();
  };

  return (
    <Modal
      title={titleText}
      open={true}
      onCancel={onClose}
      data-cy={"export-powerpoint-modal"}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem label={templateText} name="template">
          <SelectExportTemplate
            discoveryId={discoveryId}
            format={"powerpoint"}
          />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <NewButton type={"submit"} data-cy={"export-button"}>
              {okText}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onClose}
              data-cy={"cancel-button"}
            >
              {cancelText}
            </NewButton>
          </ButtonGroup>
        </FormItem>
      </Form>
    </Modal>
  );
};

ExportPowerpointModal.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  templates:
    selectRequestData(
      state,
      actionTypes.GET_DISCOVERY_EXPORT_TEMPLATES_REQUEST
    ) || [],
  templatesLoadingState: selectRequestState(
    state,
    actionTypes.GET_DISCOVERY_EXPORT_TEMPLATES_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExportPowerpointModal
);
