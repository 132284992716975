import styled from "styled-components";
import React from "react";
import PoweredByCuvama from "../PoweredByCuvama";

const CenteredPageLayout = ({ children, ...props }) => (
  <Container {...props}>
    <Main>{children}</Main>
    <PoweredByCuvama />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

export default CenteredPageLayout;
