import styled from "styled-components";
import Heading from "../../Heading";
import { useString as s } from "../../StringProvider";
import Range from "../../Range";
import TooltipIcon from "../../TooltipIcon";
import KPITypes from "../../../utils/kpi-types";
import KPIImpactFormats from "../../../utils/kpi-impact-formats";
import PropType from "prop-types";
import Text from "../../Text";
import { useMobileMediaQuery } from "../../Responsive";
import Tooltip from "../../Tooltip";

const ImpactForYou = ({ kpi, disabled }) => {
  const isMobile = useMobileMediaQuery();
  const tooltipText = s(
    "discovery.kpi.card.impact.multiplier.tooltip",
    "The multiplier is calculated as the new value divided by current value"
  );
  const notQuantifiedText = s(
    "discovery.kpi.card.impact.notQuantified",
    "Not quantified"
  );

  return (
    <Container type={kpi.type} className={isMobile ? "mobile" : undefined}>
      <Heading
        level={isMobile ? "h5Small" : "h5"}
        color={"gray3"}
        style={{
          paddingRight: kpi.type === KPITypes.FIXED_PERCENT ? 4 : undefined
        }}
      >
        {s("discovery.kpi.card.impact.title", "Impact for You")}
      </Heading>
      {kpi.hasImpactPercentage ? (
        <Text
          variant={isMobile ? "bMediumMobile" : "h4"}
          color={disabled ? "gray3" : undefined}
        >
          <Range
            first={kpi.lowImpactPercentage}
            second={kpi.highImpactPercentage}
            type={kpi.definition.impactFormat}
            customImpactFormat={kpi.definition.customImpactFormat}
            impactPercentageFormulaDescription={
              kpi.definition.impactPercentageFormulaDescription
            }
          />
          {kpi.definition.impactFormat === KPIImpactFormats.MULTIPLIER ? (
            <>
              &nbsp;
              <TooltipIcon title={tooltipText} disabled={disabled} />
            </>
          ) : null}
        </Text>
      ) : (
        <Tooltip title={kpi?.definition?.impactPercentageFormulaDescription}>
          <Heading level={"h4"} color={disabled ? "gray3" : undefined}>
            {notQuantifiedText}
          </Heading>
        </Tooltip>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.type === "fixedPercent" ? "flex-start" : "space-between"};
  align-items: flex-start;

  &.mobile {
    margin-bottom: 0;
  }
`;

ImpactForYou.propTypes = {
  disabled: PropType.bool
};

export default ImpactForYou;
