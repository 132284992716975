import NewButton from "../../../../NewButton";
import PropTypes from "prop-types";

const ToolbarButton = ({ label, active, children, onClick, ...props }) => {
  return (
    <NewButton
      onClick={onClick}
      aria-label={label}
      tooltip={label}
      type={"iconPrimary"}
      active={active}
      {...props}
    >
      {children}
    </NewButton>
  );
};

ToolbarButton.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default ToolbarButton;
