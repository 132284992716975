import PropType from "prop-types";
import styled from "styled-components";
import Link from "./Link";
import { themeProp } from "../theme";
import Tooltip from "./Tooltip";

const isExternalLink = (destination) =>
  destination.startsWith("mailto") || !destination.startsWith("/");

const Hyperlink = ({ to, color, tooltip, children, ...props }) => {
  return (
    <Tooltip title={tooltip}>
      {isExternalLink(to) ? (
        <StyledLink
          href={to}
          color={color}
          {...props}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          {children}
        </StyledLink>
      ) : (
        <Link to={to} color={color} {...props}>
          {children}
        </Link>
      )}
    </Tooltip>
  );
};

Hyperlink.propTypes = {
  to: PropType.string.isRequired,
  color: PropType.oneOf(["primary", "secondary"]),
  tooltip: PropType.string
};

Hyperlink.defaultProps = {
  color: "primary"
};

const StyledLink = styled.a`
  color: ${({ color }) =>
    themeProp(`components.link.${color}.base`)} !important;
  transition: color 0.1s ease-out !important;
  text-decoration: none !important;

  &:hover {
    color: ${({ color }) =>
      themeProp(`components.link.${color}.hover`)} !important;
  }
  &:focus {
    color: ${({ color }) =>
      themeProp(`components.link.${color}.focus`)} !important;
  }
  &[disabled] {
    color: ${({ color }) =>
      themeProp(`components.link.${color}.disabled`)} !important;
  }
`;

export default Hyperlink;
