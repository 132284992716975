import styled from "styled-components";
import { Radio as ANTRadio } from "antd";
import { themeProp } from "../theme";

const Radio = styled(ANTRadio)`
  & .ant-radio-checked::after {
    border: 1px solid ${themeProp("palette.secondary")};
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: ${themeProp("palette.secondary")};
  }

  & .ant-radio-inner::after {
    background-color: ${themeProp("palette.surface")};
  }

  & .ant-radio-checked .ant-radio-inner {
    background-color: ${themeProp("palette.secondary")};
  }

  & .ant-radio-inner {
    background-color: ${themeProp("palette.surface")};
  }

  & .ant-radio-checked.ant-radio-disabled .ant-radio-inner {
    background-color: ${themeProp("palette.secondary")};
  }

  & .ant-radio-checked.ant-radio-disabled .ant-radio-inner::after {
    background-color: ${themeProp("palette.surface")};
  }
`;

export default Radio;

export const RadioGroup = ANTRadio.Group;
