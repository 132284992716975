import types from "../actionTypes";

export const openConfigurationModal =
  ({ modal }) =>
  (dispatch) => {
    dispatch({
      type: types.OPEN_CONFIGURATION_MODAL,
      payload: { modal }
    });
  };

export const closeConfigurationModal = () => (dispatch) => {
  dispatch({
    type: types.CLOSE_CONFIGURATION_MODAL
  });
};
