import httpService from "./http.service";

const getStrings = () =>
  httpService
    .get(`/strings`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const downloadStrings = () =>
  httpService
    .post(`/strings/download`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getStrings,
  downloadStrings
};
export default methods;
