import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TooltipIcon from "../TooltipIcon";
import Text from "../Text";
import { useMobileMediaQuery } from "../Responsive";

const InputGroup = ({ title, size, help, hidden, children, ...props }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <InputGroupContainer hidden={hidden} {...props}>
      <InputGroupTitle help={help} size={size}>
        {title}
      </InputGroupTitle>
      <Row className={isMobile ? "mobile" : undefined}>{children}</Row>
    </InputGroupContainer>
  );
};

const InputGroupTitle = ({ help, children, size }) =>
  children ? (
    <Text variant={size === "large" ? "body" : "bodyMobile"}>
      {children} <TooltipIcon title={help} />
    </Text>
  ) : null;

const InputGroupContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  position: relative;

  .ant-form-item {
    margin-bottom: 0px !important;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  &.mobile {
    flex-direction: column;
    align-items: stretch;
  }
`;

InputGroup.propTypes = {
  title: PropTypes.node,
  help: PropTypes.node,
  hidden: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium"])
};

InputGroup.defaultProps = {
  size: "large"
};

export default InputGroup;
