import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { CostOfStatusQuoChart, FinancialCards, Statistics } from "./components";
import { DiscoveryFooter, DiscoveryTitleBar } from "../../components/Discovery";
import { useString as s } from "../../components/StringProvider";
import Heading from "../../components/Heading";
import PropTypes from "prop-types";
import {
  hasPermission,
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryName,
  selectDiscoverySelectedKPIs
} from "../../store/reducers";
import { Space } from "antd";
import NewButton from "../../components/NewButton";
import MobileReportTitle from "../Report/components/MobileReportTitle";
import {
  FinishDiscoveryButton,
  GoToROIButton,
  RequestEvaluationButton
} from "../../components/DiscoveryButtons";
import { useMobileMediaQuery } from "../../components/Responsive";
import { Icon } from "../../components";
import Permissions from "../../utils/permissions";

const FooterButtons = ({ reportMode, discoveryId }) => {
  return reportMode ? null : (
    <DiscoveryFooter>
      <GoToROIButton discoveryId={discoveryId} />
      <RequestEvaluationButton />
      <FinishDiscoveryButton />
    </DiscoveryFooter>
  );
};

const ValueHypothesisReport = ({
  reportId,
  discoveryId,
  discoveryName,
  discoveryKPIs,
  currency,
  canSeeLeadGeneration
}) => {
  const isMobile = useMobileMediaQuery();
  const [isShowAllChallenges, setShowAllChallenges] = useState(false);
  const reportMode = !!reportId;
  const hideAllChallenges = s(
    "discovery.opportunity.page.show_challenges.text",
    "Show all challenges"
  );
  const showAllChallenges = s(
    "discovery.opportunity.page.hide_challenges.text",
    "Hide all challenges"
  );
  const showAllHypothesesButton = s(
    "report.valueHypothesis.showAllHypotheses",
    "Show all hypotheses"
  );
  const hideHypothesesButton = s(
    "report.valueHypothesis.hideHypotheses",
    "Hide hypotheses"
  );
  const header = s(
    "discovery.opportunity.page.header",
    "Value Hypothesis for {discoveryName}",
    { discoveryName }
  );
  const subheader = s(
    "discovery.opportunity.page.subheader",
    "How {companyName} helps companies like you"
  );
  const subheaderReport = s(
    "report.valueHypothesis.subheader",
    "How {companyName} helps companies like you"
  );
  const header2 = s("discovery.opportunity.page.header2", "Cost of Status Quo");
  const subheader2 = s(
    "discovery.opportunity.page.subheader2",
    "The cost to you if you choose not to use the {companyName} platform"
  );
  const [showAllHypotheses, setShowAllHypotheses] = useState(false);

  const onToggleAllChallenges = () => {
    setShowAllChallenges(!isShowAllChallenges);
  };

  const onToggleAllHypotheses = () => {
    setShowAllHypotheses(!showAllHypotheses);
  };

  return isMobile ? (
    <>
      <MobileReportTitle
        header={header}
        subheader={reportId ? subheaderReport : subheader}
        headerLevel={"h3"}
      />
      <Space direction={"vertical"} size={16} style={{ marginBottom: "40px" }}>
        <Statistics currency={currency} kpis={discoveryKPIs} />
        <FinancialCards
          isShowAllChallenges={isShowAllChallenges}
          showAllHypotheses={showAllHypotheses}
          reportId={reportId}
        />
        {discoveryKPIs.length > 4 ? (
          <ButtonContainer>
            <NewButton type={"text"} onClick={onToggleAllHypotheses}>
              {showAllHypotheses
                ? hideHypothesesButton
                : showAllHypothesesButton}
            </NewButton>
          </ButtonContainer>
        ) : null}
      </Space>
      <MobileReportTitle
        header={header2}
        subheader={subheader2}
        headerLevel={"h2"}
      />
      <CostOfStatusQuoChart
        kpis={discoveryKPIs}
        currency={currency}
        reportMode={reportMode}
      />
      <FooterButtons reportMode={reportMode} discoveryId={discoveryId} />
    </>
  ) : (
    <Wrapper>
      <WrapperInnerTop>
        <WrapperInnerHeader>
          <DiscoveryTitleBar>
            <Heading level={reportMode ? "h1" : "h2"}>{header}</Heading>
          </DiscoveryTitleBar>
          <DiscoveryTitleBar style={{ marginBottom: 16 }}>
            <div>{reportId ? subheaderReport : subheader}</div>
            {!canSeeLeadGeneration && (
              <NewButton
                type={"secondaryDarkText"}
                onClick={onToggleAllChallenges}
              >
                <Icon
                  name={isShowAllChallenges ? "collapse" : "expand"}
                  size={"large"}
                />
                {isShowAllChallenges ? showAllChallenges : hideAllChallenges}
              </NewButton>
            )}
          </DiscoveryTitleBar>
        </WrapperInnerHeader>
        <WrapperInnerBody>
          <WrapperInnerTopLeft>
            <FinancialCards
              isShowAllChallenges={isShowAllChallenges}
              reportId={reportId}
              showAllHypotheses={true}
            />
          </WrapperInnerTopLeft>
          <WrapperInnerTopRight>
            <Statistics currency={currency} kpis={discoveryKPIs} />
          </WrapperInnerTopRight>
        </WrapperInnerBody>
      </WrapperInnerTop>
      <WrapperInnerBottom reportMode={reportMode}>
        <Header className="up-cosq-title" style={{ marginTop: 40 }}>
          <Heading level={"h2"}>{header2}</Heading>
          {subheader2}
        </Header>
        <CostOfStatusQuoChart
          kpis={discoveryKPIs}
          currency={currency}
          reportMode={reportMode}
        />
        <FooterButtons reportMode={reportMode} discoveryId={discoveryId} />
      </WrapperInnerBottom>
    </Wrapper>
  );
};

ValueHypothesisReport.propTypes = {
  reportId: PropTypes.string,
  canSeeFinishHere: PropTypes.bool,
  canSeeROIPage: PropTypes.bool
};

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media print {
    page-break-before: always;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const WrapperInnerHeader = styled.div`
  width: 100%;
`;

const WrapperInnerBody = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: column;

  @media screen and (min-width: 1160px) {
    flex-direction: row;
  }
`;

const WrapperInnerTop = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperInnerTopRight = styled.div`
  min-width: 284px;
`;

const WrapperInnerTopLeft = styled.div``;

const WrapperInnerBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

WrapperInnerBottom.propTypes = {
  reportMode: PropTypes.bool
};

WrapperInnerBottom.defaultProps = {
  reportMode: true
};

function mapStateToProps(state) {
  return {
    discoveryId: selectDiscoveryId(state),
    discoveryName: selectDiscoveryName(state),
    currency: selectDiscoveryCurrency(state),
    discoveryKPIs: selectDiscoverySelectedKPIs(state),
    canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION)
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ValueHypothesisReport
);
