import ExpandedOrCollapsed from "../../utils/ExpandedOrCollapsed";
import { selectDiscoveryQuestions } from "../reducers";

export const selectImpactValue = (scenario) => {
  switch (scenario) {
    case 0:
      return "lowImpactValue";
    case 50:
      return "expectedImpactValue";
    case 100:
      return "potentialImpactValue";
    default:
      return "expectedImpactValue";
  }
};

export const isDiscoveryGroupCollapsed = (groups, groupId) => {
  if (groups && groups[groupId] !== undefined) {
    return groups[groupId] === ExpandedOrCollapsed.COLLAPSED;
  }

  return undefined;
};

export const isLastDiscoveryQuestion = (allQuestions, questionNumber) => {
  let nextQuestion;

  for (let i = questionNumber + 1; i < allQuestions.length; i++) {
    if (allQuestions[i].active) {
      nextQuestion = i;
      break;
    }
  }

  return nextQuestion === undefined;
};

export const isLastQuestion = (state, questionNumber) => {
  const allQuestions = selectDiscoveryQuestions(state);
  return isLastDiscoveryQuestion(allQuestions, questionNumber);
};
