import React from "react";
import Form, { FormItem } from "../../components/Form";
import Input, { PasswordInput } from "../../components/Input";
import LoginFormContainer, {
  FormContainer,
  ResendContainer
} from "../Login/LoginFormContainer";
import Heading from "../../components/Heading";
import { useString as s } from "../../components/StringProvider";
import Markdown from "../../components/Discovery/Markdown";
import ButtonGroup from "../../components/ButtonGroup";
import NewButton from "../../components/NewButton";
import { CognitoError } from "../../components";

const VerifyAccountForm = ({ form, onFinish, error, email, onResendCode }) => {
  const pageHeader = s("verify.account.page.header", "Verify Account");
  const pageSubheader = s(
    "verify.account.page.subheader",
    "We sent a code to **{email}**. Please check your inbox, enter the code below, and create a password for your account.",
    { email }
  );
  const codePlaceholder = s(
    "verify.account.code.placeholder",
    "Code from email"
  );
  const codeValidationError = s(
    "verify.account.code.validation",
    "Code from email"
  );
  const passwordPlaceholder = s(
    "verify.account.password.placeholder",
    "Password"
  );
  const passwordValidationError = s(
    "verify.account.password.validation",
    "Please enter a password of at least 8 characters"
  );
  const confirmPasswordValidationError = s(
    "verify.account.confirmPassword.validation",
    "Please confirm your password"
  );
  const confirmPasswordMismatchError = s(
    "verify.account.confirmPassword.mismatch",
    "Confirm password must match password"
  );
  const confirmPasswordPlaceholder = s(
    "verify.account.confirmPassword.placeholder",
    "Confirm password"
  );
  const verifyAccount = s("verify.account.page.button", "Verify account");
  const haventReceived = s(
    "verify.account.haventReceived",
    "Haven’t received the verification code?"
  );
  const resendCode = s("verify.account.resendCode", "Resend");

  return (
    <LoginFormContainer>
      <FormContainer>
        <Heading level={"h2"} data-cy={"page-header"}>
          {pageHeader}
        </Heading>
        <div className={"subheader"} data-cy={"page-subheader"}>
          <Markdown remarkPlugins={[]}>{pageSubheader}</Markdown>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout={"vertical"}
          className={"login"}
        >
          <FormItem
            name="code"
            rules={[{ required: true, message: codeValidationError }]}
          >
            <Input
              placeholder={codePlaceholder}
              autoComplete={"one-time-code"}
            />
          </FormItem>

          <FormItem
            name="password"
            rules={[
              {
                required: true,
                message: passwordValidationError
              },
              {
                min: 8,
                message: passwordValidationError
              }
            ]}
            hasFeedback
          >
            <PasswordInput
              autoComplete="new-password"
              placeholder={passwordPlaceholder}
            />
          </FormItem>
          <FormItem
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: confirmPasswordValidationError
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(confirmPasswordMismatchError)
                  );
                }
              })
            ]}
            hasFeedback
            dependencies={["password"]}
          >
            <PasswordInput
              autoComplete="new-password"
              placeholder={confirmPasswordPlaceholder}
            />
          </FormItem>
          {error ? <CognitoError error={error} /> : null}
          <ButtonGroup mobile={true}>
            <NewButton type={"submit"} data-cy={"verify-account-button"}>
              {verifyAccount}
            </NewButton>
          </ButtonGroup>
        </Form>
        <ResendContainer>
          {haventReceived}{" "}
          <span>
            <NewButton
              type={"text"}
              onClick={onResendCode}
              data-cy={"resend-code-button"}
            >
              {resendCode}
            </NewButton>
          </span>
        </ResendContainer>
      </FormContainer>
    </LoginFormContainer>
  );
};

export default VerifyAccountForm;
