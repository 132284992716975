import types from "../actionTypes";

export const startROIOverrideOperation =
  ({ kpiCode, outcomeOverrideType, isEdit }) =>
  (dispatch) => {
    dispatch({
      type: types.START_ROI_OVERRIDE_OPERATION,
      payload: { kpiCode, outcomeOverrideType, isEdit }
    });
  };

export const setROIFormValues =
  ({
    evaluationPeriod,
    benefitAdjustmentPerYear,
    adoptionTime,
    implementationLength
  }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_ROI_FORM_VALUES,
      payload: {
        evaluationPeriod,
        benefitAdjustmentPerYear,
        adoptionTime,
        implementationLength
      }
    });
  };

export const setROIFormSubmitted =
  ({ roiFormSubmitted }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_ROI_FORM_SUBMITTED,
      payload: roiFormSubmitted
    });
  };

export const stopROIOverrideOperation = () => (dispatch) => {
  dispatch({
    type: types.STOP_ROI_OVERRIDE_OPERATION
  });
};
