import Form, { FormItem } from "../../Form";
import { useString as s } from "../../StringProvider";
import React, { useEffect, useRef } from "react";
import { Space } from "antd";
import PropType from "prop-types";
import ChallengeDescriptionTextArea from "./ChallengeDescriptionTextArea";
import SelectCategory from "../SelectCategory";
import NewButton from "../../NewButton";
import AboutThisChallenge from "../ChallengeCard/AboutThisChallenge";
import ChallengeTable from "../ChallengeCard/ChallengeTable";
import ButtonGroup from "../../ButtonGroup";
import { useMobileMediaQuery } from "../../Responsive";

const NewChallengeForm = ({
  form,
  error,
  discoveryChallenge,
  isEdit,
  onFinish,
  onCancel
}) => {
  const isMobile = useMobileMediaQuery();
  const okText = s("createChallenge.popup.save.button", "Save card");
  const changeCancelText = s(
    "createChallenge.popup.cancel.button",
    "Don't add"
  );
  const addCancelText = s("createChallenge.popup.cancel.button", "Don't add");
  const descriptionValidationText = s(
    "createChallenge.popup.description.validationText",
    "Please add a challenge description to save"
  );
  const descriptionPlaceholder = s(
    "createChallenge.popup.description.placeholder",
    "Type challenge name"
  );
  const categoryPlaceholder = s(
    "createChallenge.popup.categoryCode.placeholder",
    "Select category"
  );
  const duplicateErrorText = s(
    "createChallenge.popup.name.duplicateError",
    "Outcome name already exists"
  );
  const textRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (textRef.current) {
        textRef.current.focus({
          cursor: isEdit ? "end" : "start",
          preventScroll: true
        });
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [textRef]);

  useEffect(() => {
    if (error && error.status === 409) {
      form.setFields([
        {
          name: "description",
          errors: [duplicateErrorText]
        }
      ]);
      setTimeout(() => {
        textRef.current.focus({
          cursor: "end"
        });
      }, 1);
    }
  }, [error, textRef]);

  const onFormSubmit = (values) => {
    onFinish(values);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{}}
      onFinish={onFormSubmit}
    >
      <Space direction={"vertical"} size={18} style={{ width: "100%" }}>
        <FormItem
          name="description"
          data-cy={"form-item-challenge-description"}
          rules={[
            {
              required: true,
              message: descriptionValidationText,
              whitespace: true
            }
          ]}
        >
          <ChallengeDescriptionTextArea
            autoSize={true}
            placeholder={descriptionPlaceholder}
            ref={textRef}
          />
        </FormItem>
        <FormItem
          name="categoryCode"
          rules={[
            {
              whitespace: true
            }
          ]}
        >
          <SelectCategory
            placeholder={categoryPlaceholder}
            style={isMobile ? undefined : { maxWidth: 200 }}
            data-cy={"select-category"}
          />
        </FormItem>
        <ChallengeTable
          discoveryChallenge={discoveryChallenge}
          form={true}
          editable={!isEdit}
        />
        <AboutThisChallenge
          discoveryChallenge={discoveryChallenge}
          editMode={true}
        />
        <FormItem>
          <ButtonGroup>
            <NewButton type={"submit"}>{okText}</NewButton>
            <NewButton
              type={"secondary"}
              onClick={onCancel}
              data-cy={"cancel-button"}
            >
              {isEdit ? changeCancelText : addCancelText}
            </NewButton>
          </ButtonGroup>
        </FormItem>
      </Space>
    </Form>
  );
};

NewChallengeForm.propTypes = {
  discoveryChallenge: PropType.object,
  form: PropType.object.isRequired,
  onFinish: PropType.func.isRequired,
  onCancel: PropType.func.isRequired,
  error: PropType.object
};

export default NewChallengeForm;
