import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import Page from "../../components/Page";
import ReportHeaderFull from "./components/ReportIntroFull";
import ReportChallenges from "./components/ReportChallenges";
import ReportKPI from "./components/ReportKPI";
import { fetchDiscoveryByReportId } from "../../store/actions/discoveries";
import {
  selectCommentCountError,
  selectCommentCountLoadingState,
  selectDiscovery,
  selectDiscoveryLoadingError,
  selectDiscoveryLoadingState,
  selectDiscoveryScenario,
  selectMasterDataLoadingError,
  selectMasterDataLoadingState,
  selectRequestData,
  selectUserInfoLoadingState
} from "../../store/reducers";
import ReportHeaderPartial from "./components/ReportIntroPartial";
import { isPhaseLessThanOrEqualTo, Phases } from "../../utils/phases";
import ReportValueHypothesis from "./components/ReportValueHypothesis";
import ReportYourSituation from "./components/ReportYourSituation/ReportYourSituation";
import reportsService from "../../services/reports.service";
import ReportOutro from "./components/ReportOutro";
import ReportROI from "./components/ReportROI";
import { FullPageLayout } from "../../components/Layout";
import { getMasterDataForReportId } from "../../store/actions/master-data";
import ErrorPage from "../ErrorPage";
import LoadingState, { combineLoadingStates } from "../../utils/loading-state";
import { getReportCommentCount } from "../../store/actions/comments";
import DiscoveryOperationsConfirmation from "../../components/DiscoveryOperations/DiscoveryOperationsConfirmation";
import DiscoveryOperations from "../../components/DiscoveryOperations/DiscoveryOperations";
import ExportDiscoveryMonitor from "../../components/Discovery/ExportDiscoveryMonitor";
import { ReportHeader } from "./components/Header";
import { useMobileMediaQuery } from "../../components/Responsive";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import { setReportTemplate } from "../../store/actions/reports";
import NewGuestReportModal from "./components/Modal/NewGuestReportModal";

const Report = ({
  discovery,
  loadingState,
  loadingError,
  fetchDiscoveryByReportId,
  getMasterDataForReportId,
  getReportCommentCount,
  template,
  setReportTemplate
}) => {
  const isMobile = useMobileMediaQuery();
  const { reportId } = useParams();
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const templateParameter = params.get("template");

  useEffect(() => {
    fetchDiscoveryByReportId({ reportId });
    getMasterDataForReportId({ reportId });
    getReportCommentCount({ reportId });
  }, [reportId]);

  useEffect(() => {
    if (discovery) {
      if (
        templateParameter &&
        isPhaseLessThanOrEqualTo(templateParameter, discovery.phase)
      ) {
        setReportTemplate({ template: templateParameter });
      } else {
        setReportTemplate({ template: discovery.phase });
      }
    }
  }, [discovery, templateParameter]);

  useLoadingState(loadingState, () => {
    reportsService
      .incrementViewCount({ reportId })
      .then(() => console.log(`Incremented count`))
      .catch(() => console.log(`Failed increment count`));
  });

  if (loadingError) {
    return <ErrorPage error={loadingError} />;
  }

  if (!discovery || loadingState !== LoadingState.COMPLETED || !template) {
    return <Page />;
  }

  return !discovery ||
    loadingState !== LoadingState.COMPLETED ||
    !template ? null : (
    <Page
      id={"report"}
      header={<ReportHeader template={template} discovery={discovery} />}
    >
      <FullPageLayout style={{ marginBottom: isMobile ? "60px" : undefined }}>
        {isPhaseLessThanOrEqualTo(Phases.ROI, template) && discovery.roi ? (
          <ReportHeaderFull />
        ) : (
          <ReportHeaderPartial />
        )}
        {isPhaseLessThanOrEqualTo(Phases.ROI, template) && discovery.roi ? (
          <ReportROI discovery={discovery} reportId={reportId} />
        ) : (
          ""
        )}
        {isPhaseLessThanOrEqualTo(Phases.VALUE_HYPOTHESIS, template) ? (
          <ReportValueHypothesis reportId={reportId} />
        ) : (
          ""
        )}
        {isPhaseLessThanOrEqualTo(Phases.KPIS_IMPACTED, template) ? (
          <ReportKPI reportId={reportId} />
        ) : (
          ""
        )}
        <ReportChallenges reportId={reportId} />
        <ReportYourSituation reportId={reportId} template={template} />
        <ReportOutro discoveryName={discovery.name} />
      </FullPageLayout>
      <DiscoveryOperations />
      <DiscoveryOperationsConfirmation />
      <ExportDiscoveryMonitor
        actionType={actionTypes.DISCOVERY_EXPORT_REQUEST}
      />
      <NewGuestReportModal discovery={discovery} reportId={reportId} />
    </Page>
  );
};
const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  scenario: selectDiscoveryScenario(state),
  userInfoLoadingState: selectUserInfoLoadingState(state),
  template: selectRequestData(
    state,
    actionTypes.DISCOVERY_SET_REPORT_TEMPLATE_SUCCESS
  ),
  loadingState: combineLoadingStates([
    selectDiscoveryLoadingState(state),
    selectMasterDataLoadingState(state),
    selectCommentCountLoadingState(state)
  ]),
  loadingError:
    selectDiscoveryLoadingError(state) ||
    selectMasterDataLoadingError(state) ||
    selectCommentCountError(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDiscoveryByReportId,
      getMasterDataForReportId,
      getReportCommentCount,
      setReportTemplate
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Report);
