import types from "../actionTypes";

const INITIAL_STATE = {
  isCreatingKPI: false
};

const createKPIsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERIES_FETCH_REQUEST:
    case types.DISCOVERY_FETCH_REQUEST:
      return INITIAL_STATE;

    case types.START_CREATING_KPI:
      return {
        ...state,
        isCreatingKPI: true
      };

    case types.STOP_CREATING_KPI:
      return {
        ...state,
        isCreatingKPI: false
      };

    default:
      return state;
  }
};

export default createKPIsReducer;

export const selectIsCreatingKPI = (data) => data.isCreatingKPI;
