import React from "react";
import ValueHypothesisReport from "./ValueHypothesisReport";
import {
  EditUnquantifiedKPIModal,
  EditQuantifiedKPIModal
} from "../../components/Discovery/KPIModal";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import { useMobileMediaQuery } from "../../components/Responsive";

const ValueHypothesis = () => {
  const isMobile = useMobileMediaQuery();

  return (
    <DiscoveryContentWrapper format={"wide"} isMobile={isMobile}>
      <ValueHypothesisReport />
      <EditUnquantifiedKPIModal />
      <EditQuantifiedKPIModal />
    </DiscoveryContentWrapper>
  );
};

export default ValueHypothesis;
