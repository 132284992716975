import React from "react";
import TextBody from "../../../../components/TextBody";
import NewButton from "../../../../components/NewButton";
import { useString as s } from "../../../../components/StringProvider";
import ButtonGroup from "../../../../components/ButtonGroup";
import PropTypes from "prop-types";
import { Modal } from "../../../../components";

const ApprovePromotionModal = ({ onConfirm, onClose }) => {
  return (
    <Modal
      title={s(
        "currentConfiguration.page.approvePromotion.modal.title",
        "Approve Promotion"
      )}
      open={true}
      onCancel={onClose}
    >
      <TextBody>
        {s(
          "currentConfiguration.page.approvePromotion.modal.text",
          "By continuing, you will promote the current configuration from Sandbox to Production, including the Master Data, PPT and Notes templates."
        )}
      </TextBody>
      <div style={{ paddingTop: 24 }}>
        <ButtonGroup>
          <NewButton type={"primary"} onClick={onConfirm}>
            {s(
              "currentConfiguration.page.approvePromotion.modal.ok",
              "Approve Promotion"
            )}
          </NewButton>
          <NewButton type={"secondary"} onClick={onClose}>
            {s(
              "currentConfiguration.page.approvePromotion.modal.cancel",
              "Go Back"
            )}
          </NewButton>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

ApprovePromotionModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ApprovePromotionModal;
