import { useDrop } from "react-dnd";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import PropTypes from "prop-types";
import { useString as s } from "../../StringProvider";

const DropZoneOverlay = ({ text }) => {
  return (
    <DropZoneOverlayContainer>
      <span>{text}</span>
    </DropZoneOverlayContainer>
  );
};

const DropZone = ({ editable, children, accept, onDrop }) => {
  const dropHereText = s("solutions.popover.dropHere", "Drop here");

  const [{ canDrop }, dropRef] = useDrop(() => ({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop()
    })
  }));

  return editable ? (
    <DropZoneContainer active={canDrop} ref={dropRef}>
      {canDrop ? <DropZoneOverlay text={dropHereText} /> : null}
      {children}
    </DropZoneContainer>
  ) : (
    children
  );
};

DropZone.propTypes = {
  accept: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.any,
  editable: PropTypes.bool
};

const DropZoneOverlayContainer = styled.div`
  border-size: 1px;
  border-style: dashed;
  border-color: transparent;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  font-size: ${themeProp(`typography.small.fontSize`)};
  font-family: ${themeProp(`typography.small.fontFamily`)};
  font-weight: ${themeProp(`typography.bMedium.fontWeight`)};
  line-height: ${themeProp(`typography.small.lineHeight`)};
  color: ${themeProp("components.secondary.dropzone.overlay")};
  background-color: ${themeProp(
    "components.dropzone.secondary.backgroundActive"
  )};
  display: flex;
  justify-content: center;
  align-items: center;

  & span {
    opacity: 1;
    color: ${themeProp("components.dropzone.secondary.overlay")};
  }
`;

const DropZoneContainer = styled.div`
  border-size: 1px;
  border-style: dotted;
  border-color: ${({ active }) =>
    active
      ? themeProp("components.dropzone.secondary.overlay")
      : "transparent"};
  border-radius: 4px;
  padding: 16px;
  position: relative;
  background-color: ${themeProp(
    "components.dropzone.secondary.backgroundBase"
  )};
`;

export default DropZone;
