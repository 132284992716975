import {
  selectAllPermissions,
  selectDiscoveryMaximumPhase
} from "../../../store/reducers";
import Permissions from "../../../utils/permissions";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isPhaseLessThanOrEqualTo, Phases } from "../../../utils/phases";
import PropTypes from "prop-types";

const checkpoints = [
  "/select-challenges",
  "/challenge-summary",
  "/select-kpis",
  "/kpi-summary",
  "/business-metrics",
  "/value-hypothesis",
  "/roi",
  "/roi?edit-assumptions=true",
  "/roi?edit-assumptions=false"
];

export const getLastValidCheckpoint = ({
  checkpoint,
  maximumPhase,
  permissions
}) => {
  const reversedCheckpoints = [...checkpoints].reverse();

  let index = reversedCheckpoints.indexOf(checkpoint);

  if (index === -1) {
    return checkpoint;
  }

  while (index < reversedCheckpoints.length) {
    if (
      canUserAccessDiscoveryPath({
        path: reversedCheckpoints[index],
        maximumPhase,
        permissions
      })
    ) {
      return reversedCheckpoints[index];
    }

    index++;
  }

  return "/select-challenges";
};

export const getNextValidCheckpoint = ({
  checkpoint,
  maximumPhase,
  permissions
}) => {
  let index = checkpoints.indexOf(checkpoint);

  if (index === -1) {
    return checkpoint;
  }

  while (index < checkpoints.length) {
    if (
      canUserAccessDiscoveryPath({
        path: checkpoints[index],
        maximumPhase,
        permissions
      })
    ) {
      return checkpoints[index];
    }
    index++;
  }

  return "/select-challenges";
};

export const compareCheckpoints = ({ checkpoint1, checkpoint2 }) => {
  return checkpoints.indexOf(checkpoint1) - checkpoints.indexOf(checkpoint2);
};

export const canUserAccessDiscoveryPhase = ({
  phase,
  maximumPhase,
  permissions
}) => {
  if (phase === Phases.KPIS_IMPACTED) {
    if (
      maximumPhase &&
      !isPhaseLessThanOrEqualTo(Phases.KPIS_IMPACTED, maximumPhase)
    ) {
      return false;
    }
  }

  if (phase === Phases.VALUE_HYPOTHESIS) {
    if (
      maximumPhase &&
      !isPhaseLessThanOrEqualTo(Phases.VALUE_HYPOTHESIS, maximumPhase)
    ) {
      return false;
    }

    if (!permissions.includes(Permissions.SEE_VALUE_HYPOTHESIS)) {
      return false;
    }
  }

  if (phase === Phases.ROI) {
    if (maximumPhase && !isPhaseLessThanOrEqualTo(Phases.ROI, maximumPhase)) {
      return false;
    }

    if (!permissions.includes(Permissions.SEE_ROI_PAGE)) {
      return false;
    }
  }

  return true;
};

export const canUserAccessDiscoveryPath = ({
  path,
  maximumPhase,
  permissions
}) => {
  if (path.includes("select-challenges")) {
    if (!permissions.includes(Permissions.SEE_SELECT_CHALLENGES)) {
      return false;
    }
  }

  if (path.includes("challenge-summary")) {
    if (!permissions.includes(Permissions.SEE_CHALLENGE_SUMMARY)) {
      return false;
    }
  }

  if (path.includes("select-kpis")) {
    if (
      maximumPhase &&
      !isPhaseLessThanOrEqualTo(Phases.KPIS_IMPACTED, maximumPhase)
    ) {
      return false;
    }

    if (!permissions.includes(Permissions.SEE_SELECT_KPIS)) {
      return false;
    }
  }

  if (path.includes("kpi-summary")) {
    if (
      maximumPhase &&
      !isPhaseLessThanOrEqualTo(Phases.KPIS_IMPACTED, maximumPhase)
    ) {
      return false;
    }

    if (!permissions.includes(Permissions.SEE_KPI_SUMMARY)) {
      return false;
    }
  }

  if (path.includes("business-metrics")) {
    if (
      maximumPhase &&
      !isPhaseLessThanOrEqualTo(Phases.VALUE_HYPOTHESIS, maximumPhase)
    ) {
      return false;
    }

    if (!permissions.includes(Permissions.SEE_VALUE_HYPOTHESIS)) {
      return false;
    }
  }

  if (path.includes("value-hypothesis")) {
    if (
      maximumPhase &&
      !isPhaseLessThanOrEqualTo(Phases.VALUE_HYPOTHESIS, maximumPhase)
    ) {
      return false;
    }

    if (!permissions.includes(Permissions.SEE_VALUE_HYPOTHESIS)) {
      return false;
    }
  }

  if (path.includes("roi")) {
    if (maximumPhase && !isPhaseLessThanOrEqualTo(Phases.ROI, maximumPhase)) {
      return false;
    }

    if (!permissions.includes(Permissions.SEE_ROI_PAGE)) {
      return false;
    }
  }

  return true;
};

const OnlyIfUserCanAccessDiscoveryPath = ({
  path,
  maximumPhase,
  permissions,
  children
}) => {
  return canUserAccessDiscoveryPath({ path, maximumPhase, permissions })
    ? children
    : null;
};

OnlyIfUserCanAccessDiscoveryPath.propTypes = {
  path: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  permissions: selectAllPermissions(state),
  maximumPhase: selectDiscoveryMaximumPhase(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(OnlyIfUserCanAccessDiscoveryPath);
