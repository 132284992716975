import types from "../actionTypes";
import configurationsService from "../../services/configurations.service";

export const getCurrentConfigurations = () => (dispatch) => {
  dispatch({
    type: types.GET_CURRENT_CONFIGURATIONS_REQUEST
  });

  return configurationsService
    .getCurrentConfigurations()
    .then((payload) => {
      dispatch({
        type: types.GET_CURRENT_CONFIGURATIONS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_CURRENT_CONFIGURATIONS_FAILURE,
        error
      });
    });
};

export const downloadGuide =
  ({ guideName }) =>
  (dispatch) => {
    dispatch({
      type: types.DOWNLOAD_GUIDE_REQUEST
    });

    return configurationsService
      .downloadGuide({
        guideName
      })
      .then((payload) => {
        dispatch({
          type: types.DOWNLOAD_GUIDE_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DOWNLOAD_GUIDE_FAILURE,
          error
        });
      });
  };

export const getConfigurationLogs =
  ({ start, count, sort, order }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_CONFIGURATION_LOGS_REQUEST
    });

    return configurationsService
      .getConfigurationLogs({ start, count, sort, order })
      .then((payload) => {
        dispatch({
          type: types.GET_CONFIGURATION_LOGS_SUCCESS,
          payload,
          parameters: { start, count, sort, order }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CONFIGURATION_LOGS_FAILURE,
          error,
          parameters: { start, count, sort, order }
        });
      });
  };

export const getConfigurationLog =
  ({ configurationLogId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_CONFIGURATION_LOG_REQUEST
    });

    return configurationsService
      .getConfigurationLog({ configurationLogId })
      .then((payload) => {
        dispatch({
          type: types.GET_CONFIGURATION_LOG_SUCCESS,
          payload,
          parameters: { configurationLogId }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CONFIGURATION_LOG_FAILURE,
          error
        });
      });
  };

export const updateBrandingThemes =
  ({ primaryColor, secondaryColor, heroFileId, logoFileId }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_BRANDING_THEMES_REQUEST
    });

    return configurationsService
      .updateBrandingThemes({
        primaryColor,
        secondaryColor,
        heroFileId,
        logoFileId
      })
      .then((payload) => {
        dispatch({
          type: types.UPDATE_BRANDING_THEMES_SUCCESS,
          payload,
          parameters: { logoFileId, heroFileId }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_BRANDING_THEMES_FAILURE,
          error
        });
      });
  };

export const updateBrandingStrings = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_BRANDING_STRINGS_REQUEST
  });

  return configurationsService
    .updateBrandingStrings(data)
    .then((payload) => {
      dispatch({
        type: types.UPDATE_BRANDING_STRINGS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_BRANDING_STRINGS_FAILURE,
        error
      });
    });
};
