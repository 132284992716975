import AutoconfigurationWizardPanel from "./AutoconfigurationWizardPanel";

const AutoconfigurationWizard = ({ match }) => {
  const autoconfigurationId = match.params.autoconfigurationId;

  return (
    <AutoconfigurationWizardPanel autoconfigurationId={autoconfigurationId} />
  );
};

export default AutoconfigurationWizard;
