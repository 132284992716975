import styled from "styled-components";
import { Text } from "../index";
import { themeProp } from "../../theme";
import PropTypes from "prop-types";

const Badge = styled(Text)`
  border-radius: 6px;
  padding: 3px 8px;
  width: fit-content;
  min-width: fit-content;
  height: fit-content;
  white-space: nowrap;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  font-family: ${({ variant }) =>
    themeProp(`typography.${variant}.fontFamily`)};
  font-size: ${({ variant }) => themeProp(`typography.${variant}.fontSize`)};
  font-weight: ${({ variant }) =>
    themeProp(`typography.${variant}.fontWeight`)};
  line-height: ${({ variant }) =>
    themeProp(`typography.${variant}.fontHeight`)};
  font-weight: ${(props) => (props.color === "surface" ? 700 : "initial")};
`;

Badge.propTypes = {
  color: PropTypes.string,
  bg: PropTypes.any,
  variant: PropTypes.oneOf(["small", "micro"])
};

Badge.defaultProps = {
  variant: "small"
};

export default Badge;
