import httpService from "./http.service";

const createValidation = ({ fileId, updateType, description, workflowType }) =>
  httpService
    .post(`/validations/`, { fileId, updateType, description, workflowType })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const exportValidation = ({ validationId, type }) =>
  httpService
    .post(`/validations/${validationId}/export`, { type })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deployValidation = ({ validationId }) =>
  httpService
    .post(`/validations/${validationId}/deploy`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  createValidation,
  exportValidation,
  deployValidation
};

export default methods;
