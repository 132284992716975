import httpService from "./http.service";

const requestPromotion = () =>
  httpService
    .post(`/promotions/`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getActivePromotion = () =>
  httpService
    .get(`/promotions/active`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const approvePromotion = ({ promotionId }) =>
  httpService
    .post(`/promotions/${promotionId}/approve`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const rejectPromotion = ({ promotionId, rejectionReason }) =>
  httpService
    .post(`/promotions/${promotionId}/reject`, { rejectionReason })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  requestPromotion,
  getActivePromotion,
  rejectPromotion,
  approvePromotion
};

export default methods;
