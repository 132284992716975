import React from "react";
import Page from "../../components/Page";
import { BasicBrandHeader, SplitPageLayout } from "../../components/Layout";
import styled from "styled-components";
import Form from "../../components/Form";
import { useString as s } from "../../components/StringProvider";
import { selectRequestData, selectRequestState } from "../../store/reducers";
import { bindActionCreators } from "redux";
import { companyResearch } from "../../store/actions/ai";
import { connect } from "react-redux";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import { message, Space } from "antd";
import Text from "../../components/Text";
import {
  DesktopOrTablet,
  Mobile,
  useMobileMediaQuery
} from "../../components/Responsive";
import { useTheme } from "../../components/ThemeProvider";
import MobilePageCentredLayout from "../../components/Layout/MobilePageCentredLayout";
import AutoConfigurationForm from "./AutoConfigurationForm";

const AutoConfiguration = ({
  companyResearch,
  loadingState,
  data,
  history
}) => {
  const isMobile = useMobileMediaQuery();
  const [form] = Form.useForm();

  const pageTitle = s(
    "autoConfiguration.page.title",
    "Let the Cuvama magic happen"
  );
  const pageSubTitle = s(
    "autoConfiguration.page.subtitle",
    "Create your Cuvama experience by filling out the form"
  );
  const error = s("autoConfiguration.page.messages.error", "Request failed");

  useLoadingState(
    loadingState,
    () => {
      history.push({
        pathname: `/auto-configuration/${data._id}`
      });
    },
    () => {
      message.error(error);
    }
  );

  const onFinish = (values) => {
    companyResearch(values);
  };

  const content = (
    <Container>
      <Space direction={"vertical"} size={isMobile ? 2 : 2}>
        <Text variant={isMobile ? "h3" : "h2"}>{pageTitle}</Text>
        <Text variant={isMobile ? "bodyMobile" : "body"}>{pageSubTitle}</Text>
      </Space>
      <FormContainer>
        <AutoConfigurationForm form={form} onFinish={onFinish} />
      </FormContainer>
    </Container>
  );

  return (
    <Page header={<BasicBrandHeader />}>
      <DesktopOrTablet>
        <SplitPageLayout
          image={useTheme("assets.discovery_cover")}
          hasHeader={true}
        >
          {content}
        </SplitPageLayout>
      </DesktopOrTablet>
      <Mobile>
        <MobilePageCentredLayout
          hasHeader={true}
          image={useTheme("assets.discovery_cover")}
        >
          {content}
        </MobilePageCentredLayout>
      </Mobile>
    </Page>
  );
};

const FormContainer = styled.div`
  margin-top: 30px;
  &.mobile {
    margin-top: 20px;
  }
`;

const Container = styled.div`
  padding: 50px 50px 50px 50px;
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.COMPANY_RESEARCH_REQUEST),
  data: selectRequestData(state, actionTypes.COMPANY_RESEARCH_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      companyResearch
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AutoConfiguration);
