import React from "react";
import PropTypes from "prop-types";
import NewButton from "../NewButton";
import Icon from "../Icon";
import { formatNumberWithLocale } from "../../utils/formatting";
import { useSetting } from "../SettingsProvider";
import { Operations } from "./DiscoveryOperations";
import { startDiscoveryOperation } from "../../store/actions/discovery-operations";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  sendUserPilotEvent,
  UserPilotEventNames
} from "../../utils/user-pilot-util";

const EngagementButton = ({
  discoveryId,
  engagement,
  startDiscoveryOperation
}) => {
  const locale = useSetting("locale", "en-GB");

  const onClick = () => {
    startDiscoveryOperation({
      operationId: Operations.ENGAGEMENT,
      operationDiscoveryId: discoveryId
    });

    sendUserPilotEvent({
      eventName: UserPilotEventNames.OPEN_ENGAGEMENT_REPORT,
      data: { discoveryId }
    });
  };

  return (
    <NewButton
      type={"iconTextPrimary"}
      className={`up-engagement-button`}
      onClick={onClick}
      data-cy={"engagement-button"}
    >
      <Icon name={"trending"} colour={"gray3"} size={"medium"} />
      {formatNumberWithLocale({ value: engagement, locale })}
    </NewButton>
  );
};

EngagementButton.propTypes = {
  engagement: PropTypes.number.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startDiscoveryOperation
    },
    dispatch
  );

export default compose(connect(null, mapDispatchToProps))(EngagementButton);
