import {
  selectDiscoveryId,
  selectDiscoverySetting,
  selectRequestData,
  selectRequestError,
  selectRequestState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { message } from "antd";
import { useString as s } from "../../StringProvider";
import Form from "../../Form";
import NewChallengeForm from "./NewChallengeForm";
import {
  createDiscoveryChallenge,
  stopCreatingChallenge
} from "../../../store/actions/create-challenges";
import ViewChallengesBy from "../../../utils/view-challenges-by";
import ChallengeModal from "./ChallengeModal";
import useLoadingState from "../../../utils/use-loading-state";
import actionTypes from "../../../store/actionTypes";
import { setScrollToChallengeCode } from "../../../store/actions/challenge-library";
import ChallengePositions from "../../../Pages/ValueMap/challenge-positions";

const CreateChallengeModal = ({
  discoveryId,
  loadingState,
  viewBy,
  createDiscoveryChallenge,
  stopCreatingChallenge,
  setScrollToChallengeCode,
  error,
  newChallengeCode
}) => {
  const errorText = s(
    "createChallenge.popup.error.text",
    "Failed to create challenge"
  );
  const successText = s(
    "createChallenge.popup.success.text",
    "Card added! Changes will be applied to the current discovery only."
  );
  const [form] = Form.useForm();

  useLoadingState(
    loadingState,
    () => {
      message.success({
        style: { width: 350, textAlign: "left", margin: "0 auto" },
        content: successText
      });
      setScrollToChallengeCode({ challengeCode: newChallengeCode });
      stopCreatingChallenge();
    },
    () => {
      if (!error || error.status !== 409) {
        message.error(errorText);
      }
    }
  );

  const onFinish = ({
    description,
    categoryCode,
    importance,
    kpiMappings,
    detailText
  }) => {
    const data = {
      importance,
      definition: {
        description,
        categoryCode,
        kpiMappings,
        detailText,
        position: ChallengePositions.LAST
      }
    };

    if (viewBy === ViewChallengesBy.TYPICAL) {
      data.selectedByDefault = true;
    }

    createDiscoveryChallenge({
      discoveryId,
      data
    });
  };

  return (
    <ChallengeModal open={true} data-cy={"create-challenge-modal"}>
      <NewChallengeForm
        onFinish={onFinish}
        onCancel={stopCreatingChallenge}
        form={form}
        error={error}
      />
    </ChallengeModal>
  );
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    loadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_CREATE_CHALLENGE_REQUEST
    ),
    newChallengeCode: selectRequestData(
      state,
      actionTypes.DISCOVERY_CREATE_CHALLENGE_REQUEST
    )?.challenge?.challengeCode,
    error: selectRequestError(
      state,
      actionTypes.DISCOVERY_CREATE_CHALLENGE_REQUEST
    ),
    viewBy: selectDiscoverySetting(
      state,
      "viewChallengesBy",
      ViewChallengesBy.TYPICAL
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createDiscoveryChallenge,
      stopCreatingChallenge,
      setScrollToChallengeCode
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateChallengeModal
);
