import PropTypes from "prop-types";
import Heading from "../../../../components/Heading";
import Currency from "../../../../components/Currency";
import { useMobileMediaQuery } from "../../../../components/Responsive";
import { useString as s } from "../../../../components/StringProvider";
import KPIName from "./KPIName";
import WhiteDivider from "./WhiteDivider";
import CurrencyRange from "./CurrencyRange";
import ToText from "./ToText";

const OneTimeBenefits = ({ kpis, currency }) => {
  const isMobile = useMobileMediaQuery();
  const oneTimeBenefitsText = s(
    "discovery.kpi.card.impact.oneTimeBenefits",
    "One-Time Benefit"
  );

  const oneTimeBenefitKPIs = kpis.filter(
    (kpi) => kpi.selected && kpi.hasImpactValue && kpi.definition.oneTimeBenefit
  );

  const totalLowImpactValue = oneTimeBenefitKPIs.reduce(
    (sum, kpi) => sum + kpi.lowImpactValue,
    0
  );
  const totalHighImpactValue = oneTimeBenefitKPIs.reduce(
    (sum, kpi) => sum + kpi.potentialImpactValue,
    0
  );

  return (
    !!oneTimeBenefitKPIs.length && (
      <>
        <WhiteDivider />
        <Heading level={"h5"} color={"surface"}>
          {oneTimeBenefitsText}
        </Heading>{" "}
        <CurrencyRange>
          <Currency format="short" currency={currency}>
            {totalLowImpactValue}
          </Currency>
          {totalLowImpactValue !== totalHighImpactValue ? (
            <>
              <ToText />
              <Currency format="short" currency={currency}>
                {totalHighImpactValue}
              </Currency>
            </>
          ) : null}
        </CurrencyRange>
        {oneTimeBenefitKPIs.map((kpi, index) => (
          <KPIName
            key={index}
            className={isMobile ? "mobile" : undefined}
            variant={isMobile ? "statisticsMobile" : "bMedium"}
          >
            {kpi.definition.name}
          </KPIName>
        ))}
      </>
    )
  );
};

OneTimeBenefits.propTypes = {
  kpis: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired
};

export default OneTimeBenefits;
