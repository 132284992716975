import types from "../actionTypes";
import autoconfigurationsService from "../../services/autoconfigurations.service";

export const createAutoconfiguration =
  ({ companyName, companyWebsite, productName, model }) =>
  (dispatch) => {
    dispatch({
      type: types.CREATE_AUTOCONFIGURATION_REQUEST
    });

    return autoconfigurationsService
      .createAutoconfiguration({
        companyName,
        companyWebsite,
        productName,
        model
      })
      .then((payload) => {
        dispatch({
          type: types.CREATE_AUTOCONFIGURATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_AUTOCONFIGURATION_FAILURE,
          error
        });
      });
  };

export const getAutoconfiguration =
  ({ autoconfigurationId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_AUTOCONFIGURATION_REQUEST
    });

    return autoconfigurationsService
      .getAutoconfiguration({
        autoconfigurationId
      })
      .then((payload) => {
        dispatch({
          type: types.GET_AUTOCONFIGURATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_AUTOCONFIGURATION_FAILURE,
          error
        });
      });
  };

export const generateAutoconfiguration =
  ({ autoconfigurationId, section }) =>
  (dispatch) => {
    dispatch({
      type: types.GENERATE_AUTOCONFIGURATION_REQUEST
    });

    return autoconfigurationsService
      .generateAutoconfiguration({
        autoconfigurationId,
        section
      })
      .then((payload) => {
        dispatch({
          type: types.GENERATE_AUTOCONFIGURATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GENERATE_AUTOCONFIGURATION_FAILURE,
          error
        });
      });
  };

export const validateAutoconfiguration =
  ({ autoconfigurationId }) =>
  (dispatch) => {
    dispatch({
      type: types.VALIDATE_AUTOCONFIGURATION_REQUEST
    });

    return autoconfigurationsService
      .validateAutoconfiguration({
        autoconfigurationId
      })
      .then((payload) => {
        dispatch({
          type: types.VALIDATE_AUTOCONFIGURATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.VALIDATE_AUTOCONFIGURATION_FAILURE,
          error
        });
      });
  };
