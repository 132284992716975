import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { selectRequestData, selectRequestState } from "../../../store/reducers";
import actionTypes from "../../../store/actionTypes";
import { getOpportunities } from "../../../store/actions/crm";
import useLoadingState from "../../../utils/use-loading-state";
import { useString as s } from "../../StringProvider";
import PropTypes from "prop-types";
import * as formatting from "../../../utils/formatting";
import { getLatestCRMAccountsLog } from "../../../store/actions/config";
import InputWithDropdown from "./InputWithDropdown";
import SourceTypes from "../../../utils/source-types";
import LoadingState from "../../../utils/loading-state";
import Form from "../../Form";

const SelectOpportunity = ({
  opportunitySource,
  loadingState,
  opportunities,
  pagination,
  getOpportunities,
  onUseOpportunityName,
  onUseOpportunityId,
  getLatestCRMAccountsLog,
  log,
  disabled,
  ...props
}) => {
  const [items, setItems] = useState([]);
  const [allOpportunities, setAllOpportunities] = useState([]);
  const [itemsLoadingState, setItemsLoadingState] = useState(
    LoadingState.UNINITIALIZED
  );
  const [search, setSearch] = useState("");
  const form = Form.useFormInstance();
  const accountId = Form.useWatch("accountId", form);
  const accountName = Form.useWatch("accountName", form);
  const [accountFilled, setAccountFilled] = useState(false);

  useEffect(() => {
    setAccountFilled(!!accountId || !!accountName);
  }, [accountId, accountName]);

  const crmGroupName = s(
    "selectOpportunity.component.crmGroupName",
    "Opportunities in CRM"
  );

  const customGroupName = s(
    "selectOpportunity.component.customGroupName",
    "Your Suggestion"
  );

  const lastUpdated = s(
    "selectOpportunity.component.lastModified",
    "Last updated on {lastUpdatedTime}",
    {
      lastUpdatedTime:
        log && log.updatedAt ? formatting.timeSince(log.updatedAt) : log
    }
  );

  const suggestion = s(
    "selectOpportunity.component.suggestion",
    "You can add the Opportunity Name manually but this discovery won't be matched to your CRM."
  );

  useEffect(() => {
    getLatestCRMAccountsLog();
  }, []);

  useLoadingState(
    loadingState,
    () => {
      setAllOpportunities((all) => [...all, ...opportunities]);

      if (!opportunities.length) {
        setItems(
          allOpportunities.map(({ opportunityId, name, source }) => ({
            id: opportunityId,
            name,
            source
          }))
        );
        setItemsLoadingState(LoadingState.COMPLETED);
      } else {
        getOpportunities({
          search,
          accountId,
          start: pagination.start + pagination.count
        });
      }
    },
    () => {
      setItemsLoadingState(LoadingState.FAILED);
    }
  );

  const onLoadItems = (search) => {
    setAllOpportunities([]);
    setSearch(search);
    setItemsLoadingState(LoadingState.IN_PROGRESS);
    getOpportunities({ search, accountId });
  };

  return (
    <InputWithDropdown
      items={items}
      source={opportunitySource}
      onUseId={onUseOpportunityId}
      onUseName={onUseOpportunityName}
      onLoadItems={onLoadItems}
      loadingState={itemsLoadingState}
      crmGroupName={crmGroupName}
      customGroupName={customGroupName}
      lastUpdated={lastUpdated}
      suggestion={suggestion}
      data-cy={"select-opportunity"}
      disabled={disabled || !accountFilled}
      {...props}
    />
  );
};

SelectOpportunity.propTypes = {
  onUseOpportunityId: PropTypes.func.isRequired,
  onUseOpportunityName: PropTypes.func.isRequired,
  opportunitySource: PropTypes.oneOf(SourceTypes.ALL)
};

const mapStateToProps = (state) => {
  const data = selectRequestData(
    state,
    actionTypes.GET_CRM_OPPORTUNITIES_REQUEST
  );
  return {
    loadingState: selectRequestState(
      state,
      actionTypes.GET_CRM_OPPORTUNITIES_REQUEST
    ),
    opportunities: data ? data.items : [],
    pagination: data ? data.pagination : undefined,
    log: selectRequestData(
      state,
      actionTypes.GET_LATEST_CRM_ACCOUNTS_LOG_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOpportunities,
      getLatestCRMAccountsLog
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectOpportunity
);
