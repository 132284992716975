import { useString as s } from "../../../../../components/StringProvider";
import { ReactComponent as Icon } from "../../../../../assets/editor/code.svg";
import ToolbarButton from "./ToolbarButton";

const CodeButton = ({ onClick, active }) => {
  return (
    <ToolbarButton
      active={active}
      onClick={onClick}
      label={s("editor.buttons.code.label", "Insert Code")}
    >
      <Icon />
    </ToolbarButton>
  );
};

export default CodeButton;
