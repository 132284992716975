import React from "react";
import styled from "styled-components";
import { PageSection } from "../../../components/Page";
import { ValueHypothesisReport } from "../../ValueHypothesis";
import PropType from "prop-types";
import MobileReportSection from "./MobileReportSection";
import { useMobileMediaQuery } from "../../../components/Responsive";

const ReportValueHypothesis = ({ reportId }) => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? (
    <MobileReportSection id={"report-value-hypothesis"}>
      <ValueHypothesisReport reportId={reportId} />
    </MobileReportSection>
  ) : (
    <ReportROIContainer id={"report-value-hypothesis"}>
      <ValueHypothesisReport reportId={reportId} />
    </ReportROIContainer>
  );
};

const ReportROIContainer = styled(PageSection)`
  padding: 120px 24px;
  @media print {
    padding: 0px;
  }
  width: 100%;
`;

ReportValueHypothesis.propTypes = {
  reportId: PropType.string.isRequired
};

export default ReportValueHypothesis;
