import styled from "styled-components";
import Menu from "../components/Menu";
import { themeProp } from "../theme";

const NavigationMenu = styled(Menu)`
  &.ant-menu-horizontal {
    height: 46px;
    display: flex;
    align-items: center;

    &.ant-menu {
      background: transparent;
      border-bottom-width: 2px;
      border-bottom-color: ${themeProp("palette.gray2")};
      margin-bottom: 24px;
      margin-top: 1px;
    }

    & .ant-menu-item,
    & .ant-menu-item span {
      font-family: ${themeProp("typography.small.fontFamily")};
      font-size: ${themeProp("typography.bMediumMobile.fontSize")};
      color: ${themeProp("palette.black")};
    }

    & .ant-menu-item:first-of-type {
      padding-left: 0px;
    }

    & .ant-menu-item.ant-menu-item-selected span,
    & .ant-menu-item.ant-menu-item-selected::after,
    & .ant-menu-item.ant-menu-item-selected,
    .ant-menu-item.ant-menu-item-active.ant-menu-item-selected {
      color: ${themeProp("palette.secondary")};
      border-bottom-color: ${themeProp("palette.secondary")} !important;
      font-weight: ${themeProp("typography.bMedium.fontWeight")};
    }

    & .ant-menu-item:hover span,
    & .ant-menu-item:hover::after,
    & .ant-menu-item:hover,
    .ant-menu-item.ant-menu-item-active:hover {
      color: ${themeProp("palette.secondary")} !important;
      border-bottom-color: transparent;
    }

    & .ant-menu-item.ant-menu-item-selected:first-of-type::after,
    & .ant-menu-item.ant-menu-item-active:first-of-type::after,
    &
      .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child:first-of-type:after {
      left: 0;
    }

    & .ant-menu-item.ant-menu-item-active:not(.ant-menu-item-selected) span,
    & .ant-menu-item.ant-menu-item-active:not(.ant-menu-item-selected) {
      color: ${themeProp("palette.gray4")};
      border-bottom-color: transparent;
    }
  }

  &.ant-menu-vertical {
    &.ant-menu {
      border: 0;
    }

    &.ant-menu .ant-menu-item span,
    &.ant-menu .ant-menu-item {
      font-size: ${themeProp("typography.button.fontSize")};
      font-family: ${themeProp("typography.button.fontFamily")};
      font-weight: ${themeProp("typography.button.fontWeight")};
    }

    &.ant-menu .ant-menu-item:hover {
      color: unset;
    }

    &.ant-menu .ant-menu-item-selected {
      background-color: ${themeProp("palette.gray1")};
      color: ${themeProp("palette.secondary")};
    }
  }
`;

export default NavigationMenu;
