import { bindActionCreators, compose } from "redux";
import {
  createRole,
  getRole,
  updateRole
} from "../../../../store/actions/roles";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import Form from "../../../../components/Form";
import { message } from "antd";
import {
  selectRequestError,
  selectRequestState,
  selectRole
} from "../../../../store/reducers";
import useLoadingState from "../../../../utils/use-loading-state";
import RoleForm from "./RoleForm";
import styled from "styled-components";
import Icon from "../../../../components/Icon";
import Link from "../../../../components/Link";
import NewButton from "../../../../components/NewButton";
import Heading from "../../../../components/Heading";
import { useMobileMediaQuery } from "../../../../components/Responsive";
import { useParams, useHistory } from "react-router-dom";
import DuplicateRoleAlert from "./DuplicateRoleAlert";
import PropTypes from "prop-types";

export const formActionTypes = {
  EDIT: "edit",
  CREATE: "create",
  COPY: "copy"
};

const RoleEditor = ({
  loadingState,
  error,
  role,
  createRole,
  updateRole,
  getRole,
  formActionType
}) => {
  const isMobile = useMobileMediaQuery();
  const history = useHistory();
  const [form] = Form.useForm();
  const { roleId } = useParams();
  const saveButtonText = s("accessManagement.page.roles.save", "Save");
  const backButton = s("accessManagement.page.roles.back", "Go Back");
  const cancelText = s("accessManagement.page.roles.cancel", "Cancel");
  const errorText = s(
    "accessManagement.page.roles.error",
    "Failed to create role"
  );
  const createSuccessText = s(
    "accessManagement.page.roles.create.success",
    "Role was successfully created"
  );
  const editSuccessText = s(
    "accessManagement.page.roles.edit.success",
    "Role was successfully updated"
  );
  const createRoleText = s(
    "accessManagement.page.roleForm.create.title",
    "Add Role"
  );
  const editRoleText = s(
    "accessManagement.page.roleForm.edit.title",
    "Edit Role"
  );
  const [isDuplicateError, setDuplicateError] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const rolesPath = `/organizations/access-management/roles`;

  const onClose = () => {
    history.push(rolesPath);
  };

  const getRoleName = ({ role, formActionType }) => {
    if (!role) {
      return undefined;
    }
    if (formActionType === formActionTypes.CREATE) {
      return undefined;
    } else if (formActionType === formActionTypes.EDIT) {
      return role.name;
    } else if (formActionType === formActionTypes.COPY) {
      return `${role.name} (Copy)`;
    }
  };

  useEffect(() => {
    if (roleId) {
      getRole({ roleId });
    }
  }, [roleId]);

  useEffect(() => {
    if (role) {
      setCurrentRole(role);
    }
  }, [role]);

  useEffect(() => {
    if (formActionType === formActionTypes.CREATE) {
      setCurrentRole(null);
    }
  }, [formActionType]);

  useEffect(() => {
    const name = getRoleName({ role: currentRole, formActionType });

    if (currentRole && roleId) {
      form.setFieldsValue({
        name,
        description: currentRole && currentRole.description,
        permissions: (currentRole && currentRole.permissions) || []
      });
    }
  }, [currentRole]);

  const title =
    formActionType === formActionTypes.EDIT ? editRoleText : createRoleText;
  const successText =
    formActionType === formActionTypes.EDIT
      ? editSuccessText
      : createSuccessText;

  useLoadingState(
    loadingState,
    () => {
      onClose();
      history.push(rolesPath);
      message.success(successText);
    },
    () => {
      if (error) {
        if (error.status === 409) {
          setDuplicateError(true);
        } else {
          setDuplicateError(false);
          message.error(errorText);
        }
      } else {
        setDuplicateError(false);
        message.error(errorText);
      }
    }
  );

  const onFinish = async (values) => {
    switch (formActionType) {
      case formActionTypes.CREATE:
      case formActionTypes.COPY:
        createRole({ ...values, permissions: values.permissions || [] });
        break;
      case formActionTypes.EDIT:
        updateRole({ ...values, roleId: currentRole._id });
    }
  };

  return (
    <Container>
      <LinkContainer>
        <Link
          to={`/organizations/access-management/roles`}
          data-cy={"back-link"}
          onClick={onClose}
        >
          <Icon name={"arrowLeft"} /> {backButton}
        </Link>
      </LinkContainer>
      <HeadingContainer>
        <Heading level={isMobile ? "h5Small" : "h4"} color={"gray4"}>
          {title}
        </Heading>
      </HeadingContainer>
      <RoleForm
        form={form}
        onFinish={onFinish}
        submitButtonText={saveButtonText}
        isBlessedRole={currentRole?.blessed}
        initialPermissions={currentRole?.permissions}
      >
        <NewButton type={"secondary"} onClick={onClose} data-cy={"cancel-edit"}>
          {cancelText}
        </NewButton>
      </RoleForm>
      <AlertContainer>
        {isDuplicateError ? <DuplicateRoleAlert /> : null}
      </AlertContainer>
    </Container>
  );
};

const AlertContainer = styled.div`
  margin-top: 20px;
`;

const HeadingContainer = styled.div`
  margin-bottom: 30px;
`;

const Container = styled.div`
  margin-bottom: 20px;
`;

const LinkContainer = styled.div`
  margin-bottom: 20px;
`;

RoleEditor.propTypes = {
  formActionType: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  loadingState: selectRequestState(state, ownProps.actionType),
  error: selectRequestError(state, ownProps.actionType),
  role: selectRole(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createRole,
      updateRole,
      getRole
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RoleEditor
);
