import { Slider, Text, Tooltip } from "../../../components";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  isAuthenticated,
  selectDiscoveryDelayTimeline,
  selectDiscoveryId,
  selectDiscoveryReportId
} from "../../../store/reducers";
import {
  saveDiscoveryDelayTimeline,
  setDiscoveryDelayTimeline
} from "../../../store/actions/discoveries";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  useMobileMediaQuery,
  useWindowDimensions
} from "../../../components/Responsive";
import { useString as s } from "../../../components/StringProvider";
import DelayTimelineOptions from "../../ValueHypothesis/components/DelayTimelineOptions";
import Icon from "../../../components/Icon";
import getCOSQSliderWidth from "./get-cosq-slider-width";
import actionTypes from "../../../store/actionTypes";

const delayTimelineMarkMap = {
  [DelayTimelineOptions.MONTH]: 0,
  [DelayTimelineOptions.QUARTER]: 50,
  [DelayTimelineOptions.YEAR]: 100
};

const markDelayTimelineMap = {
  0: DelayTimelineOptions.MONTH,
  50: DelayTimelineOptions.QUARTER,
  100: DelayTimelineOptions.YEAR
};

const DelayTimeline = ({
  isAuthenticated,
  discoveryId,
  delayTimeline,
  saveDiscoveryDelayTimeline,
  setDiscoveryDelayTimeline,
  reportMode,
  fixedWidth
}) => {
  const isMobile = useMobileMediaQuery();
  const { width } = useWindowDimensions();
  const title = s("discovery.delayTimeline.title", "DELAY TIMELINE");
  const month = s("discovery.delayTimeline.month", "Month");
  const quarter = s("discovery.delayTimeline.quarter", "Quarter");
  const year = s("discovery.delayTimeline.year", "Year");
  const toolTipText = s(
    "discovery.delayTimeline.toolTip",
    "See how much you lose by not switching to {companyName}"
  );

  const onChange = (mark) => {
    const delayTimeline = markDelayTimelineMap[mark];
    if (reportMode && !isAuthenticated) {
      setDiscoveryDelayTimeline({ delayTimeline });
    } else {
      saveDiscoveryDelayTimeline({ discoveryId, delayTimeline });
    }
  };

  return (
    <DelayTimelineContainer data-cy={"delayTimeline"}>
      <TitleContainer>
        <Text
          style={{ marginLeft: "7px" }}
          variant={isMobile ? "bodyMobile" : "h5"}
          color={"gray4"}
        >
          {title}
        </Text>
        <Tooltip title={toolTipText} placement={"top"}>
          <Icon
            style={{ marginTop: "-2px", marginLeft: "5px" }}
            name={"infoCircle"}
            colour={"gray4"}
            size={"medium"}
          />
        </Tooltip>
      </TitleContainer>
      <SliderContainer>
        <Slider
          style={{
            width: getCOSQSliderWidth({
              isMobile,
              windowWidth: width,
              fixedWidth
            })
          }}
          value={delayTimelineMarkMap[delayTimeline]}
          tooltip={{ formatter: null }}
          step={50}
          marks={{
            0: month,
            50: quarter,
            100: year
          }}
          onChange={onChange}
        />
      </SliderContainer>
    </DelayTimelineContainer>
  );
};

const SliderContainer = styled.div`
  margin-left: 5px;
`;

const DelayTimelineContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

DelayTimeline.propTypes = {
  reportMode: PropTypes.bool.isRequired,
  fixedWidth: PropTypes.string
};

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: isAuthenticated(state),
    discoveryId: selectDiscoveryId(state),
    reportId: selectDiscoveryReportId(state),
    delayTimeline: selectDiscoveryDelayTimeline({
      state,
      reportMode: props.reportMode,
      actionType: actionTypes.DISCOVERY_SET_DELAY_TIMELINE_REQUEST
    })
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryDelayTimeline,
      setDiscoveryDelayTimeline
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DelayTimeline);
