import {
  selectDiscoveryCurrency,
  selectDiscoveryMetric
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { SettingsContext } from "../../../../components/SettingsProvider";
import TooltipIcon from "../../../../components/TooltipIcon";
import { Text } from "../../../../components";
import { useContext } from "react";
import styled from "styled-components";
import { formatMetric } from "../../../../utils/formatting";
import { useMobileMediaQuery } from "../../../../components/Responsive";

const ReportMetric = ({ currency, metric }) => {
  const isMobile = useMobileMediaQuery();
  const { settings } = useContext(SettingsContext);
  const localSettings = { ...settings, currency };

  return (
    <ReportMetricContainer className={isMobile ? "mobile" : undefined}>
      <Text variant={isMobile ? "bMediumMobile" : "bMedium"}>
        {formatMetric({
          type: metric.type,
          value: metric.value,
          options: localSettings
        })}
      </Text>
      <TextNoBreak variant={isMobile ? "bodyMobile" : "body"}>
        {metric.name}&nbsp;
        <TooltipIcon title={metric.description} />
      </TextNoBreak>
    </ReportMetricContainer>
  );
};

const TextNoBreak = styled(Text)`
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
`;

const ReportMetricContainer = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;

  &.mobile {
    padding-bottom: 16px;
  }
`;

ReportMetric.propTypes = {
  metricCode: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => ({
  currency: selectDiscoveryCurrency(state),
  metric: selectDiscoveryMetric(state, props.metricCode)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReportMetric
);
