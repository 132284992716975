import React, { useContext } from "react";
import { SelectChallengesContext } from "../../../Pages/SelectChallenges/SelectChallengesProvider";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Permissions from "../../../utils/permissions";
import {
  hasPermission,
  selectDiscoveryChallenge,
  selectDiscoveryId
} from "../../../store/reducers";
import Menu, { MenuItem } from "../../Menu";
import Icon from "../../Icon";
import Dropdown from "../../Dropdown";
import { useString as s } from "../../../components/StringProvider";
import NewButton from "../../NewButton";
import { startEditingChallenge } from "../../../store/actions/edit-challenges";
import { startCreatingChallenge } from "../../../store/actions/create-challenges";
import { removeDiscoveryChallengeFromDiscovery } from "../../../store/actions/discoveries";
import { isCustomChallenge } from "../../../utils/filters/challenge-filters";
import { startDeletingChallenge } from "../../../store/actions/delete-challenges";
import styled from "styled-components";
import { themeProp } from "../../../theme";

const EDIT_CHALLENGE = "editChallenge";
const DELETE_CHALLENGE = "deleteChallenge";
const REMOVE_FROM_LIST = "removeFromList";
const SET_CBI = "setCBIText";
const UNSET_CBI = "unsetCBIText";
const REPLACE_CBI = "replaceCBIText";

const ChallengeCardDropdown = ({
  cardRef,
  challengeCode,
  startEditingChallenge,
  startDeletingChallenge,
  removeDiscoveryChallengeFromDiscovery,
  canEditChallenges,
  canSeeChallengeLibrary,
  discoveryId,
  discoveryChallenge,
  isCBI,
  isCBIPresent
}) => {
  const editChallengeText = s(
    "discovery.challenge.card.editChallenge.name",
    "Edit card"
  );
  const moreActionsText = s(
    "discovery.challenge.card.moreActions.name",
    "More actions"
  );
  const deleteChallengeText = s(
    "discovery.challenge.card.deleteChallenge.name",
    "Delete custom card"
  );
  const removeFromListText = s(
    "discovery.challenge.card.removeFromList.name",
    "Remove from list"
  );
  const setCBIText = s(
    `discovery.challenge.card.setCBI.name`,
    "Set as Critical Business Issue"
  );
  const unsetCBIText = s(
    `discovery.challenge.card.unsetCBI.name`,
    "Unset Critical Business Issue"
  );
  const replaceCBIText = s(
    `discovery.challenge.card.replaceCBI.name`,
    "Replace Critical Business Issue"
  );

  const { onChangeKeyObjective, removeKeyObjective } = useContext(
    SelectChallengesContext
  );

  const menuOptions = [
    {
      key: EDIT_CHALLENGE,
      icon: "edit",
      title: editChallengeText,
      isVisible: true,
      dataCy: "discovery-challenge-card-dropdown-edit"
    },
    {
      key: SET_CBI,
      icon: "flag",
      title: setCBIText,
      isVisible: !isCBI && !isCBIPresent,
      dataCy: "discovery-challenge-card-dropdown-set-cbi"
    },
    {
      key: UNSET_CBI,
      icon: "flag",
      title: unsetCBIText,
      isVisible: isCBI,
      dataCy: "discovery-challenge-card-dropdown-unset-cbi"
    },
    {
      key: REPLACE_CBI,
      icon: "flag",
      title: replaceCBIText,
      isVisible: !isCBI && isCBIPresent,
      dataCy: "discovery-challenge-card-dropdown-replace-cbi"
    },
    {
      key: REMOVE_FROM_LIST,
      icon: "trash",
      title: removeFromListText,
      isVisible:
        canSeeChallengeLibrary && !isCustomChallenge({ discoveryChallenge }),
      dataCy: "discovery-challenge-card-dropdown-remove"
    },
    {
      key: DELETE_CHALLENGE,
      icon: "trash",
      title: deleteChallengeText,
      isVisible:
        canSeeChallengeLibrary && isCustomChallenge({ discoveryChallenge }),
      dataCy: "discovery-challenge-card-dropdown-delete"
    }
  ];

  const onClick = (e) => {
    switch (e.key) {
      case EDIT_CHALLENGE:
        startEditingChallenge({
          challengeCode,
          position: cardRef.current.getBoundingClientRect()
        });
        break;
      case REMOVE_FROM_LIST:
        removeDiscoveryChallengeFromDiscovery({
          discoveryId,
          challengeCode,
          isCBI
        });
        break;
      case DELETE_CHALLENGE:
        startDeletingChallenge({
          challengeCode
        });
        break;
      case SET_CBI:
      case REPLACE_CBI:
        onChangeKeyObjective(challengeCode);
        break;
      case UNSET_CBI:
        removeKeyObjective(challengeCode);
        break;
      default:
        break;
    }
  };

  const renderMenu = () => (
    <Menu onClick={onClick}>
      {menuOptions.map(
        (option) =>
          option.isVisible && (
            <MenuItem
              key={option.key}
              disabled={option.isDisabled}
              data-cy={option.dataCy}
            >
              <Icon name={option.icon} />
              <MenuItemInner>
                <div>{option.title}</div>
                <div>{option.description}</div>
              </MenuItemInner>
            </MenuItem>
          )
      )}
    </Menu>
  );

  return canEditChallenges ? (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomLeft"}
      trigger={["click"]}
    >
      <NewButton
        type={"iconSecondary"}
        tooltip={moreActionsText}
        data-cy={"challenge-card-dropdown"}
      >
        <Icon name={"dropdown"} />
      </NewButton>
    </Dropdown>
  ) : null;
};

const MenuItemInner = styled.div`
  width: 257px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  div:last-of-type {
    font-weight: ${themeProp("typography.body.fontWeight")};
    font-size: ${themeProp("typography.small.fontSize")};
    opacity: 0.5;
  }
`;

ChallengeCardDropdown.propTypes = {
  challengeCode: PropTypes.string.isRequired,
  cardRef: PropTypes.object.isRequired,
  isCBI: PropTypes.bool.isRequired,
  isCBIPresent: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => ({
  discoveryId: selectDiscoveryId(state),
  canEditChallenges: hasPermission(state, Permissions.EDIT_CHALLENGES),
  canSeeChallengeLibrary: hasPermission(
    state,
    Permissions.SEE_CHALLENGE_LIBRARY
  ),
  discoveryChallenge: selectDiscoveryChallenge(state, props.challengeCode)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startEditingChallenge,
      startCreatingChallenge,
      startDeletingChallenge,
      removeDiscoveryChallengeFromDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChallengeCardDropdown
);
