import { selectDiscoveryKPI } from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { Text } from "../../components";
import PropTypes from "prop-types";

const KPIName = ({ discoveryKPI }) => {
  return <Text variant={"smallBody"}>{discoveryKPI.definition.name}</Text>;
};

const mapStateToProps = (state, props) => {
  return {
    discoveryKPI: selectDiscoveryKPI(state, props.kpiCode)
  };
};

KPIName.propTypes = {
  kpiCode: PropTypes.string.isRequired
};

export default compose(connect(mapStateToProps))(KPIName);
