import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo
} from "react";
import { Divider } from "antd";
import styled, { css } from "styled-components";
import { themeProp } from "../../../../theme";
import PropTypes from "prop-types";
import {
  hasPermission,
  selectDiscoveryCurrency,
  selectDiscoveryDelayTimeline,
  selectDiscoveryScenario,
  selectRequestData,
  selectDiscoveryShowAdditionalBenefits,
  selectDiscoveryShowOutcomesByCategory
} from "../../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import EstimationScenario from "../../../ROI/components/EstimationScenario";
import { Phases } from "../../../../utils/phases";
import actionTypes from "../../../../store/actionTypes";
import DelayTimeline from "../../../ROI/components/DelayTimeline";
import { getValue, getCategoryValue } from "../../value-hypothesis-util";
import { useMobileMediaQuery } from "../../../../components/Responsive";
import KPIColumn from "./KPIColumn";
import KPICategoryColumn from "./KPICategoryColumn";
import TotalColumn from "./TotalColumn";
import DelayTimelineOptions from "../DelayTimelineOptions";
import Permissions from "../../../../utils/permissions";
import ShowAdditionalBenefits from "../ShowAdditionalBenefits";
import ShowOutcomeByCategory from "../ShowOutcomeByCategory";

const CostOfStatusQuoChart = ({
  kpis,
  reportMode,
  currency,
  scenario,
  delayTimeline,
  template,
  printMode,
  showChartControls,
  canSeeLeadGeneration,
  showAdditionalBenefits,
  showOutcomesByCategory
}) => {
  const isMobile = useMobileMediaQuery();
  const [height, setHeight] = useState(600);
  const [currencyHeight, setCurrencyHeight] = useState(0);
  const [currencyHeights, setCurrencyHeights] = useState([]);
  const percentageBoxHeight = 48;
  const boxRef = useRef();
  const sliderWidth = printMode ? "400px" : "280px";

  const sortedKPIs = useMemo(
    () =>
      kpis
        .slice()
        .sort((a, b) => a.definition.name.localeCompare(b.definition.name)),
    [kpis]
  );

  const filteredKPIs = useMemo(
    () =>
      showAdditionalBenefits
        ? sortedKPIs
        : sortedKPIs.filter((kpi) => kpi.hasImpactValue),
    [sortedKPIs, showAdditionalBenefits]
  );

  const activeKPIs = useMemo(
    () => filteredKPIs.filter((kpi) => kpi.selected),
    [filteredKPIs]
  );

  const currencyKPIs = useMemo(
    () =>
      activeKPIs.filter(
        (kpi) => kpi.hasImpactValue && !kpi.definition.oneTimeBenefit
      ),
    [activeKPIs]
  );

  const percentageKPIs = useMemo(
    () =>
      activeKPIs.filter(
        (kpi) =>
          !kpi.hasImpactValue &&
          kpi.hasImpactPercentage &&
          !kpi.definition.oneTimeBenefit
      ),
    [activeKPIs]
  );

  const kpisByCategory = useMemo(() => {
    const categoryTotals = {};

    activeKPIs
      .filter((kpi) => !kpi.definition.oneTimeBenefit)
      .forEach((item) => {
        const { categoryCode } = item.definition;
        const { expectedImpactValue, lowImpactValue, potentialImpactValue } =
          item;

        if (categoryTotals[categoryCode]) {
          categoryTotals[categoryCode].expectedImpactValue +=
            expectedImpactValue || 0;
          categoryTotals[categoryCode].lowImpactValue += lowImpactValue || 0;
          categoryTotals[categoryCode].potentialImpactValue +=
            potentialImpactValue || 0;
        } else {
          categoryTotals[categoryCode] = {
            expectedImpactValue: expectedImpactValue || 0,
            lowImpactValue: lowImpactValue || 0,
            potentialImpactValue: potentialImpactValue || 0
          };
        }
      });

    return {
      categories:
        Object.entries(categoryTotals).map(([categoryCode, impactValues]) => ({
          categoryCode,
          ...impactValues
        })) || []
    };
  }, [activeKPIs]);

  const totalAmount = useMemo(
    () =>
      currencyKPIs.reduce(
        (sum, kpi) => getValue({ kpi, scenario, delayTimeline }) + sum,
        0
      ),
    [currencyKPIs, scenario, delayTimeline]
  );

  useLayoutEffect(() => {
    if (boxRef.current) {
      setHeight(boxRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    setCurrencyHeight(
      height - 108 - 20 - percentageBoxHeight * percentageKPIs.length
    );
  }, [height, percentageKPIs.length]);

  const shouldDisplayCategoryOutcomes =
    showOutcomesByCategory &&
    kpisByCategory.categories &&
    !showAdditionalBenefits;

  const calculateHeights = (items, currencyHeight, totalAmount, getValueFn) => {
    return items.map((item) =>
      Math.ceil((currencyHeight * getValueFn(item)) / totalAmount)
    );
  };

  useEffect(() => {
    const heights = !shouldDisplayCategoryOutcomes
      ? calculateHeights(
          currencyKPIs,
          currencyHeight,
          totalAmount,
          (currencyKPI) =>
            getValue({ kpi: currencyKPI, scenario, delayTimeline })
        )
      : calculateHeights(
          kpisByCategory.categories,
          currencyHeight,
          totalAmount,
          (category) => getCategoryValue({ category, scenario, delayTimeline })
        );

    setCurrencyHeights(heights);
  }, [
    currencyKPIs,
    kpisByCategory,
    currencyHeight,
    scenario,
    delayTimeline,
    totalAmount,
    showOutcomesByCategory,
    shouldDisplayCategoryOutcomes
  ]);

  if (showChartControls === undefined) {
    showChartControls = !reportMode || (reportMode && template !== Phases.ROI);
    if (canSeeLeadGeneration) {
      showChartControls = false;
    }
  }
  return (
    <ChartContainer
      data-cy={"cost-of-status-quo-chart"}
      className={"cost-of-status-quo"}
    >
      {showChartControls && (
        <>
          <ChartMenu>
            <SlidersContainer isMobile={isMobile} printMode={printMode}>
              <DelayTimeline reportMode={reportMode} fixedWidth={sliderWidth} />
              <EstimationScenario
                reportMode={reportMode}
                fixedWidth={sliderWidth}
              />
              <ShowOutcomeByCategory
                reportMode={reportMode}
                printMode={printMode}
              />
              <ShowAdditionalBenefits
                reportMode={reportMode}
                printMode={printMode}
              />
            </SlidersContainer>
          </ChartMenu>
          <ChartDivider />
        </>
      )}
      <ChartBox
        ref={boxRef}
        reportMode={reportMode}
        printMode={printMode}
        currencyHeights={currencyHeights}
        numberOfPercentageKPIs={percentageKPIs.length}
      >
        {shouldDisplayCategoryOutcomes ? (
          <>
            {kpisByCategory.categories.map((kpiCategory, index) => (
              <KPICategoryColumn
                key={kpiCategory.categoryCode}
                index={index}
                kpiCategory={kpiCategory}
                categoryCode={kpiCategory.categoryCode}
                currency={currency}
                delayTimeline={delayTimeline}
                scenario={scenario}
              />
            ))}
            <TotalColumn
              numberOfCurrencyKPIs={kpisByCategory.categories.length}
              numberOfNonCurrencyKPIs={0}
              currency={currency}
              totalAmount={totalAmount}
            />
          </>
        ) : (
          <>
            {currencyKPIs.map((discoveryKPI, index) => (
              <KPIColumn
                discoveryKPI={discoveryKPI}
                key={discoveryKPI.kpiCode}
                index={index}
                currency={currency}
                delayTimeline={delayTimeline}
                scenario={scenario}
              />
            ))}
            {percentageKPIs.map((discoveryKPI, index) => (
              <KPIColumn
                discoveryKPI={discoveryKPI}
                key={discoveryKPI.kpiCode}
                index={index + currencyKPIs.length}
                currency={currency}
                delayTimeline={DelayTimelineOptions.YEAR}
                scenario={scenario}
              />
            ))}
            <TotalColumn
              numberOfCurrencyKPIs={currencyKPIs.length}
              numberOfNonCurrencyKPIs={percentageKPIs.length}
              currency={currency}
              totalAmount={totalAmount}
            />
          </>
        )}
      </ChartBox>
    </ChartContainer>
  );
};

const SlidersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      flex-direction: column;

      @media screen and (min-width: 1160px) {
        flex-direction: row;
      }
    `}

  ${({ printMode }) =>
    printMode &&
    css`
      flex-direction: row;
    `}
`;

const ChartBox = styled.div`
  min-width: 100%;
  display: grid;
  ${({ printMode }) => (!printMode ? "min-height: 550px;" : "")};
  grid-template-rows:
    54px ${({ currencyHeights }) =>
      currencyHeights.map((v) => `minmax(3px, ${v}px)`).join(" ")}
    ${({ numberOfPercentageKPIs }) =>
      numberOfPercentageKPIs
        ? `repeat(${numberOfPercentageKPIs},48px)`
        : undefined}
    54px;
  grid-template-columns:
    repeat(
      ${({ numberOfPercentageKPIs, currencyHeights }) =>
        numberOfPercentageKPIs + currencyHeights.length},
      minmax(80px, 1fr)
    )
    184px;
  column-gap: 8px;
  overflow-x: scroll;
  flex-grow: 1;
  padding: 32px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${themeProp("palette.gray2")};
  }
`;

const ChartMenu = styled.div`
  padding: 32px;

  & > * {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  background-color: ${themeProp("palette.surface")};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ChartDivider = styled(Divider)`
  margin: 0;
`;

ChartBox.propTypes = {
  reportMode: PropTypes.bool,
  printMode: PropTypes.bool
};

ChartBox.defaultProps = {
  reportMode: false
};

CostOfStatusQuoChart.propTypes = {
  reportMode: PropTypes.bool,
  showChartControls: PropTypes.bool,
  printMode: PropTypes.bool
};

CostOfStatusQuoChart.defaultProps = {
  reportMode: false
};

const mapStateToProps = (state, props) => ({
  scenario: selectDiscoveryScenario(state),
  delayTimeline: selectDiscoveryDelayTimeline({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_DELAY_TIMELINE_REQUEST
  }),
  currency: selectDiscoveryCurrency(state),
  template: selectRequestData(
    state,
    actionTypes.DISCOVERY_SET_REPORT_TEMPLATE_SUCCESS
  ),
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  showAdditionalBenefits: selectDiscoveryShowAdditionalBenefits({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_REQUEST
  }),
  showOutcomesByCategory: selectDiscoveryShowOutcomesByCategory({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_SHOW_OUTCOMES_BY_CATEGORY_REQUEST
  })
});

export default compose(connect(mapStateToProps))(CostOfStatusQuoChart);
