import { useEffect, useState } from "react";
import { message, Space } from "antd";
import NewButton from "../NewButton";
import Icon from "../Icon";
import styled from "styled-components";
import { themeProp } from "../../theme";
import {
  hasPermission,
  selectCookie,
  selectDiscoveryId,
  selectKPILibraryOpen,
  selectRequestData,
  selectRequestState
} from "../../store/reducers";
import {
  addDiscoveryKPIFromLibrary,
  getDiscoveryKPILibrary
} from "../../store/actions/discoveries";
import Permissions from "../../utils/permissions";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { setKPILibraryOpen } from "../../store/actions/kpi-library";
import { useMobileMediaQuery } from "../Responsive";
import { useString as s } from "../StringProvider";
import Text from "../Text";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import KPILibraryGroup from "./KPILibraryGroup";
import SearchBar from "../SearchBar";
import { startCreatingKPI } from "../../store/actions/create-kpis";
import Toggle from "../Toggle";
import { setCookie } from "../../store/actions/cookies";
import Heading from "../Heading";
import { stopDeletingKPI } from "../../store/actions/delete-kpis";
import Tooltip from "../Tooltip";
import LibraryDrawer from "./LibraryDrawer";
import { ReactComponent as InfoIcon } from "../../assets/InfoCircleOutlined.svg";

export const KPI_LIBRARY_COOKIE_NAME = "kpiLibrary";

const KPILibrary = ({
  canSeeKPILibrary,
  setKPILibraryOpen,
  kpiLibraryOpen,
  getDiscoveryKPILibrary,
  kpiLibrary,
  loadingState,
  discoveryId,
  addDiscoveryKPILoadingState,
  addDiscoveryKPIFromLibrary,
  removeDiscoveryKPILoadingState,
  deleteDiscoveryKPILoadingState,
  startCreatingKPI,
  stopDeletingKPI,
  setCookie,
  cookie,
  hasFormula
}) => {
  const isMobile = useMobileMediaQuery();
  const start = 0;
  const pageSize = 100;
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState("asc");
  const hideText = s("discovery.kpiLibrary.hide.text", "Hide");
  const sortText = s("discovery.kpiLibrary.sort.text", "Sort A - Z");
  const formulaText = s("discovery.kpiLibrary.hasFormula.text", "Has formula");
  const formulaToolTipTitle = s(
    "discovery.kpiLibrary.hasFormula.tooltip.title",
    "Show outcomes with a value formula"
  );
  const toolTipTitle = s(
    "discovery.kpiLibrary.tooltip.title",
    "Outcomes Library"
  );
  const kpiLibraryTitle = s("discovery.kpiLibrary.title", "Outcomes Library");
  const emptyLibraryText = s(
    "discovery.kpiLibrary.empty",
    "All pre-configured outcomes have been added to the discovery."
  );

  const noSearchResultsText = s(
    "discovery.kpiLibrary.searchResults.empty",
    "We didn't find any matching outcomes. Please try again or add a custom outcome."
  );

  const errorText = s(
    "discovery.kpiLibrary.messages.getFailed",
    "The Outcomes Library could not be loaded"
  );
  const addSucceeded = s(
    "discovery.kpiLibrary.messages.added",
    "Outcome added to the discovery!"
  );
  const addFailed = s(
    "discovery.kpiLibrary.messages.addFailed",
    "Failed to add outcome from library"
  );
  const removeSucceeded = s(
    "discovery.kpi.card.removeFromList.succeeded",
    "Outcome removed from the discovery!"
  );
  const removeFailed = s(
    "discovery.kpi.card.removeFromList.failed",
    "Failed to remove outcome from the discovery!"
  );
  const deleteSucceeded = s(
    "discovery.kpi.card.delete.succeeded",
    "Outcome deleted from the discovery!"
  );
  const deleteFailed = s(
    "discovery.kpi.card.delete.failed",
    "Failed to delete outcome from the discovery!"
  );
  const searchPlaceholderText = s(
    "discovery.kpiLibrary.search.placeholder",
    "Search by outcome name"
  );
  const addCustomKPIText = s(
    "discovery.kpiLibrary.addCustomKPI.text",
    "Add Custom Outcome"
  );

  const doQuery = () => {
    const query = {
      discoveryId,
      start,
      count: pageSize,
      hasFormula,
      order
    };

    if (searchTerm) {
      query.search = searchTerm;
    }

    getDiscoveryKPILibrary(query);
  };

  useLoadingState(
    removeDiscoveryKPILoadingState,
    () => {
      message.success({
        content: removeSucceeded,
        icon: <InfoIcon />
      });
      doQuery();
    },
    () => {
      message.error(removeFailed);
    }
  );

  useLoadingState(
    deleteDiscoveryKPILoadingState,
    () => {
      message.success({
        content: deleteSucceeded,
        icon: <InfoIcon />
      });
      stopDeletingKPI();
      doQuery();
    },
    () => {
      message.error(deleteFailed);
      stopDeletingKPI();
    }
  );

  useLoadingState(
    addDiscoveryKPILoadingState,
    () => {
      message.success(addSucceeded);
      doQuery();
    },
    () => {
      message.error(addFailed);
    }
  );
  const onAddKPI = (kpiCode) => {
    addDiscoveryKPIFromLibrary({ discoveryId, kpiCode });
  };

  useEffect(() => {
    if (discoveryId && kpiLibraryOpen) {
      doQuery();
    }
  }, [discoveryId, kpiLibraryOpen, searchTerm, hasFormula, order]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      message.error(errorText);
    }
  );

  const onClickAddCustomKPI = () => {
    startCreatingKPI();
  };

  const onClick = () => {
    setKPILibraryOpen({ open: true });
  };

  const onClose = () => {
    setKPILibraryOpen({ open: false });
  };

  if (!canSeeKPILibrary) {
    return null;
  }

  const kpis = (kpiLibrary && kpiLibrary.items) || [];
  const categoryCodes = ((kpiLibrary && kpiLibrary.categories) || []).map(
    (category) => category.code
  );

  const onSearch = (e) => {
    setSearchTerm(e);
  };

  const doOrder = () => {
    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  };

  const toggleState = (checked) => {
    const newData = { ...cookie };
    newData.hasFormula = checked;

    setCookie({
      name: KPI_LIBRARY_COOKIE_NAME,
      value: JSON.stringify(newData)
    });
  };

  const angleUp = <Icon name={"angleUp"} colour={"primary"} size={"base"} />;
  const angleDown = (
    <Icon name={"angleDown"} colour={"primary"} size={"base"} />
  );

  const renderTitle = () => (
    <div>
      <HideContainer>
        <Heading level={isMobile ? "h4" : "h3"} data-cy={"kpi-library-title"}>
          <b>{kpiLibraryTitle}</b>
        </Heading>
        <NewButton
          type={"text"}
          onClick={onClose}
          data-cy={"close-kpi-library"}
        >
          {hideText}
          <Icon name={"right"} colour={"primary"} size={"medium"} />
        </NewButton>
      </HideContainer>
      <SearchBar
        placeholder={searchPlaceholderText}
        onSearch={onSearch}
        data-cy={"search-kpi-library"}
      />
      <SortAndToggleRow>
        <Sort onClick={doOrder}>
          <NewButton type={"text"}>
            {sortText}
            {order === "asc" ? angleDown : angleUp}
          </NewButton>
        </Sort>
        <HasFormula>
          <HasFormulaLabel variant={"body"} color={"text"}>
            {formulaText}
          </HasFormulaLabel>
          <Tooltip
            overlayInnerStyle={{ width: "150px" }}
            title={formulaToolTipTitle}
            placement={"top"}
          >
            <Toggle
              checked={hasFormula}
              onChange={toggleState}
              data-cy={"has-formula-toggle"}
            />
          </Tooltip>
        </HasFormula>
      </SortAndToggleRow>
    </div>
  );
  const renderFooter = () => (
    <div>
      <KPILibraryItemContainer onClick={onClickAddCustomKPI}>
        <Space direction={"horizontal"} size={16}>
          <NewButton
            type={"iconPrimary"}
            onClick={onClickAddCustomKPI}
            data-cy={"add-custom-kpi"}
          >
            <Icon
              data-cy="kpi-library-add-custom-kpi-icon"
              name={"plusCircle"}
              size={"large"}
              colour={"primary"}
            />
          </NewButton>
          <Text variant={"label"} color={"primary"}>
            <b>{addCustomKPIText}</b>
          </Text>
        </Space>
      </KPILibraryItemContainer>
    </div>
  );

  return (
    <Container data-cy="kpi-library" isMobile={isMobile}>
      <NewButton
        data-cy="open-kpi-library"
        type={"iconPrimary"}
        onClick={onClick}
        className={"up-open-outcomes-library"}
      >
        <Tooltip title={toolTipTitle} placement={"left"}>
          <Icon name={"left"} size={"large"} />
        </Tooltip>
      </NewButton>
      <SideBar isMobile={isMobile} />
      <LibraryDrawer
        open={kpiLibraryOpen}
        onClose={onClose}
        title={renderTitle()}
        footer={renderFooter()}
        className={"up-outcomes-library-header"}
      >
        <Contents data-cy="kpi-library-contents">
          <Space direction={"vertical"} size={24} style={{ width: "100%" }}>
            {categoryCodes.map((categoryCode) => (
              <KPILibraryGroup
                key={categoryCode}
                categoryCode={categoryCode}
                onAdd={onAddKPI}
                searchTerm={searchTerm}
              />
            ))}
          </Space>
          {!kpis.length && (
            <EmptyLibraryContainer data-cy={"empty-library-container"}>
              <Text color={"primary"} alignment={"center"}>
                {searchTerm ? noSearchResultsText : emptyLibraryText}
              </Text>
            </EmptyLibraryContainer>
          )}
        </Contents>
      </LibraryDrawer>
    </Container>
  );
};

const Sort = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const HasFormula = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SortAndToggleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 8px; //margin-bottom of SearchBar is 16px
  margin-bottom: 24px;
`;

const KPILibraryItemContainer = styled.div`
  padding: 16px 24px 16px 0;
  border-color: ${themeProp("palette.gray1")};
  border-width: 0;
  border-radius: 8px;

  &:hover {
    background-color: ${themeProp(`components.newButton.text.backgroundHover`)};
    cursor: pointer;
  }
`;

const HideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 17px;
  margin-top: 7px;
`;

const HasFormulaLabel = styled(Text)`
  margin-right: 8px;
`;

const Container = styled.div`
  min-height: calc(100vh - 140px);
  max-width: 40px;
  background-color: ${themeProp("palette.surface")};
  position: fixed;
  right: 0;
  top: ${({ isMobile }) => (isMobile ? "100px" : "140px")};
  z-index: 1;
  padding-top: 24px;
`;

const SideBar = styled.div`
  min-height: calc(100vh - 140px);
  max-width: 5px;
  background-color: ${themeProp("palette.surface")};
  position: fixed;
  right: 0;
  top: ${({ isMobile }) => (isMobile ? "100px" : "140px")};
  z-index: 1;
  padding-top: 24px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EmptyLibraryContainer = styled.div`
  max-width: 250px;
  align-self: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const mapStateToProps = (state) => {
  const jsonString = selectCookie(state, KPI_LIBRARY_COOKIE_NAME) || "{}";
  const cookie = JSON.parse(jsonString);
  const hasFormula = cookie.hasFormula;

  return {
    canSeeKPILibrary: hasPermission(state, Permissions.SEE_KPI_LIBRARY),
    kpiLibraryOpen: selectKPILibraryOpen(state),
    kpiLibrary: selectRequestData(state, actionTypes.GET_KPI_LIBRARY_REQUEST),
    loadingState: selectRequestState(
      state,
      actionTypes.GET_KPI_LIBRARY_REQUEST
    ),
    addDiscoveryKPILoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST
    ),
    removeDiscoveryKPILoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_REQUEST
    ),
    deleteDiscoveryKPILoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_DELETE_KPI_REQUEST
    ),
    discoveryId: selectDiscoveryId(state),
    cookie,
    hasFormula
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setKPILibraryOpen,
      getDiscoveryKPILibrary,
      addDiscoveryKPIFromLibrary,
      startCreatingKPI,
      stopDeletingKPI,
      setCookie
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  KPILibrary
);
