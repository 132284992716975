import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import ROIBenefit from "../../ROI/components/ROIBenefit";
import AdditionalBenefit from "../../ROI/components/AdditionalBenefit";
import PrintROIStatistics from "./PrintROIStatistics";
import ROIStatistics from "../../ROI/components/ROIStatistics";
import EstimationScenario from "../../ROI/components/EstimationScenario";
import {
  selectDiscoveryKPIs,
  selectDiscoveryName,
  selectDiscoveryScenario
} from "../../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { impactPercentageKPIsFilter } from "../../../utils/filters/kpi-filters";

const PrintReportROIOne = ({ discoveryKPIs, scenario, discoveryName }) => {
  const hasBenefitKPIs = impactPercentageKPIsFilter(discoveryKPIs).length > 0;

  return (
    <Container>
      <Heading level={"h1"} align={"center"}>
        {s("report.roi.header", "Return on Investment for {discoveryName}", {
          discoveryName
        })}
      </Heading>
      <EstimationScenario reportMode={false} />
      {hasBenefitKPIs && (
        <>
          <Row>
            <ROIBenefit />
            <AdditionalBenefit scenario={scenario} printMode={true} />
          </Row>
          <Row>
            <PrintROIStatistics />
          </Row>
        </>
      )}
      {!hasBenefitKPIs && (
        <Row>
          <ROIBenefit />
          <ROIStatistics useMaxWidth={false} />
        </Row>
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${themeProp("palette.background")};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;

  & div:first-child {
    margin-right: 2px;
  }

  & div:nth-child(2) {
    margin-left: 2px;
  }
`;

const mapStateToProps = (state) => ({
  scenario: selectDiscoveryScenario(state),
  discoveryKPIs: selectDiscoveryKPIs(state),
  discoveryName: selectDiscoveryName(state)
});

export default compose(withRouter, connect(mapStateToProps))(PrintReportROIOne);
