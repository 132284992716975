import styled from "styled-components";
import { Divider } from "antd";

const WhiteDivider = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: white;
`;

export default WhiteDivider;
