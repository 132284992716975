import httpService from "./http.service";

const getCurrentConfigurations = () =>
  httpService
    .get(`/configurations`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const downloadGuide = ({ guideName }) =>
  httpService
    .post(`/configurations/guides/${guideName}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateBrandingThemes = ({
  primaryColor,
  secondaryColor,
  heroFileId,
  logoFileId
}) =>
  httpService
    .post(`/configurations/updateBranding/themes`, {
      primaryColor,
      secondaryColor,
      heroFileId,
      logoFileId
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateBrandingStrings = (data) =>
  httpService
    .post(`/configurations/updateBranding/strings`, data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getConfigurationLogs = ({ start = 0, count = 10, sort, order }) =>
  httpService
    .get(`/configurations/logs`, {}, { start, count, sort, order })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getConfigurationLog = ({ configurationLogId }) =>
  httpService
    .get(`/configurations/logs/${configurationLogId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getCurrentConfigurations,
  downloadGuide,
  getConfigurationLogs,
  getConfigurationLog,
  updateBrandingThemes,
  updateBrandingStrings
};
export default methods;
