import httpService from "./http.service";

const setIntegration = ({ name, baseUrl, clientId, clientSecret }) =>
  httpService
    .put(`/integrations/${name}`, { baseUrl, clientId, clientSecret })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getIntegration = ({ name }) =>
  httpService
    .get(`/integrations/${name}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteIntegration = ({ name }) =>
  httpService
    .delete(`/integrations/${name}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const retrieveIntegrationToken = ({ name, code, redirect_uri }) =>
  httpService
    .post(`/integrations/${name}/retrieveTokens`, { code, redirect_uri })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getIntegration,
  setIntegration,
  deleteIntegration,
  retrieveIntegrationToken
};

export default methods;
