import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useString as s } from "../../../components/StringProvider";
import NewButton from "../../../components/NewButton";
import Icon from "../../../components/Icon";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import { startROIOverrideOperation } from "../../../store/actions/roi-overrides-operations";

const AddOutcomeOverrideButton = ({
  outcomeOverrideType,
  startROIOverrideOperation
}) => {
  const buttonText = s(
    "discovery.roi.form.operation.addOutcomeOverride.name",
    "Add Outcome Override"
  );

  const onClick = () => {
    startROIOverrideOperation({
      kpiCode: undefined,
      outcomeOverrideType,
      isEdit: false
    });
  };

  return (
    <NewButton
      type={"outlined"}
      onClick={onClick}
      data-cy={"add-outcome-override-button"}
    >
      <StyledIcon name={"add"} size={"mediumLarge"} colour={"gray4"} />
      {buttonText}
    </NewButton>
  );
};

const StyledIcon = styled(Icon)`
  margin-right: 12px;

  &:hover {
    color: ${themeProp("palette.secondary")};
  }
`;

AddOutcomeOverrideButton.propTypes = {
  outcomeOverrideType: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startROIOverrideOperation
    },
    dispatch
  );

export default compose(connect(null, mapDispatchToProps))(
  AddOutcomeOverrideButton
);
