import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { DiscoveryFooter, DiscoveryTitleBar } from "../../components/Discovery";
import Heading from "../../components/Heading";
import { selectDiscoveryId, selectDiscoveryName } from "../../store/reducers";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import KPISummaryCards from "./KPISummaryCards";
import { useString as s } from "../../components/StringProvider";
import {
  FinishDiscoveryButton,
  GoToBusinessMetricsButton
} from "../../components/DiscoveryButtons";
import { useMobileMediaQuery } from "../../components/Responsive";
import styled from "styled-components";
import { Phases } from "../../utils/phases";
import DiscoverySubHeader from "../Discovery/DiscoverySubHeader";

const KPISummary = ({ discoveryId, discoveryName }) => {
  const isMobile = useMobileMediaQuery();
  const header = s(
    "discovery.kpi.summary.page.header",
    "Success Outcomes for {discoveryName}",
    { discoveryName }
  );

  const link1Text = s(
    "discovery.kpi.summary.page.subheader.link1",
    "Edit Outcomes"
  );

  const link2Text = s("discovery.kpi.summary.page.subheader.link2", "Summary");

  const linkConfig = [
    {
      to: `/discoveries/${discoveryId}/select-kpis`,
      label: link1Text
    },
    {
      to: `/discoveries/${discoveryId}/kpi-summary`,
      label: link2Text
    }
  ];

  return (
    <DiscoveryContentWrapper format={"wide"} isMobile={isMobile}>
      <DiscoverySubHeader
        phase={Phases.KPIS_IMPACTED}
        linkConfig={linkConfig}
      />
      <DiscoveryTitleBar>
        <Header className={isMobile ? "mobile" : undefined}>
          <Heading level={isMobile ? "h3" : "h2"}>{header}</Heading>
        </Header>
      </DiscoveryTitleBar>

      <KPISummaryCards />

      <DiscoveryFooter>
        <GoToBusinessMetricsButton />
        <FinishDiscoveryButton />
      </DiscoveryFooter>
    </DiscoveryContentWrapper>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;

  &.mobile {
    text-align: center;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 8px;
  }
`;

function mapStateToProps(state) {
  return {
    discoveryId: selectDiscoveryId(state),
    discoveryName: selectDiscoveryName(state)
  };
}

export default compose(withRouter, connect(mapStateToProps))(KPISummary);
