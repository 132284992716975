import React from "react";
import Header, { BrandBadge } from "../../components/Header";

const OrganizationHeader = ({}) => {
  return (
    <Header>
      <BrandBadge linkTo={"/MyDiscoveries"} />
    </Header>
  );
};

export default OrganizationHeader;
