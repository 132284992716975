import React from "react";
import styled from "styled-components";
import ChatPrompt from "./ChatPrompt";
import ChatMessage from "./ChatMessage";
import ChatForm from "./ChatForm";
import { useChat } from "./ChatProvider";

const Chat = () => {
  const { isChatActive } = useChat();

  return (
    <ChatContainer>
      <ChatScroll>
        <ChatPromptContainer data-cy={"chat-scroll-container"}>
          <ChatPromptInner>
            {!isChatActive && <ChatPrompt />}
            {isChatActive && <ChatMessage />}
          </ChatPromptInner>
        </ChatPromptContainer>
      </ChatScroll>
      <ChatFormContainer>
        <ChatForm />
      </ChatFormContainer>
    </ChatContainer>
  );
};

const ChatContainer = styled.section`
  height: 560px;
  display: flex;
  flex-direction: column;
`;

const ChatScroll = styled.section`
  flex: 1;
  overflow-y: auto;
`;

const ChatPromptContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const ChatPromptInner = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const ChatFormContainer = styled.section`
  flex: 0;
`;

export default Chat;
