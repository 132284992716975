import { Input as ANTInput } from "antd";
import styled, { css } from "styled-components";
import Input from "./Input";
import { themeProp } from "../../theme";
import { useMobileMediaQuery } from "../Responsive";
import PropTypes from "prop-types";

const PasswordInput = ({ ...props }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <PasswordInputComponent
      className={isMobile ? "mobile" : undefined}
      {...props}
    />
  );
};

const PasswordInputComponent = styled(ANTInput.Password)
  .withConfig({
    shouldForwardProp: (prop) => !["block"].includes(prop)
  })
  .attrs(() => ({
    bordered: false
  }))`

  padding: 0;

  & > input.ant-input, &.ant-input-password {
    width: ${(props) => (props.block ? "100%" : "initial")};
    background: transparent;
    color: ${themeProp("palette.text")};
    transition: border-color 0.2s ease-out;
    border-bottom: 1px solid ${themeProp("palette.secondary")};
    font-size: ${themeProp("typography.body.fontSize")};
    transition: border-color 0.2s ease-out;
    padding: 4px 12px;
    border-radius: 0;

    ${({ size }) =>
      size === "large"
        ? css`
            height: 54px;
            padding: 0 12px;
          `
        : css`
            height: 40px;
            padding: 0 8px;
          `}

    &:hover {
      border-bottom: 1px solid ${themeProp("palette.secondary")};
    }

    &:focus {
      border-bottom: 1px solid ${themeProp("palette.secondary")};
      background-color: ${themeProp("palette.surface")};
    }

    &.ant-input-disabled {
      border-bottom: 1px solid transparent;
      padding-left: 0;
      padding-right: 0;
      color: ${themeProp("palette.gray4")};
    }
  
    &.ant-input-disabled:hover {
      border-bottom: 1px solid transparent;
      background-color: ${themeProp("palette.gray1")};
    }
  }

  &.ant-input-password {
    padding: 0;
  }

  .ant-input-suffix {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
  }
`;

PasswordInput.propTypes = {
  ...Input.propTypes,
  size: PropTypes.oneOf(["medium", "large"])
};
PasswordInput.defaultProps = {
  ...Input.defaultProps,
  size: "large"
};

export default PasswordInput;
