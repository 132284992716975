import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { useString as s } from "../StringProvider";
import Text from "../Text";
import NewButton from "../NewButton";
import { hasPermission } from "../../store/reducers";
import Permissions from "../../utils/permissions";
import { connect } from "react-redux";
import ButtonGroup from "../ButtonGroup";
import Modal from "../Modal";

const FinishDiscoveryButton = ({
  canSeeFinishHere,
  variant,
  history,
  ...props
}) => {
  const [modal, showModal] = useState(false);
  const finishHere = s("discovery.summary.page.finish_button", "Finish here");
  const finishDiscovery = s(
    "discovery.finish.modal.header",
    "Finish discovery"
  );
  const modalText = s(
    "discovery.finish.modal.text",
    "Would you like to finish discovery here? You can resume it anytime."
  );
  const finishHereButton = s("discovery.finish.modal.button1", "Finish here");
  const cancelButton = s("discovery.finish.modal.button2", "Continue editing");

  const onClick = () => {
    history.push({ pathname: `/MyDiscoveries` });
  };

  return canSeeFinishHere ? (
    <>
      <NewButton
        type={variant}
        onClick={() => showModal(true)}
        data-cy={"finish-here-button"}
        {...props}
      >
        {finishHere}
      </NewButton>
      <Modal
        title={finishDiscovery}
        visible={modal}
        onCancel={() => showModal(false)}
      >
        <Text>{modalText}</Text>
        <div style={{ paddingTop: 24 }}>
          <ButtonGroup>
            <NewButton type={"primary"} onClick={onClick}>
              {finishHereButton}
            </NewButton>
            <NewButton type={"secondary"} onClick={() => showModal(false)}>
              {cancelButton}
            </NewButton>
          </ButtonGroup>
        </div>
      </Modal>
    </>
  ) : null;
};

FinishDiscoveryButton.propTypes = {
  variant: PropTypes.oneOf(["primary", "alternate"])
};
FinishDiscoveryButton.defaultProps = {
  variant: "alternate"
};

const mapStateToProps = (state) => {
  return {
    canSeeFinishHere: hasPermission(state, Permissions.SEE_FINISH_HERE)
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(FinishDiscoveryButton);
