import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Space, Spin } from "antd";
import NewButton from "../../../../components/NewButton";
import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "../../../../components/ThemeProvider";
import { LoadingOutlined } from "@ant-design/icons";

const UpdateConfigurationForm = ({
  onUpdateConfiguration,
  onDiscard,
  loading
}) => {
  const updateConfiguration = s(
    "manageConfiguration.page.updateConfiguration.updateConfiguration",
    "Update Configuration"
  );
  const discard = s(
    "manageConfiguration.page.updateConfiguration.discard",
    "Discard"
  );
  const successColor = useTheme("palette.success");

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: successColor }} spin />
  );

  return (
    <Space size={16} style={{ marginTop: "16px" }}>
      <NewButton type={"primary"} onClick={onUpdateConfiguration}>
        {updateConfiguration}
      </NewButton>
      <NewButton type={"secondary"} onClick={onDiscard}>
        {discard}
      </NewButton>
      {loading && <Spin indicator={antIcon} />}
    </Space>
  );
};

UpdateConfigurationForm.propTypes = {
  onDiscard: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UpdateConfigurationForm
);
