import httpService from "./http.service";

const getDiscoveryCommentCount = ({ discoveryId }) =>
  httpService
    .get(`/discoveries/${discoveryId}/commentCount`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getComments = ({ topic }) =>
  httpService
    .get(`/comments/?topic=${encodeURIComponent(topic)}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getValueNote = ({ discoveryId, kpiCode }) => {
  const topic = `/discoveries/${discoveryId}/kpis/${kpiCode}`;

  return httpService
    .get(`/comments/?topic=${encodeURIComponent(topic)}&isValueNote=true`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));
};

const postComment = ({ topic, text }) =>
  httpService
    .post(`/comments/`, { topic, text })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateComment = ({ commentId, text }) =>
  httpService
    .patch(`/comments/${commentId}`, { text })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteComment = ({ commentId }) =>
  httpService
    .delete(`/comments/${commentId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getReportCommentCount = ({ reportId }) =>
  httpService
    .get(`/reports/${reportId}/commentCount`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getReportComments = ({ reportId, topic }) =>
  httpService
    .get(`/reports/${reportId}/comments/?topic=${encodeURIComponent(topic)}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const postReportComment = ({ reportId, topic, text }) =>
  httpService
    .post(`/reports/${reportId}/comments/`, { topic, text })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateReportComment = ({ reportId, commentId, text }) =>
  httpService
    .patch(`/reports/${reportId}/comments/${commentId}`, { text })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteReportComment = ({ reportId, commentId }) =>
  httpService
    .delete(`/reports/${reportId}/comments/${commentId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getDiscoveryCommentCount,
  getComments,
  getValueNote,
  postComment,
  updateComment,
  deleteComment,
  getReportCommentCount,
  getReportComments,
  postReportComment,
  updateReportComment,
  deleteReportComment
};

export default methods;
