import React, { useEffect, useState } from "react";
import { useString as s } from "../../../components/StringProvider";
import PropTypes from "prop-types";
import { Space, message } from "antd";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Text from "../../../components/Text";
import NewButton from "../../../components/NewButton";
import { selectDiscoveryRequestROILoadingState } from "../../../store/reducers";
import LoadingState from "../../../utils/loading-state";
import { requestDiscoveryROI } from "../../../store/actions/discoveries";
import ButtonGroup from "../../../components/ButtonGroup";
import { Modal } from "../../../components";

const RequestEvaluationModal = ({
  discoveryId,
  discoveryUsername,
  loadingState,
  onClose,
  requestDiscoveryROI
}) => {
  const okText = s("request_evaluation.popup.okButton", "Notify to evaluate");
  const cancelText = s("request_evaluation.popup.cancelButton", "Don't Notify");
  const titleText = s("request_evaluation.popup.header", "Evaluate ROI");
  const bodyText = s(
    "request_evaluation.popup.body",
    "You can evaluate ROI only in collaboration with the Discovery Owner. Would you like to progress to ROI evaluation and notify the Discovery Owner? ",
    { discoveryUsername }
  );
  const successText = s(
    "request_evaluation.popup.success",
    "We notified the Discovery Owner. Stay tuned!"
  );
  const errorText = s(
    "request_evaluation.popup.error",
    "Failed to notify the Discovery Owner"
  );

  const [progressing, setProgressing] = useState(false);

  useEffect(() => {
    switch (loadingState) {
      case LoadingState.IN_PROGRESS:
        setProgressing(true);
        break;
      case LoadingState.COMPLETED:
        if (progressing) {
          setProgressing(false);
          message.success(successText);
          onClose();
        }
        break;
      case LoadingState.FAILED:
        if (progressing) {
          setProgressing(false);
          message.error(errorText);
        }
        break;
    }
  }, [loadingState, progressing]);

  const onNotify = () => {
    requestDiscoveryROI({ discoveryId });
  };

  return (
    <Modal title={titleText} open={true} onCancel={onClose}>
      <Space direction={"vertical"} size={24}>
        <Text>{bodyText}</Text>
        <ButtonGroup size={"small"}>
          <NewButton type={"primary"} onClick={onNotify}>
            {okText}
          </NewButton>
          <NewButton type={"secondary"} onClick={onClose}>
            {cancelText}
          </NewButton>
        </ButtonGroup>
      </Space>
    </Modal>
  );
};

RequestEvaluationModal.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  discoveryUsername: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectDiscoveryRequestROILoadingState(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestDiscoveryROI
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RequestEvaluationModal
);
