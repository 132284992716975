import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Form, { FormItem } from "../../Form";
import { TextArea } from "../../Input";
import { useString as s } from "../../StringProvider";
import NewButton from "../../NewButton";
import ButtonGroup from "../../ButtonGroup";

const NewCommentForm = ({ form, onClose, onPost }) => {
  const okText = s("comments.popup.add.button", "Add");
  const cancelText = s("comments.popup.cancel.button", "Cancel");
  const textValidation = s(
    "comments.popup.text.validation",
    "Please input text!"
  );
  const okTextDisabled = s(
    "comments.popup.add.disabled",
    "Please add a comment"
  );

  const textValue = Form.useWatch("text", form);

  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setTimeout(() => {
        textRef.current.focus();
      }, 1);
    }
  }, [textRef, textValue]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !e.altKey) {
      e.preventDefault();

      if (textValue && textValue.length) {
        onPost({ text: textValue });
      }
    }
  };

  return (
    <Form form={form} layout="vertical" initialValues={{}} onFinish={onPost}>
      <FormItem
        name="text"
        rules={[
          {
            required: true,
            message: textValidation,
            whitespace: true
          }
        ]}
      >
        <TextArea
          placeholder={s("comments.popup.text.placeholder", "Add a comment")}
          ref={textRef}
          autoSize={true}
          onKeyDown={handleKeyDown}
        />
      </FormItem>
      <FormItem>
        <ButtonGroup>
          <NewButton
            type={"submit"}
            disabled={!textValue}
            tooltip={!textValue ? okTextDisabled : ""}
            data-cy={"post-comment-button"}
          >
            {okText}
          </NewButton>
          <NewButton
            type={"secondary"}
            onClick={onClose}
            data-cy={"cancel-post-comment-button"}
          >
            {cancelText}
          </NewButton>
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

NewCommentForm.propTypes = {
  form: PropTypes.object.isRequired,
  onPost: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default NewCommentForm;
