import React, { useContext } from "react";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { themeProp } from "../../../theme";
import { Text } from "../../../components";
import {
  formatCurrencyString,
  formatROIPercentage
} from "../../../utils/formatting";
import HeaderMetric from "../../Report/components/HeaderMetric";
import { SettingsContext } from "../../../components/SettingsProvider";
import {
  selectDiscoveryCashflow,
  selectDiscoveryCurrency,
  selectDiscoveryName,
  selectDiscoveryROI
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";

const PrintReportCoverTwo = ({ discoveryName, currency, cashflow }) => {
  const { settings } = useContext(SettingsContext);
  const { paybackPeriod, isMaxPaybackPeriod, paybackPeriodMonths } = cashflow;
  const { locale = "en-GB" } = settings;
  const discoverySettings = { ...settings, currency };
  const header = s("report.page.header", "Opportunity for {discoveryName}", {
    discoveryName
  });
  const moreThanText = s(
    "report.header.paybackPeriod.moreThan",
    "More than {paybackPeriod, select, 1 {{paybackPeriod} Year} other {{paybackPeriod} Years}}",
    { paybackPeriod }
  );
  const lessThanText = s(
    "report.header.paybackPeriod.lessThan",
    "Approx. {paybackPeriodMonths, select, 1 {{paybackPeriodMonths} Month} other {{paybackPeriodMonths} Months}}",
    { paybackPeriod, paybackPeriodMonths }
  );

  return (
    <ReportHeaderContainer>
      <Heading level="h1">{header}</Heading>
      <Text>
        {s(
          "report.page.subheader",
          "Discover how we will help you grow and evolve"
        )}
      </Text>
      <ReportHeaderMetrics>
        <HeaderMetric label={s("report.header.metric1.label", "ROI")}>
          {formatROIPercentage({
            value: cashflow.returnOnInvestmentPercentage,
            options: { locale }
          })}
        </HeaderMetric>
        <HeaderMetric label={s("report.header.metric2.label", "Payback")}>
          {isMaxPaybackPeriod ? moreThanText : lessThanText}
        </HeaderMetric>
        <HeaderMetric label={s("report.header.metric3.label", "Investment")}>
          {formatCurrencyString({
            value: Math.round(cashflow.totalInvestment),
            settings: discoverySettings
          })}
        </HeaderMetric>
      </ReportHeaderMetrics>
    </ReportHeaderContainer>
  );
};

const ReportHeaderContainer = styled.header`
  background-color: ${themeProp("palette.background")};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  & h1 {
    text-align: center;
  }
`;

const ReportHeaderMetrics = styled.div`
  display: flex;
  margin-top: 32px;
`;

const mapStateToProps = (state) => ({
  discoveryName: selectDiscoveryName(state),
  roi: selectDiscoveryROI(state),
  currency: selectDiscoveryCurrency(state),
  cashflow: selectDiscoveryCashflow(state)
});

export default compose(connect(mapStateToProps))(PrintReportCoverTwo);
