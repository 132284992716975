import styled from "styled-components";
import React from "react";
import PoweredByCuvama from "../PoweredByCuvama";
import { themeProp } from "../../theme";

const ErrorPageLayout = ({ children }) => (
  <Container>
    <Main>{children}</Main>
    <PoweredByCuvama />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background: ${themeProp("palette.gray2")};
`;

const Main = styled.div`
  margin-top: 128px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;

  max-width: 460px;

  h3 {
    text-align: center;
    margin-bottom: 0;
  }
`;

export default ErrorPageLayout;
