import styled from "styled-components";
import { useMobileMediaQuery } from "../Responsive";

const DiscoveryTitleBar = ({ children, ...props }) => {
  const isMobile = useMobileMediaQuery();
  return (
    <TitleBar {...props} isMobile={isMobile}>
      {children}
    </TitleBar>
  );
};

const TitleBar = styled.div`
  width: 100%;
  justify-content: space-between;
  display: ${({ isMobile }) => (isMobile ? "" : "flex")};
`;

export default DiscoveryTitleBar;
