import React from "react";
import styled from "styled-components";
import { themeProp } from "../theme";
import PropTypes from "prop-types";
import { useMobileMediaQuery } from "./Responsive";

export const PageSection = styled.div`
  @media print {
    page-break-before: always;
  }
`;

const Page = ({ header, children }) => {
  const isMobile = useMobileMediaQuery();
  return (
    <PageContainer isMobile={isMobile} header={header}>
      {header}
      <PageBody className={"page-body"}>{children}</PageBody>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: ${(props) => (!props.header ? "0px" : "80px")};
  &:has([data-cy="discovery-sub-header"]) {
    padding-top: ${(props) => (props.isMobile ? "104px" : "140px")};
  }
`;

const PageBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${PageSection}:nth-child(even) {
    background: ${themeProp("palette.surface")};
  }
`;

Page.propTypes = {
  header: PropTypes.object
};

Page.defaultProps = {
  subHeader: false
};

export default Page;
