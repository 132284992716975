import httpService from "./http.service";

const getMasterData = ({ version }) =>
  httpService
    .get(`/master/${version}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getMasterDataForReportId = ({ reportId }) =>
  httpService
    .get(`/reports/${reportId}/master`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getLatestVersion = () =>
  httpService
    .get(`/master/versions/latest`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getMasterData,
  getMasterDataForReportId,
  getLatestVersion
};
export default methods;
