import PropTypes from "prop-types";
import { useEffect } from "react";
import {
  selectFileFileId,
  selectFileState,
  selectFileUploadPercentage,
  selectRequestData
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { createFile, uploadFile } from "../../../../store/actions/files";
import actionTypes from "../../../../store/actionTypes";
import { createValidation } from "../../../../store/actions/validations";
import ValidationWorkflowTypes from "../../../../utils/validation-workflow-types";
import { Text } from "../../../../components";
import Icon from "../../../../components/Icon";
import NewButton from "../../../../components/NewButton";
import * as formatting from "../../../../utils/formatting";
import Progress from "../../../../components/Progress";
import styled from "styled-components";
import { useString as s } from "../../../../components/StringProvider";
import { FileStates } from "../../../../store/reducers/file-list";
import { removeFileFromList } from "../../../../store/actions/file-list";

const FileUploadStatus = ({
  icon,
  file,
  fileId,
  createFile,
  createFileData,
  uploadFile,
  uploadPercentage,
  createValidation,
  removeFileFromList,
  state,
  validationWorkflowType
}) => {
  const controller = new AbortController();
  const cancelTooltip = s(
    "manageConfiguration.page.fileUpload.remove",
    "Remove"
  );
  const messages = {};

  messages[FileStates.READY] = { text: "" };
  messages[FileStates.UPLOADING] = {
    text: s(
      "manageConfiguration.page.fileUploadStatus.uploading",
      "Uploading..."
    )
  };
  messages[FileStates.UPLOAD_ERROR] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUploadStatus.uploadError",
      "Upload error"
    )
  };
  messages[FileStates.UPLOAD_COMPLETE] = {
    text: s(
      "manageConfiguration.page.fileUploadStatus.uploadComplete",
      "Upload complete"
    )
  };
  messages[FileStates.VALIDATING] = {
    text: s(
      "manageConfiguration.page.fileUploadStatus.validating",
      "Validating..."
    )
  };
  messages[FileStates.VALIDATION_ERROR] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUploadStatus.validationError",
      "Validation error"
    )
  };
  messages[FileStates.VALIDATION_COMPLETE] = {
    text: s(
      "manageConfiguration.page.fileUploadStatus.validationComplete",
      "Validation complete"
    )
  };
  messages[FileStates.VALIDATION_COMPLETE_WITH_ERRORS] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUploadStatus.validationCompleteWithErrors",
      "Error detected"
    )
  };
  messages[FileStates.CANCELLING] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUploadStatus.cancelling",
      "Cancelling..."
    )
  };

  useEffect(() => {
    if (state === FileStates.READY) {
      createFile({
        name: file.name,
        size: file.size,
        type: file.type,
        temporary: true,
        description: file.uid
      });
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      uploadFile({
        url: createFileData.url,
        file,
        controller
      });
    }
  }, [fileId]);

  useEffect(() => {
    switch (state) {
      case FileStates.UPLOAD_COMPLETE:
        if (validationWorkflowType) {
          createValidation({
            fileId: fileId,
            workflowType: validationWorkflowType
          });
        }

        break;
    }
  }, [state]);

  const onCancel = () => {
    controller.abort();

    if (state === FileStates.UPLOADING) {
      controller.abort();
    }

    removeFileFromList({ file });
  };

  switch (state) {
    case FileStates.VALIDATION_COMPLETE:
      return (
        <Container>
          <Row>
            <Icon
              name={icon}
              size={"large"}
              colour={"gray3"}
              className={"icon"}
            />
            <Text variant={"smallBody"} className={"name"}>
              {file.name}
            </Text>
            <Text
              className={"percent"}
              variant={"smallBody"}
              color={messages[state]?.color || "gray4"}
            >
              {messages[state]?.text}
            </Text>
            <Icon name={"check"} colour={"success"} />
          </Row>
        </Container>
      );

    case FileStates.VALIDATION_COMPLETE_WITH_ERRORS:
      return (
        <Container>
          <Row>
            <Icon
              name={"alert"}
              size={"large"}
              colour={"error"}
              className={"icon"}
            />
            <Text className={"name"} variant={"smallBody"}>
              {file.name}
            </Text>
            <Text
              className={"percent"}
              variant={"smallBody"}
              color={messages[state]?.color}
            >
              {messages[state]?.text}
            </Text>
            <NewButton
              type={"iconSecondary"}
              onClick={onCancel}
              tooltip={cancelTooltip}
              className={"cancel"}
            >
              <Icon name={"trash"} size={18} />
            </NewButton>
          </Row>
        </Container>
      );

    default:
      return (
        <Container>
          <Row>
            <Icon
              name={icon}
              size={"large"}
              colour={"gray3"}
              className={"icon"}
            />
            <Text className={"name"} variant={"smallBody"}>
              {file.name}
            </Text>
            <Text className={"percent"} variant={"smallBody"} color={"gray4"}>
              {formatting.formatPercentage({ value: uploadPercentage })}
            </Text>
            <NewButton
              type={"iconSecondary"}
              onClick={onCancel}
              tooltip={cancelTooltip}
            >
              <Icon name={"trash"} size={18} />
            </NewButton>
          </Row>

          <Row>
            <Progress percent={uploadPercentage} color={"primary"} />
          </Row>
          <Row>
            <Text
              color={messages[state]?.color || "gray4"}
              variant={"smallBody"}
            >
              {messages[state]?.text}
            </Text>
          </Row>
        </Container>
      );
  }
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  & .icon {
    height: 20px;
    width: 20px;
  }

  & .name {
    flex: 1;
  }

  & .percent {
    text-align: right;
  }

  & .ant-progress-text {
    display: none;
  }

  & .cancel {
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

FileUploadStatus.propTypes = {
  file: PropTypes.object.isRequired,
  validationWorkflowType: PropTypes.oneOf(ValidationWorkflowTypes.ALL),
  icon: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  createFileData: selectRequestData(state, actionTypes.CREATE_FILE_REQUEST),
  state: selectFileState(state, props.file.uid),
  fileId: selectFileFileId(state, props.file.uid),
  uploadPercentage: selectFileUploadPercentage(state, props.file.uid)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFile,
      uploadFile,
      createValidation,
      removeFileFromList
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FileUploadStatus
);
