import DefinitionTypes from "../../../../utils/definition-types";
import KPITypes from "../../../../utils/kpi-types";
import PropType from "prop-types";
import NewKPIImpactValue from "./NewKPIImpactValue";
import ImpactValue from "./ImpactValue";

const DiscoveryKPIImpactValue = ({ discoveryKPI, reportId, currency }) => {
  return discoveryKPI.definition.status === DefinitionTypes.NEW ||
    discoveryKPI.definition.type === KPITypes.UNQUANTIFIED ? (
    <NewKPIImpactValue
      discoveryKPI={discoveryKPI}
      currency={currency}
      reportId={reportId}
    />
  ) : !discoveryKPI.hasImpactValue ||
    discoveryKPI.definition.noFormulaReason ? (
    <NewKPIImpactValue
      discoveryKPI={discoveryKPI}
      currency={currency}
      reportId={reportId}
    />
  ) : (
    <ImpactValue
      lowImpactValue={discoveryKPI.lowImpactValue}
      potentialImpactValue={discoveryKPI.potentialImpactValue}
      currency={currency}
      discoveryKPI={discoveryKPI}
      reportId={reportId}
    />
  );
};

DiscoveryKPIImpactValue.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  reportId: PropType.string
};

export default DiscoveryKPIImpactValue;
