import ShowCardOptions from "../../utils/show-card-options";
import ViewChallengesBy from "../../utils/view-challenges-by";
import { sortDiscoveryChallengesByOrder } from "../../utils/sorting";

const getGroupSortFunction = ({ categoryMap }) => {
  return (a, b) => {
    const groupNameA = categoryMap && categoryMap[a] ? categoryMap[a].name : "";
    const groupNameB = categoryMap && categoryMap[b] ? categoryMap[b].name : "";

    const groupA = groupNameA.match(/^Other.*$/i) ? "ZZZ" : groupNameA;
    const groupB = groupNameB.match(/^Other.*$/i) ? "ZZZ" : groupNameB;
    return groupA.localeCompare(groupB);
  };
};

export const selectDiscoveryChallengeGroups = (
  discoveryChallenges,
  showChallenges,
  categoryMap
) => {
  if (!discoveryChallenges) {
    return [];
  }

  const groupIds = discoveryChallenges
    .filter(
      (discoveryChallenge) =>
        discoveryChallenge.selected ||
        showChallenges !== ShowCardOptions.SELECTED_CARDS
    )
    .map((discoveryChallenges) => discoveryChallenges.definition.categoryCode)
    .reduce(
      (list, groupId) =>
        list.find((g) => g === groupId) ? list : [...list, groupId],
      []
    );

  groupIds.sort(getGroupSortFunction({ categoryMap }));
  return groupIds.join(",");
};

export const selectDiscoveryChallengeGroupMembers = (
  discoveryChallenges,
  groupId,
  showChallenges
) => {
  if (!discoveryChallenges) {
    return [];
  }

  const result = discoveryChallenges
    .filter(
      (discoveryChallenge) =>
        discoveryChallenge.selected ||
        showChallenges !== ShowCardOptions.SELECTED_CARDS
    )
    .filter(
      (discoveryChallenges) =>
        groupId === discoveryChallenges.definition.categoryCode
    );

  result.sort(sortDiscoveryChallengesByOrder);
  return result
    .map((discoveryChallenge) => discoveryChallenge.challengeCode)
    .join(",");
};

export const calculateNewChallengeOrder = (
  discoveryChallenge,
  nextChallenge,
  discoveryChallenges,
  viewBy
) => {
  if (viewBy === ViewChallengesBy.IMPORTANCE) {
    const maxOrder = discoveryChallenges.reduce((max, discoveryChallenge) => {
      return !max || discoveryChallenge.definition.order > max
        ? discoveryChallenge.definition.order
        : max;
    }, 0);

    return maxOrder + 10;
  }

  if (nextChallenge) {
    const beforeOrder = discoveryChallenge.definition.order;
    const afterOrder = nextChallenge.definition.order;

    return (beforeOrder + afterOrder) / 2;
  } else {
    return discoveryChallenge.definition.order + 1;
  }
};
