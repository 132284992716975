import { useString as s } from "../../StringProvider";
import React, { useEffect, useRef } from "react";
import Form, { FormItem } from "../../Form";
import { TextArea } from "../../Input";
import PropType from "prop-types";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateComment,
  updateReportComment
} from "../../../store/actions/comments";
import styled from "styled-components";
import NewButton from "../../NewButton";
import ButtonGroup from "../../ButtonGroup";

const EditCommentForm = ({
  reportId,
  comment,
  onClose,
  updateComment,
  updateReportComment
}) => {
  const okText = s("comments.popup.editComment.save.button", "Save");
  const cancelText = s(
    "comments.popup.editComment.cancel.button",
    "Don't Edit"
  );
  const [form] = Form.useForm();
  const textRef = useRef();

  useEffect(() => {
    if (textRef.current) {
      setTimeout(() => {
        textRef.current.focus({
          cursor: "end"
        });
      }, 1);
    }
  }, [textRef]);

  useEffect(() => {
    form.setFieldsValue({ text: comment.text });
  }, [comment]);

  const onPost = ({ text }) => {
    if (reportId) {
      updateReportComment({ reportId, commentId: comment._id, text });
    } else {
      updateComment({ commentId: comment._id, text });
    }
  };

  return (
    <Container>
      <Form form={form} layout="vertical" initialValues={{}} onFinish={onPost}>
        <FormItem
          name="text"
          rules={[
            {
              required: true,
              message: "Please input text!",
              whitespace: true
            }
          ]}
        >
          <TextArea
            placeholder={s(
              "comments.popup.editComment.text.placeholder",
              "Type comment"
            )}
            ref={textRef}
            autoSize={true}
          />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <NewButton type={"submit"} data-cy={"edit-comment-button"}>
              {okText}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onClose}
              data-cy={"cancel-edit-comment-button"}
            >
              {cancelText}
            </NewButton>
          </ButtonGroup>
        </FormItem>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 24px;
`;

EditCommentForm.propTypes = {
  comment: PropType.object.isRequired,
  reportId: PropType.string,
  onClose: PropType.func.isRequired
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateComment,
      updateReportComment
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditCommentForm);
