import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";
import actionTypes from "../actionTypes";

const INITIAL_STATE = {
  commentCountError: false,
  commentCountLoadingState: LoadingState.UNINITIALIZED,
  commentCount: {},
  topic: null,
  valueNoteError: false,
  valueNoteLoadingState: LoadingState.UNINITIALIZED,
  valueNoteCommentId: null,
  commentsError: false,
  commentsLoadingState: LoadingState.UNINITIALIZED,
  commentIds: [],
  postCommentLoadingState: LoadingState.UNINITIALIZED,
  postCommentError: false,
  updateCommentLoadingState: LoadingState.UNINITIALIZED,
  updateCommentError: false,
  deleteCommentLoadingState: LoadingState.UNINITIALIZED,
  deleteCommentError: false,
  draftComments: {},
  editCommentId: null,
  commentsMap: {},
  deletingComment: false
};

const addCommentToCommentsMap = (commentsMap, comment) => {
  const newMap = { ...commentsMap };

  newMap[comment._id] = comment;
  return newMap;
};

const appendComment = (commentIds, comment) => {
  return [...commentIds, comment._id];
};

const deleteComment = (commentIds, commentId) => {
  return commentIds.filter((id) => id !== commentId);
};

const incrementCommentCount = (commentCount, topic) => {
  const result = { ...commentCount };

  if (result[topic]) {
    result[topic]++;
  } else {
    result[topic] = 1;
  }

  return result;
};

const decrementCommentCount = (commentCount, topic) => {
  const result = { ...commentCount };

  if (result[topic]) {
    result[topic]--;
  }

  return result;
};

const addDraft = (draftComments, topic, text) => {
  const comments = {
    ...draftComments
  };

  comments[topic] = text;
  return comments;
};

const removeDraft = (draftComments, topic) => {
  delete draftComments[topic];
  return draftComments;
};

const updateTopicCommentCount = ({ topic, count, commentCount }) => {
  const newCommentCount = {
    ...commentCount
  };

  newCommentCount[topic] = count;
  return newCommentCount;
};

const commentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_COMMENT_COUNT_REQUEST:
      return {
        ...state,
        commentCountLoadingState: LoadingState.IN_PROGRESS
      };

    case types.GET_COMMENT_COUNT_FAILURE:
      return {
        ...state,
        commentCountLoadingState: LoadingState.FAILED,
        commentCountError: action.payload.error
      };

    case types.GET_COMMENT_COUNT_SUCCESS:
      return {
        ...state,
        commentCountLoadingState: LoadingState.COMPLETED,
        commentCount: action.payload.topics
      };

    case types.START_EDITING_KPI:
      return {
        ...state,
        valueNoteLoadingState: LoadingState.UNINITIALIZED
      };

    case types.GET_VALUE_NOTE_REQUEST:
      return {
        ...state,
        valueNoteLoadingState: LoadingState.IN_PROGRESS,
        valueNoteError: null,
        valueNoteCommentId: null
      };

    case types.GET_VALUE_NOTE_FAILURE:
      return {
        ...state,
        valueNoteLoadingState: LoadingState.FAILED,
        valueNoteError: action.payload.error
      };

    case types.GET_VALUE_NOTE_SUCCESS:
      return {
        ...state,
        valueNoteLoadingState: LoadingState.COMPLETED,
        valueNoteCommentId: action.payload.comment
          ? action.payload.comment._id
          : null,
        commentsMap: action.payload.comment
          ? addCommentToCommentsMap(state.commentsMap, action.payload.comment)
          : state.commentsMap
      };

    case types.GET_COMMENTS_REQUEST:
      return {
        ...state,
        commentsLoadingState: LoadingState.IN_PROGRESS
      };

    case types.GET_COMMENTS_FAILURE:
      return {
        ...state,
        commentsLoadingState: LoadingState.FAILED,
        commentsError: action.payload.error
      };

    case types.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsLoadingState: LoadingState.COMPLETED,
        commentIds: action.payload.comments.map((comment) => comment._id),
        commentsMap: action.payload.comments.reduce((map, comment) => {
          map[comment._id] = comment;
          return map;
        }, {}),
        topic: action.payload.topic,
        commentCount: updateTopicCommentCount({
          topic: action.payload.topic,
          count: action.payload.total,
          commentCount: state.commentCount
        })
      };

    case actionTypes.CLOSE_COMMENTS:
      return {
        ...state,
        commentsLoadingState: LoadingState.UNINITIALIZED,
        updateCommentLoadingState: LoadingState.UNINITIALIZED,
        deleteCommentError: LoadingState.UNINITIALIZED,
        draftComments: addDraft(
          state.draftComments,
          action.payload.topic,
          action.payload.text
        )
      };

    case types.POST_COMMENT_REQUEST:
      return {
        ...state,
        postCommentLoadingState: LoadingState.IN_PROGRESS,
        postCommentError: null
      };

    case types.POST_COMMENT_FAILURE:
      return {
        ...state,
        postCommentLoadingState: LoadingState.FAILED,
        postCommentError: action.payload.error
      };

    case types.POST_COMMENT_SUCCESS:
      return {
        ...state,
        postCommentLoadingState: LoadingState.COMPLETED,
        commentIds: appendComment(state.commentIds, action.payload.comment),
        commentCount: incrementCommentCount(
          state.commentCount,
          action.payload.topic
        ),
        draftComments: removeDraft(state.draftComments, action.payload.topic),
        commentsMap: addCommentToCommentsMap(
          state.commentsMap,
          action.payload.comment
        )
      };

    case types.UPDATE_COMMENT_REQUEST:
      return {
        ...state,
        updateCommentLoadingState: LoadingState.IN_PROGRESS,
        updateCommentError: null
      };

    case types.UPDATE_COMMENT_FAILURE:
      return {
        ...state,
        updateCommentLoadingState: LoadingState.FAILED,
        updateCommentError: action.payload.error
      };

    case types.UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        updateCommentLoadingState: LoadingState.COMPLETED,
        editCommentId: null,
        commentsMap: addCommentToCommentsMap(
          state.commentsMap,
          action.payload.comment
        )
      };

    case types.DELETE_COMMENT_REQUEST:
      return {
        ...state,
        deleteCommentLoadingState: LoadingState.IN_PROGRESS,
        deleteCommentError: null
      };

    case types.DELETE_COMMENT_FAILURE:
      return {
        ...state,
        deleteCommentLoadingState: LoadingState.FAILED,
        deleteCommentError: action.payload.error
      };

    case types.DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        deleteCommentLoadingState: LoadingState.COMPLETED,
        commentIds: deleteComment(state.commentIds, action.payload.commentId),
        commentCount: decrementCommentCount(
          state.commentCount,
          action.payload.topic
        )
      };

    case types.START_EDITING_COMMENT:
      return {
        ...state,
        editCommentId: action.payload.commentId
      };

    case types.STOP_EDITING_COMMENT:
      return {
        ...state,
        editCommentId: null
      };

    case types.START_DISCOVERY_OPERATION:
      return {
        ...state,
        deleteCommentLoadingState: LoadingState.UNINITIALIZED
      };

    case types.SET_DELETING_COMMENT:
      return {
        ...state,
        deletingComment: action.payload.deletingComment
      };

    default:
      return state;
  }
};

export default commentsReducer;

export const selectCommentCountLoadingState = (commentsData) =>
  commentsData.commentCountLoadingState;
export const selectCommentCountError = (commentsData) =>
  commentsData.commentCountError;
export const selectCommentCountForTopic = (commentsData, topic) =>
  commentsData.commentCount ? commentsData.commentCount[topic] : 0;
export const selectCommentTopic = (commentsData) => commentsData.topic;
export const selectCommentIds = (commentsData) => commentsData.commentIds;
export const selectCommentsLoadingState = (commentsData) =>
  commentsData.commentsLoadingState;
export const selectPostCommentLoadingState = (commentsData) =>
  commentsData.postCommentLoadingState;
export const selectDraftCommentForTopic = (commentsData, topic) =>
  commentsData.draftComments ? commentsData.draftComments[topic] : null;
export const selectDeleteCommentLoadingState = (commentsData) =>
  commentsData.deleteCommentLoadingState;
export const isEditingCommentId = (commentsData, commentId) =>
  commentsData.editCommentId === commentId;
export const isEditingAnyComment = (commentsData) =>
  commentsData.editCommentId !== null;
export const selectComment = (commentsData, commentId) =>
  commentsData.commentsMap[commentId];
export const selectValueNote = (commentsData) =>
  commentsData.valueNoteCommentId
    ? commentsData.commentsMap[commentsData.valueNoteCommentId]
    : null;
export const selectValueNoteLoadingState = (commentsData) =>
  commentsData.valueNoteLoadingState;

export const isDeletingComment = (commentsData) => commentsData.deletingComment;
