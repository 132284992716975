import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { PageSection } from "../../../components/Page";
import { ROISummary } from "../../ROI/components/ROISummary";
import MobileReportSection from "./MobileReportSection";
import MobileReportTitle from "./MobileReportTitle";
import { useMobileMediaQuery } from "../../../components/Responsive";
import {
  selectDiscovery,
  selectDiscoveryKPIs,
  selectDiscoveryScenario
} from "../../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const ReportROI = ({ discovery, discoveryKPIs, scenario, reportId }) => {
  const isMobile = useMobileMediaQuery();
  const reportMode = !!reportId;
  const header = s(
    "report.roi.header",
    "Return on Investment for {discoveryName}",
    {
      discoveryName: discovery.name
    }
  );
  // Dan 20220811: Some strange redux behaviour: I am passing the 'scenario' value to ROISummary because if I don't and I let
  // ROISummary connect to redux to fetch the 'scenario' value it comes back as undefined BUT
  // only when viewing the Report.js page! It fetches the expected 'scenario' value for Discovery.js
  return isMobile ? (
    <MobileReportSection id={"report-roi"}>
      <MobileReportTitle header={header} style={{ marginBottom: "24px" }} />
      <ROISummary
        discovery={discovery}
        reportMode={reportMode}
        discoveryKPIs={discoveryKPIs}
        scenario={scenario}
      />
    </MobileReportSection>
  ) : (
    <ReportROIContainer id={"report-roi"}>
      <ReportROITitle level="h1" align="center">
        {header}
      </ReportROITitle>
      <ROISummary
        discovery={discovery}
        reportMode={reportMode}
        discoveryKPIs={discoveryKPIs}
        scenario={scenario}
      />
    </ReportROIContainer>
  );
};
ReportROI.propTypes = {
  discovery: PropTypes.shape({
    name: PropTypes.string
  }).isRequired
};

const ReportROIContainer = styled(PageSection)`
  padding: 120px 24px;
  @media print {
    padding: 0;
  }
`;
const ReportROITitle = styled(Heading)`
  margin-bottom: 16px;
`;

const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  discoveryKPIs: selectDiscoveryKPIs(state),
  scenario: selectDiscoveryScenario(state)
});

export default compose(withRouter, connect(mapStateToProps))(ReportROI);
