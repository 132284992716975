import {
  selectAllFiles,
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { message } from "antd";
import { useString as s } from "../../../../components/StringProvider";
import React, { useEffect, useState } from "react";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import JobMonitor from "../../../../components/JobMonitor";
import UpdateConfiguration from "../UpdateConfiguration";
import { clearFileList } from "../../../../store/actions/file-list";
import { Modal } from "../../../../components";

const UpdateMasterDataModal = ({
  onClose,
  files,
  clearFileList,
  deployMasterDataLoadingState,
  deployMasterDataRequestData
}) => {
  const [ready, setReady] = useState(false);
  const [jobId, setJobId] = useState("");
  const titleText = s("update_master_data.popup.header", "Upload Master Data");
  const deploymentSuccess = s(
    "update_master_data.popup.messages.deploymentSuccess",
    "The Master Data files were updated"
  );
  const deploymentFailure = s(
    "update_master_data.popup.messages.deploymentFailure",
    "Failed to update the Master Data"
  );

  useEffect(() => {
    clearFileList();
    setJobId("");
  }, []);

  useEffect(() => {
    if (files && files.length === 0) {
      setReady(true);
    }
  }, [files]);

  useLoadingState(
    deployMasterDataLoadingState,
    () => {
      setJobId(deployMasterDataRequestData._id);
    },
    () => {}
  );

  const onJobFinish = (successful) => {
    if (successful) {
      message.success(deploymentSuccess);
      onClose();
    } else {
      message.error(deploymentFailure);
      setJobId("");
    }
  };

  return (
    ready && (
      <Modal
        title={titleText}
        open={true}
        onCancel={onClose}
        style={{ minWidth: 860, maxWidth: 860, maxHeight: 860, minHeight: 860 }}
      >
        <>
          <UpdateConfiguration />
          {jobId && <JobMonitor jobId={jobId} onFinish={onJobFinish} />}
        </>
      </Modal>
    )
  );
};

const mapStateToProps = (state) => ({
  files: selectAllFiles(state),
  deployMasterDataLoadingState: selectRequestState(
    state,
    actionTypes.DEPLOY_VALIDATION_REQUEST
  ),
  deployMasterDataRequestData: selectRequestData(
    state,
    actionTypes.DEPLOY_VALIDATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearFileList
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UpdateMasterDataModal
);
