import httpService from "./http.service";

const createFile = ({ name, type, size, description, temporary }) =>
  httpService
    .post(`/files/`, { name, type, size, description, temporary })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const downloadFile = ({ fileId }) =>
  httpService
    .post(`/files/${fileId}/download`, { fileId })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const transformAndDownloadFile = ({ fileId, transformationType }) =>
  httpService
    .post(`/files/${fileId}/transformAndDownload`, { transformationType })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  createFile,
  downloadFile,
  transformAndDownloadFile
};

export default methods;
