import {
  selectDiscoveryChallenges,
  selectDiscoverySetting,
  selectIsCreatingChallenge,
  selectScrollToChallengeCode
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Space } from "antd";
import React, { useEffect } from "react";
import ChallengeCardAndModals from "../../components/Discovery/ChallengeCard/ChallengeCardAndModals";
import ShowCardOptions from "../../utils/show-card-options";
import { CreateChallengeModal } from "../../components/Discovery/ChallengeModal";
import { clearScrollToChallengeCode } from "../../store/actions/challenge-library";
import { scrollToSection } from "../Report/components/Header/ScrollUtils";
import { sortDiscoveryChallengesByOrder } from "../../utils/sorting";
import { isTypicalChallenge } from "../../utils/filters/challenge-filters";

const ViewTypicalChallenges = ({
  discoveryChallenges,
  showChallenges,
  isCreatingChallenge,
  scrollToChallengeCode,
  clearScrollToChallengeCode
}) => {
  useEffect(() => {
    if (scrollToChallengeCode) {
      scrollToSection({ sectionId: `challenge-card-${scrollToChallengeCode}` });
      clearScrollToChallengeCode();
    }
  }, [scrollToChallengeCode]);

  const sortedDiscoveryChallenges = [...discoveryChallenges]
    .filter(
      (discoveryChallenge) =>
        discoveryChallenge.selected ||
        showChallenges === ShowCardOptions.ALL_CARDS
    )
    .filter(isTypicalChallenge);

  sortedDiscoveryChallenges.sort(sortDiscoveryChallengesByOrder);

  return (
    <Space direction={"vertical"} size={12}>
      {isCreatingChallenge && <CreateChallengeModal />}
      {sortedDiscoveryChallenges.map((discoveryChallenge, index) => (
        <ChallengeCardAndModals
          key={discoveryChallenge.challengeCode}
          nextChallengeCode={
            index < sortedDiscoveryChallenges.length - 1
              ? sortedDiscoveryChallenges[index + 1].challengeCode
              : undefined
          }
          challengeCode={discoveryChallenge.challengeCode}
          editable={true}
        />
      ))}
    </Space>
  );
};

const mapStateToProps = (state) => ({
  discoveryChallenges: selectDiscoveryChallenges(state),
  showChallenges: selectDiscoverySetting(
    state,
    "showChallenges",
    ShowCardOptions.ALL_CARDS
  ),
  isCreatingChallenge: selectIsCreatingChallenge(state),
  scrollToChallengeCode: selectScrollToChallengeCode(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearScrollToChallengeCode
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ViewTypicalChallenges
);
