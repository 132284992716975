import PropTypes from "prop-types";
import styled from "styled-components";
import Heading from "../../Heading";
import ImpactForYou from "./ImpactForYou";
import PropType from "prop-types";
import KPITypes from "../../../utils/kpi-types";
import ImpactTBD from "./ImpactTBD";
import { useMobileMediaQuery } from "../../Responsive";
import KPISlider from "./KPISlider";

const RangeDrivenKPIData = ({
  kpi,
  currency,
  isEditable,
  disabled,
  onChange
}) => {
  const isMobile = useMobileMediaQuery();

  return (
    <div>
      <div>
        <Heading level={isMobile ? "h5Small" : "h5"} color={"gray3"}>
          {kpi.definition.rangeMetric}
        </Heading>
      </div>
      <div>
        <KPISlider
          kpi={kpi}
          currency={currency}
          disabled={!isEditable}
          onChange={onChange}
        />
      </div>
      <MarginContainer className={isMobile ? "mobile" : undefined}>
        {kpi.definition.type !== KPITypes.PERCENT_AND_METRIC_DRIVEN ? (
          <ImpactForYou kpi={kpi} disabled={disabled} />
        ) : (
          <ImpactTBD kpi={kpi} disabled={disabled} />
        )}
      </MarginContainer>
    </div>
  );
};

RangeDrivenKPIData.propTypes = {
  currency: PropTypes.string,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
  kpi: PropTypes.object.isRequired,
  disabled: PropType.bool
};

RangeDrivenKPIData.defaultProps = {
  currency: "GBP",
  isEditable: false,
  onChange: () => {},
  kpi: {}
};

const MarginContainer = styled.div`
  &.mobile {
    padding-top: 12px;
  }
`;

export default RangeDrivenKPIData;
