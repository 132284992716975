import React, { useEffect, useState } from "react";
import NotesStartPage from "./NotesStartPage";
import NotesEditor from "./NotesEditor";
import { useParams } from "react-router-dom";
import { selectRequestData, selectRequestState } from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { getDiscoveryNote } from "../../store/actions/notes";
import { connect } from "react-redux";
import useLoadingState from "../../utils/use-loading-state";
import { useString as s } from "../StringProvider";
import Helmet from "react-helmet";
import { InternalServerError } from "../../Pages/ErrorPage";
import { getDiscoverySummary } from "../../store/actions/discoveries";
import { getNotesTemplate } from "../../store/actions/templates";

const NotesPanel = ({
  closeDrawer,
  getDiscoveryNote,
  discoveryNote,
  loadingState,
  getDiscoverySummary,
  discoverySummary,
  getNotesTemplate,
  notesTemplateLoadingState
}) => {
  const params = useParams();
  const discoveryId = params.discoveryId;
  const [showStartPage, setShowStartPage] = useState(true);
  const [ready, setReady] = useState(false);
  const [templateReady, setTemplateReady] = useState(false);
  const [error, setError] = useState(false);
  const notesTitle = s(
    "page.notes.title",
    "Notes: {accountName} opportunity, {version}",
    discoverySummary || {}
  );

  useEffect(() => {
    getDiscoverySummary({ discoveryId });
  }, [discoveryId]);

  useEffect(() => {
    getDiscoveryNote({ discoveryId });
  }, [discoveryId]);

  useEffect(() => {
    getNotesTemplate();
  }, [discoveryId]);

  useLoadingState(
    loadingState,
    () => {
      setReady(true);
      setShowStartPage(!discoveryNote);
    },
    () => {
      setError(true);
    }
  );

  useLoadingState(
    notesTemplateLoadingState,
    () => {
      setTemplateReady(true);
    },
    () => {
      setError(true);
    }
  );

  const onClick = () => {
    setShowStartPage(false);
  };

  if (error) {
    return <InternalServerError />;
  }

  if (!ready || !templateReady) {
    return null;
  }

  if (ready && showStartPage) {
    return (
      <>
        <Helmet>
          <title>{notesTitle}</title>
        </Helmet>
        <NotesStartPage onClick={onClick} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{notesTitle}</title>
      </Helmet>
      <NotesEditor closeDrawer={closeDrawer} />
    </>
  );
};

const mapStateToProps = (state) => ({
  discoveryNote: selectRequestData(state, actionTypes.GET_NOTE_REQUEST),
  loadingState: selectRequestState(state, actionTypes.GET_NOTE_REQUEST),
  notesTemplateLoadingState: selectRequestState(
    state,
    actionTypes.GET_NOTES_TEMPLATE_REQUEST
  ),
  discoverySummary: selectRequestData(
    state,
    actionTypes.DISCOVERY_SUMMARY_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDiscoveryNote,
      getDiscoverySummary,
      getNotesTemplate
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotesPanel
);
