import React from "react";
import styled from "styled-components";
import { themeProp } from "../../theme";

const WiggleLoader = () => {
  return (
    <Loader>
      {Array.from({ length: 5 }, (_, index) => (
        <div key={index} className="box"></div>
      ))}
    </Loader>
  );
};

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  gap: 2px;

  .box {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    animation: wiggleAnimation 1.5s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
  }

  .box:nth-child(1) {
    background-color: ${themeProp("palette.accent5")};
    animation-delay: 0s;
  }

  .box:nth-child(2) {
    background-color: ${themeProp("palette.accent6")};
    animation-delay: 0.2s;
  }

  .box:nth-child(3) {
    background-color: ${themeProp("palette.accent4")};
    animation-delay: 0.4s;
  }

  .box:nth-child(4) {
    background-color: ${themeProp("palette.primary")};
    animation-delay: 0.6s;
  }

  .box:nth-child(5) {
    background-color: ${themeProp("palette.secondary")};
    animation-delay: 0.8s;
  }

  @keyframes wiggleAnimation {
    0%,
    30%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-6px);
    }
  }
`;

export default WiggleLoader;
