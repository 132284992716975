const openDownload = (url) => {
  if (window.Cypress) {
    console.log("Skipped openDownload in Cypress()");
    return;
  }

  const element = document.createElement("a");
  element.setAttribute("href", url);
  document.body.appendChild(element);
  element.click();
};

export default openDownload;
