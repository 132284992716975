const KPITypes = {
  PERCENT_DRIVEN: "percentDriven",
  NEW_VALUE_DRIVEN: "newValueDriven",
  FIXED_PERCENT: "fixedPercent",
  METRIC_DRIVEN: "metricDriven",
  PERCENT_AND_METRIC_DRIVEN: "percentAndMetricDriven",
  UNQUANTIFIED: "unquantified"
};

KPITypes.ALL = Object.values(KPITypes);

export default KPITypes;
