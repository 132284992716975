import { Typography } from "antd";
import { useString as s } from "../StringProvider";
const { Text } = Typography;

export const translateCognitoError = (message) => {
  const map = {};

  map["PreSignUp failed with error INVALID_DOMAIN."] = s(
    "error.INVALID_DOMAIN",
    "Sorry, you cannot sign up from this domain."
  );

  map["error.resetPassword.invalidCode"] = s(
    "error.resetPassword.invalidCode",
    "Invalid verification code provided, please try again."
  );

  map["error.resetPassword.expiredCode"] = s(
    "error.resetPassword.expiredCode",
    "Expired verification code provided."
  );

  map["error.resetPassword.tooManyFailedAttempts"] = s(
    "error.resetPassword.tooManyFailedAttempts",
    "Too many failed attempts. Please try again later."
  );

  map["error.resetPassword.tooManyRequests"] = s(
    "error.resetPassword.tooManyRequests",
    "Too many failed attempts. Please try again later."
  );

  map["error.resetPassword.userNotConfirmed"] = s(
    "error.resetPassword.userNotConfirmed",
    "User not confirmed."
  );

  return map[message] || message;
};

const CognitoError = ({ error, ...props }) =>
  error ? (
    <div className={"error-wrapper"} data-cy={"cognito-error"}>
      <Text type="danger" {...props}>
        {translateCognitoError(error)}
      </Text>
    </div>
  ) : null;

export default CognitoError;
