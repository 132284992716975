import types from "../actionTypes";
import configService from "../../services/config.service";

export const getLatestCRMAccountsLog = () => (dispatch) => {
  dispatch({
    type: types.GET_LATEST_CRM_ACCOUNTS_LOG_REQUEST
  });

  return configService
    .getLatestCRMAccountsLog()
    .then((payload) => {
      dispatch({
        type: types.GET_LATEST_CRM_ACCOUNTS_LOG_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_LATEST_CRM_ACCOUNTS_LOG_FAILURE,
        error
      });
    });
};

export const getSalesforceConfiguration = () => (dispatch) => {
  dispatch({
    type: types.GET_SALESFORCE_CONFIGURATION_REQUEST
  });

  return configService
    .getSalesforceConfiguration()
    .then((payload) => {
      dispatch({
        type: types.GET_SALESFORCE_CONFIGURATION_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_SALESFORCE_CONFIGURATION_FAILURE,
        error
      });
    });
};

export const updateSalesforceConfiguration = (changes) => (dispatch) => {
  dispatch({
    type: types.UPDATE_SALESFORCE_CONFIGURATION_REQUEST
  });

  return configService
    .updateSalesforceConfiguration(changes)
    .then((payload) => {
      dispatch({
        type: types.UPDATE_SALESFORCE_CONFIGURATION_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_SALESFORCE_CONFIGURATION_FAILURE,
        error
      });
    });
};

export const deleteSalesforceConfiguration = () => (dispatch) => {
  dispatch({
    type: types.DELETE_SALESFORCE_CONFIGURATION_REQUEST
  });

  return configService
    .deleteSalesforceConfiguration()
    .then(() => {
      dispatch({
        type: types.DELETE_SALESFORCE_CONFIGURATION_SUCCESS,
        payload: null
      });
    })
    .catch((error) => {
      dispatch({
        type: types.DELETE_SALESFORCE_CONFIGURATION_FAILURE,
        error
      });
    });
};

export const getAdminSettings = () => (dispatch) => {
  dispatch({
    type: types.GET_ADMIN_SETTINGS_REQUEST
  });

  return configService
    .getAdminSettings()
    .then((payload) => {
      dispatch({
        type: types.GET_ADMIN_SETTINGS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_ADMIN_SETTINGS_FAILURE,
        error
      });
    });
};

export const updateAdminSettings = (changes) => (dispatch) => {
  dispatch({
    type: types.UPDATE_ADMIN_SETTINGS_REQUEST
  });

  return configService
    .updateAdminSettings(changes)
    .then((payload) => {
      dispatch({
        type: types.UPDATE_ADMIN_SETTINGS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_ADMIN_SETTINGS_FAILURE,
        error
      });
    });
};

export const getSalesforceInstallation = () => (dispatch) => {
  dispatch({
    type: types.GET_SALESFORCE_INSTALLATION_REQUEST
  });

  return configService
    .getSalesforceInstallation()
    .then((payload) => {
      dispatch({
        type: types.GET_SALESFORCE_INSTALLATION_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_SALESFORCE_INSTALLATION_FAILURE,
        error
      });
    });
};

export const startSalesforceInstallation = () => (dispatch) => {
  dispatch({
    type: types.START_SALESFORCE_INSTALLATION_REQUEST
  });

  return configService
    .startSalesforceInstallation()
    .then((payload) => {
      dispatch({
        type: types.START_SALESFORCE_INSTALLATION_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.START_SALESFORCE_INSTALLATION_FAILURE,
        error
      });
    });
};
