import Select from "./Select";
import { customList } from "country-codes-list";
import countries from "i18n-iso-countries";
import { useSetting } from "./SettingsProvider";
import _ from "lodash";
import styled from "styled-components";
import { Paragraph } from "./Typography";
import PropTypes from "prop-types";
import { Divider } from "antd";
import "country-flag-icons/3x2/flags.css";
import * as flagCountries from "country-flag-icons";
import { useState, useRef } from "react";

const OptionRenderer = (option) => {
  if (!option) {
    return null;
  }

  return option?.data?.disabled ? (
    <Container>
      <Divider />
    </Container>
  ) : (
    <Container>
      <div className={`flag:${option.value}`} />
      <Paragraph variant={"body"}>{option.label}</Paragraph>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;

  & [class^="flag:"] {
    border-radius: 3px;
  }

  & .ant-divider {
    margin: 0;
  }
`;

const SelectCountry = ({
  countryCodes,
  preferredCountryCodes,
  onChange,
  value,
  ...props
}) => {
  const locale = useSetting("locale", "en-GB");
  const [language] = locale.split("-");
  const codes =
    countryCodes && countryCodes.length
      ? countryCodes
      : Object.keys(customList("countryCode", "{countryCode}"));
  const [open, setOpen] = useState(false);
  const [, setLanguageReady] = useState(false);
  const ref = useRef();

  import(`i18n-iso-countries/langs/${language}.json`)
    .then((langModule) => {
      countries.registerLocale(langModule);
      setLanguageReady(true);
    })
    .catch((err) => {
      console.error(err);
    });

  const options = codes
    .filter(
      (c) => c && countries.getName(c, language) && flagCountries.hasFlag(c)
    )
    .map((c) => ({
      key: c,
      value: c,
      label: countries.getName(c, language),
      preferred:
        preferredCountryCodes && preferredCountryCodes.indexOf(c) !== -1 ? 2 : 0
    }));

  if (preferredCountryCodes && preferredCountryCodes.length) {
    options.push({
      key: "divider",
      value: "divider",
      label: "divider",
      preferred: 1,
      disabled: true
    });
  }

  const sortedOptions = _.orderBy(
    options,
    ["preferred", "label"],
    ["desc", "asc"]
  );

  const onChangeInternal = (value) => {
    if (onChange) {
      onChange(value.length ? value.pop() : "");
    }

    ref?.current?.blur();
  };

  return (
    <Select
      ref={ref}
      open={open}
      mode={"multiple"}
      menuItemSelectedIcon={false}
      showSearch={false}
      tagRender={OptionRenderer}
      options={sortedOptions}
      optionRender={OptionRenderer}
      onChange={onChangeInternal}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      value={value ? [value] : undefined}
      allowClear={value}
      {...props}
    />
  );
};

SelectCountry.propTypes = {
  countryCodes: PropTypes.array,
  preferredCountryCodes: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func
};

export default SelectCountry;
