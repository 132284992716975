import Page from "../../components/Page";
import { ErrorPageLayout } from "../../components/Layout";
import { useString as s } from "../../components/StringProvider";
import Heading from "../../components/Heading";
import { Text } from "../../components";
import { Space } from "antd";
import NewButton from "../../components/NewButton";
import Icon from "../../components/Icon";
import openInNewTab from "../../utils/open-in-new-tab";

const IFrameNotSupported = () => {
  const headerText = s(
    "iframe_not_supported.header",
    "Embedding is not supported for this page"
  );
  const errorText = s(
    "iframe_not_supported.text",
    "This page cannot be used while embedded in another page or app. Open the page in a new tab to continue."
  );
  const buttonText = s(
    "iframe_not_supported.open_in_new_tab",
    "Open Page in New Tab"
  );

  const onClick = () => {
    openInNewTab(window.location.href);
  };

  return (
    <Page>
      <ErrorPageLayout>
        <Space direction={"vertical"} size={"large"}>
          <Icon colour={"gray4"} size={"xlarge"} name={"devices"} />
          <Heading level={"h3"} color={"gray4"}>
            {headerText}
          </Heading>
          <Text color={"gray4"}>{errorText}</Text>
          <NewButton type={"alternate"} onClick={onClick}>
            {buttonText}
          </NewButton>
        </Space>
      </ErrorPageLayout>
    </Page>
  );
};

export default IFrameNotSupported;
