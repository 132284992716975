import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import {
  generateAutoconfiguration,
  getAutoconfiguration,
  validateAutoconfiguration
} from "../../../../store/actions/autoconfigurations";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { message, Steps } from "antd";
import styled from "styled-components";
import ButtonGroup from "../../../../components/ButtonGroup";
import NewButton from "../../../../components/NewButton";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import useLoadingState from "../../../../utils/use-loading-state";
import JobMonitor from "../../../../components/JobMonitor";
import JobStatuses from "../../../../utils/job-statuses";
import GenerateError from "./GenerateError";
import GenerateProgress from "./GenerateProgress";
import UpdateConfigurationModal from "../../ManageConfiguration/UpdateConfiguration/UpdateConfigurationModal";
import { deployValidation } from "../../../../store/actions/validations";
const Step = Steps.Step;

const getSteps = ({ autoconfiguration, location }) => {
  const matches = location.pathname.match(/^.*\/([a-z]+)$/);

  const steps = [
    { key: "questions" },
    {
      key: "categories",
      disabled: !autoconfiguration?.questions?.length
    },
    {
      key: "challenges",
      disabled: !autoconfiguration?.categories?.length
    },
    {
      key: "kpis",
      disabled: !autoconfiguration?.challenges?.length
    },
    {
      key: "mappings",
      disabled:
        !autoconfiguration?.challenges?.length ||
        !autoconfiguration?.kpis?.length
    }
  ];

  const currentStep = steps.find((s) => s.key === matches[1]);

  if (currentStep) {
    currentStep.selected = true;
  }

  return steps;
};

const getCurrentStep = ({ autoconfiguration, location }) => {
  const steps = getSteps({ autoconfiguration, location });
  const index = steps.findIndex((s) => s.selected);

  return index !== -1 ? index : 0;
};

const AutoconfigurationLayout = ({
  history,
  autoconfiguration,
  children,
  generateLoadingState,
  generateJob,
  getAutoconfiguration,
  generateAutoconfiguration,
  validateAutoconfiguration,
  validation,
  validateLoadingState,
  deployValidation,
  deployLoadingState,
  deployJob
}) => {
  const regenerate = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.regenerate.button",
    "Regenerate Master Data"
  );
  const proceed = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.proceed.button",
    "Proceed"
  );
  const deploy = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.deploy.button",
    "Update Configuration"
  );
  const deployError = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.messages.deployError",
    "Failed to update configuration"
  );
  const cancel = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.cancel.button",
    "Cancel"
  );
  const location = useLocation();
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [jobId, setJobId] = useState("");
  const [job, setJob] = useState(null);
  const [generateError, setGenerateError] = useState(false);
  const [isDeployModalVisible, setDeployModalVisible] = useState(false);
  const stepTitles = {};

  stepTitles["questions"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.steps.questions",
    "Questions & Answers"
  );

  stepTitles["categories"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.steps.categories",
    "Categories"
  );

  stepTitles["challenges"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.steps.challenges",
    "Challenges"
  );

  stepTitles["kpis"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.steps.kpis",
    "KPIs"
  );

  stepTitles["mappings"] = s(
    "organization.page.autoconfiguration.autoconfigurationWizard.steps.mappings",
    "Mappings"
  );

  useEffect(() => {
    setSteps(getSteps({ autoconfiguration, location }));
    setCurrentStep(getCurrentStep({ autoconfiguration, location }));
  }, [autoconfiguration, location]);

  useLoadingState(
    generateLoadingState,
    () => {
      setJobId(generateJob._id);
    },
    () => {
      setGenerateError(true);
    }
  );

  useEffect(() => {
    if (job) {
      switch (job.status) {
        case JobStatuses.COMPLETED:
          setJobId(null);
          getAutoconfiguration({ autoconfigurationId: autoconfiguration._id });
          setGenerateError(false);
          break;
        case JobStatuses.FAILED:
          setJobId(null);
          setGenerateError(true);
          break;
      }
    }
  }, [job]);

  useLoadingState(
    validateLoadingState,
    () => {
      console.log("validation", validation);
      if (validation.status === "validated") {
        setDeployModalVisible(false);
        deployValidation({ validationId: validation._id });
      } else {
        message.error(deployError);
      }
    },
    () => {
      message.error(deployError);
    }
  );

  useLoadingState(
    deployLoadingState,
    () => {
      history.push(`/organizations/deployments/${deployJob._id}`);
    },
    () => {
      message.error(deployError);
    }
  );

  const onContinue = () => {
    onChangeStep(currentStep + 1);
  };

  const onCancel = () => {
    history.push(`/organizations/autoconfigurations/create`);
  };

  const onRegenerate = () => {
    generateAutoconfiguration({
      autoconfigurationId: autoconfiguration._id,
      section: steps[currentStep].key
    });
  };

  const onGenerateJobChange = (newJob) => {
    setJob(newJob);
  };

  const onChangeStep = (newStep) => {
    history.push(
      `/organizations/autoconfigurations/${autoconfiguration._id}/${steps[newStep].key}`
    );
  };

  const onDeploy = () => {
    setDeployModalVisible(true);
  };

  const onConfirmDeploy = () => {
    validateAutoconfiguration({ autoconfigurationId: autoconfiguration._id });
  };

  const onCancelDeploy = () => {
    setDeployModalVisible(false);
  };

  return (
    <Container>
      <SubContainer>
        {jobId ? (
          <div className={"content"}>
            <GenerateProgress job={job} />
            <JobMonitor onChange={onGenerateJobChange} jobId={jobId} />
          </div>
        ) : generateError ? (
          <div className={"content"}>
            <GenerateError />
          </div>
        ) : (
          <div className={"content"}>{children}</div>
        )}
        <Steps
          current={currentStep}
          status={jobId ? "process" : generateError ? "error" : "finish"}
          onChange={onChangeStep}
          direction={"vertical"}
        >
          {steps.map((step) => (
            <Step
              key={step.key}
              title={stepTitles[step.key] || step.key}
              disabled={step.disabled}
            />
          ))}
        </Steps>
      </SubContainer>

      <SideBySide>
        <ButtonGroup>
          {currentStep + 1 === steps.length && (
            <NewButton
              type={"primary"}
              onClick={onDeploy}
              data-cy={"deploy-button"}
              disabled={!!jobId || generateError}
            >
              {deploy}
            </NewButton>
          )}
          {currentStep + 1 < steps.length && (
            <NewButton
              type={"primary"}
              onClick={onContinue}
              data-cy={"proceed-button"}
              disabled={!!jobId || generateError}
            >
              {proceed}
            </NewButton>
          )}
          <NewButton
            type={"secondary"}
            onClick={onRegenerate}
            data-cy={"regenerate-button"}
            disabled={!!jobId}
          >
            {regenerate}
          </NewButton>
        </ButtonGroup>
        <ButtonGroup>
          <NewButton
            type={"alternate"}
            onClick={onCancel}
            data-cy={"cancel-button"}
          >
            {cancel}
          </NewButton>
        </ButtonGroup>
      </SideBySide>
      {isDeployModalVisible && (
        <UpdateConfigurationModal
          onClose={onCancelDeploy}
          onConfirm={onConfirmDeploy}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: stretch;
  gap: 24px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  gap: 24px;

  & .content {
    flex-grow: 1;
    width: 100%;
  }

  & .ant-steps {
    max-width: 218px;
    max-height: 50vh;
  }
`;

const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 242px;
`;

const mapStateToProps = (state) => ({
  autoconfiguration: selectRequestData(
    state,
    actionTypes.GET_AUTOCONFIGURATION_REQUEST
  ),
  generateLoadingState: selectRequestState(
    state,
    actionTypes.GENERATE_AUTOCONFIGURATION_REQUEST
  ),
  generateJob: selectRequestData(
    state,
    actionTypes.GENERATE_AUTOCONFIGURATION_REQUEST
  ),
  validateLoadingState: selectRequestState(
    state,
    actionTypes.VALIDATE_AUTOCONFIGURATION_REQUEST
  ),
  validation: selectRequestData(
    state,
    actionTypes.VALIDATE_AUTOCONFIGURATION_REQUEST
  ),
  deployLoadingState: selectRequestState(
    state,
    actionTypes.DEPLOY_VALIDATION_REQUEST
  ),
  deployJob: selectRequestData(state, actionTypes.DEPLOY_VALIDATION_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAutoconfiguration,
      generateAutoconfiguration,
      validateAutoconfiguration,
      deployValidation
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AutoconfigurationLayout);
