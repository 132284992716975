import styled from "styled-components";
import { Rate as ANTRate } from "antd";
import { themeProp } from "../theme";

const Rate = styled(ANTRate)`
  &.ant-rate {
    color: ${themeProp("palette.primary")};
    line-height: 32px;
  }

  &.ant-rate-disabled,
  &.ant-rate-disabled .ant-rate-star {
    cursor: not-allowed;
  }

  & .ant-rate-star:not(:last-child) {
    margin-right: 3px;
  }

  &.ant-rate-disabled .ant-rate-star.ant-rate-star-full,
  &.ant-rate-disabled .ant-rate-star.ant-rate-star-zero,
  &.ant-rate-disabled .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
    transition: transform 0s;
  }

  &.ant-rate-disabled
    .ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
    transform: scale(0.91);
  }

  &.ant-rate-disabled .ant-rate-star.ant-rate-star-full:hover {
    transform: scale(0.91);
  }

  &.ant-rate-disabled .ant-rate-star.ant-rate-star-zero:hover {
    transform: scale(0.91);
  }
`;

export default Rate;
