import styled from "styled-components";
import { TextArea } from "../../Input";
import { themeProp } from "../../../theme";

const ChallengeDescriptionTextArea = styled(TextArea)`
  font-family: ${themeProp("typography.body.fontFamily")};
  font-size: ${themeProp("typography.body.fontSize")};
  font-weight: ${themeProp("typography.bMedium.fontWeight")};
  line-height: ${themeProp("typography.body.lineHeight")};
  padding-left: 0px;
`;

export default ChallengeDescriptionTextArea;
