import PropTypes from "prop-types";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { $getRoot, $createParagraphNode, $createTextNode } from "lexical";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS
} from "@lexical/markdown";

export default function OnUpdateValuePlugin({ value, initialized }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const rootElement = editor && editor.getRootElement();

    if (value && editor && initialized) {
      editor.update(() => {
        const root = $getRoot();

        const paragraphNode = $createParagraphNode();
        paragraphNode.append($createTextNode(value));

        root.clear();
        root.append(paragraphNode);

        const markdown = $convertToMarkdownString(TRANSFORMERS);
        $convertFromMarkdownString(markdown, TRANSFORMERS);

        root.selectStart(0, 0);
      });

      setTimeout(() => {
        rootElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 10);
    }
  }, [value]);

  return null;
}

OnUpdateValuePlugin.propTypes = {
  value: PropTypes.string.isRequired,
  initialized: PropTypes.bool.isRequired
};
