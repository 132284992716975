import React, { createContext, useContext, useEffect } from "react";
import _ from "lodash";
import { selectFeatures } from "../store/reducers";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFeatures } from "../store/actions/features";

export const FeaturesContext = createContext();
export const FeaturesProvider = ({ getFeatures, features, children }) => {
  const getFeature = (key, defaultValue) => _.get(features, key, defaultValue);

  useEffect(() => {
    getFeatures();
  }, []);

  return (
    !!features && (
      <FeaturesContext.Provider
        value={{
          features,
          getFeature
        }}
      >
        {children}
      </FeaturesContext.Provider>
    )
  );
};

export const useFeature = (key, activeByDefault) => {
  const { features } = useContext(FeaturesContext);
  const result = features[key];

  return result === true || result === false ? result : activeByDefault;
};

const mapStateToProps = (state) => ({
  features: selectFeatures(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFeatures
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesProvider);
