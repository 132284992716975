import { v4 as uuidv4 } from "uuid";
import CookieStorage from "./CookieStorage";
import httpService from "./http.service";
import EnvironmentNames from "../utils/constants/EnvironmentNames";

const domain =
  httpService.getEnvironmentId() === EnvironmentNames.LOCALHOST
    ? window.location.hostname
    : ".cuvama.com";

const storage = new CookieStorage({
  domain,
  secure: httpService.getEnvironmentId() !== EnvironmentNames.LOCALHOST,
  sameSite:
    httpService.getEnvironmentId() !== EnvironmentNames.LOCALHOST
      ? "none"
      : "strict"
});

const saveState = (stateData) => {
  const value = JSON.stringify(stateData);
  const state = uuidv4();

  storage.setItem(state, value);
  return state;
};

const getState = ({ state }) => {
  const stateData = storage.getItem(state);

  return stateData ? JSON.parse(stateData) : null;
};

export default {
  saveState,
  getState
};
