import {
  AlternateViewportContent,
  OrganizationPageLayout
} from "../../../components/Layout";
import Page from "../../../components/Page";
import OrganizationHeader from "../OrganizationHeader";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { NotFound } from "../../ErrorPage";
import { compose } from "redux";
import { selectCookie } from "../../../store/reducers";
import { connect } from "react-redux";
import CookieNames from "../../../utils/cookie-names";
import DiscoveryUnsupportedResolutionPage from "../../Discovery/DiscoveryUnsupportedResolutionPage";
import CreateAutoconfiguration from "./CreateAutoconfiguration";
import React from "react";
import AutoconfigurationWizard from "./AutoconfigurationWizard/AutoconfigurationWizard";

const Autoconfiguration = ({ showDiscoveryOnUnsupportedResolution }) => {
  return (
    <AlternateViewportContent
      alwaysShowContent={showDiscoveryOnUnsupportedResolution}
      alternate={<DiscoveryUnsupportedResolutionPage />}
    >
      <Page header={<OrganizationHeader />}>
        <OrganizationPageLayout>
          <Switch>
            <Route
              path="/organizations/autoconfigurations/create"
              exact
              component={CreateAutoconfiguration}
            />
            <Route
              path="/organizations/autoconfigurations/:autoconfigurationId"
              component={AutoconfigurationWizard}
            />
            <Route
              path="/organizations/autoconfigurations/:autoconfigurationId/*"
              component={AutoconfigurationWizard}
            />
            <Redirect
              from={""}
              to={"/organizations/autoconfigurations/create"}
              exact
            />
            <Route component={NotFound} />
          </Switch>
        </OrganizationPageLayout>
      </Page>
    </AlternateViewportContent>
  );
};

const mapStateToProps = (state) => ({
  showDiscoveryOnUnsupportedResolution: selectCookie(
    state,
    CookieNames.SHOW_DISCOVERY_ON_UNSUPPORTED_RESOLUTION
  )
});

export default compose(withRouter, connect(mapStateToProps))(Autoconfiguration);
