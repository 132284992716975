import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { startDiscoveryOperation } from "../../store/actions/discovery-operations";
import { Operations } from "./DiscoveryOperations";
import NewButton from "../NewButton";
import Icon from "../Icon";
import Permissions from "../../utils/permissions";
import { hasPermission } from "../../store/reducers";

const EmailButton = ({
  discoveryId,
  canSeeEmailButton,
  isLeadGeneration,
  startDiscoveryOperation,
  tooltip,
  isMandatoryQuestionsCompleted,
  type
}) => {
  const onClick = () => {
    startDiscoveryOperation({
      operationId: Operations.EMAIL,
      operationDiscoveryId: discoveryId
    });
  };

  return canSeeEmailButton && !isLeadGeneration ? (
    <NewButton
      type={type}
      onClick={onClick}
      tooltip={tooltip}
      data-cy={"email-button"}
      className="up-email-button"
      color="text"
      disabled={!isMandatoryQuestionsCompleted}
    >
      <Icon name={"email"} size={"medium"} />
    </NewButton>
  ) : null;
};

EmailButton.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  isMandatoryQuestionsCompleted: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["iconPrimary", "iconSecondary", "iconDarkPrimary"])
};

const mapStateToProps = (state) => ({
  canSeeEmailButton: hasPermission(state, Permissions.SHARE_EDIT),
  isLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startDiscoveryOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EmailButton
);
