import styled from "styled-components";
import {
  selectDiscoveryCurrency,
  selectDiscoverySelectedKPIs
} from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { KPICard } from "../../components/Discovery";
import PropType from "prop-types";
import Masonry from "../../components/Masonry";
import { useMobileMediaQuery } from "../../components/Responsive";
import { sortDiscoveryKPIsByImportance } from "../../utils/sorting";

const KPISummaryCards = ({
  showAllKPIs,
  selectedKPIs,
  currency,
  reportId,
  printMode
}) => {
  const isMobile = useMobileMediaQuery();
  const sortedKPIs = [...selectedKPIs];

  sortedKPIs.sort(sortDiscoveryKPIsByImportance);

  return isMobile ? (
    (showAllKPIs ? sortedKPIs : sortedKPIs.slice(0, 4)).map((discoveryKPI) => (
      <MarginContainer key={discoveryKPI.kpiCode} className={"mobile"}>
        <KPICard
          discoveryKPI={discoveryKPI}
          reportId={reportId}
          currency={currency}
        />
      </MarginContainer>
    ))
  ) : (
    <Masonry
      breakpointCols={{
        default: 2,
        1300: 1
      }}
    >
      {sortedKPIs.map((discoveryKPI) => (
        <MarginContainer key={discoveryKPI.kpiCode}>
          <KPICard
            discoveryKPI={discoveryKPI}
            reportId={reportId}
            currency={currency}
            printMode={printMode}
          />
        </MarginContainer>
      ))}
    </Masonry>
  );
};

const MarginContainer = styled.div`
  margin-bottom: 20px;

  &.mobile {
    margin-bottom: 16px;
  }
  &.mobile:last-child {
    margin-bottom: 0;
  }
`;

KPISummaryCards.propType = {
  reportId: PropType.string,
  showAllKPIs: PropType.bool,
  printMode: PropType.bool
};

KPISummaryCards.defaultProps = {
  showAllKPIs: true
};

const mapStateToProps = (state) => ({
  currency: selectDiscoveryCurrency(state),
  selectedKPIs: selectDiscoverySelectedKPIs(state)
});

export default compose(connect(mapStateToProps))(KPISummaryCards);
