import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";
import { replaceCheckpointInDiscovery } from "./discovery";

const INITIAL_STATE = {
  loadingState: LoadingState.UNINITIALIZED,
  discoveries: [],
  pagination: null,
  discoveriesCount: 0,
  onlySamples: false,
  fetchDiscoveriesError: false,
  addDiscoveryLoadingState: LoadingState.UNINITIALIZED,
  addDiscoveryError: null,
  addDiscoveryResult: null
};

const updateEngagementCountsForDiscoveryInDiscoveries = ({
  discoveries,
  discoveryId,
  externalEngagementCount,
  externalReportViewCount
}) => {
  const discovery = discoveries.find((item) => item._id === discoveryId);

  if (!discovery) {
    return discoveries;
  }

  const newDiscovery = {
    ...discovery,
    externalEngagementCount,
    externalReportViewCount
  };

  return replaceDiscoveryInDiscoveries({
    discoveries,
    discovery: newDiscovery
  });
};

const replaceDiscoveryInDiscoveries = ({ discoveries, discovery }) => {
  return discoveries.reduce(
    (list, item) =>
      item._id === discovery._id ? [...list, discovery] : [...list, item],
    []
  );
};

const isFilterEmpty = (filters) => {
  const ignoreFields = ["start", "count", "sort", "order"];

  for (const field of Object.keys(filters)) {
    if (!ignoreFields.includes(field)) {
      return false;
    }
  }

  return true;
};

const discoveries = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERIES_FETCH_REQUEST:
      return {
        ...state,
        loadingState: LoadingState.IN_PROGRESS,
        fetchDiscoveriesError: false
      };
    case types.DISCOVERIES_FETCH_SUCCESS:
      let discoveriesCount = state.discoveriesCount;

      if (isFilterEmpty(action.payload.filters)) {
        discoveriesCount = action.payload.pagination.total;
      }

      return {
        ...state,
        discoveries: action.payload.discoveries,
        pagination: action.payload.pagination,
        loadingState: LoadingState.COMPLETED,
        discoveriesCount,
        onlySamples: action.payload.onlySamples
      };
    case types.DISCOVERIES_FETCH_FAILURE:
      return {
        ...state,
        fetchDiscoveriesError: action.payload.error,
        loadingState: LoadingState.FAILED
      };

    case types.START_DISCOVERY_OPERATION:
      return {
        ...state,
        addDiscoveryLoadingState: LoadingState.UNINITIALIZED,
        addDiscoveryError: null,
        addDiscoveryResult: null
      };
    case types.ADD_DISCOVERY_REQUEST:
      return {
        ...state,
        addDiscoveryLoadingState: LoadingState.IN_PROGRESS,
        addDiscoveryError: null,
        addDiscoveryResult: null
      };
    case types.ADD_DISCOVERY_SUCCESS:
      return {
        ...state,
        discoveriesCount: state.discoveriesCount + 1,
        addDiscoveryLoadingState: LoadingState.COMPLETED,
        addDiscoveryResult: action.payload.discovery
      };
    case types.ADD_DISCOVERY_FAILURE:
      return {
        ...state,
        addDiscoveryLoadingState: LoadingState.FAILED,
        addDiscoveryError: action.payload.error
      };
    case types.UPDATE_DISCOVERY_SUCCESS:
      return {
        ...state,
        discoveries: replaceDiscoveryInDiscoveries({
          discoveries: state.discoveries,
          discovery: action.payload.discovery
        })
      };
    case types.DELETE_DISCOVERY_SUCCESS:
      return {
        ...state,
        discoveriesCount: state.discoveriesCount - 1
      };

    case types.ASSIGN_DISCOVERY_REQUEST:
      return {
        ...state
      };
    case types.ASSIGN_DISCOVERY_SUCCESS:
      const { canSeeAllDiscoveries } = action.payload;

      return {
        ...state,
        discoveries: canSeeAllDiscoveries
          ? replaceDiscoveryInDiscoveries({
              discoveries: state.discoveries,
              discovery: action.payload.discovery
            })
          : state.discoveries,
        discoveriesCount:
          state.discoveriesCount - (canSeeAllDiscoveries ? 0 : 1)
      };
    case types.ASSIGN_DISCOVERY_FAILURE:
      return {
        ...state
      };
    case types.DISCOVERY_SAVE_CHECKPOINT_SUCCESS:
      let discovery = state?.discoveries?.find(
        (discovery) => discovery._id === action.payload.discoveryId
      );

      if (!discovery) {
        return {
          ...state
        };
      }

      discovery = replaceCheckpointInDiscovery({
        discovery: discovery,
        checkpoint: action.payload.checkpoint,
        phase: action.payload.phase,
        lastViewedPage: action.payload.lastViewedPage
      });

      return {
        ...state,
        discoveries: replaceDiscoveryInDiscoveries({
          discoveries: state.discoveries,
          discovery: discovery
        })
      };

    case types.UPDATE_DISCOVERY_ENGAGEMENT_COUNTS:
      return {
        ...state,
        discoveries: updateEngagementCountsForDiscoveryInDiscoveries({
          discoveries: state.discoveries,
          discoveryId: action.payload.discoveryId,
          externalEngagementCount: action.payload.externalEngagementCount,
          externalReportViewCount: action.payload.externalReportViewCount
        })
      };

    default:
      return state;
  }
};

export const selectDiscoveries = (discoveriesData) =>
  discoveriesData.discoveries;
export const selectDiscoveriesPagination = (discoveriesData) =>
  discoveriesData.pagination;
export const selectDiscoveriesCount = (discoveriesData) =>
  discoveriesData.discoveriesCount;
export const selectDiscoveriesOnlySamples = (discoveriesData) =>
  discoveriesData.onlySamples;

export const selectFetchDiscoveriesLoadingState = (discoveriesData) =>
  discoveriesData.loadingState;
export const selectDiscoveriesFetchError = (discoveriesData) =>
  discoveriesData.fetchDiscoveriesError;
export const selectAddDiscoveryError = (discoveriesData) =>
  discoveriesData.addDiscoveryError;
export const selectAddDiscoveryLoadingState = (discoveriesData) =>
  discoveriesData.addDiscoveryLoadingState;
export const selectAddDiscoveryResult = (discoveriesData) =>
  discoveriesData.addDiscoveryResult;

export default discoveries;
