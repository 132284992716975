import types from "../actionTypes";
import guestService from "../../services/guests.service";

export const getGuest = () => (dispatch) => {
  dispatch({
    type: types.GET_GUEST_REQUEST
  });

  return guestService
    .getGuest()
    .then((payload) => {
      dispatch({
        type: types.GET_GUEST_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_GUEST_FAILURE,
        error
      });
    });
};

export const registerGuest =
  ({ email, reportId }) =>
  (dispatch) => {
    dispatch({
      type: types.REGISTER_GUEST_REQUEST
    });

    return guestService
      .registerGuest({ email, reportId })
      .then((payload) => {
        dispatch({
          type: types.REGISTER_GUEST_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.REGISTER_GUEST_FAILURE,
          error
        });
      });
  };

const guests = { getGuest, registerGuest };

export default guests;
