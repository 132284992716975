import React, { useEffect } from "react";
import { useString as s } from "../StringProvider";
import Form, { FormItem } from "../Form";
import PropTypes from "prop-types";
import SelectTemplate from "./SelectTemplate";
import NewButton from "../NewButton";
import { bindActionCreators, compose } from "redux";
import { exportDiscovery } from "../../store/actions/discoveries";
import { connect } from "react-redux";
import ButtonGroup from "../ButtonGroup";
import Modal from "../Modal";
import { hasPermission } from "../../store/reducers";
import Permissions from "../../utils/permissions";

const PrintReportModal = ({
  reportId,
  template,
  phase,
  exportDiscovery,
  onClose,
  canSeeLeadGeneration
}) => {
  const [form] = Form.useForm();
  const okText = s("print_report.popup.button1", "Next");
  const printReportText = s("export_pdf.popup.header", "Export PDF Report");
  const downloadPDFText = s("download_pdf.popup.header", "Download PDF");
  const templateText = s(
    "print_powerpoint.popup.box1.header",
    "Discovery Template"
  );
  const cancelText = s("print_report.popup.cancel", "Cancel");

  useEffect(() => {
    form.setFieldsValue({ template });
  }, [form, template]);

  const onFinish = async ({ template }) => {
    exportDiscovery({ reportId, template, format: "pdf" });
    onClose();
  };

  return (
    <Modal
      title={canSeeLeadGeneration ? downloadPDFText : printReportText}
      open={true}
      onCancel={onClose}
      data-cy={"export-pdf-modal"}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem label={templateText} name="template">
          <SelectTemplate phase={phase} />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <NewButton type={"submit"} data-cy={"export-button"}>
              {okText}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onClose}
              data-cy={"cancel-button"}
            >
              {cancelText}
            </NewButton>
          </ButtonGroup>
        </FormItem>
      </Form>
    </Modal>
  );
};

PrintReportModal.propTypes = {
  reportId: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  phase: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PrintReportModal
);
