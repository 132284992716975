import { CenteredPageLayout } from "../../components/Layout";
import { Space } from "antd";
import Heading from "../../components/Heading";
import NewButton from "../../components/NewButton";
import Page from "../../components/Page";
import Header, { BrandBadge } from "../../components/Header";
import { useString as s } from "../../components/StringProvider";
import styled from "styled-components";
import Icon from "../../components/Icon";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { setCookie } from "../../store/actions/cookies";
import CookieNames from "../../utils/cookie-names";
import TextBody from "../../components/TextBody";
import { useMobileMediaQuery } from "../../components/Responsive";

const DiscoveryUnsupportedResolutionPageHeader = ({}) => {
  return (
    <Header>
      <BrandBadge linkTo={"/"} />
    </Header>
  );
};

const DiscoveryUnsupportedResolutionPage = ({ setCookie }) => {
  const isMobile = useMobileMediaQuery();

  const header = s(
    "discoveryUnsupportedResolution.page.header",
    "This screen width is not supported for this page yet"
  );
  const subheader = s(
    "discoveryUnsupportedResolution.page.subheader",
    "You can still use the app, but you’ll get the best experience on a wider screen"
  );
  const continueButton = s(
    "discoveryUnsupportedResolution.page.continue",
    "Continue Anyway"
  );

  const onContinueAnyway = () => {
    setCookie({
      name: CookieNames.SHOW_DISCOVERY_ON_UNSUPPORTED_RESOLUTION,
      value: "true"
    });
  };

  return (
    <Page header={<DiscoveryUnsupportedResolutionPageHeader />}>
      <CenteredPageLayout>
        <Container>
          <Space direction={"vertical"} size={32}>
            <Space direction={"vertical"} size={16}>
              <Space direction={"vertical"} size={8}>
                <Icon colour={"gray4"} size={"xlarge"} name={"devices"} />
                <Heading level={isMobile ? "h4" : "h3"} color={"gray4"}>
                  {header}
                </Heading>
              </Space>
              <TextBody color={"gray4"}>{subheader}</TextBody>
            </Space>
            <NewButton type={"alternate"} onClick={onContinueAnyway} style>
              {continueButton}
            </NewButton>
          </Space>
        </Container>
      </CenteredPageLayout>
    </Page>
  );
};

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;

  h3,
  h4 {
    text-align: center;
    margin-bottom: 0px;
  }
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCookie
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DiscoveryUnsupportedResolutionPage
);
