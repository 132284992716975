import { Tooltip as ANTTooltip } from "antd";
import styled from "styled-components";
import { themeProp } from "../theme";
import { POPUP_CONTAINER } from "./FullscreenProvider";

export const getTooltipDefaultAttributes = (props) => {
  const fontFamily = themeProp("typography.body.fontFamily")
    ? themeProp("typography.body.fontFamily")(props)
    : undefined;
  const background = themeProp("palette.text")
    ? themeProp("palette.text")(props)
    : undefined;

  return {
    arrow: { pointAtCenter: true },
    overlayInnerStyle: {
      padding: "8px 16px",
      borderRadius: "8px",
      color: "white",
      background,
      fontFamily,
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 700,
      letterSpacing: "0.6px",
      textAlign: "center",
      ...props?.overlayInnerStyle
    },
    getPopupContainer: () => document.getElementById(POPUP_CONTAINER)
  };
};

const Tooltip = styled(ANTTooltip).attrs(getTooltipDefaultAttributes)``;

export default Tooltip;
