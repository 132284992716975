import Heading from "../../Heading";
import Text from "../../Text";
import { Space } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useString as s } from "../../StringProvider";
import {
  selectDiscoverySelectedKPIs,
  selectSolution
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import RelatedKPI from "./RelatedKPI";
import Icon from "../../Icon";
import ScrollZone from "../../ScrollZone";

const RelatedKPIs = ({ solutionCode, solution, discoveryKPIs, onClose }) => {
  const titleText = s(
    "solutions.popover.relatedKPIs.title",
    "Related Outcomes"
  );

  return (
    <>
      <Title>
        <Icon
          colour={"surface"}
          name={"arrowLeft"}
          size={"medium"}
          onClick={onClose}
          style={{ cursor: "pointer" }}
        />
        <Heading level={"h3"} color={"surface"}>
          {solution.name}
        </Heading>
      </Title>
      <Text variant={"bMedium"} color={"surface"}>
        {titleText}
      </Text>
      <ScrollZone style={{ marginTop: "16px" }}>
        <Space direction={"vertical"} size={18}>
          {discoveryKPIs.map((discoveryKPI) => (
            <RelatedKPI
              key={discoveryKPI.kpiCode}
              discoveryKPI={discoveryKPI}
              solutionCode={solutionCode}
            />
          ))}
        </Space>
      </ScrollZone>
    </>
  );
};

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 16px;

  & h3 {
    margin-bottom: 0px;
    margin-left: 20px;
  }
`;

RelatedKPIs.propTypes = {
  solutionCode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    solution: selectSolution(state, props.solutionCode),
    discoveryKPIs: selectDiscoverySelectedKPIs(state)
  };
};

export default compose(connect(mapStateToProps))(RelatedKPIs);
