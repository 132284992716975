const getStringsFormConfig = (s) => [
  {
    groupId: 1,
    groupName: s("updateBranding.page.stringsForm.group1.name", "General"),
    values: [
      {
        name: "variables.companyName",
        label: s(
          "updateBranding.page.stringsForm.values.label.companyName",
          "Company Name"
        )
      }
    ]
  },
  {
    groupId: 2,
    groupName: s(
      "updateBranding.page.stringsForm.group2.name",
      "New Discovery Modal"
    ),
    values: [
      {
        name: "new_discovery.popup.discovery_type.header",
        label: s(
          "updateBranding.page.stringsForm.values.label.discoveryTypeLabel",
          "Discovery Type Field, Label"
        )
      },
      {
        name: "new_discovery.popup.discovery_type.placeholder",
        label: s(
          "updateBranding.page.stringsForm.values.label.discoveryTypePlaceholder",
          "Discovery Type Field, Placeholder Text"
        )
      },
      {
        name: "new_discovery.popup.box2.header",
        label: s(
          "updateBranding.page.stringsForm.values.label.opportunityLabel",
          "Opportunity Field, Label"
        )
      },
      {
        name: "new_discovery.popup.box2.text",
        label: s(
          "updateBranding.page.stringsForm.values.label.opportunityPlaceholder",
          "Opportunity Field, Placeholder Text"
        )
      },
      {
        name: "new_discovery.popup.box3.header",
        label: s(
          "updateBranding.page.stringsForm.values.label.descriptionLabel",
          "Description Field, Label"
        )
      },
      {
        name: "new_discovery.popup.box3.text",
        label: s(
          "updateBranding.page.stringsForm.values.label.descriptionPlaceholder",
          "Description Field, Placeholder Text"
        )
      }
    ]
  },
  {
    groupId: 3,
    groupName: s(
      "updateBranding.page.stringsForm.group3.name",
      "New Discovery Page"
    ),
    values: [
      {
        name: "start_discovery.page.header",
        label: s(
          "updateBranding.page.stringsForm.values.label.startDiscoveryPageHeader",
          "Start Discovery Page, Header"
        )
      },
      {
        name: "start_discovery.page.subheader",
        label: s(
          "updateBranding.page.stringsForm.values.label.startDiscoveryPageSubHeader",
          "Start Discovery Page, Sub Header"
        )
      }
    ]
  },
  {
    groupId: 4,
    groupName: s(
      "updateBranding.page.stringsForm.group4.name",
      "ROI Edit Assumptions"
    ),
    values: [
      {
        name: "discovery.roi.form.input1",
        label: s(
          "updateBranding.page.stringsForm.values.label.roiEditAssumptionsInput1",
          "ROI Edit Assumptions, Annual Investment, Tooltip"
        )
      },
      {
        name: "discovery.roi.form.input2",
        label: s(
          "updateBranding.page.stringsForm.values.label.roiEditAssumptionsInput2",
          "ROI Edit Assumptions, Upfront Investment, Tooltip"
        )
      }
    ]
  },
  {
    groupId: 5,
    groupName: s(
      "updateBranding.page.stringsForm.group5.name",
      "Register Interest Form"
    ),
    optional: true,
    values: [
      {
        name: "register_interest.popup.privacy.link1.label",
        label: s(
          "updateBranding.page.stringsForm.values.label.registerInterestPrivacyLinkText",
          "Lead Generation, Register Interest Modal, Privacy Link Text"
        )
      },
      {
        name: "register_interest.popup.privacy.link1.url",
        label: s(
          "updateBranding.page.stringsForm.values.label.registerInterestPrivacyLinkURL",
          "Lead Generation, Register Interest Modal, Privacy Link URL"
        )
      }
    ]
  },
  {
    groupId: 6,
    groupName: s(
      "updateBranding.page.stringsForm.group6.name",
      "Email Templates"
    ),
    values: [
      {
        name: "share.popup.template.challenges.subject",
        label: s(
          "updateBranding.page.stringsForm.values.challengesEmailTemplate.subject.label",
          "Challenges Email Template - Subject"
        )
      },
      {
        name: "share.popup.template.challenges.body",
        label: s(
          "updateBranding.page.stringsForm.values.challengesEmailTemplate.body.label",
          "Challenges Email Template - Body"
        )
      },
      {
        name: "share.popup.template.kpis.subject",
        label: s(
          "updateBranding.page.stringsForm.values.kpisEmailTemplate.subject.label",
          "KPIs Email Template - Subject"
        )
      },
      {
        name: "share.popup.template.kpis.body",
        label: s(
          "updateBranding.page.stringsForm.values.kpisEmailTemplate.body.label",
          "KPIs Email Template - Body"
        )
      },
      {
        name: "share.popup.template.valueHypothesis.subject",
        label: s(
          "updateBranding.page.stringsForm.values.valueHypothesisEmailTemplate.subject.label",
          "Value Hypothesis Email Template - Subject"
        )
      },
      {
        name: "share.popup.template.valueHypothesis.body",
        label: s(
          "updateBranding.page.stringsForm.values.valueHypothesisEmailTemplate.body.label",
          "Value Hypothesis Email Template - Body"
        )
      },
      {
        name: "share.popup.template.roi.subject",
        label: s(
          "updateBranding.page.stringsForm.values.roiEmailTemplate.subject.label",
          "ROI Email Template - Subject"
        )
      },
      {
        name: "share.popup.template.roi.body",
        label: s(
          "updateBranding.page.stringsForm.values.roiEmailTemplate.body.label",
          "ROI Email Template - Body"
        )
      }
    ]
  },
  {
    groupId: 7,
    groupName: s("updateBranding.page.stringsForm.group7.name", "Web Report"),
    values: [
      {
        name: "report.page.subheader2",
        label: s(
          "updateBranding.page.stringsForm.values.webReportSubHeader.label",
          'Web Report (Challenges, KPIs, Value Hypothesis) Intro Sub Header ("Sell Outcomes...")'
        )
      },
      {
        name: "report.end.header",
        label: s(
          "updateBranding.page.stringsForm.values.webReportEndMessage.label",
          "Web Report, End Message"
        )
      }
    ]
  }
];

export default getStringsFormConfig;
