const getSalesforceAuthenticationBaseUrl = ({
  baseUrl,
  salesforceIntegrationV2,
  salesforceSandbox
}) => {
  if (!salesforceIntegrationV2) {
    return baseUrl;
  }

  if (salesforceSandbox) {
    return "https://test.salesforce.com";
  }

  return "https://login.salesforce.com";
};

export default getSalesforceAuthenticationBaseUrl;
