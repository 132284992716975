import Form, { FormItem } from "../../../../components/Form";
import { useString as s } from "../../../../components/StringProvider";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Input";
import ButtonGroup from "../../../../components/ButtonGroup";
import NewButton from "../../../../components/NewButton";
import { selectIsCuvamaUser, selectRoles } from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { getRoles } from "../../../../store/actions/roles";
import { connect } from "react-redux";
import getRoleFormConfig from "./role-form-config";
import GroupsOfCheckBoxes from "../../../../components/GroupsOfCheckBoxes";

const getDependenciesMap = ({ groups }) => {
  const map = {};

  for (const group of groups) {
    for (const permission of group.items) {
      if (permission.dependsOn) {
        map[permission.dependsOn] = permission.name;
      }
    }
  }

  return map;
};

const RoleForm = ({
  form,
  onFinish,
  submitButtonText,
  children,
  isBlessedRole = false,
  isCuvamaUser,
  initialPermissions
}) => {
  const roleNameText = s(
    "accessManagement.page.roleForm.roleName.label",
    "Role name"
  );
  const roleNamePlaceholder = s(
    "accessManagement.page.roleForm.roleName.placeholder",
    "The role name"
  );
  const descriptionText = s(
    "accessManagement.page.roleForm.description.label",
    "Description"
  );
  const descriptionPlaceholder = s(
    "accessManagement.page.roleForm.description.placeholder",
    "The role description"
  );
  const roleNameValidation = s(
    "accessManagement.page.roleForm.roleName.validation",
    "Role name is required"
  );

  const roleFormConfigGroups = getRoleFormConfig({ s, isCuvamaUser });
  const fullInputWidth = "673px";
  const dependenciesMap = getDependenciesMap({ groups: roleFormConfigGroups });
  const [currentPermissions, setCurrentPermissions] = useState("");

  useEffect(() => {
    if (initialPermissions) {
      const permissions = [...initialPermissions];

      permissions.sort();
      setCurrentPermissions(permissions.join(","));
    }
  }, [initialPermissions]);

  const onFieldsChange = (changedFields) => {
    const permissions = changedFields.find((field) =>
      field.name.includes("permissions")
    );

    if (permissions) {
      const newPermissions = [...permissions.value];

      newPermissions.sort();
      const removedPermission = currentPermissions
        .split(",")
        .find((p) => !newPermissions.includes(p));

      if (removedPermission && dependenciesMap[removedPermission]) {
        form.setFieldsValue({
          permissions: newPermissions.filter(
            (p) => p !== dependenciesMap[removedPermission]
          )
        });
      }

      setCurrentPermissions(newPermissions.join(","));
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name={"roleForm"}
      onFieldsChange={onFieldsChange}
    >
      <FormItem
        name="name"
        label={roleNameText}
        style={{ maxWidth: "464px" }}
        rules={[{ required: true, message: roleNameValidation }]}
      >
        <Input placeholder={roleNamePlaceholder} disabled={isBlessedRole} />
      </FormItem>
      <FormItem name="description" label={descriptionText}>
        <Input
          placeholder={descriptionPlaceholder}
          style={{ maxWidth: { inputWidth: fullInputWidth } }}
          disabled={isBlessedRole}
        />
      </FormItem>
      <FormItem name="permissions">
        <GroupsOfCheckBoxes
          groups={roleFormConfigGroups}
          selectedItems={currentPermissions}
        />
      </FormItem>
      <FormItem>
        <ButtonGroup>
          <NewButton type={"submit"} data-cy={"role-form-save"}>
            {submitButtonText}
          </NewButton>
          {children}
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

RoleForm.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  initialPermissions: PropTypes.array
};

const mapStateToProps = (state) => ({
  roles: selectRoles(state),
  isCuvamaUser: selectIsCuvamaUser(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRoles
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(RoleForm);
