import Input from "./Input";
export { default as CurrencyInput } from "./CurrencyInput";
export { default as SliderInput } from "./SliderInput";
export { default as NumberInput } from "./NumberInput";
export { default as EmailInput } from "./EmailInput";
export { default as PhoneInput } from "./PhoneInput";
export { default as InputGroup } from "./InputGroup";
export { default as PasswordInput } from "./PasswordInput";
export { default as TextArea } from "./TextArea";
export { default as RichTextEditor } from "./RichTextEditor";

export default Input;
