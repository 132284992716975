import { Input } from "antd";
import styled from "styled-components";
import { themeProp } from "../../theme";

const { TextArea: ANTTextArea } = Input;

const TextArea = styled(ANTTextArea).attrs(() => ({
  bordered: false
}))`
  background: transparent;
  color: ${themeProp("palette.text")};
  transition: background 0;
  border-bottom: 1px solid ${themeProp("palette.secondary")};
  font-size: ${themeProp("typography.body.fontSize")};
  line-height: ${themeProp("typography.body.lineHeight")};
  border-radius: 0;
  transition: border-color 0.2s ease-out;

  &:hover {
    border-bottom: 1px solid ${themeProp("palette.secondary")};
  }
  &:focus {
    border-bottom: 1px solid ${themeProp("palette.secondary")};
    background-color: ${themeProp("palette.surface")};
  }

  &.ant-input {
    resize: none;
  }
`;

export default TextArea;
