import httpService from "./http.service";

const getSettings = () =>
  httpService
    .get(`/settings`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getUserSettings = () =>
  httpService
    .get(`/settings/me`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const setUserSettings = (data) =>
  httpService
    .patch(`/settings/me`, data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getSettings,
  getUserSettings,
  setUserSettings
};
export default methods;
