import React, { useCallback, useEffect, useState } from "react";
import { MobileOrTablet, useMobileOrTabletMediaQuery } from "../Responsive";
import { bindActionCreators, compose } from "redux";
import {
  hasPermission,
  selectCookie,
  selectDiscoveryId,
  selectDiscoveryQuestions,
  selectRequestData
} from "../../store/reducers";
import Permissions from "../../utils/permissions";
import { connect } from "react-redux";
import DiscoveryNotesDrawer from "./DiscoveryNotesDrawer";
import Icon from "../Icon";
import NewButton from "../NewButton";
import { useString as s } from "../StringProvider";
import openInNewWindow from "../../utils/open-in-new-window";
import errorService from "../../services/errors.service";
import { message } from "antd";
import DiscoveryNotesPrompt from "./DiscoveryNotesPrompt";
import { useLocation } from "react-router-dom";
import { isLastDiscoveryQuestion } from "../../store/selectors/discovery";
import actionTypes from "../../store/actionTypes";
import { getNotesTemplate } from "../../store/actions/templates";
import { getDiscoveryNote } from "../../store/actions/notes";
import pusherService from "../../services/pusher.service";
import ChannelNames from "../../utils/channel-names";
import httpService from "../../services/http.service";
import PusherEventNames from "../../utils/constants/pusher-event-names";

const getNoteLink = ({ discoveryId }) => {
  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;

  return `${protocol}//${host}${
    port && port !== 443 && port !== 80 ? ":" + port : ""
  }/discoveries/${discoveryId}/notes`;
};

const DiscoveryNotesButton = ({
  canSeeDiscoveryNotesButton,
  discoveryId,
  allQuestions,
  cookie,
  getDiscoveryNote,
  getNotesTemplate,
  discoveryNotes,
  notesTemplate
}) => {
  const tooltipText = s("notes.button", "Open notes");
  const popupsBlocked = s(
    "notes.popup.blocked",
    "A popup blocker may have prevented us from opening the page. Try disabling it to open the window."
  );
  const [visible, setVisible] = useState(false);
  const isMobileOrTablet = useMobileOrTabletMediaQuery();
  const [showPrompt, setShowPrompt] = useState(true);
  const [questionNumber, setQuestionNumber] = useState(undefined);
  const { pathname } = useLocation();
  const tenantId = httpService.getTenantId();

  useEffect(() => {
    const matches = pathname?.match(
      /^\/discoveries\/[^\/]+\/questions\/(\d+)$/
    );

    setQuestionNumber(matches ? matches[1] : undefined);
  }, [pathname]);

  useEffect(() => {
    getNotesTemplate();
    getDiscoveryNote({ discoveryId });
  }, [discoveryId]);

  useEffect(() => {
    pusherService.subscribe({
      channelName: ChannelNames.DISCOVERY_NOTES({ discoveryId, tenantId }),
      eventName: PusherEventNames.UPDATE,
      callback: onUpdateNote
    });

    return () => {
      pusherService.unsubscribe({
        channelName: ChannelNames.DISCOVERY_NOTES({ discoveryId, tenantId }),
        eventName: PusherEventNames.UPDATE,
        callback: onUpdateNote
      });
    };
  }, [discoveryId]);

  useEffect(() => {
    const result =
      !cookie &&
      questionNumber &&
      allQuestions &&
      isLastDiscoveryQuestion(allQuestions, parseInt(questionNumber)) &&
      !!notesTemplate &&
      !discoveryNotes?.text;

    setShowPrompt(result);
  }, [cookie, questionNumber, allQuestions, discoveryNotes, notesTemplate]);

  const onUpdateNote = () => {
    getDiscoveryNote({ discoveryId });
  };

  const onClose = () => {
    setVisible(false);
  };

  const onClick = useCallback(() => {
    if (isMobileOrTablet) {
      setVisible(true);
    } else {
      const url = getNoteLink({ discoveryId });

      if (
        !openInNewWindow({
          url,
          left: 24,
          height: 600,
          top: Math.max(0, window.screen.availHeight - 600 - 24 - 30)
        })
      ) {
        errorService.logError({
          category: "discovery-notes-button",
          data: `Popup blocked: ${url}`
        });
        message.error(popupsBlocked);
      }
    }
  }, [isMobileOrTablet]);

  const renderButton = () => {
    return showPrompt ? (
      <DiscoveryNotesPrompt onClick={onClick} />
    ) : (
      <NewButton type={"feedback"} tooltip={tooltipText} onClick={onClick}>
        <Icon name={"notes"} size={"large"} />
      </NewButton>
    );
  };

  if (!canSeeDiscoveryNotesButton) {
    return null;
  }

  return (
    <>
      <MobileOrTablet>
        <DiscoveryNotesDrawer
          visible={visible}
          discoveryId={discoveryId}
          onClose={onClose}
        />
      </MobileOrTablet>
      {renderButton()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    canSeeDiscoveryNotesButton: hasPermission(
      state,
      Permissions.SEE_DISCOVERY_NOTES
    ),
    discoveryId: selectDiscoveryId(state),
    allQuestions: selectDiscoveryQuestions(state),
    cookie: selectCookie(state, "DiscoveryNotesPrompt"),
    notesTemplate: selectRequestData(
      state,
      actionTypes.GET_NOTES_TEMPLATE_REQUEST
    ),
    discoveryNotes: selectRequestData(state, actionTypes.GET_NOTE_REQUEST)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotesTemplate,
      getDiscoveryNote
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DiscoveryNotesButton
);
