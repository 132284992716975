import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { DiscoveryFooter, DiscoveryTitleBar } from "../../components/Discovery";
import { useString as s } from "../../components/StringProvider";
import Heading from "../../components/Heading";
import { selectDiscoveryId, selectDiscoveryName } from "../../store/reducers";
import ChallengeSummaryCards from "./ChallengeSummaryCards";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import {
  FinishDiscoveryButton,
  GoToSelectKPIsButton
} from "../../components/DiscoveryButtons";
import { useMobileMediaQuery } from "../../components/Responsive";
import styled from "styled-components";
import DiscoverySubHeader from "../Discovery/DiscoverySubHeader";
import { Phases } from "../../utils/phases";

const ChallengeSummary = ({ discoveryId, discoveryName }) => {
  const isMobile = useMobileMediaQuery();
  const header = s(
    "discovery.challenges.summary.page.header",
    "Challenges to be solved for {discoveryName}",
    { discoveryName }
  );

  const link1Text = s(
    "discovery.challenges.summary.page.navigation.subheader.link1",
    "Edit Challenges"
  );

  const link2Text = s(
    "discovery.challenges.summary.page.navigation.subheader.link2",
    "Summary"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const linkConfig = [
    {
      to: `/discoveries/${discoveryId}/select-challenges`,
      label: link1Text
    },
    {
      to: `/discoveries/${discoveryId}/challenge-summary`,
      label: link2Text
    }
  ];

  return (
    <DiscoveryContentWrapper format={"wide"} isMobile={isMobile}>
      <DiscoverySubHeader
        phase={Phases.CHALLENGES_SOLVED}
        linkConfig={linkConfig}
      />

      <DiscoveryTitleBar>
        <Header className={isMobile ? "mobile" : undefined}>
          <Heading level={isMobile ? "h3" : "h2"}>{header}</Heading>
        </Header>
      </DiscoveryTitleBar>

      <ChallengeSummaryCards />

      <DiscoveryFooter>
        <GoToSelectKPIsButton />
        <FinishDiscoveryButton />
      </DiscoveryFooter>
    </DiscoveryContentWrapper>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;

  &.mobile {
    text-align: center;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 8px;
  }
`;

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  discoveryName: selectDiscoveryName(state)
});

export default compose(connect(mapStateToProps))(ChallengeSummary);
