import httpService from "./http.service";

const requestAccess = ({ discoveryId, message }) =>
  httpService
    .post(`/access/`, { discoveryId, message })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getAccess = ({ accessId }) =>
  httpService
    .get(`/access/${accessId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const grantAccess = ({ accessId }) =>
  httpService
    .post(`/access/${accessId}/grant`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  requestAccess,
  getAccess,
  grantAccess
};

export default methods;
