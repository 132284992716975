const ValidationStatuses = {
  READY: "ready",
  VALIDATING: "validating",
  VALIDATED: "validated",
  VALIDATED_WITH_ERRORS: "validatedWithErrors",
  FAILED: "failed"
};

ValidationStatuses.ALL = Object.values(ValidationStatuses);

module.exports = ValidationStatuses;
