import { Mobile, useMobileMediaQuery } from "../../components/Responsive";
import { BasicBrandHeader } from "../../components/Layout";
import styled from "styled-components";
import { themeProp } from "../../theme";
import { Text } from "../../components";

const LoginFormContainer = ({ children }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <OuterContainer className={isMobile ? "mobile" : undefined}>
      <Mobile>
        <BasicBrandHeader />
      </Mobile>
      <Container className={isMobile ? "mobile" : undefined}>
        {children}
      </Container>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & h2 {
    text-align: center;
    margin-bottom: 32px;
  }

  &.mobile {
    margin-top: 64px;
    padding-bottom: 20px;
    min-width: fit-content;
  }

  & .login-link {
    font-weight: ${themeProp("typography.button.fontWeight")};
    font-family: ${themeProp("typography.button.fontFamily")};
    font-size: ${themeProp("typography.button.fontSize")};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 550px;
  min-width: 550px;
  flex-grow: 1;

  & form {
    width: 100%;
  }

  & .ant-form-item-explain-error {
    text-align: left;
  }

  &.mobile {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: stretch;
    max-width: unset;
    min-width: unset;
  }

  & > * {
    margin-bottom: 32px;
  }

  &.mobile > * {
    text-align: center;
  }

  &.mobile .ant-form-item-explain {
    text-align: left;
  }

  &.mobile > *:last-child {
    margin-bottom: 0px;
  }

  & .subheader {
    margin-bottom: 32px;
  }

  & form .button-group {
    margin-top: 32px;
  }
`;

export const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h2 {
    margin-bottom: 8px !important;
  }
`;

export const ResendContainer = styled(Text)`
  margin-top: 32px;

  button {
    padding: 0 !important;
    background-color: transparent !important;
  }
`;

export default LoginFormContainer;
