import httpService from "./http.service";

const downloadAllPowerpointFileDeployments = () =>
  httpService
    .post(`/deployments/powerpoint/download`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  downloadAllPowerpointFileDeployments
};

export default methods;
