import styled from "styled-components";
import { themeProp } from "../../../theme";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowRight } from "../../../assets/ArrowRight.svg";
import { useMobileMediaQuery } from "../../Responsive";

const ChallengeLink = ({ link }) => {
  const isMobile = useMobileMediaQuery();
  return (
    <FormattedLink
      href={link.url}
      target={"_blank"}
      rel={"noopener noreferrer"}
      className={isMobile ? "mobile" : undefined}
    >
      {link.text}
      <ArrowRight />
    </FormattedLink>
  );
};

const FormattedLink = styled.a`
  font-family: ${themeProp("typography.bMedium.fontFamily")};
  font-size: ${themeProp("typography.bMedium.fontSize")};
  line-height: ${themeProp("typography.bMedium.lineHeight")};
  font-weight: ${themeProp("typography.bMedium.fontWeight")};

  &.mobile {
    font-family: ${themeProp("typography.bMediumMobile.fontFamily")};
    font-size: ${themeProp("typography.bMediumMobile.fontSize")};
    line-height: ${themeProp("typography.bMediumMobile.lineHeight")};
    font-weight: ${themeProp("typography.bMediumMobile.fontWeight")};
  }
  cursor: pointer;
  color: ${themeProp("palette.primary")};
  display: block;
  margin-bottom: 12px;
  position: relative;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    color: ${themeProp("components.button.primary.hover")};
  }
  &:focus {
    color: ${themeProp("components.button.primary.focus")};
  }
  &:focus-visible {
    color: ${themeProp("components.button.primary.focus")};
  }

  & svg {
    position: relative;
    top: 0.125em;
    height: 1em;
    width: 1em;
    margin-left: 4px;
    path {
      fill: currentColor;
    }
  }
`;

ChallengeLink.propTypes = {
  link: PropTypes.object.isRequired
};

export default ChallengeLink;
