import PropTypes from "prop-types";
import { selectCategory } from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import Badge from "./Badge";
import { themeProp } from "../../theme";

const CategoryBadge = ({ categoryCode, category, variant, disabled }) => {
  return (
    <Badge
      bg={disabled ? themeProp("palette.gray2") : category?.color}
      color={
        category?.textColor === "#FFFFFF" ? "surface" : category?.textColor
      }
      variant={variant}
      className={"category-badge"}
      data-cy={`category-badge-${categoryCode}`}
    >
      {category?.name || categoryCode}
    </Badge>
  );
};

CategoryBadge.propTypes = {
  categoryCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["small", "micro"])
};

CategoryBadge.defaultProps = {
  variant: "small"
};

const mapStateToProps = (state, props) => ({
  category: selectCategory(state, props.categoryCode)
});

export default compose(connect(mapStateToProps))(CategoryBadge);
