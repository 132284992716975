import { useString as s } from "../../../../../components/StringProvider";
import { ReactComponent as Icon } from "../../../../../assets/editor/type-underline.svg";
import ToolbarButton from "./ToolbarButton";

const UnderlineButton = ({ onClick, active }) => {
  return (
    <ToolbarButton
      active={active}
      onClick={onClick}
      label={s("editor.buttons.underline.label", "Format Underline")}
    >
      <Icon />
    </ToolbarButton>
  );
};

export default UnderlineButton;
