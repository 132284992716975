import types from "../actionTypes";

const INITIAL_STATE = {
  fullscreen: false
};

const fullscreen = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.payload
      };

    default:
      return state;
  }
};

export default fullscreen;

export const isFullscreen = (fullscreenData) => fullscreenData.fullscreen;
