import styled from "styled-components";
import { Drawer } from "antd";
import { themeProp } from "../../theme";
import { useMobileMediaQuery, useWindowDimensions } from "../Responsive";

const LibraryDrawer = ({ children, ...props }) => {
  const mobile = useMobileMediaQuery();
  const { width } = useWindowDimensions();

  return (
    <StyledDrawer
      contentWrapperStyle={{
        marginTop: mobile ? "100px" : "140px",
        width: mobile ? width : "378px",
        maxWidth: mobile ? width : "378px",
        minWidth: mobile ? width : "378px"
      }}
      {...props}
    >
      {children}
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer).attrs(() => ({
  maskClosable: false,
  mask: false,
  closeIcon: false,
  placement: "right"
}))`
  font-size: ${themeProp("typography.body.fontSize")};
  & .ant-drawer-header {
    border-bottom: 0;
  }
  & .ant-drawer-header-title {
    flex-direction: column;
    align-items: stretch;
  }

  & .ant-drawer-content-wrapper {
    box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  }

  & .ant-drawer-footer {
    border-top: 0;
    box-shadow: 0 0 4px rgba(5, 37, 67, 0.08);
  }
  & .ant-drawer-body {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${themeProp("palette.gray4")};
    }

    margin-right: 16px;
    padding: 0 8px 24px 24px;
  }
`;

export default LibraryDrawer;
