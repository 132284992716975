import types from "../actionTypes";
import featuresService from "../../services/features.service";

export const getFeatures = () => (dispatch) => {
  dispatch({
    type: types.GET_FEATURES_REQUEST
  });

  return featuresService
    .getFeatures()
    .then((payload) => {
      dispatch({
        type: types.GET_FEATURES_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_FEATURES_FAILURE,
        error
      });
    });
};

export const updateFeatures =
  ({ changes }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_FEATURES_REQUEST
    });

    return featuresService
      .updateFeatures({ changes })
      .then((payload) => {
        dispatch({
          type: types.UPDATE_FEATURES_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_FEATURES_FAILURE,
          error
        });
      });
  };
