import React from "react";
import styled from "styled-components";
import { themeProp } from "../theme";

const Heading = ({ level, ...props }) => {
  const transformLevel = (level) => {
    if (level === "h5Small") {
      return "h5";
    }

    return level;
  };

  return <StyledDiv as={transformLevel(level)} level={level} {...props} />;
};

const StyledDiv = styled.div`
  font-size: ${(props) => themeProp(`typography.${props.level}.fontSize`)};
  font-family: ${(props) => themeProp(`typography.${props.level}.fontFamily`)};
  font-weight: ${(props) =>
    props.bold ? "700" : themeProp(`typography.${props.level}.fontWeight`)};
  line-height: ${(props) => themeProp(`typography.${props.level}.lineHeight`)};
  text-align: ${(props) => `${props.align}`};
  color: ${(props) => themeProp(`palette.${props.color}`)};
  ${(props) =>
    props.textOverflow ? `text-overflow: ${props.textOverflow};` : ""}
  ${(props) => (props.textOverflow ? `overflow: hidden;` : "")}
    ${(props) => (props.level.match(/^h5/) ? `text-transform: uppercase;` : "")}
  ${(props) => (props.useEllipsis ? `white-space: nowrap;` : "")}
  ${(props) => (props.useEllipsis ? `text-overflow: ellipsis;` : "")}
  ${(props) => (props.useEllipsis ? `overflow: hidden;` : "")}
  ${(props) => (props.useEllipsis ? `padding-left: 16px;` : "")}
  ${(props) => (props.useEllipsis ? `padding-right: 16px;` : "")}
`;

Heading.defaultProps = {
  level: "h2",
  align: "initial",
  color: "initial"
};

export default Heading;
