import { bindActionCreators, compose } from "redux";
import { deactivateUser } from "../../../../store/actions/users";
import { connect } from "react-redux";
import React from "react";
import { useString as s } from "../../../../components/StringProvider";
import { message } from "antd";
import PropTypes from "prop-types";
import {
  selectRequestError,
  selectRequestState
} from "../../../../store/reducers";
import NewButton from "../../../../components/NewButton";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import ButtonGroup from "../../../../components/ButtonGroup";
import { Modal } from "../../../../components";

const DeactivateUserModal = ({
  userId,
  loadingState,
  error,
  onClose,
  deactivateUser
}) => {
  const submitText = s(
    "accessManagement.page.users.deactivate.submit",
    "Yes, deactivate"
  );
  const cancelText = s(
    "accessManagement.page.users.deactivate.cancel",
    "Don't deactivate"
  );
  const errorText = s(
    "accessManagement.page.users.deactivate.error",
    "Failed to deactivate User"
  );
  const successText = s(
    "accessManagement.page.users.deactivate.success",
    "User was successfully deactivated"
  );

  const onSubmit = () => {
    deactivateUser({ userId });
  };

  useLoadingState(
    loadingState,
    () => {
      onClose();
      message.success(successText);
    },
    () => {
      if (error) {
        message.error(errorText);
      }
    }
  );

  return (
    <Modal
      title={s(
        "accessManagement.popup.deactivateUser.header",
        "Deactivate User"
      )}
      open={true}
      onCancel={onClose}
    >
      <ButtonGroup>
        <NewButton
          type={"submit"}
          onClick={onSubmit}
          data-cy={"deactivate-user"}
        >
          {submitText}
        </NewButton>
        <NewButton
          type={"secondary"}
          onClick={onClose}
          data-cy={"dont-deactivate-user"}
        >
          {cancelText}
        </NewButton>
      </ButtonGroup>
    </Modal>
  );
};

DeactivateUserModal.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.USERS_DEACTIVATE_REQUEST),
  error: selectRequestError(state, actionTypes.USERS_DEACTIVATE_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deactivateUser
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeactivateUserModal
);
