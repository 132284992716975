import types from "../actionTypes";

const INITIAL_STATE = {
  challengeCode: null
};

const deleteChallengesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.START_DELETING_CHALLENGE:
      return {
        ...state,
        challengeCode: action.payload.challengeCode
      };

    case types.STOP_DELETING_CHALLENGE:
      return {
        ...state,
        challengeCode: null
      };

    default:
      return state;
  }
};

export default deleteChallengesReducer;

export const selectDeleteChallengeCode = (data) => data.challengeCode;
