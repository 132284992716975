import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import settingService from "../services/settings.service";

export const SettingsContext = createContext();
export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({});
  const getSetting = (key, defaultValue) => _.get(settings, key, defaultValue);

  useEffect(() => {
    settingService
      .getSettings()
      .then((settings) => {
        setSettings({ ...settings });
      })
      .catch((error) => {
        console.error("setting retrival failed", error);
      });
  }, []);
  return (
    <SettingsContext.Provider
      value={{
        settings,
        getSetting
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
export const useSetting = (key, defaultValue) => {
  const { settings } = useContext(SettingsContext);
  return _.get(settings, key, defaultValue);
};

export default SettingsProvider;
