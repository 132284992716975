import MetricTypes from "./metric-types";
import KPIImpactFormats from "./kpi-impact-formats";
import NumberParser from "./NumberParser";
import { useStringTemplate } from "../components/StringProvider";
import { IntlMessageFormat } from "intl-messageformat";
import { useSetting } from "../components/SettingsProvider";

const deleteNullOrUndefinedField = (data, field) => {
  if (data[field] === null || data[field] === undefined) {
    delete data[field];
  }
};

const dateTimeFormat = (date) => {
  const locale = useSetting("locale", "en-GB");
  const d = new Date(date);
  const month = new Intl.DateTimeFormat(locale, { month: "short" }).format(d);
  const day = new Intl.DateTimeFormat(locale, { day: "2-digit" }).format(d);

  return `${month} ${day} ${String(d.getHours()).padStart(2, "0")}:${String(
    d.getMinutes()
  ).padStart(2, "0")}`;
};

const dateTimeYearFormat = (date) => {
  const locale = useSetting("locale", "en-GB");
  const d = new Date(date);
  const year = new Intl.DateTimeFormat(locale, { year: "numeric" }).format(d);
  const month = new Intl.DateTimeFormat(locale, { month: "short" }).format(d);
  const day = new Intl.DateTimeFormat(locale, { day: "numeric" }).format(d);

  return `${day} ${month}, ${year}, ${String(d.getHours()).padStart(
    2,
    "0"
  )}:${String(d.getMinutes()).padStart(2, "0")}`;
};

const timeSince = (createdAt) => {
  const d = new Date(createdAt);
  const seconds = Math.floor((new Date() - d) / 1000);
  const locale = useSetting("locale", "en-GB");

  if (seconds > 60 * 60 * 24) {
    const month = new Intl.DateTimeFormat(locale, { month: "short" }).format(d);
    const day = new Intl.DateTimeFormat(locale, { day: "2-digit" }).format(d);

    return `${month} ${day} ${String(d.getHours()).padStart(2, "0")}:${String(
      d.getMinutes()
    ).padStart(2, "0")}`;
  }

  if (seconds > 60 * 60) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  }

  if (seconds > 60) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  }

  return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
};

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const formatPercentage = ({ value, options = {} }) => {
  const { showPlusSign } = options;

  if (value === null || value === undefined) {
    return "";
  }

  return value >= 0 ? `${showPlusSign ? "+" : ""}${value}%` : `${value}%`;
};

const formatPercentageWithDP = ({ value, options = {} }) => {
  const { locale = "en-GB" } = options;
  const { showPlusSign } = options;
  const format = new Intl.NumberFormat(locale, {
    style: "decimal",
    notation: "standard",
    minimumFractionDigits: options.minimumFractionDigits,
    maximumFractionDigits: options.maximumFractionDigits
  }).format;

  if (value === null || value === undefined) {
    return "";
  }

  return value >= 0
    ? `${showPlusSign ? "+" : ""}${format(value)}%`
    : `${format(value)}%`;
};

const formatPercentage1DP = ({
  value,
  options = { minimumFractionDigits: 1, maximumFractionDigits: 1 }
}) => {
  return formatPercentageWithDP({ value, options });
};

const formatNumber = ({ value, options }) => {
  const { locale = "en-GB", notation = "standard" } = options;
  const { minimumFractionDigits } = options;
  const format = new Intl.NumberFormat(locale, {
    notation,
    minimumFractionDigits
  }).format;

  return format(value);
};

const applyStringTemplate = ({ template, placeholders }) => {
  return new IntlMessageFormat(template, "en-US").format(placeholders);
};

const formatMaxDP = ({
  value,
  locale = "en-GB",
  maximumFractionDigits = 1,
  notation = "standard"
}) => {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: "decimal",
    notation,
    maximumFractionDigits
  }).format;

  return numberFormat(value);
};

const formatUnit = ({ value, options = {}, unitType }) => {
  const {
    showPlusSign,
    showUnits,
    locale = "en-GB",
    notation = "standard"
  } = options;

  if (value === null || value === undefined) {
    return "";
  }

  const valueDP = formatMaxDP({ value, locale, notation });

  const unitTemplates = {};
  unitTemplates[KPIImpactFormats.FTE] = useStringTemplate(
    "formats.FTE",
    "{value} FTE"
  );
  unitTemplates[KPIImpactFormats.HOURS] = useStringTemplate(
    "formats.Hours",
    "{value} Hours"
  );
  unitTemplates[KPIImpactFormats.WEEKS] = useStringTemplate(
    "formats.Weeks",
    "{value} Weeks"
  );
  unitTemplates[KPIImpactFormats.PERCENTAGE_POINTS] = useStringTemplate(
    "formats.PercentagePoints",
    "{value} pp"
  );

  const _unit = applyStringTemplate({
    template: unitTemplates[unitType],
    placeholders: { value: valueDP }
  });

  const valueUnit = showUnits ? " " + _unit : valueDP;

  return value >= 0 ? `${showPlusSign ? "+" : ""}${valueUnit}` : valueUnit;
};

const formatCustom = ({ value, options = {} }) => {
  const {
    showPlusSign,
    showUnits,
    customImpactFormat,
    notation = "standard",
    locale = "en-GB"
  } = options;

  if (value === null || value === undefined) {
    return "";
  }

  const valueDP = formatMaxDP({
    value,
    locale,
    maximumFractionDigits: 0,
    notation
  });

  return `${showPlusSign ? "+" : ""}${
    !showUnits
      ? valueDP
      : customImpactFormat
      ? customImpactFormat.replace("{impact}", valueDP)
      : valueDP
  }`;
};

const formatRangeNumber = ({ value, type, options }) => {
  switch (type) {
    case MetricTypes.PERCENTAGE:
      return Number.isInteger(value)
        ? formatPercentage({ value, options })
        : formatPercentage1DP({ value, options });
    case MetricTypes.PERCENTAGE1DP:
      return formatPercentage1DP({ value, options });
    case MetricTypes.CURRENCY:
      return formatCurrencyString({ value, settings: options, options });
    case MetricTypes.NUMBER2DP:
      return formatNumber2DP({ value, options });
    case KPIImpactFormats.MULTIPLIER:
      return formatMultiplier({ value, options });
    case KPIImpactFormats.FTE:
    case KPIImpactFormats.HOURS:
    case KPIImpactFormats.WEEKS:
    case KPIImpactFormats.PERCENTAGE_POINTS:
      return formatUnit({
        value,
        options,
        unitType: type
      });
    case KPIImpactFormats.NUMBER:
      return formatNumber({ value, options });
    case KPIImpactFormats.CUSTOM:
      return formatCustom({
        value,
        options
      });
    default:
      return value;
  }
};

const formatMetric = ({ type, value, options }) => {
  switch (type) {
    case "Currency":
      return formatCurrencyString({ value, settings: options, options });
    case "Percentage":
      return `${value}%`;
    default:
      return formatNumber({
        value,
        options
      });
  }
};

const formatMultiplier = ({ value, options }) => {
  const { locale } = options;
  const format = new Intl.NumberFormat(locale, {
    style: "decimal",
    notation: "standard",
    maximumFractionDigits: 1
  }).format;
  return `${format(value / 100)}x`;
};

const formatNumber2DP = ({ value, options }) => {
  const { locale } = options;
  const format = new Intl.NumberFormat(locale, {
    style: "decimal",
    notation: "standard",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format;
  return format(value);
};

const formatCurrencyString = ({ value, settings = {}, options = {} }) => {
  const { locale = "en-GB", currency = "GBP", trim = true } = settings;
  const { notation = "standard", currencyDisplay = "narrowSymbol" } = options;
  const format = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    notation,
    currencyDisplay
  }).format;
  const result = format(value);
  return trim
    ? result.slice(-3) === ".00"
      ? result.slice(0, -3)
      : result
    : result;
};

const getCurrencySymbol = ({ settings = {} }) => {
  const { locale = "en-GB", currency = "GBP" } = settings;
  const notation = "compact";
  const currencyDisplay = "narrowSymbol";
  const format = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    notation,
    currencyDisplay
  }).format;
  const result = format(0);
  return result.replaceAll(/\d/g, "");
};

const isNumber = (value) => {
  return !isNaN(value) && !isNaN(parseFloat(value));
};

const getPercentageFormatter = () => (value) =>
  value !== "" && value !== undefined
    ? formatPercentage({
        value
      })
    : value;

const getRangeNumberFormatter =
  ({ type, options }) =>
  (value) =>
    formatRangeNumber({
      value,
      type,
      options
    });

const getMetricFormatter =
  ({ locale, currency, type, options = { notation: "compact" } }) =>
  (value) => {
    options["locale"] = locale;
    options["currency"] = currency;

    if (!isNumber(value)) {
      return "";
    }

    return formatMetric({ type, value, options });
  };

const getMetricParser =
  ({ locale, currency }) =>
  (value) => {
    const parser = new NumberParser(locale, currency);
    const currencySymbol = getCurrencySymbol({
      settings: { locale, currency }
    });
    const trimmedValue = value
      .replace("%", "")
      .replace(currencySymbol, "")
      .trim();

    return trimmedValue ? parser.parse(trimmedValue) : value;
  };

const getMetricTooltipFormatter =
  ({ locale, currency, type }) =>
  (value) => {
    switch (type) {
      case "Currency":
        return formatCurrencyString({ value, settings: { locale, currency } });
      case "Percentage":
        return formatPercentage({ value });
      default:
        return formatNumber({ value, options: { locale } });
    }
  };

const getMetricMarkFormatter =
  ({ locale, currency, type }) =>
  (value) => {
    switch (type) {
      case "Currency":
        return formatCurrencyString({
          value,
          settings: { locale, currency },
          options: { notation: "compact" }
        });
      case "Percentage":
        return formatPercentage({ value });
      default:
        return formatNumber({
          value,
          options: { locale, notation: "compact" }
        });
    }
  };

const getCurrencyFormatter =
  ({ settings, options }) =>
  (value) =>
    value !== "" && value !== undefined
      ? formatCurrencyString({ value, settings, options })
      : value;

const formatROIPercentage = ({ value, options }) => {
  if (value > 200) {
    const { locale } = options;
    const format = new Intl.NumberFormat(locale, {
      style: "decimal",
      notation: "standard",
      maximumFractionDigits: 1
    }).format;

    return value > 1000
      ? `${format(Math.round(value / 100))}x`
      : `${format(value / 100)}x`;
  }

  return formatPercentage({ value });
};

const formatDate = ({ time, locale, separator = "/" }) => {
  const date = new Date(time);

  return date
    .toLocaleDateString(locale, {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    })
    .replaceAll("/", separator);
};

const formatConfigurationDetailDate = ({ time, locale }) => {
  const date = new Date(time);

  return date.toLocaleDateString(locale, {
    day: "numeric",
    year: "numeric",
    month: "short"
  });
};

const formatNumberWithLocale = ({ value, locale }) => {
  return value !== undefined ? value.toLocaleString(locale) : undefined;
};

export {
  formatPercentage,
  formatPercentageWithDP,
  deleteNullOrUndefinedField,
  timeSince,
  formatBytes,
  formatNumber,
  formatRangeNumber,
  formatMetric,
  formatMultiplier,
  formatNumber2DP,
  formatCurrencyString,
  getCurrencySymbol,
  getPercentageFormatter,
  getRangeNumberFormatter,
  getMetricMarkFormatter,
  getMetricTooltipFormatter,
  getMetricFormatter,
  getCurrencyFormatter,
  getMetricParser,
  formatROIPercentage,
  formatDate,
  formatConfigurationDetailDate,
  formatCustom,
  applyStringTemplate,
  formatNumberWithLocale,
  dateTimeFormat,
  dateTimeYearFormat
};
