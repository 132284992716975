import types from "../actionTypes";
import themeService from "../../services/theme.service";

export const getTheme = () => (dispatch) => {
  dispatch({
    type: types.GET_THEME_REQUEST
  });

  return themeService
    .getTheme()
    .then((payload) => {
      dispatch({
        type: types.GET_THEME_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_THEME_FAILURE,
        error
      });
    });
};
