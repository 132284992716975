import types from "../actionTypes";

const INITIAL_STATE = {
  challengeCode: null
};

const editChallengesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERIES_FETCH_REQUEST:
    case types.DISCOVERY_FETCH_REQUEST:
      return INITIAL_STATE;

    case types.START_EDITING_CHALLENGE:
      return {
        ...state,
        challengeCode: action.payload.challengeCode
      };

    case types.STOP_EDITING_CHALLENGE:
      return {
        ...state,
        challengeCode: null
      };

    default:
      return state;
  }
};

export default editChallengesReducer;

export const selectEditChallengeCode = (data) => data.challengeCode;
