import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export const useDesktopMediaQuery = () =>
  useMediaQuery({ query: "(min-width: 1024px)" });

export const useTabletMediaQuery = () =>
  useMediaQuery({ query: "(min-width: 578px) and (max-width: 1023px)" });

export const useMobileMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 577px)" });

export const useTinyMobileMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 388px)" });

export const useMobileOrTabletMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 1023px)" });

export const Desktop = ({ children }) => {
  const isDesktop = useDesktopMediaQuery();

  return isDesktop ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMobileMediaQuery();

  return isMobile ? children : null;
};

export const MobileOrTablet = ({ children }) => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();

  return isMobile || isTablet ? children : null;
};

export const DesktopOrTablet = ({ children }) => {
  const isDesktop = useDesktopMediaQuery();
  const isTablet = useTabletMediaQuery();

  return isDesktop || isTablet ? children : null;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const useCustomMediaQuery = ({
  type,
  singleBreakPoint,
  startBreakPoint,
  endBreakPoint
}) => {
  let query = "";

  switch (type) {
    case "min":
      query = `(min-width: ${singleBreakPoint}px)`;
      break;
    case "max":
      query = `(max-width: ${singleBreakPoint}px)`;
      break;
    case "between":
      query = `(min-width: ${startBreakPoint}px) and (max-width: ${endBreakPoint}px)`;
      break;
    default:
      break;
  }

  const isBreakPoint = useMediaQuery({ query });

  return { isBreakPoint };
};

useCustomMediaQuery.propTypes = {
  type: PropTypes.oneOf(["min", "max", "between"]).isRequired,
  singleBreakPoint: PropTypes.number,
  startBreakPoint: PropTypes.number,
  endBreakPoint: PropTypes.number
};
