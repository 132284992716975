import { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useComponentDimensions from "../../utils/use-component-dimensions";
import Highlighter from "../../components/Highlighter";
import { Paragraph } from "../../components/Typography";
import { Icon } from "../../components";
import Tooltip from "../../components/Tooltip";
import { themeProp } from "../../theme";

const ValueMapAutocompleteItem = ({
  children: name,
  category,
  itemCode,
  searchTerm
}) => {
  const wrapperRef = useRef(null);
  const dimensions = useComponentDimensions(wrapperRef);

  return (
    <ItemWrapper
      ref={wrapperRef}
      data-cy={`canvas-autocomplete-item-${itemCode}`}
    >
      <div>
        <div>
          <Icon
            name={"tick"}
            size={"medium"}
            colour={"gray3"}
            className="tick-icon"
          />
        </div>
        <div>
          <AutoCompleteTruncatedParagraph
            title={
              <Highlighter
                textToHighlight={name}
                searchWords={[searchTerm]}
                color={"transparent"}
                fontWeight={"bold"}
              />
            }
            color={"black"}
            maxWidth={`${dimensions.width * 0.75}`}
            variant="body"
          />
        </div>
      </div>
      <div>
        <AutoCompleteTruncatedParagraph
          title={
            <Highlighter
              textToHighlight={category}
              searchWords={[searchTerm]}
              color={"transparent"}
              fontWeight={"bold"}
            />
          }
          color={"gray3"}
          maxWidth={`${dimensions.width * 0.18}`}
          classNames="autocomplete-category"
        />
      </div>
    </ItemWrapper>
  );
};

export const AutoCompleteTruncatedParagraph = ({
  title,
  maxWidth,
  color,
  classNames,
  variant
}) => {
  const ref = useRef(null);
  const { scrollWidth, clientWidth } = useComponentDimensions(ref);

  return (
    <Tooltip title={scrollWidth <= clientWidth ? "" : title}>
      <TruncatedParagraph
        color={color}
        maxWidth={maxWidth}
        className={classNames}
        variant={variant}
        ref={ref}
      >
        {title}
      </TruncatedParagraph>
    </Tooltip>
  );
};

const ItemWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 2px 0px;

  & > * {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .autocomplete-category {
    font-size: ${themeProp("typography.small.fontSize")};
    font-weight: ${themeProp("typography.body.fontWeight")};
  }

  .tick-icon {
    svg {
      transform: translateY(3px);
    }
  }
`;

const TruncatedParagraph = styled(Paragraph)`
  max-width: ${(props) => props.maxWidth}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

ValueMapAutocompleteItem.propTypes = {
  children: PropTypes.node.isRequired,
  category: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  itemCode: PropTypes.string.isRequired
};

export default ValueMapAutocompleteItem;
