import PropTypes from "prop-types";
import { message } from "antd";
import isCopySupported from "./is-copy-supported";
import { marked } from "marked";
import removeMd from "remove-markdown";

/**
 * Custom hook for copying content to clipboard with support for HTML and plain text.
 * Cross-browser compatibility and cross-platform support
 */

const useCopyClipboard = ({
  showNotification,
  copySuccessMessage,
  copyFailedMessage
}) => {
  const copyToClipboard = async (content) => {
    try {
      if (isCopySupported && content) {
        const { ClipboardItem } = window;
        const htmlBlob = new Blob([marked(content)], { type: "text/html" });
        const textBlob = new Blob([removeMd(content)], { type: "text/plain" });

        const data = [
          new ClipboardItem({
            "text/html": htmlBlob,
            "text/plain": textBlob
          })
        ];

        await navigator.clipboard.write(data);
      } else if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(content);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = removeMd(content);
        document.body.appendChild(textArea);
        textArea.select();
        document.body.removeChild(textArea);
      }

      if (showNotification && copySuccessMessage) {
        message.success(copySuccessMessage);
      }
    } catch (err) {
      console.error("Failed to copy:", err);

      if (showNotification && copyFailedMessage) {
        message.error(copyFailedMessage);
      }
    }
  };

  return { copyToClipboard };
};

useCopyClipboard.propTypes = {
  showNotification: PropTypes.bool,
  copySuccessMessage: PropTypes.string,
  copyFailedMessage: PropTypes.string
};

export default useCopyClipboard;
