import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useString as s } from "../../components/StringProvider";
import { useTheme } from "../../components/ThemeProvider";
import Heading from "../../components/Heading";
import NewButton from "../../components/NewButton";
import { SplitPageLayout } from "../../components/Layout";
import {
  DesktopOrTablet,
  Mobile,
  useMobileMediaQuery,
  useTinyMobileMediaQuery
} from "../../components/Responsive";
import ButtonGroup from "../../components/ButtonGroup";
import MobilePageCentredLayout from "../../components/Layout/MobilePageCentredLayout";

const StartDiscovery = ({ match, history }) => {
  const { discoveryId } = match.params;
  const isMobile = useMobileMediaQuery();
  const isTinyMobile = useTinyMobileMediaQuery();

  const onStartClick = () => {
    history.push({
      pathname: `/discoveries/${discoveryId}/questions/0`
    });
  };

  const content = (
    <Container className={isMobile ? "mobile" : undefined}>
      <Heading level={isTinyMobile ? "h3" : isMobile ? "h2" : "h1"}>
        {s(
          "start_discovery.page.header",
          "Explore how we help companies like you"
        )}
      </Heading>
      <Heading level={isMobile ? "body" : "h3"}>
        {s(
          "start_discovery.page.subheader",
          "Answer a few questions to discover the business challenges we will help you solve and align on your success outcomes"
        )}
      </Heading>

      <ButtonContainer className={isMobile ? "mobile" : undefined}>
        <ButtonGroup>
          <NewButton
            type={"primary"}
            onClick={onStartClick}
            data-cy={"lets-start-button"}
            className={"up-lets-start-button"}
          >
            {s("start_discovery.page.button", "Let's start")}
          </NewButton>
        </ButtonGroup>
      </ButtonContainer>
    </Container>
  );

  return (
    <>
      <DesktopOrTablet>
        <SplitPageLayout
          image={useTheme("assets.discovery_cover")}
          hasHeader={true}
        >
          {content}
        </SplitPageLayout>
      </DesktopOrTablet>
      <Mobile>
        <MobilePageCentredLayout
          hasHeader={true}
          image={useTheme("assets.discovery_cover")}
        >
          {content}
        </MobilePageCentredLayout>
      </Mobile>
    </>
  );
};

const ButtonContainer = styled.div`
  &.mobile {
    margin-top: 32px;
  }
`;

const Container = styled.div`
  padding-left: 80px;
  padding-top: 155px;

  display: flex;
  flex-direction: column;

  h1 {
    max-width: 568px;
  }

  h3 {
    max-width: 489px;
    margin: 16px 0 40px 0;
  }

  &.mobile {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  &.mobile h2 {
    text-align: center;
    margin-bottom: 16px;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 16px;
  }

  &.mobile body {
    text-align: center;
    margin-bottom: 16px;
  }
`;

export default withRouter(StartDiscovery);
