const KPIImpactFormats = {
  PERCENTAGE: "Percentage",
  MULTIPLIER: "Multiplier",
  PERCENTAGE1DP: "Percentage1DP",
  FTE: "FTE",
  HOURS: "Hours",
  WEEKS: "Weeks",
  NUMBER: "Number",
  PERCENTAGE_POINTS: "PercentagePoints",
  CUSTOM: "Custom"
};

KPIImpactFormats.ALL = Object.values(KPIImpactFormats);

export default KPIImpactFormats;
