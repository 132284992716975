import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "redux";
import { DiscoveryFooter, DiscoveryTitleBar } from "../../components/Discovery";
import MetricWidget from "./components/MetricWidget";
import { useString as s } from "../../components/StringProvider";
import {
  hasPermission,
  selectAllGroups,
  selectDiscoveryCurrency,
  selectDiscoveryGroups,
  selectDiscoveryId,
  selectDiscoveryMetricGroupCodeString,
  selectDiscoveryResetMetricError,
  selectDiscoveryUngroupedMetricCodeString
} from "../../store/reducers";
import MetricGroup from "./components/MetricGroup";
import { message } from "antd";
import Heading from "../../components/Heading";
import Permissions from "../../utils/permissions";
import ValidationTypes from "../../utils/validation-types";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import { GoToValueHypothesisButton } from "../../components/DiscoveryButtons";
import { useMobileMediaQuery } from "../../components/Responsive";
import Text from "../../components/Text";

const BusinessMetrics = ({
  discoveryId,
  currency,
  metricCodeString,
  groupCodeString,
  groups,
  discoveryGroups,
  resetMetricError
}) => {
  const isMobile = useMobileMediaQuery();
  const resetMetricErrorMessage = s(
    "discovery.metrics.reset.error",
    "Failed to reset metric"
  );
  const emptyPageText = s(
    "discovery.metrics.page.empty.text",
    "You have not selected any outcomes that need additional inputs in order to evaluate their financial impact.  Please click Next to proceed."
  );

  useEffect(() => {
    if (resetMetricError) {
      message.error(resetMetricErrorMessage);
    }
  }, [resetMetricError]);

  let hasNoErrors = true;

  for (const groupCode of Object.keys(discoveryGroups)) {
    if (
      discoveryGroups[groupCode] &&
      discoveryGroups[groupCode].validationType === ValidationTypes.ERROR
    ) {
      hasNoErrors = false;
    }
  }

  const groupCodes = groupCodeString ? groupCodeString.split(",") : [];
  const sortedGroups = groups.filter((group) =>
    groupCodes.includes(group.code)
  );
  sortedGroups.sort((a, b) => a.order - b.order);

  const hasMetrics = metricCodeString || groupCodeString;

  return (
    <DiscoveryContentWrapper isMobile={isMobile}>
      <DiscoveryTitleBar>
        <Header className={isMobile ? "mobile" : undefined}>
          <Heading level={isMobile ? "h3" : "h2"}>
            {s("discovery.metrics.page.header", "Your Situation")}
          </Heading>
          {hasMetrics && (
            <SubHeader>
              <Text variant={isMobile ? "bodyMobile" : "body"}>
                {s(
                  "discovery.metrics.page.subheader",
                  "Please review these industry averages and optionally adjust the assumptions"
                )}
              </Text>
            </SubHeader>
          )}
        </Header>
      </DiscoveryTitleBar>

      {metricCodeString ? (
        <Metrics>
          {metricCodeString.split(",").map((metricCode, index) => (
            <MetricWidget
              key={index}
              discoveryId={discoveryId}
              metricCode={metricCode}
              currency={currency}
            />
          ))}
        </Metrics>
      ) : (
        ""
      )}

      {groupCodeString ? (
        <Groups>
          {sortedGroups.map((group, index) =>
            !metricCodeString ? (
              <MetricGroup
                key={group.code}
                groupCode={group.code}
                initiallyCollapsed={index === 0 && group.collapsed}
              />
            ) : (
              <MetricGroup
                key={group.code}
                groupCode={group.code}
                initiallyCollapsed={group.collapsed}
              />
            )
          )}
        </Groups>
      ) : (
        ""
      )}

      {hasMetrics ? "" : <span>{emptyPageText}</span>}

      <DiscoveryFooter>
        {hasNoErrors || !hasMetrics ? <GoToValueHypothesisButton /> : null}
      </DiscoveryFooter>
    </DiscoveryContentWrapper>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;

  &.mobile {
    text-align: center;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 8px;
  }
`;

const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const Metrics = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Groups = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  currency: selectDiscoveryCurrency(state),
  metricCodeString: selectDiscoveryUngroupedMetricCodeString(
    state,
    hasPermission(state, Permissions.SEE_ALL_METRICS)
  ),
  groupCodeString: selectDiscoveryMetricGroupCodeString(
    state,
    hasPermission(state, Permissions.SEE_ALL_METRICS)
  ),
  resetMetricError: selectDiscoveryResetMetricError(state),
  groups: selectAllGroups(state),
  discoveryGroups: selectDiscoveryGroups(state)
});

export default compose(connect(mapStateToProps))(BusinessMetrics);
