import React from "react";
import ErrorPage from "../Pages/ErrorPage";
import errorsService from "../services/errors.service";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    errorsService
      .logError({
        data: error.message,
        error: error.message,
        stack: error.stack,
        category: "error-boundary"
      })
      .then(() => {
        console.log(error, errorInfo);
      })
      .catch((e) => {
        console.log(
          "Error while logging error to backend",
          e,
          error,
          errorInfo
        );
      });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
