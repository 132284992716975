import types from "../actionTypes";
import discoveriesService from "../../services/discoveries.service";

export const startCreatingChallenge = () => (dispatch) => {
  dispatch({
    type: types.START_CREATING_CHALLENGE
  });
};

export const stopCreatingChallenge = () => (dispatch) => {
  dispatch({
    type: types.STOP_CREATING_CHALLENGE
  });
};

export const createDiscoveryChallenge =
  ({ discoveryId, data }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_CREATE_CHALLENGE_REQUEST
    });

    return discoveriesService
      .createDiscoveryChallenge({ discoveryId, data })
      .then(({ discovery, challenge }) => {
        dispatch({
          type: types.DISCOVERY_CREATE_CHALLENGE_SUCCESS,
          payload: { discovery, challenge }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_CREATE_CHALLENGE_FAILURE,
          error
        });
      });
  };

export const deleteDiscoveryChallenge =
  ({ discoveryId, challengeCode, isCBI }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_DELETE_CHALLENGE_REQUEST,
      parameters: { discoveryId, challengeCode, isCBI }
    });

    return discoveriesService
      .deleteDiscoveryChallenge({ discoveryId, challengeCode })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_DELETE_CHALLENGE_SUCCESS,
          payload: { discovery },
          parameters: { discoveryId, challengeCode, isCBI }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_DELETE_CHALLENGE_FAILURE,
          error,
          parameters: { discoveryId, challengeCode, isCBI }
        });
      });
  };

export const restoreDiscoveryChallenge =
  ({ discoveryId, challengeCode }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_RESTORE_CHALLENGE_REQUEST,
      parameters: { discoveryId, challengeCode }
    });

    return discoveriesService
      .restoreDiscoveryChallenge({ discoveryId, challengeCode })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_RESTORE_CHALLENGE_SUCCESS,
          payload: { discovery },
          parameters: { discoveryId, challengeCode }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_RESTORE_CHALLENGE_FAILURE,
          error,
          parameters: { discoveryId, challengeCode }
        });
      });
  };
