import PropTypes from "prop-types";
import { Switch as ANTSwitch } from "antd";
import styled, { css } from "styled-components";
import { themeProp } from "../theme";

const sizeVariants = {
  large: css`
    .ant-switch {
      width: 48px;
      min-width: 48px;
      height: 26px;
    }
    .ant-switch .ant-switch-handle {
      width: 18px;
    }
    .ant-switch[aria-checked="false"] .ant-switch-handle {
      top: 4px;
      left: 6px;
      height: 18px;
      min-width: 18px;
    }
    .ant-switch-checked .ant-switch-handle::before {
      top: 2px;
      right: 2px;
      bottom: -2px;
      left: -2px;
      height: 18px;
      min-width: 18px;
    }
  `,
  medium: css`
    .ant-switch {
      width: 40px;
      min-width: 40px;
      height: 22px;
    }
    .ant-switch .ant-switch-handle {
      width: 16px;
    }
    .ant-switch[aria-checked="false"] .ant-switch-handle {
      height: 16px;
      min-width: 16px;
      transform: translate(1px, 1.1px);
    }
    .ant-switch-checked .ant-switch-handle::before {
      height: 16px;
      min-width: 16px;
      transform: translate(1px, 1px);
    }
  `
};

const ToggleContainer = styled.div`
  ${({ size }) => sizeVariants[size]}
  .ant-switch-checked {
    background-color: ${themeProp("palette.secondary")};
  }
`;

const Toggle = ({ size, ...props }) => (
  <ToggleContainer size={size}>
    <ANTSwitch {...props} />
  </ToggleContainer>
);

Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(["large", "medium"])
};

Toggle.defaultProps = {
  size: "large"
};

export default Toggle;
