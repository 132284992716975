import { useString as s } from "../../../../../components/StringProvider";
import { ReactComponent as Icon } from "../../../../../assets/editor/list-ul.svg";
import ToolbarButton from "./ToolbarButton";

const UnorderedListButton = ({ onClick, active }) => {
  return (
    <ToolbarButton
      active={active}
      onClick={onClick}
      label={s("editor.buttons.unorderedList.label", "Format Bulleted List")}
    >
      <Icon />
    </ToolbarButton>
  );
};

export default UnorderedListButton;
