import styled from "styled-components";
import ScrollingModal from "../../ScrollingModal";
import { themeProp } from "../../../theme";
import { useMobileMediaQuery } from "../../Responsive";
import { compose } from "redux";
import { connect } from "react-redux";

const KPIModal = ({ showKPIInfoBox, children, ...props }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <StyledModal
      className={isMobile ? "mobile" : undefined}
      heightOffset={showKPIInfoBox ? 115 : 0}
      {...props}
    >
      {children}
    </StyledModal>
  );
};

const StyledModal = styled(ScrollingModal)`
  padding: 21px 24px 16px 88px;
  border: 2px solid ${themeProp("palette.primary")};
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  border-radius: 12px;

  &.mobile {
    padding: 16px 16px 16px 16px;
  }
`;

const mapStateToProps = (state) => ({
  showKPIInfoBox: state.discoveryData.showKPIInfoBox
});

export default compose(connect(mapStateToProps))(KPIModal);
