import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "../../components/ThemeProvider";
import { SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import ConfirmAccountForm from "./ConfirmAccountForm";
import Form from "../../components/Form";
import {
  confirmSignUp,
  resendSignUp,
  login
} from "../../store/actions/authentication";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { selectRequestState, isAuthenticated } from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import { message } from "antd";
import { useString as s } from "../../components/StringProvider";

const ConfirmAccount = ({
  email,
  invitation,
  confirmSignUp,
  resendSignUp,
  history,
  loadingState,
  resendLoadingState,
  isAuthenticated,
  login
}) => {
  const resendSuccessful = s(
    "confirm.account.page.messages.resendCode",
    "Code resent."
  );
  const resendFailed = s(
    "confirm.account.page.messages.resendFailed",
    "Failed to resend code."
  );

  const [form] = Form.useForm();
  const [password, setPassword] = useState();

  const onFinish = async ({ code, password }) => {
    setPassword(password);
    confirmSignUp({
      email,
      confirmationCode: code,
      password
    });
  };

  const onResendCode = () => {
    resendSignUp({ username: email });
  };

  useLoadingState(
    resendLoadingState,
    () => {
      message.success(resendSuccessful);
    },
    () => {
      message.error(resendFailed);
    }
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (invitation) {
        history.push({
          pathname: `/discoveries/${invitation.discoveryId}/select-challenges`
        });
      } else {
        history.push({
          pathname: `/`
        });
      }
    }
  }, [isAuthenticated, invitation]);

  useLoadingState(loadingState, () => {
    login({ email, password });
  });

  return (
    <Page>
      <SplitPageLayout image={useTheme("assets.reset_cover")}>
        <ConfirmAccountForm
          onFinish={onFinish}
          onResendCode={onResendCode}
          form={form}
          email={email}
        />
      </SplitPageLayout>
    </Page>
  );
};

ConfirmAccount.propTypes = {
  invitation: PropTypes.object,
  email: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isAuthenticated: isAuthenticated(state),
    loadingState: selectRequestState(state, actionTypes.CONFIRM_USER_REQUEST),
    resendLoadingState: selectRequestState(
      state,
      actionTypes.RESEND_CONFIRMATION_REQUEST
    )
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmSignUp,
      resendSignUp,
      login
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfirmAccount);
