import types from "../actionTypes";
import accessService from "../../services/access.service";

export const requestAccess =
  ({ discoveryId, message }) =>
  (dispatch) => {
    dispatch({
      type: types.REQUEST_ACCESS_REQUEST
    });

    return accessService
      .requestAccess({
        discoveryId,
        message
      })
      .then((payload) => {
        dispatch({
          type: types.REQUEST_ACCESS_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.REQUEST_ACCESS_FAILURE,
          error
        });
      });
  };

export const getAccess =
  ({ accessId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_ACCESS_REQUEST
    });

    return accessService
      .getAccess({
        accessId
      })
      .then((payload) => {
        dispatch({
          type: types.GET_ACCESS_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ACCESS_FAILURE,
          error
        });
      });
  };

export const grantAccess =
  ({ accessId }) =>
  (dispatch) => {
    dispatch({
      type: types.GRANT_ACCESS_REQUEST
    });

    return accessService
      .grantAccess({
        accessId
      })
      .then((payload) => {
        dispatch({
          type: types.GRANT_ACCESS_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GRANT_ACCESS_FAILURE,
          error
        });
      });
  };
