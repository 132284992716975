import PropType from "prop-types";
import Heading from "../../Heading";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useString as s } from "../../StringProvider";
import SolutionBadge from "./SolutionBadge";
import {
  selectDiscoveryActiveSolutionCodes,
  selectMasterDataMap
} from "../../../store/reducers";

const LinkedSolutions = ({
  discoveryKPI,
  activeSolutionCodes,
  solutionsMap
}) => {
  const linkedSolutionsText = s(
    "dictionary.linkedSolutions",
    "Linked Solutions"
  );

  activeSolutionCodes.sort(
    (a, b) => solutionsMap[a].order - solutionsMap[b].order
  );

  return discoveryKPI && activeSolutionCodes.length ? (
    <>
      <Heading level={"h5"} color={"gray"}>
        {linkedSolutionsText}
      </Heading>
      <span>
        {activeSolutionCodes.map((solutionCode, index) => (
          <span key={index}>
            <SolutionBadge solutionCode={solutionCode} />{" "}
          </span>
        ))}
      </span>
    </>
  ) : null;
};

LinkedSolutions.propTypes = {
  discoveryKPI: PropType.object.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    activeSolutionCodes: selectDiscoveryActiveSolutionCodes(
      state,
      props.discoveryKPI.definition.solutionMappings
    ),
    solutionsMap: selectMasterDataMap(state, "solutions")
  };
};

export default compose(withRouter, connect(mapStateToProps))(LinkedSolutions);
