import React, { useContext } from "react";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { themeProp } from "../../../theme";
import {
  formatCurrencyString,
  formatPercentage,
  formatROIPercentage
} from "../../../utils/formatting";
import { SettingsContext } from "../../../components/SettingsProvider";
import {
  selectDiscovery,
  selectDiscoveryCashflow,
  selectDiscoveryCurrency,
  selectDiscoveryId
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { fetchDiscovery } from "../../../store/actions/discoveries";
import { connect } from "react-redux";
import { Text } from "../../../components";
import HeaderMetric from "./HeaderMetric";
import NewButton from "../../../components/NewButton";
import Icon from "../../../components/Icon";
import { Space } from "antd";
import MobileReportSection from "./MobileReportSection";
import MobileReportTitle from "./MobileReportTitle";
import { scrollToReportSection } from "./Header/ScrollUtils";
import { useMobileMediaQuery } from "../../../components/Responsive";

const ReportIntroFull = ({ discovery, discoveryCurrency, cashflow }) => {
  const isMobile = useMobileMediaQuery();
  const { settings } = useContext(SettingsContext);
  const { locale = "en-GB" } = settings;
  const { paybackPeriod, isMaxPaybackPeriod, paybackPeriodMonths } = cashflow;
  const discoverySettings = { ...settings, currency: discoveryCurrency };
  const header = s("report.page.header", "Opportunity for {discoveryName}", {
    discoveryName: discovery.name
  });
  const subheader = s(
    "report.page.subheader",
    "Discover how we will help you grow and evolve"
  );
  const metric1 = s("report.header.metric1.label", "ROI");
  const metric2 = s("report.header.metric2.label", "Payback");
  const metric3 = s("report.header.metric3.label", "Investment");
  const viewDetails = s("report.header.button1.label", "View details");
  const moreThanText = s(
    "report.header.paybackPeriod.moreThan",
    "More than {paybackPeriod, select, 1 {{paybackPeriod} Year} other {{paybackPeriod} Years}}",
    { paybackPeriod }
  );
  const lessThanText = s(
    "report.header.paybackPeriod.lessThan",
    "Approx. {paybackPeriodMonths, select, 1 {{paybackPeriodMonths} Month} other {{paybackPeriodMonths} Months}}",
    { paybackPeriod, paybackPeriodMonths }
  );

  return isMobile ? (
    <MobileReportSection
      id={"report-intro"}
      data-cy={"report-header-full"}
      className={"fullpage"}
    >
      <MobileReportTitle
        header={header}
        subheader={subheader}
        style={{ marginTop: "20px" }}
      />
      <Space size={16} direction={"vertical"}>
        <HeaderMetric label={metric1}>
          {formatPercentage({
            value: cashflow.returnOnInvestmentPercentage,
            options: { showPlusSign: true }
          })}
        </HeaderMetric>
        <HeaderMetric label={metric2}>
          {isMaxPaybackPeriod ? moreThanText : lessThanText}
        </HeaderMetric>
        <HeaderMetric label={metric3}>
          {formatCurrencyString({
            value: Math.round(cashflow.totalInvestment),
            settings: discoverySettings
          })}
        </HeaderMetric>
      </Space>
      <ButtonBar className={"mobile"}>
        <NewButton
          type={"text"}
          onClick={() => scrollToReportSection({ sectionId: "report-roi" })}
        >
          {viewDetails}
          <Icon name={"arrowDown"} />
        </NewButton>
      </ButtonBar>
    </MobileReportSection>
  ) : (
    <ReportHeaderContainer data-cy={"report-header-full"}>
      <Heading level="h1">{header}</Heading>
      <Text>{subheader}</Text>
      <ReportHeaderMetrics>
        <HeaderMetric label={metric1}>
          {formatROIPercentage({
            value: cashflow.returnOnInvestmentPercentage,
            options: { locale }
          })}
        </HeaderMetric>
        <HeaderMetric label={metric2}>
          {isMaxPaybackPeriod ? moreThanText : lessThanText}
        </HeaderMetric>
        <HeaderMetric label={metric3}>
          {formatCurrencyString({
            value: Math.round(cashflow.totalInvestment),
            settings: discoverySettings
          })}
        </HeaderMetric>
      </ReportHeaderMetrics>
      <ButtonBar>
        <NewButton
          type={"text"}
          onClick={() => scrollToReportSection({ sectionId: "report-roi" })}
        >
          {viewDetails}
          <Icon name={"arrowDown"} />
        </NewButton>
      </ButtonBar>
    </ReportHeaderContainer>
  );
};

const ReportHeaderContainer = styled.div`
  height: 480px;
  background-color: ${themeProp("palette.background")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const ReportHeaderMetrics = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: center;
`;

const ButtonBar = styled.div`
  position: absolute;
  bottom: 24px;

  &.mobile {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: unset;
    position: static;
    padding-bottom: 65px;
    padding-top: 16px;
  }
`;

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  discoveryCurrency: selectDiscoveryCurrency(state),
  discovery: selectDiscovery(state),
  cashflow: selectDiscoveryCashflow(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReportIntroFull
);
