import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  selectDiscoveryChallenges,
  selectDiscoveryId,
  selectDiscoverySetting
} from "../../store/reducers";
import { selectDiscoveryChallengeGroupMembers } from "../../store/selectors/groups-of-challenges";
import { Space } from "antd";
import ChallengeCardAndModals from "../../components/Discovery/ChallengeCard/ChallengeCardAndModals";
import styled from "styled-components";
import { CategoryName } from "../../components/Discovery";
import PropTypes from "prop-types";
import { useMobileMediaQuery } from "../../components/Responsive";
import { updateDiscoverySettingsGroup } from "../../store/actions/discoveries";
import ExpandedOrCollapsed from "../../utils/ExpandedOrCollapsed";
import { isDiscoveryGroupCollapsed } from "../../store/selectors/discovery";
import { isTypicalChallenge } from "../../utils/filters/challenge-filters";
import DiscoverySettings from "../../utils/discoverySettings";
import ShowCardOptions from "../../utils/show-card-options";
import { GroupCollapse } from "../../components";

const GroupOfChallenges = ({
  discoveryId,
  groupId,
  discoveryChallengeCodeString,
  collapsed,
  updateDiscoverySettingsGroup
}) => {
  const isMobile = useMobileMediaQuery();
  const discoveryChallengeCodes = discoveryChallengeCodeString.split(",");

  const toggleState = () => {
    updateDiscoverySettingsGroup({
      discoveryId,
      groupId,
      status: !collapsed
        ? ExpandedOrCollapsed.COLLAPSED
        : ExpandedOrCollapsed.EXPANDED
    });
  };

  const renderContents = () => {
    return (
      <Space direction={"vertical"} size={12} style={{ width: "100%" }}>
        {discoveryChallengeCodes.map((challengeCode, index) => (
          <ChallengeCardAndModals
            key={challengeCode}
            nextChallengeCode={
              index < discoveryChallengeCodes.length - 1
                ? discoveryChallengeCodes[index + 1]
                : undefined
            }
            challengeCode={challengeCode}
            editable={true}
          />
        ))}
      </Space>
    );
  };

  return (
    <Container data-cy={"group"} isMobile={isMobile}>
      <GroupCollapse
        title={<CategoryName categoryCode={groupId} />}
        onCollapse={toggleState}
        collapsed={collapsed}
      >
        {renderContents()}
      </GroupCollapse>
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${(props) => (props.isMobile ? "32px" : "0px")};
`;

GroupOfChallenges.propTypes = {
  groupId: PropTypes.string.isRequired,
  collapsedByDefault: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  const showChallenges = selectDiscoverySetting(
    state,
    DiscoverySettings.SHOW_CHALLENGES,
    ShowCardOptions.ALL_CARDS
  );
  const discoveryChallenges =
    selectDiscoveryChallenges(state)?.filter(isTypicalChallenge);
  const isGroupCollapsed = isDiscoveryGroupCollapsed(
    selectDiscoverySetting(state, "groups"),
    props.groupId
  );
  const collapsed =
    isGroupCollapsed !== undefined
      ? isGroupCollapsed
      : props.collapsedByDefault;

  return {
    discoveryChallengeCodeString: selectDiscoveryChallengeGroupMembers(
      discoveryChallenges,
      props.groupId,
      showChallenges
    ),
    collapsed,
    discoveryId: selectDiscoveryId(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettingsGroup
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupOfChallenges);
