import styled from "styled-components";
import { themeProp } from "../../../theme";
import PropType from "prop-types";

const MobileReportSection = styled.div`
  padding-top: 64px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;

  &.fullpage {
    min-height: 100vh;
    padding-left: 0px;
    padding-right: 0px;
  }

  font-family: ${themeProp("typography.bodyMobile.fontFamily")};
  font-size: ${themeProp("typography.bodyMobile.fontSize")};
  font-weight: ${themeProp("typography.bodyMobile.fontWeight")};
  line-height: ${themeProp("typography.bodyMobile.lineHeight")};
  color: ${themeProp("palette.text")};

  & h1 {
    font-family: ${themeProp("typography.h2.fontFamily")};
    font-size: ${themeProp("typography.h2.fontSize")};
    font-weight: ${themeProp("typography.h2.fontWeight")};
    line-height: ${themeProp("typography.h2.lineHeight")};
    margin-bottom: 0px;
  }

  & h2 {
    font-family: ${themeProp("typography.h3.fontFamily")};
    font-size: ${themeProp("typography.h3.fontSize")};
    font-weight: ${themeProp("typography.h3.fontWeight")};
    line-height: ${themeProp("typography.h3.lineHeight")};
    margin-bottom: 0px;
  }

  & h3 {
    margin-bottom: 0px;
  }

  & h4 {
    margin-bottom: 0px;
  }

  & h5 {
    margin-bottom: 3px;
  }

  & .ant-table-thead .ant-table-cell {
    background-color: ${themeProp("palette.gray1")};
    padding-top: 14px;
    padding-bottom: 14px;
  }

  & .ant-table-tbody .ant-table-cell {
    font-family: ${themeProp("typography.bodyMobile.fontFamily")};
    font-size: ${themeProp("typography.bodyMobile.fontSize")};
    font-weight: ${themeProp("typography.bodyMobile.fontWeight")};
    line-height: ${themeProp("typography.bodyMobile.lineHeight")};
  }

  ${({ backgroundImage }) =>
    backgroundImage ? `background-image: url("${backgroundImage}");` : ""}
  ${({ backgroundImage }) =>
    backgroundImage ? `background-size: contain;` : ""}
    ${({ backgroundImage }) =>
    backgroundImage ? `background-repeat: no-repeat;` : ""}
    ${({ backgroundImage }) =>
    backgroundImage ? `background-position: center bottom;` : ""}
`;

MobileReportSection.propTypes = {
  backgroundImage: PropType.string
};

export default MobileReportSection;
