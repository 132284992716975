import Page from "../../components/Page";
import { ErrorPageLayout } from "../../components/Layout";
import { useString as s } from "../../components/StringProvider";
import Heading from "../../components/Heading";
import { Text } from "../../components";
import { Space } from "antd";
import { ReactComponent as Icon500 } from "../../assets/500.svg";
import NewButton from "../../components/NewButton";
import PropType from "prop-types";

const InternalServerError = ({ text }) => {
  const headerText = s("internal_server_error_page.header", "Oops!");
  const errorText = s(
    "internal_server_error_page.text",
    "Sorry, not your fault. It seems the server doesn't work. Please reload this page or try again later."
  );
  const buttonText = s("internal_server_error_page.reload_page", "Reload Page");

  const reloadPage = () => {
    location.reload();
  };

  return (
    <Page>
      <ErrorPageLayout>
        <Space direction={"vertical"} size={"large"}>
          <Icon500 alt={"500 error"} aria-hidden={true} />
          <Heading level={"h3"} color={"gray4"}>
            {headerText}
          </Heading>
          <Text color={"gray4"}>{text ? text : errorText}</Text>
          <NewButton type={"alternate"} onClick={reloadPage}>
            {buttonText}
          </NewButton>
        </Space>
      </ErrorPageLayout>
    </Page>
  );
};

InternalServerError.propTypes = {
  text: PropType.string
};

export default InternalServerError;
