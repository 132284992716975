import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import actionTypes from "../../../store/actionTypes";
import {
  selectRequestData,
  selectRequestState,
  selectDiscoveryId,
  selectDiscoveryConversationId
} from "../../../store/reducers";
import {
  startConversation,
  getConversation,
  continueConversation,
  retryConversation
} from "../../../store/actions/ai";
import { resetDiscoveryAiConversation } from "../../../store/actions/discoveries";
import useLoadingState from "../../../utils/use-loading-state";
import JobMonitor from "../../JobMonitor";
import JobStatuses from "../../../utils/job-statuses";
import AiChatLoadingNames from "../../../utils/constants/ai-chat-loading-names";
import AiChatRoleNames from "../../../utils/constants/ai-chat-role-names";

export const ChatContext = createContext();

export const ChatProvider = ({
  children,
  startConversation,
  getConversation,
  continueConversation,
  retryConversation,
  resetDiscoveryAiConversation,
  discoveryId,
  conversationId,
  startConversationData,
  getConversationData,
  continueConversationData,
  retryConversationData,
  startConversationLoadingState,
  getConversationLoadingState,
  continueConversationLoadingState,
  retryConversationLoadingState,
  initialChatError,
  initialChatData,
  initialChatLoading
}) => {
  const chatEndRef = useRef(null);
  const [isChatActive, setIsChatActive] = useState(false);
  const [canEnterPrompt, setCanEnterPrompt] = useState(true);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [chatOptions, setChatOptions] = useState(null);
  const [jobId, setJobId] = useState("");
  const [chatData, setChatData] = useState(initialChatData);
  const [chatError, setChatError] = useState(initialChatError);
  const [chatLoading, setChatLoading] = useState(initialChatLoading);

  const scrollToChatBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "instant" });
  };

  const handleSuccess = (data) => {
    setJobId(data?.jobId);
    setChatData(data);
  };

  const handleError = () => {
    setChatError(true);
    setChatLoading((prevState) => ({
      ...prevState,
      active: false
    }));
  };

  const handleLoading = () => {
    setChatError(false);
    setChatLoading((prevState) => ({
      ...prevState,
      active: true
    }));
  };

  useLoadingState(getConversationLoadingState, () =>
    handleSuccess(getConversationData)
  );

  useLoadingState(
    startConversationLoadingState,
    () => handleSuccess(startConversationData),
    handleError,
    handleLoading
  );

  useLoadingState(
    continueConversationLoadingState,
    () => handleSuccess(continueConversationData),
    handleError,
    handleLoading
  );

  useLoadingState(
    retryConversationLoadingState,
    () => handleSuccess(retryConversationData),
    handleError,
    handleLoading
  );

  useEffect(() => {
    if (conversationId) {
      getConversation({ conversationId });
      setIsChatActive(true);
    }
  }, [conversationId]);

  useEffect(() => {
    setCanEnterPrompt(!chatLoading.active);
  }, [chatLoading]);

  const updateChatMessages = (newMessage) => {
    setChatData((prevChatData) => {
      if (!prevChatData) {
        return {
          ...prevChatData,
          messages: [newMessage]
        };
      }

      return {
        ...prevChatData,
        messages: [...prevChatData.messages, newMessage]
      };
    });
  };

  const handleResetConversation = () => {
    if (discoveryId) {
      scrollToChatBottom();
      setIsChatActive(false);
      setCanEnterPrompt(true);
      setSelectedPrompt(null);
      setPrompt("");
      setChatData(null);
      resetDiscoveryAiConversation({ discoveryId });
    }
  };

  const handleStartConversation = () => {
    if (!discoveryId || !prompt) return;

    const { options = [], accessType, emails = [] } = chatOptions || {};

    startConversation({
      discoveryId,
      options,
      emails,
      prompt,
      ...(accessType && { accessType })
    });

    updateChatMessages({
      hidden: false,
      role: AiChatRoleNames.USER,
      content: prompt
    });

    setChatLoading((prevState) => ({
      ...prevState,
      type: AiChatLoadingNames.GENERATING
    }));
  };

  const handleContinueConversation = () => {
    if (conversationId && prompt) {
      continueConversation({ conversationId, prompt });

      updateChatMessages({
        hidden: false,
        role: AiChatRoleNames.USER,
        content: prompt
      });

      setChatLoading((prevState) => ({
        ...prevState,
        type: AiChatLoadingNames.GENERATING
      }));
    }
  };

  const handleRegenerate = () => {
    const prompt = "Regenerate";

    if (conversationId) {
      continueConversation({ conversationId, prompt });

      updateChatMessages({
        hidden: false,
        role: AiChatRoleNames.USER,
        content: prompt
      });

      setChatLoading((prevState) => ({
        ...prevState,
        type: AiChatLoadingNames.REGENERATING
      }));
    }
  };

  const handleRetry = () => {
    if (conversationId) {
      retryConversation({ conversationId });

      setChatLoading((prevState) => ({
        ...prevState,
        type: AiChatLoadingNames.REGENERATING
      }));
    }
  };

  const onJobChange = (job) => {
    switch (job.status) {
      case JobStatuses.FAILED:
        setJobId("");
        setChatError(true);
        setChatLoading((prevState) => ({
          ...prevState,
          active: false
        }));
        break;

      case JobStatuses.STARTED:
        setChatError(false);
        setChatLoading((prevState) => ({
          ...prevState,
          active: true
        }));
        break;

      case JobStatuses.COMPLETED:
        setChatError(false);
        setChatLoading((prevState) => ({
          ...prevState,
          active: false
        }));
        getConversation({ conversationId });
        break;
    }
  };

  return (
    <ChatContext.Provider
      value={{
        conversationId,
        isChatActive,
        setIsChatActive,
        canEnterPrompt,
        setCanEnterPrompt,
        selectedPrompt,
        setSelectedPrompt,
        prompt,
        setPrompt,
        chatEndRef,
        scrollToChatBottom,
        chatOptions,
        setChatOptions,
        handleStartConversation,
        chatData,
        chatError,
        setChatError,
        chatLoading,
        setChatLoading,
        handleResetConversation,
        handleContinueConversation,
        handleRegenerate,
        handleRetry
      }}
    >
      {jobId ? <JobMonitor jobId={jobId} onChange={onJobChange} /> : null}
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialChatError: PropTypes.bool,
  initialChatData: PropTypes.any,
  initialChatLoading: PropTypes.shape({
    active: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(AiChatLoadingNames))
  })
};

ChatProvider.defaultProps = {
  initialChatError: false,
  initialChatData: null,
  initialChatLoading: {
    active: false,
    type: AiChatLoadingNames.GENERATING
  }
};

export const useChat = () => {
  const context = useContext(ChatContext);
  return context;
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  conversationId: selectDiscoveryConversationId(state),
  startConversationData: selectRequestData(
    state,
    actionTypes.START_CONVERSATION_REQUEST
  ),
  startConversationLoadingState: selectRequestState(
    state,
    actionTypes.START_CONVERSATION_REQUEST
  ),
  getConversationData: selectRequestData(
    state,
    actionTypes.GET_CONVERSATION_REQUEST
  ),
  getConversationLoadingState: selectRequestState(
    state,
    actionTypes.GET_CONVERSATION_REQUEST
  ),
  continueConversationData: selectRequestData(
    state,
    actionTypes.CONTINUE_CONVERSATION_REQUEST
  ),
  continueConversationLoadingState: selectRequestState(
    state,
    actionTypes.CONTINUE_CONVERSATION_REQUEST
  ),
  retryConversationData: selectRequestData(
    state,
    actionTypes.RETRY_CONVERSATION_REQUEST
  ),
  retryConversationLoadingState: selectRequestState(
    state,
    actionTypes.RETRY_CONVERSATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startConversation,
      getConversation,
      continueConversation,
      resetDiscoveryAiConversation,
      retryConversation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChatProvider
);
