import ValueMapList, { bottomAreaItem } from "./ValueMapList";
import { useEffect, useRef, useState } from "react";
import {
  selectDiscoveryBusinessCriticalChallengeCode,
  selectDiscoveryChallenges,
  selectDiscoveryId,
  selectDiscoverySetting,
  selectRequestState,
  selectRequestData
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import {
  removeDiscoveryChallengeFromDiscovery,
  reorderDiscoveryChallenges,
  saveDiscoveryChallenge,
  updateDiscoverySettings,
  getAutocompleteDiscoveryChallengeLibrary,
  addDiscoveryChallengeFromLibrary
} from "../../store/actions/discoveries";
import { connect } from "react-redux";
import { useString as s } from "../../components/StringProvider";
import useLoadingState from "../../utils/use-loading-state";
import actionTypes from "../../store/actionTypes";
import KeyObjective from "./KeyObjective";
import ItemTypes from "../../components/ItemTypes";
import { reorderItems } from "./value-map-utils";
import {
  createDiscoveryChallenge,
  deleteDiscoveryChallenge
} from "../../store/actions/create-challenges";
import { sortDiscoveryChallengesByOrder } from "../../utils/sorting";
import {
  isCustomChallenge,
  isTypicalChallenge
} from "../../utils/filters/challenge-filters";
import { message } from "antd";
import { DropDirection as DragDirection } from "./ValueMapCard";
import {
  sendUserPilotEvent,
  UserPilotEventNames
} from "../../utils/user-pilot-util";
import ChallengePositions from "./challenge-positions";

const ValueMapChallengeList = ({
  discoveryId,
  discoveryChallenges,
  challengesEditMode,
  challengesShowSelectedOnly,
  businessCriticalChallengeCode,
  saveDiscoveryChallenge,
  updateDiscoverySettings,
  saveLoadingState,
  reorderDiscoveryChallenges,
  createDiscoveryChallenge,
  createLoadingState,
  deleteDiscoveryChallenge,
  removeDiscoveryChallengeFromDiscovery,
  deleteLoadingState,
  removeLoadingState,
  reorderLoadingState,
  restoreLoadingState,
  getAutocompleteDiscoveryChallengeLibrary,
  challengeLibrary,
  addDiscoveryChallengeFromLibrary
}) => {
  const [itemsAndKeyObjectiveCode, setItemsAndKeyObjectiveCode] = useState({
    keyObjectiveCode: "",
    items: []
  });
  const [keyObjective, setKeyObjective] = useState(null);
  const oldItemsRef = useRef([]);
  const oldKeyObjectiveCodeRef = useRef(undefined);
  const showSelectedOnly = useRef(challengesShowSelectedOnly);

  const challengesTitleText = s("valueMap.page.challenges.title", "Challenges");
  const emptyText = s(
    "valueMap.page.challenges.empty",
    "Click on the “+” above to add a challenge"
  );
  const unselectedHiddenText = s(
    "valueMap.page.challenges.unselectedHidden",
    "Hover on the challenges header and click on the downward arrow to show all challenges"
  );

  const challengesCTA = s(
    "valueMap.page.challenges.cta",
    "What challenges do you need to solve?"
  );
  const failedToCreate = s(
    "createChallenge.popup.error.text",
    "Failed to create challenge"
  );
  const failedToSave = s(
    "editChallenge.popup.error.text",
    "Failed to save challenge"
  );
  const failedToRemove = s(
    "discovery.challenge.card.removeFromList.failed",
    "Failed to remove challenge from the discovery!"
  );
  const failedToDelete = s(
    "discovery.challenge.card.delete.failed",
    "Failed to delete the challenge from the discovery"
  );
  const failedToReorder = s(
    "valueMap.page.challenges.reorder.failed",
    "Failed to reorder"
  );
  const failedToRestore = s(
    "valueMap.page.challenges.restore.failed",
    "Failed to restore challenge"
  );

  useEffect(() => {
    showSelectedOnly.current = challengesShowSelectedOnly;
  }, [challengesShowSelectedOnly]);

  const onReorder = ({ code, destinationCode, direction }) => {
    const bottomItemCode =
      oldItemsRef.current[oldItemsRef.current.length - 1]?.code;
    const newItems = reorderItems({
      code,
      destinationCode:
        destinationCode === bottomAreaItem.code
          ? bottomItemCode
          : destinationCode,
      direction,
      items: oldItemsRef.current,
      showSelectedOnly: showSelectedOnly.current
    });

    setItemsAndKeyObjectiveCode((oldItemsAndKeyObjectiveCode) => ({
      keyObjectiveCode:
        oldItemsAndKeyObjectiveCode.keyObjectiveCode === code
          ? ""
          : oldItemsAndKeyObjectiveCode.keyObjectiveCode,
      items: newItems
    }));

    const keyObjectiveCode =
      direction === DragDirection.REMOVE_KEY_OBJECTIVE
        ? ""
        : oldKeyObjectiveCodeRef.current;

    reorderDiscoveryChallenges({
      discoveryId,
      keyObjectiveCode,
      codes: newItems.map((i) => i.code)
    });

    sendUserPilotEvent({
      eventName: UserPilotEventNames.UNMARK_AS_CBI,
      data: { discoveryId }
    });
  };

  const onToggleEdit = () => {
    updateDiscoverySettings({
      discoveryId,
      changes: {
        canvasChallengesEditMode: !challengesEditMode
      }
    });
  };

  const onToggleShowSelected = () => {
    showSelectedOnly.current = !showSelectedOnly.current;
    updateDiscoverySettings({
      discoveryId,
      changes: {
        canvasChallengesShowSelectedOnly: showSelectedOnly.current
      }
    });
  };

  const onSaveChallenge = ({ item, data }) => {
    const changes = {};

    if ("selected" in data) {
      changes.selected = data.selected;
    }

    if ("text" in data) {
      changes.definition = { description: data.text };
    }

    saveDiscoveryChallenge({
      discoveryId,
      challengeCode: item.code,
      changes
    });

    const userPilotEventName =
      changes.hasOwnProperty("selected") && changes.selected
        ? UserPilotEventNames.SELECT_CHALLENGE
        : UserPilotEventNames.DESELECT_CHALLENGE;

    sendUserPilotEvent({
      eventName: userPilotEventName,
      data: { discoveryId, code: item.code }
    });
  };

  const filterSelected = ({ items }) => {
    if (!showSelectedOnly.current) {
      return items.concat(bottomAreaItem);
    }

    return items.filter((item) => item.selected).concat(bottomAreaItem);
  };

  const moveKeyObjectiveCodeToStartOfList = ({ keyObjectiveCode, codes }) => {
    return [
      keyObjectiveCode,
      ...codes.filter((code) => code !== keyObjectiveCode)
    ];
  };

  const onChangeKeyObjective = ({ item }) => {
    setItemsAndKeyObjectiveCode((oldItemsAndKeyObjectiveCode) => ({
      keyObjectiveCode: item.code,
      items: reorderItems({
        code: item.code,
        destinationCode: oldItemsAndKeyObjectiveCode.items[0]?.code,
        items: oldItemsRef.current,
        direction: DragDirection.KEY_OBJECTIVE,
        showSelectedOnly: showSelectedOnly.current
      })
    }));

    sendUserPilotEvent({
      eventName: UserPilotEventNames.MARK_AS_CBI,
      data: { discoveryId, code: item.code }
    });

    const codes = moveKeyObjectiveCodeToStartOfList({
      keyObjectiveCode: item.code,
      codes: oldItemsRef.current.map((i) => i.code)
    });

    reorderDiscoveryChallenges({
      discoveryId,
      keyObjectiveCode: item.code,
      codes
    });
  };

  const onCreateKeyObjective = ({ text }) => {
    createDiscoveryChallenge({
      discoveryId,
      data: {
        definition: {
          description: text,
          isBusinessCriticalChallenge: true
        }
      }
    });
  };

  const onCreateChallenge = ({ text, fromLibrary = false, code }) => {
    if (fromLibrary) {
      addDiscoveryChallengeFromLibrary({
        discoveryId,
        challengeCode: code,
        position: ChallengePositions.FIRST
      });
    } else {
      createDiscoveryChallenge({
        discoveryId,
        data: {
          definition: {
            description: text,
            position: ChallengePositions.FIRST
          }
        }
      });
    }
  };

  const onDeleteChallenge = ({ item }) => {
    if (item.custom) {
      deleteDiscoveryChallenge({ discoveryId, challengeCode: item.code });
    } else {
      removeDiscoveryChallengeFromDiscovery({
        discoveryId,
        challengeCode: item.code,
        isCBI: item.code === businessCriticalChallengeCode
      });
    }

    sendUserPilotEvent({
      eventName: UserPilotEventNames.REMOVE_CHALLENGE,
      data: { discoveryId, code: item.code }
    });
  };

  useLoadingState(
    saveLoadingState,
    () => {},
    () => {
      message.error(failedToSave);
    }
  );

  useLoadingState(
    createLoadingState,
    () => {},
    () => {
      message.error(failedToCreate);
    }
  );

  useLoadingState(
    removeLoadingState,
    () => {},
    () => {
      message.error(failedToRemove);
    }
  );

  useLoadingState(
    deleteLoadingState,
    () => {},
    () => {
      message.error(failedToDelete);
    }
  );

  useLoadingState(
    reorderLoadingState,
    () => {},
    () => {
      message.error(failedToReorder);
    }
  );

  useLoadingState(
    restoreLoadingState,
    () => {},
    () => {
      message.error(failedToRestore);
    }
  );

  useEffect(() => {
    if (discoveryChallenges) {
      const challenges = [...discoveryChallenges.filter(isTypicalChallenge)];

      challenges.sort(sortDiscoveryChallengesByOrder);

      const newItems = challenges.map((c) => ({
        code: c.challengeCode,
        text: c.definition.description,
        selected: c.selected,
        custom: isCustomChallenge({ discoveryChallenge: c })
      }));

      setItemsAndKeyObjectiveCode({
        keyObjectiveCode: businessCriticalChallengeCode,
        items: newItems
      });
    }
  }, [discoveryChallenges, businessCriticalChallengeCode]);

  useEffect(() => {
    if (itemsAndKeyObjectiveCode?.items?.length) {
      setKeyObjective(
        itemsAndKeyObjectiveCode.items.find(
          (i) => i.code === itemsAndKeyObjectiveCode.keyObjectiveCode
        )
      );
    }

    oldItemsRef.current = itemsAndKeyObjectiveCode?.items;
    oldKeyObjectiveCodeRef.current = itemsAndKeyObjectiveCode?.keyObjectiveCode;
  }, [itemsAndKeyObjectiveCode]);

  const searchChallengeLibrary = (searchTerm) => {
    const query = {
      discoveryId,
      start: 0,
      count: 100,
      order: "asc",
      searchCategories: "1"
    };

    if (searchTerm) {
      query.search = searchTerm;
      getAutocompleteDiscoveryChallengeLibrary(query);
    }
  };

  return (
    <ValueMapList
      type={ItemTypes.VALUE_MAP_CHALLENGE}
      title={challengesTitleText}
      callToAction={challengesCTA}
      emptyText={emptyText}
      unselectedHiddenText={unselectedHiddenText}
      items={filterSelected({ items: itemsAndKeyObjectiveCode.items })}
      totalItemsCount={itemsAndKeyObjectiveCode.items.length}
      keyObjectiveCode={itemsAndKeyObjectiveCode.keyObjectiveCode}
      data-cy={"challenges-panel"}
      editMode={challengesEditMode}
      showSelectedOnly={showSelectedOnly.current}
      onToggleEdit={onToggleEdit}
      onToggleShowSelected={onToggleShowSelected}
      onSaveItem={onSaveChallenge}
      onCreateItem={onCreateChallenge}
      onDeleteItem={onDeleteChallenge}
      onReorder={onReorder}
      createLoadingState={createLoadingState}
      searchLibrary={searchChallengeLibrary}
      searchLibraryData={challengeLibrary}
      onChangeKeyObjective={onChangeKeyObjective}
    >
      {challengesEditMode && (
        <KeyObjective
          keyObjective={keyObjective}
          onChange={onChangeKeyObjective}
          onSaveItem={onSaveChallenge}
          onCreateKeyObjective={onCreateKeyObjective}
          onDeleteKeyObjective={onDeleteChallenge}
          discoveryId={discoveryId}
          onReorder={onReorder}
        />
      )}
    </ValueMapList>
  );
};

const mapStateToProps = (state) => {
  return {
    discoveryChallenges: selectDiscoveryChallenges(state),
    discoveryId: selectDiscoveryId(state),
    challengesEditMode: selectDiscoverySetting(
      state,
      "canvasChallengesEditMode",
      false
    ),
    challengesShowSelectedOnly: selectDiscoverySetting(
      state,
      "canvasChallengesShowSelectedOnly",
      false
    ),
    businessCriticalChallengeCode:
      selectDiscoveryBusinessCriticalChallengeCode(state),
    saveLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_SAVE_CHALLENGE_REQUEST
    ),
    createLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_CREATE_CHALLENGE_REQUEST
    ),
    deleteLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_DELETE_CHALLENGE_REQUEST
    ),
    removeLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_REQUEST
    ),
    reorderLoadingState: selectRequestState(
      state,
      actionTypes.REORDER_DISCOVERY_CHALLENGES_REQUEST
    ),
    restoreLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_RESTORE_CHALLENGE_REQUEST
    ),
    challengeLibrary: selectRequestData(
      state,
      actionTypes.GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryChallenge,
      createDiscoveryChallenge,
      reorderDiscoveryChallenges,
      deleteDiscoveryChallenge,
      removeDiscoveryChallengeFromDiscovery,
      updateDiscoverySettings,
      getAutocompleteDiscoveryChallengeLibrary,
      addDiscoveryChallengeFromLibrary
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ValueMapChallengeList
);
