import React from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Markdown from "../../../components/Discovery/Markdown";
import PropTypes from "prop-types";
import Radio from "../../../components/Radio";

const RadioButtonCard = ({ text, editable, style, value }) => {
  return (
    <StyledRadio
      value={value}
      disabled={!editable}
      style={style}
      data-cy={`question-option-card-${value}`}
    >
      <Markdown>{text}</Markdown>
    </StyledRadio>
  );
};

const StyledRadio = styled(Radio)`
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  border-radius: 8px;
  padding: 16px;
  background: ${themeProp("palette.surface")};
  user-select: none;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  width: 100%;

  &.ant-radio-wrapper-checked {
    background: ${themeProp("palette.secondary")};

    & span:not(.ant-radio) * {
      color: white;
    }
  }

  & .ant-radio {
    margin-right: 10px;
  }

  & .ant-radio-wrapper {
    align-items: center;
  }

  & .ant-radio {
    top: unset;
  }

  & .ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  & .ant-radio.ant-radio-checked .ant-radio-inner {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

RadioButtonCard.propTypes = {
  value: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  style: PropTypes.any
};

export default RadioButtonCard;
