import Mask from "./Mask";
import { useEffect, useRef, useState } from "react";
import Content from "./Content";

const ScrollingModal = ({ open, children, style, dispatch, ...props }) => {
  const [introComplete, setIntroComplete] = useState();
  const contentRef = useRef();
  const zIndex = 1;
  const maskStyle = {
    background: "rgba(0,0,0)",
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: introComplete ? 0.5 : 0,
    transition: "opacity 5s",
    display: introComplete ? "block" : "none",
    zIndex
  };
  const contentStyle = {
    visibility: introComplete ? "visible" : "hidden"
  };
  const wrapStyle = {
    zIndex: zIndex + 1,
    visibility: introComplete ? "visible" : "hidden",
    position: "relative"
  };

  useEffect(() => {
    if (open) {
      setIntroComplete(true);
    }
  }, [open]);

  useEffect(() => {
    if (introComplete) {
      contentRef.current?.focus();
    }
  }, [introComplete]);

  const onWrapperKeyDown = (e) => {
    if (introComplete) {
      if (e.key === "Tab" || e.keyCode === 9) {
        contentRef.current.changeActive(!e.shiftKey);
      }
    }
  };

  return (
    <div>
      <Mask style={maskStyle} />
      <div
        tabIndex={-1}
        onKeyDown={onWrapperKeyDown}
        role="dialog"
        className={"scrolling-modal-wrap"}
        style={wrapStyle}
      >
        <Content
          ref={contentRef}
          style={{ ...contentStyle, ...style }}
          {...props}
        >
          {children}
        </Content>
      </div>
    </div>
  );
};

export default ScrollingModal;
