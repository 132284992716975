import { useString as s } from "../../StringProvider";
import Hyperlink from "../../Hyperlink";

const PrivacyText = ({}) => {
  const BLANK = "BLANK";
  const privacyAgree = s("register_interest.popup.privacy.agree", "I agree to");
  const privacyAnd = s("register_interest.popup.privacy.and", "and");
  const privacyLink1Label = s(
    "register_interest.popup.privacy.link1.label",
    "Privacy Policy"
  );
  const privacyLink1Url = s(
    "register_interest.popup.privacy.link1.url",
    "https://cuvama.com/privacy-policy/"
  );
  const privacyLink2Label = s(
    "register_interest.popup.privacy.link2.label",
    BLANK
  );
  const privacyLink2Url = s("register_interest.popup.privacy.link2.url", BLANK);

  return (
    <>
      {privacyAgree}{" "}
      <Hyperlink to={privacyLink1Url}>{privacyLink1Label}</Hyperlink>
      {privacyLink2Label !== BLANK ? (
        <>
          {privacyAnd}{" "}
          <Hyperlink to={privacyLink2Url}>{privacyLink2Label}</Hyperlink>
        </>
      ) : null}
    </>
  );
};

export default PrivacyText;
