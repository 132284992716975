import types from "../actionTypes";
import filesService from "../../services/files.service";
import httpService from "../../services/http.service";

export const createFile =
  ({ name, type, size, temporary, description, localTag }) =>
  (dispatch) => {
    const newType = type ? type : "unknown";

    dispatch({
      type: types.CREATE_FILE_REQUEST,
      parameters: {
        name,
        type: newType,
        size,
        temporary,
        description,
        localTag
      }
    });

    return filesService
      .createFile({
        name,
        type: newType,
        size,
        temporary,
        description
      })
      .then((payload) => {
        dispatch({
          type: types.CREATE_FILE_SUCCESS,
          payload,
          parameters: {
            name,
            type: newType,
            size,
            temporary,
            description,
            localTag
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_FILE_FAILURE,
          error,
          parameters: {
            name,
            type: newType,
            size,
            temporary,
            description,
            localTag
          }
        });
      });
  };

export const uploadFile =
  ({ url, file, controller }) =>
  (dispatch) => {
    dispatch({
      type: types.UPLOAD_FILE_REQUEST,
      parameters: {
        url,
        file
      }
    });

    const onUploadProgress = (progressEvent) => {
      const { loaded, total } = progressEvent;
      const uploadPercentage = Math.floor((loaded * 100) / total);

      dispatch({
        type: types.UPLOAD_FILE_PROGRESS,
        parameters: {
          file
        },
        payload: {
          uploadPercentage
        }
      });
    };

    return httpService
      .uploadFile(url, file, onUploadProgress, controller.signal)
      .then(() => {
        dispatch({
          type: types.UPLOAD_FILE_SUCCESS,
          parameters: {
            url,
            file
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPLOAD_FILE_FAILURE,
          error,
          parameters: {
            url,
            file
          }
        });
      });
  };

export const downloadFile =
  ({ fileId }) =>
  (dispatch) => {
    dispatch({
      type: types.DOWNLOAD_FILE_REQUEST
    });

    return filesService
      .downloadFile({
        fileId
      })
      .then((payload) => {
        dispatch({
          type: types.DOWNLOAD_FILE_SUCCESS,
          payload,
          parameters: {
            fileId
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DOWNLOAD_FILE_FAILURE,
          error,
          parameters: {
            fileId
          }
        });
      });
  };

export const transformAndDownloadFile =
  ({ fileId, transformationType }) =>
  (dispatch) => {
    dispatch({
      type: types.TRANSFORM_AND_DOWNLOAD_FILE_REQUEST
    });

    return filesService
      .transformAndDownloadFile({
        fileId,
        transformationType
      })
      .then((payload) => {
        dispatch({
          type: types.TRANSFORM_AND_DOWNLOAD_FILE_SUCCESS,
          payload,
          parameters: {
            fileId,
            transformationType
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.TRANSFORM_AND_DOWNLOAD_FILE_FAILURE,
          error,
          parameters: {
            fileId,
            transformationType
          }
        });
      });
  };
