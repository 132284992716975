import { useString as s } from "../../../../../components/StringProvider";
import { ReactComponent as Icon } from "../../../../../assets/editor/type-italic.svg";
import ToolbarButton from "./ToolbarButton";

const ItalicButton = ({ onClick, active }) => {
  return (
    <ToolbarButton
      active={active}
      onClick={onClick}
      label={s("editor.buttons.italic.label", "Format Italic")}
    >
      <Icon />
    </ToolbarButton>
  );
};

export default ItalicButton;
