import httpService from "./http.service";

const getNotificationCount = () =>
  httpService
    .get(`/notifications/countUnread`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getNotifications = ({ isRead }) => {
  const url =
    isRead === undefined
      ? `/notifications/`
      : `/notifications/?isRead=${encodeURIComponent(isRead)}`;

  return httpService
    .get(url)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));
};

const getNotification = ({ notificationId }) =>
  httpService
    .get(`/notifications/${notificationId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const markNotificationsAsRead = ({ notificationIds }) =>
  httpService
    .post(`/notifications/markAsRead`, notificationIds)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getNotificationCount,
  getNotifications,
  getNotification,
  markNotificationsAsRead
};

export default methods;
