import React, { useState } from "react";
import PropTypes from "prop-types";
import NewButton from "../NewButton";
import styled, { css } from "styled-components";
import Icon from "../Icon";
import { useMobileMediaQuery } from "../Responsive";

const AboutThisCard = ({
  text,
  disabled,
  children,
  defaultExpandedState,
  printMode,
  style = {}
}) => {
  const isMobile = useMobileMediaQuery();
  const [isExpanded, setExpanded] = useState(defaultExpandedState);

  return (
    <Container style={style} printMode={printMode}>
      <NewButton
        data-cy={"about-this-card-button"}
        className={"up-challenge-card-about-this"}
        type={"text"}
        onClick={() => setExpanded(!isExpanded)}
        disabled={disabled}
        style={{
          left: -8,
          marginBottom: isMobile && !isExpanded ? 0 : 8
        }}
      >
        {text} <Icon name={isExpanded ? "angleUp" : "angleDown"} />
      </NewButton>
      {isExpanded ? <>{children}</> : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ printMode }) =>
    printMode &&
    css`
      display: none;
    `}
`;

AboutThisCard.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  printMode: PropTypes.bool
};

export default AboutThisCard;
