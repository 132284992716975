import styled, { css } from "styled-components";
import { themeProp } from "../theme";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../assets/Search.svg";
import { Input } from "antd";

const SearchBar = ({ onSearch, placeholder, size, ...props }) => {
  const [currentText, setCurrentText] = useState();

  const onChange = (e) => {
    setCurrentText(e.target.value);
  };

  const onSearchInternal = (e) => {
    onSearch(e);
    setCurrentText(e);
  };

  return (
    <Search
      onSearch={onSearchInternal}
      onChange={onChange}
      className={currentText ? "hide-search-icon" : undefined}
      placeholder={placeholder}
      size={size}
      {...props}
    />
  );
};

const Search = styled(Input.Search).attrs(() => {
  return {
    allowClear: true,
    bordered: false,
    prefix: <SearchIcon />
  };
})`
  margin-bottom: 16px;
  background: transparent;

  &:hover {
    background-color: ${themeProp("palette.surface")};
  }

  & input {
    background: transparent;
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")};
    line-height: ${themeProp("typography.body.lineHeight")};
    font-weight: ${themeProp("typography.body.fontWeight")};
    color: ${themeProp("palette.text")};
    padding: 0;
    height: 40px;
    padding: 0 8px;

    &::placeholder {
      color: ${themeProp("palette.gray3")};
      font-size: ${themeProp("typography.bodyMobile.fontSize")};
      line-height: ${themeProp("typography.bodyMobile.lineHeight")};
      font-weight: ${themeProp("typography.bodyMobile.fontWeight")};
    }
  }

  & .ant-input-affix-wrapper {
    border-width: 0 0 1px 0;
    border-color: ${themeProp("palette.gray3")};
    border-bottom-style: solid;
    padding: 0 8px;
  }

  & .ant-input-affix-wrapper-focused .ant-input-prefix {
    display: none;
  }

  &.hide-search-icon .ant-input-prefix {
    display: none;
  }

  & .ant-input-clear-icon {
    color: ${themeProp("palette.text")};
  }

  & .ant-input-group-addon {
    display: none;
  }

  & span {
    background: transparent;
    border-start-start-radius: 0px !important;
    border-end-start-radius: 0px !important;
  }

  & .ant-input-prefix {
    margin-right: 8px;
  }
`;

export default SearchBar;
