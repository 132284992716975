import React from "react";
import { useParams, withRouter } from "react-router-dom";
import OrganizationHeader from "../OrganizationHeader";
import Page from "../../../components/Page";
import { useTheme } from "../../../components/ThemeProvider";
import FadeLoader from "react-spinners/FadeLoader";
import styled from "styled-components";
import TextBody from "../../../components/TextBody";
import { Space } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import PropType from "prop-types";
import { selectJob } from "../../../store/reducers";
import JobStatuses from "../../../utils/job-statuses";
import DeploymentSuccess from "./DeploymentSuccess";
import DeploymentFailure from "./DeploymentFailure";
import DeploymentMessage from "./DeploymentMessage";
import PoweredByCuvama from "../../../components/PoweredByCuvama";
import JobMonitor from "../../../components/JobMonitor";

const DeploymentJob = ({ job }) => {
  const { jobId } = useParams();
  const loaderColor = useTheme("palette.primary");

  if (job && job.status === JobStatuses.COMPLETED) {
    return <DeploymentSuccess type={job.type} />;
  }

  if (job && job.status === JobStatuses.FAILED) {
    return <DeploymentFailure type={job.type} />;
  }

  return (
    <Page header={<OrganizationHeader />}>
      {jobId && <JobMonitor jobId={jobId} />}
      <LoaderContainer>
        <LoaderSpace size={16} direction={"vertical"} align={"center"}>
          <LoaderDiv>
            <FadeLoader color={loaderColor} size="50" />
          </LoaderDiv>
          <TextBody>{!!job && <DeploymentMessage type={job.type} />}</TextBody>
        </LoaderSpace>
        <Footer>
          <PoweredByCuvama />
        </Footer>
      </LoaderContainer>
    </Page>
  );
};

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const LoaderSpace = styled(Space)`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const Footer = styled.div`
  margin: 0;
  position: absolute;
  bottom: 0;
`;

const LoaderDiv = styled.div`
  height: 50px;
  width: 50px;
`;

const mapStateToProps = (state, props) => ({
  job: selectJob(state, props.match.params.jobId)
});

DeploymentJob.propTypes = {
  jobId: PropType.string.isRequired
};

export default compose(withRouter, connect(mapStateToProps))(DeploymentJob);
