const Permissions = {
  EDIT_KPIS: "EDIT_KPIS",
  EDIT_CHALLENGES: "EDIT_CHALLENGES",
  SEE_ALL_DISCOVERIES: "SEE_ALL_DISCOVERIES",
  SEE_ALL_METRICS: "SEE_ALL_METRICS",
  EXPORT_USERS: "EXPORT_USERS",
  EXPORT_DISCOVERIES: "EXPORT_DISCOVERIES",
  EXPORT_POWERPOINT: "EXPORT_POWERPOINT",
  EXPORT_DISCOVERY_EXCEL: "EXPORT_DISCOVERY_EXCEL",
  EDIT_KPI_VALUE_WITHOUT_NOTIFICATION: "EDIT_KPI_VALUE_WITHOUT_NOTIFICATION",
  UPDATE_QUESTIONS: "UPDATE_QUESTIONS",
  UPDATE_ROI_VALUES: "UPDATE_ROI_VALUES",
  SEE_SOLUTIONS: "SEE_SOLUTIONS",
  SEE_MY_DISCOVERIES: "SEE_MY_DISCOVERIES",
  SEE_INTERNAL_LINKS_AND_COMMENTS: "SEE_INTERNAL_LINKS_AND_COMMENTS",
  SEE_FINISH_HERE: "SEE_FINISH_HERE",
  SHARE_EDIT: "SHARE_EDIT",
  SEE_LEAD_GENERATION: "SEE_LEAD_GENERATION",
  SEE_ROI_PAGE: "SEE_ROI_PAGE",
  SEE_SELECT_KPIS: "SEE_SELECT_KPIS",
  SEE_KPI_SUMMARY: "SEE_KPI_SUMMARY",
  SEE_SELECT_CHALLENGES: "SEE_SELECT_CHALLENGES",
  SEE_CHALLENGE_SUMMARY: "SEE_CHALLENGE_SUMMARY",
  SEE_VALUE_HYPOTHESIS: "SEE_VALUE_HYPOTHESIS",
  SEE_SHARE_BUTTON: "SEE_SHARE_BUTTON",
  SEE_DISCOVERY_DROPDOWN_MENU: "SEE_DISCOVERY_DROPDOWN_MENU",
  SEE_CLONE_DISCOVERY_BUTTON: "SEE_CLONE_DISCOVERY_BUTTON",
  SEE_ORG_ADMIN: "SEE_ORG_ADMIN",
  SEE_NEW_DISCOVERY_BUTTON: "SEE_NEW_DISCOVERY_BUTTON",
  VERIFY_TEMPLATE: "VERIFY_TEMPLATE",
  UPDATE_CONFIGURATION: "UPDATE_CONFIGURATION",
  REQUEST_PROMOTION: "REQUEST_PROMOTION",
  PUBLISH_PROMOTION: "PUBLISH_PROMOTION",
  SEE_ACCESS_MANAGEMENT: "SEE_ACCESS_MANAGEMENT",
  EDIT_ACCESS_MANAGEMENT: "EDIT_ACCESS_MANAGEMENT",
  SEE_DISCOVERY_NOTES: "SEE_DISCOVERY_NOTES",
  SEE_KPI_LIBRARY: "SEE_KPI_LIBRARY",
  SEE_CHALLENGE_LIBRARY: "SEE_CHALLENGE_LIBRARY",
  SEE_CANVAS: "SEE_CANVAS",
  SEE_AI_EMAIL_TEMPLATES: "SEE_AI_EMAIL_TEMPLATES",
  SEE_DISCOVERY_HELP: "SEE_DISCOVERY_HELP",
  EXPORT_TO_PDF: "EXPORT_TO_PDF"
};

Permissions.ALL = Object.values(Permissions);

export default Permissions;
