import { useState, useEffect } from "react";
import { Tag } from "antd";
import { validate } from "email-validator";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { themeProp } from "../../theme";
import Select from "../Select";

const allEmailsValid = (options) => {
  for (const option of options) {
    const email = option.value;
    if (!validate(email)) {
      return false;
    }
  }

  return true;
};

const getEmailOptions = (emails) => {
  const emailOptions = [];
  for (const option of emails) {
    const email = option.value;
    if (!validate(email)) {
      emailOptions.push({
        key: email,
        value: email,
        label: "invalid-" + new Date().toString()
      });
    } else {
      emailOptions.push({ key: email, value: email, label: email });
    }
  }

  return emailOptions;
};

const getValidEmails = (emails) => {
  return emails.filter((email) => validate(email.key));
};

const formatEmails = (emails) => {
  return emails.filter(validate).map((email) => ({
    key: email,
    value: email,
    label: email
  }));
};

const extractEmails = (input) => {
  if (input) {
    const emailMatches = input
      .split(/[;,"|\n\t/ +*!<>]/)
      .map((item) => item.trim().toLowerCase())
      .filter((email) => email !== "");

    const uniqueEmails = [...new Set(emailMatches)];

    return formatEmails(uniqueEmails);
  }

  return [];
};

const MultipleEmailInput = ({
  value,
  onChange,
  placeholder,
  variant,
  maxTagRows
}) => {
  const [emails, setEmails] = useState(value || []);

  useEffect(() => {
    setEmails(value || []);
  }, [value]);

  const handleOnChange = (newValue) => {
    const lastValue = newValue[newValue.length - 1]?.value;
    const extractedEmails = extractEmails(lastValue);
    const uniqueEmails = [
      ...new Set(
        [...newValue, ...extractedEmails].map((emailObj) =>
          emailObj.value.trim().toLowerCase()
        )
      )
    ];
    const formattedEmails = formatEmails(uniqueEmails);

    onChange(formattedEmails);
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={label.includes("invalid") ? "red" : undefined}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          fontSize: "14px"
        }}
      >
        {value}
      </Tag>
    );
  };

  return (
    <StyledSelect
      mode="tags"
      open={false}
      suffixIcon={null}
      labelInValue={true}
      placeholder={placeholder}
      tagRender={tagRender}
      onChange={handleOnChange}
      value={emails}
      variant={variant}
      maxTagRows={maxTagRows}
    />
  );
};

const StyledSelect = styled(Select)`
  ${({ variant, maxTagRows }) =>
    variant === "secondary" &&
    css`
      background-color: transparent !important;

      .ant-select-selector {
        border: 0 !important;
        border-bottom: 1px solid ${themeProp(`palette.secondary`)} !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;
        min-height: 40px;
        max-height: calc((40px * ${maxTagRows}));
        scrollbar-width: thin;
        -ms-overflow-style: none;
        font-weight: ${themeProp(`typography.body.fontWeight`)};

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: ${themeProp("palette.gray4")};
        }
      }

      .ant-select-selection-overflow {
        gap: 8px;
        padding: 4px 0;
      }

      .ant-tag {
        height: 32px;
        display: flex;
        align-items: center;
        font-weight: ${themeProp(`typography.body.fontWeight`)};
      }

      .ant-tag:not(.ant-tag-red) {
        background-color: ${themeProp("palette.gray2")};
      }

      & .ant-form-item-explain {
        padding: 4px 12px;
        font-size: ${themeProp("typography.bodyMobile.fontSize")};
        line-height: ${themeProp("typography.bodyMobile.lineHeight")};
        font-weight: ${themeProp("typography.bodyMobile.fontWeight")};
      }
    `}
`;

MultipleEmailInput.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  maxTagRows: PropTypes.number
};

MultipleEmailInput.defaultProps = {
  variant: "primary",
  maxTagRows: 1
};

export {
  MultipleEmailInput as default,
  allEmailsValid,
  getEmailOptions,
  getValidEmails
};
