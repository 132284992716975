import store from "../store/store";
import { Userpilot } from "userpilot";

export const UserPilotEventNames = {
  MARK_AS_CBI: "Canvas - Mark As CBI",
  UNMARK_AS_CBI: "Canvas - Unmark CBI",
  CLICK_TO_ADD_CBI: "Canvas - Click to Add CBI",
  SELECT_OUTCOME: "Canvas - Select Outcome",
  DESELECT_OUTCOME: "Canvas - Deselect Outcome",
  SELECT_CHALLENGE: "Canvas - Select Challenge",
  DESELECT_CHALLENGE: "Canvas - Deselect Challenge",
  DRAG_AND_DROP_OUTCOME: "Canvas - Drag and Drop Outcome",
  DRAG_AND_DROP_CHALLENGE: "Canvas - Drag and Drop Challenge",
  EDIT_TEXT_OUTCOME: "Canvas - Edit Text Outcome",
  EDIT_TEXT_CHALLENGE: "Canvas - Edit Text Challenge",
  REMOVE_OUTCOME: "Canvas - Remove Outcome",
  REMOVE_CHALLENGE: "Canvas - Remove Challenge",
  ADD_NEW_COMMENT_CANVAS_CHALLENGE: "Canvas - Add New Challenge Comment",
  ADD_NEW_COMMENT_CANVAS_OUTCOME: "Canvas - Add New Outcome Comment",
  ADD_NEW_COMMENT_CHALLENGE: "Challenge Page - Add New Comment",
  ADD_NEW_COMMENT_OUTCOME: "Outcome Page - Add New Comment",
  ADD_NEW_COMMENT_VALUE_HYPOTHESIS: "Value Hypothesis - Add New Comment",
  ADD_NEW_COMMENT_ROI: "ROI - Add New Comment",
  OPEN_ENGAGEMENT_REPORT: "MyDiscoveries - Open Engagement Report",
  VH_OPEN_SETTINGS: "VH - Edit Assumptions - Settings Icon",
  VH_OPEN_VALUE_SECTION: "VH - Edit Assumptions - Value Section"
};

export const sendUserPilotEvent = ({ eventName, data }) => {
  const userId = store?.getState()?.authentication?.user?.userId;
  Userpilot.track(eventName, { ...data, userId });
};

export const RESOURCE_CENTER = "resource_center:8UQEYEDhXo";
export const AI_FEEDBACK_SURVEY = "survey:m6pchJFwEw";
