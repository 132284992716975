import React, { useEffect, useState } from "react";
import { Prompt, withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  saveDiscoveryQuestion,
  setCanvasClicked
} from "../../store/actions/discoveries";
import {
  hasPermission,
  selectAllPermissions,
  selectDiscoveryId,
  selectDiscoveryQuestion,
  selectDiscoveryQuestions,
  selectQuestion,
  selectRequestData,
  selectRequestState
} from "../../store/reducers";
import QuestionForm from "./components/QuestionForm";
import { useString as s } from "../../components/StringProvider";
import { message } from "antd";
import Permissions from "../../utils/permissions";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import { useMobileOrTabletMediaQuery } from "../../components/Responsive";
import useLoadingState from "../../utils/use-loading-state";
import QuestionTypes from "../../utils/question-types";
import actionTypes from "../../store/actionTypes";
import { getNextValidCheckpoint } from "../Discovery/components/OnlyIfUserCanAccessDiscoveryPath";
import CanvasButton from "../Discovery/CanvasButton";
import Form from "../../components/Form";
import { getValueMapPath } from "../ValueMap/value-map-utils";
import DiscoverySubHeader from "../Discovery/DiscoverySubHeader";
import { Phases } from "../../utils/phases";

const Question = ({
  history,
  question,
  questionNumber,
  allQuestions,
  discoveryId,
  discoveryQuestion,
  canUpdateQuestions,
  saveQuestionLoadingState,
  saveDiscoveryQuestion,
  permissions,
  headerCanvasButtonClicked,
  setCanvasClicked
}) => {
  const isMobileOrTablet = useMobileOrTabletMediaQuery();
  const [nextStepClicked, setNextStepClicked] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [form] = Form.useForm();
  const [loop, setLoop] = useState(0);

  const errorText = s(
    "discovery.question.error.text",
    "Failed to save question"
  );

  const quickCanvasButtonText = s(
    "canvas.questions.button.text",
    "Quick Canvas"
  );

  useEffect(() => {
    setLoop(0);
  }, [questionNumber]);

  useEffect(() => {
    if (headerCanvasButtonClicked) {
      onClickQuickCanvas();
      setCanvasClicked({ clicked: false });
    }
  }, [headerCanvasButtonClicked]);

  const onClickQuickCanvas = () => {
    history.push(getValueMapPath(discoveryId));
  };

  const quickCanvasButton = (
    <CanvasButton
      buttonText={quickCanvasButtonText}
      isQuickCanvas={true}
      onClick={onClickQuickCanvas}
    />
  );

  const nextStep = () => {
    let nextQuestion;

    for (let i = questionNumber + 1; i < allQuestions.length; i++) {
      if (allQuestions[i].active) {
        nextQuestion = i;
        break;
      }
    }

    if (nextQuestion !== undefined) {
      history.push(`/discoveries/${discoveryId}/questions/${nextQuestion}`);
    } else {
      const nextCheckpoint = getNextValidCheckpoint({
        checkpoint: "/select-challenges",
        permissions
      });

      history.push(`/discoveries/${discoveryId}${nextCheckpoint}`);
    }
  };

  const onNext = () => {
    history.push(`/discoveries/${discoveryId}/questions/next`);
  };

  useEffect(() => {
    if (!discoveryQuestion.active) {
      onNext();
    }
  }, [discoveryQuestion]);

  useEffect(() => {
    history.listen(() => window.scrollTo(0, 0));
  }, [history]);

  useLoadingState(
    saveQuestionLoadingState,
    () => {
      if (nextStepClicked) {
        nextStep();
        return;
      }

      if (nextLocation) {
        history.push(nextLocation);
      }
    },
    () => {
      message.error(errorText);
    }
  );

  const initializeIfNecessary = (answer) => {
    if (answer !== null && answer !== undefined) {
      return answer;
    }

    return question.questionType === QuestionTypes.MULTIPLE_TEXT_VALUE
      ? []
      : null;
  };

  const isUnset = (value) => {
    return (
      value === undefined ||
      value === null ||
      value === "" ||
      value?.length === 0
    );
  };

  const numberMatchesString = (a, b) => {
    return (
      (typeof a === "string" || typeof a === "number") &&
      (typeof b === "string" || typeof b === "number") &&
      `${a}` === `${b}`
    );
  };

  return (
    <>
      <Prompt
        message={(location) => {
          const initializedAnswer = initializeIfNecessary(
            form.getFieldValue("answer")
          );

          const isEqual =
            _.isEqual(discoveryQuestion?.answer, initializedAnswer) ||
            numberMatchesString(discoveryQuestion?.answer, initializedAnswer) ||
            (isUnset(discoveryQuestion?.answer) && isUnset(initializedAnswer));

          if (loop > 1) {
            return true;
          }

          setLoop((currentValue) => currentValue + 1);

          const clickedNext = location.pathname.indexOf("/next") !== -1;

          setNextStepClicked(clickedNext);

          if (discoveryQuestion.active && !isEqual && canUpdateQuestions) {
            setNextLocation(location);
            saveDiscoveryQuestion({
              discoveryId,
              questionNumber,
              answer: initializedAnswer
            });
            return false;
          }

          if (clickedNext) {
            nextStep();
            return false;
          }

          return true;
        }}
      />

      <DiscoverySubHeader
        phase={Phases.QUESTIONS}
        onNext={onNext}
        alwaysShowSubheader={true}
      />

      <DiscoveryContentWrapper isMobile={isMobileOrTablet}>
        <QuestionForm
          form={form}
          quickCanvasButton={quickCanvasButton}
          key={questionNumber}
          discoveryQuestion={discoveryQuestion}
          questionNumber={questionNumber}
          discoveryId={discoveryId}
          onNext={onNext}
          editable={canUpdateQuestions}
        />
      </DiscoveryContentWrapper>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const discoveryQuestion = selectDiscoveryQuestion(
    state,
    parseInt(props.match.params.questionNumber)
  );

  return {
    questionNumber: parseInt(props.match.params.questionNumber),
    discoveryQuestion,
    discoveryId: selectDiscoveryId(state),
    allQuestions: selectDiscoveryQuestions(state),
    saveQuestionLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_SAVE_QUESTION_REQUEST
    ),
    headerCanvasButtonClicked: selectRequestData(
      state,
      actionTypes.DISCOVERY_CANVAS_CLICKED_SUCCESS
    ),
    canUpdateQuestions: hasPermission(state, Permissions.UPDATE_QUESTIONS),
    question: selectQuestion(state, discoveryQuestion?.questionCode),
    permissions: selectAllPermissions(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryQuestion,
      setCanvasClicked
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Question);
