import React, { useContext } from "react";
import { NumberInput } from "../../components/Input";
import { SettingsContext } from "../SettingsProvider";
import PropTypes from "prop-types";
import { getCurrencyFormatter, getMetricParser } from "../../utils/formatting";
import MetricTypes from "../../utils/metric-types";

const CurrencyInput = ({
  value,
  onChange,
  onCommit,
  currency,
  size,
  ...props
}) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;

  const formatter = getCurrencyFormatter({
    settings: { locale, currency },
    options: { notation: "standard" }
  });
  const parser = getMetricParser({
    type: MetricTypes.CURRENCY,
    locale,
    currency
  });

  return (
    <NumberInput
      formatter={formatter}
      parser={parser}
      value={value}
      onChange={onChange}
      onCommit={onCommit}
      size={size}
      {...props}
    />
  );
};

CurrencyInput.propType = {
  value: PropTypes.number.isRequired,
  formatter: PropTypes.func,
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  currency: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["medium", "large"])
};

CurrencyInput.defaultProps = {
  size: "large"
};

export default CurrencyInput;
