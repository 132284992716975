import React from "react";
import DeactivateUserModal from "./Users/DeactivateUserModal";
import ResetPasswordModal from "./Users/ResetPasswordModal";
import DeleteRoleModal from "./Roles/DeleteRoleModal";
import { bindActionCreators, compose } from "redux";
import { stopAccessManagementOperation } from "../../../store/actions/users";
import { connect } from "react-redux";
import {
  selectUsersOperationRoleId,
  selectUsersOperationId,
  selectUsersOperationUserId
} from "../../../store/reducers";
import { useHistory } from "react-router-dom";
import ReactivateUserModal from "./Users/ReactivateUserModal";
import GeneratePasswordModal from "./Users/GeneratePasswordModal";

export const Operations = {
  DEACTIVATE_USER: "deactivate_user",
  REACTIVATE_USER: "reactivate_user",
  RESET_PASSWORD: "reset_password",
  DELETE_ROLE: "delete_role",
  DUPLICATE_ROLE: "duplicate_role",
  CREATE_ROLE: "create_role",
  GENERATE_PASSWORD: "generate_password"
};

const AccessManagementActions = ({
  operationId,
  operationUserId,
  operationRoleId,
  stopAccessManagementOperation
}) => {
  let history = useHistory();

  const onClose = () => {
    stopAccessManagementOperation();
  };

  if (!operationId && !operationUserId) {
    return null;
  }

  switch (operationId) {
    case Operations.RESET_PASSWORD:
      return <ResetPasswordModal onClose={onClose} userId={operationUserId} />;

    case Operations.DEACTIVATE_USER:
      return <DeactivateUserModal onClose={onClose} userId={operationUserId} />;

    case Operations.REACTIVATE_USER:
      return <ReactivateUserModal onClose={onClose} userId={operationUserId} />;

    case Operations.GENERATE_PASSWORD:
      return (
        <GeneratePasswordModal onClose={onClose} userId={operationUserId} />
      );

    case Operations.DELETE_ROLE:
      return <DeleteRoleModal onClose={onClose} roleId={operationRoleId} />;

    case Operations.DUPLICATE_ROLE:
      history.push(
        `/organizations/access-management/roles/create/${operationRoleId}`
      );
      break;

    default:
      return null;
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    operationId: selectUsersOperationId(state),
    operationUserId: selectUsersOperationUserId(state),
    operationRoleId: selectUsersOperationRoleId(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      stopAccessManagementOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccessManagementActions
);
