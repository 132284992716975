import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../theme";
import Icon from "./Icon";
import Tooltip from "./Tooltip";

// Workaround to avoid this warning 'Function components cannot be given refs'
const IconWrapperComponent = React.forwardRef((props, ref) => (
  <span ref={ref}>
    <IconWrapper name="help" {...props} />
  </span>
));

const TooltipIcon = ({
  title,
  disabled,
  color,
  dataCy,
  dataCyTooltip,
  iconName = "help"
}) =>
  title ? (
    !disabled ? (
      <Tooltip title={title} data-cy={dataCyTooltip}>
        <IconWrapperComponent color={color} data-cy={dataCy} />
      </Tooltip>
    ) : (
      <Icon name={iconName} colour={"gray3"} data-cy={dataCy} />
    )
  ) : null;

TooltipIcon.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf(["primary", "surface"]),
  disabled: PropTypes.bool,
  dataCy: PropTypes.string
};
TooltipIcon.defaultProps = {
  title: null,
  color: "primary"
};

export default TooltipIcon;

const IconWrapper = styled(Icon)`
  color: ${(props) => themeProp(`palette.${props.color}`)};
  cursor: help;

  &:hover {
    color: ${(props) => themeProp(`palette.${props.color}`)};
  }
`;
