import SelectableCard, {
  CardIconBar,
  SelectableCardLeftColumn,
  SelectableCardRightColumn,
  SelectableCardRow
} from "../SelectableCard";
import {
  hasPermission,
  selectDiscoveryBusinessCriticalChallengeCode,
  selectDiscoveryChallenge,
  selectDiscoveryId
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Checkbox from "../../Checkbox";
import React, { useEffect, useRef, useState } from "react";
import { saveDiscoveryChallenge } from "../../../store/actions/discoveries";
import Text from "../../Text";
import { Space } from "antd";
import { CommentsButton } from "../Comments";
import ChallengeCardDropdown from "./ChallengeCardDropdown";
import Permissions from "../../../utils/permissions";
import MobileDetails from "./MobileDetails";
import StandardDetails from "./StandardDetails";
import CategoryBadges from "../CategoryBadges";
import RateImportance from "../../RateImportance";
import { useString as s } from "../../StringProvider";
import { useMobileMediaQuery } from "../../Responsive";
import { Icon, Tooltip } from "../../../components";
import { UserPilotEventNames } from "../../../utils/user-pilot-util";

const ChallengeCard = ({
  discoveryChallenge,
  discoveryId,
  businessCriticalChallengeCode,
  editable,
  reportId,
  canSeeInternalLinksAndComments,
  saveDiscoveryChallenge,
  printMode
}) => {
  const isMobile = useMobileMediaQuery();
  const ref = useRef();
  const [selected, setSelected] = useState(discoveryChallenge.selected);

  const isCBI =
    businessCriticalChallengeCode === discoveryChallenge.challengeCode;

  const importanceForYou = s(
    "discovery.challenge.card.importanceForYou",
    "Importance for you"
  );

  const cbiTooltip = s(
    "discovery.challenge.card.cbi.tooltip",
    "Critical Business Issue"
  );

  useEffect(() => {
    if (selected !== discoveryChallenge.selected) {
      setSelected(discoveryChallenge.selected);
    }
  }, [discoveryChallenge.selected]);

  const onCheck = (value) => {
    setSelected(value);
    saveDiscoveryChallenge({
      discoveryId,
      challengeCode: discoveryChallenge.challengeCode,
      changes: { selected: value }
    });
  };

  const onChangeImportance = ({ importance }) => {
    saveDiscoveryChallenge({
      discoveryId,
      challengeCode: discoveryChallenge.challengeCode,
      changes: { importance }
    });
  };

  const toolbar = (
    <CardIconBar>
      <CommentsButton
        reportId={reportId}
        topic={`/discoveries/${discoveryId}/challenges/${discoveryChallenge.challengeCode}`}
        eventName={UserPilotEventNames.ADD_NEW_COMMENT_CHALLENGE}
      />
      {!reportId && editable ? (
        <ChallengeCardDropdown
          discoveryId={discoveryId}
          challengeCode={discoveryChallenge.challengeCode}
          cardRef={ref}
          isCBI={isCBI}
          isCBIPresent={Boolean(businessCriticalChallengeCode)}
        />
      ) : null}
    </CardIconBar>
  );

  return (
    <SelectableCard
      selected={selected}
      editable={editable}
      data-cy={`challenge-card-${discoveryChallenge.challengeCode}`}
      ref={ref}
      toggle={
        isCBI ? (
          <Tooltip title={cbiTooltip}>
            <Icon name={"flagSolid"} size={"larger"} colour="secondary" />
          </Tooltip>
        ) : (
          <Checkbox
            className={"up-challenge-card-check-box"}
            checked={selected}
            onChange={(v) => onCheck(v.target.checked)}
            disabled={isCBI}
          />
        )
      }
      toolbar={toolbar}
      style={{ minWidth: isMobile ? undefined : "500px" }}
    >
      <SelectableCardRow>
        <SelectableCardLeftColumn>
          <Space direction={"vertical"} size={8}>
            <Text
              variant={isMobile ? "bMediumMobile" : "bMedium"}
              data-cy={"challenge-description"}
              className={"selectable-card-title"}
            >
              {discoveryChallenge.definition.description}
            </Text>
            <CategoryBadges
              showKeyObjectiveBadge={
                businessCriticalChallengeCode ===
                discoveryChallenge.challengeCode
              }
              definition={discoveryChallenge.definition}
              showStatus={!reportId}
            />
          </Space>
        </SelectableCardLeftColumn>
        <SelectableCardRightColumn width={isMobile ? "50px" : "150px"}>
          {isMobile && editable ? null : toolbar}
          {!isCBI && selected && !isMobile ? (
            <RateImportance
              className={"up-challenge-card-importance"}
              label={importanceForYou}
              editable={
                businessCriticalChallengeCode !==
                  discoveryChallenge.challengeCode && editable
              }
              importance={discoveryChallenge.importance}
              onChangeImportance={onChangeImportance}
            />
          ) : null}
        </SelectableCardRightColumn>
      </SelectableCardRow>
      <SelectableCardRow style={{ marginTop: "18px" }}>
        {isMobile ? (
          <MobileDetails
            discoveryChallenge={discoveryChallenge}
            onChangeImportance={onChangeImportance}
            editable={editable}
            reportId={reportId}
            selected={selected}
            canSeeInternalLinksAndComments={canSeeInternalLinksAndComments}
          />
        ) : (
          <StandardDetails
            discoveryChallenge={discoveryChallenge}
            onChangeImportance={onChangeImportance}
            editable={editable}
            reportId={reportId}
            selected={selected}
            canSeeInternalLinksAndComments={canSeeInternalLinksAndComments}
            printMode={printMode}
          />
        )}
      </SelectableCardRow>
    </SelectableCard>
  );
};

ChallengeCard.propTypes = {
  challengeCode: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  reportId: PropTypes.string,
  printMode: PropTypes.bool
};

const mapStateToProps = (state, props) => ({
  discoveryId: selectDiscoveryId(state),
  businessCriticalChallengeCode:
    selectDiscoveryBusinessCriticalChallengeCode(state),
  discoveryChallenge: selectDiscoveryChallenge(state, props.challengeCode),
  canSeeInternalLinksAndComments: hasPermission(
    state,
    Permissions.SEE_INTERNAL_LINKS_AND_COMMENTS
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryChallenge
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChallengeCard
);
