import React from "react";
import styled from "styled-components";
import AlertBox from "../../../../components/AlertBox";
import { useString as s } from "../../../../components/StringProvider";

const DuplicateUserAlert = () => {
  const title = s(
    "accessManagement.page.popup.messages.user.duplicateErrorTitle",
    "Please use different values"
  );
  const message = s(
    "accessManagement.page.popup.messages.user.duplicateError",
    "A User with that email already exists"
  );
  return (
    <NewUserModalAlert title={title}>
      <span data-cy={"user-modal-alert"}>{message}</span>
    </NewUserModalAlert>
  );
};

const NewUserModalAlert = styled(AlertBox)`
  margin-bottom: 16px;
`;

export default DuplicateUserAlert;
