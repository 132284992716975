import { default as SRCMasonry } from "react-masonry-css";
import styled from "styled-components";

const Masonry = ({ children, breakpointCols }) => {
  return (
    <Container>
      <SRCMasonry
        className={"my-masonry-grid"}
        columnClassName={"my-masonry-grid-column"}
        breakpointCols={breakpointCols}
      >
        {children}
      </SRCMasonry>
    </Container>
  );
};

const Container = styled.div`
  & .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -20px; /* gutter size offset */
    width: auto;
  }

  & .my-masonry-grid-column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
  }

  & .my-masonry-grid-column > div {
  }

  /* Style your items */
  & .my-masonry-grid-column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 20px;
  }
`;

export default Masonry;
