import types from "../actionTypes";

export const startEditingKPI =
  ({ kpiCode, position }) =>
  (dispatch) => {
    dispatch({
      type: types.START_EDITING_KPI,
      payload: { kpiCode, position }
    });
  };

export const stopEditingKPI = () => (dispatch) => {
  dispatch({
    type: types.STOP_EDITING_KPI
  });
};
