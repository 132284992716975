import styled from "styled-components";
import { useDrop } from "react-dnd";
import PropTypes from "prop-types";
import ItemTypes from "../../components/ItemTypes";
import { DropDirection } from "./ValueMapCard";
import { bottomAreaItem } from "./ValueMapList";

const ValueMapBottomArea = ({ onReorder }) => {
  const onDrop = (item) => {
    onReorder({
      code: item.code,
      destinationCode: bottomAreaItem.code,
      direction: DropDirection.BOTTOM
    });
  };

  const [{}, dropRef] = useDrop(() => ({
    accept: [ItemTypes.VALUE_MAP_CHALLENGE, ItemTypes.VALUE_MAP_KPI],
    drop: onDrop,
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver()
    })
  }));

  return <Container ref={dropRef} />;
};

const Container = styled.div`
  height: 100%;
`;

ValueMapBottomArea.propTypes = {
  onReorder: PropTypes.func.isRequired
};

export default ValueMapBottomArea;
