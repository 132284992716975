import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import QuestionVisualizations from "../../../utils/question-visualizations";
import { Col, Row } from "antd";
import RadioButtonCard from "./RadioButtonCard";
import { useMobileOrTabletMediaQuery } from "../../../components/Responsive";
import {
  selectDiscoveryQuestion,
  selectQuestion
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { RadioGroup } from "../../../components/Radio";

const RadioButtons = ({
  questionNumber,
  question,
  discoveryQuestion,
  width,
  editable,
  ...props
}) => {
  const isMobileOrTablet = useMobileOrTabletMediaQuery();
  const filteredOptions = question.options.filter(
    (option) => !discoveryQuestion.inactiveOptions.includes(option.value)
  );

  const OneColumnCRadioButtons = () => (
    <CheckBoxContainer width={width} isMobileOrTablet={isMobileOrTablet}>
      {filteredOptions.map((item, index) => (
        <RadioButtonCard
          key={index}
          iconStyle={{ marginRight: "28px" }}
          style={{
            marginBottom: isMobileOrTablet ? "8px" : "16px",
            padding: "18px 26px",
            alignItems: "center",
            display: "flex"
          }}
          text={item.label}
          editable={editable}
          questionNumber={questionNumber}
          value={item.value}
        />
      ))}
    </CheckBoxContainer>
  );

  if (isMobileOrTablet) {
    return (
      <RadioGroup {...props}>
        <OneColumnCRadioButtons />
      </RadioGroup>
    );
  }

  return question.questionVisualization ===
    QuestionVisualizations.ONE_COLUMN_RADIO_BUTTONS ? (
    <RadioGroup {...props}>
      <OneColumnCRadioButtons />
    </RadioGroup>
  ) : (
    <RadioGroup {...props}>
      <Row gutter={[24, 8]} style={{ marginTop: 17, marginBottom: 19 }}>
        {filteredOptions.map((item, index) => (
          <Col span={12} key={index}>
            <RadioButtonCard
              style={{ maxWidth: 320, display: "flex", alignItems: "center" }}
              text={item.label}
              editable={editable}
              questionNumber={questionNumber}
              value={item.value}
            />
          </Col>
        ))}
      </Row>
    </RadioGroup>
  );
};

const CheckBoxContainer = styled.div`
  margin-top: 17px;
  margin-bottom: 19px;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => {
    if (props.isMobileOrTablet) {
      return "100%";
    }

    return props.width === "wide" ? "560px;" : "400px;";
  }}
  min-width: ${(props) => {
    if (props.isMobileOrTablet) {
      return "100%";
    }

    return props.width === "wide" ? "560px;" : "400px;";
  }}
`;

RadioButtons.propTypes = {
  width: PropTypes.oneOf(["normal", "wide"]).isRequired,
  editable: PropTypes.bool.isRequired,
  questionNumber: PropTypes.number.isRequired
};

RadioButtons.defaultProps = {
  width: "normal"
};

const mapStateToProps = (state, props) => {
  const discoveryQuestion = selectDiscoveryQuestion(
    state,
    parseInt(props.questionNumber)
  );

  return {
    question: selectQuestion(state, discoveryQuestion.questionCode),
    discoveryQuestion
  };
};

export default compose(connect(mapStateToProps))(RadioButtons);
