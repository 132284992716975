import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon, NewButton } from "../..";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import useCopyClipboard from "../../../utils/use-copy-clipboard";
import { useString as s } from "../../../components/StringProvider";
import AiChatRoleNames from "../../../utils/constants/ai-chat-role-names";
import { useChat } from "./ChatProvider";
import AiChatLoadingNames from "../../../utils/constants/ai-chat-loading-names";

const ChatControlPanel = ({ role, content, isRegenerateActive }) => {
  const copySuccessMessage = s(
    "aiDiscoveryModal.chat.control.panel.copySuccess",
    "Response copied!"
  );
  const copyFailedMessage = s(
    "aiDiscoveryModal.chat.control.panel.copyError",
    "Response failed to copy!"
  );

  const ControlNames = {
    REGENERATE: s(
      "aiDiscoveryModal.chat.control.panel.regenerate",
      "Regenerate"
    ),
    COPY: s("aiDiscoveryModal.chat.control.panel.copy", "Copy"),
    LIKE: s("aiDiscoveryModal.chat.control.panel.like", "Like"),
    DISLIKE: s("aiDiscoveryModal.chat.control.panel.dislike", "Bad response")
  };

  const { handleRegenerate, chatLoading } = useChat();
  const [feedBack, setFeedBack] = useState("");

  const { copyToClipboard } = useCopyClipboard({
    showNotification: true,
    copySuccessMessage,
    copyFailedMessage
  });

  const controls = [
    {
      name: ControlNames.REGENERATE,
      iconName: "retry",
      active:
        !(
          chatLoading.active &&
          chatLoading.type === AiChatLoadingNames.REGENERATING
        ) && isRegenerateActive,
      disabled: chatLoading.active
    },
    {
      name: ControlNames.COPY,
      iconName: "copyAll",
      active: true,
      disabled: false
    },
    {
      name: ControlNames.LIKE,
      iconName: "thumbUp",
      active: true,
      disabled: false
    },
    {
      name: ControlNames.DISLIKE,
      iconName: "thumbDown",
      active: true,
      disabled: false
    }
  ];

  const handleFeedback = (feedbackName) => {
    setFeedBack((prevFeedback) =>
      prevFeedback === feedbackName ? "" : feedbackName
    );
  };

  const handleControl = (controlName) => {
    switch (controlName) {
      case ControlNames.REGENERATE:
        handleRegenerate();
        break;
      case ControlNames.COPY:
        copyToClipboard(content);
        break;
      case ControlNames.LIKE:
        handleFeedback(ControlNames.LIKE);
        break;
      case ControlNames.DISLIKE:
        handleFeedback(ControlNames.DISLIKE);
        break;
      default:
        null;
    }
  };

  if (role !== AiChatRoleNames.ASSISTANT) {
    return null;
  }

  return (
    <PanelContainer>
      {controls.map((item, index) =>
        item.active ? (
          <NewButton
            key={index}
            type={"iconDarkPrimary"}
            tooltip={item.name}
            onClick={() => handleControl(item.name)}
            disabled={item.disabled}
            className={feedBack === item.name ? "active" : "inactive"}
          >
            <StyledIcon
              name={item.iconName}
              size={"medium"}
              $isActive={feedBack == item.name}
            />
          </NewButton>
        ) : null
      )}
    </PanelContainer>
  );
};

ChatControlPanel.propTypes = {
  role: PropTypes.oneOf([AiChatRoleNames.ASSISTANT, AiChatRoleNames.USER])
    .isRequired,
  content: PropTypes.string.isRequired,
  isRegenerateActive: PropTypes.bool.isRequired
};

const PanelContainer = styled.div`
  margin-top: 20px;

  button:hover {
    svg {
      color: ${themeProp(`palette.secondary`)};
    }
  }

  button:focus {
    background-color: transparent !important;
  }
`;

const StyledIcon = styled(Icon)`
  svg {
    color: ${(props) =>
      props.$isActive
        ? themeProp(`palette.secondary`)
        : themeProp(`palette.gray4`)};
  }
`;

export default ChatControlPanel;
