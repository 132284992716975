import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useString as s } from "../../../../../components/StringProvider";
import PropTypes from "prop-types";
import {
  selectAllFiles,
  selectFileAdditionalData,
  selectFileFileId,
  selectFileState,
  selectFileValidationId,
  selectRequestData,
  selectRequestState
} from "../../../../../store/reducers";
import NewButton from "../../../../../components/NewButton";
import { Alert, Modal } from "../../../../../components";
import Hyperlink from "../../../../../components/Hyperlink";
import { Paragraph } from "../../../../../components/Typography";
import {
  FileDragger,
  FileUploadStatus,
  ValidationErrors
} from "../../../ManageConfiguration/components";
import styled from "styled-components";
import {
  addFileToList,
  clearFileList
} from "../../../../../store/actions/file-list";
import ButtonGroup from "../../../../../components/ButtonGroup";
import {
  createValidation,
  deployValidation
} from "../../../../../store/actions/validations";
import ValidationWorkflowTypes from "../../../../../utils/validation-workflow-types";
import { FileStates } from "../../../../../store/reducers/file-list";
import actionTypes from "../../../../../store/actionTypes";
import useLoadingState from "../../../../../utils/use-loading-state";
import JobMonitor from "../../../../../components/JobMonitor";
import { message } from "antd";
import GuideNames from "../../../../../utils/constants/GuideNames";
import openDownload from "../../../../../utils/open-download";
import { downloadGuide } from "../../../../../store/actions/configurations";

const AddUsersInBulkModal = ({
  onClose,
  files,
  fileId,
  validationId,
  fileState,
  additionalData,
  validation,
  validationLoadingState,
  deploymentLoadingState,
  deploymentRequestData,
  downloadGuideLoadingState,
  download,
  downloadGuide,
  addFileToList,
  clearFileList,
  createValidation,
  deployValidation,
  doUsersRefresh
}) => {
  const title = s(
    "accessManagement.popup.addUsersInBulk.title",
    "Add Users in Bulk"
  );
  const header = s(
    "accessManagement.popup.addUsersInBulk.header",
    "Please upload an .xlsx file with your user list using the provided template that outlines the standards for bulk user uploads."
  );
  const downloadTemplate = s(
    "accessManagement.popup.addUsersInBulk.downloadTemplate",
    "Download Template"
  );
  const startValidation = s(
    "addUsersInBulk.popup.startValidation.button",
    "Start Validation"
  );
  const cancel = s("addUsersInBulk.popup.cancel.button", "Cancel");
  const addUsers = s("addUsersInBulk.popup.addUsers.button", "Add Users");
  const deploymentSuccess = s(
    "addUsersInBulk.popup.messages.deploymentSuccess",
    "User data successfully updated"
  );
  const deploymentFailure = s(
    "addUsersInBulk.popup.messages.deploymentFailure",
    "Failed to update user data"
  );
  const createdUsersCount = s(
    "addUsersInBulk.popup.messages.createdUsersCount",
    "{count} new {count, plural, one {user} other {users}} will be added",
    { count: additionalData?.created }
  );
  const modifiedUsersCount = s(
    "addUsersInBulk.popup.messages.modifiedUsersCount",
    "{count} {count, plural, one {user} other {users}} will be modified",
    { count: additionalData?.modified }
  );
  const downloadError = s(
    "addUsersInBulk.popup.messages.downloadError",
    "Error downloading guide"
  );
  const [jobId, setJobId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    clearFileList();
  }, []);

  useLoadingState(
    deploymentLoadingState,
    () => {
      setJobId(deploymentRequestData._id);
      setLoading(true);
    },
    () => {}
  );

  useLoadingState(
    validationLoadingState,
    () => {
      setLoading(false);
    },
    () => {
      setLoading(false);
    },
    () => {
      setLoading(true);
    }
  );

  useLoadingState(
    downloadGuideLoadingState,
    () => {
      console.log("download", download);
      openDownload(download.url);
    },
    () => {
      message.error(downloadError);
    }
  );

  const onDownloadTemplate = (e) => {
    e.preventDefault();
    downloadGuide({ guideName: GuideNames.BULK_USER_UPLOAD_GUIDE });
  };

  const onStartUpload = (file) => {
    addFileToList({ file });
  };

  const onStartValidation = () => {
    createValidation({
      fileId,
      workflowType: ValidationWorkflowTypes.BULK_USER_UPLOAD
    });
  };

  const onAddUsers = () => {
    deployValidation({ validationId });
  };

  const onJobFinish = (successful) => {
    if (successful) {
      message.success(deploymentSuccess);
      doUsersRefresh();
      setLoading(false);
      onClose();
    } else {
      message.error(deploymentFailure);
      setJobId("");
      setLoading(false);
    }
  };

  const renderButtons = () => {
    switch (fileState) {
      case FileStates.UPLOAD_COMPLETE:
      case FileStates.VALIDATING:
        return (
          <ButtonGroup>
            <NewButton
              type={"primary"}
              onClick={onStartValidation}
              loading={loading}
              data-cy={"start-validation-button"}
            >
              {startValidation}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onClose}
              data-cy={"cancel-button"}
            >
              {cancel}
            </NewButton>
          </ButtonGroup>
        );
      case FileStates.VALIDATION_COMPLETE:
        return (
          <ButtonGroup>
            <NewButton
              type={"primary"}
              onClick={onAddUsers}
              loading={loading}
              data-cy={"add-users-button"}
            >
              {addUsers}
            </NewButton>
            <NewButton
              type={"secondary"}
              onClick={onClose}
              data-cy={"cancel-button"}
            >
              {cancel}
            </NewButton>
          </ButtonGroup>
        );
    }
  };

  return (
    <Modal title={title} open={true} onCancel={onClose} width={580}>
      <Container>
        <Paragraph variant={"smallBody"}>
          {header}{" "}
          <Hyperlink
            to={"#"}
            onClick={onDownloadTemplate}
            color={"primary"}
            data-cy={"download-template"}
          >
            {downloadTemplate}
          </Hyperlink>
        </Paragraph>
        {files.length === 0 ? (
          <FileDragger onStartUpload={onStartUpload} acceptTypes={".xlsx"} />
        ) : (
          <div>
            {files.map((file) => (
              <FileUploadStatus key={file.uid} file={file} icon={"xls"} />
            ))}
          </div>
        )}
        {(additionalData?.created || additionalData?.modified) &&
        !validation?.validationErrors?.length ? (
          <AlertList>
            {!!additionalData?.created ? (
              <Alert
                message={createdUsersCount}
                type={"success"}
                variant={"smallBody"}
                showIcon
                data-cy={"created-users-alert"}
              />
            ) : null}
            {!!additionalData?.modified ? (
              <Alert
                message={modifiedUsersCount}
                type={"warning"}
                variant={"smallBody"}
                showIcon
                data-cy={"modified-users-alert"}
              />
            ) : null}
          </AlertList>
        ) : null}
        {renderButtons()}
        {fileState === FileStates.VALIDATION_COMPLETE_WITH_ERRORS && (
          <ValidationErrors validation={validation} />
        )}
        {jobId && <JobMonitor jobId={jobId} onFinish={onJobFinish} />}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 24px;
`;

const AlertList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 12px;
`;

AddUsersInBulkModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  doUsersRefresh: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const files = selectAllFiles(state);

  return {
    files,
    fileState: selectFileState(state, files[0]?.uid),
    fileId: selectFileFileId(state, files[0]?.uid),
    validationId: selectFileValidationId(state, files[0]?.uid),
    additionalData: selectFileAdditionalData(state, files[0]?.uid),
    validation: selectRequestData(state, actionTypes.CREATE_VALIDATION_REQUEST),
    validationLoadingState: selectRequestState(
      state,
      actionTypes.CREATE_VALIDATION_REQUEST
    ),
    deploymentLoadingState: selectRequestState(
      state,
      actionTypes.DEPLOY_VALIDATION_REQUEST
    ),
    deploymentRequestData: selectRequestData(
      state,
      actionTypes.DEPLOY_VALIDATION_REQUEST
    ),
    download: selectRequestData(state, actionTypes.DOWNLOAD_GUIDE_REQUEST),
    downloadGuideLoadingState: selectRequestState(
      state,
      actionTypes.DOWNLOAD_GUIDE_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearFileList,
      addFileToList,
      createValidation,
      deployValidation,
      downloadGuide
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddUsersInBulkModal
);
