import styled from "styled-components";

const ROIFormHeader = styled.div`
  margin-bottom: 16px;

  &.mobile {
    text-align: center;
    margin-bottom: 24px;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 8px;
  }
`;

export default ROIFormHeader;
