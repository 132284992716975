import { selectedKPIsFilter } from "./filters/kpi-filters";

const getKPIsImpactedByMetrics = ({ discoveryKPI, discoveryKPIs }) => {
  const metricMappings = discoveryKPI?.definition?.metricMappings;
  const selectedKPIs = selectedKPIsFilter(discoveryKPIs).filter(
    (kpi) => kpi.kpiCode !== discoveryKPI.kpiCode
  );

  return selectedKPIs.filter((kpi) =>
    kpi?.definition?.metricMappings?.some((metric) =>
      metricMappings?.includes(metric)
    )
  );
};

export default getKPIsImpactedByMetrics;
