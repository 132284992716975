import styled from "styled-components";
import { TextArea } from "../../Input";
import { useMobileMediaQuery } from "../../Responsive";
import { themeProp } from "../../../theme";
import React from "react";

const BenefitTextArea = React.forwardRef(({ ...props }, inputRef) => {
  const isMobile = useMobileMediaQuery();

  return (
    <StyledTextArea
      {...props}
      className={isMobile ? "mobile" : undefined}
      ref={inputRef}
    />
  );
});

const StyledTextArea = styled(TextArea)`
  padding-left: 0px;

  &.mobile {
    font-family: ${themeProp("typography.small.fontFamily")};
    font-size: ${themeProp("typography.small.fontSize")};
    font-weight: ${themeProp("typography.small.fontWeight")};
    line-height: ${themeProp("typography.small.lineHeight")};
    padding-left: 11px;
  }
`;

export default BenefitTextArea;
