import types from "../actionTypes";

export const startDeletingChallenge =
  ({ challengeCode }) =>
  (dispatch) => {
    dispatch({
      type: types.START_DELETING_CHALLENGE,
      payload: { challengeCode }
    });
  };

export const stopDeletingChallenge = () => (dispatch) => {
  dispatch({
    type: types.STOP_DELETING_CHALLENGE
  });
};
