import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import styled, {
  ThemeProvider as StyledThemeProvider
} from "styled-components";
import WebFont from "webfontloader";
import { ThemeError } from "../Pages/ErrorPage";
import {
  selectRequestData,
  selectRequestError,
  selectRequestState
} from "../store/reducers";
import actionTypes from "../store/actionTypes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getTheme } from "../store/actions/themes";
import useLoadingState from "../utils/use-loading-state";

const getFontsFromTheme = (theme) => {
  const fonts = [];
  const fontMap = {};

  for (const [, value] of Object.entries(theme.typography || {})) {
    const fontList = value.fontFamily ? value.fontFamily.split(",") : [];

    for (const i in fontList) {
      const font = fontList[i].trim();
      const fontWeight = value.fontWeight;

      if (!font.match(/^(serif|sans-serif)$/)) {
        if (!fontMap[font]) {
          fontMap[font] = ["400"];
        }

        if (fontWeight && !fontMap[font].includes(fontWeight)) {
          fontMap[font] = [...fontMap[font], fontWeight];
        }
      }
    }
  }

  for (const [key, value] of Object.entries(fontMap)) {
    fonts.push(`${key}${value.length ? ":" : ""}${value.join(",")}`);
  }

  return fonts;
};

export const ThemeContext = createContext();
export const ThemeProvider = ({
  children,
  theme,
  loadingState,
  getTheme,
  error
}) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    getTheme();
  }, []);

  useEffect(() => {
    if (theme) {
      const fonts = getFontsFromTheme(theme);

      if (fonts.length > 0) {
        const timeout = setTimeout(() => {
          console.log("Font loading timeout");
          setFontsLoaded(true);
        }, 10000);

        WebFont.load({
          google: {
            families: fonts
          },
          active: () => {
            clearTimeout(timeout);
            setFontsLoaded(true);
          },
          inactive: () => {
            clearTimeout(timeout);
            setFontsLoaded(true);
          },
          fontinactive: (familyName) => {
            console.log(`Failed to load font '${familyName}'`);
          }
        });
      } else {
        setFontsLoaded(true);
      }
    }
  }, [theme]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      console.error("theme retrieval failed", error);
      setFailed(true);
    }
  );

  return theme && fontsLoaded ? (
    <ThemeContext.Provider
      value={{
        theme
      }}
    >
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  ) : failed ? (
    <ThemeError />
  ) : (
    <ThemeLoader />
  );
};
export const useTheme = (key, defaultValue) => {
  const { theme } = useContext(ThemeContext);
  return _.get(theme, key, defaultValue);
};

const mapStateToProps = (state) => ({
  theme: selectRequestData(state, actionTypes.GET_THEME_REQUEST) || {},
  loadingState: selectRequestState(state, actionTypes.GET_THEME_REQUEST),
  error: selectRequestError(state, actionTypes.GET_THEME_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTheme
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ThemeProvider);

const ThemeLoader = styled.div``;
