import React, { useImperativeHandle, useRef } from "react";

const Content = React.forwardRef(
  ({ type, color, tooltip, children, style, ...props }, ref) => {
    const sentinelStartRef = useRef();
    const sentinelEndRef = useRef();
    const contentRef = useRef();

    const sentinelStyle = {
      width: 0,
      height: 0,
      overflow: "hidden",
      outline: "none"
    };
    const contentStyle = {
      backgroundColor: "white",
      backgroundClip: "padding-box",
      borderRadius: 12
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        window.scrollTo({
          top: 140 + (props.heightOffset || 0),
          left: 0,
          behavior: "smooth"
        });
        //sentinelStartRef.current.focus({preventScroll: true});
      },
      changeActive: (next) => {
        const { activeElement } = document;
        if (next && activeElement === sentinelEndRef.current) {
          sentinelStartRef.current.focus();
        } else if (!next && activeElement === sentinelStartRef.current) {
          sentinelEndRef.current.focus();
        }
      }
    }));

    return (
      <div
        key="dialog-element"
        role="document"
        style={{ ...contentStyle, ...style }}
      >
        <div
          tabIndex={0}
          ref={sentinelStartRef}
          style={sentinelStyle}
          aria-hidden="true"
        />
        <div className={"scrolling-modal-content"}>
          <div className={"scrolling-modal-body"} ref={contentRef} {...props}>
            {children}
          </div>
        </div>
        <div
          tabIndex={0}
          ref={sentinelEndRef}
          style={sentinelStyle}
          aria-hidden="true"
        />
      </div>
    );
  }
);

export default Content;
