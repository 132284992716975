const MetricTypes = {
  PERCENTAGE: "Percentage",
  INTEGER: "Integer",
  CURRENCY: "Currency",
  NUMBER2DP: "Number2DP",
  PERCENTAGE1DP: "Percentage1DP"
};

MetricTypes.ALL = Object.values(MetricTypes);

export default MetricTypes;
