import Form, { FormItem } from "../../components/Form";
import Input, { PasswordInput } from "../../components/Input";
import { useString as s } from "../../components/StringProvider";
import NewButton from "../../components/NewButton";
import React from "react";
import PropTypes from "prop-types";
import { CognitoError } from "../../components";
import LoginFormContainer, { FormContainer } from "../Login/LoginFormContainer";
import Heading from "../../components/Heading";
import ButtonGroup from "../../components/ButtonGroup";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { selectForgotPasswordEmail } from "../../store/reducers";
import { connect } from "react-redux";
import Markdown from "../../components/Discovery/Markdown";

const ResetPasswordForm = ({
  history,
  email,
  form,
  onFinish,
  invitation,
  error,
  loading
}) => {
  const invitationPageHeader = s(
    "resetPassword.page.invitations.header",
    "Create a Password"
  );
  const pageHeader = s("resetPassword.page.header", "Password Reset");
  const invitationPageSubheader = s(
    "resetPassword.page.invitations.subheader",
    "Enter a password to create your account."
  );
  const pageSubheader = s(
    "resetPassword.page.subheader",
    "We’ve sent a code to **{email}** if this account exists. Please check your email and type the code below. If this account doesn’t exist, or if you’ve never logged in, please go to sign up instead.",
    { email }
  );
  const codePlaceholder = s(
    "resetPassword.code.placeholder",
    "Code from email"
  );
  const codeValidationError = s(
    "resetPassword.code.validation",
    "Code from email"
  );
  const passwordPlaceholder = s(
    "resetPassword.password.placeholder",
    "New password"
  );
  const passwordValidationError = s(
    "resetPassword.password.validation",
    "Please enter a password of at least 8 characters"
  );
  const confirmPasswordPlaceholder = s(
    "resetPassword.confirmPassword.placeholder",
    "Confirm password"
  );
  const confirmPasswordValidationError = s(
    "resetPassword.confirmPassword.validation",
    "Please confirm your password"
  );
  const confirmPasswordMismatchError = s(
    "resetPassword.confirmPassword.mismatch",
    "Confirm password must match password"
  );
  const setNewPassword = s(
    "resetPassword.page.setNewPasswordButton",
    "Set new password"
  );
  const backToLogin = s("resetPassword.page.backToLogin", "Back to log in");

  const onLogin = () => {
    if (invitation?.code) {
      history.push({ pathname: `/invitations/${invitation?.code}/login` });
    } else {
      history.push({ pathname: "/login" });
    }
  };

  return (
    <LoginFormContainer>
      <FormContainer>
        <Heading level={"h2"} data-cy={"page-header"}>
          {invitation ? invitationPageHeader : pageHeader}
        </Heading>
        <div className={"subheader"} data-cy={"page-subheader"}>
          <Markdown remarkPlugins={[]}>
            {invitation ? invitationPageSubheader : pageSubheader}
          </Markdown>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout={"vertical"}
          className={"login"}
        >
          <FormItem
            name="code"
            rules={[{ required: true, message: codeValidationError }]}
          >
            <Input
              placeholder={codePlaceholder}
              autoComplete={"one-time-code"}
            />
          </FormItem>

          <FormItem
            name="password"
            rules={[
              {
                required: true,
                message: passwordValidationError
              },
              {
                min: 8,
                message: passwordValidationError
              }
            ]}
            hasFeedback
          >
            <PasswordInput
              autoComplete="new-password"
              placeholder={passwordPlaceholder}
            />
          </FormItem>

          <FormItem
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: confirmPasswordValidationError
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(confirmPasswordMismatchError)
                  );
                }
              })
            ]}
            hasFeedback
            dependencies={["password"]}
          >
            <PasswordInput
              autoComplete="new-password"
              placeholder={confirmPasswordPlaceholder}
            />
          </FormItem>

          {error ? <CognitoError error={error} /> : null}

          <ButtonGroup mobile={true}>
            <NewButton
              type={"submit"}
              data-cy={"reset-password-button"}
              loading={loading}
              disabled={loading}
            >
              {setNewPassword}
            </NewButton>
            <NewButton
              type={"secondary"}
              to={
                invitation?.code
                  ? `/invitations/${invitation?.code}/login`
                  : "/login"
              }
              data-cy={"back-to-login-button"}
              onClick={onLogin}
            >
              {backToLogin}
            </NewButton>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </LoginFormContainer>
  );
};

ResetPasswordForm.propTypes = {
  form: PropTypes.object.isRequired,
  error: PropTypes.string,
  onFinish: PropTypes.func.isRequired,
  invitation: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  return {
    email: selectForgotPasswordEmail(state)
  };
};

export default compose(withRouter, connect(mapStateToProps))(ResetPasswordForm);
