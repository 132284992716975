import React from "react";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import { useTheme } from "../../../components/ThemeProvider";
import { themeProp } from "../../../theme";
import { PageSection } from "../../../components/Page";
import MobileReportSection from "./MobileReportSection";
import Markdown from "../../../components/Discovery/Markdown";
import { useMobileMediaQuery } from "../../../components/Responsive";

const ReportOutro = ({ discoveryName }) => {
  const isMobile = useMobileMediaQuery();
  const defaultCoverImage = useTheme("assets.report_header_cover");
  const coverImage = useTheme("assets.report_end_cover");
  const header = s("report.end.header", "", { discoveryName });
  const footerImage = coverImage || defaultCoverImage;

  return isMobile ? (
    <MobileReportSection data-cy={"report-footer"} className={"fullpage"}>
      <ReportFooterText className={"mobile"}>
        <Markdown size={header.length < 130 ? "h3" : "h4"}>{header}</Markdown>
      </ReportFooterText>
      <ReportFooterImage src={footerImage} className={"mobile"} />
    </MobileReportSection>
  ) : (
    <ReportFooterContainer data-cy={"report-footer"}>
      <ReportFooterImage src={footerImage} />
      <ReportFooterText>
        <Markdown size={"h2"}>{header}</Markdown>
      </ReportFooterText>
    </ReportFooterContainer>
  );
};

const ReportFooterContainer = styled(PageSection)`
  display: flex;
  background: ${themeProp("palette.surface")};
  padding-top: 120px;
`;
const ReportFooterImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 400px;

  &.mobile {
    height: unset;
  }

  @media screen and (min-width: 900px) {
    height: 600px;
  }
`;
const ReportFooterText = styled.div`
  padding: 0 24px;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.mobile {
    flex: 0;
    padding-top: 120px;
    padding-bottom: 20px;
  }
`;

export default ReportOutro;
