import styled from "styled-components";
import { themeProp } from "../../theme";
import PropType from "prop-types";
import Link from "../../components/Link";
import { Space } from "antd";
import { Phases } from "../../utils/phases";
import { useMobileMediaQuery } from "../../components/Responsive";
import { HeaderMenuItem } from "../../components/Header";
import React from "react";
import QuestionsNavigator from "./QuestionsNavigator";
import { useLocation } from "react-router-dom";

const DiscoverySubHeader = ({ phase, linkConfig = [], onNext }) => {
  const isMobile = useMobileMediaQuery();
  const location = useLocation();

  const isLinkSelected = (to) => {
    return location.pathname + location.search === to;
  };

  const isLinkEnabled = (linkItem) => {
    if (!linkItem.hasOwnProperty("conditionToEnableLink")) {
      return true;
    }

    return linkItem.conditionToEnableLink;
  };

  const LinksNavigator = () => {
    return (
      linkConfig.length > 0 && (
        <Space direction={"horizontal"} size={32}>
          {linkConfig.map((linkItem, index) => {
            return (
              <HeaderMenuItem key={index}>
                {isLinkEnabled(linkItem) && (
                  <Link
                    to={linkItem.to}
                    color={
                      isLinkSelected(linkItem.to)
                        ? "subheaderSelected"
                        : "subheaderUnselected"
                    }
                  >
                    {linkItem.label}
                  </Link>
                )}
                {!isLinkEnabled(linkItem) && (
                  <DisabledLink>{linkItem.label}</DisabledLink>
                )}
              </HeaderMenuItem>
            );
          })}
        </Space>
      )
    );
  };

  return (
    <DiscoverySubHeaderContainer
      data-cy={"discovery-sub-header"}
      className={isMobile ? "mobile" : undefined}
      linkConfig={linkConfig}
      phase={phase}
    >
      <HeaderMiddleSection
        className={isMobile ? "mobile" : undefined}
        phase={phase}
      >
        {phase === Phases.QUESTIONS && <QuestionsNavigator onNext={onNext} />}
        {linkConfig && LinksNavigator()}
      </HeaderMiddleSection>
    </DiscoverySubHeaderContainer>
  );
};

const DisabledLink = styled.div`
  cursor: not-allowed;
  color: ${() => themeProp(`components.button.subheaderUnselected.base`)};
`;

const DiscoverySubHeaderContainer = styled.div`
  background: ${themeProp("palette.surface")};
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  display: flex;
  justify-content: ${(props) =>
    props.linkConfig || props.phase === Phases.QUESTIONS
      ? "space-between"
      : "flex-end"};
  height: 60px;
  align-items: center;
  width: 100%;
  top: 80px;
  left: 0;
  position: fixed;
  z-index: 5;
  padding-left: 40px;
  padding-right: 40px;

  body.scrollbar-gap & {
    padding-right: ${(props) =>
      themeProp("constants.scrollBarWidth")(props) + 40}px;
  }

  a {
    font-weight: ${themeProp("typography.bMedium.fontWeight")};
    font-family: ${themeProp("typography.label.fontFamily")};
  }

  &.mobile {
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const HeaderMiddleSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: ${(props) =>
    props.phase === Phases.QUESTIONS ? "0px" : "30px"};

  &.mobile {
    margin-left: 0;
    height: 40px;
  }
`;

DiscoverySubHeader.propTypes = {
  linkConfig: PropType.array,
  rocketShipEditable: PropType.bool,
  phase: PropType.oneOf(Phases.ALL).isRequired
};

export default DiscoverySubHeader;
