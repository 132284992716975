import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import Page from "../../components/Page";
import { fetchDiscoveryByReportId } from "../../store/actions/discoveries";
import {
  selectDiscovery,
  selectDiscoveryCurrency,
  selectDiscoveryLoadingError,
  selectDiscoveryLoadingState,
  selectDiscoveryName,
  selectDiscoverySelectedKPIs,
  selectMasterDataLoadingError,
  selectMasterDataLoadingState
} from "../../store/reducers";
import { isPhaseLessThanOrEqualTo } from "../../utils/phases";
import { getMasterDataForReportId } from "../../store/actions/master-data";
import ErrorPage from "../ErrorPage";
import LoadingState, { combineLoadingStates } from "../../utils/loading-state";
import ReportPrintLayout from "../../components/Layout/ReportPrintLayout";
import PrintReportChallenges from "./components/PrintReportChallenges";
import PrintReportCoverOne from "./components/PrintReportCoverOne";
import PrintReportKPIs from "./components/PrintReportKPIs";
import PrintReportROIOne from "./components/PrintReportROIOne";
import PrintReportROITwo from "./components/PrintReportROITwo";
import PrintReportValueHypothesisOne from "./components/PrintReportValueHypothesisOne";
import PrintReportValueHypothesisTwo from "./components/PrintReportValueHypothesisTwo";
import PrintReportEnd from "./components/PrintReportEnd";
import PrintReportCoverTwo from "./components/PrintReportCoverTwo";
import PrintReportYourSituation from "./components/PrintReportYourSituation";
import PrintCostOfStatusQuo from "./components/PrintCostOfStatusQuo";
import PrintReportROIChart from "./components/PrintReportROIChart";

const PrintReport = ({
  discovery,
  name,
  currency,
  discoveryKPIs,
  loadingState,
  loadingError,
  fetchDiscoveryByReportId,
  getMasterDataForReportId
}) => {
  const { reportId, section } = useParams();
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const templateParameter = params.get("template");
  const [template, setTemplate] = useState();

  useEffect(() => {
    if (!discovery || discovery.reportId !== reportId) {
      fetchDiscoveryByReportId({ reportId });
    }
  }, [reportId, discovery, fetchDiscoveryByReportId]);

  useEffect(() => {
    getMasterDataForReportId({ reportId });
  }, [reportId, getMasterDataForReportId]);

  useEffect(() => {
    if (discovery) {
      if (
        templateParameter &&
        isPhaseLessThanOrEqualTo(templateParameter, discovery.phase)
      ) {
        setTemplate(templateParameter);
      } else {
        setTemplate(discovery.phase);
      }
    }
  }, [discovery, templateParameter]);

  if (loadingError) {
    return <ErrorPage error={loadingError} />;
  }

  if (!discovery || loadingState !== LoadingState.COMPLETED || !template) {
    return <Page />;
  }

  const renderSection = () => {
    switch (section) {
      case "cover-one":
        return <PrintReportCoverOne />;
      case "cover-two":
        return <PrintReportCoverTwo />;
      case "kpis":
        return <PrintReportKPIs discovery={discovery} />;
      case "challenges":
        return <PrintReportChallenges discovery={discovery} />;
      case "roi-one":
        return <PrintReportROIOne />;
      case "roi-two":
        return <PrintReportROITwo />;
      case "value-hypothesis-one":
        return (
          <PrintReportValueHypothesisOne
            name={name}
            reportId={reportId}
            currency={currency}
            kpis={discoveryKPIs}
          />
        );
      case "value-hypothesis-two":
        return (
          <PrintReportValueHypothesisTwo
            currency={currency}
            kpis={discoveryKPIs}
          />
        );
      case "your-situation":
        return (
          <PrintReportYourSituation discovery={discovery} template={template} />
        );
      case "cost-of-status-quo":
        return (
          <PrintCostOfStatusQuo currency={currency} kpis={discoveryKPIs} />
        );
      case "roi-chart":
        return <PrintReportROIChart />;
      case "end":
        return <PrintReportEnd discoveryName={discovery.name} />;
    }
  };

  return !discovery ||
    loadingState !== LoadingState.COMPLETED ||
    !template ? null : (
    <Page id={"report"}>
      <ReportPrintLayout>{renderSection()}</ReportPrintLayout>
    </Page>
  );
};
const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  name: selectDiscoveryName(state),
  currency: selectDiscoveryCurrency(state),
  discoveryKPIs: selectDiscoverySelectedKPIs(state),
  loadingState: combineLoadingStates([
    selectDiscoveryLoadingState(state),
    selectMasterDataLoadingState(state)
  ]),
  loadingError:
    selectDiscoveryLoadingError(state) || selectMasterDataLoadingError(state)
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDiscoveryByReportId,
      getMasterDataForReportId
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PrintReport);
