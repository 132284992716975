import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Space, message } from "antd";
import NewButton from "../../../../components/NewButton";
import { useString as s } from "../../../../components/StringProvider";
import Icon from "../../../../components/Icon";
import Table from "../../../../components/Table";
import React from "react";
import { exportValidation } from "../../../../store/actions/validations";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import openDownload from "../../../../utils/open-download";
import ValidationExportTypes from "../../../../utils/validation-export-types";
import ValidationWorkflowTypes from "../../../../utils/validation-workflow-types";
import { Alert } from "../../../../components";

const ValidationErrors = ({
  validation,
  exportValidation,
  exportValidationLoadingState,
  download
}) => {
  const alert = s(
    "manageConfiguration.page.updateConfiguration.alert",
    "We have detected {errors} {errors, plural, one {error} other {errors}} in your file. Please make the necessary corrections to the file and upload the updated version.",
    { errors: validation.validationErrors.length }
  );
  const exportButton = s(
    "manageConfiguration.page.updateConfiguration.export",
    "Export Error Log"
  );
  const na = s(
    "manageConfiguration.page.updateConfiguration.notApplicable",
    "N/A"
  );
  const downloadError = s(
    "manageConfiguration.page.updateConfiguration.downloadError",
    "Error downloading error log"
  );
  const lineNumber = s(
    "manageConfiguration.page.updateConfiguration.validationErrors.lineNumber",
    "Line Number"
  );
  const sheetName = s(
    "manageConfiguration.page.updateConfiguration.validationErrors.sheetName",
    "Sheet Name"
  );
  const cellName = s(
    "manageConfiguration.page.updateConfiguration.validationErrors.cellName",
    "Cell Name"
  );
  const description = s(
    "manageConfiguration.page.updateConfiguration.validationErrors.description",
    "Description"
  );
  const data = validation
    ? validation.validationErrors.map((version, index) => ({
        ...version,
        key: index
      }))
    : [];

  useLoadingState(
    exportValidationLoadingState,
    () => {
      openDownload(download.url);
    },
    () => {
      message.error(downloadError);
    }
  );

  const columns = [];

  if (
    validation.workflowType === ValidationWorkflowTypes.VERIFY_TEMPLATE ||
    validation.workflowType === ValidationWorkflowTypes.CONFIGURATION
  ) {
    columns.push({
      title: sheetName,
      dataIndex: "sheetName",
      key: "sheetName",
      render: (text) => text || na
    });
    columns.push({
      title: cellName,
      dataIndex: "column",
      key: "column",
      render: (text, record) =>
        record.row
          ? record.column
            ? `${record.column}${record.row}`
            : `${record.row}`
          : na
    });
  } else if (validation.workflowType === ValidationWorkflowTypes.CRM_ACCOUNTS) {
    columns.push({
      title: lineNumber,
      dataIndex: "row",
      key: "row",
      render: (text, record) => record.row
    });
  } else if (
    validation.workflowType === ValidationWorkflowTypes.BULK_USER_UPLOAD
  ) {
    columns.push({
      title: cellName,
      dataIndex: "column",
      key: "column",
      render: (text, record) =>
        record.row
          ? record.column
            ? `${record.column}${record.row}`
            : `${record.row}`
          : na
    });
  }

  columns.push({
    title: description,
    dataIndex: "description",
    key: "description"
  });

  const onTableChange = () => {};

  const onExport = () => {
    exportValidation({
      validationId: validation._id,
      type: ValidationExportTypes.ERROR_LOG
    });
  };

  return (
    <Space
      size={16}
      direction={"vertical"}
      style={{ width: "100%" }}
      data-cy={"validation-errors"}
    >
      <Alert
        showIcon
        type={"error"}
        message={alert}
        variant={"smallBody"}
        data-cy={"validation-errors-alert"}
      />
      <Table
        columns={columns}
        dataSource={data}
        onChange={onTableChange}
        pagination={false}
        data-cy={"validation-errors-table"}
      />
      <NewButton
        type={"secondary"}
        onClick={onExport}
        data-cy={"export-logs-button"}
      >
        <Icon name={"download"} />
        {exportButton}
      </NewButton>
    </Space>
  );
};

const mapStateToProps = (state) => ({
  exportValidationLoadingState: selectRequestState(
    state,
    actionTypes.EXPORT_VALIDATION_REQUEST
  ),
  download: selectRequestData(state, actionTypes.EXPORT_VALIDATION_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportValidation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ValidationErrors
);
