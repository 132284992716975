import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { themeProp } from "../../theme";
import PropTypes from "prop-types";
import gfm from "remark-gfm";
import Hyperlink from "../Hyperlink";

const Markdown = styled(ReactMarkdown).attrs(({ remarkPlugins, onClick }) => ({
  remarkPlugins,
  components: {
    a(props) {
      const { href, children } = props;
      return (
        <Hyperlink to={href} onClick={onClick}>
          {children}
        </Hyperlink>
      );
    }
  }
}))`
  h1 {
    font-size: ${(props) => themeProp("typography.bMedium.fontSize")(props)};
    line-height: ${(props) =>
      themeProp("typography.bMedium.lineHeight")(props)};
    font-weight: ${(props) =>
      themeProp("typography.bMedium.fontWeight")(props)};
    font-family: ${(props) =>
      themeProp("typography.bMedium.fontFamily")(props)};
  }

  h2 {
    font-size: ${(props) =>
      themeProp(`typography.${props.size}.fontSize`)(props)};
    line-height: ${(props) =>
      themeProp(`typography.${props.size}.lineHeight`)(props)};
    font-weight: ${(props) =>
      themeProp(`typography.${props.size}.fontWeight`)(props)};
    font-family: ${(props) =>
      themeProp(`typography.${props.size}.fontFamily`)(props)};
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: ${(props) => themeProp("typography.small.fontSize")(props)};
    line-height: ${(props) => themeProp("typography.small.lineHeight")(props)};
    font-weight: ${(props) => themeProp("typography.small.fontWeight")(props)};
    font-family: ${(props) => themeProp("typography.small.fontFamily")(props)};
  }

  p {
    font-size: ${(props) =>
      themeProp(`typography.${props.size}.fontSize`)(props)};
    line-height: ${(props) =>
      themeProp(`typography.${props.size}.lineHeight`)(props)};
    font-weight: ${(props) =>
      themeProp(`typography.${props.size}.fontWeight`)(props)};
    font-family: ${(props) =>
      themeProp(`typography.${props.size}.fontFamily`)(props)};
    margin-bottom: ${(props) =>
      props.paragraphMargin
        ? themeProp(`typography.${props.size}.fontSize`)(props)
        : "0px"};
  }

  p:last-child {
    margin-bottom: 0;
  }

  li {
    font-size: ${(props) =>
      themeProp(`typography.${props.size}.fontSize`)(props)};
    line-height: ${(props) =>
      themeProp(`typography.${props.size}.lineHeight`)(props)};
    font-weight: ${(props) =>
      themeProp(`typography.${props.size}.fontWeight`)(props)};
    font-family: ${(props) =>
      themeProp(`typography.${props.size}.fontFamily`)(props)};
    list-style-position: inside;
  }

  a {
    color: ${(props) => themeProp("palette.primary")(props)};
  }

  del {
    text-decoration: line-through;
  }

  em {
    text-decoration: underline;
    font-style: normal;
  }
`;

Markdown.propTypes = {
  active: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(["small", "body", "h2", "h3"]).isRequired,
  paragraphMargin: PropTypes.bool.isRequired,
  remarkPlugins: PropTypes.array
};

Markdown.defaultProps = {
  active: false,
  size: "body",
  paragraphMargin: false,
  remarkPlugins: [gfm]
};

export default Markdown;
