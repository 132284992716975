import {
  isAuthenticated,
  selectRequestData,
  selectRequestState
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { getInvitation } from "../../store/actions/invitations";
import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import LoadingState from "../../utils/loading-state";
import ErrorPage, { NotFound } from "../ErrorPage";
import LandingPage from "./LandingPage";
import ResetPassword from "../ResetPassword";
import ForgotPassword from "../ForgotPassword";
import Login from "../Login";
import CheckEmail from "./CheckEmail";
import actionTypes from "../../store/actionTypes";
import ConfirmAccount from "./ConfirmAccount";
import VerifyAccount from "./VerifyAccount";

const Invitation = ({
  invitation,
  loadingState,
  match,
  getInvitation,
  emailStatus
}) => {
  const invitationCode = match.params.invitationCode;

  useEffect(() => {
    getInvitation({ invitationCode });
  }, [invitationCode]);

  switch (loadingState) {
    case LoadingState.FAILED:
      return <ErrorPage />;

    case LoadingState.COMPLETED:
      return (
        <Switch>
          <Route
            path="/invitations/:invitationId"
            exact
            render={() => <LandingPage invitation={invitation} />}
          />
          <Route
            path="/invitations/:invitationId/check-email"
            exact
            render={() => <CheckEmail invitation={invitation} />}
          />
          <Route
            path="/invitations/:invitationId/login"
            exact
            render={() => <Login invitation={invitation} />}
          />
          <Route
            path="/invitations/:invitationId/forgot-password"
            exact
            render={() => <ForgotPassword invitation={invitation} />}
          />
          <Route
            path="/invitations/:invitationId/reset-password"
            exact
            render={() => <ResetPassword invitation={invitation} />}
          />
          <Route
            path="/invitations/:invitationId/confirm-account"
            exact
            render={() => (
              <ConfirmAccount
                invitation={invitation}
                email={emailStatus.email}
              />
            )}
          />
          <Route
            path="/invitations/:invitationId/verify-account"
            exact
            render={() => (
              <VerifyAccount
                invitation={invitation}
                email={emailStatus.email}
              />
            )}
          />
          <Route component={NotFound} />
        </Switch>
      );

    default:
      return null;
  }
};

function mapStateToProps(state) {
  return {
    invitation: selectRequestData(state, actionTypes.GET_INVITATION_REQUEST),
    loadingState: selectRequestState(state, actionTypes.GET_INVITATION_REQUEST),
    isAuthenticated: isAuthenticated(state),
    emailStatus: selectRequestData(
      state,
      actionTypes.INVITATION_CHECK_EMAIL_STATUS_REQUEST
    )
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInvitation
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Invitation);
