import React, { useEffect, useRef, useState } from "react";
import EngagementNotificationCard from "./EngagementNotificationCard";
import Table from "../Table";
import { useString as s } from "../../components/StringProvider";
import { dateTimeYearFormat } from "../../utils/formatting";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getDiscoveryNotifications } from "../../store/actions/discoveries";
import actionTypes from "../../store/actionTypes";
import { selectRequestData } from "../../store/reducers";
import Tooltip from "../Tooltip";

const EngagementNotificationsTable = ({
  discoveryId,
  notifications,
  pagination,
  getDiscoveryNotifications
}) => {
  const [start, setStart] = useState(0);
  const [sort, setSort] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [pageSize, setPageSize] = useState(6);
  const firstUpdate = useRef(true);

  const doQuery = () => {
    getDiscoveryNotifications({
      discoveryId,
      external: true,
      start,
      count: pageSize,
      sort,
      order
    });
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    doQuery();
  }, [start, sort, order]);

  const handleTableChange = (pagination, filters, sorter) => {
    const mapOrder = (order) => {
      switch (order) {
        case "ascend":
          return "asc";
        case "descend":
          return "desc";
        default:
          return;
      }
    };

    if (pagination.current) {
      setStart((pagination.current - 1) * pagination.pageSize);
    }

    setPageSize(pagination.pageSize);

    if (sorter.order) {
      setSort(sorter.columnKey);
      setOrder(mapOrder(sorter.order));
    } else {
      setSort(undefined);
      setOrder(undefined);
    }
  };

  const currentPagination =
    pagination && pagination.total > pageSize
      ? {
          current: Math.floor(pagination.start / pageSize) + 1,
          total: pagination.total,
          pageSize,
          position: ["bottomRight"],
          showSizeChanger: false
        }
      : false;

  const data = notifications.map((notification) => ({
    _id: notification._id,
    createdAt: dateTimeYearFormat(notification.createdAt),
    action: (
      <EngagementNotificationCard
        notification={notifications.find((n) => n._id === notification._id)}
      />
    ),
    userType: notification.external
      ? s("engagement.dashboard.table.userTypeCustomer", "Customer")
      : "N/A",
    external: notification.external,
    email: (
      <Tooltip title={notification?.data?.username} placement={"top"}>
        {notification?.data?.username}
      </Tooltip>
    )
  }));

  const columns = [
    {
      title: s("engagement.dashboard.table.column1", "Date"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      ellipsis: { showTitle: false },
      width: "200px"
    },
    {
      title: s("engagement.dashboard.table.column2", "Email"),
      dataIndex: "email",
      key: "email",
      sorter: true,
      ellipsis: { showTitle: false },
      width: "200px"
    },
    {
      title: s("engagement.dashboard.table.column3", "Action"),
      dataIndex: "action",
      key: "action",
      sorter: false,
      width: "450px"
    },
    {
      title: s("engagement.dashboard.table.column4", "User type"),
      dataIndex: "userType",
      key: "userType",
      sorter: false,
      width: "150px"
    }
  ];

  return (
    <Table
      scroll={{ x: "577px" }}
      columns={columns}
      dataSource={data}
      onChange={handleTableChange}
      pagination={currentPagination}
    />
  );
};

EngagementNotificationsTable.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  notifications: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  getDiscoveryNotifications: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  notifications: selectRequestData(
    state,
    actionTypes.GET_DISCOVERY_NOTIFICATIONS_REQUEST
  )?.notifications,
  pagination: selectRequestData(
    state,
    actionTypes.GET_DISCOVERY_NOTIFICATIONS_REQUEST
  )?.pagination
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDiscoveryNotifications
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EngagementNotificationsTable);
