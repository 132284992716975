import PropTypes from "prop-types";
import Toggle from "../../../components/Toggle";
import TextBody from "../../../components/TextBody";
import { Space } from "antd";
import { Tooltip } from "../../../components";
import Icon from "../../../components/Icon";
import styled, { css } from "styled-components";
import { themeProp } from "../../../theme";

const ChartToggle = ({
  dataCy,
  userpilotTag,
  checked,
  onChange,
  toggleText,
  toggleTooltip,
  disabled
}) => {
  return (
    <Space size={12}>
      <Toggle
        data-cy={dataCy}
        className={userpilotTag}
        checked={checked}
        onChange={onChange}
        size={"medium"}
        disabled={disabled}
      />
      <ContentContainer disabled={disabled}>
        <TextBody>{toggleText}</TextBody>
        <IconContainer>
          <Tooltip title={toggleTooltip} placement={"top"}>
            <Icon name={"infoCircle"} colour={"gray3"} size={"medium"} />
          </Tooltip>
        </IconContainer>
      </ContentContainer>
    </Space>
  );
};

const ContentContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${themeProp("palette.gray3")};
    `}
`;

const IconContainer = styled.div`
  margin-top: 4px;
`;

ChartToggle.propTypes = {
  dataCy: PropTypes.string.isRequired,
  userpilotTag: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  toggleText: PropTypes.string.isRequired,
  toggleTooltip: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default ChartToggle;
