import { useEffect, useRef, useState } from "react";
import Toggle from "../../Toggle";
import {
  selectDiscoveryCurrency,
  selectDiscoveryId
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { saveDiscoveryKPI } from "../../../store/actions/discoveries";
import { connect } from "react-redux";
import { CommentsButton } from "../Comments";
import SelectableCard, {
  CardIconBar,
  SelectableCardRow
} from "../SelectableCard";
import PropTypes from "prop-types";
import KPICardDropdown from "./KPICardDropdown";
import Text from "../../Text";
import StandardDetails from "./StandardDetails";
import MobileDetails from "./MobileDetails";
import { Desktop, MobileOrTablet, useMobileMediaQuery } from "../../Responsive";
import { UserPilotEventNames } from "../../../utils/user-pilot-util";

const KPICard = ({
  discoveryId,
  reportId,
  currency,
  discoveryKPI,
  editable,
  saveDiscoveryKPI,
  printMode
}) => {
  const isMobile = useMobileMediaQuery();

  const [checked, setChecked] = useState(discoveryKPI.selected);
  const onChange = async ({ kpiCode, changes }) => {
    saveDiscoveryKPI({ discoveryId: discoveryId, kpiCode, changes });
  };
  const ref = useRef();

  useEffect(() => {
    if (checked !== discoveryKPI.selected) {
      setChecked(discoveryKPI.selected);
    }
  }, [discoveryKPI.selected]);

  const onToggle = async (value) => {
    setChecked(value);
    saveDiscoveryKPI({
      discoveryId: discoveryId,
      kpiCode: discoveryKPI.kpiCode,
      changes: { selected: value }
    });
  };

  const onChangeImportance = ({ importance }) => {
    saveDiscoveryKPI({
      discoveryId,
      kpiCode: discoveryKPI.kpiCode,
      changes: { importance }
    });
  };

  const toolbar = (
    <CardIconBar>
      <CommentsButton
        reportId={reportId}
        topic={`/discoveries/${discoveryId}/kpis/${discoveryKPI.kpiCode}`}
        eventName={UserPilotEventNames.ADD_NEW_COMMENT_OUTCOME}
      />
      {!reportId && editable ? (
        <KPICardDropdown
          discoveryId={discoveryId}
          discoveryKPI={discoveryKPI}
          hasFormulaDescription={discoveryKPI.hasFormulaDescription}
        />
      ) : null}
    </CardIconBar>
  );

  return (
    <SelectableCard
      selected={checked && editable}
      editable={editable}
      ref={ref}
      data-cy={`kpi-card-${discoveryKPI.kpiCode}`}
      toggle={<Toggle checked={checked} onChange={onToggle} />}
      toolbar={toolbar}
    >
      <SelectableCardRow>
        <Text
          variant={isMobile ? "bMediumMobile" : "h3"}
          data-cy={"kpi-name"}
          className={"selectable-card-title"}
        >
          {discoveryKPI.definition.name}
        </Text>
        {isMobile && editable ? null : toolbar}
      </SelectableCardRow>
      <MobileOrTablet>
        <MobileDetails
          discoveryKPI={discoveryKPI}
          currency={currency}
          reportId={reportId}
          editable={editable && checked}
          selected={checked}
          onChange={onChange}
          onChangeImportance={onChangeImportance}
        />
      </MobileOrTablet>
      <Desktop>
        <StandardDetails
          discoveryKPI={discoveryKPI}
          currency={currency}
          reportId={reportId}
          editable={editable && checked}
          selected={checked}
          onChange={onChange}
          onChangeImportance={onChangeImportance}
          printMode={printMode}
        />
      </Desktop>
    </SelectableCard>
  );
};

KPICard.propTypes = {
  discoveryKPI: PropTypes.object.isRequired,
  reportId: PropTypes.string,
  editable: PropTypes.bool,
  printMode: PropTypes.bool
};

KPICard.defaultProps = {
  editable: false
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    currency: selectDiscoveryCurrency(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryKPI
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(KPICard);
