import React from "react";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { themeProp } from "../../../theme";
import { useTheme } from "../../../components/ThemeProvider";
import { PageSection } from "../../../components/Page";

const PrintReportEnd = ({ discoveryName }) => {
  const coverImage = useTheme("assets.report_end_cover");

  return (
    <ReportFooterContainer>
      <ReportFooterImage src={coverImage} />
      <ReportFooterText>
        <Heading level={"h2"}>
          {s("report.end.header", "", { discoveryName })}
        </Heading>
      </ReportFooterText>
    </ReportFooterContainer>
  );
};

const ReportFooterContainer = styled(PageSection)`
  display: flex;
  align-items: center;
  background: ${themeProp("palette.surface")};
  min-height: 100vh;
`;

const ReportFooterImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
  height: 600px;
`;

const ReportFooterText = styled.div`
  padding: 0 24px;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default PrintReportEnd;
