import types from "../actionTypes";
import discoveriesService from "../../services/discoveries.service";

export const startCreatingKPI = () => (dispatch) => {
  dispatch({
    type: types.START_CREATING_KPI
  });
};

export const stopCreatingKPI = () => (dispatch) => {
  dispatch({
    type: types.STOP_CREATING_KPI
  });
};

export const createDiscoveryKPI =
  ({ discoveryId, data }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_CREATE_KPI_REQUEST
    });

    return discoveriesService
      .createDiscoveryKPI({ discoveryId, data })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_CREATE_KPI_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_CREATE_KPI_FAILURE,
          error
        });
      });
  };
