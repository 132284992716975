import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Group from "../../../components/Discovery/Group";
import {
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryMetricCodeStringByGroup
} from "../../../store/reducers";
import ThreeColumns from "./ThreeColumns";
import ReportMetric from "../../Report/components/ReportYourSituation/ReportMetric";

const PrintReportMetricGroup = ({ groupCode, metricCodeString }) => {
  return (
    <Group
      groupCode={groupCode}
      showGroupDescription={false}
      initiallyCollapsed={false}
      collapsible={false}
    >
      <ThreeColumns>
        {metricCodeString.split(",").map((metricCode, index) => (
          <ReportMetric key={index} metricCode={metricCode} />
        ))}
      </ThreeColumns>
    </Group>
  );
};

const mapStateToProps = (state, props) => ({
  discoveryId: selectDiscoveryId(state),
  currency: selectDiscoveryCurrency(state),
  metricCodeString: selectDiscoveryMetricCodeStringByGroup(
    state,
    props.groupCode
  )
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PrintReportMetricGroup
);
