import {
  isAuthenticated,
  isInitialLoginComplete,
  selectInitializationError,
  selectRequestData,
  selectRequestState,
  selectUserId
} from "../store/reducers";
import actionTypes from "../store/actionTypes";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import React, { useEffect, useState } from "react";
import useLoadingState from "../utils/use-loading-state";
import ErrorPage from "./ErrorPage";
import { updateMyInvitations } from "../store/actions/invitations";
import {
  amplifyInitialized,
  amplifySignedIn,
  amplifySignedOut,
  getMyUserInformation,
  logout
} from "../store/actions/authentication";
import {
  getUserSettings,
  setUserSettings
} from "../store/actions/user-settings";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from "react-router-dom";
import InitializationError from "./InitializationError";
import httpService from "../services/http.service";
import ChannelNames from "../utils/channel-names";
import pusherService from "../services/pusher.service";
import AboutUserModal from "../components/UserInformation/AboutUserModal";
import { Hub } from "aws-amplify/utils";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import loginStateService from "../services/login-state.service";
import { isConfigurationComplete } from "../store/selectors/config";
import errorsService from "../services/errors.service";

const handleInitialization = ({ initializationError }) => {
  return initializationError ? (
    <Switch>
      <Route path="/initialization-error" component={InitializationError} />
      <Redirect to="/initialization-error" />
    </Switch>
  ) : (
    ""
  );
};

const AuthenticationStates = {
  AMPLIFY_UNINITIALIZED: "AMPLIFY_UNINITIALIZED",
  AMPLIFY_INITIALIZED: "AMPLIFY_INITIALIZED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  UNAUTHENTICATED_USER_LOADED: "UNAUTHENTICATED_USER_LOADED",

  AUTHENTICATED: "AUTHENTICATED",
  AUTHENTICATED_USER_LOADED: "AUTHENTICATED_USER_LOADED",
  AUTHENTICATED_INVITATIONS_UPDATED: "AUTHENTICATED_INVITATIONS_UPDATED",

  ERROR: "ERROR"
};

const AuthenticationHandler = ({
  children,
  isAuthenticated,
  userInfoLoadingState,
  updateInvitationsLoadingState,
  updateMyInvitations,
  getMyUserInformation,
  userData,
  userSettings,
  getUserSettings,
  setUserSettings,
  isInitialLoginComplete,
  initializationError,
  logout,
  userId,
  isConfigurationComplete,
  amplifyInitialized,
  amplifySignedIn,
  amplifySignedOut,
  history
}) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [customState, setCustomState] = useState("");
  const location = useLocation();
  const [state, setState] = useState(
    AuthenticationStates.AMPLIFY_UNINITIALIZED
  );

  const checkSession = (attempt = 1) => {
    console.log(`checkSession() - attempt #${attempt}`);
    getCurrentUser()
      .then(() => {
        fetchAuthSession().then(({ tokens }) => {
          amplifyInitialized({ token: tokens?.accessToken?.toString() });
        });
      })
      .catch((e) => {
        // If the user is not logged in, don't retry
        if (e.name === "UserUnAuthenticatedException") {
          amplifyInitialized();
          return;
        }

        // If it's a different exception, retry only once
        if (attempt < 2) {
          setTimeout(() => {
            checkSession(attempt + 1);
          }, 100);
          return;
        }

        // If it's the 2nd attempt, log an error.
        amplifyInitialized();
        console.log(e);
        errorsService
          .logError({
            category: "authentication-handler",
            data: e.message,
            error: e
          })
          .then(() => {
            console.error("Logged error to backend:", e);
          })
          .catch(() => {
            console.error("Failed to log error to backend:", e);
          });
      });
  };

  useEffect(() => {
    return Hub.listen("auth", ({ payload }) => {
      console.log("Amplify Hub Event", payload.event);
      switch (payload.event) {
        case "signedIn":
          fetchAuthSession().then(({ tokens }) => {
            amplifySignedIn({ token: tokens?.accessToken?.toString() });
          });
          break;
        case "signInWithRedirect":
          fetchAuthSession().then(({ tokens }) => {
            amplifySignedIn({ token: tokens?.accessToken?.toString() });
          });
          break;
        case "customOAuthState":
          setCustomState(payload.data);
          break;
        case "signedOut":
          amplifySignedOut();
          break;
      }
    });
  }, []);

  useEffect(() => {
    console.log("state", state);
    switch (state) {
      case AuthenticationStates.UNAUTHENTICATED:
      case AuthenticationStates.AUTHENTICATED:
        setReady(false);
        getMyUserInformation();
        break;
      case AuthenticationStates.UNAUTHENTICATED_USER_LOADED:
        setReady(true);
        break;
      case AuthenticationStates.AUTHENTICATED_USER_LOADED:
        getUserSettings();
        updateMyInvitations();
        break;
      case AuthenticationStates.AUTHENTICATED_INVITATIONS_UPDATED:
        setReady(true);
        const data = customState
          ? loginStateService.getState({ state: customState })
          : undefined;

        if (data) {
          history.push(data);
        }
        break;

      case AuthenticationStates.ERROR:
        setError(true);
        break;
    }
  }, [state]);

  useEffect(() => {
    setState(
      isAuthenticated
        ? AuthenticationStates.AUTHENTICATED
        : AuthenticationStates.UNAUTHENTICATED
    );
  }, [isInitialLoginComplete, isAuthenticated]);

  useLoadingState(
    userInfoLoadingState,
    () => {
      if (
        userData.user.userId &&
        state === AuthenticationStates.AUTHENTICATED
      ) {
        setState(AuthenticationStates.AUTHENTICATED_USER_LOADED);
      } else if (
        !userData.user.userId &&
        state === AuthenticationStates.UNAUTHENTICATED
      ) {
        setState(AuthenticationStates.UNAUTHENTICATED_USER_LOADED);
      } else {
        console.log("Retry to get user info that matches authentication state");
        getMyUserInformation();
      }
    },
    () => {
      setState(AuthenticationStates.ERROR);
    }
  );

  useLoadingState(
    updateInvitationsLoadingState,
    () => {
      setState(AuthenticationStates.AUTHENTICATED_INVITATIONS_UPDATED);
    },
    () => {
      setState(AuthenticationStates.ERROR);
    }
  );

  useEffect(() => {
    if (isConfigurationComplete) {
      setTimeout(() => {
        checkSession();
      }, 0);
    }
  }, [isConfigurationComplete]);

  /*
    Resubscribe to the auth channel when the User navigates to a
    new page in case "Pusher" was disconnected (due to a long running
    browser session, for example)
   */

  useEffect(() => {
    if (userId) {
      const tenantId = httpService.getTenantId();
      const channelName = ChannelNames.AUTH({ userId, tenantId });
      if (!pusherService.isSubscribed(channelName)) {
        pusherService.subscribe({
          channelName,
          eventName: "logout",
          callback: () => {
            logout();
          }
        });
      }
    }
  }, [location, userId]);

  useEffect(() => {
    if (isAuthenticated && userSettings && !userSettings.timeZone) {
      setUserSettings({
        ...userSettings,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
    }
  }, [userSettings]);

  if (error) {
    return <ErrorPage />;
  }

  if (initializationError) {
    return handleInitialization({ initializationError });
  }

  if (!ready || !isInitialLoginComplete) {
    return null;
  }

  return (
    <>
      {children}
      <AboutUserModal />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  updateInvitationsLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_MY_INVITATIONS_REQUEST
  ),
  userInfoLoadingState: selectRequestState(
    state,
    actionTypes.USER_INFO_REQUEST
  ),
  userData: selectRequestData(state, actionTypes.USER_INFO_REQUEST),
  userSettings: selectRequestData(state, actionTypes.GET_USER_SETTINGS_REQUEST),
  isInitialLoginComplete: isInitialLoginComplete(state),
  initializationError: selectInitializationError(state),
  isConfigurationComplete: isConfigurationComplete(state),
  userId: selectUserId(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMyInvitations,
      getMyUserInformation,
      getUserSettings,
      setUserSettings,
      logout,
      amplifyInitialized,
      amplifySignedIn,
      amplifySignedOut
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AuthenticationHandler);
