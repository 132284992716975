import React, { useEffect, useState } from "react";
import Input from "./Input";
import PropTypes from "prop-types";

const EmailInput = React.forwardRef(
  ({ value, onChange, size, ...props }, inputRef) => {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const handleOnChange = (event) => {
      const caret = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });

      setCurrentValue(event.target.value.toLowerCase());
      event.target.value = event.target.value.toLowerCase();

      if (onChange) {
        onChange(event);
      }
    };

    return (
      <Input
        value={currentValue}
        onChange={handleOnChange}
        size={size}
        {...props}
        ref={inputRef}
      />
    );
  }
);

EmailInput.propTypes = {
  size: PropTypes.oneOf(["medium", "large"])
};

EmailInput.defaultProps = {
  size: "large"
};

export default EmailInput;
