import { useMobileMediaQuery } from "../../components/Responsive";
import DiscoveryHeader from "./DiscoveryHeader";
import Page from "../../components/Page";
import { BasicBrandHeader, FullPageLayout } from "../../components/Layout";
import DiscoveryNotesButton from "../../components/Notes/DiscoveryNotesButton";
import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const START_DISCOVERY = "/start-discovery";

const getHeader = ({ page, isMobile }) => {
  if (page === "/start-discovery" && isMobile) {
    return <BasicBrandHeader />;
  } else if (page === START_DISCOVERY) {
    return (
      <DiscoveryHeader
        showMenu={false}
        showCloseButton={true}
        showControlPanel={false}
        showAccountBadge={false}
      />
    );
  }

  return <DiscoveryHeader />;
};

const getLayout = ({ page }) => {
  if (page === START_DISCOVERY) {
    return Layout.NONE;
  }

  return Layout.FULL;
};

const Layout = {
  FULL: "full",
  NONE: "none"
};

const DiscoveryPage = ({ children, subHeader }) => {
  const isMobile = useMobileMediaQuery();
  const { pathname } = useLocation();
  const [page, setPage] = useState("");
  const [layout, setLayout] = useState(Layout.FULL);

  useEffect(() => {
    const matches = pathname.match(
      /^\/discoveries\/[^\/]+(\/[^\/]+)(\/[^\/]+)?$/
    );

    if (matches) {
      setPage(matches[1]);
    }
  }, [pathname]);

  useEffect(() => {
    setLayout(getLayout({ page }));
  }, [page]);

  return (
    <Page header={getHeader({ page, isMobile })}>
      {layout === Layout.FULL ? (
        <FullPageLayout>
          {subHeader}
          {children}
          <DiscoveryNotesButtonContainer
            className={isMobile ? "mobile" : undefined}
          >
            <DiscoveryNotesButton />
          </DiscoveryNotesButtonContainer>
        </FullPageLayout>
      ) : (
        <>{children}</>
      )}
    </Page>
  );
};

const DiscoveryNotesButtonContainer = styled.div`
  position: fixed;
  left: 24px;
  bottom: 24px;

  &.mobile {
    bottom: 88px;
  }
`;

DiscoveryPage.propTypes = {
  subHeader: PropTypes.node
};

export default DiscoveryPage;
