import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import JobStatuses from "../../../../utils/job-statuses";
import Status from "../../../../components/Status";

const SalesforceInstallationStatus = ({ status }) => {
  return (
    <StatusContainer>
      <Status status={status || JobStatuses.PENDING} />
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  margin: 8px 0 24px 0;
`;

SalesforceInstallationStatus.propTypes = {
  status: PropTypes.oneOf([...JobStatuses.ALL])
};

export default SalesforceInstallationStatus;
