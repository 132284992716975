import httpService from "./http.service";

const getConfig = () =>
  httpService
    .get(`/config`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getLatestCRMAccountsLog = () =>
  httpService
    .get(`/config/crm`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getSalesforceConfiguration = () =>
  httpService
    .get(`/config/salesforce`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getSalesforceInstallation = () =>
  httpService
    .get(`/config/salesforce/installation`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const startSalesforceInstallation = () =>
  httpService
    .post(`/config/salesforce/startInstallation`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateSalesforceConfiguration = (changes) =>
  httpService
    .patch(`/config/salesforce`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteSalesforceConfiguration = () =>
  httpService
    .delete(`/config/salesforce`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getAdminSettings = () =>
  httpService
    .get(`/config/admin`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateAdminSettings = (changes) =>
  httpService
    .patch(`/config/admin`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getConfig,
  getLatestCRMAccountsLog,
  getSalesforceConfiguration,
  updateSalesforceConfiguration,
  deleteSalesforceConfiguration,
  getAdminSettings,
  updateAdminSettings,
  getSalesforceInstallation,
  startSalesforceInstallation
};
export default methods;
