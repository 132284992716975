import { useLocation, withRouter } from "react-router-dom";
import { Userpilot } from "userpilot";
import { useEffect } from "react";
import { selectDiscoveryId, selectUserId } from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import httpService from "../../services/http.service";

const UserPilotPageTracker = ({ userId, discoveryId }) => {
  const location = useLocation();

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  useEffect(() => {
    if (discoveryId && userId) {
      const tenantId = httpService.getTenantId();
      Userpilot.identify(userId, {
        discoveryId,
        tenantId,
        company: { id: tenantId, name: tenantId }
      });
    }
  }, [discoveryId, userId]);

  return null;
};

const mapStateToProps = (state) => ({
  userId: selectUserId(state),
  discoveryId: selectDiscoveryId(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(UserPilotPageTracker);
