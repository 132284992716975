import styled from "styled-components";
import Heading from "../../../../components/Heading";

const CurrencyRange = styled(Heading).attrs(() => ({
  level: "h3"
}))`
  color: white;
`;

export default CurrencyRange;
