import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { selectRequestData, selectRequestState } from "../../../store/reducers";
import actionTypes from "../../../store/actionTypes";
import { getAccounts } from "../../../store/actions/crm";
import useLoadingState from "../../../utils/use-loading-state";
import { useString as s } from "../../StringProvider";
import PropTypes from "prop-types";
import * as formatting from "../../../utils/formatting";
import { getLatestCRMAccountsLog } from "../../../store/actions/config";
import InputWithDropdown from "./InputWithDropdown";
import SourceTypes from "../../../utils/source-types";
import LoadingState from "../../../utils/loading-state";

const SelectAccount = ({
  accountSource,
  loadingState,
  accounts,
  pagination,
  getAccounts,
  onUseAccountName,
  onUseAccountId,
  getLatestCRMAccountsLog,
  log,
  ...props
}) => {
  const [items, setItems] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [itemsLoadingState, setItemsLoadingState] = useState(
    LoadingState.UNINITIALIZED
  );
  const [search, setSearch] = useState("");

  const crmGroupName = s(
    "selectAccount.component.crmGroupName",
    "Customers in CRM"
  );

  const customGroupName = s(
    "selectAccount.component.customGroupName",
    "Your Suggestion"
  );

  const lastUpdated = s(
    "selectAccount.component.lastModified",
    "Last updated on {lastUpdatedTime}",
    {
      lastUpdatedTime:
        log && log.updatedAt ? formatting.timeSince(log.updatedAt) : log
    }
  );

  const suggestion = s(
    "selectAccount.component.suggestion",
    "You can still use this Customer Name but your discovery won't be matched to the CRM."
  );

  useEffect(() => {
    getLatestCRMAccountsLog();
  }, []);

  useLoadingState(
    loadingState,
    () => {
      setAllAccounts((all) => [...all, ...accounts]);

      if (!accounts.length) {
        setItems(
          allAccounts.map(({ accountId, name, source }) => ({
            id: accountId,
            name,
            source
          }))
        );
        setItemsLoadingState(LoadingState.COMPLETED);
      } else {
        getAccounts({ search, start: pagination.start + pagination.count });
      }
    },
    () => {
      setItemsLoadingState(LoadingState.FAILED);
    }
  );

  const onLoadItems = (search) => {
    setAllAccounts([]);
    setSearch(search);
    setItemsLoadingState(LoadingState.IN_PROGRESS);
    getAccounts({ search });
  };

  return (
    <InputWithDropdown
      items={items}
      source={accountSource}
      onUseId={onUseAccountId}
      onUseName={onUseAccountName}
      onLoadItems={onLoadItems}
      crmGroupName={crmGroupName}
      customGroupName={customGroupName}
      lastUpdated={lastUpdated}
      suggestion={suggestion}
      loadingState={itemsLoadingState}
      data-cy={"select-account"}
      {...props}
    />
  );
};

SelectAccount.propTypes = {
  onUseAccountId: PropTypes.func.isRequired,
  onUseAccountName: PropTypes.func.isRequired,
  accountSource: PropTypes.oneOf(SourceTypes.ALL)
};

const mapStateToProps = (state) => {
  const data = selectRequestData(state, actionTypes.GET_CRM_ACCOUNTS_REQUEST);
  return {
    loadingState: selectRequestState(
      state,
      actionTypes.GET_CRM_ACCOUNTS_REQUEST
    ),
    accounts: data ? data.items : [],
    pagination: data ? data.pagination : undefined,
    log: selectRequestData(
      state,
      actionTypes.GET_LATEST_CRM_ACCOUNTS_LOG_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccounts,
      getLatestCRMAccountsLog
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectAccount
);
