import styled from "styled-components";
import PropTypes from "prop-types";
import { themeProp } from "../../theme";
import Text from "../Text";
import { useString as s } from "../StringProvider";
import Icon from "../Icon";
import NewButton from "../NewButton";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { setCookie } from "../../store/actions/cookies";

const DiscoveryNotesPrompt = ({ onClick, setCookie }) => {
  const prompt = s("notes.prompt.text", "Don't forget to ask extra questions");
  const hideText = s("notes.prompt.hide", "Hide");
  const tooltipText = s("notes.button", "Open notes");

  const onClose = () => {
    setCookie({ name: "DiscoveryNotesPrompt", value: "true" });
  };

  return (
    <Container>
      <HideContainer>
        <NewButton
          data-cy={"hide-prompt-button"}
          type={"text"}
          onClick={onClose}
        >
          <FlexContainer>
            <Text variant={"small"} color={"gray4"}>
              <b>{hideText}</b>
            </Text>
            <Icon name={"close"} colour={"gray4"} size={"medium"} />
          </FlexContainer>
        </NewButton>
      </HideContainer>
      <TextContainer>
        <Text variant={"bMedium"}>{prompt}</Text>
      </TextContainer>
      <NewButton type={"iconPrimary"} tooltip={tooltipText} onClick={onClick}>
        <Icon name={"notes"} size={"large"} />
      </NewButton>
    </Container>
  );
};

const HideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 7px;

  & .ant-btn {
    margin-right: -9px;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 12px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span[name="close"] {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Container = styled.div`
  background: ${themeProp("palette.surface")};
  padding: 16px;
  filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.04));
  border-radius: 6px;
  max-width: 200px;

  & .ant-btn-iconPrimary {
    margin-left: -6px;
  }
`;

DiscoveryNotesPrompt.propTypes = {
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCookie
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DiscoveryNotesPrompt
);
