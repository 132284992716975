import { useString as s } from "../../StringProvider";
import PropTypes from "prop-types";
import NameAndEmailForm from "./NameAndEmailForm";
import { Phases } from "../../../utils/phases";
import Modal from "../../Modal";
import React, { useState } from "react";
import styled from "styled-components";
import Icon from "../../Icon";
import NewButton from "../../NewButton";
import ManageDiscoveryAccess from "./ManageDiscoveryAccess";

const ShareDiscoveryModal = ({ template, onClose }) => {
  const [page, setPage] = useState(1);

  const onNext = () => {
    setPage(2);
  };

  const onPrevious = () => {
    setPage(1);
  };

  const renderPage = () => {
    switch (page) {
      case 1:
        return (
          <NameAndEmailForm
            template={template}
            onClose={onClose}
            onNext={onNext}
          />
        );

      case 2:
        return (
          <ManageDiscoveryAccess
            onPrevious={onPrevious}
            onClose={onClose}
            height={"430px"}
            width={"550px"}
          />
        );

      default:
    }
  };

  const manageAccessTitle = () => {
    return (
      <ManageAccessTitleContainer>
        <NewButton type={"iconSecondary"} onClick={onPrevious}>
          <StyledIcon name={"arrowLeft2"} size={"larger"} />
        </NewButton>
        <div>{s("share.popup.header.page2", "Manage Access")}</div>
      </ManageAccessTitleContainer>
    );
  };

  return (
    <Modal
      title={
        page === 1
          ? s("share.popup.header.page1", "Share")
          : manageAccessTitle()
      }
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
      width={"600px"}
      open={true}
      onCancel={onClose}
      data-cy={"share-discovery-modal"}
    >
      {renderPage()}
    </Modal>
  );
};

const StyledIcon = styled(Icon)`
  margin-top: 2px;
  margin-left: -8px;
  color: black;
`;

const ManageAccessTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
`;

ShareDiscoveryModal.propTypes = {
  template: PropTypes.oneOf(Phases.ALL).isRequired,
  onClose: PropTypes.func.isRequired
};

export default ShareDiscoveryModal;
